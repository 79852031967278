import React, { FC, useEffect, useState } from "react";
import { Form, Formik, FormikValues } from "formik";
import { SideModal, Typography, Input, Button, ChipPercentage } from "design_system/src";
import * as yup from "yup";
import _ from "lodash";
import { ReactComponent as Bookmark } from "design_system/src/static/icons/bookmark.svg";
import { ReactComponent as Document } from "design_system/src/static/icons/document-ui.svg";
import { ReactComponent as Discount } from "design_system/src/static/icons/discount.svg";
import { ReactComponent as Save } from "design_system/src/static/icons/save.svg";
import { ReactComponent as Envelope } from "design_system/src/static/icons/envelope.svg";
import { ReactComponent as Close } from "design_system/src/static/icons/close-circle.svg";
import "./AddEditDiscount.scss";
import {
  Discount as IDiscount,
  DiscountType,
} from "../../../models/discount.interface";
import { TVehicle } from "../../../models/vehicle.interface";
import { addDiscount, deleteDiscount } from "../../../helpers/fetchs/inventory";

interface AddEditDiscountForm extends Partial<IDiscount> {
  hasPromoCode: boolean;
}

const validationSchema = (vehicle: TVehicle | null | undefined, creditTimes: any[]) =>
  yup.object({
    hasPromoCode: yup.boolean().required("Campo requerido"),
    promoCode: yup.string().when("hasPromoCode", {
      is: true,
      then: yup.string().required("Campo requerido"),
    }),
    type: yup.string().required("Campo requerido"),
    value: yup.number().when("type", {
      is: DiscountType.NET.toString(),
      then: yup
        .number()
        .typeError("El valor debe ser numerico")
        .required("Campo requerido")
        .min(0, "El valor no puede ser menor a 0")
        .max(
          creditTimes[creditTimes.length-1].paymentWeek,
          `El valor no puede ser mayor a ${creditTimes[creditTimes.length - 1].paymentWeek}`
        ),
    }).when("type", {
      is: DiscountType.PERCENTAGE.toString(),
      then: yup
        .number()
        .typeError("El valor debe ser numerico")
        .required("Campo requerido")
        .min(0, "El valor no puede ser menor a 0")
        .max(
          99,
          "El valor no puede ser mayor a 100%"
        ),
    }),
  });

const defaults: AddEditDiscountForm = {
  hasPromoCode: false,
  type: DiscountType.NET,
  value: 0,
  promoCode: "",
};

interface DiscountProps {
  open: boolean;
  setOpen: Function;
  setResult: Function;
  vehicle?: TVehicle | null;
  initials?: any;
  creditTimes:any[];
}

const calculateDiscount = (
  type: DiscountType | undefined,
  value: number | undefined,
  creditTimes: any[]
) => {
  const nValue = Number(value) || 0;

  const defaultValue = { newPrice: Number(creditTimes[0].oldPaymentWeek??creditTimes[0].paymentWeek), percent: 0 };

  if (!type || !value || !creditTimes) {
    return defaultValue;
  }
  const originalPrice = Number(creditTimes[0].oldPaymentWeek ?? creditTimes[0].paymentWeek);
  switch (type) {
    case DiscountType.NET:
      return {
        newPrice: Math.min(
          Math.max(originalPrice - nValue, 0),
          originalPrice
        ).toFixed(2),
        percent: (nValue * 100) / originalPrice,
      };
    case DiscountType.PERCENTAGE:
      return {
        newPrice: (originalPrice - (originalPrice / 100) * nValue).toFixed(2),
        percent: nValue,
      };
    default:
      return defaultValue;
  }
};

const AddEditDiscount: FC<DiscountProps> = ({ open, setOpen, vehicle, setResult, initials = null, creditTimes }) => {
  const [initialValue, setInitialValue] = useState(defaults);
  const [activeId, setActiveId] = useState(null);
  const onSubmit =
    async (
      values: FormikValues
    ) => {
      try {
        if (!vehicle) return;

        const discount: any = {
          type: values.type
        };
        if (values.hasPromoCode) {
          discount.code = values.promoCode;
        }
        if (values.type === DiscountType.PERCENTAGE) {
          discount.percentageValue = values.value / 100;
        }
        else {
          discount.netValue = values.value;
        }
        const result = await addDiscount(
          discount,
          vehicle.internalId,
          activeId
        );
        setOpen(false);
        setResult(result);
      } catch (e) {
        console.error(e);
        setOpen(false);
      }
    };
  const delDiscount = async () => {
    if (vehicle && initials) {
      const result = await deleteDiscount(
        initials.id,
        vehicle.internalId
      );
      setOpen(false);
      setResult(result);
    }
  };
  useEffect(() => {

    if (initials) {
      setActiveId(initials.id);
      setInitialValue({
        hasPromoCode: !!initials.code,
        type: initials.type,
        value: initials.type === DiscountType.PERCENTAGE ? initials.percentageValue * 100 : initials.netValue,
        promoCode: initials.code ? initials.code : ""
      }
      );
    }
    else {
      setActiveId(null);
      setInitialValue(defaults);
    }
  }, [initials]);
  return (
    <SideModal open={open} setOpen={setOpen} size="small">
      <Formik
        validateOnMount
        initialValues={initialValue}
        enableReinitialize
        onSubmit={onSubmit}
        validationSchema={validationSchema(vehicle,creditTimes)}
      >
        {(formik) => {

          const discount = calculateDiscount(
            formik.values.type,
            formik.values.value,
            creditTimes
          );

          return (
            <div className="discount-container">
              <Form>
                <div className="title-discount-container m_b_xs">
                  <Typography
                    scale="heading4"
                    weight="600"
                    className="m_t_xxxl m_b_xl text_center"
                    textColor="neutral_900"
                  >Nuevo descuento</Typography>
                </div>

                <Input
                  icon={<Document />}
                  title="¿Descuento por código promocional?"
                  type="slider"
                  name="hasPromoCode"
                  labels
                  className="m_b_sm"
                  value={formik.values.hasPromoCode}
                  onChange={formik.handleChange}
                />
                {formik.values.hasPromoCode ? (
                  <Input
                    name="promoCode"
                    icon={<Envelope />}
                    value={formik.values.promoCode}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    title="Escribe código promocional"
                    type="text"
                    error={
                      Boolean(formik.errors.promoCode) &&
                      formik.touched.promoCode
                    }
                    subtitle={
                      formik.touched.promoCode
                        ? (formik.errors.promoCode as string)
                        : undefined
                    }
                  />
                ) : null}
                <Input
                  name="type"
                  value={formik.values.type}
                  onChange={(e:any) => {
                    formik.setFieldValue(
                      "value",
                      e.target.value === DiscountType.NET ? 0 : 5
                    );
                    formik.handleChange(e);
                  }}
                  icon={<Bookmark />}
                  onBlur={formik.handleBlur}
                  title="Tipo de descuento"
                  type="select"
                  options={[
                    { label: "Descuento Neto", value: DiscountType.NET },
                    {
                      label: "Descuento Procentual",
                      value: DiscountType.PERCENTAGE,
                    },
                  ]}
                />
                  <Input
                    name="value"
                    value={formik.values.value}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    icon={<Discount />}
                    title={formik.values.type === DiscountType.NET ? "Valor de descuento" : "Porcentaje de descuento"}
                    type="number"
                    error={
                      !!formik.errors.value &&
                      formik.getFieldMeta("value").touched
                    }
                    subtitle={
                      formik.getFieldMeta("value").touched
                        ? (formik.errors.value as string)
                        : undefined
                    }
                  />

                <div className="flex_1" />
                <div className="footer-discount-container m_t_xxxl">
                  <Typography weight="400" scale="small" className="m_b_xxs">
                    <span className="text_neutral_900">Pagos semanales</span>
                  </Typography>
                  {formik.values.value &&
                    formik.values.type &&
                    Number(formik.values.value) > 0 ? (
                    <div className="display_flex flex_1 flex_align_center">
                      <Typography weight="600" scale="heading4">
                        <span className=" text_red_300 m_t_xxs text_decoration_line-through">
                            ${Number(creditTimes[0].oldPaymentWeek ?? creditTimes[0].paymentWeek)}
                        </span>
                      </Typography>
                      <div className="m_l_xs">
                        {formik.values.type === DiscountType.PERCENTAGE && (
                          <ChipPercentage
                            value={Number(discount.newPrice)}
                              total={Number(creditTimes[0].oldPaymentWeek ?? creditTimes[0].paymentWeek)}
                          />
                        )}
                      </div>
                    </div>
                  ) : null}
                  <Typography weight="600" scale="heading3" className="m_y_xs">
                    <span className="text_primary_300">
                      ${discount.newPrice}
                    </span>
                  </Typography>
                  {/* <div className="display_flex flex_align_center">
                    <Button scale="small" className="bg_neutral_800">
                      x 12 meses{" "}
                    </Button>
                    <span className="text_xxsmall_400 m_l_xs">52 semanas</span>
                  </div> */}
                </div>

                <Button
                  icon={<Save />}
                  disabled={!formik.isValid}
                  scale="small"
                  type="submit"
                  orientation="right"
                  className="m_x_xxl m_t_lg"
                >
                  Guardar{" "}
                </Button>
                {
                  initials && (
                    <Button
                      icon={<Close />}
                      scale="small"
                      variant="ghost"
                      orientation="right"
                      className="m_t_sm m_x_xxl m_b_xl text_red_300"
                      onClick={delDiscount}
                    >
                      Borrar descuento
                    </Button>
                  )
                }
              </Form>
            </div>
          );
        }}
      </Formik>
    </SideModal>
  );
};

export default AddEditDiscount;
