/* eslint-disable no-nested-ternary */
/* eslint-disable dot-notation */
/* eslint-disable no-unused-vars */

import React, { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// -----------------LIBRARIES IMPORTS------------------- //
import {
    Button,
    ModalConfirmation as Modal,
    SideModal,
    Typography,
} from "design_system/src";
import { toasterNotification } from "helpers/globalNotifications/globalNotifications";
import { toasterOptions } from "enums/toaster.enum";
import { LightTooltip } from "components/hocs/tooltip/Tooltip";

// -----------------DATA IMPORTS------------------- //
import { CreditStatus, CreditStatusValues } from "models/credit.interface";
import { statusOzoners } from "models/ozoner.interface";
import { statusVehicles, TVehicle } from "models/vehicle.interface";
import { FormStatusValues } from "models/financialForm.interface";

// -----------------STATIC IMPORTS------------------- //
import "../ozoner.scss";
import { ReactComponent as Back } from "design_system/src/static/icons/back.svg";
import { ReactComponent as Eye } from "design_system/src/static/icons/eye.svg";
import { ReactComponent as Refresh } from "design_system/src/static/icons/refresh.svg";
import { ReactComponent as Copy } from "design_system/src/static/icons/copy.svg";
import { ReactComponent as Monitor } from "design_system/src/static/icons/monitor.svg";
import { ReactComponent as Panda } from "design_system/src/static/icons/panda.svg";
import { ReactComponent as EyeOff } from "design_system/src/static/icons/eye-off.svg";
import { ReactComponent as Reload } from "design_system/src/static/icons/reload.svg";
import { ReactComponent as Whatsapp } from "design_system/src/static/icons/whatsapp.svg";
import { ReactComponent as Mail } from "design_system/src/static/icons/mail.svg";
import { ReactComponent as SMS } from "design_system/src/static/icons/chat-square-plain.svg";
import { ReactComponent as Add } from "design_system/src/static/icons/add-circle.svg";
import { ReactComponent as Down } from "design_system/src/static/icons/chevron-down.svg";
import { ReactComponent as Helmet } from "design_system/src/static/icons/helmet.svg";

// -----------------COMPONENTS IMPORTS------------------- //
import { editCredit } from "helpers/fetchs/credits";
import History from "components/hocs/history/History";
import Inventory from "views/inventory/Inventory";
import { handleEventGoogle } from "helpers/analytics/googleAnalytics";
import TruoraDetails from "../platforms/TruoraDetails";
import PalencaDetails from "../platforms/PalencaDetails";
import DataOzoner from "./sections/DataOzoner";
import DocumentsOzoner from "./sections/DocumentsOzoner";
import CollectionOzoner from "./sections/CollectionOzoner";
import CreditOzoner from "./sections/CreditOzoner";
import EditOzoner from "../edits/EditOzoner";
import HistoryStatus from "./statusHistory/HistoryStatus";
import { ozonerContext } from "./OzonerContext";
import NotificationsModal from "./modals/NotificationsModal";
import SolicitudOzoner from "./sections/SolicitudOzoner/SolicitudOzoner";

export const displayQuota = (quota: number) => {
    if (quota) {
        const quotaToStr = quota.toString();
        return `0,${quotaToStr}`;
    }
    return `0,${0}`;
};

// eslint-disable-next-line no-shadow
export enum EditTypesOzoner {
    REGULAR = 0,
    OZONER_DATA = 1,
    PERSONAL_REFERENCES = 2,
    FINANCIAL_DATA = 3,
    NEW_SOLICITUD = 4,
}

const CreditOption = (
    data: any,
    onVehicle: Function,
    onClick: any = () => {}
) => {
    if (data) {
        const { vehicle, credit, form, vehicleAvailable } = data;
        return (
            <div
                className="p_md display_flex flex_gap_md border_solid border_1 border_neutral_400 flex_align_center cursor_pointer"
                role="button"
                tabIndex={0}
                onClick={onClick}
                onKeyDown={onClick}
                key={form._id}
            >
                {vehicle ? (
                    <>
                        <div className="br_xs overflow_hidden foto_moto">
                            <img
                                src={
                                    vehicle.images[0]
                                        ? vehicle.images[0].url
                                        : `${process.env.REACT_APP_STORAGE_BASE_URL}public/uploads/generic-moto.png`
                                }
                                alt="moto detail"
                            />
                        </div>
                        <div className="flex_grow_1 flex_center_col">
                            <Typography
                                scale="small"
                                weight="600"
                                className="inventory_link cursor_pointer"
                                onClick={(e) =>
                                    onVehicle(e, vehicle.internalId)
                                }
                            >
                                {vehicle.internalId}
                            </Typography>

                            <Typography
                                scale="xxsmall"
                                weight="400"
                                className="m_b_xxs"
                            >
                                {vehicle.brand && vehicle.model
                                    ? `${vehicle.brand.name} ${
                                          vehicle.model.name
                                      } ${vehicle.suffix ?? ""}`
                                    : "no definido"}
                            </Typography>
                            <div
                                className={` br_xs p_x_md p_y_xxs  bg_${
                                    vehicleAvailable
                                        ? statusVehicles[
                                              vehicle.status as keyof typeof statusVehicles
                                          ]?.color
                                        : "neutral_600"
                                }_nocontrast`}
                            >
                                <Typography
                                    scale="small"
                                    weight="600"
                                    textColor="neutral_0"
                                    className="text_center"
                                >
                                    {vehicleAvailable
                                        ? statusVehicles[
                                              vehicle.status as keyof typeof statusVehicles
                                          ]?.title ?? vehicle.status
                                        : "No disponible"}
                                </Typography>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="br_xs overflow_hidden foto_moto">
                            <img
                                src={`${process.env.REACT_APP_STORAGE_BASE_URL}public/uploads/generic-moto.png`}
                                alt="moto detail"
                            />
                        </div>
                        <div className="flex_grow_1 flex_center_col">
                            <Typography
                                scale="small"
                                weight="600"
                                className="inventory_link cursor_pointer"
                            >
                                Sin Moto
                            </Typography>

                            <div className="br_xs p_x_md p_y_xxs  bg_neutral_600_nocontrast">
                                <Typography
                                    scale="small"
                                    weight="600"
                                    textColor="neutral_0"
                                    className="text_center"
                                >
                                    Por Asignar
                                </Typography>
                            </div>
                        </div>
                    </>
                )}
                <div className="h_xxxl bg_neutral_400 w_xxxs" />
                {credit ? (
                    <div className="flex_grow_1">
                        <Typography
                            scale="medium"
                            weight="600"
                            textColor="neutral_900"
                            className="m_b_xs text_no_break"
                        >
                            Crédito {credit.code}
                        </Typography>
                        <LightTooltip
                            title={
                                CreditStatusValues[
                                    credit?.status as keyof typeof CreditStatus
                                ]?.tooltip ?? credit?.status
                            }
                        >
                            <div>
                                <Typography
                                    scale="xsmall"
                                    weight="400"
                                    textColor={
                                        (CreditStatusValues as any)[
                                            credit.status
                                        ]?.color
                                    }
                                    className="m_b_xs"
                                >
                                    {(CreditStatusValues as any)[credit.status]
                                        ?.title ?? credit.status}
                                </Typography>
                            </div>
                        </LightTooltip>
                    </div>
                ) : (
                    <div className="flex_grow_1">
                        <Typography
                            scale="medium"
                            weight="600"
                            textColor="neutral_900"
                            className="m_b_xs text_no_break"
                        >
                            Solicitud de crédito
                        </Typography>
                        <LightTooltip
                            title={
                                (FormStatusValues as any)[form.status]
                                    ?.tooltip ??
                                (FormStatusValues as any)[form.status]
                            }
                        >
                            <div>
                                <Typography
                                    scale="xsmall"
                                    weight="400"
                                    textColor={
                                        (FormStatusValues as any)[form.status]
                                            ?.color
                                    }
                                    className="m_b_xs"
                                >
                                    {
                                        (FormStatusValues as any)[form.status]
                                            ?.title
                                    }
                                </Typography>
                            </div>
                        </LightTooltip>
                    </div>
                )}
                <div className="dim_lg" />
            </div>
        );
    }
    return null;
};

const DetailsOzoner = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { search } = useLocation();
    const { _id } = useParams();

    // -----------------LOGIC STATES------------------- //
    const [tab, setTab] = useState<number>(0);
    const [openHistory, setOpenHistory] = useState<boolean>(false);
    const [openStatusHistory, setOpenStatusHistory] = useState<boolean>(false);
    const [openPalenca, setOpenPalenca] = useState(false);
    const [openTruora, setOpenTruora] = useState(false);
    const [openNotifications, setOpenNotifications] = useState(false);
    const [openDetails, setOpenDetails] = useState<boolean>(false);
    const [openCredits, setOpenCredits] = useState<boolean>(false);

    const [editData, setEditData] = useState<any>();

    const {
        setId,
        setFormId,
        ozoner,
        financialForms,
        loading,
        openModal,
        setOpenModal,
        modalOption,
        loadingModal,
        modalData,
        selfie,
        getPalencaLink,
        openCreditChange,
        setOpenCreditChange,
        updateCreditTime,
        changeCreditLabel,
        setResult,
        financialForm,
        openVehicle,
        setOpenVehicle,
        updateStatusSolicitud,
        validTruora,
        ozonios,
        hasCredit,
        handleDetailsVeh,
    } = useContext(ozonerContext);

    const changeHistoryDate = (
        e: React.ChangeEvent<HTMLInputElement>,
        change: any
    ) => {
        if (financialForm?.credit) {
            editCredit(financialForm.credit._id, {
                historicUpdateId: change._id,
                historicUpdateDate: e.target.value,
            }).then((data) => {
                setResult(data);
            });
        }
    };

    

    const openEditOzoner = (label: string, editType: number) => {
        switch (editType) {
            case EditTypesOzoner.OZONER_DATA:
                setEditData({ label, sections: [true, true, false, false] });
                break;
            case EditTypesOzoner.FINANCIAL_DATA:
                setEditData({ label, sections: [false, false, false, true] });
                break;
            case EditTypesOzoner.PERSONAL_REFERENCES:
                setEditData({ label, sections: [false, false, true, false] });
                break;
            case EditTypesOzoner.NEW_SOLICITUD:
                setEditData({
                    label,
                    sections: [false, true, true, true],
                    form: true,
                });
                break;
            default:
                setEditData({ label });
                break;
        }
    };
    useEffect(() => {
        setOpenDetails(true);
    }, [editData]);

    const changeForm = (id: string) => {
        navigate(`/ozoner/${_id}?formId=${id}`);
        setFormId(id);
        setOpenCredits(false);
    };

    useEffect(() => {
        setId(_id);
        const queryParams = new URLSearchParams(location.search);
        const tabParam = queryParams.get("tab");
        const edit = queryParams.get("openEdit");
        const formId = queryParams.get("formId");
        if (formId) setFormId(formId);

        if (tabParam) setTab(parseInt(tabParam, 10));
        if (edit === "true") {
            openEditOzoner(
                "Crear nueva solicitud",
                EditTypesOzoner.NEW_SOLICITUD
            );
        }
    }, [_id]);

    const createNewForm = () => {
        openEditOzoner("Crear nueva solicitud", EditTypesOzoner.NEW_SOLICITUD);
        setOpenCredits(false);
    };
    useEffect(() => {
        switch (tab) {
            case 0:
                handleEventGoogle(
                    "TAB_Pb_request_info",
                    "Panda Solicitudes",
                    "tab view to the info ozoner in request"
                );
                break;
            case 1:
                handleEventGoogle(
                    "TAB_Pb_request_financial",
                    "Panda Solicitudes",
                    "tab view to the financial ozoner in request"
                );
                break;
            case 2:
                handleEventGoogle(
                    "TAB_Pb_request_documents",
                    "Panda Solicitudes",
                    "tab view to the documents ozoner in request "
                );
                break;
            case 3:
                handleEventGoogle(
                    "TAB_Pb_request_collection",
                    "Panda Solicitudes",
                    "tab view to the collection ozoner in request"
                );
                break;

            default:
                break;
        }
    }, [tab]);

    const goWA = (number: string) =>
        window.open(`https://api.whatsapp.com/send?phone=${number}`, "_blank");

    return !loading && ozoner ? (
        <>
            <div className="dim_100_per">
                <div className="shadow_hard br_xs p_x_md p_y_xs display_flex flex_gap_md flex_align_center m_b_lg">
                    <Button
                        variant="icon"
                        icon={<Back />}
                        subvariant="edit"
                        scale="small"
                        onClick={() => navigate(-1)}
                        className="flex_align_self_start"
                    />
                    <div
                        id="preview-ozoner"
                        className="display_flex flex_gap_md flex_align_center flex_grow_1"
                    >
                        <div
                            className={`br_sm border_xxs border_solid photo_ozoner  display_flex overflow_hidden img_ozoner border_${
                                (statusOzoners as any)[ozoner.status]?.color
                            }`}
                        >
                            <img
                                src={selfie}
                                alt=""
                                className="dim_100_per img_cover"
                            />
                        </div>
                        <div className="display_flex flex_justify_between flex_col flex_gap_xs ">
                            <div className="display_flex flex_gap_md flex_align_center">
                                <div className="p_y_xxxs p_x_xxs br_xxxs bg_neutral_300 flex_center flex_gap_xs">
                                    {ozoner.createdOn === "pandaboard" ? (
                                        <Panda className=" dim_sm text_primary_200" />
                                    ) : (
                                        <Monitor className=" dim_sm text_primary_200" />
                                    )}
                                    <Typography
                                        scale="small"
                                        textColor="neutral_800"
                                        weight="400"
                                        className="text_capitalize"
                                    >
                                        {ozoner.createdOn}
                                    </Typography>
                                </div>
                            </div>
                            <Typography
                                scale="medium"
                                textColor="neutral_900"
                                weight="600"
                                className="text_capitalize"
                            >
                                {ozoner.name}
                            </Typography>
                            <div className="display_flex flex_gap_xs flex_align_center">
                                <LightTooltip
                                    title={
                                        ozoner.truoraData
                                            ? validTruora
                                                ? "Validación de identidad"
                                                : "Falló en la validación de identidad"
                                            : "No se ha realizado la validación de identidad"
                                    }
                                >
                                    <div
                                        className={`br_xs overflow_hidden display_flex flex_align_center ${
                                            ozoner.truoraData
                                                ? `bg_${
                                                      validTruora
                                                          ? "secondary_600"
                                                          : "red_300"
                                                  }_nocontrast text_neutral_0`
                                                : "bg_neutral_600_nocontrast text_neutral_800"
                                        }`}
                                    >
                                        <Typography
                                            scale="small"
                                            weight="600"
                                            className="p_y_xxxs p_x_sm flex_grow_1 text_center platforms_text"
                                        >
                                            Truora
                                        </Typography>
                                        {ozoner.truoraData && (
                                            <div
                                                className={`p_x_xs ${
                                                    validTruora
                                                        ? "truora_trigger"
                                                        : "bg_red_400_nocontrast"
                                                } flex_center cursor_pointer flex_align_self_stretch`}
                                                role="button"
                                                tabIndex={0}
                                                onClick={() => {
                                                    setOpenTruora(true);
                                                    handleEventGoogle(
                                                        "BTN_Pb_request_truora",
                                                        "Panda Solicitudes",
                                                        "click in truora button "
                                                    );
                                                }}
                                                onKeyPress={() => {
                                                    setOpenTruora(true);
                                                    handleEventGoogle(
                                                        "BTN_Pb_request_truora",
                                                        "Panda Solicitudes",
                                                        "click in truora button "
                                                    );
                                                }}
                                            >
                                                <Eye className="dim_md text_neutral_0" />
                                            </div>
                                        )}
                                    </div>
                                </LightTooltip>
                                <div
                                    className={`br_xs overflow_hidden display_flex flex_align_center ${
                                        ozoner.palencaData &&
                                        Object.keys(ozoner.palencaData).length
                                            ? "bg_green_300_nocontrast text_neutral_0"
                                            : "bg_neutral_600_nocontrast text_neutral_800"
                                    } `}
                                >
                                    <Typography
                                        scale="small"
                                        weight="600"
                                        className="p_y_xxs p_x_sm flex_grow_1 text_center platforms_text"
                                    >
                                        Palenca
                                    </Typography>
                                    {ozoner.palencaData &&
                                    Object.keys(ozoner.palencaData).length ? (
                                        <div
                                            className="p_x_xs palenca_trigger flex_align_self_stretch flex_center cursor_pointer"
                                            role="button"
                                            tabIndex={0}
                                            onClick={() => {
                                                setOpenPalenca(true);
                                                handleEventGoogle(
                                                    "BTN_Pb_request_palenca",
                                                    "Panda Solicitudes",
                                                    "click in palenca button "
                                                );
                                            }}
                                            onKeyPress={() => {
                                                setOpenPalenca(true);
                                                handleEventGoogle(
                                                    "BTN_Pb_request_palenca",
                                                    "Panda Solicitudes",
                                                    "click in palenca button "
                                                );
                                            }}
                                        >
                                            <Eye className="dim_md text_neutral_0 " />
                                        </div>
                                    ) : (
                                        <LightTooltip title="Copiar link de palenca">
                                            <div
                                                className="p_x_xs palenca_trigger flex_align_self_stretch flex_center cursor_pointer"
                                                role="button"
                                                tabIndex={0}
                                                onClick={() => {
                                                    getPalencaLink();
                                                    handleEventGoogle(
                                                        "BTN_Pb_request_palenca",
                                                        "Panda Solicitudes",
                                                        "click in palenca button "
                                                    );
                                                }}
                                                onKeyPress={() => {
                                                    getPalencaLink();
                                                    handleEventGoogle(
                                                        "BTN_Pb_request_palenca",
                                                        "Panda Solicitudes",
                                                        "click in palenca button "
                                                    );
                                                }}
                                            >
                                                <Copy className="dim_md text_neutral_0" />
                                            </div>
                                        </LightTooltip>
                                    )}
                                </div>
                                {financialForm?.form?.workInDigitalPlatforms ? (
                                    <div className="flex_center flex_gap_xs">
                                        <Helmet className="dim_lg text_primary_300" />
                                        <div>
                                            <Typography
                                                weight="600"
                                                scale="xxsmall"
                                                textColor="neutral_700"
                                            >
                                                Trabajador de
                                            </Typography>
                                            <Typography
                                                weight="600"
                                                scale="xsmall"
                                                textColor="primary_300"
                                            >
                                                Plataformas Digitales
                                            </Typography>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <div
                        id="messages"
                        className="p_md br_xs bg_neutral_200 display_flex flex_align_center flex_gap_md"
                    >
                        <div className="messages_cont flex_center flex_gap_md">
                            <div
                                className="br_circle cursor_pointer messages_item"
                                tabIndex={0}
                                role="button"
                                onKeyDown={() => goWA(ozoner.phone)}
                                onClick={() => goWA(ozoner.phone)}
                            >
                                <div className="dim_xxl br_circle border_1 border_solid border_green_300 bg_green_100 flex_center pos_relative">
                                    <Whatsapp className="dim_lg text_green_300 border_green_300 messages_icon" />

                                    <div className="messages_title p_y_xxxs p_x_sm bg_neutral_0 br_xs">
                                        <Typography
                                            weight="600"
                                            scale="xxsmall"
                                            textColor="neutral_700"
                                        >
                                            Whatsapp
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="br_circle cursor_pointer messages_item"
                                tabIndex={0}
                                role="button"
                                onKeyDown={() => {
                                    window.location.href = `mailto:${ozoner.email}`;
                                }}
                                onClick={() => {
                                    window.location.href = `mailto:${ozoner.email}`;
                                }}
                            >
                                <div className="dim_xxl br_circle border_1 border_solid border_primary_300 bg_primary_25 flex_center pos_relative">
                                    <Mail className="dim_lg text_primary_300 border_primary_300 messages_icon" />
                                    <div className="messages_title p_y_xxxs p_x_sm bg_neutral_0 br_xs">
                                        <Typography
                                            weight="600"
                                            scale="xxsmall"
                                            textColor="neutral_700"
                                        >
                                            Correo
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Button
                            scale="small"
                            variant="tint"
                            icon={<Refresh />}
                            onClick={() => {
                                setOpenHistory(true);
                                handleEventGoogle(
                                    "BTN_Pb_ozoner_historic_changes",
                                    "Panda Ozoners",
                                    "click in historic changes ozoner button"
                                );
                            }}
                            className="shadow_none"
                        >
                            Historial de cambios
                        </Button>
                    </div>
                    <div id="credit-selection" className="pos_relative">
                        <div
                            className="pos_relative cursor_pointer"
                            onClick={() => setOpenCredits(!openCredits)}
                            role="button"
                            tabIndex={0}
                            onKeyDown={() => setOpenCredits(!openCredits)}
                        >
                            {CreditOption(financialForm, handleDetailsVeh)}
                            <Down
                                className={`dim_lg open_credits ${
                                    openCredits ? "open" : ""
                                }`}
                            />
                        </div>
                        <div
                            className={`credits_section shadow_hard bg_neutral_0 ${
                                openCredits ? "open" : ""
                            }`}
                        >
                            {financialForms
                                ?.filter(
                                    (form: any) =>
                                        financialForm?.form?._id !==
                                        form?.form?._id
                                )
                                .map((form: any) =>
                                    CreditOption(form, handleDetailsVeh, () =>
                                        changeForm(form?.form?._id)
                                    )
                                )}
                            <div
                                className="p_xl flex_gap_xs border_solid border_1 border_neutral_400 flex_center cursor_pointer"
                                role="button"
                                tabIndex={0}
                                onClick={createNewForm}
                                onKeyDown={createNewForm}
                            >
                                <Typography
                                    weight="600"
                                    scale="medium"
                                    textColor="neutral_900"
                                    className=""
                                >
                                    Nueva solicitud de crédito
                                </Typography>
                                <Add className="dim_lg" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="display_flex">
                    <Typography
                        scale="medium"
                        weight="600"
                        onClick={() => {
                            setTab(0);
                        }}
                        className={`cursor_pointer text_center p_x_md p_y_xs ozoner_tab ${
                            tab === 0 ? "active" : ""
                        }`}
                    >
                        Datos
                    </Typography>
                    <Typography
                        scale="medium"
                        weight="600"
                        onClick={() => {
                            setTab(1);
                        }}
                        className={`cursor_pointer text_center p_x_md p_y_xs ozoner_tab ${
                            tab === 1 ? "active" : ""
                        }`}
                    >
                        Documentos
                    </Typography>
                    <Typography
                        scale="medium"
                        weight="600"
                        onClick={() => {
                            setTab(2);
                        }}
                        className={`cursor_pointer text_center p_x_md p_y_xs ozoner_tab ${
                            tab === 2 ? "active" : ""
                        }`}
                    >
                        {financialForm?.credit?.status &&
                        financialForm?.credit?.status !== CreditStatus.pending
                            ? "Financiero"
                            : "Solicitud"}
                    </Typography>
                    {financialForm?.credit?.status &&
                        financialForm?.credit?.status !==
                            CreditStatus.pending && (
                            <Typography
                                scale="medium"
                                weight="600"
                                onClick={() => {
                                    setTab(3);
                                }}
                                className={`cursor_pointer text_center p_x_md p_y_xs ozoner_tab ${
                                    tab === 3 ? "active" : ""
                                }`}
                            >
                                Cobranza
                            </Typography>
                        )}
                    {financialForm?.credit &&
                        financialForm?.credit.status !== "pending" && (
                            <Typography
                                scale="medium"
                                weight="600"
                                onClick={() => {
                                    setTab(4);
                                }}
                                className={`cursor_pointer text_center p_x_md p_y_xs ozoner_tab ${
                                    tab === 4 ? "active" : ""
                                }`}
                            >
                                Crédito
                            </Typography>
                        )}
                </div>
                <div className="p_sm border_1 border_solid border_neutral_400 bg_neutral_100">
                    {tab === 0 && (
                        <DataOzoner
                            handleDetailsVeh={handleDetailsVeh}
                            openEditOzoner={openEditOzoner}
                        />
                    )}
                    {tab === 1 && <DocumentsOzoner />}
                    {tab === 2 && (
                        <SolicitudOzoner
                            openEditOzoner={openEditOzoner}
                            handleDetailsVeh={handleDetailsVeh}
                        />
                    )}
                    {tab === 3 && <CollectionOzoner />}
                    {tab === 4 && (
                        <CreditOzoner
                            setOpenStatusHistoric={setOpenStatusHistory}
                        />
                    )}
                </div>
            </div>
            {openVehicle && (
                <SideModal open={openVehicle} setOpen={setOpenVehicle}>
                    <div className="pos_relative h_100_per">
                        <Inventory
                            onClick={(v: any) => {
                                updateStatusSolicitud(null, v.internalId);
                                setOpenVehicle(false);
                            }}
                            initialFilter={["available"]}
                            availableFilters={{
                                available: true,
                                delivered: false,
                                aside: false,
                                lost: false,
                                inRecovery: false,
                                underMaintenance: false,
                                draft: false,
                                left: false,
                                recovered: false,
                            }}
                        />
                    </div>
                </SideModal>
            )}
            {openDetails && editData && (
                <EditOzoner
                    open={openDetails}
                    setOpen={setOpenDetails}
                    ozoner={ozoner}
                    form={editData?.form ? undefined : financialForm?.form}
                    setResult={setResult}
                    label={editData?.label}
                    sections={editData?.sections}
                    hasCredit={hasCredit}
                />
            )}
            {openModal && (
                <Modal
                    handleClose={() => setOpenModal(false)}
                    openConfirmation={openModal}
                    title={modalData[modalOption]?.title}
                    subtitle={modalData[modalOption]?.subtitle}
                    icon={modalData[modalOption]?.icon}
                    actions={[
                        {
                            label: "Volver",
                            color: "grey",
                            action: () => setOpenModal(false),
                        },
                        {
                            label: "Aceptar",
                            color: "primary",
                            action: modalData[modalOption]?.action,
                            loading: loadingModal,
                        },
                    ]}
                />
            )}

            {openHistory && ozoner?._id && (
                <History
                    entityId={ozoner._id}
                    open={openHistory}
                    setOpen={setOpenHistory}
                />
            )}
            {openStatusHistory && financialForm?.credit?._id && (
                <HistoryStatus
                    historic={financialForm.credit.statusHistoric ?? []}
                    open={openStatusHistory}
                    setOpen={setOpenStatusHistory}
                    ozonios={ozonios}
                    onChange={changeHistoryDate}
                />
            )}

            <TruoraDetails
                open={openTruora}
                setOpen={setOpenTruora}
                truoraData={ozoner?.truoraData}
                ozonerDocuments={ozoner?.documents}
            />
            <PalencaDetails
                open={openPalenca}
                setOpen={setOpenPalenca}
                palencaData={ozoner?.palencaData}
                getPalencaLink={getPalencaLink}
            />
            {openCreditChange && (
                <Modal
                    openConfirmation={openCreditChange}
                    handleClose={() => setOpenCreditChange(false)}
                    onConfirmationCallBack={updateCreditTime}
                    title={
                        <Typography scale="large" weight="400">
                            ¿Seguro que quieres{" "}
                            <Typography
                                scale="large"
                                weight="600"
                                component="span"
                            >
                                cambiar el plan de pago del ozoner?
                            </Typography>
                        </Typography>
                    }
                    subtitle={changeCreditLabel()}
                    copyConfirm="Aceptar cambio"
                    copyReject="Cancelar"
                    icon={<Reload />}
                />
            )}
        </>
    ) : null;
};

export default DetailsOzoner;
