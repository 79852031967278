/* eslint-disable no-unneeded-ternary */
import React, { FC, useState } from "react";
import  Button  from "../../Button/Button";
import  Popup  from "../../Popup/Popup";
import Calendar from "../../Calendar/Calendar";
import { ReactComponent as CalendarIcon } from "../../../static/icons/date.svg";

const formatDate = (date: Date | null) => date ? `${date.getFullYear()}-${date.getMonth() + 1 < 10 ? 0 : ""}${date.getMonth() + 1}-${date.getDate() < 10 ? 0 : ""}${date.getDate()}` : "";

interface DateInputProps extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
> {
    value?: any
    onChange?: any
    className?: string;
}
const DateInput: FC<DateInputProps> = ({ value = "", onChange, name = "", className }) => {
    const [open, setOpen] = useState<boolean>(false);
    const [month, setMonth] = useState<Date>(new Date());
    const setDay = (day: Date) => {
        const sday=day ? day : new Date();
        setMonth(sday);
        setOpen(false);
        if (onChange)
            onChange({
                target: {
                    name,
                    value: formatDate(sday)
                }
            });
    };
    return (
        <>
            <Button className={` ${className} w_100_per`} scale="small" variant="outline" icon={<CalendarIcon />} onClick={() => setOpen(!open)}>{value?value.substring(0,10):"Seleccione fecha"}</Button>
            <Popup open={open} setOpen={setOpen }>
                <Calendar day={new Date(`${value}T00:00:00`)} setDay={setDay} month={month} setMonth={setMonth} />
            </Popup>
        </>
    );
};

export default DateInput;
