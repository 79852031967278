/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";

// icons
import { ReactComponent as AddCircle } from "design_system/src/static/icons/add-circle.svg";
import { ReactComponent as Recipt } from "design_system/src/static/icons/invoice.svg";
import { ReactComponent as Document } from "design_system/src/static/icons/document.svg";
import { ReactComponent as Pin } from "design_system/src/static/icons/pin.svg";
import { ReactComponent as Action } from "design_system/src/static/icons/more-v.svg";
import { ReactComponent as List } from "design_system/src/static/icons/bulleted-list-2.svg";
import { ReactComponent as Boxes } from "design_system/src/static/icons/box_2.svg";
import { ReactComponent as Excel } from "design_system/src/static/icons/excel.svg";
import { ReactComponent as Monitor } from "design_system/src/static/icons/monitor.svg";
import { ReactComponent as Panda } from "design_system/src/static/icons/panda.svg";

import motoLoading from "static/icons/loading_moto.gif";

import { LightTooltip } from "components/hocs/tooltip/Tooltip";
import {
    Button,
    Typography,
    Pager,
    ModalConfirmation as Modal,
    Select,
    DateSelect,
    Popup,
    ListTable,
} from "design_system/src";
import Divider from "@mui/material/Divider";

// css
import moment from "moment";
import "moment/locale/es";

import "./ozocio.scss";
import CardOzoner from "components/hocs/cardozoner/CardOzoner";
import {
    getOzoners,
    getAgreements,
    getExportAgreements,
} from "helpers/fetchs/ozoners";
import profileDefaultOzoner from "static/images/default_profile_ozoner.png";

import {
    getOzonerFilter,
    setOzonerFilter,
} from "helpers/localStorage/filterStorage";
import { useLocation, useNavigate } from "react-router-dom";
import { priceFormatFinancial, priceMXNTotal } from "helpers/prices/prices";
import SaveFilter from "components/hocs/saveFilter/SaveFilter";
import {
    addUserFilter,
    deleteUserFilter,
    getOzonioById,
} from "helpers/fetchs/ozonios";
import { getUser } from "helpers/localStorage/authStorage";
import { FilterSections, UserFilter } from "models/ozonio.interface";
import { isArray } from "lodash";
import { DocumentTypesEnum } from "components/hocs/loadDocuments/loadDocuments";
import {
    TOzocio,
    StatusOfferVehicle,
    StatusOfferVehicleValues,
    TOzocioOneOffer,
} from "models/ozocio.interface";
import { FetchOzocios } from "helpers/fetchs/ozocios";
import { handleEventGoogle } from "helpers/analytics/googleAnalytics";
import EditOzoner from "./EditOzocio";
import ExportOzocios from "./ExportOzocios";
import SearchBar from "../../components/hocs/searchBar/searchBar";

const createdOnOptions = [
    {
        label: "Pandaboard",
        color: "primary_200",
        value: "pandaboard",
    },
    {
        label: "Webpage",
        color: "yellow_100",
        value: "webpage",
    },
];
let petition: number;
const getStatusFilterName = (status: string) =>
    `statusPersonalFilter_${status}`;

const sortOptions = [
    {
        value: "createdAt",
        label: "Más antiguos",
    },
    {
        value: "-createdAt",
        label: "Más recientes",
    },
];

function Ozoner() {
    const navigate = useNavigate();

    const [viewType, setViewType] = useState(true);

    const [openDetails, setOpenDetails] = useState<boolean>(false);
    const [openExport, setOpenExport] = useState<boolean>(false);
    const [ozocios, setOzocios] = useState<TOzocioOneOffer[] | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [inputQuery, setInputQuery] = useState<string>("");
    const [query, setQuery] = useState<string>("");

    // -----------------FILTERS STATES------------------- //
    const [sortValue, setSortValue] = useState("-createdAt");
    const [openConfirmFilter, setOpenConfirmFilter] = useState(false);
    const [callbackConfirm, setCallbackConfirm] = useState<any>();
    const [openOptions, setOpenOptions] = useState("");
    const [personalFilters, setPersonalFilters] = useState<UserFilter[]>([]);
    const [selectedPersonalFilter, setSelectedPersonalFilter] =
        useState<string>();
    const [openSaveFilter, setOpenSaveFilter] = useState<boolean>(false);
    const [createdOnFilter, setCreatedOnFilter] = useState([]);
    const [statusFilter, setStatusFilter] = useState<string[]>([]);

    const [datesFilter, setDatesFilter] = useState({
        initial: new Date(),
        last: new Date(),
        option: -1,
    });
    const [hasDatesFilter, setHasDatesFilter] = useState(false);
    const [count, setCount] = useState(0);

    const requestFilters = () => {
        const userL = getUser();
        getOzonioById(userL.userId).then((data) => {
            if (data?.personalFilters) {
                setPersonalFilters(
                    data.personalFilters.filter(
                        (fil: UserFilter) =>
                            fil.section === FilterSections.ozociosList
                    )
                );
            }
        });
    };
    const changePersonalFilter = (uFilter: UserFilter) => {
        if (uFilter._id !== selectedPersonalFilter) {
            setSelectedPersonalFilter(uFilter._id);
            setCreatedOnFilter(uFilter.values.createdOnFilter ?? []);
            setStatusFilter(uFilter.values.statusFilter ?? []);
            if (uFilter.values.datesFilter) {
                setDatesFilter({
                    ...uFilter.values.datesFilter,
                    initial: new Date(uFilter.values.datesFilter.initial),
                    last: new Date(uFilter.values.datesFilter.last),
                });
                setHasDatesFilter(true);
            } else {
                setHasDatesFilter(false);
            }
        }
    };

    const setStatusPersonalFilter = (value: string) => {
        const name = getStatusFilterName(value);
        setSelectedPersonalFilter(name);
        setCreatedOnFilter([]);
        setHasDatesFilter(false);
        setStatusFilter([value]);
    };

    const deletePersonalFilter = (uFilter: UserFilter) => {
        deleteUserFilter(getUser().userId, uFilter._id).then(() => {
            setSelectedPersonalFilter("");
            requestFilters();
        });
    };

    const confirmChangeFilter = () => {
        const { type, value } = callbackConfirm;
        if (type) {
            setStatusPersonalFilter(value);
        } else {
            changePersonalFilter(value);
        }
    };

    const changingFilter = (type: boolean, value: any) => {
        if (
            selectedPersonalFilter ||
            (!createdOnFilter.length && !statusFilter.length && !hasDatesFilter)
        ) {
            if (type) {
                setStatusPersonalFilter(value);
            } else {
                changePersonalFilter(value);
            }
        } else {
            setCallbackConfirm({ type, value });
            setOpenConfirmFilter(true);
        }
    };

    const saveFilter = (name: string) => {
        return addUserFilter(
            getUser().userId,
            name,
            FilterSections.ozociosList,
            {
                statusFilter,
                createdOnFilter,
                datesFilter: hasDatesFilter ? datesFilter : undefined,
            }
        ).then((e: any) => {
            requestFilters();
            setSelectedPersonalFilter(
                e.personalFilters[e.personalFilters.length - 1]._id
            );
            return e;
        });
    };

    useEffect(() => {
        handleEventGoogle(
            "VIEW_Panda_ozocios",
            "Panda",
            "page view to the ozocios list view"
        );
        petition = 0;
        const information = getOzonerFilter();
        if (information.query) {
            setQuery(information.query);
            setInputQuery(information.query);
        }
        if (information.currentPage) {
            setCurrentPage(information.currentPage);
        }
        if (information.selectedPersonalFilter) {
            setSelectedPersonalFilter(information.selectedPersonalFilter);
        }
        if (information.createdOnFilter) {
            setCreatedOnFilter(information.createdOnFilter);
        }
        if (information?.datesFilter) {
            setDatesFilter({
                ...information.datesFilter,
                initial: new Date(information.datesFilter.initial),
                last: new Date(information.datesFilter.last),
            });

            setHasDatesFilter(true);
        }
        if (information.statusFilter) {
            setStatusFilter(information.statusFilter);
        } else {
            setStatusFilter([]);
        }
        requestFilters();
    }, []);

    const requestOzocios = () => {
        setLoading(true);
        const num = petition + 1;
        petition += 1;

        const filters = [];

        if (
            createdOnFilter.length &&
            createdOnFilter.length !== createdOnOptions.length
        )
            filters.push(`createdOn=${createdOnFilter}`);
        if (
            statusFilter.length &&
            statusFilter.length !== Object.keys(StatusOfferVehicleValues).length
        )
            filters.push(`status=${statusFilter}`);

        if (datesFilter && hasDatesFilter) {
            filters.push(
                `minCreatedAt=${moment
                    .utc(datesFilter.initial)
                    .format("YYYY-MM-DD")}&maxCreatedAt=${moment
                    .utc(datesFilter.last)
                    .format("YYYY-MM-DD")}`
            );
        }
        if (sortValue && sortValue !== "") {
            filters.push(`sort=${sortValue}`);
        }

        FetchOzocios(
            currentPage,
            query,
            undefined,
            undefined,
            undefined,
            filters.join("&")
        )
            .then((res) => {
                if (res && num === petition) {
                    setLoading(false);
                    setOzocios(res.data);
                    setCount(res.count);
                    setTotalPages(res.pages);
                    if (res.pages < currentPage) {
                        setCurrentPage(1);
                    }
                }
            })
            .catch(() => {
                setLoading(false);
                setOzocios([]);
            });
    };
    useEffect(() => {
        if (currentPage === 1){
            requestOzocios();
        }
        else{
            setCurrentPage(1);
        }
    }, [
        statusFilter,
        query,
        createdOnFilter,
        datesFilter,
        hasDatesFilter,
        sortValue,
    ]);
    useEffect(() => {
        setOzocios([]);
        requestOzocios();
    }, [
        currentPage,
    ]);

    useEffect(() => {
        setOzonerFilter({
            statusFilter,
            query,
            currentPage,
            createdOnFilter,
            datesFilter: hasDatesFilter ? datesFilter : null,
            selectedPersonalFilter,
        });
    }, [
        statusFilter,
        query,
        currentPage,
        createdOnFilter,
        datesFilter,
        hasDatesFilter,
        selectedPersonalFilter,
    ]);

    const handleDetailsOzo = (id: string, offerId: string) => {
        navigate(`/ozocio/${id}?offerId=${offerId}`);
    };

    const handleSearch = (event: any) => {
        event.preventDefault();
        setQuery(inputQuery);
    };

    return (
        <div className="dim_100_per flex_content_col shadow_hard inner_container bg_neutral_400">
            <div className="flex_header">
                <div className="bg_neutral_0 shadow_hard p_t_xs p_b_md p_x_lg">
                    <div className="display_flex flex_gap_sm flex_align_center">
                        <Typography
                            scale="heading3"
                            weight="600"
                            textColor="neutral_900"
                        >
                            Ofertas de Ozocios
                        </Typography>
                        <SearchBar
                            className="flex_grow_1"
                            handleSearch={handleSearch}
                            query={inputQuery}
                            setQuery={setInputQuery}
                            sortOptions={sortOptions}
                            sortValue={sortValue}
                            setsortValue={setSortValue}
                        />
                    </div>
                    <div className="display_flex flex_gap_xl m_b_xs">
                        <div className="display_flex flex_gap_xl flex_grow_1">
                            <Select
                                fixedPlaceholder
                                variant="checkbox"
                                placeholder="Estado"
                                options={Object.keys(
                                    StatusOfferVehicleValues
                                ).map((key: any) => ({
                                    label: StatusOfferVehicleValues[
                                        key as StatusOfferVehicle
                                    ].title,
                                    value: key,
                                }))}
                                value={statusFilter}
                                onChange={(e: any) => {
                                    setStatusFilter(e.target.value);
                                    setSelectedPersonalFilter("");
                                }}
                            />
                            <Select
                                fixedPlaceholder
                                variant="checkbox"
                                placeholder="Origen"
                                options={createdOnOptions}
                                value={createdOnFilter}
                                onChange={(e: any) => {
                                    setCreatedOnFilter(e.target.value);
                                    setSelectedPersonalFilter("");
                                }}
                            />
                            <DateSelect
                                value={datesFilter}
                                hasValue={hasDatesFilter}
                                setHasValue={setHasDatesFilter}
                                placeholder="Fecha de creación"
                                setValue={(e: any) => {
                                    setDatesFilter(e);
                                    setHasDatesFilter(true);
                                    setSelectedPersonalFilter("");
                                }}
                            />
                        </div>
                        <div className="display_flex flex_gap_xs flex_grow_0 flex_align_center">
                            <Button
                                variant="ghost"
                                scale="small"
                                icon={<Pin />}
                                onClick={() => setOpenSaveFilter(true)}
                            >
                                Fijar filtro
                            </Button>
                            <LightTooltip title="Vista de tabla">
                                <div
                                    className={`selectable_icon ${
                                        viewType ? "active" : ""
                                    }`}
                                    role="button"
                                    tabIndex={0}
                                    onClick={() => setViewType(true)}
                                    onKeyDown={() => setViewType(true)}
                                >
                                    <List className="dim_lg" />
                                </div>
                            </LightTooltip>
                            <LightTooltip title="Vista en cards">
                                <div
                                    className={`selectable_icon ${
                                        !viewType ? "active" : ""
                                    }`}
                                    role="button"
                                    tabIndex={0}
                                    onClick={() => setViewType(false)}
                                    onKeyDown={() => setViewType(false)}
                                >
                                    <Boxes className="dim_lg" />
                                </div>
                            </LightTooltip>
                            <LightTooltip title="Exportar ozocios">
                                <div
                                    className="selectable_icon"
                                    role="button"
                                    tabIndex={0}
                                    onClick={() => setOpenExport(true)}
                                    onKeyDown={() => setOpenExport(true)}
                                >
                                    <Excel className="dim_lg" />
                                </div>
                            </LightTooltip>
                        </div>
                    </div>
                    <div className="display_flex flex_gap_xl">
                        {personalFilters.map((uFilter) => (
                            <div
                                className={`display_flex flex_gap_sm p_x_md p_y_xs status_tab pos_relative ${
                                    selectedPersonalFilter === uFilter._id
                                        ? "active"
                                        : ""
                                }
              `}
                            >
                                <Typography
                                    scale="small"
                                    weight={
                                        selectedPersonalFilter === uFilter._id
                                            ? "600"
                                            : "400"
                                    }
                                    onClick={() =>
                                        changingFilter(false, uFilter)
                                    }
                                    key={`status-filter-${uFilter._id}`}
                                    className="cursor_pointer "
                                >
                                    {uFilter.name}
                                </Typography>
                                <Action
                                    className="dim_md cursor_pointer text_neutral_600"
                                    onClick={() => setOpenOptions(uFilter._id)}
                                />
                                <Popup
                                    open={openOptions === uFilter._id}
                                    setOpen={() => setOpenOptions("")}
                                    placementX="right-inset"
                                    className="bg_neutral_100 br_xxs m_r_md"
                                >
                                    <Typography
                                        scale="xsmall"
                                        weight="400"
                                        className="p_xs border_b_solid border_b_1 border_neutral_400 cursor_pointer menu_selectable"
                                        onClick={() =>
                                            deletePersonalFilter(uFilter)
                                        }
                                        onKeyPress={() =>
                                            deletePersonalFilter(uFilter)
                                        }
                                        tabIndex={0}
                                        role="button"
                                    >
                                        Eliminar filtro
                                    </Typography>
                                </Popup>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div
                className={`flex_body ${
                    viewType ? "p_x_lg" : "body_listing"
                } overflow_x_hidden`}
            >
                {loading ? (
                    <div className="flex_center_col dim_100_per">
                        <Typography
                            weight="600"
                            scale="heading4"
                            textColor="neutral_800"
                            className="m_b_xs"
                        >
                            Cargando los ozocios...
                        </Typography>
                        <img src={motoLoading} alt="" className="h_xxxl" />
                    </div>
                ) : ozocios?.length ? (
                    viewType ? (
                        <ListTable
                            data={ozocios as any[]}
                            canSelect={false}
                            rowClassName="br_none"
                            hideHeader
                            className="m_t_md"
                            onRowClick={(ozocio) =>
                                handleDetailsOzo(
                                    ozocio._id,
                                    ozocio.OfferVehicles._id
                                )
                            }
                            cols={[
                                {
                                    label: "Nombre",
                                    weight: 2,
                                    render: (row: any) => (
                                        // eslint-disable-next-line no-nested-ternary
                                        <div className="display_flex flex_align_center flex_gap_md w_100_per">
                                            {row?.createdOn === "pandaboard" ? (
                                                <Panda className="dim_md text_primary_300" />
                                            ) : (
                                                <Monitor className="dim_md text_primary_300" />
                                            )}
                                            <div className="flex_grow_1">
                                                <Typography
                                                    scale="medium"
                                                    weight="400"
                                                    className="flex_1 text_no_break"
                                                >
                                                    {row?.name || "-"}
                                                </Typography>
                                                <Typography
                                                    scale="xsmall"
                                                    weight="400"
                                                    className="flex_1"
                                                >
                                                    {row?.phone || "-"}
                                                </Typography>
                                            </div>
                                        </div>
                                    ),
                                },
                                {
                                    label: "Offer",
                                    render: (row: any, index: number) => (
                                        <Typography
                                            scale="medium"
                                            weight="600"
                                            className="text_no_break"
                                        >
                                            {row.OfferVehicles.brand ?? ""}{" "}
                                            {row.OfferVehicles.model ?? ""}{" "}
                                            {row.OfferVehicles
                                                .cylinderCapacity ?? ""}{" "}
                                            {row.OfferVehicles.year ?? ""}{" "}
                                        </Typography>
                                    ),
                                },
                                {
                                    label: "price_Offer",
                                    render: (row: any, index: number) => (
                                        <div className="display_flex flex_align_center flex_col w_100_per">
                                            <Typography
                                                scale="medium"
                                                weight="600"
                                                className="text_no_break"
                                            >
                                                Oferta:{" "}
                                                {priceFormatFinancial(
                                                    row.OfferVehicles
                                                        .amountOffer
                                                )}
                                            </Typography>
                                            <Typography
                                                scale="small"
                                                weight="600"
                                                textColor="neutral_600"
                                                className="text_no_break"
                                            >
                                                Fecha:{" "}
                                                {row.OfferVehicles.createdAt
                                                    ? moment(
                                                          row.OfferVehicles
                                                              .createdAt
                                                      )
                                                          .utcOffset(-360)
                                                          .format("DD/MM/YY")
                                                    : "No registra"}
                                            </Typography>
                                        </div>
                                    ),
                                },
                                {
                                    label: "Estado",
                                    render: (row: any) => (
                                        <LightTooltip
                                            title={
                                                (
                                                    StatusOfferVehicleValues as any
                                                )[row.OfferVehicles.status]
                                                    ?.tooltip ??
                                                "Sin informacion"
                                            }
                                        >
                                            <div>
                                                <Typography
                                                    weight="400"
                                                    scale="medium"
                                                    textColor="neutral_700"
                                                    className="text_center flex_grow_1 flex_basis_0"
                                                >
                                                    <div
                                                        className={`
                                                  dso_chip_small flex_grow_1
                                                  bg_${
                                                      (
                                                          StatusOfferVehicleValues as any
                                                      )[
                                                          row.OfferVehicles
                                                              .status
                                                      ]?.color ?? "neutral_600"
                                                  }_nocontrast
                                                  text_neutral_0 flex_basis_0 overflow_hidden overflow_ellipsis text_center
                                                `}
                                                    >
                                                        {(
                                                            StatusOfferVehicleValues as any
                                                        )[
                                                            row.OfferVehicles
                                                                .status
                                                        ]?.title ??
                                                            "No registrado"}
                                                    </div>
                                                </Typography>
                                            </div>
                                        </LightTooltip>
                                    ),
                                },
                            ]}
                        />
                    ) : (
                        <Grid
                            container
                            direction="row"
                            spacing={3}
                            className="h_100_per p_t_md spaced"
                        >
                            {ozocios && !loading
                                ? ozocios.map((row, index) => (
                                      // eslint-disable-next-line react/no-array-index-key
                                      <Grid
                                          item
                                          xs={6}
                                          sm={3}
                                          md={2}
                                          key={index}
                                      >
                                          <div
                                              className="dso_card flex_center_col flex_justify_between  bg_neutral_0 dim_100_per p_x_xs p_y_sm cursor_pointer ozoner_card"
                                              onClick={() =>
                                                  handleDetailsOzo(
                                                      row._id,
                                                      row.OfferVehicles._id
                                                  )
                                              }
                                              onKeyPress={() =>
                                                  handleDetailsOzo(
                                                      row._id,
                                                      row.OfferVehicles._id
                                                  )
                                              }
                                              role="button"
                                              tabIndex={0}
                                          >
                                              <div className="flex_center_col w_100_per flex_gap_xxs">
                                                  <LightTooltip
                                                      title={`Creado en ${row.createdOn}`}
                                                  >
                                                      <div className="bg_primary_50_nocontrast text_primary_300 display_flex p_xs br_xs shadow_hard">
                                                          {row.createdOn ===
                                                          "pandaboard" ? (
                                                              <Panda className="dim_md" />
                                                          ) : (
                                                              <Monitor className="dim_md" />
                                                          )}
                                                      </div>
                                                  </LightTooltip>
                                                  <Typography
                                                      scale="small"
                                                      weight="600"
                                                      textColor="neutral_1000"
                                                      className="text_no_break"
                                                  >
                                                      {row.OfferVehicles
                                                          .createdAt
                                                          ? moment(
                                                                row
                                                                    .OfferVehicles
                                                                    .createdAt
                                                            )
                                                                .utcOffset(-360)
                                                                .format(
                                                                    "DD/MM/YY"
                                                                )
                                                          : "No registra"}
                                                  </Typography>
                                                  <Typography
                                                      scale="xxsmall"
                                                      weight="600"
                                                      textColor="neutral_600"
                                                      className="text_no_break m_b_xs"
                                                  >
                                                      Fecha de solicitud
                                                  </Typography>
                                                  <Typography
                                                      scale="small"
                                                      weight="600"
                                                      textColor="neutral_1000"
                                                      className="text_center overflow_ellipsis w_100_per"
                                                  >
                                                      {row?.name || "-"}
                                                  </Typography>
                                                  <Typography
                                                      scale="xxsmall"
                                                      weight="400"
                                                      textColor="neutral_700"
                                                      className="text_center m_b_xs"
                                                  >
                                                      {row?.phone || "-"}
                                                  </Typography>
                                                  <Typography
                                                      scale="small"
                                                      weight="600"
                                                      textColor="neutral_1000"
                                                      className="text_center"
                                                  >
                                                      Oferta{" "}
                                                      {row?.OfferVehicles?._id?.slice(-4) ||
                                                          "-"}
                                                  </Typography>
                                                  <Typography
                                                      scale="small"
                                                      weight="400"
                                                      textColor="neutral_1000"
                                                      className="text_center "
                                                  >
                                                      {" "}
                                                      {row.OfferVehicles
                                                          .brand ?? ""}{" "}
                                                      {row.OfferVehicles
                                                          .model ?? ""}{" "}
                                                      {row.OfferVehicles
                                                          .cylinderCapacity ??
                                                          ""}{" "}
                                                      {row.OfferVehicles.year ??
                                                          ""}{" "}
                                                  </Typography>
                                                  <Typography
                                                      scale="xsmall"
                                                      weight="400"
                                                      className="text_no_break m_b_xs"
                                                  >
                                                      Oferta:{" "}
                                                      {priceFormatFinancial(
                                                          row.OfferVehicles
                                                              .amountOffer
                                                      )}
                                                  </Typography>
                                                  <LightTooltip
                                                      title={
                                                          (
                                                              StatusOfferVehicleValues as any
                                                          )[
                                                              row.OfferVehicles
                                                                  .status
                                                          ]?.tooltip ??
                                                          "Sin informacion"
                                                      }
                                                  >
                                                      <div>
                                                          <Typography
                                                              weight="400"
                                                              scale="medium"
                                                              textColor="neutral_700"
                                                              className="text_center flex_grow_1 flex_basis_0"
                                                          >
                                                              <div
                                                                  className={`
                                                  dso_chip_small flex_grow_1
                                                  bg_${
                                                      (
                                                          StatusOfferVehicleValues as any
                                                      )[
                                                          row.OfferVehicles
                                                              .status
                                                      ]?.color ?? "neutral_600"
                                                  }_nocontrast
                                                  text_neutral_0 flex_basis_0 overflow_hidden overflow_ellipsis text_center
                                                `}
                                                              >
                                                                  {(
                                                                      StatusOfferVehicleValues as any
                                                                  )[
                                                                      row
                                                                          .OfferVehicles
                                                                          .status
                                                                  ]?.title ??
                                                                      "No registrado"}
                                                              </div>
                                                          </Typography>
                                                      </div>
                                                  </LightTooltip>
                                              </div>
                                          </div>
                                      </Grid>
                                  ))
                                : new Array(12)
                                      .fill("", 0, 12)
                                      .map((i, index) => (
                                          // eslint-disable-next-line react/no-array-index-key
                                          <Grid
                                              item
                                              xs={6}
                                              sm={3}
                                              md={2}
                                              key={index}
                                          >
                                              <CardOzoner loading />
                                          </Grid>
                                      ))}
                        </Grid>
                    )
                ) : (
                    <div className="flex_center dim_100_per">
                        <Typography
                            scale="heading4"
                            weight="600"
                            className="text_neutral_900 m_xl text_center"
                        >
                            Tus filtros no generaron resultados
                        </Typography>
                    </div>
                )}
            </div>

            <div className="display_flex flex_align_center flex_justify_between flex_footer bg_neutral_0 p_x_xxxl p_y_md">
                <Button
                    scale="small"
                    icon={<AddCircle />}
                    className="w_30_per"
                    onClick={() => setOpenDetails(true)}
                >
                    Añadir Oferta
                </Button>
                <Pager
                    totalPages={totalPages}
                    setPage={setCurrentPage}
                    page={currentPage}
                    count={count}
                    className="w_fit"
                />
            </div>
            <EditOzoner
                open={openDetails}
                setOpen={setOpenDetails}
                setResult={() => {
                    setOzocios([]);
                    requestOzocios();
                }}
            />
            {openExport && (
                <ExportOzocios open={openExport} setOpen={setOpenExport} />
            )}
            {(openSaveFilter || openConfirmFilter) && (
                <SaveFilter
                    open={openSaveFilter}
                    setOpen={setOpenSaveFilter}
                    callback={saveFilter}
                    openConfirm={openConfirmFilter}
                    setOpenConfirm={setOpenConfirmFilter}
                    callbackConfirm={confirmChangeFilter}
                />
            )}
        </div>
    );
}

export default Ozoner;
