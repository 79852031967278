import { TBrand } from "./brand.interface";
import { TCylinderCapacity } from "./cylindercapacity.interface";
import { TModel } from "./model.interface";

export interface TDetails {
  milage: number;
  changes: string;
  note: string;
  suspension: boolean;
  size: string;
  year: number;
}

export interface TCountry {
  _id: string;
  tax_name: string;
  tax: number;
  currency: string;
  iso: string;
  deposit: number;
  insurance: number;
  deliveryPrice: number;
  name: string;
}

export interface TCity {
  _id: string;
  country: string;
  name: string;
}

export interface THub {
  _id: string;
  name: string;
  city: string;
}

export interface ISalePrices {
  weeks: number;
  paymentWeek: number;
  interestRate: number;
  capital: number;
  interest: number;
}

// eslint-disable-next-line no-shadow
export enum MaintenanceColor {
  GREEN = "green",
  YELLOW = "yellow",
  BLUE = "blue",
  RED = "red",
  BLACK = "black",
}

// eslint-disable-next-line no-shadow
export enum VisualStatus {
  good = "good",
  regular = "regular",
  bad = "bad",
  bone = "bone",
}
// eslint-disable-next-line no-shadow
export enum DocumentalStatus {
  digital = "digital",
  complete = "complete",
  incomplete = "incomplete",
}

export const MaintenanceColorValues:{[key in MaintenanceColor]:{label:string,days?:number,color:string}} = {
  [MaintenanceColor.GREEN]: { label: "Verde", days: 0, color:"green_300" },
  [MaintenanceColor.YELLOW]:{ label: "Amarillo", days: 2, color:"yellow_100" },
  [MaintenanceColor.BLUE]:{ label: "Azul", days: 3, color:"yellow_100" },
  [MaintenanceColor.RED]:{ label: "Rojo",days: 7, color: "red_300"},
  [MaintenanceColor.BLACK]:{ label: "Negro", color: "neutral_1000"},
};

export const VisualStatusValues:{[key in VisualStatus]:{label:string}}={
  [VisualStatus.good]: { label:"Bueno"},
  [VisualStatus.regular]: { label:"Regular"},
  [VisualStatus.bad]: { label:"Malo"},
  [VisualStatus.bone]: { label:"Hueso"},
};

export const DocumentalStatusValues:{[key in DocumentalStatus]:{label:string}}={
  [DocumentalStatus.digital]: { label:"Completa Digital"},
  [DocumentalStatus.complete]: { label:"Completa"},
  [DocumentalStatus.incomplete]: { label:"Incompleta"},
};

export interface TVehicle {
  createdAt?: string;
  description: string;
  detail: string;
  warranty: string;
  salePrices: any;
  hub: THub;
  _id: string;
  city: TCity;
  country: TCountry;
  owner: string;
  forSale: boolean;
  depositPrice: number;
  deposit: boolean;
  insurance: boolean;
  status: string;
  type: string;
  score: number;
  insurancePrice: number;
  oldPrice: number;
  externalId: string;
  internalId: string;
  images: any[];
  extraCost: number;
  vehicleSN: string;
  registrationCard: string;
  engineSN: string;
  brakesCondition: string;
  plate: string;
  tankSize: string;
  rentPrice: number;
  purchaseCost: number;
  condition: string;
  color: string;
  secondaryColor: string;
  suffix: string;
  purchaseDate: string;
  details: TDetails;
  cylindersCapacity: TCylinderCapacity;
  model: TModel;
  brand: TBrand;
  creditTime: number[];
  documents?: any[];
  discounts: any[];
  factorKM: string | number
  blueBookPrice: string | number
  gpsStatus: string
  maintenanceColor: MaintenanceColor
  maintenanceDays: number
  confirmationKM: boolean
  platedVehicle: boolean;
  newVehicle: boolean;
  isPresale: boolean;
  hasTileDevice: boolean;

  gpsHardwareCost?:  number;
  gpsMonthlySoftwareCost?:  number;
  repairsPrice?: number;
  debtsPrice?: number;
  visualStatus?:VisualStatus;
  documentalStatus?: DocumentalStatus;
}

export interface TEditVehicle {
  gpsHardwareCost?: string | number;
  gpsMonthlySoftwareCost?: string | number;
repairsPrice?:string | number;
debtsPrice?:string | number;
visualStatus?:VisualStatus;
documentalStatus?: DocumentalStatus;
  platedVehicle: boolean;
  newVehicle: boolean;
  isPresale: boolean;
  hasTileDevice: boolean;
  _id?: string;
  description: string;
  detail: string;
  warranty: string;
  internalId: string;
  brand: string;
  model: string;
  color: string;// "blue"|"red"|"green";
  secondaryColor: string;// "blue"|"red"|"green";
  suffix: string;
  cylindersCapacity: string;
  plate: string;
  engineSN: string;
  registrationCard: string;
  vehicleSN: string;
  extraCost: string | number;
  purchaseCost: string | number;
  purchaseDate: string;
  year: string;
  milage: string | number;
  // creditTime: number[];
  creditCalc: any[];
  hub: string;
  brakeType: string;

  factorKM: string | number
  blueBookPrice: string | number
  gpsStatus: string
  maintenanceColor?: MaintenanceColor
  maintenanceDays: string | number
  confirmationKM: boolean
  isContado?: boolean
}
export interface TRequestvehicle {
  totals: any;
  count: number;
  pages: number;
  page: number;
  data: TVehicle[];
}
export interface TRequestBrand {
  count: number;
  pages: number;
  page: number;
  data: TBrand[];
}
export interface TRequestModel {
  count: number;
  pages: number;
  page: number;
  data: TModel[];
}
export interface TRequestCylinderCapacity {
  count: number;
  pages: number;
  page: number;
  data: TCylinderCapacity[];
}

// eslint-disable-next-line no-shadow
export enum statusVehiclesEnum {
  available = "available",
  aside = "aside",
  delivered = "delivered",
  underMaintenance = "underMaintenance",
  draft = "draft",
  lost = "lost",
  recovered = "recovered",
  inRecovery ="inRecovery",
  left = "left"
}

export const statusVehicles = {
  [statusVehiclesEnum.draft]: {
    title: "Draft",
    color: "primary_200",
    availableStatus: [
      statusVehiclesEnum.available,
      statusVehiclesEnum.left
    ]
  },
  [statusVehiclesEnum.available]: {
    title: "Disponible",
    color: "primary_300",
    availableStatus: [
      statusVehiclesEnum.underMaintenance,
      statusVehiclesEnum.left
    ]
  },
  [statusVehiclesEnum.delivered]: {
    title: "Entregada",
    color: "green_300",
    availableStatus: [
      statusVehiclesEnum.lost,
      statusVehiclesEnum.inRecovery,
    ]
  },
  [statusVehiclesEnum.aside]: {
    title: "Apartada",
    color: "secondary_500",
    availableStatus: []
  },
  [statusVehiclesEnum.inRecovery]: {
    title: "En recuperacion",
    color: "red_300",
    availableStatus: [
      statusVehiclesEnum.recovered,
    ]
  },
  [statusVehiclesEnum.recovered]: {
    title: "Reingreso",
    color: "yellow_100",
    availableStatus: [
      statusVehiclesEnum.lost,
      statusVehiclesEnum.left
    ]
  },
  [statusVehiclesEnum.underMaintenance]: {
    title: "Mantenimiento",
    color: "primary_500",
    availableStatus: [
      statusVehiclesEnum.available,
      statusVehiclesEnum.left,
    ]
  },
  [statusVehiclesEnum.left]: {
    title: "Quedada",
    color: "neutral_700",
    availableStatus: [
      statusVehiclesEnum.lost,
      statusVehiclesEnum.left
    ]
  },
  [statusVehiclesEnum.lost]: {
    title: "Perdidas",
    color: "neutral_900",
    availableStatus: [
      statusVehiclesEnum.recovered,
    ]
  },
};

export const vehicleFields = {
  description: "Descripción",
  detail: "Detalle",
  warranty: "Garantía",
  salePrices: "Precios de venta",
  hub: "Hub",
  city: "Ciudad",
  country: "País",
  owner: "Propietario",
  forSale: "En venta",
  depositPrice: "Precio de depósito",
  deposit: "Depósito",
  insurance: "Seguro",
  status: "Estado",
  type: "Tipo",
  score: "Puntuación",
  insurancePrice: "Precio del seguro",
  oldPrice: "Precio antiguo",
  externalId: "ID externo",
  internalId: "ID interno",
  images: "Imágenes",
  extraCost: "Costo adicional",
  vehicleSN: "Número de serie del vehículo",
  registrationCard: "Tarjeta de registro",
  engineSN: "Número de serie del motor",
  brakesCondition: "Condición de los frenos",
  brakeType: "Tipo de frenos",
  creditCalc: "Calculo de crédito",
  plate: "Matrícula",
  tankSize: "Capacidad del tanque",
  rentPrice: "Precio de alquiler",
  purchaseCost: "Costo de compra",
  condition: "Condición",
  color: "Color",
  secondaryColor: "Color secundario",
  suffix: "Sufijo",
  purchaseDate: "Fecha de compra",
  details: "Detalles",
  cylindersCapacity: "Capacidad de los cilindros",
  model: "Modelo",
  brand: "Marca",
  creditTime: "Tiempo de crédito",
  documents: "Documentos",
  discounts: "Descuentos",
  factorKM: "Factor de KM",
  blueBookPrice: "Precio del mercado",
  gpsStatus: "Estado del GPS",
  maintenanceColor: "Color del mantenimiento",
  maintenanceDays: "Dias de mantenimiento",
};

// eslint-disable-next-line no-shadow
export enum VehicleSortOptions {
  PROMOTION = "promotion",
  HIGHER_PRICE = "higherPrice",
  LOWER_PRICE = "lowerPrice",
  HIGHER_CC = "higherCC",
  LOWER_CC = "lowerCC",
  HIGHER_KM = "higherKM",
  LOWER_KM = "lowerKM",
}
