/* eslint-disable no-underscore-dangle */
/* eslint-disable dot-notation */
import { get, put, post, deletes } from "helpers/axios/axiosInstance";
import FormData from "form-data";
import { getRoles, setRoles } from "helpers/localStorage/dataStorage";
import { AnyARecord } from "dns";
import * as qs from "qs";
import { FilterSections } from "models/ozonio.interface";



export const getOzonios = async (
  query: string,
  letter?: string
): Promise<any | null> => {

  try {
    let path = "v1/user?";
    if (query) {
      path = `${path}&query=${query}`;
    }
    if (letter) {
      path = `${path}&letter=${letter}`;
    }
    const res = await get(path);
    return res.data as any;
  } catch (err: any) {
    throw err.response.data;
  }
};
export const getOzonioById = async (id: string = ""): Promise<any | null> => {

  try {
    const res = await get(`v1/user/${id}`);
    return res.data as any;
  } catch (err: any) {
    throw err.response.data;
  }
};

export const addOzonio = async (
  values: any,
  image: any
): Promise<AnyARecord | null> => {
  try {
    const data = new FormData();
    data.append("name", values.name);
    data.append("username", values.username);
    data.append("role", values.role);
    data.append("phone", values.phone);
    if (image && image.file) data.append("imageFile", image.file);


    let res;
    if (values["_id"]) {
      res = await put(`v1/user/${values["_id"]}`, data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    } else {
      res = await post("v1/user", data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    }
    return res.data;
  } catch (err: any) {
    throw err.response.data.message;
  }
};

export const editOzonio = async (values: any, id: String): Promise<any> => {
  try {
    const data = new FormData();
    Object.keys(values).forEach((k) => {
      data.append(k, values[k]);
    });

    const res = await put(`v1/user/${id}`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    return res.data as any;
  } catch (err: any) {
    throw err.response.data.message;
  }
};

export const deleteOzonio = async (id: String): Promise<any> => {
  try {

    const res = await deletes(`v1/user/${id}`);
    return res;
  } catch (err: any) {
    throw err.response.data.message;
  }
};

export const getExport = async (
  query: string,
  letter?: string
): Promise<any> => {

  try {
    let path = "v1/user/export?";
    if (query) {
      path = `${path}&query=${query}`;
    }
    if (letter) {
      path = `${path}&letter=${letter}`;
    }

    const res = await get(path);
    return res.data;
  } catch (err: any) {
    throw err.response.data;
  }
};

export const getOzonioRoles = async (): Promise<any | null> => {

  try {
    let info = getRoles();
    if (!info) {
      const res = await get("v1/user/role");
      info = res.data.data;
      setRoles(info);
    }
    return info;
  } catch (err: any) {
    throw err.response.data;
  }
};
export const addImage = async (
  user: string, file: File
): Promise<any> => {
  try {
    const data = new FormData();
    data.append("imageFile", file);


    const res = await put(`v1/user/${user}`, data, { headers: { "Content-Type": "multipart/form-data" } });
    return res.data;
  } catch (err: any) {
    throw err.response.data.message;
  }
};

export const restorePassword = async (
  email: any
): Promise<AnyARecord | null> => {
  try {
    const data = qs.stringify({
      email,
    });

    const res = await post("v1/user/recover-password", data, {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    });
    return res.data;
  } catch (err: any) {
    throw err.response.data.message;
  }
};
export const addUserFilter = async (
  id: any,
  name: string,
  section: FilterSections,
  values: object
): Promise<AnyARecord | null> => {
  try {
    const data = { name, values, section };

    const res = await post(`v1/user/${id}/personalFilter`, data);
    return res.data;
  } catch (err: any) {
    throw err.response.data.message;
  }
};
export const deleteUserFilter = async (
  id: string,
  fId: string
): Promise<AnyARecord | null> => {
  try {
    const res = await deletes(`v1/user/${id}/personalFilter/${fId}`);
    return res.data;
  } catch (err: any) {
    throw err.response.data.message;
  }
};

