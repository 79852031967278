/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
// css
import "./cardInventory.scss";
import { MaintenanceColor, MaintenanceColorValues, statusVehicles, TVehicle } from "models/vehicle.interface";

// imgs
import { ReactComponent as Eye } from "design_system/src/static/icons/eye.svg";
import { ReactComponent as EyeOff } from "design_system/src/static/icons/eye-off.svg";
import { ReactComponent as Edit } from "design_system/src/static/icons/edit-ui-2.svg";
import { ReactComponent as Moto } from "design_system/src/static/icons/Motocycle.svg";
import { ReactComponent as Signal } from "design_system/src/static/icons/radio-signal.svg";

import moment from "moment";
import { Button, Typography } from "design_system/src";
import { editVehicle } from "helpers/fetchs/inventory";
import { priceFormatFinancial } from "helpers/prices/prices";
import { StatusPopup } from "../statusPopup/statusPopup";
import { LightTooltip } from "../tooltip/Tooltip";
import { editSolicitud } from "../../../helpers/fetchs/solicitudes";
import { toasterNotification } from "../../../helpers/globalNotifications/globalNotifications";
import { toasterOptions } from "../../../enums/toaster.enum";

type Props = {
    vehicle: TVehicle;
    onClick: Function | null;
    setResult?: Function;
    defaultCredit?: number;
    filterSalePrice?: string[];
};
export const colorsMaintenance: any = {
    green: {
        color: "green_300",
        desc: "Para entrega en 24h",
    },
    yellow: {
        color: "yellow_100",
        desc: "Para entrega en 48h",
    },
    blue: {
        color: "secondary_500",
        desc: "Para entrega en 72h",
    },
    red: {
        color: "red_300",
        desc: "Para entrega en 5 dias habiles",
    },
};
function CardInventory({
    vehicle,
    onClick = null,
    setResult = () => {},
    defaultCredit = 52,
    filterSalePrice,
}: Props) {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [creditTime, setCreditTime] = useState<any>();
    const [displayCreditTimes, setDisplayCreditTimes] = useState<any[]>();

    useEffect(() => {
       
        const filters = (filterSalePrice?.[0] ?? "").split(",");
        const [min, max, week] = filters ?? [];
        const discount = vehicle?.discounts?.filter(
            (ele) => ele.status === "active"
        )[0];
        const displayCredits =
            vehicle.salePrices
                ?.map((price: any) => {
                    const newPrice = { ...price };
                    if (discount) {
                        newPrice.oldPaymentWeek = newPrice.paymentWeek;
                        switch (discount.type) {
                            case "percentage":
                                newPrice.paymentWeek =
                                    price.paymentWeek *
                                    (1 - discount.percentageValue);
                                break;
                            case "net":
                                newPrice.paymentWeek =
                                    price.paymentWeek - discount.netValue;
                                break;
                            default:
                                break;
                        }
                    }
                    return newPrice;
                })
                .filter((price: any) => {
                    if (week && price.weeks !== parseInt(week, 10))
                        return false;
                    if (min && min > price.paymentWeek) return false;
                    if (max && max < price.paymentWeek) return false;
                    return true;
                }) ?? [];
        setDisplayCreditTimes(displayCredits);
        const credit = displayCredits?.filter(
            (price: any) => price.weeks === defaultCredit
        )[0];
        if (credit) {
            setCreditTime(credit);
        } else if (displayCredits?.length) {
            setCreditTime(displayCredits[0]);
        }
    }, [vehicle, defaultCredit, filterSalePrice]);
    const handleDetailsVeh = () => {
        navigate(`/inventory/${vehicle.internalId}`);
    };

    const changeState = (e: any) => {
        e.stopPropagation();
        setOpen(true);
    };
    const changeCredit = (e: any, credit: any) => {
        e.stopPropagation();
        setCreditTime(credit);
    };
    const setState = (status: string) => {
        editVehicle({ status }, vehicle.internalId).then((ele) => {
            setResult(ele);
            setOpen(false);
        });
    };

    // eslint-disable-next-line

    return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <div
            className="dso_card flex_center_col bg_neutral_0 dim_100_per p_xs cursor_pointer inventory_card"
            onClick={() => {
                if (!onClick) {
                    handleDetailsVeh();
                } else {
                    onClick(vehicle);
                }
            }}
            role="button"
            tabIndex={0}
        >
            <div className="fit display_flex flex_gap_xs flex_align_center m_b_sm pos_relative w_100_per">
                <div
                    className={`
          dso_chip_small flex_grow_1 
          bg_${
              statusVehicles[vehicle?.status as keyof typeof statusVehicles]
                  ?.color
          }_nocontrast text_neutral_0 flex_basis_0 overflow_hidden overflow_ellipsis text_center
          `}
                >
                    {statusVehicles[
                        vehicle?.status as keyof typeof statusVehicles
                    ]?.title ?? vehicle?.status}
                </div>
                <Button
                    variant="icon"
                    subvariant="edit"
                    scale="small"
                    icon={<Edit className="text_neutral_900" />}
                    onClick={changeState}
                />
                <StatusPopup
                    open={open}
                    setOpen={setOpen}
                    labels={
                        statusVehicles[
                            vehicle.status as keyof typeof statusVehicles
                        ]?.availableStatus.map((key) => statusVehicles[key]) ??
                        []
                    }
                    onChange={setState}
                    className="m_l_xs"
                />
            </div>
            <div className=" flex_1 w_100_per">
                <img
                    className="w_100_per center_y br_sm moto_img"
                    src={
                        vehicle?.images[0]
                            ? vehicle?.images[0].url
                            : `${process.env.REACT_APP_STORAGE_BASE_URL}public/uploads/generic-moto.png`
                    }
                    alt="vehicle"
                />
            </div>
            <div className="fix flex_center_col w_100_per p_t_sm">
                <div className="display_flex flex_align_center flex_gap_xxs">
                    <LightTooltip
                        title={
                            vehicle.maintenanceDays?`Mantenimiento de ${vehicle.maintenanceDays} dias` :
                            "No dispone de informacion"
                        }
                    >
                        <Moto
                            className={`dim_lg text_${
                                MaintenanceColorValues[vehicle.maintenanceColor]
                                    ?.color ?? "primary_300"
                            }`}
                        />
                    </LightTooltip>
                    <LightTooltip
                        title={
                            vehicle.gpsStatus === "withSignal"
                                ? "Reporta señal GPS"
                                : "No reporta señal GPS"
                        }
                    >
                        <Signal
                            className={`dim_lg text_${
                                vehicle.gpsStatus === "withSignal"
                                    ? "green_300"
                                    : "neutral_300"
                            }`}
                        />
                    </LightTooltip>
                    <Typography
                        scale="medium"
                        weight="400"
                        textColor="neutral_900"
                        className="text_center"
                    >
                        {vehicle.internalId}
                    </Typography>
                </div>
                <Typography
                    scale="xsmall"
                    weight="400"
                    textColor="neutral_600"
                    className="text_center"
                >
                    Creacion:{" "}
                    {vehicle.createdAt
                        ? moment(vehicle.createdAt).format("DD/MM/YYYY")
                        : "--"}
                </Typography>
                <Typography
                    scale="medium"
                    weight="600"
                    textColor="neutral_1000"
                    className="m_b_sm text_center text_uppercase"
                >
                    {`${vehicle.brand?.name} ${vehicle.model?.name} ${
                        vehicle.cylindersCapacity
                    } ${vehicle.suffix ? vehicle.suffix : ""}`}
                </Typography>
                <div className="bg_neutral_300 br_b_sm p_y_sm flex_center_col w_100_per">
                    {creditTime?.oldPaymentWeek && (
                        <Typography
                            scale="large"
                            weight="600"
                            className="text_center text_red_300 text_decoration_line-through"
                        >
                            {priceFormatFinancial(
                                creditTime?.oldPaymentWeek,
                                0
                            )}
                        </Typography>
                    )}
                    <Typography
                        scale="large"
                        weight="600"
                        className="text_center text_neutral_900"
                    >
                        {priceFormatFinancial(creditTime?.paymentWeek, 2)}
                    </Typography>
                    {displayCreditTimes && (
                        <>
                            <div className="flex_center flex_gap_xxs m_b_xxs">
                                {displayCreditTimes.map((credit: any) => (
                                    <div
                                        key={`${vehicle.internalId}-${credit.weeks}`}
                                        className={`w_xxl cursor_pointer br_sm bg_${
                                            creditTime?.weeks === credit.weeks
                                                ? "green_300"
                                                : "neutral_600"
                                        }_nocontrast`}
                                        onClick={(e) => changeCredit(e, credit)}
                                        onKeyPress={(e) =>
                                            changeCredit(e, credit)
                                        }
                                        role="button"
                                        tabIndex={0}
                                    >
                                        <Typography
                                            scale="xsmall"
                                            weight="600"
                                            textColor={
                                                creditTime?.weeks ===
                                                credit.weeks
                                                    ? "neutral_0"
                                                    : "neutral_800"
                                            }
                                            className="text_center"
                                        >
                                            {credit.weeks}
                                        </Typography>
                                    </div>
                                ))}
                            </div>
                            <Typography
                                scale="xsmall"
                                weight="400"
                                textColor="neutral_600"
                                className="text_center"
                            >
                                Semanas para pagar
                            </Typography>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}
export default CardInventory;
