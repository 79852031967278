import React from "react";
import Navbar from "components/hocs/navbar/Navbar";
import { Outlet } from "react-router-dom";
import Sidebar from "components/hocs/sidebar/Sidebar";

// css
import "./mobileLayout.scss";
import NavbarMobile from "components/hocs/navbarMobile/NavbarMobile";

function MobileLayout() {
    return (
        <div className="rootL-mobile">
            <NavbarMobile />
            <div className="main">
                <div className="mobile-container">
                    <Outlet />
                </div>
            </div>
        </div>
    );
}

export default MobileLayout;
