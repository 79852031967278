import { formStatusEnum } from "./financialForm.interface";
import { TUser } from "./history.interface";
import { TVehicle } from "./vehicle.interface";


// eslint-disable-next-line no-shadow
export enum NotificationClassification {
  fixedPosition = "fixedPosition",
  sleepingSiteChange = "sleepingSiteChange",
  noSignal = "noSignal",
  leavingGeofence = "leavingGeofence",
}

export const NotificationValues = {
  [NotificationClassification.fixedPosition]: {
    module: "inventory",
    moduleName: "Vehiculo",
    idField: "internalId"
  },
  [NotificationClassification.sleepingSiteChange]: {
    module: "inventory",
    moduleName: "Vehiculo",
    idField: "internalId"
  },
  [NotificationClassification.noSignal]: {
    module: "inventory",
    moduleName: "Vehiculo",
    idField: "internalId"
  },
  [NotificationClassification.leavingGeofence]: {
    module: "inventory",
    moduleName: "Vehiculo",
    idField: "internalId"
  },
};

export interface TNotification {
  title: string;
  description: string;
  user: TUser;
  read: boolean;
  data: any;
  classification: NotificationClassification;
  _id:string;
}
