import React, { useEffect } from "react";
import CardLogin from "components/hocs/cardLogin/CardLogin";

// images
import img1 from "static/images/login1.svg";
import img2 from "static/images/login2.svg";

// css
import "./auth.scss";
import { handleEventGoogle } from "helpers/analytics/googleAnalytics";

function Auth() {
  useEffect(() => {
    handleEventGoogle("VIEW_Panda_login", "Panda", "page view to login view");
  }, []);

  return (
    <div className="root flex_center">
      <img alt="circulos" src={img1} className="img1 display_none_mobile" />
      <img alt="orange" src={img2} className="img2  display_none_mobile" />
      <CardLogin />
    </div>
  );
}

export default Auth;
