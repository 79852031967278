/* eslint-disable no-underscore-dangle */
/* eslint-disable dot-notation */
import { get, put, post, deletes } from "helpers/axios/axiosInstance";
import {
  IOzonerTotals,
  TDataozoner,
  TEditDataozoner,
  TRequestozoner,
  ozonerSearchGroups,
} from "models/ozoner.interface";
import FormData from "form-data";
import { TEditFinancialForm, economicActivityEnum } from "models/financialForm.interface";
import {
  ISlackNotifier,
  slackNotifyTruoraFailed,
} from "../slackNotifier/slackNotifier";

export const getOzonerBySearchQuery = async (
  search: string = ""
): Promise<ozonerSearchGroups[]> => {
  try {
    const res = await get(`v1/ozoner/search?query=${search}`);
    return res.data;
  } catch (err: any) {
    throw err.response.data;
  }
};

export const getOzonerByVehicle = async (id: string): Promise<TDataozoner> => {
  try {
    const res = await get(`v1/ozoner/vehicle/${id}`);
    const dataObject = res.data[0]; // Extraes el objeto del array
    return dataObject as TDataozoner;
  } catch (err: any) {
    throw err.response.data;
  }
};
export const getOzonerByCurp = async (curp: string): Promise<TDataozoner> => {
  try {
    const res = await get(`v1/ozoner/curp/${curp}`);
    const dataObject = res.data; // Extraes el objeto del array
    return dataObject as TDataozoner;
  } catch (err: any) {
    throw err.response.data;
  }
};

export const getOzoners = async (
  page: number,
  status?: string,
  query?: string,
  aditionalQuery?: string,
  city?: string,
): Promise<TRequestozoner | null> => {
  try {
    let path = `v1/ozoner?page=${page}`;
    if (status) {
      path = `${path}&status=${status}`;
    }
    if (query) {
      path = `${path}&query=${query}`;
    }
    if (city) {
      path = `${path}&city=${city}`;
    }
    if (aditionalQuery) {
      path = `${path}&${aditionalQuery}`;
    }
    const res = await get(path);
    return res.data as TRequestozoner;
  } catch (err: any) {
    throw err.response.data;
  }
};
export const getOzonerById = async (
  id: string = ""
): Promise<TDataozoner | null> => {
  try {
    const res = await get(`v1/ozoner/${id}`);
    return res.data as TDataozoner;
  } catch (err: any) {
    throw err.response.data;
  }
};
export const getOzonerPalenca = async (
  id: string = ""
): Promise<TDataozoner | null> => {
  try {
    const res = await get(`v1/palenca/syncPalenca/${id}`);
    return res.data as TDataozoner;
  } catch (err: any) {
    throw err.response.data;
  }
};
export const getOzonerTruora = async (
  id: string = ""
): Promise<TDataozoner | null> => {
  try {
    const res = await get(`v1/truora/syncTruora/${id}`);
    return res.data as TDataozoner;
  } catch (err: any) {
    throw err.response.data;
  }
};
export const getOzonerAgreements = async (
  id: string = ""
): Promise<any | null> => {
  try {
    const res = await get(`v1/agreement?credit=${id}`);
    return res.data;
  } catch (err: any) {
    throw err.response.data;
  }
};
export const getAgreements = async (
  filter?: string,
  limit: number = -1
): Promise<any | null> => {
  try {
    const res = await get(
      `v1/agreement?limit=${limit}${filter ? `&status=${filter}` : ""}`
    );
    return res.data;
  } catch (err: any) {
    throw err.response.data;
  }
};
export const getExportAgreements = async (
  status: string,
): Promise<any> => {
  try {
    let path = "v1/agreement/export";
    if (status) {
      path = `${path}?status=${status}`;
    }

    const res = await get(path);
    return res.data;
  } catch (err: any) {
    throw err.response.data;
  }
};

export const getOzonerAgreementById = async (
  id: string
): Promise<any | null> => {
  try {
    const res = await get(`v1/agreement?=${id}`);
    return res.data;
  } catch (err: any) {
    throw err.response.data;
  }
};

export const addOzonerAgreements = async (
  id: string,
  agreements: any[],
  credit:string,
  idA?: string
): Promise<any | null> => {
  try {
    if (idA) {
      await deletes(`v1/agreement/${idA}`);
    }
    const res = await post("v1/agreement", {
      ozoner: id,
      credit,
      agreementParts: agreements,
    });
    return res.data;
  } catch (err: any) {
    throw err.response.data;
  }
};
export const cloneOzoner = async (
  id: string,
  name: string,
): Promise<any | null> => {
  try {
    const res = await post(`v1/ozoner/${id}/clone`, { name });
    return res.data;
  } catch (err: any) {
    throw err.response.data;
  }
};
export const setOzonerAgreements = async (
  idA: string,
  idP: string,
  status: string
): Promise<any | null> => {
  try {
    const res = await put(`v1/agreement/${idA}/parts/${idP}`, { status });
    return res.data;
  } catch (err: any) {
    throw err.response.data;
  }
};

export const getAllOzonersTotals = async () => {
  const path = "/v1/ozoner/totals";
  try {
    const res = await get(path);

    return res.data as IOzonerTotals;
  } catch (err: any) {
    throw err.response.data;
  }
};

export const getOzonerCredits = async (
  id: string = ""
): Promise<any | null> => {
  try {
    const res = await get(`v1/credit/ozoner/${id}`);
    return res.data;
  } catch {
    console.log("no credit");
    return null;
  }
};

export const addOzoner = async (
  ozoner: TEditDataozoner,
  form: TEditFinancialForm |null,
  id?: string
): Promise<TDataozoner | null> => {
  try {
    const data = new FormData();
    if(ozoner.createdAt) data.append("createdAt", ozoner.createdAt);
    if(ozoner.name) data.append("name", ozoner.name);
    if(ozoner.phone) data.append("phone", ozoner.phone);
    if(ozoner.email) data.append("email", ozoner.email);
    if(ozoner.curp) data.append("curp", ozoner.curp);
    if(ozoner.cp) data.append("fullAddress[cp]", ozoner.cp);
    if(ozoner.state) data.append("fullAddress[state]", ozoner.state);
    if(ozoner.delegation) data.append("fullAddress[delegation]", ozoner.delegation);
    if(ozoner.neighborhood) data.append("fullAddress[neighborhood]", ozoner.neighborhood);
    if(ozoner.street) data.append("fullAddress[street]", ozoner.street);
    if(ozoner.extNumber) data.append("fullAddress[extNumber]", ozoner.extNumber);
    if(ozoner.latitude) data.append("fullAddress[lat]", ozoner.latitude);
    if(ozoner.longitude) data.append("fullAddress[long]", ozoner.longitude);
    if (ozoner.intNumber)
      data.append("fullAddress[intNumber]", ozoner.intNumber);

    if (form && !id) {
      data.append(
        "financialForm[personal_references][personal_reference_1][name]",
        form.personal_references.personal_reference_1.name
      );
      data.append(
        "financialForm[personal_references][personal_reference_1][phone]",
        form.personal_references.personal_reference_1.phone
      );
      data.append(
        "financialForm[personal_references][personal_reference_1][relation]",
        form.personal_references.personal_reference_1.relation
      );
      data.append(
        "financialForm[personal_references][personal_reference_1][other]",
        form.personal_references.personal_reference_1.other
      );
      data.append(
        "financialForm[personal_references][personal_reference_2][name]",
        form.personal_references.personal_reference_2.name
      );
      data.append(
        "financialForm[personal_references][personal_reference_2][phone]",
        form.personal_references.personal_reference_2.phone
      );
      data.append(
        "financialForm[personal_references][personal_reference_2][relation]",
        form.personal_references.personal_reference_2.relation
      );
      data.append(
        "financialForm[personal_references][personal_reference_2][other]",
        form.personal_references.personal_reference_2.other
      );
      data.append("financialForm[createdAt]", form.createdAt);
      data.append("financialForm[monthlyIncome]", form.monthlyIncome);
      data.append("financialForm[monthlySpending]", form.monthlySpending);
      data.append("financialForm[childrenCount]", form.childrenCount);
      data.append("financialForm[dependantsCount]", form.dependantsCount);
      data.append("financialForm[economicActivity][value]", form.economicActivity);
      if(form.economicActivity === economicActivityEnum.otro)
        data.append("financialForm[economicActivity][description]", form.economicActivityOther);
      data.append("financialForm[civilStatus]", form.civilStatus);
      data.append("financialForm[educationalLevel]", form.educationalLevel);
      form.livesWith.forEach(live=>{
        data.append("financialForm[livesWith][]", live);
      });
      data.append("financialForm[workInDigitalPlatforms]", form.workInDigitalPlatforms);
      if (form.companyName) data.append("financialForm[companyName]", form.companyName);
      if (form.assets) {
        if (form.assets.assets)
          form.assets.assets.forEach((asset: string) => {
            data.append("financialForm[assets][assets]", asset);
          });
        if (form.assets.other)
          data.append("financialForm[assets][other]", form.assets.other);
        if (form.assets.joinedAssets)
          data.append(
        "financialForm[assets][joinedAssets]",
        form.assets.joinedAssets
        );
      }
      if (form.vehicleId) {
        data.append("financialForm[vehicleId]", form.vehicleId);
        data.append("financialForm[creditTime]", form.creditTime);
      }
    }


    let res;
    if (id) {
      res = await put(`v1/ozoner/${id}`, data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    } else {
      data.append("city", ozoner.city);
      res = await post("v1/ozoner", data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    }
    return res.data as TDataozoner;
  } catch (err: any) {
    throw err.response.data.message;
  }
};

export const editOzoner = async (
  values: any,
  id: String
): Promise<TDataozoner> => {
  try {
    const data = new FormData();
    if(values.fullAddress){
      if (values.fullAddress.cp) data.append("fullAddress[cp]", values.fullAddress.cp);
      if (values.fullAddress.state) data.append("fullAddress[state]", values.fullAddress.state);
      if (values.fullAddress.delegation) data.append("fullAddress[delegation]", values.fullAddress.delegation);
      if (values.fullAddress.neighborhood) data.append("fullAddress[neighborhood]", values.fullAddress.neighborhood);
      if (values.fullAddress.street) data.append("fullAddress[street]", values.fullAddress.street);
      if (values.fullAddress.extNumber) data.append("fullAddress[extNumber]", values.fullAddress.extNumber);
      if (values.fullAddress.intNumber)
        data.append("fullAddress[intNumber]", values.fullAddress.intNumber);
    }
    Object.keys(values).filter(key=>key!=="fullAdress").forEach((k) => {
      data.append(k, values[k]);
    });
    const res = await put(`v1/ozoner/${id}`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    return res.data as TDataozoner;
  } catch (err: any) {
    throw err.response.data.message;
  }
};

export const deleteOzoner = async (id: String): Promise<any> => {
  try {
    const res = await deletes(`v1/ozoner/${id}`);
    return res;
  } catch (err: any) {
    throw err.response.data.message;
  }
};


export const getExport = async (
  pages?: string,
  params?: string,
  limit?:number,
  city?: string,
): Promise<any> => {
  try {
    let path = "v1/ozoner/export?";
    if (pages) {
      path = `${path}&pages=${pages}`;
    }
    if (limit) {
      path = `${path}&limit=${limit}`;
    }
    if (city) {
      path = `${path}&city=${city}`;
    }
    if (params) {
      path = `${path}&${params}`;
    }


    const res = await get(path);
    return res.data;
  } catch (err: any) {
    throw err.response.data;
  }
};

export const genOzonerDocuments = async (
  ozonerId: string,
  formId: string,
  rfc:string
): Promise<any> => {
  try {
    const path = `v1/ozoner/${ozonerId}/createDocuments`;

    const res = await post(path,{
      formId,
      rfc
    });
    return res.data;
  } catch (err: any) {
    throw err.response.data;
  }
};

export const addFile = async (
  ozoner: string,
  file: File,
  type: string = "other"
): Promise<any> => {
  try {
    const data = new FormData();
    data.append("name", file.name);
    data.append("file", file);

    const res = await put(`v1/ozoner/${ozoner}/${type}`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return res.data;
  } catch (err: any) {
    throw err.response.data.message;
  }
};
export const deleteFile = async (
  ozoner: string,
  file: string
): Promise<any> => {
  try {
    const res = await deletes(`v1/ozoner/${ozoner}/document/${file}`);
    return res.data;
  } catch (err: any) {
    throw err.response.data.message;
  }
};

export const getFiles = async (ozoner: string): Promise<any> => {
  try {
    const res = await get(`v1/ozoner/${ozoner}/download-documents`, {
      responseType: "arraybuffer",
      responseEncoding: "binary",
    });
    return res.data;
  } catch (err: any) {
    throw err.response.data.message;
  }
};
const formatDate = (d: Date) =>
  `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;

export const getCohorts = async (dateI: Date, dateF: Date, city?: string): Promise<any> => {
  try {
    let path = `v1/ozoner/cohorts?dateStart=${formatDate(dateI)}&dateEnd=${formatDate(dateF)}`;
    if (city) {
      path = `${path}&city=${city}`;
    }

    const res = await get(path);
    return res.data;
  } catch (err: any) {
    throw err.response.data;
  }
};

function sleep(ms: number) {
  // eslint-disable-next-line no-promise-executor-return
  return new Promise((resolve) => setTimeout(resolve, ms));
}
type TruoraValidationResult = {
  history: {
    validation_status: "success" | "failure";
  }[];
};
export const validateTruora = async (
  docs: any,
  _id: string,
  slackNotifierArgs: ISlackNotifier
): Promise<void> => {
  const data = new FormData();
  data.append("document_front", docs.ine_front as Blob);
  data.append("document_reverse", docs.ine_back as Blob);
  const truoraResult = await post(`v1/truora/${_id}`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });

  const accountId = truoraResult.data;

  // we need to wait before call the validation endpoint to be sure that the validation was done correctly
  await sleep(10000);

  const selfieFormData = new FormData();
  selfieFormData.append("fileImg", docs.selfie as Blob);
  await post(`v1/truora/validation-face/${accountId}`, selfieFormData, {});

  // we need to wait before call the validation endpoint to be sure that the validation was done correctly
  await sleep(10000);

  const result = await post<TruoraValidationResult>(
    `v1/truora/validations/${accountId}`,
    {},
    {}
  );

  const invalids = result.data.history.filter(
    (item) => item.validation_status === "failure"
  );

  if (invalids.length > 0) {
    await slackNotifyTruoraFailed(slackNotifierArgs);
    throw invalids;
  }
};

export const getTruoraPdf = async (checkId: string): Promise<any> => {
  try {
    // const path = "v1/truora/pdf/CHKd7d36d49f197e1b965be2483cf7b39f1";
    const path = `v1/truora/pdf/${checkId}`;

    const res = await get(path, {
      responseType: "arraybuffer",
      responseEncoding: "binary",
      maxBodyLength: Infinity,
    });
    return res.data;
  } catch (err: any) {
    throw err.response.data;
  }
};
// export const getTruoraPdf = async (
//   checkId: string,
// ): Promise<any> => {
//   try {
//     const axiosInstance = axios.create({
//       "maxBodyLength": Infinity,

//       headers: {
//         "Content-Type": "application/json",
//         "Truora-API-Key": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2NvdW50X2lkIjoiIiwiYWRkaXRpb25hbF9kYXRhIjoie30iLCJjbGllbnRfaWQiOiJUQ0lhZTYxNDI0NWU0OTQxMTY0ZDE1ZGQ5OTk4ODg3NzdlMyIsImV4cCI6MzI0MjIxMzQ5MCwiZ3JhbnQiOiIiLCJpYXQiOjE2NjU0MTM0OTAsImlzcyI6Imh0dHBzOi8vY29nbml0by1pZHAudXMtZWFzdC0xLmFtYXpvbmF3cy5jb20vdXMtZWFzdC0xX2lFdENDSkVjNSIsImp0aSI6ImIzNmQ5OGM3LWM3ZDMtNDY2ZC04YzBjLTY2YjVkNmI5ODAxNSIsImtleV9uYW1lIjoicGFnaW5hd2Vib3pvbiIsImtleV90eXBlIjoiYmFja2VuZCIsInVzZXJuYW1lIjoib3pvbm1vYmktcGFnaW5hd2Vib3pvbiJ9.vMtN0yU3HO76y0sj7Z-F-4YqPtetYanDp3lZ1TsTiD8"
//       },
//     });
//     const res = await axiosInstance.get(`https://api.checks.truora.com/v1/checks/${checkId}/pdf`, {
//       responseType: "arraybuffer",
//       responseEncoding: "binary",
//     });
//     return res.data;
//   } catch (err: any) {
//     throw err.response.data;
//   }
// };

export const getOzonerNotificationsCount = async (
  id: string = ""
): Promise<any | null> => {
  const res = await get(`v1/group-notification/ozoner/${id}?onlyCount=true`);
  return res.data;
};
export const getOzonerNotifications = async (
  id: string = "",
  type: "textmsg" | "email" | "whatsapp" | "" = ""
): Promise<any | null> => {
  const res = await get(`v1/group-notification/ozoner/${id}?type=${type}`);
  return res.data;
};
