// eslint-disable-next-line no-shadow
export enum DiscountType {
  // eslint-disable-next-line no-unused-vars
  NET = "net",
  // eslint-disable-next-line no-unused-vars
  PERCENTAGE = "percentage",
}

export interface Discount {
  promoCode: string;
  type: DiscountType;
  value: number;
}
