// eslint-disable-next-line no-shadow
export enum Roles {
  ADMIN = "ADMIN",
  RECUPERATOR = "RECUPERATOR",
  CREDIT_ANALYST = "CREDIT_ANALYST",
  COLLECTOR_AGENT = "COLLECTOR_AGENT",
  DEVELOPER = "DEVELOPER",
  SELLER = "SELLER",
  SELLER_CHIEF = "SELLER_CHIEF",
};

export interface Role {
  _id:string;
  permissions:any[];
  description:string;
  name:Roles;
}
export interface Ozonio {
  _id:string;
  username:string;
  phone:string;
  name:string;
  role:Role;
  updatedPassword:boolean;
  createdAt:string;
  updatedAt:string;
  slackId:string;
};

// eslint-disable-next-line no-shadow
export enum FilterSections {
  ozonersList = "ozonersList",
  vehiclesList = "vehiclesList",
  creditsWriteOffList = "creditsWriteOffList",
  creditsList = "creditsList",
  formsList = "formsList",
  ozociosList = "ozociosList",
}

export interface UserFilter{
  default?: boolean;
  name:string;
  section:FilterSections;
  values:any;
  _id:string;
}