import React, { useEffect } from "react";
import Navbar from "components/hocs/navbar/Navbar";
import { Outlet } from "react-router-dom";
import Sidebar from "components/hocs/sidebar/Sidebar";

// css
import "./layout.scss";

function Layout() {

  return (
    <div className="rootL">
      <Navbar />
      <Sidebar />
      <div className="main">
        <div className="container">
          <Outlet/>
        </div>
      </div>
    </div>
  );
}

export default Layout;
