import React, { useEffect, useState, useContext } from "react";
import {
  Input, Typography,
  ModalConfirmation as Modal,
  Button,
} from "design_system/src";
import { ReactComponent as Pencil } from "design_system/src/static/icons/pencil-ui.svg";
import { ReactComponent as Invoice } from "design_system/src/static/icons/invoice.svg";
import { Grid } from "@mui/material";
import moment from "moment";
import * as yup from "yup";
import { Form, Formik } from "formik";

import { addOzonerAgreements, setOzonerAgreements } from "helpers/fetchs/ozoners";
import { priceMXNTotal } from "helpers/prices/prices";
import { toasterNotification } from "helpers/globalNotifications/globalNotifications";
import { toasterOptions } from "enums/toaster.enum";
import { statusAgreements } from "models/ozoner.interface";
import { ozonerContext } from "../OzonerContext";

type AgreementModalProps = {
  openAgreement: boolean;
  setOpenAgreement: any;
  openAgreements: boolean;
  setOpenAgreements: any;
  editingAgreement:any
  setEditingAgreement:Function
};
const validationSchema = yup.object().shape({
  date: yup.string().required("Campo requerido"),
  amount: yup.number().min(1).required("Campo requerido"),
  comment: yup.string(),
});
const defaultsAgreement = {
  date: moment().format(),
  amount: "",
  comment: "",
  amountQuote: "",
};

const AgreementModal: React.FC<AgreementModalProps> = ({
  openAgreement,
  setOpenAgreement,
  editingAgreement,
  openAgreements,
  setOpenAgreements,
  setEditingAgreement
}) => {
  const [listAgreements, setListAgreements] = useState<any[]>([
    defaultsAgreement,
  ]);
  const [agreementDetail, setAgreementDetail] = useState<any>(null);
  const [editingAgreementId, setEditingAgreementId] = useState<any>();
  const [selectedAgreement, setSelectedAgreement] = useState<number>(0);

  const { agreements, financialForm, vehicle, ozoner, getAgreements, agreementsHist } = useContext(ozonerContext);


  const closeAgreements = () => {
    setOpenAgreement(false);
    setEditingAgreement(defaultsAgreement);
    setEditingAgreementId(undefined);
    setListAgreements([defaultsAgreement]);
    setSelectedAgreement(0);
  };

  const addAgreement = () => {
    if (ozoner?._id)
      addOzonerAgreements(ozoner._id, listAgreements, financialForm.credit._id, editingAgreementId)
        .then((response: any) => {
          closeAgreements();
          getAgreements();
        })
        .catch((e: any) => {
          toasterNotification({
            msg: e.message,
            toasterType: toasterOptions.error,
          });
        });
  };


  const changeAgreement = (idA: string, idP: string, status: string) => {
    if (ozoner?._id)
      setOzonerAgreements(idA, idP, status).then(() => {
        getAgreements();
      });
  };


  const editAgreement = (agreement: any) => {
    const agrees = agreement.agreementParts.map((part: any) => ({
      ...part,
      date: moment(part.date).utc().format(),
    }));
    setOpenAgreements(false);
    setOpenAgreement(true);
    setAgreementDetail(null);
    setEditingAgreementId(agreement._id);
    setListAgreements(agrees);
    setSelectedAgreement(0);
    setEditingAgreement(agrees[0]);
  };


  
  const agreementValid = () => {
    return listAgreements.reduce(
      (acc, val, i) => acc && validationSchema.isValidSync(val),
      true
    );
  };
  return (<>
  {openAgreement ? (
    <Formik
      validateOnMount
      enableReinitialize
      initialValues={editingAgreement}
      onSubmit={() => { }}
      validationSchema={validationSchema}
    >
      {(formik) => (
        <Form>
          <Modal
            openConfirmation={openAgreement}
            handleClose={closeAgreements}
            // onConfirmationCallBack={addAgreement}
            // eslint-disable-next-line react/jsx-no-useless-fragment
            icon={<Invoice className="text_primary_300" />}
            widthDesktop={60}
            isConfirmationLoading={false}
            actions={[
              {
                label: "Cancelar",
                color: "grey",
                action: closeAgreements,
              },
              {
                label: editingAgreementId
                  ? "Modificar acuerdo"
                  : "Crear acuerdo",
                color: "primary",
                isSubmit: true,
                action: () => addAgreement(),
                disabled: !agreementValid(),
              },
            ]}
          >
            <div className="display_flex h_60_vp">
              <div className="flex_basis_0 flex_grow_1 overflow_hidden">
                <Typography scale="large" weight="600">
                  Acuerdo de pago OZONER
                </Typography>
                <Typography scale="medium" weight="400" className="m_b_md">
                  Después de la llamada directa, se llegó a un acuerdo donde
                  se realizaran los siguientes pagos:
                </Typography>
                {listAgreements.map((agreement: any, i) => (
                  <div
                    className={`w_100_per p_x_md p_y_sm br_l_md display_flex flex_align_center flex_justify_between ${i === selectedAgreement
                      ? "bg_neutral_0"
                      : "cursor_pointer"
                      }`}
                    tabIndex={0}
                    role="button"
                    onClick={() => {
                      formik.resetForm();
                      setSelectedAgreement(i);
                      setEditingAgreement(agreement);
                    }}
                    onKeyDown={() => {
                      formik.resetForm();
                      setSelectedAgreement(i);
                      setEditingAgreement(agreement);
                    }}
                  >
                    <Typography
                      scale="medium"
                      weight={i === selectedAgreement ? "600" : "400"}
                    >
                      Pago {i + 1}
                    </Typography>
                    <div className="display_flex flex_gap_md flex_align_center">
                      <Typography
                        scale="medium"
                        textColor="green_300"
                        weight={i === selectedAgreement ? "600" : "400"}
                      >
                        {priceMXNTotal(agreement.amount, 0)}
                      </Typography>
                    </div>
                  </div>
                ))}
                <Button
                  variant="ghost"
                  scale="small"
                  className="m_t_md"
                  onClick={() => {
                    setSelectedAgreement(listAgreements.length);
                    setListAgreements([
                      ...listAgreements,
                      { ...defaultsAgreement },
                    ]);
                    setEditingAgreement(defaultsAgreement);
                    formik.resetForm();
                  }}
                >
                  + Agregar Fecha de pago
                </Button>
              </div>
              <div className="flex_basis_0 flex_grow_1 overflow_x_hidden overflow_y_auto flex_content_col bg_neutral_0 p_md br_md">
                <div className="flex_body p_r_xs">
                  <Input
                    onBlur={formik.handleBlur}
                    error={
                      !!formik.errors.date &&
                      formik.getFieldMeta("date").touched
                    }
                    subtitle={
                      formik.getFieldMeta("date").touched
                        ? (formik.errors.date as string)
                        : undefined
                    }
                    title=""
                    name="date"
                    type="date"
                    className="m_b_xs w_100_per"
                    value={formik.values.date}
                    onChange={(e: any) => {
                      formik.handleChange(e);
                    }}
                  />
                  <Typography scale="medium" weight="400">
                    el ozoner se compromete a pagar:
                  </Typography>
                  <Input
                    onBlur={formik.handleBlur}
                    error={
                      !!formik.errors.amount &&
                      formik.getFieldMeta("amount").touched
                    }
                    subtitle={
                      formik.getFieldMeta("amount").touched
                        ? (formik.errors.amount as string)
                        : undefined
                    }
                    title=""
                    name="amount"
                    type="number"
                    placeholder="$0.0"
                    className="m_b_xs w_100_per"
                    value={formik.values.amount}
                    onChange={(e: any) => {
                      formik.handleChange(e);
                      formik.setFieldValue("amountQuote", "");
                    }}
                  />
                  <Typography scale="medium" weight="400">
                    ó bien, si prefiere en numero de cuotas:{" "}
                  </Typography>

                  <Input
                    title=""
                    name="amountQuote"
                    type="counter"
                    min={0}
                    max={50}
                    value={formik.values.amountQuote}
                    placeholder="Numero de cuotas"
                    className="m_b_xs w_100_per"
                    onChange={(e: any) => {
                      formik.handleChange(e);
                      formik.setFieldValue(
                        "amount",
                        Math.round(
                          e.target.value *
                          (vehicle.paymentWeek ?? 0)
                        )
                      );
                    }}
                  />
                  <Typography
                    scale="medium"
                    weight="400"
                    className="m_b_md"
                  >
                    Comentarios adicionales:
                  </Typography>

                  <textarea
                    onBlur={formik.handleBlur}
                    placeholder="Agrega algún comentario"
                    name="comment"
                    className="m_b_xs agreement_box shadow_medium w_100_per p_md border_1 border_solid border_neutral_100"
                    value={formik.values.comment}
                    onChange={(e: any) => {
                      formik.handleChange(e);
                    }}
                  />
                </div>
                <div className="flex_footer">
                  <Button
                    className="w_100_per "
                    scale="small"
                    disabled={!formik.isValid || !formik.dirty}
                    onClick={() => {
                      const agrees = [...listAgreements];
                      agrees[selectedAgreement] = formik.values;
                      setListAgreements(agrees);
                      formik.resetForm();
                      setEditingAgreement(agrees[selectedAgreement]);
                    }}
                  >
                    Guardar Pago
                  </Button>
                  {listAgreements.length > 1 ? (
                    <Button
                      className="w_100_per m_t_md"
                      color="red"
                      variant="outline"
                      scale="small"
                      onClick={() => {
                        const agrees = [...listAgreements];
                        agrees.splice(selectedAgreement, 1);
                        setListAgreements(agrees);
                        formik.resetForm();
                        setSelectedAgreement(0);
                        setEditingAgreement(agrees[0]);
                      }}
                    >
                      Eliminar Pago
                    </Button>
                  ) : null}
                </div>
              </div>
            </div>
          </Modal>
        </Form>
      )}
    </Formik>
  ) : null}

    {openAgreements ? (
      <Modal
        openConfirmation={openAgreements}
        handleClose={() => {
          setOpenAgreements(false);
          setAgreementDetail(null);
        }}
        icon={<Invoice className="text_primary_300" />}
        isConfirmationLoading={false}
        widthDesktop={40}
        actions={[
          {
            label: "Cerrar historial de pagos",
            color: "grey",
            action: () => setOpenAgreements(false),
          },
        ]}
      >
        <>
          {agreementDetail && (
            <div
              className="agreement_details flex_center"
              onClick={() => setAgreementDetail(null)}
              onKeyPress={() => setAgreementDetail(null)}
              role="button"
              tabIndex={0}
            >
              <div
                className="br_sm shadow_hard overflow_hidden bg_neutral_200 m_b_md pos_relative"
                onClick={(e: any) => e.preventDefault()}
                onKeyPress={(e: any) => e.preventDefault()}
                role="button"
                tabIndex={0}
              >
                <div className="w_per_100 bg_neutral_0 p_md p_b_sm display_flex flex_justify_between flex_align_center">
                  <Typography
                    scale="small"
                    weight="600"
                    textColor="neutral_1000"
                    className=""
                  >
                    {moment
                      .utc(agreementDetail.date)
                      .locale("es")
                      .format("dddd, DD MMMM")}
                  </Typography>
                  <div className="display_flex flex_gap_md flex_align_center">
                    <Typography
                      scale="small"
                      weight="600"
                      textColor="green_300"
                      className=""
                    >
                      {priceMXNTotal(agreementDetail.amount, 0)}
                    </Typography>
                  </div>
                </div>
                <div className="p_y_sm p_x_sm">
                  <div className="display_flex flex_gap_md flex_align_center">
                    <Typography
                      scale="xsmall"
                      weight="600"
                      textColor="neutral_1000"
                      className=""
                    >
                      Comentarios
                    </Typography>
                  </div>
                  <Typography
                    scale="xxsmall"
                    weight="400"
                    textColor="neutral_600"
                    className="overflow_hidden"
                  >
                    {agreementDetail.comment}
                  </Typography>
                  {agreementDetail.status === statusAgreements.onCourse ? (
                    <div className="display_flex flex_gap_xs m_t_sm">
                      <Button
                        scale="small"
                        variant="outline"
                        className="flex_basis_0 flex_grow_1"
                        onClick={() =>
                          changeAgreement(
                            agreements._id,
                            agreementDetail._id,
                            statusAgreements.failed
                          )
                        }
                      >
                        No pagó
                      </Button>
                      <Button
                        scale="small"
                        className="flex_basis_0 flex_grow_1"
                        onClick={() =>
                          changeAgreement(
                            agreements._id,
                            agreementDetail._id,
                            statusAgreements.accomplished
                          )
                        }
                      >
                        Pago recibido
                      </Button>
                    </div>
                  ) : (
                    <div
                      className={`agreement_flag ${agreementDetail.status}`}
                    >
                      <Typography
                        scale="xxsmall"
                        weight="600"
                        textColor="neutral_0"
                      >
                          {agreementDetail.status === statusAgreements.accomplished
                          ? "Pagado"
                          : "Fallido"}
                      </Typography>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          <Typography scale="large" weight="400">
            Historial de
            <Typography scale="large" weight="600" component="span">
              {" "}
              acuerdos de pago del ozoner
            </Typography>
          </Typography>
          <Grid container direction="row" spacing={3} className="">
            {agreementsHist.reduce((acc:any[], agreement:any) => {
              agreement.agreementParts.forEach((ele: any, index: number) => {
                acc.push(
                  <Grid key={`${ele._id}-${index}`} item xs={6}>
                    <div className="br_sm shadow_hard overflow_hidden bg_neutral_200 m_b_md pos_relative">
                      <div className="w_per_100 bg_neutral_0 p_md p_b_sm display_flex flex_justify_between flex_align_center">
                        <Typography
                          scale="small"
                          weight="600"
                          textColor="neutral_1000"
                          className=""
                        >
                          {moment
                            .utc(ele.date)
                            .locale("es")
                            .format("dddd, DD MMMM")}
                        </Typography>
                        <div className="display_flex flex_gap_md flex_align_center">
                          <Typography
                            scale="small"
                            weight="600"
                            textColor="green_300"
                            className=""
                          >
                            {priceMXNTotal(ele.amount, 0)}
                          </Typography>
                          <Pencil
                            className="dim_md text_neutral_600 cursor_pointer"
                            onClick={() => {
                              editAgreement(agreement);
                            }}
                          />
                        </div>
                      </div>
                      <div className="p_y_sm p_x_sm">
                        <div className="display_flex flex_gap_md flex_align_center">
                          <Typography
                            scale="xsmall"
                            weight="600"
                            textColor="neutral_1000"
                            className=""
                          >
                            Comentarios
                          </Typography>
                          <Typography
                            scale="xxsmall"
                            weight="400"
                            textColor="secondary_600"
                            className="cursor_pointer"
                            onClick={() => setAgreementDetail(ele)}
                            onKeyPress={() => setAgreementDetail(ele)}
                            role="button"
                            tabIndex={0}
                          >
                            Leer todo
                          </Typography>
                        </div>
                        <Typography
                          scale="xxsmall"
                          weight="400"
                          textColor="neutral_600"
                          className="h_lg overflow_hidden"
                        >
                          {ele.comment}
                        </Typography>
                        {ele.status === statusAgreements.onCourse ? (
                          <div className="display_flex flex_gap_xs m_t_sm">
                            <Button
                              scale="small"
                              variant="outline"
                              className="flex_basis_0 flex_grow_1"
                              onClick={() =>
                                changeAgreement(
                                  agreements._id,
                                  ele._id,
                                  statusAgreements.failed
                                )
                              }
                            >
                              No pagó
                            </Button>
                            <Button
                              scale="small"
                              className="flex_basis_0 flex_grow_1"
                              onClick={() =>
                                changeAgreement(
                                  agreements._id,
                                  ele._id,
                                  statusAgreements.accomplished
                                )
                              }
                            >
                              Pago recibido
                            </Button>
                          </div>
                        ) : (
                          <div className={`agreement_flag ${ele.status}`}>
                            <Typography
                              scale="xxsmall"
                              weight="600"
                              textColor="neutral_0"
                            >
                                {ele.status === statusAgreements.accomplished
                                ? "Pagado"
                                : "Fallido"}
                            </Typography>
                          </div>
                        )}
                      </div>
                    </div>
                  </Grid>
                );
              });
              return acc;
            }, [])}
          </Grid>
        </>
      </Modal>
    ):null}
  </>
  );
  };

export default AgreementModal;
