
import React from "react";
import { useSearchParams } from "react-router-dom";
import CardRecover from "components/hocs/cardRecover/CardRecover";

// images
import img1 from "static/images/login1.svg";
import img2 from "static/images/login2.svg";


// css
import "../auth/auth.scss";



const Recover = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.getAll("token")[0] || "";
  if(token === ""){
    window.location.href = "/auth";
  }
  return (
    <div className="root flex_center">
      <img alt="circulos" src={img1} className="img1 display_none_mobile" />
      <img alt="orange" src={img2} className="img2  display_none_mobile" />
      <CardRecover token={ token } />
    </div>
  );
};

export default Recover;
