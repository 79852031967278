import { toasterOptions } from "enums/toaster.enum";
import { toasterNotification } from "helpers/globalNotifications/globalNotifications";

export const commonErrorHandler = (err:any)=>{
    toasterNotification({
        // eslint-disable-next-line no-nested-ternary
        msg: err
            ? Array.isArray(err)
                ? err[0]
                : err
            : "Ocurrio un error",
        toasterType: toasterOptions.error,
        style: "light",
    });
};