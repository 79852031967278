import React, { useEffect, useState } from "react";
import { Animated } from "react-animated-css";
import { ReactComponent as Close } from "design_system/src/static/icons/close-sm.svg";

import { Grid, Box } from "@mui/material";
import { Typography } from "design_system/src";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  setupMessageColor,
  transformDate,
  transformTitle,
} from "../../../helpers/collectionNews/utils";
import { getOzonerAgreementById } from "../../../helpers/fetchs/ozoners";

interface IAgreementDetailProps {
  date: string;
  amount: number;
  comment: string;
}

type IOverlayCardsProps = {
  title: string;
  updatedAt: string;
  description: string;
  type: string;
  setOpenDetailModal: any;
  comment?: string;
  agreementDetailProps?: IAgreementDetailProps;
};
const OverlayCards: React.FC<IOverlayCardsProps> = ({
  type,
  title,
  updatedAt,
  description,
  setOpenDetailModal,
  agreementDetailProps,
  comment
}) => {
  const [showCard2, setShowCard2] = useState(false);
  const [removeCard2FromDOM, setRemoveCard2FromDOM] = useState(true);

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };

  const footerStyle = {
    width: "100%",
    display: "flex",
    justifyContent: "space-evenly",
  };

  const handleClick = () => {
    setRemoveCard2FromDOM(false);
    setShowCard2(!showCard2);
  };

  const handleClose = () => {
    setOpenDetailModal(false);
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!showCard2) {
      const timer = setTimeout(() => {
        setRemoveCard2FromDOM(true);
      }, 325);
      return () => clearTimeout(timer);
    }
  }, [showCard2]);

  return (
    <Box style={style}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            width: "40vw",
            height: "40vh",
            backgroundColor: "rgb(255,255,255)",
            borderRadius: 10,
            zIndex: 2,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "stretch",
              alignItems: "stretch",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                style={{
                  backgroundColor: "orange",
                  width: "150px",
                  textAlign: "center",
                  padding: "2.5px",
                  borderBottomRightRadius: 10,
                  borderTopLeftRadius: 10,
                }}
              >
                <Typography
                  scale="medium"
                  weight="600"
                  className="text_center "
                  style={{ color: "white" }}
                >
                  Novedad
                </Typography>
              </div>

              <Typography
                onClick={handleClose}
                className="m_r_md p_t_xxs"
                scale="medium"
                weight="400"
                style={{ cursor: "pointer" }}
              >
                <Close />
              </Typography>
            </div>

            <div style={{ padding: 10 }}>
              <div
                className="m_b_md"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography scale="medium" weight="600">
                  {title}
                </Typography>
                {comment ? (

                  <Typography scale="medium" weight="600">
                    {comment}
                  </Typography>
                ) : null}
                <div>
                  <Typography
                    scale="medium"
                    weight="400"
                    textColor="neutral_600"
                  >
                    Fecha
                  </Typography>
                  <Typography scale="medium" weight="600">
                    {transformDate(updatedAt)}
                  </Typography>
                </div>
              </div>
              <div
                style={{
                  height: "22.5vh",
                  backgroundColor: setupMessageColor(type).backgroundColor,
                  overflowY: "auto",
                }}
              >
                <Typography scale="medium" weight="600" className="m_md">
                  Comentarios
                </Typography>

                <Typography
                  scale="medium"
                  weight="400"
                  className="m_md"
                  style={{ color: setupMessageColor(type).color }}
                >
                  {description}
                </Typography>
              </div>
              <div className="display_flex m_t_md" style={footerStyle}>
                <LoadingButton
                  disableElevation
                  size="small"
                  loading={false}
                  variant="contained"
                  onClick={handleClose}
                  sx={{
                    width: "100%",
                    backgroundColor: "rgb(105, 115, 125)",
                  }}
                >
                  Cancelar
                </LoadingButton>

                {agreementDetailProps && (
                  <>
                    <div className="m_x_md" />
                    <LoadingButton
                      disableElevation
                      size="small"
                      loading={false}
                      variant="contained"
                      onClick={handleClick}
                      sx={{ width: "100%" }}
                    >
                      {showCard2
                        ? "Ocultar Acuerdo de Pago"
                        : "Ver Acuerdo de Pago"}
                    </LoadingButton>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <Animated
          animationIn="slideInLeft"
          animationOut="slideOutLeft"
          isVisible={showCard2}
          animationInDuration={500}
          animationOutDuration={500}
          style={{ display: removeCard2FromDOM ? "none" : "block" }}
        >
          <div
            id="slide_"
            style={{
              zIndex: 1,
              width: "30vw",
              height: "38.5vh",
              backgroundColor: "rgb(237,240,243)",
              borderTopRightRadius: 10,
              borderBottomRightRadius: 10,
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  backgroundColor: "white",
                  borderTopRightRadius: 10,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography scale="medium" weight="600" className="m_md">
                  {agreementDetailProps?.date
                    ? transformDate(agreementDetailProps.date, "full")
                    : "No Info"}
                </Typography>

                <Typography
                  scale="large"
                  weight="600"
                  className="m_md m_r_xl"
                  textColor="green_300"
                >
                  ${agreementDetailProps?.amount} mxn
                </Typography>
              </div>
              <div
                className="p_lg"
                style={{ height: "30vh", overflowY: "scroll" }}
              >
                <Typography scale="medium" weight="600" className="m_b_md">
                  Comentarios
                </Typography>
                <Typography scale="medium" weight="600" className="">
                  {agreementDetailProps?.comment}
                </Typography>
              </div>
            </div>
          </div>
        </Animated>
      </div>
    </Box>
  );
};

export default OverlayCards;
