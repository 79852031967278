/* eslint-disable no-nested-ternary */
import React, { FC, useEffect, useRef, useState } from "react";
import { Button, Knob, Skeleton, Typography } from "design_system/src";

import { ReactComponent as Add } from "design_system/src/static/icons/add-circle.svg";
import { ReactComponent as Close } from "design_system/src/static/icons/close-circle.svg";
import { ReactComponent as Profile } from "design_system/src/static/icons/stars-profile.svg";
import { ReactComponent as Groups } from "design_system/src/static/icons/groups.svg";
import { ReactComponent as Phone } from "design_system/src/static/icons/phone.svg";
import { ReactComponent as Info } from "design_system/src/static/icons/user-information.svg";
import { ReactComponent as Copy } from "design_system/src/static/icons/copy.svg";
import { ReactComponent as Email } from "design_system/src/static/icons/email.svg";
import { ReactComponent as Signal } from "design_system/src/static/icons/radio-signal.svg";
import { ReactComponent as NoSignal } from "design_system/src/static/icons/no-radio-signal.svg";
import { ReactComponent as Send } from "design_system/src/static/icons/cursor.svg";

import { useNavigate, useParams } from "react-router-dom";
import {
    InterestPoint,
    InterestPointStatus,
    InterestPointType,
    InterestPointTypeValues,
    Repossession,
    RepossessionPolicyValues,
} from "models/repossession.interface";
import { getVehicleByInternalId } from "helpers/fetchs/inventory";
import profileDefaultOzoner from "static/images/default_profile_ozoner.png";
import { toasterOptions } from "enums/toaster.enum";
import { getOzonerById } from "helpers/fetchs/ozoners";
import { toasterNotification } from "helpers/globalNotifications/globalNotifications";
import { TCreditPayment } from "models/credit-payment.interface";
import { CreditStatusValues, TCredit } from "models/credit.interface";
import {
    TDataozoner,
    TFullAddress,
    statusOzoners,
} from "models/ozoner.interface";
import { FetchCredit, FetchCreditTotals } from "helpers/fetchs/credits";
import { TVehicle } from "models/vehicle.interface";
import { priceFormatFinancial } from "helpers/prices/prices";
import { FetchRepossession } from "helpers/fetchs/repossession";
import moment from "moment";
import { useSelector } from "react-redux";
import { RootState, store } from "store";
import { addHistoric } from "store/actions/repossessions";
import { LightTooltip } from "components/hocs/tooltip/Tooltip";
import { compareRouteRecoveries } from "./RecoveryMap";

interface TabsProps {
    tabsTitles: string[];
    children: React.ReactNode;
    className: string;
}

const Tabs: FC<TabsProps> = ({ tabsTitles, children, className }) => {
    const [tab, setTab] = useState(0);

    const contents = React.Children.toArray(children) as React.ReactElement[];
    return (
        <div className={`dso_card bg_neutral_200 overflow_hidden ${className}`}>
            <div className="tab_titles bg_neutral_400 display_flex flex_gap_xl p_x_md p_y_xs">
                {tabsTitles.map((title, i) => (
                    <div className="pos_relative" key={title}>
                        <Typography
                            scale="small"
                            weight={tab === i ? "600" : "400"}
                            className="cursor_pointer overflow_ellipsis"
                            onClick={() => setTab(i)}
                        >
                            {title}
                        </Typography>
                        {tab === i ? (
                            <div className="title_mark bg_primary_300 w_100_per h_xxs br_t_xxs pos_absolute" />
                        ) : null}
                    </div>
                ))}
            </div>
            <div className="tabs_body">{contents[tab]}</div>
        </div>
    );
};

export interface IOzonerReferenceProps {
    ozonerPhone: string;
    ozonerEmail: string;
    ozonerRef1: any;
    ozonerRef2: any;
}

export interface CreditGPSDetailsProps {
    detailVehicle?: any;
    map: any;
    loadingHotspots: boolean;
}

const CreditGPSDetail = ({
    detailVehicle: position,
    map,
    loadingHotspots,
}: CreditGPSDetailsProps) => {
    const navigate = useNavigate();

    const contentRef = useRef<HTMLDivElement>(null);

    const [credit, setCredit] = useState<TCredit | null>();
    const [ozoner, setOzoner] = useState<TDataozoner | null>();
    const [vehicle, setVehicle] = useState<TVehicle | null>();
    const [reference1, setReference1] = useState<any>();
    const [reference2, setReference2] = useState<any>();
    const [address, setAddress] = useState<TFullAddress>();
    const [selfie, setSelfie] = useState<any>();
    const [totals, setTotals] = useState<any>();

    const { historicRecoveries } = useSelector(
        (state: RootState) => state.repossessionReducer
    );

    useEffect(() => {
        setOzoner(null);
        setReference1(undefined);
        setReference2(undefined);
        setAddress(undefined);
        setSelfie(undefined);
        setCredit(undefined);
        if (position?.credit) {
            FetchCredit(position.credit).then((data) => {
                setCredit(data);
                setVehicle(data.vehicle);
                setOzoner(data.ozoner);
                let references = [];
                const financialForm = data.ozoner?.financialForm?.filter(
                    (form) => form._id === data.financialFormId
                );
                if (financialForm?.[0]?.personal_references) {
                    references = financialForm[0].personal_references;
                    setReference1(references.personal_reference_1);
                    setReference2(references.personal_reference_2);
                }
                if (data.ozoner?.fullAddress) {
                    setAddress(data.ozoner.fullAddress);
                }
            });
            FetchCreditTotals(position.credit).then((data) => {
                setTotals(data);
            });
        }
    }, [position]);

    useEffect(() => {
        if (ozoner) {
            if (ozoner.documents?.length) {
                const url = ozoner.documents.filter(
                    (obj: any) => obj.typeEvidence === "selfie"
                );
                if (url.length > 0) {
                    setSelfie(url[0].url);
                } else {
                    setSelfie(undefined);
                }
            }
        }
    }, [ozoner]);

    const goWA = (number?: string) => {
        if (number)
            window.open(
                `https://api.whatsapp.com/send?phone=${number}`,
                "_blank"
            );
    };

    const goToOzoner = () => {
        if (ozoner) {
            window.open(
                `${window.location.origin}/ozoner/${ozoner._id}`,
                "_blank"
            );
        }
    };

    const handleCopy = (content?: string) => {
        if (content) {
            navigator.clipboard
                .writeText(content)
                .then(() => {
                    toasterNotification({
                        toasterType: toasterOptions.success,
                        msg: "Copiado al portapapeles",
                        style: "light",
                    });
                })
                .catch((error) => {
                    toasterNotification({
                        toasterType: toasterOptions.error,
                        msg: `Error al copiar el contenido: ${error}`,
                        style: "light",
                    });
                });
        }
    };

    return (
        <div className="h_100_per dso_card bg_neutral_0 p_sm flex_content_col w_100_per">
            {position ? (
                ozoner ? (
                    <>
                        <div className="flex_center m_b_sm p_x_md flex_justify_between flex_gap_lg flex_header">
                            <div className="flex_center flex_gap_lg">
                                <div
                                    className={`dso_card_img br_xs bg_neutral_200 display_flex overflow_hidden border_${
                                        (statusOzoners as any)[
                                            ozoner?.status ?? ""
                                        ]?.color ?? "primary_300"
                                    }`}
                                >
                                    <img
                                        src={
                                            ozoner?.image?.url ||
                                            selfie ||
                                            profileDefaultOzoner
                                        }
                                        alt=""
                                        className="recovery_photo flex_shrink_0"
                                    />
                                </div>
                                <div>
                                    <LightTooltip
                                        title={
                                            (CreditStatusValues as any)[
                                                position.credit?.status
                                            ]?.tooltip ??
                                            position.credit?.status
                                        }
                                    >
                                        <div>
                                            <Typography
                                                scale="xsmall"
                                                weight="600"
                                                textColor={
                                                    (CreditStatusValues as any)[
                                                        position.credit.status
                                                    ]?.color
                                                }
                                            >
                                                {(CreditStatusValues as any)[
                                                    position.credit.status
                                                ]?.title ??
                                                    position.credit.status}
                                            </Typography>
                                        </div>
                                    </LightTooltip>
                                    <Typography scale="small" weight="600">
                                        {ozoner?.name}
                                    </Typography>
                                    <Typography scale="small" weight="600">
                                        {vehicle?.internalId}
                                    </Typography>
                                </div>
                            </div>
                            <div className="display_flex flex_col flex_gap_md flex_justify_between">
                                <Button
                                    scale="small"
                                    icon={<Phone />}
                                    onClick={() => goWA(ozoner?.phone)}
                                >
                                    Contactar
                                </Button>
                                <Button
                                    scale="small"
                                    icon={<Info />}
                                    variant="tint"
                                    onClick={goToOzoner}
                                >
                                    Ver perfil
                                </Button>
                            </div>
                        </div>
                        <div className="m_b_sm bg_neutral_200 display_flex w_100_per br_xs">
                            <div className="border_solid border_1 border_neutral_400 flex_basis_0 flex_grow_1 p_y_xs br_l_xs">
                                <Typography
                                    scale="medium"
                                    weight="600"
                                    textColor="green_300"
                                    className="text_no_break text_center"
                                >
                                    {priceFormatFinancial(totals?.totalPaid, 0)}
                                </Typography>
                                <Typography
                                    scale="xsmall"
                                    weight="400"
                                    textColor="neutral_900"
                                    className="text_no_break text_center"
                                >
                                    pagado
                                </Typography>
                            </div>
                            <div className="display_flex flex_gap_xs flex_justify_center flex_align_center border_solid border_1 border_neutral_400 flex_basis_0 flex_grow_1 p_y_xs">
                                <Knob
                                    value={Math.round(
                                        ((totals?.totalPaid ?? 0) /
                                            (totals?.totalCredit ?? 1)) *
                                            100
                                    )}
                                    variant="fixed"
                                    symbol="%"
                                    className="dim_xl border_transparent"
                                />
                                <div>
                                    <Typography
                                        scale="xsmall"
                                        weight="600"
                                        textColor="neutral_900"
                                    >
                                        Cuotas
                                    </Typography>
                                    <Typography
                                        scale="xsmall"
                                        weight="600"
                                        textColor="neutral_900"
                                    >
                                        pagadas
                                    </Typography>
                                </div>
                            </div>
                            <div className="border_solid border_1 border_neutral_400 flex_basis_0 flex_grow_1 p_y_xs br_r_xs">
                                <Typography
                                    scale="medium"
                                    weight="600"
                                    textColor="red_300"
                                    className="text_center"
                                >
                                    {priceFormatFinancial(totals?.totalDebt, 0)}
                                </Typography>
                                <Typography
                                    scale="xsmall"
                                    weight="400"
                                    textColor="neutral_900"
                                    className="text_no_break text_center"
                                >
                                    Pendiente
                                </Typography>
                            </div>
                        </div>
                        <div className="flex_body">
                            <Tabs
                                tabsTitles={[
                                    "Contacto del ozoner",
                                    "Referencia 1",
                                    "Referencia 2",
                                ]}
                                className="m_b_sm"
                            >
                                <div className="p_x_lg p_y_md">
                                    <div className="display_flex flex_justify_between m_b_xs">
                                        <div className="display_flex flex_gap_md flex_align_center">
                                            <Phone className="dim_lg text_primary_300" />
                                            <Typography
                                                scale="small"
                                                weight="600"
                                                textColor="neutral_900"
                                            >
                                                {ozoner?.phone ?? "--"}
                                            </Typography>
                                        </div>
                                        <div
                                            className="display_flex flex_gap_xs flex_align_center cursor_pointer"
                                            onClick={() =>
                                                handleCopy(ozoner?.phone)
                                            }
                                            role="button"
                                            onKeyDown={() =>
                                                handleCopy(ozoner?.phone)
                                            }
                                            tabIndex={0}
                                        >
                                            <Copy className="dim_md text_neutral_700" />
                                            <Typography
                                                scale="small"
                                                weight="600"
                                                textColor="neutral_700"
                                            >
                                                Copiar
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className="display_flex flex_justify_between">
                                        <div className="display_flex flex_gap_md flex_align_center">
                                            <Email className="dim_lg text_primary_300" />
                                            <Typography
                                                scale="small"
                                                weight="600"
                                                textColor="neutral_900"
                                            >
                                                {ozoner?.email ?? "--"}
                                            </Typography>
                                        </div>
                                        <div
                                            className="display_flex flex_gap_xs flex_align_center cursor_pointer"
                                            onClick={() =>
                                                handleCopy(ozoner?.email)
                                            }
                                            role="button"
                                            onKeyDown={() =>
                                                handleCopy(ozoner?.email)
                                            }
                                            tabIndex={0}
                                        >
                                            <Copy className="dim_md text_neutral_700" />
                                            <Typography
                                                scale="small"
                                                weight="600"
                                                textColor="neutral_700"
                                            >
                                                Copiar
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                                <div className="p_x_lg p_y_md">
                                    <div className="display_flex flex_gap_md flex_align_center m_b_xs">
                                        <Profile className="dim_lg text_primary_300" />
                                        <Typography
                                            scale="small"
                                            weight="600"
                                            textColor="neutral_900"
                                        >
                                            {reference1?.name ?? "--"}
                                        </Typography>
                                    </div>
                                    <div className="display_flex flex_gap_md flex_align_center m_b_xs">
                                        <Groups className="dim_lg text_primary_300" />
                                        <Typography
                                            scale="small"
                                            weight="600"
                                            textColor="neutral_900"
                                        >
                                            {reference1?.relation ?? "--"}
                                        </Typography>
                                    </div>

                                    <div className="display_flex flex_justify_between ">
                                        <div className="display_flex flex_gap_md flex_align_center">
                                            <Phone className="dim_lg text_primary_300" />
                                            <Typography
                                                scale="small"
                                                weight="600"
                                                textColor="neutral_900"
                                            >
                                                {reference1?.phone ?? "--"}
                                            </Typography>
                                        </div>
                                        <div
                                            className="display_flex flex_gap_xs flex_align_center cursor_pointer"
                                            onClick={() =>
                                                handleCopy(reference1?.phone)
                                            }
                                            role="button"
                                            onKeyDown={() =>
                                                handleCopy(reference1?.phone)
                                            }
                                            tabIndex={0}
                                        >
                                            <Copy className="dim_md text_neutral_700" />
                                            <Typography
                                                scale="small"
                                                weight="600"
                                                textColor="neutral_700"
                                            >
                                                Copiar
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                                <div className="p_x_lg p_y_md">
                                    <div className="display_flex flex_gap_md flex_align_center m_b_xs">
                                        <Profile className="dim_lg text_primary_300" />
                                        <Typography
                                            scale="small"
                                            weight="600"
                                            textColor="neutral_900"
                                        >
                                            {reference2?.name ?? "--"}
                                        </Typography>
                                    </div>
                                    <div className="display_flex flex_gap_md flex_align_center m_b_xs">
                                        <Groups className="dim_lg text_primary_300" />
                                        <Typography
                                            scale="small"
                                            weight="600"
                                            textColor="neutral_900"
                                        >
                                            {reference2?.relation ?? "--"}
                                        </Typography>
                                    </div>

                                    <div className="display_flex flex_justify_between ">
                                        <div className="display_flex flex_gap_md flex_align_center">
                                            <Phone className="dim_lg text_primary_300" />
                                            <Typography
                                                scale="small"
                                                weight="600"
                                                textColor="neutral_900"
                                            >
                                                {reference2?.phone ?? "--"}
                                            </Typography>
                                        </div>
                                        <div
                                            className="display_flex flex_gap_xs flex_align_center cursor_pointer"
                                            onClick={() =>
                                                handleCopy(reference2?.phone)
                                            }
                                            role="button"
                                            onKeyDown={() =>
                                                handleCopy(reference2?.phone)
                                            }
                                            tabIndex={0}
                                        >
                                            <Copy className="dim_md text_neutral_700" />
                                            <Typography
                                                scale="small"
                                                weight="600"
                                                textColor="neutral_700"
                                            >
                                                Copiar
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                            </Tabs>
                            <Tabs
                                tabsTitles={["Ubicación", "Motocicleta"]}
                                className="m_b_sm"
                            >
                                <div className="p_x_md p_y_xs">
                                {loadingHotspots && (
                                    <>
                                        <div className="display_flex flex_align_center flex_gap_sm m_b_xs">
                                            <Typography
                                                scale="small"
                                                weight="400"
                                                textColor="neutral_600"
                                                className=""
                                            >
                                                Hotspots
                                            </Typography>
                                            <div className="border_solid border_1 border_neutral_400 flex_grow_1" />
                                        </div>
                                        <Typography
                                            scale="large"
                                            weight="400"
                                            textColor="red_300"
                                            className="p_y_md text_center"
                                        >
                                            Los Hotspots de esta moto se estan
                                            cargando...
                                        </Typography>
                                    </>
                                )}
                                    <div className="display_flex flex_align_center flex_gap_sm m_b_xs">
                                        <Typography
                                            scale="small"
                                            weight="400"
                                            textColor="neutral_600"
                                            className=""
                                        >
                                            Ultima ubicacion
                                        </Typography>
                                        <div className="border_solid border_1 border_neutral_400 flex_grow_1" />
                                    </div>
                                    {position.type === "Point" ? (
                                        <>
                                            {position.isTrackGPS ? (
                                                <div className="display_flex flex_gap_xs flex_align_center m_b_xs">
                                                    <NoSignal className="dim_lg text_neutral_800" />
                                                    <Typography
                                                        scale="small"
                                                        weight="600"
                                                        textColor="neutral_900"
                                                    >
                                                        Moto con ultimo registro
                                                        de Tracker GPS
                                                    </Typography>
                                                </div>
                                            ) : (
                                                <div className="display_flex flex_gap_xs flex_align_center m_b_xs">
                                                    {Math.round(
                                                        (Date.now() -
                                                            new Date(
                                                                position.updatedAt
                                                            ).getTime()) /
                                                            (1000 *
                                                                60 *
                                                                60 *
                                                                24)
                                                    ) <= 3 ? (
                                                        <>
                                                            <Signal className="dim_lg text_green_300" />
                                                            <Typography
                                                                scale="small"
                                                                weight="600"
                                                                textColor="neutral_900"
                                                            >
                                                                Moto con señal
                                                            </Typography>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <NoSignal className="dim_lg text_red_300" />
                                                            <Typography
                                                                scale="small"
                                                                weight="600"
                                                                textColor="neutral_900"
                                                            >
                                                                Moto sin señal
                                                            </Typography>
                                                        </>
                                                    )}
                                                </div>
                                            )}
                                            {/* TODO: Add addres detail based on coordinates */}
                                            {/* <div className="display_flex flex_justify_between m_b_xs">
                                            <Typography
                                                scale="small"
                                                weight="600"
                                                textColor="neutral_900"
                                            >
                                                {position.credit.gps.pos.location.join(
                                                    ", "
                                                )}
                                            </Typography>

                                            <div
                                                className="display_flex flex_gap_xs flex_align_center cursor_pointer"
                                                onClick={() =>
                                                    handleCopy(
                                                        position.credit.gps.pos.location.join(
                                                            ", "
                                                        )
                                                    )
                                                }
                                                role="button"
                                                onKeyDown={() =>
                                                    handleCopy(
                                                        position.credit.gps.pos.location.join(
                                                            ", "
                                                        )
                                                    )
                                                }
                                                tabIndex={0}
                                            >
                                                <Copy className="dim_md text_neutral_700" />
                                                <Typography
                                                    scale="small"
                                                    weight="600"
                                                    textColor="neutral_700"
                                                >
                                                    Copiar
                                                </Typography>
                                            </div>
                                        </div> */}
                                            <div className="m_b_sm bg_neutral_0 display_flex w_100_per br_sm">
                                                <div className="border_solid border_1 border_neutral_300 flex_basis_0 flex_grow_1 p_y_xs br_l_sm flex_center">
                                                    <Typography
                                                        scale="small"
                                                        weight="400"
                                                        textColor="neutral_700"
                                                        className="text_no_break text_center"
                                                    >
                                                        Ubicacion
                                                    </Typography>
                                                </div>
                                                <div className="table_divider h_xl" />
                                                <div className="display_flex flex_gap_xs flex_justify_center flex_align_center border_solid border_1 border_neutral_300 flex_basis_0 flex_grow_1 p_y_xs flex_center">
                                                    <Typography
                                                        scale="small"
                                                        weight="400"
                                                        textColor="neutral_700"
                                                        className="text_center"
                                                    >
                                                        {moment(
                                                            position.updatedAt
                                                        ).format(
                                                            "DD/MMM/YYYY hh:mm a"
                                                        )}
                                                    </Typography>
                                                </div>
                                                {/* <div className="table_divider h_xl" /> */}
                                                <div className="border_solid border_1 border_neutral_300 flex_basis_0 flex_grow_1 p_y_xs br_r_sm">
                                                    <Typography
                                                        scale="small"
                                                        weight="400"
                                                        textColor="neutral_700"
                                                        className="text_center"
                                                    >
                                                        {
                                                            position
                                                                .coordinates[0]
                                                        }
                                                        ,{" "}
                                                        {
                                                            position
                                                                .coordinates[1]
                                                        }
                                                    </Typography>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <Typography
                                            scale="medium"
                                            weight="400"
                                            textColor="neutral_900"
                                            className="p_md text_center"
                                        >
                                            No se tiene informacion disponible
                                            del gps
                                        </Typography>
                                    )}
                                    <div className="display_flex flex_align_center flex_gap_sm m_b_xs">
                                        <Typography
                                            scale="small"
                                            weight="400"
                                            textColor="neutral_600"
                                            className=""
                                        >
                                            Domicilio del ozoner
                                        </Typography>
                                        <div className="border_solid border_1 border_neutral_400 flex_grow_1" />
                                    </div>
                                    {address ? (
                                        <div className="display_flex flex_justify_between m_b_xs">
                                            <Typography
                                                scale="small"
                                                weight="600"
                                                textColor="neutral_900"
                                            >
                                                {`${address.intNumber} ${address.extNumber} ${address.street}, ${address.delegation} ${address.neighborhood}, ${address.cp}, ${address.state}`}
                                            </Typography>

                                            <div
                                                className="display_flex flex_gap_xs flex_align_center cursor_pointer"
                                                onClick={() =>
                                                    handleCopy(
                                                        `${address.intNumber} ${address.extNumber} ${address.street}, ${address.delegation} ${address.neighborhood}, ${address.cp}, ${address.state}`
                                                    )
                                                }
                                                role="button"
                                                onKeyDown={() =>
                                                    handleCopy(
                                                        `${address.intNumber} ${address.extNumber} ${address.street}, ${address.delegation} ${address.neighborhood}, ${address.cp}, ${address.state}`
                                                    )
                                                }
                                                tabIndex={0}
                                            >
                                                <Copy className="dim_md text_neutral_700" />
                                                <Typography
                                                    scale="small"
                                                    weight="600"
                                                    textColor="neutral_700"
                                                >
                                                    Copiar
                                                </Typography>
                                            </div>
                                        </div>
                                    ) : (
                                        <Typography
                                            scale="medium"
                                            weight="400"
                                            textColor="neutral_900"
                                            className="p_md text_center"
                                        >
                                            No se tiene informacion disponible
                                            del domicilio del ozoner
                                        </Typography>
                                    )}
                                </div>
                                <div className="p_x_md p_y_xs">
                                    <div className="img_recovery_cont m_b_md">
                                        <img
                                            src={vehicle?.images?.[0]?.url}
                                            alt=""
                                            className="img_cover"
                                        />
                                    </div>
                                    <div className="display_flex m_b_xs flex_gap_xxs">
                                        <div className="display_flex flex_basis_0 flex_grow_1 bg_neutral_400 p_x_md p_y_xxs flex_gap_xs">
                                            <Typography
                                                scale="small"
                                                weight="600"
                                                textColor="neutral_700"
                                                className=" flex_basis_0 flex_grow_1"
                                            >
                                                Marca
                                            </Typography>
                                            <Typography
                                                scale="small"
                                                weight="400"
                                                textColor="neutral_1000"
                                                className=" flex_basis_0 flex_grow_1"
                                            >
                                                {vehicle?.brand?.name}
                                            </Typography>
                                        </div>
                                        <div className="display_flex flex_basis_0 flex_grow_1 bg_neutral_400 p_x_md p_y_xxs flex_gap_xs">
                                            <Typography
                                                scale="small"
                                                weight="600"
                                                textColor="neutral_700"
                                                className=" flex_basis_0 flex_grow_1"
                                            >
                                                Modelo
                                            </Typography>
                                            <Typography
                                                scale="small"
                                                weight="400"
                                                textColor="neutral_1000"
                                                className=" flex_basis_0 flex_grow_1"
                                            >
                                                {vehicle?.model?.name}
                                            </Typography>
                                        </div>
                                        <div className="flex_basis_0 flex_grow_1 bg_neutral_400 p_x_md p_y_xxs flex_gap_xs">
                                            <Typography
                                                scale="small"
                                                weight="600"
                                                textColor="neutral_700"
                                                className="text_center"
                                            >
                                                Serie vehicular
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className="display_flex flex_gap_xxs">
                                        <div className="display_flex flex_basis_0 flex_grow_1 bg_neutral_400 p_x_md p_y_xxs flex_gap_xs br_bl_xs">
                                            <Typography
                                                scale="small"
                                                weight="600"
                                                textColor="neutral_700"
                                                className=" flex_basis_0 flex_grow_1"
                                            >
                                                Año
                                            </Typography>
                                            <Typography
                                                scale="small"
                                                weight="400"
                                                textColor="neutral_1000"
                                                className=" flex_basis_0 flex_grow_1"
                                            >
                                                {vehicle?.details?.year}
                                            </Typography>
                                        </div>
                                        <div className="display_flex flex_basis_0 flex_grow_1 bg_neutral_400 p_x_md p_y_xxs flex_gap_xs">
                                            <Typography
                                                scale="small"
                                                weight="600"
                                                textColor="neutral_700"
                                                className=" flex_basis_0 flex_grow_1"
                                            >
                                                Cilindraje
                                            </Typography>
                                            <Typography
                                                scale="small"
                                                weight="400"
                                                textColor="neutral_1000"
                                                className=" flex_basis_0 flex_grow_1"
                                            >
                                                {vehicle?.cylindersCapacity}
                                            </Typography>
                                        </div>
                                        <div className="flex_basis_0 flex_grow_1 bg_neutral_400 p_x_md p_y_xxs flex_gap_xs br_br_xs">
                                            <Typography
                                                scale="small"
                                                weight="600"
                                                textColor="neutral_1000"
                                                className="text_center"
                                            >
                                                {vehicle?.engineSN}
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                            </Tabs>
                        </div>
                    </>
                ) : (
                    <div className="p_x_md p_y_xs">
                        <Typography
                            scale="large"
                            weight="400"
                            textColor="neutral_900"
                            className=""
                        >
                            {position.internalId}
                        </Typography>
                        <div className="display_flex flex_align_center flex_gap_sm m_b_xs">
                            <Typography
                                scale="small"
                                weight="400"
                                textColor="neutral_600"
                                className=""
                            >
                                Ultima ubicacion
                            </Typography>
                            <div className="border_solid border_1 border_neutral_400 flex_grow_1" />
                        </div>

                        <div className="m_b_sm bg_neutral_0 display_flex w_100_per br_sm">
                            <div className="border_solid border_1 border_neutral_300 flex_basis_0 flex_grow_1 p_y_xs br_l_sm flex_center">
                                <Typography
                                    scale="small"
                                    weight="400"
                                    textColor="neutral_700"
                                    className="text_no_break text_center"
                                >
                                    Ubicacion
                                </Typography>
                            </div>
                            <div className="table_divider h_xl" />
                            <div className="border_solid border_1 border_neutral_300 flex_basis_0 flex_grow_1 p_y_xs br_r_sm">
                                <Typography
                                    scale="small"
                                    weight="400"
                                    textColor="neutral_700"
                                    className="text_center"
                                >
                                    {position.coordinates[0]},{" "}
                                    {position.coordinates[1]}
                                </Typography>
                            </div>
                        </div>
                    </div>
                )
            ) : (
                <>
                    <div className="display_flex flex_gap_xs h_15_per flex_align_stretch m_b_md">
                        <Skeleton className=" " height="100%" width={90} />
                        <Skeleton
                            className="flex_grow_1"
                            height="100%"
                            width={60}
                        />
                        <Skeleton
                            className="m_l_xxxl"
                            height="100%"
                            width="30%"
                        />
                    </div>
                    {/* <Skeleton className="m_b_lg" height={60} width={60} /> */}
                    {/* <Skeleton className="m_b_xl" height={45} width={45} /> */}
                    <Skeleton className="w_100_per m_b_sm" height="10%" />
                    <Skeleton className="w_100_per m_b_sm" height="15%" />
                    <Skeleton className="w_100_per m_b_sm" height="43%" />
                    <Skeleton className="w_100_per m_b_sm" height="7%" />
                </>
            )}
        </div>
    );
};

export default CreditGPSDetail;
