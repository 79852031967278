/* eslint-disable dot-notation */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from "react";
import { Formik, Form, FormikValues, FormikHelpers } from "formik";
import * as yup from "yup";
import Swal from "sweetalert2";


// icons
import { ReactComponent as Close } from "design_system/src/static/icons/close-circle.svg";
import { ReactComponent as User } from "design_system/src/static/icons/add-profile.svg";
import { ReactComponent as Stars } from "design_system/src/static/icons/stars-profile.svg";
import { ReactComponent as Phone } from "design_system/src/static/icons/numeric-password.svg";
import { ReactComponent as Email } from "design_system/src/static/icons/email.svg";
import { ReactComponent as Settings } from "design_system/src/static/icons/user-setting.svg";


import { Button, Input, Typography, SideModal } from "design_system/src";

// css
import "./ozonio.scss";
import { useNavigate } from "react-router-dom";
import { addOzonio, deleteOzonio, getOzonioRoles } from "helpers/fetchs/ozonios";
import { Role } from "models/ozonio.interface";
import { toasterNotification } from "helpers/globalNotifications/globalNotifications";
import { toasterOptions } from "enums/toaster.enum";

const validationSchema = yup.object({
  name: yup.string().required("Campo requerido"),
  role: yup.string().required("Campo requerido"),
  phone: yup.string().max(13, "Maximo 13 digitos").required("Campo requerido"),
  username: yup.string().email("Debe ser email").required("Campo requerido"),
});

const defaults = {
  _id: "",
  name: "",
  role: "",
  phone: "",
  username: "",
};

interface EditProps {
  open: boolean;
  setOpen: Function;
  setResult: Function;
  values?: any | null;
}

function EditOzonio({
  open,
  setOpen,
  values = defaults,
  setResult,
}: EditProps) {
  const history = useNavigate();
  const [initial, setInitial] = useState<any>(defaults);
  const [loading, setLoading] = useState<boolean>(false);
const [rolesOptions, setRolesOptions] = useState([]);
  const [img, setImg] = useState({
    file: "",
    view: "",
  });

  useEffect(()=>{
    getOzonioRoles().then(roles=>{
      setRolesOptions(roles.map((role:Role)=>({label:role.description,value:role._id})));
    });
  },[]);

  useEffect(() => {

    if (values?.image) {
      setImg({
        file: "",
        view: values.image.url,
      });
    }
    const val: any = { ...values };
    if(values.role){
      val.role = values.role._id;
    }
    setInitial(val);
  }, [values]);

  const handleFile = (e: any) => {
    const file = e.target.files[0];
    if (file.size > 10000000) {
      Swal.fire({
        text: "La imagen no debe ser superior a 10mb",
        icon: "warning",
        confirmButtonText: "Aceptar",
      });
    } else {
      setImg({
        file,
        view: URL.createObjectURL(file),
      });
    }
  };


  const deleteImage = (e: any) => {
    e.stopPropagation();
    setImg({
      file: "",
      view: "",
    });
  };

  const onSubmit = (vals: FormikValues, formik: FormikHelpers<any>) => {
    setLoading(true);

    addOzonio(vals, img)
        .then(async (ele) => {
            setImg({
                file: "",
                view: "",
            });
            setOpen(false);
            setResult(ele);
            setInitial(defaults);
            formik.resetForm();
            setLoading(false);
        })
        .catch((err) => {
            toasterNotification({
                // eslint-disable-next-line no-nested-ternary
                msg: err?(Array.isArray(err)?err[0]:err):"Ocurrio un error",
                toasterType: toasterOptions.error,
                style: "light",
            });
            setLoading(false);
        });
  };

  return (
    values && (
      <SideModal
        open={open}
        setOpen={setOpen}
        size="small"
      >
        <Formik
          validateOnMount
          enableReinitialize
          initialValues={initial}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {(formik) => (
            <Form className="flex_content_col">

              <Typography
                scale="heading2"
                weight="600"
                textColor="neutral_900"
                className="text_center flex_header m_b_xl  "
              >
                {values["_id"] ? "Editar Ozonio" : "Nuevo Ozonio"}
              </Typography>

              <div className="flex_body pretty_slider overflow_x_visible overflow_y_auto">

                <div className="dso_card bg_neutral_0 p_md m_b_xl w_fit center_x">
                  <Typography
                    scale="xsmall"
                    weight="600"
                    textColor="neutral_900"
                    className="text_center m_b_md"
                  >
                    {formik.values.name === ""
                      ? "NOMBRE OZONIO"
                      : formik.values.name}
                  </Typography>
                  <div className="dso_card_img m_b_md bg_neutral_200 w_fit center_x display_flex overflow_hidden">
                    {img.view ? (
                      <>
                        <div className="br_circle bg_neutral_0 delete_photo display_flex" role="button" onKeyDown={deleteImage} onClick={deleteImage} tabIndex={0} >
                          <Close className="text_red_300 dim_lg" />
                        </div>
                        <img src={img.view} alt="" className="cards_photo" />
                      </>
                    ) : (
                      <User className="dim_xl m_lg" />
                    )}
                    <input
                      type="file"
                      accept="image/png, image/jpeg"
                      onChange={handleFile}
                      className="input-file"
                    />
                  </div>
                </div>
                <Input
                  title="Nombre"
                  placeholder="Escribe el nombre aquí"
                  name="name"
                  type="text"
                  icon={<Settings />}
                  className="m_b_xs w_100_per"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    !!formik.errors["name"] &&
                    formik.getFieldMeta("name").touched
                  }
                  subtitle={
                    formik.getFieldMeta("name").touched
                      ? (formik.errors["name"] as string)
                      : undefined
                  }
                />
                <Input
                  title="Rol"
                  placeholder="Escribe el nombre aquí"
                  name="role"
                  type="select"
                  options={rolesOptions}
                  icon={<Stars />}
                  className="m_b_xs w_100_per"
                  value={formik.values.role}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    !!formik.errors["role"] &&
                    formik.getFieldMeta("role").touched
                  }
                  subtitle={
                    formik.getFieldMeta("role").touched
                      ? (formik.errors["role"] as string)
                      : undefined
                  }
                />
                <Input
                  onBlur={formik.handleBlur}
                  error={
                    !!formik.errors["phone"] &&
                    formik.getFieldMeta("phone").touched
                  }
                  subtitle={
                    formik.getFieldMeta("phone").touched
                      ? (formik.errors["phone"] as string)
                      : undefined
                  }
                  title="Número teléfonico"
                  placeholder="Escribe el teléfono aquí"
                  name="phone"
                  type="text"
                  icon={<Phone />}
                  className="m_b_xs w_100_per"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                />
                <Input
                  onBlur={formik.handleBlur}
                  error={
                    !!formik.errors["username"] &&
                    formik.getFieldMeta("username").touched
                  }
                  subtitle={
                    formik.getFieldMeta("username").touched
                      ? (formik.errors["username"] as string)
                      : undefined
                  }
                  title="Correo electronico"
                  placeholder="Escribe el correo aquí"
                  name="username"
                  type="email"
                  icon={<Email />}
                  className="m_b_xs w_100_per"
                  value={formik.values.username}
                  onChange={formik.handleChange}
                />
              </div>


              <div className="flex_footer">
                <Button
                  scale="large"
                  orientation="right"
                  className="w_100_per"
                  type="submit"
                  disabled={
                    loading ||
                    !formik.isValid ||
                    (!formik.dirty &&
                      values?.image?.url === img?.view)
                  }
                >
                  Guardar
                </Button>
              </div>

            </Form>
          )}
        </Formik>
      </SideModal>
    )
  );
}

export default EditOzonio;
