/* eslint-disable no-undef-init */
import React, { useEffect, useRef, useState } from "react";

import moment from "moment";
import "mapbox-gl/dist/mapbox-gl.css";

import {
    RepossessionPolicy,
    Repossession as RepossessionModel,
    InterestPoint,
    InterestPointType,
    FetchRoutesResponse,
    Route,
    InterestPointStatus,
    RecoveryStatus,
    RepossessionStatus,
    LocationType,
    InterestPointTypeValues,
    Repossession,
} from "models/repossession.interface";
import { handleEventGoogle } from "helpers/analytics/googleAnalytics";
import mapboxgl, { Map, MarkerOptions } from "mapbox-gl";

import {
    FetchRepossessionsGPS,
    FetchRoutes,
} from "helpers/fetchs/repossession";
import { getOzonios } from "helpers/fetchs/ozonios";
import { LightTooltip } from "components/hocs/tooltip/Tooltip";

import {
    Button,
    DateRange,
    DateSelect,
    Input,
    Select,
    Typography,
} from "design_system/src";
import { useSelector } from "react-redux";
import { RootState, store } from "store";
import { loadAllLocations, loadData } from "store/actions/repossessions";
import {
    getRepossessionsFilter,
    setRepossessionsFilter,
} from "helpers/localStorage/filterStorage";
import { FetchVehiclesLocations } from "helpers/fetchs/inventory";

import {
    baseFilterOptions,
    locationTypesOptions,
    tabs,
    typesOptions,
} from "./Repossessions.constants";
import RecoveryMap, { compareRouteRecoveries } from "./map/RecoveryMap";
import "./recovery.scss";
import RecoveryRoutes from "./routes/Routes";

export type TDetailsRepossession = {
    repossession: Repossession;
    interestPoint: InterestPoint;
};

export type TDetailsLocation = {
    type: string;
    coordinates: [number, number];
    updatedAt: string;
    isTrackGPS: boolean;
    statusCredit: string;
    internalId: string;
    credit: {
        _id: string;
        status: string;
        code: string;
        internalId: string;
    };
};

export type TDetailsMap = TDetailsRepossession | TDetailsLocation;

export type featureProperties = {
    repossession: RepossessionModel;
    interestPoint: InterestPoint;
    id: string;
    primary: boolean;
}

export const Repossessions = () => {
    // VIEW STATES
    const [query, setQuery] = useState<string>("");
    const [viewTab, setViewTab] = useState(0);
    const [filterOptions, setFilterOptions] = useState(baseFilterOptions);
    const [filters, setFilters] = useState<string[]>([]);
    const [locationsFilter, setLocationsFilter] = useState<LocationType[]>([]);
    const [types, setTypes] = useState<string[]>([
        InterestPointType.signalVehicle,
        InterestPointType.noSignalVehicle,
        InterestPointType.address,
        InterestPointType.hotspot,
    ]);

    // ROUTES STATES
    const [idRuta, setIdRuta] = useState();
    const [ozonios, setOzonios] = useState<any[]>([]);
    const [routeOzonio, setRouteOzonio] = useState<string>("");
    const [openRuta, setOpenRuta] = useState(false);
    const [routes, setRoutes] = useState<Route[]>([]);
    const [datesFilter, setDatesFilter] = useState({
        initial: new Date(),
        last: new Date(),
        option: -1,
    });
    const [hasDatesFilter, setHasDatesFilter] = useState(false);
    const [ozonioFilter, setOzonioFilter] = useState("");
    const [count, setCount] = useState(0);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [routeDate, setRouteDate] = useState<string>(
        moment().format("YYYY-MM-DD")
    );

    // DATA STATES
    const [selecteds, setSelecteds] =
        useState<
            { repossession: RepossessionModel; interestPoint: InterestPoint }[]
        >();
    const [details, setDetails] = useState<TDetailsMap>();

    const { lastUpdate, data, recoveryTotals } = useSelector(
        (state: RootState) => state.repossessionReducer
    );
    const { lastUpdateLocations, allLocations } = useSelector(
        (state: RootState) => state.locationsReducer
    );

    const map = useRef<any>(null);

    useEffect(() => {
        if (filters.length >= filterOptions.length) {
            setFilters([]);
        }
    }, [filters]);
    useEffect(() => {
        if (locationsFilter.length >= locationTypesOptions.length) {
            setLocationsFilter([]);
        }
    }, [locationsFilter]);
    useEffect(() => {
        if (recoveryTotals) {
            setFilterOptions(
                baseFilterOptions.map((filter) => ({
                    value: filter.value,
                    label: `${filter.label} (${
                        (recoveryTotals as any)?.[filter.value] ?? 0
                    })`,
                }))
            );
        }
    }, [recoveryTotals]);

    const toogleTypes = (value: string) => {
        const set = new Set(types);

        if (set.has(value)) {
            set.delete(value);
        } else {
            set.add(value);
        }

        setTypes(Array.from(set));
    };

    const fetchRoutes = () => {
        FetchRoutes(
            hasDatesFilter
                ? {
                      start: datesFilter.initial,
                      end: datesFilter.last,
                  }
                : undefined,
            ozonioFilter
        ).then((resp: FetchRoutesResponse) => {
            const newRoutes = resp.data;
            newRoutes.forEach((route) => {
                let routeSignal = 0;
                let routeNoSignal = 0;
                let address = 0;
                let hotspots = 0;
                let completed = 0;
                route.recoveries.forEach((recovery) => {
                    if (
                        recovery.interestPoint?.type ===
                        InterestPointType.address
                    ) {
                        address += 1;
                    } else if (
                        recovery.interestPoint?.type ===
                        InterestPointType.hotspot
                    ) {
                        hotspots += 1;
                    } else if (
                        recovery.interestPoint?.type ===
                        InterestPointType.signalVehicle
                    ) {
                        routeSignal += 1;
                    } else {
                        routeNoSignal += 1;
                    }
                    if (recovery.status === RecoveryStatus.recovered) {
                        completed += 1;
                    }
                });
                // eslint-disable-next-line no-param-reassign
                route.totals = {
                    signal: routeSignal,
                    noSignal: routeNoSignal,
                    address,
                    hotspots,
                    completed,
                };
            });
            setRoutes(newRoutes);
            setTotalPages(resp.pages);
            setCount(resp.count);
        });
    };

    const fetch = (force?: boolean) => {
        if (force || Date.now() - lastUpdate > 3600000) {
            FetchRepossessionsGPS(
                "",
                1,
                -1,
                "",
                "",
                `${RepossessionStatus.pending},${RepossessionStatus.running}`
            )
                .then((res) => {
                    store.dispatch(
                        loadData(res.data, {
                            ...res.totals,
                            count: Object.values(res.totals).reduce(
                                (acc, ele) => acc + ele,
                                0
                            ),
                        }) as any
                    );
                })
                .catch((e) => {
                    console.log("Error fetch recovery", e);
                });
            FetchVehiclesLocations().then((res: any) => {
                store.dispatch(loadAllLocations(res) as any);
            });
        }
    };

    useEffect(() => {
        fetchRoutes();
    }, [hasDatesFilter, datesFilter, ozonioFilter]);

    const updateLocationsSource = () => {
        if (allLocations?.length) {
            const features = allLocations
                .filter((position: any) => position.coordinates)
                .filter((position: any) => {
                    if (
                        !position.internalId
                            ?.toUpperCase()
                            .includes(query.toUpperCase())
                    )
                        return false;
                    return true;
                })
                .filter((position: any) => {
                    if(details){
                        if((details as any).internalId ===position.internalId)
                            return true;
                        return false;
                    }
                    if (locationsFilter.length) {
                        const daysGap = Math.round(
                            (Date.now() -
                                new Date(position.updatedAt).getTime()) /
                                (1000 * 60 * 60 * 24)
                        );
                        if (
                            locationsFilter.includes(LocationType.gpsTraker) &&
                            position.isTrackGPS
                        ) {
                            return true;
                        }
                        if (
                            locationsFilter.includes(
                                LocationType.signalVehicle
                            ) &&
                            position.type === "Point" &&
                            !position.isTrackGPS
                        ) {
                            return true;
                        }
                        return false;
                    }
                    return true;
                })
                .map((position: any) => {
                    const daysGap = Math.round(
                        (Date.now() - new Date(position.updatedAt).getTime()) /
                            (1000 * 60 * 60 * 24)
                    );
                    return {
                        type: "Feature",
                        properties: {
                            title: position.internalId,
                            description: "",
                            position: JSON.stringify(position),
                            isTrackGPS: position.isTrackGPS,
                            type:
                                position.type === "Point" && !position.isTrackGPS
                                    ? InterestPointType.signalVehicle
                                    : InterestPointType.noSignalVehicle,
                            id: `${position.type}_${position.internalId}`,
                        },
                        geometry: {
                            coordinates: [...position.coordinates],
                            type: "Point",
                        },
                    };
                });

            map.current.getSource("allvehicles")?.setData({
                type: "FeatureCollection",
                features,
            });
        }
    };

    const updateRepossessionsSource = () => {
        if (data && Object.keys(data).length) {

            const allFeatures =  {...data};
            

            const aditionalPoints = [...(selecteds??[])];
            let detailsId:string|undefined = undefined;
            let detailsRepossessionId:string;
            if(details && (details as TDetailsRepossession).interestPoint) {
                const {repossession,interestPoint}=(details as TDetailsRepossession);
                let detailsInterestPointId  = interestPoint._id;
                if(!detailsInterestPointId || !detailsInterestPointId.length) {
                    detailsInterestPointId =`tmp${interestPoint.type}`;
                }
                

                detailsRepossessionId =repossession._id;
                detailsId =  `${detailsInterestPointId}${detailsRepossessionId}`;
                aditionalPoints.push({repossession,interestPoint:{...interestPoint,_id:detailsInterestPointId}});
            }
                aditionalPoints.forEach(point => {
                    const id = `${
                        point.interestPoint._id ?? `tmp${point.interestPoint.type}`
                    }${point.repossession._id}`;
                    if(!(id in allFeatures)){
                        allFeatures[id]={
                            ...point,
                            id,
                            primary: false,
                        };
                    }
                });
    
            const selectedsIds = selecteds?.map(
                (item) =>
                    `${item.interestPoint._id ?? `tmp${item.interestPoint.type}`
                    }${item.repossession._id}`
                    
            );

            const features = Object.values(allFeatures)
                .filter(({ repossession, interestPoint, id, primary }) => {
                    if (
                        !repossession.vehicleId
                            ?.toUpperCase()
                            .includes(query.toUpperCase())
                    )
                        return false;
                    if (openRuta) {
                        if (id!==undefined && selectedsIds?.includes(id)) return true;
                        return false;
                    }
                    if (id === detailsId) return true;
                    if (detailsId) {
                        if (repossession._id === detailsRepossessionId){
                            if (interestPoint.status === InterestPointStatus.expired && id!==undefined && !selectedsIds?.includes(id))
                                return false;
        
                            return true;
                        }
                        return false;
                    }
                    if (interestPoint.status === InterestPointStatus.expired)
                        return false;
                    if (
                        !primary ||
                        interestPoint.type === InterestPointType.hotspot
                    )
                        return false;
                    if (
                        filters.length &&
                        !filters.includes(repossession.policy)
                    )
                        return false;
                    if (!types.includes(interestPoint.type)) return false;
                    return true;
                })
                .map(({ repossession, interestPoint, id }) => {
                    return {
                        type: "Feature",
                        properties: {
                            title: repossession.vehicleId,
                            description: "",
                            policy: repossession.policy,
                            repossession: JSON.stringify(repossession),
                            interestPoint: JSON.stringify(interestPoint),
                            type: interestPoint.type,
                            id,
                            isTrackGPS: interestPoint.isTrackGPS,
                        },
                        geometry: {
                            coordinates: [
                                ...interestPoint.location.coordinates,
                            ],
                            type: "Point",
                        },
                    };
                });
    
            map.current.getSource("vehicles")?.setData({
                type: "FeatureCollection",
                features,
            });
            if (detailsId) {
                const bounds = new mapboxgl.LngLatBounds();
                features.forEach((point) => {
                    bounds.extend(point.geometry.coordinates as any);
                });

                bounds.getEast();
                const size = (bounds.getEast() - bounds.getWest()) * 2.2;
                bounds.extend({
                    lng: bounds.getEast() - size,
                    lat: bounds.getNorth(),
                });

                map.current.fitBounds(bounds, {
                    padding: 40,
                });
            }

        }
    };
    const updateSource = () => {
        updateLocationsSource();
        updateRepossessionsSource();
    };

    useEffect(() => {
        updateSource();
    }, [
        filters,
        data,
        allLocations,
        types,
        selecteds,
        query,
        openRuta,
        locationsFilter,
    ]);
    useEffect(() => {
        handleEventGoogle(
            "VIEW_Panda_repossessions",
            "Panda",
            "page view to the repossessions map view"
        );

        const information = getRepossessionsFilter();
        if (information.viewTab) {
            setViewTab(information.viewTab);
        }
        if (information?.datesFilter) {
            setDatesFilter({
                ...information.datesFilter,
                initial: new Date(information.datesFilter.initial),
                last: new Date(information.datesFilter.last),
            });

            setHasDatesFilter(true);
        } else {
            const weekDate = new Date();
            weekDate.setDate(weekDate.getDate() + 8);
            setDatesFilter({
                initial: new Date(),
                last: weekDate,
                option: -1,
            });
            setHasDatesFilter(true);
        }
        if (information.filterOzonio) {
            setOzonioFilter(information.filterOzonio);
        }
        getOzonios("").then((ozons) => {
            setOzonios(
                ozons.data.map((ozonio: any) => ({
                    value: ozonio._id,
                    label: ozonio.name,
                }))
            );
        });
        fetch();
        fetchRoutes();
    }, []);

    useEffect(() => {
        setRepossessionsFilter({
            viewTab,
            datesFilter: hasDatesFilter ? datesFilter : null,
            filterOzonio: ozonioFilter,
        });
    }, [viewTab, datesFilter, hasDatesFilter, ozonioFilter]);

    const getFilters = () => {
        switch (viewTab) {
            case 0:
                return (
                    <div className="display_flex flex_align_center flex_gap_xl">
                        <Select
                            fixedPlaceholder
                            variant="checkbox"
                            placeholder="Estado"
                            options={locationTypesOptions}
                            value={locationsFilter}
                            className="policy_select"
                            onChange={(e: any) => {
                                setLocationsFilter(e.target.value);
                            }}
                        />
                    </div>
                );
            case 1:
                return (
                    <div className="display_flex flex_align_center flex_gap_xl">
                        <div className="display_flex flex_align_center flex_gap_sm">
                            {typesOptions.map((option, index) => (
                                <LightTooltip
                                    title={option.title}
                                    key={`${option.title}_index`}
                                >
                                    <div
                                        role="button"
                                        tabIndex={0}
                                        onKeyDown={() => {
                                            toogleTypes(option.value);
                                        }}
                                        onClick={() => {
                                            toogleTypes(option.value);
                                        }}
                                        className={`cursor_pointer p_xs br_xs display_flex flex_align_center flex_gap_md ${
                                            types.includes(option.value)
                                                ? "bg_primary_50 text_primary_300"
                                                : ""
                                        }`}
                                    >
                                        {option.icon}
                                    </div>
                                </LightTooltip>
                            ))}
                        </div>
                        <Select
                            fixedPlaceholder
                            variant="checkbox"
                            placeholder="Politicas"
                            options={filterOptions}
                            value={filters}
                            className="policy_select"
                            onChange={(e: any) => {
                                setFilters(e.target.value);
                            }}
                        />
                    </div>
                );
            case 2:
                return (
                    <div className="display_flex flex_align_center flex_gap_md">
                        <Select
                            name="sortVehicles"
                            options={[
                                { label: "Todos los encargados", value: "" },
                                ...ozonios,
                            ]}
                            className="w_20_vp"
                            placeholder="Selecciona un encargado"
                            value={ozonioFilter}
                            onChange={(e: any) =>
                                setOzonioFilter(e.target.value)
                            }
                        />
                        <DateSelect
                            value={datesFilter}
                            hasValue={hasDatesFilter}
                            setHasValue={setHasDatesFilter}
                            placeholder="Fecha de creación"
                            setValue={(e: any) => {
                                setDatesFilter(e);
                                setHasDatesFilter(true);
                            }}
                        />
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className="dim_100_per flex_content_col inner_container bg_neutral_300">
            {/* SearchBar */}
            <div className="w_100_per bg_neutral_0 flex_header p_x_lg p_y_md">
                <div className="display_flex flex_align_center flex_justify_between m_b_md">
                    <Typography
                        scale="heading3"
                        weight="600"
                        className="text_primary_300"
                    >
                        Recuperaciones
                    </Typography>
                    <div className="display_flex flex_align_center flex_gap_sm p_x_xs p_y_xxs bg_neutral_300 br_xs">
                        {tabs.map((tab, i) => (
                            <Typography
                                scale="medium"
                                key={tab}
                                weight={i === viewTab ? "600" : "400"}
                                textColor={
                                    i === viewTab
                                        ? "neutral_1000"
                                        : "neutral_700"
                                }
                                className={`p_x_md p_y_xxs br_xs cursor_pointer ${
                                    i === viewTab
                                        ? "bg_neutral_0 shadow_soft"
                                        : ""
                                }`}
                                onClick={() => setViewTab(i)}
                            >
                                {tab}
                            </Typography>
                        ))}
                    </div>
                </div>
                {getFilters()}
            </div>
            <div
                className={`flex_grow_1 overflow_hidden pos_relative ${
                    viewTab <= 1 ? "" : "display_none"
                }`}
            >
                <RecoveryMap
                    query={query}
                    setQuery={setQuery}
                    loadingData={!data}
                    allLocations={allLocations}
                    ozonios={ozonios}
                    selecteds={selecteds}
                    setSelecteds={setSelecteds}
                    map={map}
                    routeDate={routeDate}
                    setRouteDate={setRouteDate}
                    routeOzonio={routeOzonio}
                    setRouteOzonio={setRouteOzonio}
                    openRuta={openRuta}
                    setOpenRuta={setOpenRuta}
                    idRuta={idRuta}
                    viewTab={viewTab}
                    setIdRuta={setIdRuta}
                    fetchRoutes={fetchRoutes}
                    setViewTab={setViewTab}
                    updateSource={updateSource}
                    isFullMap={!viewTab}
                    details={details}
                    setDetails={setDetails}
                />
            </div>
            {viewTab === 2 ? (
                <RecoveryRoutes
                    routes={routes}
                    fetchRoutes={fetchRoutes}
                    totalPages={totalPages}
                    count={count}
                    setViewTab={setViewTab}
                    setSelecteds={setSelecteds}
                    setRouteDate={setRouteDate}
                    setRouteOzonio={setRouteOzonio}
                    setOpenRuta={setOpenRuta}
                    setIdRuta={setIdRuta}
                />
            ) : null}
        </div>
    );
};
