/* eslint-disable no-param-reassign */
/* eslint-disable dot-notation */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */

import { get, put, post, deletes } from "helpers/axios/axiosInstance";
import { getBrandsModels, setBrands } from "helpers/localStorage/dataStorage";
import { TBrand } from "models/brand.interface";
import { TModel } from "models/model.interface";
import {
  MaintenanceColor,
  MaintenanceColorValues,
  TCity,
  TEditVehicle,
  THub,
  TRequestBrand,
  TRequestCylinderCapacity,
  TRequestvehicle,
  TVehicle,
  VehicleSortOptions,
} from "models/vehicle.interface";
import { FetchSolicitud } from "./solicitudes";




export const getVehicles = async (
  page: number,
  status?: string,
  query?: string,
  aditionalQuery?: string,
  city?: string,
  sort: string = VehicleSortOptions.HIGHER_PRICE,
): Promise<TRequestvehicle | null> => {

  try {
    let path = `v1/vehicle?page=${page}&sort=${sort}`;
    if (status) {
      path = `${path}&status=${status}`;
    }
    if (query) {
      path = `${path}&query=${query}`;
    }
    if (city) {
      path = `${path}&city=${city}`;
    }
    if (aditionalQuery) {
      path = `${path}&${aditionalQuery}`;
    }

    const res = await get(path);
    return res.data as TRequestvehicle;
  } catch (err: any) {
    throw err.response.data;
  }
};

export const getVehicleByInternalId = async (
  internalId: string = ""
): Promise<TVehicle | null> => {
  try {
    const res = await get(`v1/vehicle/${internalId}`);

    return res.data as TVehicle;
  } catch (err: any) {
    throw err.response.data;
  }
};
export const getHotspotsByInternalId = async (
  internalId: string = ""
): Promise<any | null> => {
  try {
    const res = await get(`v1/vehicle/hotspots/${internalId}`);

    return res.data as TVehicle;
  } catch (err: any) {
    throw err.response.data;
  }
};

export const addVehicle = async (
  values: TEditVehicle,
  images: any[],
  id: string
): Promise<TVehicle | null> => {

  try {
    const data = new FormData();

    let color="";
    let found = false;

    (Object.keys(MaintenanceColorValues) as (keyof typeof MaintenanceColorValues)[]).sort((a,b)=>{
      return ((MaintenanceColorValues[a].days??Infinity) - (MaintenanceColorValues[b].days??Infinity))??0;
    }).forEach(key => {
      if(!found && ((MaintenanceColorValues as any)[key].days??Infinity)>=values.maintenanceDays){
        color = key;
        found=true;
      }
    });

    if(!found) {
      color = MaintenanceColor.BLACK;
    }

    data.append("internalId", values.internalId);
    data.append("brand", values.brand);
    data.append("model", values.model);
    data.append("color", values.color);
    data.append("detail", values.detail);
    data.append("description", values.description);
    data.append("warranty", values.warranty);
    if (values.secondaryColor)
      data.append("secondaryColor", values.secondaryColor);
    data.append("cylindersCapacity", values.cylindersCapacity);
    if (values.suffix) data.append("suffix", values.suffix);
    if (values.plate) data.append("plate", values.plate);
    if (values.engineSN) data.append("engineSN", values.engineSN);
    if (values.registrationCard)
      data.append("registrationCard", values.registrationCard);
    data.append("vehicleSN", values.vehicleSN);
    if (values.extraCost !== undefined && values.extraCost !== null)
      data.append("extraCost", `${values.extraCost}`);
    if (values.gpsHardwareCost !== undefined && values.gpsHardwareCost !== null)
      data.append("gpsHardwareCost", `${values.gpsHardwareCost}`);
    if (values.gpsMonthlySoftwareCost !== undefined && values.gpsMonthlySoftwareCost !== null)
      data.append("gpsMonthlySoftwareCost", `${values.gpsMonthlySoftwareCost}`);
    if (values.documentalStatus !== undefined && values.documentalStatus !== null)
      data.append("documentalStatus", `${values.documentalStatus}`);
    if (values.visualStatus !== undefined && values.visualStatus !== null)
      data.append("visualStatus", `${values.visualStatus}`);
    if (values.repairsPrice !== undefined && values.repairsPrice !== null)
      data.append("repairsPrice", `${values.repairsPrice}`);
    if (values.debtsPrice !== undefined && values.debtsPrice !== null)
      data.append("debtsPrice", `${values.debtsPrice}`);
    data.append("purchaseCost", `${values.purchaseCost}`);
    data.append("details[year]", values.year);
    data.append("details[milage]", `${values.milage}`);
    data.append("purchaseDate", `${values.purchaseDate}`);
    data.append("hub", `${values.hub}`);
    if (values.factorKM !== undefined && values.factorKM !== null)
      data.append("factorKM", `${values.factorKM}`);
    data.append("blueBookPrice", `${values.blueBookPrice}`);
    data.append("gpsStatus", `${values.gpsStatus}`);
    data.append("maintenanceDays", `${values.maintenanceDays}`);
    data.append("maintenanceColor", `${color}`);
    data.append("confirmationKM", `${values.confirmationKM}`);
    data.append("newVehicle", `${values.newVehicle}`);
    data.append("isPresale", `${values.isPresale}`);
    data.append("hasTileDevice", `${!!values.hasTileDevice}`);
    data.append("platedVehicle", `${values.platedVehicle}`);
    if (values.brakeType) data.append("brakeType", `${values.brakeType}`);
    // values.creditTime.forEach((ele: any) => {
    //   data.append("creditTime[]", `${ele}`);
    // });
    if (values.creditCalc) {
      values.creditCalc.forEach((ele: any, i: number) => {
        data.append(`creditCalc[${i}][weeks]`, ele.weeks);
        data.append(`creditCalc[${i}][interestRate]`, ele.interestRate);
      });
    }

    // if (image.file)
    //   data.append("imageFile", image);
    const imgs: any[] = [];
    images.forEach((img, i) => {
      if (img.name) {
        imgs.push({ name: img.name });
      } else {
        const now = Date.now() + 100 * i;
        imgs.push({ name: `Foto${now}` });
        data.append(`Foto${now}`, img.file);
      }
    });
    data.append("images", JSON.stringify(imgs));

    let res;
    if (values["_id"]) {
      res = await put(`v1/vehicle/${id}`, data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    } else {
      res = await post("v1/vehicle", data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    }
    return res.data as TVehicle;
  } catch (err: any) {
    throw err.response?.data?.message??err;
  }
};

export const editVehicle = async (
  values: any,
  id: String
): Promise<TVehicle | null> => {
  try {
    const data = new FormData();
    Object.keys(values).forEach((k) => {
      data.append(k, values[k]);
    });

    const res = await put(`v1/vehicle/${id}`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    return res.data as TVehicle;
  } catch (err: any) {
    throw err.response.data.message;
  }
};
export const deleteVehicle = async (id: String): Promise<any> => {
  try {
    const res = await deletes(`v1/vehicle/${id}`);
    return res;
  } catch (err: any) {
    throw err.response.data.message;
  }
};
export const estimatePrice = async (blue: number, km: number, total: number, weeks: number, interestRate:number, repairsPrice?:number, debtsPrice?:number ): Promise<any> => {
  try {
    const res = await get(`v1/vehicle/estimatePrice?blueBookPrice=${blue
      }&purchaseCost=${total
      }&factorKM=${km
      }&interestRate=${interestRate
      }&weeks=${weeks}
      ${repairsPrice!==undefined?`&repairsPrice=${repairsPrice}`:""}
      ${debtsPrice!==undefined?`&debtsPrice=${debtsPrice}`:""}
      `);
    return res;
  } catch (err: any) {
    throw err.response.data.message;
  }
};
export const addDiscount = async (
  values: any,
  id: String,
  other?: string | null
): Promise<TVehicle | null> => {
  try {
    if (other) {
      await put(`v1/vehicle/${id}/discount`, {
        id: other,
        status: "inactive",
      });
    }
    const res = await post(`v1/vehicle/${id}/discount`, values);

    return res.data as TVehicle;
  } catch (err: any) {
    throw err.response.data.message;
  }
};

export const deleteDiscount = async (
  discount: any,
  id: String
): Promise<TVehicle | null> => {
  try {
    const res = await put(`v1/vehicle/${id}/discount`, {
      id: discount,
      status: "inactive",
    });

    return res.data as TVehicle;
  } catch (err: any) {
    throw err.response.data.message;
  }
};

export const getBrandsWithModels = async (): Promise<TBrand[] | null> => {
  try {
    let info = getBrandsModels();
    if (!info) {
      const res = await get("v1/brands");
      info = res.data;
      setBrands(info);
    }
    return info;
  } catch (err: any) {
    throw err.response.data;
  }
};
export const getHubs = async (): Promise<THub[] | null> => {
  try {
    const res = await get("v1/hub");
    return res.data as THub[];
  } catch (err: any) {
    throw err.response.data;
  }
};

export const getCities = async (): Promise<TCity[] | null> => {
  try {
    const res = await get("v1/city");
    return res.data as TCity[];
  } catch (err: any) {
    throw err.response.data;
  }
};

export const getExport = async (
  pages?: string,
  params?: string,
  limit?:number
): Promise<any> => {
  try {

    let path = "v1/vehicle/export";
    let first = true;

    if (pages) {
      path = `${path}${first?"?":"&"}pages=${pages}`;
      first=false;
    }
    if (limit) {
      path = `${path}${first?"?":"&"}limit=${limit}`;
      first=false;
    }
    if (params) {
      path = `${path}${first?"?":"&"}${params}`;
      first=false;
    }


    const res = await get(path);
    return res.data;
  } catch (err: any) {
    throw err.response.data;
  }
};

export const addFile = async (vehicle: string, file: File): Promise<any> => {
  try {
    const data = new FormData();
    data.append("name", file.name);
    data.append("file", file);

    const res = await put(`v1/vehicle/${vehicle}/document`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return res.data;
  } catch (err: any) {
    throw err.response.data.message;
  }
};
export const deleteFile = async (
  vehicle: string,
  file: string
): Promise<any> => {
  try {
    const res = await deletes(`v1/vehicle/${vehicle}/document`, {
      data: { name: file },
    });
    return res.data;
  } catch (err: any) {
    throw err.response.data.message;
  }
};

export const FetchVehiclesLocations = async (): Promise<any> => {
  try {
    const res = await get<any>(
      "/v1/vehicle/locations/map"
    );
    return res.data;
  } catch (err: any) {
    throw err.response.data;
  }
};
