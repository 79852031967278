/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import CircularProgress from "@mui/material/CircularProgress";
import Swal from "sweetalert2";
import { fetchLogin, fetchProfile } from "helpers/fetchs/auth";
import { useNavigate } from "react-router-dom";

// logos
import logo from "static/logos/logo_pandaboard.svg";

// css
import "./login.scss";

// icons
import {ReactComponent as Boy} from "design_system/src/static/icons/boy.svg";

import {ReactComponent as Lock} from "design_system/src/static/icons/lock-square.svg";
import { Button, Input, Typography } from "design_system/src";

import { TLoginResponse } from "models/profile";
import { clearLastUrl, getLastUrl, setLastUrl, setUser } from "helpers/localStorage/authStorage";

const validationSchema = yup.object({
  username: yup.string().required("Campo requerido"),
  password: yup.string().required("Campo requerido"),
});

function CardLogin() {
  const navigate = useNavigate();
  const [loading, setloading] = useState<boolean>(false);
  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema,
    onSubmit: (value) => {
      
      setloading(true);
      fetchLogin(value)
        .then((res: TLoginResponse) => {
          setloading(false);
          fetchProfile(res.access_token).then((prof) => {
            setUser(prof);
            // TODO: update localstore y redux
            const lastUrl=getLastUrl();
            if (lastUrl){
              clearLastUrl();
              // eslint-disable-next-line no-restricted-globals
              location.href = lastUrl;
            }
            else{
              navigate("/");
            }
          });
        })
        .catch(() => {
          // devuelve statusCode y message
          setloading(false);
          Swal.fire({
            title: "Error!",
            text: "Usuario o contraseña incorrecta",
            icon: "error",
            confirmButtonText: "Aceptar",
          });
        });
    },
  });

  return (
      <div
        className="
        dso_card 
        login_card 
        bg_neutral_0 
        p_xxl 
        shadow_hard"
      >
        <img alt="Logo" src={logo} className="logo center_x m_b_xxl" />
        <form onSubmit={formik.handleSubmit}>
              <Typography scale="medium" weight="600" className=" m_b_md text_center">
                LOGIN
              </Typography>

              <Input
                title="Usuario"
                type="text"
                name="username"
                placeholder="Escriba su usuario aquí" 
                value={formik.values.username}
                onChange={formik.handleChange}
                icon={<Boy/>}
                className="m_b_md dso_width_100_per" />
              <Input
                title="Contraseña"
                type="password"
                name="password"
                placeholder="Escriba su contraseña aquí" 
                value={formik.values.password}
                onChange={formik.handleChange}
                icon={<Lock/>}
                className="m_b_xxl dso_width_100_per" /> 
              <Button
              type="submit"
              disabled={loading ||!(formik.isValid)}
              scale="small"
              className="w_100_per m_b_md m_t_xxs"
              icon={<Boy/>}
              >
                {loading ? (
                  <CircularProgress className="loading" />
                ) : (
                  "Ingresar"
                )}
              </Button>
        </form>
      </div>
  );
}

export default CardLogin;
