import React, {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import OzonMap from "../../../components/hocs/map/OzonMap";
import MyLocationButton from "../../../components/hocs/map/MyLocationButton";
import { placesReducer } from "../../../context/places/placesReducer";
import { PlacesState } from "../../../context/places/PlacesProvider";
import { MapContext, PlacesContext } from "../../../context";

const useVehicles = () => {
  return [
    {
      id: "VOL-653CD2",
      location: "New York, NY, USA",
      latitude: 40.74759625348667,
      longitude: -74.00422032706065,
      temp: "8°C",
      startedAt: "Sep 01, 7:53 AM",
      departedAt: "Sep 01, 8:02 AM",
      arrivedAt: "Sep 01, 8:18 AM",
    },
    {
      id: "VOL-653CD3",
      location: "New York, NY, USA",
      latitude: 40.75374208987527,
      longitude: -74.02878378307403,
      temp: "6°C",
      startedAt: "Sep 01, 8:21 AM",
      departedAt: "Sep 01, 8:36 AM",
      arrivedAt: "Sep 01, 9:54 AM",
    },
    {
      id: "VOL-653CD4",
      location: "New York, NY, USA",
      latitude: 40.765281069832085,
      longitude: -73.96392724511145,
      temp: "8°C",
      startedAt: "Sep 01, 6:34 AM",
      departedAt: "Sep 01, 7:41 AM",
      arrivedAt: "Sep 01, 9:20 AM",
    },
  ];
};

const usePlaces = () => {
  return [
    {
      id: "VOL-653CD3",
      center: [-99.20101614570018, 19.43086822603641],
      text_es: "Vehiculo OMX32",
      place_name_es: "Monumento a la Revolución",
    },
  ];
};

const INITIAL_STATE: PlacesState = {
  isLoading: true,
  userLocation: undefined,
  isLoadingPlaces: false,
  places: [],
};
const OzonerLocation = () => {
  const [state, dispatch] = useReducer(placesReducer, INITIAL_STATE);

  const { searchPlacesByTerm } = useContext(PlacesContext);
  const { isMapReady } = useContext(MapContext);

  const rootRef = useRef(null);
  const mdUp = useMediaQuery((theme: any) => theme.breakpoints.up("md"));
  // const vehicles = useVehicles();
  const vehicles = usePlaces();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [currentVehicleId, setCurrentVehicleId] = useState<any>(
    vehicles[0]?.id
  );
  useEffect(() => {
    if (isMapReady) {
      searchPlacesByTerm(vehicles);
    }
  }, [isMapReady]);

  const handleVehicleSelect = useCallback((vehicleId) => {
    setCurrentVehicleId(vehicleId);
  }, []);

  const handleVehicleDeselect = useCallback(() => {
    setCurrentVehicleId(undefined);
  }, []);

  const handleDrawerOpen = useCallback(() => {
    setOpenDrawer(true);
  }, []);

  const handleDrawerClose = useCallback(() => {
    setOpenDrawer(false);
  }, []);

  return (
    <>
      <OzonMap />
      <MyLocationButton />
    </>
  );
};

export default OzonerLocation;
