/* eslint-disable no-nested-ternary */
/* eslint-disable dot-notation */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from "react";
import antecedentes from "static/images/truora/antecedentes.png";
import identidad from "static/images/truora/identidad.png";
import truora from "static/images/truora/truora.png";
// import palenca from "static/images/truora/palenca.png";
import { Typography, ModalConfirmation as Modal, DataTable, Button, Knob } from "design_system/src";
import { ReactComponent as PDF } from "design_system/src/static/icons/pdf.svg";
import moment from "moment";
import { getTruoraPdf } from "helpers/fetchs/ozoners";
import { Grid } from "@mui/material";
import { handleEventGoogle } from "helpers/analytics/googleAnalytics";


const backgrounds = {
  affiliations_and_insurances: "Afiliaciones y seguros",
  alert_in_media: "Alertas en medios",
  behavior: "Comportamiento",
  business_background: "Antecedentes comerciales",
  criminal_record: "Antecedentes penales",
  driving_licenses: "Licencias de conducir",
  international_background: "Antecedentes internacionales",
  legal_background: "Antecedentes legales",
  personal_identity: "Identidad personal",
  professional_background: "Antecedentes profesionales",
  traffic_fines: "Multas de tráfico",
  vehicle_information: "Información del vehículo",
  vehicle_permits: "Permisos de vehículos",
  taxes_and_finances: "Impuestos y finanzas",
  document_validation: "Validacion de documentos",
  politically_exposed_person: "Persona expuesta politicamente"
};

interface TruoraProps {
  open: boolean;
  setOpen: Function;
  truoraData: any;
  ozonerDocuments: any
}

function TruoraDetails({
  open,
  setOpen,
  truoraData,
  ozonerDocuments,
}: TruoraProps) {
  const [stepTruora, setStepTruora] = useState("select");

  const downloadPdf = (id: string) => {
    if (id) {
      getTruoraPdf(id).then((data) => {
        // const blob = new Blob([data], { type: "text/csv;charset=utf-8;" });
        // const link = document.createElement("a");
        // if (link.download !== undefined) {
        //   // Browsers that support HTML5 download attribute
        //   const url = URL.createObjectURL(blob);
        //   link.setAttribute("href", url);
        //   link.setAttribute("download", "truora.pdf");
        //   link.style.visibility = "hidden";
        //   document.body.appendChild(link);
        //   link.click();
        //   document.body.removeChild(link);
        //   setOpen(false);
        // }
        const url = window.URL.createObjectURL(
          new Blob([data], { type: "application/pdf" })
        );
        const link = document.createElement("a");
        link.target = "_blank";
        link.href = url;
        link.download = "truora.pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      });
    }
  };

  const renderTruoraIdentity = (data: any[]) => {
    const face = data.filter(ele => ele.type === "face-recognition")[0];
    const documents = data.filter(ele => ele.type === "document-validation")[0];
    const proceso = [
      { label: "Estado validacion foto", value: face?.validation_status === "success" ? "Exitosa" : (face?.validation_status === "pending" ? "Pendiente" : "Fallida"), valueColor: face?.validation_status === "success" ? "green_300" : "red_300" },
      { label: "Estado validacion Documentos", value: documents?.validation_status === "success" ? "Exitosa" : (documents?.validation_status === "pending" ? "Pendiente" : "Fallida"), valueColor: documents?.validation_status === "success" ? "green_300" : "red_300" },
      { label: "ID proceso", value: face?.validation_id ?? "--" },
      { label: "ID Cuenta", value: face?.account_id ?? "--" },
      { label: "Fecha de creación", value: moment(face?.creation_date).format("D MMM YYYY hh:mm") ?? "--" },
      { label: "Puntuación global del check", value: `${face?.details?.face_recognition_validations?.confidence_score ?? "--"}` },
    ];
    const details = [
      { label: "Nombres", value: documents?.details?.document_details?.name ?? "--" },
      { label: "Apellidos", value: documents?.details?.document_details?.last_name ?? "--" },
      { label: "Fecha de nacimiento", value: documents?.details?.document_details?.date_of_birth ? moment(documents?.details?.document_details?.date_of_birth).format("D MMM YYYY") : "--" },
      { label: "Género", value: documents?.details?.document_details?.gender === "male" ? "Maculino" : "Femenino" },
      { label: "Tipo de documento", value: documents?.details?.document_details?.document_type ?? "--" },
      { label: "Número de documento", value: documents?.details?.document_details?.document_number ?? "--" },
      { label: "Año de expedición", value: documents?.details?.document_details?.issue_date ? moment(documents?.details?.document_details?.issue_date).format("YYYY") : "--" },
      { label: "Fecha de registro", value: documents?.details?.document_details?.registration_date ? moment(documents?.details?.document_details?.registration_date).format("YYYY") : "--" },
      { label: "Municipio", value: documents?.details?.document_details?.mexico_document?.municipality_name ?? "--" },
      { label: "Estado", value: documents?.details?.document_details?.mexico_document?.state_name ?? "--" },
      { label: "Localidad", value: `${documents?.details?.document_details?.mexico_document?.locality ?? "--"}` },
      { label: "Sección", value: `${documents?.details?.document_details?.mexico_document?.section ?? "--"}` },
      { label: "Clave electoral", value: documents?.details?.document_details?.mexico_document?.elector_key ?? "--" },
      { label: "Domicilio", value: documents?.details?.document_details?.residence_address ?? "--" },
    ];
    const docsValid = documents?.attachment_validations.reduce((acc: any, val: any) => { acc[`${val.validation_type}-${val.attachment_type}`] = val.result; return acc; }, {});
    const validDocuments = [
      { label: "ID Cuenta", value: documents?.account_id ?? "--" },
      { label: "ID Validacion", value: documents?.validation_id ?? "--" },
      { label: "Fecha de creación", value: moment(documents?.creation_date).format("D MMM YYYY hh:mm") ?? "--" },
      { label: "Validacion de detección facial", value: docsValid["face-detection-validation-document-front"] === "valid" ? "Validado" : "Fallido", valueColor: docsValid["face-detection-validation-document-front"] === "valid" ? "green_300" : "red_300" },
      { label: "Validacion de texto legible frontal", value: docsValid["ocr-validation-document-front"] === "valid" ? "Validado" : "Fallido", valueColor: docsValid["ocr-validation-document-front"] === "valid" ? "green_300" : "red_300" },
      { label: "Validacion de texto legible tracera", value: docsValid["ocr-validation-document-reverse"] === "valid" ? "Validado" : "Fallido", valueColor: docsValid["ocr-validation-document-reverse"] === "valid" ? "green_300" : "red_300" },
    ];

    const front = ozonerDocuments ? ozonerDocuments.filter((ele: any) => ele.typeEvidence === "ine_front")[0]?.url : "";
    const back = ozonerDocuments ? ozonerDocuments.filter((ele: any) => ele.typeEvidence === "ine_back")[0]?.url : "";
    const selfie = ozonerDocuments ? ozonerDocuments.filter((ele: any) => ele.typeEvidence === "selfie")[0]?.url : "";



    return (
      <div className="display_flex flex_gap_xxl">
        <div className="flex_basis_0 flex_grow_1 overflow_hidden">
          <DataTable
            title="Proceso de Identidad"
            data={proceso}
            className="m_b_xl"
          />
          <DataTable
            title="Detalles de la validación"
            subtitle="Información extraída del documento"
            data={details}
          />
        </div>
        <div className="flex_basis_0 flex_grow_1 overflow_hidden">
          <div className="display_flex w_100_per m_b_xl">
            <DataTable
              title="ID frontal"
              data={[{ value: (<img src={front} alt="id-front" className="w_100_per" />) }]}
              className="overflow_hidden flex_basis_0 flex_grow_1 "
            />
            <DataTable
              title="ID posterior"
              data={[{ value: (<img src={back} alt="id-front" className=" w_100_per" />) }]}
              className="overflow_hidden flex_basis_0 flex_grow_1"
            />
          </div>
          <DataTable
            title="Validaciones de documento"
            data={validDocuments}
            className="m_b_xl"
          />
          <div className="display_flex w_100_per">
            <DataTable
              title="Selfie"
              data={[{ value: (<img src={selfie} alt="id-front" className=" w_100_per" />) }]}
              className="overflow_hidden flex_basis_0 flex_grow_1"
            />
            <DataTable
              title="ID frontal"
              data={[{ value: (<img src={front} alt="id-front" className="w_100_per" />) }]}
              className="overflow_hidden flex_basis_0 flex_grow_1 "
            />
          </div>

        </div>
      </div>
    );
  };
  const renderTruoraHistory = (data: any[]) => {
    // const face = data.filter(ele => ele.type === "face-recognition")[0];
    const documents = data.filter(ele => ele.type === "document-validation")[0];
    const check = data.filter(ele => ele.type === "score")[0];
    const details = [
      { label: "Nombre", value: `${documents?.details?.document_details?.name ?? ""} ${documents?.details?.document_details?.last_name ?? ""}` },
      { label: "Género", value: documents?.details?.document_details?.gender === "male" ? "Maculino" : "Femenino" },
      { label: "CURP", value: documents?.details?.document_details?.document_number ?? "--" },
      { label: "País", value: documents?.details?.document_details?.country ?? "--" },
      { label: "Fecha de nacimiento", value: documents?.details?.document_details?.date_of_birth ? moment(documents?.details?.document_details?.date_of_birth).format("D MMM YYYY") : "--" },
      { label: "Check ID", value: documents?.details?.background_check?.check_id ?? "--" },
      { label: "Estado", value: check.status === "completed" ? "Exitosa" : (check.status === "in_progress" ? "Pendiente" : "Fallida"), valueColor: check.status === "completed" ? "green_300" : (check.status === "in_progress" ? "yellow_100" : "red_300") },
    ];
    const validations = check.scores?.map((score:any)=>{
      let scoreColor = "green_300";
      if (score.score < 0.8)
        scoreColor = "yellow_100";
      if (score.score < 0.5)
        scoreColor = "red_300";
      return { label: (backgrounds as any)[score.data_set], value: `${Math.floor(score.score * 100)/10}`, valueColor:scoreColor };
    })??[];
    
    // const docsValid = documents?.attachment_validations.reduce((acc:any, val:any) => {acc[`${val.validation_type}-${val.attachment_type}`]=val.result; return acc;},{});
    // const validDocuments = [
    //   { label: "ID Cuenta", value: documents.account_id ?? "--" },
    //   { label: "ID Validacion", value: documents.validation_id ?? "--" },
    //   { label: "Fecha de creación", value: moment(documents.creation_date).format("D MMM YYYY hh:mm") ?? "--" },
    //   { label: "Validacion de detección facial", value: docsValid["face-detection-validation-document-front"] ==="valid"?"Validado":"Fallido", valueColor:docsValid["face-detection-validation-document-front"] ==="valid"?"green_300":"red_300" },
    //   { label: "Validacion de texto legible frontal", value: docsValid["ocr-validation-document-front"] ==="valid"?"Validado":"Fallido", valueColor:docsValid["ocr-validation-document-front"] ==="valid"?"green_300":"red_300" },
    //   { label: "Validacion de texto legible tracera", value: docsValid["ocr-validation-document-reverse"] ==="valid"?"Validado":"Fallido", valueColor:docsValid["ocr-validation-document-reverse"] ==="valid"?"green_300":"red_300" },
    // ];

    // const front = ozoner?.documents ? ozoner.documents.filter((ele: any) => ele.typeEvidence === "ine_front")[0].url:"";
    // const back = ozoner?.documents ? ozoner.documents.filter((ele: any) => ele.typeEvidence === "ine_back")[0].url:"";
    // const selfie = ozoner?.documents ? ozoner.documents.filter((ele: any) => ele.typeEvidence === "selfie")[0].url:"";

    const score = Math.floor((check?.score??0)*100)/10;
    let scoreColor = "green_300";
    if(score < 8)
    scoreColor = "yellow_100";
    if(score < 5)
    scoreColor = "red_300";

    return (<Grid container spacing={2}>
      <Grid item xs={8}>
        <DataTable
          title="Datos del usuario"
          data={details}
        />
      </Grid>
      <Grid item xs={4} >
        <div className="p_t_xxxl">
          <div className="bg_neutral_0 br_sm p_y_md p_x_xxl p_b_md flex_center_col flex_justify_between flex_gap_xs h_100_per">
            <Knob
              total={10}
              value={score}
              className="w_80_per"
              color={scoreColor}
            />
            <div className="flex_col_center">
              <Typography scale="small" weight="400" className="text_center">
                Fecha y hora de consulta:
              </Typography>
              <Typography scale="large" weight="600" className="text_center">
                {moment(documents?.creation_date).format("D MMM YYYY hh:mm")}
              </Typography>
            </div>
          </div>
        </div>
        <div className="flex_center_col p_t_md flex_gap_sm">
          <Typography scale="medium" weight="600" className="text_center">
            Detalles de la consulta:
          </Typography>
          <Button scale="small" icon={<PDF />} onClick={() => downloadPdf(check?.check_id)} className="w_100_per">Descargar PDF</Button>
        </div>
      </Grid>
      <Grid item xs={12} >
        <DataTable
          title="Validaciones individuales"
          data={validations}
        />
      </Grid>
    </Grid>
    );
  };
  const renderTruoraPersona = (data: any[]) => {
    // const face = data.filter(ele => ele.type === "face-recognition")[0];
    const documents = data.filter(ele => ele.type === "document-validation")[0];
    const check = data.filter(ele => ele.type === "person")[0];
    const details = [
      { label: "Nombre", value: `${documents?.details?.document_details?.name ?? ""} ${documents?.details?.document_details?.last_name ?? ""}` },
      { label: "Género", value: documents?.details?.document_details?.gender === "male" ? "Maculino" : "Femenino" },
      { label: "CURP", value: documents?.details?.document_details?.document_number ?? "--" },
      { label: "País", value: documents?.details?.document_details?.country ?? "--" },
      { label: "Fecha de nacimiento", value: documents?.details?.document_details?.date_of_birth ? moment(documents?.details?.document_details?.date_of_birth).format("D MMM YYYY") : "--" },
      { label: "Check ID", value: documents?.details?.background_check?.check_id ?? "--" },
      { label: "Estado", value: (check?.status === "completed" || check?.status === "delayed") ? "Exitosa" : (check?.status === "in_progress" ? "Pendiente" : "Fallida"), valueColor: (check?.status === "completed" || check?.status === "delayed") ? "green_300" : (check?.status === "in_progress" ? "yellow_100" : "red_300") },
    ];
    const validations = check?.scores?.map((score:any)=>{
      let scoreColor = "green_300";
      if (score.score < 0.8)
        scoreColor = "yellow_100";
      if (score.score < 0.5)
        scoreColor = "red_300";
      return { label: (backgrounds as any)[score.data_set], value: `${Math.floor(score.score * 100)/10}`, valueColor:scoreColor };
    })??[];
    // const docsValid = documents?.attachment_validations.reduce((acc:any, val:any) => {acc[`${val.validation_type}-${val.attachment_type}`]=val.result; return acc;},{});
    // const validDocuments = [
    //   { label: "ID Cuenta", value: documents.account_id ?? "--" },
    //   { label: "ID Validacion", value: documents.validation_id ?? "--" },
    //   { label: "Fecha de creación", value: moment(documents.creation_date).format("D MMM YYYY hh:mm") ?? "--" },
    //   { label: "Validacion de detección facial", value: docsValid["face-detection-validation-document-front"] ==="valid"?"Validado":"Fallido", valueColor:docsValid["face-detection-validation-document-front"] ==="valid"?"green_300":"red_300" },
    //   { label: "Validacion de texto legible frontal", value: docsValid["ocr-validation-document-front"] ==="valid"?"Validado":"Fallido", valueColor:docsValid["ocr-validation-document-front"] ==="valid"?"green_300":"red_300" },
    //   { label: "Validacion de texto legible tracera", value: docsValid["ocr-validation-document-reverse"] ==="valid"?"Validado":"Fallido", valueColor:docsValid["ocr-validation-document-reverse"] ==="valid"?"green_300":"red_300" },
    // ];

    // const front = ozoner?.documents ? ozoner.documents.filter((ele: any) => ele.typeEvidence === "ine_front")[0].url:"";
    // const back = ozoner?.documents ? ozoner.documents.filter((ele: any) => ele.typeEvidence === "ine_back")[0].url:"";
    // const selfie = ozoner?.documents ? ozoner.documents.filter((ele: any) => ele.typeEvidence === "selfie")[0].url:"";

    const score = Math.floor((check?.score??0)*100)/10;
    let scoreColor = "green_300";
    if(score < 8)
    scoreColor = "yellow_100";
    if(score < 5)
    scoreColor = "red_300";

    return (<Grid container spacing={2}>
      <Grid item xs={8}>
        <DataTable
          title="Datos del usuario"
          data={details}
        />
      </Grid>
      <Grid item xs={4} >
        <div className="p_t_xxxl">
          <div className="bg_neutral_0 br_sm p_y_md p_x_xxl p_b_md flex_center_col flex_justify_between flex_gap_xs h_100_per">
            <Knob
              total={10}
              value={score}
              className="w_80_per"
              color={scoreColor}
            />
            <div className="flex_col_center">
              <Typography scale="small" weight="400" className="text_center">
                Fecha y hora de consulta:
              </Typography>
              <Typography scale="large" weight="600" className="text_center">
                {moment(documents?.creation_date).format("D MMM YYYY hh:mm")}
              </Typography>
            </div>
          </div>
        </div>
        <div className="flex_center_col p_t_md flex_gap_sm">
          <Typography scale="medium" weight="600" className="text_center">
            Detalles de la consulta:
          </Typography>
          <Button scale="small" icon={<PDF />} onClick={() => downloadPdf(check?.check_id)} className="w_100_per">Descargar PDF</Button>
        </div>
      </Grid>
      <Grid item xs={12} >
        <DataTable
          title="Validaciones individuales"
          data={validations}
        />
      </Grid>
    </Grid>
    );
  };

  return open && truoraData ? (
    <Modal
      openConfirmation={open}
      handleClose={() => { setOpen(false); setStepTruora("select"); }}
      icon={<img src={truora} alt="truora icon" />}
      isConfirmationLoading={false}
      widthDesktop={stepTruora === "select" ? 65 : 80}
      actions={
        stepTruora === "select"?[
          { label: "Cerrar Truora", color: "grey", action: () => { setOpen(false); setStepTruora("select"); } },
        ]:[
          { label: "Regresar a menu troura", color: "primary", action: () => setStepTruora("select") }]
      }
    >
      <>
        {stepTruora === "select" && (
          <>
            <Typography scale="large" weight="600" className="text_center m_b_xl w_100_per">
              Selecciona el producto que quieres usar
            </Typography>
          <div className="flex_center flex_gap_xl m_b_lg">
            <div className="br_sm bg_neutral_0 border_neutral_500 border_1 border_solid p_y_md p_x_lg flex_center_col flex_gap_md w_90_per cursor_pointer"
              role="button"
              tabIndex={0}
              onClick={() => setStepTruora("identidad")}
              onKeyPress={() => setStepTruora("identidad")}>
              <img src={identidad} alt="" className="img_options" />
              <Typography scale="large" weight="600" className="text_center">
                Validación de identidad digital
              </Typography>
            </div>
            <div className="br_sm bg_neutral_0 border_neutral_500 border_1 border_solid p_y_md p_x_lg flex_center_col flex_gap_md w_90_per cursor_pointer"
              role="button"
              tabIndex={0}
              onClick={() => setStepTruora("antecedentes")}
              onKeyPress={() => setStepTruora("antecedentes")}>
              <img src={antecedentes} alt="" className="img_options" />
              <Typography scale="large" weight="600" className="text_center">
                Verificación de antecedentes
              </Typography>
            </div>
            <div className="br_sm bg_neutral_0 border_neutral_500 border_1 border_solid p_y_md p_x_lg flex_center_col flex_gap_md w_90_per cursor_pointer"
              role="button"
              tabIndex={0}
              onClick={() => setStepTruora("persona")}
              onKeyPress={() => setStepTruora("persona")}>
              <img src={antecedentes} alt="" className="img_options" />
              <Typography scale="large" weight="600" className="text_center">
                Verificación de Persona
              </Typography>
            </div>

          </div>
          </>
        )}
        {stepTruora === "identidad" && (
          renderTruoraIdentity(truoraData)
        )}
        {stepTruora === "antecedentes" && (
          renderTruoraHistory(truoraData)
        )}
        {stepTruora === "persona" && (
          renderTruoraPersona(truoraData)
        )}
      </>
    </Modal>
  ) : (<div />);
}

export default TruoraDetails;
