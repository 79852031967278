import React, { useContext, useEffect, useMemo, useReducer } from "react";
import { PlacesContext } from "./PlacesContext";
import { placesReducer } from "./placesReducer";
import { getOzonioLocation } from "../../helpers/location/getUserLocation";
import {
  Feature,
  PlacesResponse,
} from "../../views/ozoner/location/interfaces/places";

export interface PlacesState {
  isLoading: boolean;
  userLocation?: [number, number];
  isLoadingPlaces: boolean;
  places: Feature[];
}

const INITIAL_STATE: PlacesState = {
  isLoading: true,
  userLocation: undefined,
  isLoadingPlaces: false,
  places: [],
};

interface Props {
  children: JSX.Element | JSX.Element[];
}

export const PlacesProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(placesReducer, INITIAL_STATE);

  // const location: [number, number] = [-99.20101614570018, 19.43086822603641];

  useEffect(() => {
    getOzonioLocation().then((lngLat: any) => {
      dispatch({ type: "setUserLocation", payload: lngLat });
      // console.log(lngLat);
    });
  }, []);

  const searchPlaceVehicle = async (vehicleGpsInfo: any) => {
    console.log("searchPlacesByTerm", { vehicleGpsInfo });
    dispatch({ type: "setPlaces", payload: vehicleGpsInfo });
  };

  const contextValue = useMemo(
    () => ({
      ...state,
      searchPlacesByTerm: searchPlaceVehicle,
    }),
    [state, searchPlaceVehicle]
  );

  return (
    <PlacesContext.Provider value={contextValue}>
      {children}
    </PlacesContext.Provider>
  );
};

export default PlacesProvider;
