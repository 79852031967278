/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useContext } from "react";

// -----------------LIBRARIES IMPORTS------------------- //
// @ts-ignore
import {
    Button,
    Input,
    Knob,
    ModalConfirmation as Modal,
    ModalConfirmation,
    SideModal,
    Typography,
} from "design_system/src";
import { Grid } from "@mui/material";
import * as yup from "yup";

// -----------------DATA IMPORTS------------------- //
import { priceFormatFinancial, priceMXNTotal } from "helpers/prices/prices";
import { TDataozoner } from "models/ozoner.interface";
import { editOzoner } from "helpers/fetchs/ozoners";
import {
    FormStatusValues,
    TDataFinancialForm,
    devolutionReasonEnum,
    devolutionReasonValues,
    economicActivityEnum,
    formStatusEnum,
    rejectedReasonEnum,
    rejectedReasonValues,
} from "models/financialForm.interface";
import moment from "moment";

import { handleEventGoogle } from "helpers/analytics/googleAnalytics";

// -----------------STATIC IMPORTS------------------- //
import "../../../ozoner.scss";
import { ReactComponent as Edit } from "design_system/src/static/icons/edit-text.svg";
import { ReactComponent as Check } from "design_system/src/static/icons/checkmark.svg";
import { ReactComponent as Eye } from "design_system/src/static/icons/eye.svg";
import { ReactComponent as Reload } from "design_system/src/static/icons/reload.svg";
import { ReactComponent as Close } from "design_system/src/static/icons/close-lg.svg";
import { ReactComponent as Money } from "design_system/src/static/icons/money.svg";
import { ReactComponent as Up } from "design_system/src/static/icons/success-plan.svg";
import { ReactComponent as AddFiles } from "design_system/src/static/icons/upload-bracket.svg";
import { ReactComponent as Down } from "design_system/src/static/icons/failed-plan.svg";
import { ReactComponent as Fail } from "design_system/src/static/icons/close-circle.svg";
import { ReactComponent as Success } from "design_system/src/static/icons/tick-circle.svg";
import { ReactComponent as Info } from "design_system/src/static/icons/info.svg";
import { ReactComponent as ArrowUp } from "design_system/src/static/icons/up-md.svg";
import { ReactComponent as ArrowDown } from "design_system/src/static/icons/down-md.svg";

import CurrencyInput from "components/hocs/buttonsInputField/CurrencyInput";

// -----------------COMPONENTS IMPORTS------------------- //
import { statusVehicles } from "models/vehicle.interface";
import Dropzone from "react-dropzone";
import Swal from "sweetalert2";
import Rejected from "views/ozoner/details/modals/Rejected";
import { toasterNotification } from "helpers/globalNotifications/globalNotifications";
import { CreditStatus } from "models/credit.interface";
import { toasterOptions } from "enums/toaster.enum";
import {
    addFileForm,
    deleteFileForm,
    editSolicitud,
} from "helpers/fetchs/solicitudes";
import { UpdateCreditDeposit } from "helpers/fetchs/credits";
import { Roles } from "models/ozonio.interface";
import { RequireRole } from "components/hocs/sidebar/Sidebar";
import { DocumentTypesEnum } from "components/hocs/loadDocuments/loadDocuments";
import { LightTooltip } from "components/hocs/tooltip/Tooltip";
import { ozonerContext } from "../../OzonerContext";
import { EditTypesOzoner, displayQuota } from "../../DetailsOzoner";
import Devolution from "../../modals/Devolution";
import SolicitudOtherInfo from "./SolicitudOtherInfo";
import { PartnershipSegmentation } from "./Segmentations";

const curpRegex =
    /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;

// eslint-disable-next-line no-shadow
export enum validationSections {
    financial = "financial",
    info = "info",
    references = "references",
}

export const validationSectionsValues = {
    [validationSections.financial]: {
        title: "Datos Financieros",
    },
    [validationSections.info]: {
        title: "Datos personales",
    },
    [validationSections.references]: {
        title: "Ref. personales",
    },
};

const ozonerVS = yup.object({
    name: yup.string().required("Falta el nombre del ozoner"),
    phone: yup.number().required("Falta el numero del ozoner"),
    email: yup
        .string()
        .email("El email del ozoner tiene el formato incorrecto")
        .required("Falta el email del ozoner"),
    curp: yup
        .string()
        .required("Falta el CURP del ozoner")
        .matches(curpRegex, "El CURP es inválido"),
    fullAddress: yup.object({
        cp: yup
            .string()
            .required("Falta el codigo postal")
            .matches(
                /^\d{5}$/,
                "El codigo postal debe ser un número de 5 dígitos."
            ),
        state: yup.string().required("Falta el estado en la direccion"),
        delegation: yup
            .string()
            .required("Falta la delegacion en la direccion"),
        neighborhood: yup.string().required("Falta la colonia en la direccion"),
        street: yup.string().required("Falta la calle en la direccion"),
        extNumber: yup
            .string()
            .required("Falta el numero exterior en la direccion"),
        lat: yup
            .string()
            .required("No se ha asignado ubicacion exacta"),
    }),
});
const referencesVS = yup.object({
    personal_reference_1: yup.object({
        name: yup.string().required("Falta el nombre de la referencia 1"),
        phone: yup.number().required("Falta el numero de la referencia 1"),
        relation: yup.string().required("Falta la relación de la referencia 1"),
        other: yup.string().when("relation", {
            is: (value: string) => value === "Otro",
            then: yup.string().required("Falta la relación de la referencia 1"),
            otherwise: yup.string().optional(),
        }),
    }),
    personal_reference_2: yup.object({
        name: yup.string().required("Falta el nombre de la referencia 2"),
        phone: yup.number().required("Falta el numero de la referencia 2"),
        relation: yup.string().required("Falta la relación de la referencia 2"),
        other: yup.string().when("relation", {
            is: (value: string) => value === "Otro",
            then: yup.string().required("Falta la relación de la referencia 2"),
            otherwise: yup.string().optional(),
        }),
    }),
});
const financialVS = yup.object({
    monthlyIncome: yup.number().required("Falta el ingreso mensual"),
    monthlySpending: yup.number().required("Falta el egreso emnsual"),
    childrenCount: yup.number().required("Falta el numero de hijos"),
    dependantsCount: yup.number().required("Falta el numero de dependientes"),
    companyName: yup.string().nullable(),
    civilStatus: yup.string().required("Falta el estado civil"),
    educationalLevel: yup.string().required("Falta el nivel de estudios"),
    livesWith: yup.array().min(1, "Falta con quien vive"),
});
const validateModel = async (
    ozoner: TDataozoner,
    financialForm: TDataFinancialForm
) => {
    const validation = {
        [validationSections.financial]: {
            valid: true,
            errors: [],
        },
        [validationSections.info]: {
            valid: true,
            errors: [],
        },
        [validationSections.references]: {
            valid: true,
            errors: [],
        },
        isValid: true,
    };
    await ozonerVS
        .validate(ozoner, { abortEarly: false })
        .then(() => {})
        .catch((err) => {
            const errors = err.inner.reduce((obj: any, e: any) => {
                // eslint-disable-next-line no-param-reassign
                if (!(e.path in obj)) obj[e.path] = [];
                // eslint-disable-next-line no-param-reassign
                obj[e.path] = obj[e.path].concat(e.errors);

                return [...obj, ...e.errors];
            }, []);
            validation[validationSections.info].valid = false;
            validation[validationSections.info].errors = errors;
            validation.isValid = false;
        });
    await referencesVS
        .validate(financialForm?.personal_references ?? {}, {
            abortEarly: false,
        })
        .then(() => {})
        .catch((err) => {
            const errors = err.inner.reduce((obj: any, e: any) => {
                // eslint-disable-next-line no-param-reassign
                if (!(e.path in obj)) obj[e.path] = [];
                // eslint-disable-next-line no-param-reassign
                obj[e.path] = obj[e.path].concat(e.errors);

                return [...obj, ...e.errors];
            }, []);
            validation[validationSections.references].valid = false;
            validation[validationSections.references].errors = errors;
            validation.isValid = false;
        });
    await financialVS
        .validate(financialForm ?? {}, {
            abortEarly: false,
        })
        .then(() => {})
        .catch((err) => {
            const errors = err.inner.reduce((obj: any, e: any) => {
                // eslint-disable-next-line no-param-reassign
                if (!(e.path in obj)) obj[e.path] = [];
                // eslint-disable-next-line no-param-reassign
                obj[e.path] = obj[e.path].concat(e.errors);

                return [...obj, ...e.errors];
            }, []);
            validation[validationSections.financial].valid = false;
            validation[validationSections.financial].errors = errors;
            validation.isValid = false;
        });
    return validation;
};

const validationItem = (
    valid: boolean,
    validLabel: string,
    invalidLabel: string
) => (
    <>
        {valid ? (
            <Success className="dim_lg text_green_300" />
        ) : (
            <Fail className="dim_lg text_red_300" />
        )}
        <Typography
            scale="xsmall"
            weight="400"
            textColor={valid ? "green_300" : "red_300"}
            className="text_no_break"
        >
            {valid ? validLabel : invalidLabel}
        </Typography>
    </>
);

interface SolicitudValidationSectionProps {
    validationStatus: {
        [key: string]: {
            valid: boolean;
            errors: string[];
        };
    };
    setValidationStatus: Function;
}

function PendingSection() {
    const { financialForm, ozoner, updateStatusSolicitud } =
        useContext(ozonerContext);

    const [infoOpen, setInfoOpen] = useState(false);
    const [referencesOpen, setReferencesOpen] = useState(false);
    const [financialOpen, setFinancialOpen] = useState(false);

    const [validationStatus, setValidationStatus] = useState<any>({
        [validationSections.financial]: {
            valid: true,
            errors: [],
        },
        [validationSections.info]: {
            valid: true,
            errors: [],
        },
        [validationSections.references]: {
            valid: true,
            errors: [],
        },
        isValid: false,
    });

    const validationDropdowns = [infoOpen, referencesOpen, financialOpen];
    const setValidationDropdowns = {
        setInfoOpen,
        setReferencesOpen,
        setFinancialOpen,
    };
    useEffect(() => {
        validateModel(ozoner, financialForm?.form).then((result) =>
            setValidationStatus(result)
        );
    }, [financialForm, ozoner]);

    const toogleValidationDropdowns = (id: number) => {
        switch (id) {
            case 0:
                setInfoOpen(!infoOpen);
                break;
            case 1:
                setReferencesOpen(!referencesOpen);
                break;
            case 2:
                setFinancialOpen(!financialOpen);
                break;

            default:
                break;
        }
    };

    const sendToValidate = () => {
        updateStatusSolicitud(formStatusEnum.inValidation, null);
        handleEventGoogle(
            "BTN_Pb_request_inValidation",
            "Panda Solicitudes",
            "click in validation request button"
        );
    };

    return (
        <>
            <PartnershipSegmentation />
            <div
                id="validate-soli-ozoner"
                className="p_md border_b_solid border_1 border_neutral_400 display_flex flex_col flex_gap_md"
            >
                <div className="display_flex flex_col flex_gap_xs">
                    {Object.keys(validationSectionsValues).map((key, idx) => (
                        <>
                            <div className="display_flex flex_gap_xs flex_align_center">
                                {validationItem(
                                    validationStatus[key].valid,
                                    `${
                                        validationSectionsValues[
                                            key as validationSections
                                        ].title
                                    } completos`,
                                    `${
                                        validationSectionsValues[
                                            key as validationSections
                                        ].title
                                    } incompletos`
                                )}

                                {validationStatus[key]
                                    .valid ? null : validationDropdowns[idx] ? (
                                    <ArrowUp
                                        className="dim_lg text_red_300 cursor_pointer"
                                        onClick={() =>
                                            toogleValidationDropdowns(idx)
                                        }
                                    />
                                ) : (
                                    <ArrowDown
                                        className="dim_lg text_red_300 cursor_pointer"
                                        onClick={() =>
                                            toogleValidationDropdowns(idx)
                                        }
                                    />
                                )}
                            </div>
                            {validationDropdowns[idx] &&
                                validationStatus[key].errors.map(
                                    (error: string) => (
                                        <Typography
                                            scale="xxsmall"
                                            weight="400"
                                            textColor="red_300"
                                            className="text_no_break p_l_md"
                                        >
                                            {error}
                                        </Typography>
                                    )
                                )}
                        </>
                    ))}
                    <div className="display_flex flex_gap_xs flex_align_center">
                        {validationItem(
                            financialForm?.form?.partnership,
                            "Alianza asignada",
                            "Alianza sin asignar"
                        )}
                    </div>
                    <div className="display_flex flex_gap_xs flex_align_center">
                        {validationItem(
                            financialForm?.form?.sellResponsible,
                            "Vendedor asignado",
                            "Vendedor sin asignar"
                        )}
                    </div>
                </div>
                <Button
                    scale="small"
                    color="primary"
                    onClick={sendToValidate}
                    disabled={
                        !validationStatus.isValid ||
                        !financialForm?.form?.partnership ||
                        !financialForm?.form?.sellResponsible 
                    }
                >
                    Mandar a validación de crédito
                </Button>
            </div>
        </>
    );
}
export default PendingSection;
