import { AnyARecord } from "dns";
import { deletes, get, post, put } from "../axios/axiosInstance";



export const fetchDevices = async (page?: number, limit?: number, query?: string, hasVehicle?: string, brand?:string, vehicle?:string) => {
  try {
    const filters=[];
    if(limit){
      filters.push(`limit=${limit}`);
    }
    if(page){
      filters.push(`page=${page}`);
    }
    if(vehicle){
      filters.push(`vehicle=${vehicle}`);
    }
    if (brand){
      filters.push(`brand=${brand}`);
    }
    if (query){
      filters.push(`query=${query}`);
    }
    if (hasVehicle !==undefined){
      filters.push(`hasVehicle=${hasVehicle}`);
    }
    const url = `v1/device?${filters.join("&")}`;
    const res = await get(url);
    return res.data;
  } catch (err: any) {
    throw err.response.data;
  }
}; 
export const fetchCurrentDevice = async (vehicle:string) => {
  try {
    
    const url = `v1/device/currentDevice/${vehicle}`;
    const res = await get(url);
    return res.data;
  } catch (err: any) {
    throw err.response.data;
  }
}; 

export const createNotification = async (data:any) => {
  
  try {
    const url = "v1/notify";
    const res = await post(url,data);
    return res.data;
  } catch (err: any) {
    throw err.response.data;
  }
};
export const markRead = async (notification:any) => {
  
  try {
    const url = `v1/notify/${notification}`;
    const res = await put(url,{read:true});
    return res.data;
  } catch (err: any) {
    throw err.response.data;
  }
};

export const deleteDevice = async (id: String): Promise<any> => {
  try {

    const res = await deletes(`v1/device/${id}`);
    return res;
  } catch (err: any) {
    throw err.response.data.message;
  }
};

export const addDevice = async (
  values: any,
): Promise<AnyARecord | null> => {
  try {
    const val = values;
    let res;
    if (values["_id"]) {
      delete val._id;
      res = await put(`v1/device/${values["_id"]}`, val);
    } else {
      delete val._id;
      res = await post("v1/device", val);
    }
    return res.data;
  } catch (err: any) {
    throw err.response.data.message;
  }
};

export const editDevice = async (values: any, id: String): Promise<any> => {
  try {

    const res = await put(`v1/device/${id}`, values);

    return res.data as any;
  } catch (err: any) {
    throw err.response.data.message;
  }
};
