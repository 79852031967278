import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { getToken } from "helpers/localStorage/authStorage";
import { fetchRefresh } from "helpers/fetchs/auth";

const getAuthHeaders = () => {
  return {
    Authorization: `Bearer ${getToken()}`
  };
};

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
  httpsAgent: {
    rejectUnauthorized: false
  }
});

export const get = async <T = any, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<AxiosResponse<T, any>> =>{
  try{
    const conf = config??{};
    conf.headers = { ...conf.headers, ...getAuthHeaders() };
    const res = await axiosInstance.get<T>(url,conf);
    return res;
  } catch (err: any) {
    if (err.response.status === 401) {
      await fetchRefresh();
      return get<T>(url, config);
    }
      throw err;
  }
};
export const post = async <T = any, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<AxiosResponse<T, any>> =>{
  try{
    const conf = config??{};
    conf.headers = { ...conf.headers, ...getAuthHeaders() };
    const res =await axiosInstance.post<T>(url,data,conf);
    return res;
  } catch (err: any) {
    if (err.response.status === 401) {
      await fetchRefresh();
      return post<T>(url, data, config);
    }
      throw err;
  }
};
export const put = async <T = any, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<AxiosResponse<T, any>> =>{
  try{
    const conf = config??{};
    conf.headers = { ...conf.headers, ...getAuthHeaders() };
    const res=await axiosInstance.put<T>(url,data,conf);
    return res;
  } catch (err: any) {
    
    if (err.response.status === 401) {
      await fetchRefresh();
      return put<T>(url, data, config);
    }
      throw err;
  }
};

export const deletes = async <T = any, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<AxiosResponse<T, any>> =>{
  try{
    const conf = config??{};
    conf.headers = { ...conf.headers, ...getAuthHeaders() };
    const res = await axiosInstance.delete<T>(url,conf);
    return res;
  } catch (err: any) {
    if (err.response.status === 401) {
      await fetchRefresh();
      return deletes<T>(url, config);
    }
      throw err;
  }
};