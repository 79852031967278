import React from "react";

import { Grid } from "@mui/material";
import { Typography } from "design_system/src";

import LoadingMapGif from "../../../static/gifs/loadinMap.gif";

const LoadingMap = () => {
  return (
    <Grid container alignItems="center" justifyContent="center" height="70vh">
      <Grid item>
        <div
          className="display_flex flex_align_center"
          style={{ flexDirection: "column" }}
        >
          <img src={LoadingMapGif} alt="loading..." />
          <Typography scale="large" weight="600" textColor="neutral_900">
            Iniciando Mapa...
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
};

export default LoadingMap;
