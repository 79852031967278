/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable dot-notation */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect, ChangeEvent } from "react";
import { Grid } from "@mui/material";
import { Formik, Form, FormikValues, FormikHelpers, FormikProps } from "formik";
import * as yup from "yup";
import Swal from "sweetalert2";
import Divider from "@mui/material/Divider";

// icons
import { ReactComponent as Bookmark } from "design_system/src/static/icons/bookmark.svg";
import { ReactComponent as Color } from "design_system/src/static/icons/color.svg";
// import { ReactComponent as Art } from "design_system/src/static/icons/new-artboard.svg";
import { ReactComponent as Moto } from "design_system/src/static/icons/motorcycle.svg";
import { ReactComponent as Eye } from "design_system/src/static/icons/eye.svg";
import { ReactComponent as Docs } from "design_system/src/static/icons/file.svg";
import { ReactComponent as Calendar } from "design_system/src/static/icons/date.svg";
import { ReactComponent as Coin } from "design_system/src/static/icons/coin.svg";
import { ReactComponent as Money } from "design_system/src/static/icons/money.svg";
import { ReactComponent as Archive } from "design_system/src/static/icons/archive.svg";
import { ReactComponent as Speedometer } from "design_system/src/static/icons/speedometer.svg";
import { ReactComponent as CD } from "design_system/src/static/icons/cd.svg";
import { ReactComponent as List } from "design_system/src/static/icons/numbered-list.svg";
import { ReactComponent as Card } from "design_system/src/static/icons/credit-card-2.svg";
import { ReactComponent as Wheel } from "design_system/src/static/icons/car-wheel-2.svg";
import { ReactComponent as Image } from "design_system/src/static/icons/image.svg";
import { ReactComponent as Save } from "design_system/src/static/icons/save.svg";
import { ReactComponent as Close } from "design_system/src/static/icons/close-circle.svg";
import { ReactComponent as Store } from "design_system/src/static/icons/store.svg";
import { ReactComponent as Helmet } from "design_system/src/static/icons/helmet-race.svg";
import { ReactComponent as Copy } from "design_system/src/static/icons/copy.svg";
import { ReactComponent as GPS } from "design_system/src/static/icons/gps.svg";
import { ReactComponent as Wrench } from "design_system/src/static/icons/thermometer-hot.svg";
import { ReactComponent as Book } from "design_system/src/static/icons/book.svg";

import {
    Button,
    Input,
    Typography,
    SideModal,
    CheckboxInput,
} from "design_system/src";
import { useNavigate } from "react-router-dom";

// css
import {
    DocumentalStatusValues,
    TEditVehicle,
    TVehicle,
    VisualStatusValues,
} from "models/vehicle.interface";
import {
    addVehicle,
    deleteVehicle,
    estimatePrice,
    getBrandsWithModels,
    getHubs,
} from "helpers/fetchs/inventory";
import { TModel } from "models/model.interface";
import { priceFormatFinancial } from "helpers/prices/prices";
import { toasterOptions } from "enums/toaster.enum";
import { toasterNotification } from "helpers/globalNotifications/globalNotifications";

const vehicleTypes = [
    {
        label: "Deportiva",
        description:
            "Es una motocicleta deportiva de alto rendimiento con un diseño aerodinámico y elegante.",
        detail: "Es una motocicleta deportiva diseñada para ofrecer un alto rendimiento y una experiencia de conducción emocionante. Con un motor potente y un chasis ligero, esta moto está construida para la velocidad y la agilidad en carreteras y circuitos. El diseño aerodinámico y elegante también la convierte en una moto impresionante que no pasará desapercibida en la carretera.",
    },
    {
        label: "Aventurera",
        description:
            "Es una moto de aventura versátil que combina comodidad y capacidad todoterreno.",
        detail: "Es una motocicleta de aventura diseñada para brindar una experiencia de conducción cómoda y versátil, tanto en la carretera como fuera de ella. Con una posición de conducción erguida y una suspensión de largo recorrido, esta moto es ideal para viajes largos y aventuras todoterreno. También cuenta con una potencia suficiente para ofrecer una experiencia de conducción emocionante y un diseño robusto que la hace perfecta para los caminos más difíciles.",
    },
    {
        label: "Clásica",
        description:
            " Es una motocicleta clásica con un diseño atemporal y elegante.",
        detail: "Es una motocicleta clásica que combina la elegancia atemporal del diseño vintage con la tecnología moderna de hoy en día. Con un motor potente y un chasis de alta calidad, ofrece una experiencia de conducción suave y emocionante. Además, el diseño elegante y atemporal de esta moto la convierte en una obra de arte en movimiento que sin duda llamará la atención en la carretera.",
    },
    {
        label: "Crucero",
        description:
            "Es una motocicleta de crucero de estilo americano con un motor potente y un diseño clásico.",
        detail: "Es una motocicleta de crucero de estilo americano diseñada para ofrecer una experiencia de conducción cómoda y emocionante en carreteras largas. Con un motor potente y un diseño clásico, esta moto es ideal para aquellos que buscan un estilo de vida relajado y con estilo. Además, cuenta con una gran cantidad de características de confort, incluyendo un asiento cómodo y un sistema de sonido de alta calidad.",
    },
    {
        label: "Naked",
        description:
            "Es una motocicleta de estilo naked con un diseño agresivo y minimalista.",
        detail: "Es una motocicleta de estilo naked diseñada para aquellos que buscan una experiencia de conducción emocionante y minimalista. Con un diseño agresivo y minimalista, esta moto cuenta con una potencia impresionante y una maniobrabilidad excepcional, lo que la hace ideal para la conducción en ciudad y carreteras sinuosas. Además, cuenta con características modernas como un panel de instrumentos modernos.",
    },
];

const validationSchema = yup.object({
    internalId: yup.string().required("Campo requerido"),
    color: yup.string().nullable(),
    secondaryColor: yup.string().nullable(),
    suffix: yup.string().nullable(),
    brand: yup.string().required("Campo requerido"),
    model: yup.string().required("Campo requerido"),
    year: yup.string().required("Campo requerido"),
    purchaseCost: yup.number().required("Campo requerido"),
    // rentPrice: 0,
    cylindersCapacity: yup.string().required("Campo requerido"),
    milage: yup.number(),

    brakeType: yup.string().nullable(),
    vehicleSN: yup.string().required("Campo requerido"),
    extraCost: yup.number().required("Campo requerido"),
    purchaseDate: yup.date().required("Campo requerido"),
    hub: yup.string().required("Campo requerido"),
    plate: yup.string().nullable(),
    engineSN: yup.string().nullable(),
    registrationCard: yup.string().nullable(),
    description: yup.string().required("Campo requerido"),
    detail: yup.string().required("Campo requerido"),

    factorKM: yup.number(),
    blueBookPrice: yup.number(),
    gpsMonthlySoftwareCost: yup.number(),
    gpsHardwareCost: yup.number(),
    repairsPrice: yup.number(),
    debtsPrice: yup.number(),
    visualStatus: yup.string().required("Campo requerido"),
    documentalStatus: yup.string().required("Campo requerido"),
    gpsStatus: yup.string().required("Campo requerido"),
    maintenanceDays: yup
        .number()
        .required("Campo requerido")
        .min(0, "Debe ser un numero positivo"),
    confirmationKM: yup.boolean(),
    platedVehicle: yup.boolean(),
    newVehicle: yup.boolean(),
    isContado: yup.boolean(),
    isPresale: yup.boolean(),
    hasTileDevice: yup.boolean(),
});
const formatDate = (date: Date | null) =>
    date
        ? `${date.getFullYear()}-${date.getMonth() + 1 < 10 ? 0 : ""}${
              date.getMonth() + 1
          }-${date.getDate() + 1 < 10 ? 0 : ""}${date.getDate()}`
        : "";
const defaults: TEditVehicle = {
    _id: "",
    internalId: "",
    brand: "",
    color: "",
    secondaryColor: "",
    suffix: "",
    model: "",
    year: "",
    purchaseCost: "",
    cylindersCapacity: "",
    milage: "",
    plate: "",
    engineSN: "",
    registrationCard: "",
    vehicleSN: "",
    purchaseDate: formatDate(new Date()),
    extraCost: "",
    hub: "",
    brakeType: "",
    creditCalc: [],
    description: "",
    detail: "",
    warranty: "Garantía",
    gpsStatus: "",
    maintenanceDays: "",
    factorKM: 0,
    blueBookPrice: "",
    confirmationKM: false,
    platedVehicle: false,
    newVehicle: false,
    isContado: false,
    isPresale: false,
    hasTileDevice: false,

    gpsMonthlySoftwareCost: "",
    gpsHardwareCost: "",
    repairsPrice: "",
    debtsPrice: "",
};

const victoryId = "65664ef2ecccdd6a95a813d3";

const colors = [
    { label: "Amarillo", value: "yellow" },
    { label: "Rosa", value: "pink" },
    { label: "Morado", value: "purple" },
    { label: "Azul", value: "blue" },
    { label: "Naranja", value: "orange" },
    { label: "Verde", value: "green" },
    { label: "Blanco", value: "white" },
    { label: "Gris", value: "gray" },
    { label: "Rojo", value: "red" },
    { label: "Negro", value: "black" },
    { label: "Grafito ", value: "graphite" },
];

const gpsStatuses = [
    { label: "Con señal", value: "withSignal" },
    { label: "Sin Señal", value: "withoutSignal" },
];

interface EditProps {
    open: boolean;
    setOpen: Function;
    values?: TVehicle | null;
    setResult: Function;
}

function EditVehicle({ open, setOpen, values = null, setResult }: EditProps) {
    const [img, setimg] = useState<
        { file?: any; view: string; name?: string }[]
    >([]);
    const [initial, setInitial] = useState<any>(defaults);
    const [brands, setBrands] = useState<
        { label: string; value: string | number; models?: TModel[] }[]
    >([]);
    const [models, setModels] = useState<
        | {
              label: string;
              value: string | number;
              cylinderCapacity: string[];
          }[]
        | undefined
    >([]);
    const [cylinders, setCylinders] = useState<
        { label: string; value: string | number }[]
    >([]);
    const [hubs, setHubs] = useState<
        { label: string; value: string | number }[]
    >([]);
    const [modified, setModified] = useState<boolean>(false);
    const [detailTab, setDetailTab] = useState<number>(0);
    const [useDefault, setUseDefault] = useState<boolean>(false);
    const [defaultType, setDefaultType] = useState<any>(vehicleTypes[0]);
    const [creditTimes, setCreditTimes] = useState<any>({
        78: { selected: false, interestRate: 80 },
        104: { selected: false, interestRate: 106 },
        156: { selected: false, interestRate: 135 },
    });
    const [prices, setPrices] = useState<any>({});
    const [pricingValues, setPricingValues] = useState<any>({
        blue: 0,
        km: 0,
        total: 0,
        debtsPrice: 0,
        repairsPrice: 0,
    });
    const [showErrors, setShowErrors] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const history = useNavigate();

    const findModels = (brand: any) => {
        setModels(
            brands
                .filter((e) => e.value === brand)[0]
                ?.models?.map((ele: any) => ({
                    label: ele.name,
                    value: ele["_id"],
                    cylinderCapacity: ele.cylinderCapacity,
                })) ?? []
        );
    };
    const findCylinders = (model: any) => {
        if (model && models) {
            setCylinders(
                models
                    .filter((e) => e.value === model)[0]
                    ?.cylinderCapacity.map((ele: any) => ({
                        label: ele,
                        value: ele,
                    }))
            );
        }
    };
    const setDesc = (
        formik: FormikProps<any>,
        type: any,
        isDefault: boolean
    ) => {
        setDefaultType(type);
        setUseDefault(isDefault);
        if (isDefault) {
            formik.setFieldValue("description", type.description);
            formik.setFieldValue("detail", type.detail);
        }
    };

    const updatePrice = async () => {
        const newPrices: any = {};
        const { blue, km, total, debtsPrice, repairsPrice } = pricingValues;
        const generalAvailable =
            blue !== undefined &&
            typeof blue === "number" &&
            total !== undefined &&
            typeof total === "number";
        const availableForWeeks =
            generalAvailable && km !== undefined && typeof km === "number";
        const availableForUpfront =
            generalAvailable &&
            debtsPrice !== undefined &&
            typeof debtsPrice === "number" &&
            repairsPrice !== undefined &&
            typeof repairsPrice === "number";

        let promises: any[] = [];
        console.log(availableForUpfront,availableForWeeks,Object.keys(creditTimes));
        if (availableForWeeks) {
            console.log("entro weeks");
            promises = [
                ...promises,
                ...Object.keys(creditTimes).map((weeks: string) => {
                    return estimatePrice(
                        blue,
                        km,
                        total,
                        parseInt(weeks, 10),
                        creditTimes[weeks].interestRate
                    ).then((price) => {
                        newPrices[weeks] = {
                            price: price.data.paymentWeek,
                            priceCredit: price.data.priceCredit,
                        };
                    });
                }),
            ];
        }
        if (availableForUpfront) {
            console.log("entro contado");
            promises.push(
                estimatePrice(
                    blue,
                    km,
                    total,
                    1,
                    0,
                    repairsPrice,
                    debtsPrice
                ).then((price) => {
                    newPrices[1] = {
                        price: price.data.paymentWeek,
                        priceCredit: price.data.priceCredit,
                    };
                })
            );
        }
        await Promise.all(promises);

        setPrices(newPrices);
    };
    useEffect(() => {
        getBrandsWithModels().then((res) => {
            if (res) {
                const maped = res
                    .map((ele) => ({
                        label: ele.name,
                        value: ele["_id"],
                        models: ele.models,
                    }))
                    .sort((a: any, b: any) => {
                        if (a.name < b.name) {
                            return -1;
                        }
                        if (a.name > b.name) {
                            return 1;
                        }
                        return 0;
                    });
                setBrands(maped);
            }
        });
        getHubs().then((res) => {
            if (res) {
                setHubs(
                    res.map((ele: any) => ({
                        label: ele.name,
                        value: ele["_id"],
                    }))
                );
            }
        });
    }, []);

    useEffect(() => {
        if (initial.brand) findModels(initial.brand);
    }, [initial, brands]);
    useEffect(() => {
        updatePrice();
    }, [creditTimes, pricingValues]);

    useEffect(() => {
        let val: any;
        if (values) {
            const imgs = values.images.map((ele: any) => ({
                view: ele.url,
                name: ele.name,
            }));

            setimg(imgs);
            val = { ...values };
            val.year = values.details?.year;
            val.milage = values.details?.milage;
            val.confirmationKM = !!values.confirmationKM;
            val.cylindersCapacity = values.cylindersCapacity;
            val.brand = values.brand?.["_id"];
            val.model = values.model?.["_id"];
            val.hub = values.hub?.["_id"];
            val.warranty = values.warranty ?? "garantía";
            if (val.salePrices) {
                const credits = { ...creditTimes };
                val.salePrices.forEach((price: any) => {
                    if (price.weeks === 1) {
                        val.isContado = true;
                        return;
                    }
                    if (!credits[price.weeks]) {
                        credits[price.weeks] = {};
                    }
                    credits[price.weeks].selected = true;
                    credits[price.weeks].interestRate = price.interestRate;
                });
                setCreditTimes(credits);
            }
            setPricingValues({
                blue: val.blueBookPrice,
                km: val.factorKM,
                total: val.purchaseCost,
                debtsPrice: val.debtsPrice,
                repairsPrice: val.repairsPrice,
            });
        } else {
            val = { ...defaults };
        }

        setInitial(val);
    }, [values]);

    const deleteImage = (i: number) => {
        const images = JSON.parse(JSON.stringify(img));
        images.splice(i, 1);
        setimg(images);
        setModified(true);
    };

    const handleFiles = (e: ChangeEvent<HTMLInputElement>) => {
        const { files } = e.target;
        if (files) {
            let changes = false;
            const temp = [...img];
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < files.length; i++) {
                const file = files.item(i);
                if (file) {
                    if (file.size > 10000000) {
                        Swal.fire({
                            text: "La imagen no debe ser superior a 10mb",
                            icon: "warning",
                            confirmButtonText: "Aceptar",
                        });
                    } else {
                        temp.push({
                            file,
                            view: URL.createObjectURL(file),
                        });
                        changes = true;
                    }
                }
            }
            if (changes) {
                setimg(temp);
                setModified(true);
            }
        }
    };
    const deleteV = (val: FormikValues) => {
        if (val["_id"]) {
            deleteVehicle(val["internalId"]).then(() => history(-1));
        }
    };

    const displayErrors = (formik: any) => {
        if (Object.keys(formik.errors)?.length)
            toasterNotification({
                msg: `Hay errores en los campos: ${Object.keys(
                    formik.errors
                ).reduce(
                    (acc, ele) => `${acc ? `${acc}, ` : ""}'${ele}'`,
                    ""
                )}`,
                style: "light",
                toasterType: toasterOptions.error,
            });
        setShowErrors(true);
    };

    const selectCredits = (weeks: string) => {
        const credits = { ...creditTimes };
        credits[weeks].selected = !credits[weeks].selected;
        if (
            Object.keys(credits).filter((ele) => credits[ele].selected).length >
            0
        ) {
            setCreditTimes(credits);
            setModified(true);
        }
    };
    const changeInterestCredits = (weeks: string, value: number) => {
        const credits = { ...creditTimes };
        credits[weeks].interestRate = value;
        setCreditTimes(credits);
        setModified(true);
    };

    const handleCopy = (type: number) => {
        if (values) {
            let text;
            switch (type) {
                case 0:
                    text = `Hazme una descripción corta sobre ${
                        values.brand.name
                    } ${values.model.name} ${
                        values.suffix ? values.suffix : ""
                    } ${values.cylindersCapacity ?? ""}, No más de 25 palabras`;
                    break;
                case 1:
                    text = `Hazme una descripción larga sobre ${
                        values.brand.name
                    } ${values.model.name} ${
                        values.suffix ? values.suffix : ""
                    } ${values.cylindersCapacity ?? ""} , con puntos claves`;
                    break;

                default:
                    text = "";
                    break;
            }
            navigator.clipboard.writeText(text);
        }
    };

    const onSubmit = (vals: FormikValues, formik: FormikHelpers<any>) => {
        const val = {
            ...vals,
            creditCalc: vals.isContado
                ? [{ weeks: 1, interestRate: 0 }]
                : Object.keys(creditTimes)
                      .filter((ele) => creditTimes[ele]?.selected)
                      .map((weeks) => ({
                          weeks,
                          interestRate: creditTimes[weeks].interestRate,
                      })),
        };
        setLoading(true);
        addVehicle(val as unknown as TEditVehicle, img, initial.internalId)
            .then((ele) => {
                setimg([]);
                setLoading(false);
                setOpen(false);
                setResult(ele);
                setInitial(defaults);
                formik.resetForm();
            })
            .catch((err) => {
                if (err === "Vehicle already exists") {
                    // eslint-disable-next-line no-param-reassign
                    err =
                        "Este internalId ya existe, si es una moto recuperada marque la anterior como tal para registrar la nueva moto";
                }
                toasterNotification({
                    msg: err
                        ? Array.isArray(err)
                            ? err[0]
                            : err
                        : "Ocurrio un error",
                    toasterType: toasterOptions.error,
                    style: "light",
                });
                setLoading(false);
            });
    };

    return (
        initial && (
            <SideModal open={open} setOpen={setOpen} size="normal">
                <Formik
                    validateOnMount
                    enableReinitialize
                    initialValues={initial}
                    onSubmit={(val: any, formik) => {
                        onSubmit(val, formik);
                    }}
                    validationSchema={validationSchema}
                >
                    {(formik) => (
                        <Form className="flex_content_col">
                            <div className="flex_header">
                                <Typography
                                    scale="heading4"
                                    weight="600"
                                    textColor="neutral_900"
                                    className="m_b_lg m_t_md"
                                >
                                    {initial["_id"]
                                        ? "Editar Ozoneta"
                                        : "Crear nueva Ozoneta"}
                                </Typography>
                                <Divider />
                            </div>
                            <div className="flex_body p_y_lg p_x_sm overflow_x_hidden">
                                <Grid container direction="row" spacing={3}>
                                    <Grid item xs={12}>
                                        <Typography
                                            scale="large"
                                            weight="600"
                                            textColor="neutral_700"
                                            className="m_b_lg"
                                        >
                                            Información del vehículo
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} className="display_flex">
                                        <div className="dso_card bg_neutral_0 flex_center_col m_b_xl m_r_md p_md border_primary_200 border_dotted pos_relative ">
                                            <Typography
                                                scale="xsmall"
                                                weight="600"
                                                textColor="primary_300"
                                                className="text_center m_b_md"
                                            >
                                                AÑADIR FOTO
                                            </Typography>
                                            <div
                                                className="dso_card_img m_b_md bg_neutral_200 w_fit display_flex overflow_hidden border_primary_300"
                                                onClick={() => 1}
                                                onKeyPress={() => 1}
                                                role="button"
                                                tabIndex={0}
                                            >
                                                <Image className="dim_xl m_lg text_primary_300 border_transparent" />
                                                <input
                                                    type="file"
                                                    multiple
                                                    accept="image/png, image/jpeg"
                                                    onChange={handleFiles}
                                                    className="input-file"
                                                />
                                            </div>
                                        </div>
                                        <div className="flex_1 display_flex overflow_y_auto flex_gap_md p_r_md">
                                            {img.map((ele, i) => (
                                                <div
                                                    className="dso_card bg_neutral_0 flex_center_col m_b_xl p_md pos_relative"
                                                    key={`photo${i}`}
                                                >
                                                    <Close
                                                        className="text_red_300 dim_lg delete_photo"
                                                        onClick={() => {
                                                            deleteImage(i);
                                                        }}
                                                    />
                                                    <Typography
                                                        scale="xsmall"
                                                        weight="600"
                                                        textColor="neutral_900"
                                                        className="text_center m_b_md"
                                                    >
                                                        FOTO
                                                    </Typography>
                                                    <div
                                                        className="dso_card_img m_b_md bg_neutral_200 w_fit display_flex overflow_hidden"
                                                        onClick={() => 1}
                                                        onKeyPress={() => 1}
                                                        role="button"
                                                        tabIndex={0}
                                                    >
                                                        <img
                                                            src={ele.view}
                                                            alt=""
                                                            className="cards_photo "
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Input
                                            onBlur={formik.handleBlur}
                                            error={
                                                !!formik.errors.purchaseDate &&
                                                (formik.getFieldMeta(
                                                    "purchaseDate"
                                                ).touched ||
                                                    showErrors)
                                            }
                                            subtitle={
                                                formik.getFieldMeta(
                                                    "purchaseDate"
                                                ).touched || showErrors
                                                    ? (formik.errors
                                                          .purchaseDate as string)
                                                    : undefined
                                            }
                                            title="Fecha de compra"
                                            name="purchaseDate"
                                            type="date"
                                            className="m_b_xs w_100_per"
                                            value={formik.values.purchaseDate}
                                            onChange={(e: any) => {
                                                formik.handleChange(e);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Input
                                            onBlur={formik.handleBlur}
                                            error={
                                                !!formik.errors.internalId &&
                                                (formik.getFieldMeta(
                                                    "internalId"
                                                ).touched ||
                                                    showErrors)
                                            }
                                            subtitle={
                                                formik.getFieldMeta(
                                                    "internalId"
                                                ).touched || showErrors
                                                    ? (formik.errors
                                                          .internalId as string)
                                                    : undefined
                                            }
                                            title="ID Interno"
                                            placeholder="Identificacion vehículo"
                                            name="internalId"
                                            type="text"
                                            icon={<Archive />}
                                            className="m_b_xs w_100_per"
                                            value={formik.values.internalId}
                                            onChange={formik.handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Input
                                            onBlur={formik.handleBlur}
                                            error={
                                                !!formik.errors.brand &&
                                                (formik.getFieldMeta("brand")
                                                    .touched ||
                                                    showErrors)
                                            }
                                            subtitle={
                                                formik.getFieldMeta("brand")
                                                    .touched || showErrors
                                                    ? (formik.errors
                                                          .brand as string)
                                                    : undefined
                                            }
                                            title="Marca"
                                            placeholder="Selecciona marca"
                                            name="brand"
                                            type="select"
                                            options={brands}
                                            icon={<Bookmark />}
                                            className="m_b_xs w_100_per"
                                            value={formik.values.brand}
                                            onChange={(e: any) => {
                                                findModels(e.target.value);
                                                formik.handleChange(e);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Input
                                            onBlur={formik.handleBlur}
                                            error={
                                                !!formik.errors.model &&
                                                (formik.getFieldMeta("model")
                                                    .touched ||
                                                    showErrors)
                                            }
                                            subtitle={
                                                formik.getFieldMeta("model")
                                                    .touched || showErrors
                                                    ? (formik.errors
                                                          .model as string)
                                                    : undefined
                                            }
                                            title="Modelo"
                                            placeholder="Modelo del vehículo"
                                            name="model"
                                            type="select"
                                            options={models as any[]}
                                            icon={<Moto />}
                                            className="m_b_xs w_100_per"
                                            value={formik.values.model}
                                            onChange={(e: any) => {
                                                findCylinders(e.target.value);
                                                formik.handleChange(e);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Input
                                            onBlur={formik.handleBlur}
                                            error={
                                                !!formik.errors.year &&
                                                (formik.getFieldMeta("year")
                                                    .touched ||
                                                    showErrors)
                                            }
                                            subtitle={
                                                formik.getFieldMeta("year")
                                                    .touched || showErrors
                                                    ? (formik.errors
                                                          .year as string)
                                                    : undefined
                                            }
                                            title="Año"
                                            placeholder="Año del vehículo"
                                            name="year"
                                            type="number"
                                            icon={<Calendar />}
                                            className="m_b_xs w_100_per"
                                            value={formik.values.year}
                                            onChange={formik.handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Input
                                            onBlur={formik.handleBlur}
                                            error={
                                                !!formik.errors
                                                    .cylindersCapacity &&
                                                (formik.getFieldMeta(
                                                    "cylindersCapacity"
                                                ).touched ||
                                                    showErrors)
                                            }
                                            subtitle={
                                                formik.getFieldMeta(
                                                    "cylindersCapacity"
                                                ).touched || showErrors
                                                    ? (formik.errors
                                                          .cylindersCapacity as string)
                                                    : undefined
                                            }
                                            title="Cilindraje"
                                            name="cylindersCapacity"
                                            type="select"
                                            options={cylinders}
                                            icon={<Speedometer />}
                                            className="m_b_xs w_100_per flex_1"
                                            value={
                                                formik.values.cylindersCapacity
                                            }
                                            onChange={formik.handleChange}
                                            placeholder="Cilindraje"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Input
                                            onBlur={formik.handleBlur}
                                            error={
                                                !!formik.errors.color &&
                                                (formik.getFieldMeta("color")
                                                    .touched ||
                                                    showErrors)
                                            }
                                            subtitle={
                                                formik.getFieldMeta("color")
                                                    .touched || showErrors
                                                    ? (formik.errors
                                                          .color as string)
                                                    : undefined
                                            }
                                            title="Color Principal"
                                            placeholder="Selecciona un color"
                                            name="color"
                                            type="select"
                                            options={colors}
                                            icon={<Color />}
                                            className="m_b_xs w_100_per"
                                            value={formik.values.color}
                                            onChange={formik.handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Input
                                            onBlur={formik.handleBlur}
                                            error={
                                                !!formik.errors
                                                    .secondaryColor &&
                                                (formik.getFieldMeta(
                                                    "secondaryColor"
                                                ).touched ||
                                                    showErrors)
                                            }
                                            subtitle={
                                                formik.getFieldMeta(
                                                    "secondaryColor"
                                                ).touched || showErrors
                                                    ? (formik.errors
                                                          .secondaryColor as string)
                                                    : undefined
                                            }
                                            title="Color Secundario"
                                            name="secondaryColor"
                                            type="select"
                                            placeholder="Selecciona un color"
                                            options={colors}
                                            icon={<Color />}
                                            className="m_b_xs w_100_per"
                                            value={formik.values.secondaryColor}
                                            onChange={formik.handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Input
                                            onBlur={formik.handleBlur}
                                            error={
                                                !!formik.errors.brakeType &&
                                                (formik.getFieldMeta(
                                                    "brakeType"
                                                ).touched ||
                                                    showErrors)
                                            }
                                            subtitle={
                                                formik.getFieldMeta("brakeType")
                                                    .touched || showErrors
                                                    ? (formik.errors
                                                          .brakeType as string)
                                                    : undefined
                                            }
                                            title="Tipo de Frenos"
                                            name="brakeType"
                                            type="select"
                                            placeholder="Selecciona frenos"
                                            options={[
                                                {
                                                    label: "Tambor",
                                                    value: "drum",
                                                },
                                                {
                                                    label: "Discos",
                                                    value: "disc",
                                                },
                                            ]}
                                            icon={<CD />}
                                            className="m_b_xs w_100_per"
                                            value={formik.values.brakeType}
                                            onChange={formik.handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Input
                                            onBlur={formik.handleBlur}
                                            error={
                                                !!formik.errors.engineSN &&
                                                (formik.getFieldMeta("engineSN")
                                                    .touched ||
                                                    showErrors)
                                            }
                                            subtitle={
                                                formik.getFieldMeta("engineSN")
                                                    .touched || showErrors
                                                    ? (formik.errors
                                                          .engineSN as string)
                                                    : undefined
                                            }
                                            title="Numero de motor (Opcional)"
                                            placeholder="Numero del motor"
                                            name="engineSN"
                                            type="text"
                                            icon={<List />}
                                            className="m_b_xs w_100_per"
                                            value={formik.values.engineSN}
                                            onChange={formik.handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Input
                                            onBlur={formik.handleBlur}
                                            error={
                                                !!formik.errors.plate &&
                                                (formik.getFieldMeta("plate")
                                                    .touched ||
                                                    showErrors)
                                            }
                                            subtitle={
                                                formik.getFieldMeta("plate")
                                                    .touched || showErrors
                                                    ? (formik.errors
                                                          .plate as string)
                                                    : undefined
                                            }
                                            title="Placa(Opcional)"
                                            name="plate"
                                            type="text"
                                            icon={<Speedometer />}
                                            className="m_b_xs w_100_per"
                                            value={formik.values.plate}
                                            onChange={formik.handleChange}
                                            placeholder="Placa"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Input
                                            onBlur={formik.handleBlur}
                                            error={
                                                !!formik.errors.vehicleSN &&
                                                (formik.getFieldMeta(
                                                    "vehicleSN"
                                                ).touched ||
                                                    showErrors)
                                            }
                                            subtitle={
                                                formik.getFieldMeta("vehicleSN")
                                                    .touched || showErrors
                                                    ? (formik.errors
                                                          .vehicleSN as string)
                                                    : undefined
                                            }
                                            title="Serie vehicular"
                                            placeholder="Numero de seríe"
                                            name="vehicleSN"
                                            type="text"
                                            icon={<Wheel />}
                                            className="m_b_xs w_100_per"
                                            value={formik.values.vehicleSN}
                                            onChange={formik.handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Input
                                            onBlur={formik.handleBlur}
                                            error={
                                                !!formik.errors.suffix &&
                                                (formik.getFieldMeta("suffix")
                                                    .touched ||
                                                    showErrors)
                                            }
                                            subtitle={
                                                formik.getFieldMeta("suffix")
                                                    .touched || showErrors
                                                    ? (formik.errors
                                                          .suffix as string)
                                                    : undefined
                                            }
                                            title="Sufijo (Opcional)"
                                            placeholder="Sufijo del vehículo"
                                            name="suffix"
                                            type="text"
                                            icon={<Helmet />}
                                            className="m_b_xs w_100_per"
                                            value={formik.values.suffix}
                                            onChange={formik.handleChange}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Input
                                            onBlur={formik.handleBlur}
                                            error={
                                                !!formik.errors
                                                    .registrationCard &&
                                                (formik.getFieldMeta(
                                                    "registrationCard"
                                                ).touched ||
                                                    showErrors)
                                            }
                                            subtitle={
                                                formik.getFieldMeta(
                                                    "registrationCard"
                                                ).touched || showErrors
                                                    ? (formik.errors
                                                          .registrationCard as string)
                                                    : undefined
                                            }
                                            title="T. de circulacíon (Opcional)"
                                            placeholder="Numero de tarjeta"
                                            name="registrationCard"
                                            type="text"
                                            icon={<Card />}
                                            className="m_b_xs w_100_per"
                                            value={
                                                formik.values.registrationCard
                                            }
                                            onChange={formik.handleChange}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Input
                                            onBlur={formik.handleBlur}
                                            error={
                                                !!formik.errors.gpsStatus &&
                                                (formik.getFieldMeta(
                                                    "gpsStatus"
                                                ).touched ||
                                                    showErrors)
                                            }
                                            subtitle={
                                                formik.getFieldMeta("gpsStatus")
                                                    .touched || showErrors
                                                    ? (formik.errors
                                                          .gpsStatus as string)
                                                    : undefined
                                            }
                                            title="Estado de GPS"
                                            name="gpsStatus"
                                            type="select"
                                            placeholder="Selecciona estado"
                                            options={gpsStatuses}
                                            icon={<GPS />}
                                            className="m_b_xs w_100_per"
                                            value={formik.values.gpsStatus}
                                            onChange={formik.handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Input
                                            onBlur={formik.handleBlur}
                                            error={
                                                !!formik.errors
                                                    .maintenanceDays &&
                                                (formik.getFieldMeta(
                                                    "maintenanceDays"
                                                ).touched ||
                                                    showErrors)
                                            }
                                            subtitle={
                                                formik.getFieldMeta(
                                                    "maintenanceDays"
                                                ).touched || showErrors
                                                    ? (formik.errors
                                                          .maintenanceDays as string)
                                                    : undefined
                                            }
                                            title="Dias de Mantenimiento"
                                            name="maintenanceDays"
                                            type="number"
                                            placeholder="Selecciona dias"
                                            icon={<Wrench />}
                                            className="m_b_xs w_100_per"
                                            value={
                                                formik.values.maintenanceDays
                                            }
                                            onChange={formik.handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Input
                                            onBlur={formik.handleBlur}
                                            error={
                                                !!formik.errors.visualStatus &&
                                                (formik.getFieldMeta(
                                                    "visualStatus"
                                                ).touched ||
                                                    showErrors)
                                            }
                                            subtitle={
                                                formik.getFieldMeta(
                                                    "visualStatus"
                                                ).touched || showErrors
                                                    ? (formik.errors
                                                          .visualStatus as string)
                                                    : undefined
                                            }
                                            title="Estado Visual"
                                            name="visualStatus"
                                            type="select"
                                            placeholder="Selecciona estado"
                                            options={Object.keys(
                                                VisualStatusValues
                                            ).map((key) => ({
                                                value: key,
                                                label: (
                                                    VisualStatusValues as any
                                                )[key].label,
                                            }))}
                                            icon={<Eye />}
                                            className="m_b_xs w_100_per"
                                            value={formik.values.visualStatus}
                                            onChange={formik.handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Input
                                            onBlur={formik.handleBlur}
                                            error={
                                                !!formik.errors
                                                    .documentalStatus &&
                                                (formik.getFieldMeta(
                                                    "documentalStatus"
                                                ).touched ||
                                                    showErrors)
                                            }
                                            subtitle={
                                                formik.getFieldMeta(
                                                    "documentalStatus"
                                                ).touched || showErrors
                                                    ? (formik.errors
                                                          .documentalStatus as string)
                                                    : undefined
                                            }
                                            title="Estado documental"
                                            name="documentalStatus"
                                            type="select"
                                            placeholder="Selecciona estado"
                                            options={Object.keys(
                                                DocumentalStatusValues
                                            ).map((key) => ({
                                                value: key,
                                                label: (
                                                    DocumentalStatusValues as any
                                                )[key].label,
                                            }))}
                                            icon={<Docs />}
                                            className="m_b_xs w_100_per"
                                            value={
                                                formik.values.documentalStatus
                                            }
                                            onChange={formik.handleChange}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography
                                            scale="large"
                                            weight="600"
                                            textColor="neutral_700"
                                            className="m_b_lg"
                                        >
                                            Etiquetas
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div className="flex_align_center flex_gap_xs display_flex">
                                            <CheckboxInput
                                                className="m_b_lg text_no_break"
                                                label="Marcar vehículo como Emplacado"
                                                name="platedVehicle"
                                                value
                                                checked={
                                                    formik.values.platedVehicle
                                                }
                                                onChange={formik.handleChange}
                                            />

                                            <Typography
                                                scale="small"
                                                weight="400"
                                                textColor="neutral_600"
                                                className="m_b_lg"
                                            >
                                                ¿Este vehículo tiene placas?
                                            </Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div className="flex_align_center flex_gap_xs display_flex">
                                            <CheckboxInput
                                                className="m_b_lg text_no_break"
                                                label="Marcar vehículo como nuevo"
                                                name="newVehicle"
                                                value
                                                checked={
                                                    formik.values.newVehicle
                                                }
                                                onChange={formik.handleChange}
                                            />

                                            <Typography
                                                scale="small"
                                                weight="400"
                                                textColor="neutral_600"
                                                className="m_b_lg"
                                            >
                                                ¿Este vehículo es nuevo?
                                            </Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div className="flex_align_center flex_gap_xs display_flex">
                                            <CheckboxInput
                                                className="m_b_lg text_no_break"
                                                label="Marcar como vehículo en Pre-venta"
                                                name="isPresale"
                                                value
                                                checked={
                                                    formik.values.isPresale
                                                }
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div className="flex_align_center flex_gap_xs display_flex">
                                            <CheckboxInput
                                                className="m_b_lg text_no_break"
                                                label="Tiene un GPS Tile instalado"
                                                name="hasTileDevice"
                                                value
                                                checked={
                                                    formik.values.hasTileDevice
                                                }
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                            scale="large"
                                            weight="600"
                                            textColor="neutral_700"
                                            className="m_b_lg"
                                        >
                                            Costo del vehículo
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Input
                                            title="Moto para venta de contado"
                                            type="slider"
                                            labels
                                            subtitle="¿Es venta de contado?"
                                            name="isContado"
                                            className="m_b_xs"
                                            value={formik.values.isContado}
                                            onChange={formik.handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Input
                                            title="Kilometraje por confirmar"
                                            type="slider"
                                            labels
                                            subtitle="Deshabilitar el kilometraje de la moto"
                                            name="confirmationKM"
                                            className="m_b_xs"
                                            value={formik.values.confirmationKM}
                                            onChange={formik.handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Input
                                            onBlur={formik.handleBlur}
                                            error={
                                                !!formik.errors.milage &&
                                                (formik.getFieldMeta("milage")
                                                    .touched ||
                                                    showErrors)
                                            }
                                            subtitle={
                                                formik.getFieldMeta("milage")
                                                    .touched || showErrors
                                                    ? (formik.errors
                                                          .milage as string)
                                                    : undefined
                                            }
                                            title="Kilometraje"
                                            name="milage"
                                            type="number"
                                            icon={<Speedometer />}
                                            className="m_b_xs w_100_per"
                                            value={formik.values.milage}
                                            onChange={formik.handleChange}
                                            placeholder="Kilometraje"
                                            disabled={
                                                formik.values.confirmationKM
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Input
                                            onBlur={(e: any) => {
                                                setPricingValues({
                                                    blue: formik.values
                                                        .blueBookPrice,
                                                    km: formik.values.factorKM,
                                                    total: formik.values
                                                        .purchaseCost,
                                                    debtsPrice:
                                                        formik.values
                                                            .debtsPrice,
                                                    repairsPrice:
                                                        formik.values
                                                            .repairsPrice,
                                                });
                                                formik.handleBlur(e);
                                            }}
                                            error={
                                                !!formik.errors.purchaseCost &&
                                                (formik.getFieldMeta(
                                                    "purchaseCost"
                                                ).touched ||
                                                    showErrors)
                                            }
                                            subtitle={
                                                formik.getFieldMeta(
                                                    "purchaseCost"
                                                ).touched || showErrors
                                                    ? (formik.errors
                                                          .purchaseCost as string)
                                                    : undefined
                                            }
                                            title="Costo de compra"
                                            placeholder="Costo del vehículo"
                                            name="purchaseCost"
                                            type="number"
                                            icon={<Coin />}
                                            className="m_b_xs w_100_per"
                                            value={formik.values.purchaseCost}
                                            onChange={formik.handleChange}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Input
                                            error={
                                                !!formik.errors.extraCost &&
                                                (formik.getFieldMeta(
                                                    "extraCost"
                                                ).touched ||
                                                    showErrors)
                                            }
                                            subtitle={
                                                formik.getFieldMeta("extraCost")
                                                    .touched || showErrors
                                                    ? (formik.errors
                                                          .extraCost as string)
                                                    : undefined
                                            }
                                            title="Gastos extras "
                                            placeholder="Ejemplo: GPS, Sirenas, Tracker etc."
                                            name="extraCost"
                                            type="number"
                                            icon={<Coin />}
                                            className="m_b_xs w_100_per"
                                            value={formik.values.extraCost}
                                            onChange={formik.handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Input
                                            onBlur={formik.handleBlur}
                                            error={
                                                !!formik.errors
                                                    .gpsHardwareCost &&
                                                (formik.getFieldMeta(
                                                    "gpsHardwareCost"
                                                ).touched ||
                                                    showErrors)
                                            }
                                            subtitle={
                                                formik.getFieldMeta(
                                                    "gpsHardwareCost"
                                                ).touched || showErrors
                                                    ? (formik.errors
                                                          .gpsHardwareCost as string)
                                                    : undefined
                                            }
                                            title="Costo Hardware GPS"
                                            placeholder="Costo del hardware"
                                            name="gpsHardwareCost"
                                            type="number"
                                            icon={<Coin />}
                                            className="m_b_xs w_100_per"
                                            value={
                                                formik.values.gpsHardwareCost
                                            }
                                            onChange={formik.handleChange}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Input
                                            error={
                                                !!formik.errors
                                                    .gpsMonthlySoftwareCost &&
                                                (formik.getFieldMeta(
                                                    "gpsMonthlySoftwareCost"
                                                ).touched ||
                                                    showErrors)
                                            }
                                            subtitle={
                                                formik.getFieldMeta(
                                                    "gpsMonthlySoftwareCost"
                                                ).touched || showErrors
                                                    ? (formik.errors
                                                          .gpsMonthlySoftwareCost as string)
                                                    : undefined
                                            }
                                            title="Costo Mensual GPS"
                                            placeholder="Plataforma GPS"
                                            name="gpsMonthlySoftwareCost"
                                            type="number"
                                            icon={<Coin />}
                                            className="m_b_xs w_100_per"
                                            value={
                                                formik.values
                                                    .gpsMonthlySoftwareCost
                                            }
                                            onChange={formik.handleChange}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={formik.values.isContado ? 12 : 6}
                                    >
                                        <Input
                                            onBlur={(e: any) => {
                                                setPricingValues({
                                                    blue: formik.values
                                                        .blueBookPrice,
                                                    km: formik.values.factorKM,
                                                    total: formik.values
                                                        .purchaseCost,
                                                    debtsPrice:
                                                        formik.values
                                                            .debtsPrice,
                                                    repairsPrice:
                                                        formik.values
                                                            .repairsPrice,
                                                });
                                                formik.handleBlur(e);
                                            }}
                                            error={
                                                !!formik.errors.blueBookPrice &&
                                                (formik.getFieldMeta(
                                                    "blueBookPrice"
                                                ).touched ||
                                                    showErrors)
                                            }
                                            subtitle={
                                                formik.getFieldMeta(
                                                    "blueBookPrice"
                                                ).touched || showErrors
                                                    ? (formik.errors
                                                          .blueBookPrice as string)
                                                    : undefined
                                            }
                                            title={
                                                formik.values.isContado
                                                    ? "Precio de venta total de la moto"
                                                    : "Precio de mercado"
                                            }
                                            placeholder={
                                                formik.values.isContado
                                                    ? "Ingrese el precio de venta"
                                                    : "Ingrese el precio de mercado"
                                            }
                                            name="blueBookPrice"
                                            type="number"
                                            icon={<Book />}
                                            className="m_b_xs w_100_per"
                                            value={formik.values.blueBookPrice}
                                            onChange={formik.handleChange}
                                        />
                                    </Grid>
                                    {formik.values.isContado ? (
                                        <>
                                            <Grid item xs={6}>
                                                <Input
                                                    onBlur={(e: any) => {
                                                        setPricingValues({
                                                            blue: formik.values
                                                                .blueBookPrice,
                                                            km: formik.values
                                                                .factorKM,
                                                            total: formik.values
                                                                .purchaseCost,
                                                            debtsPrice:
                                                                formik.values
                                                                    .debtsPrice,
                                                            repairsPrice:
                                                                formik.values
                                                                    .repairsPrice,
                                                        });
                                                        formik.handleBlur(e);
                                                    }}
                                                    error={
                                                        !!formik.errors
                                                            .repairsPrice &&
                                                        (formik.getFieldMeta(
                                                            "repairsPrice"
                                                        ).touched ||
                                                            showErrors)
                                                    }
                                                    subtitle={
                                                        formik.getFieldMeta(
                                                            "repairsPrice"
                                                        ).touched || showErrors
                                                            ? (formik.errors
                                                                  .repairsPrice as string)
                                                            : undefined
                                                    }
                                                    title="Precio de reparacion"
                                                    placeholder="Ingrese el valor estimado de reparacion"
                                                    name="repairsPrice"
                                                    type="number"
                                                    icon={<Coin />}
                                                    className="m_b_xs w_100_per"
                                                    value={
                                                        formik.values
                                                            .repairsPrice
                                                    }
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Input
                                                    onBlur={(e: any) => {
                                                        setPricingValues({
                                                            blue: formik.values
                                                                .blueBookPrice,
                                                            km: formik.values
                                                                .factorKM,
                                                            total: formik.values
                                                                .purchaseCost,
                                                            debtsPrice:
                                                                formik.values
                                                                    .debtsPrice,
                                                            repairsPrice:
                                                                formik.values
                                                                    .repairsPrice,
                                                        });
                                                        formik.handleBlur(e);
                                                    }}
                                                    error={
                                                        !!formik.errors
                                                            .debtsPrice &&
                                                        (formik.getFieldMeta(
                                                            "debtsPrice"
                                                        ).touched ||
                                                            showErrors)
                                                    }
                                                    subtitle={
                                                        formik.getFieldMeta(
                                                            "debtsPrice"
                                                        ).touched || showErrors
                                                            ? (formik.errors
                                                                  .debtsPrice as string)
                                                            : undefined
                                                    }
                                                    title="Adeudos"
                                                    placeholder="Ingrese el valor del adeudo"
                                                    name="debtsPrice"
                                                    type="number"
                                                    icon={<Coin />}
                                                    className="m_b_xs w_100_per"
                                                    value={
                                                        formik.values.debtsPrice
                                                    }
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                />
                                            </Grid>
                                        </>
                                    ) : (
                                        <Grid item xs={6}>
                                            <Input
                                                onBlur={(e: any) => {
                                                    setPricingValues({
                                                        blue: formik.values
                                                            .blueBookPrice,
                                                        km: formik.values
                                                            .factorKM,
                                                        total: formik.values
                                                            .purchaseCost,
                                                        debtsPrice:
                                                            formik.values
                                                                .debtsPrice,
                                                        repairsPrice:
                                                            formik.values
                                                                .repairsPrice,
                                                    });
                                                    formik.handleBlur(e);
                                                }}
                                                error={
                                                    !!formik.errors.factorKM &&
                                                    (formik.getFieldMeta(
                                                        "factorKM"
                                                    ).touched ||
                                                        showErrors)
                                                }
                                                subtitle={
                                                    formik.getFieldMeta(
                                                        "factorKM"
                                                    ).touched || showErrors
                                                        ? (formik.errors
                                                              .factorKM as string)
                                                        : undefined
                                                }
                                                title="Factor de kilometraje"
                                                placeholder="Ingrese el valor del factor"
                                                name="factorKM"
                                                type="number"
                                                icon={<Speedometer />}
                                                className="m_b_xs w_100_per"
                                                value={formik.values.factorKM}
                                                onChange={formik.handleChange}
                                            />
                                        </Grid>
                                    )}

                                    <Grid item xs={12}>
                                        <Divider />
                                        <div className="display_flex flex_justify_end flex_gap_lg m_y_md flex_align_center">
                                            <Typography
                                                scale="small"
                                                weight="600"
                                                textColor="neutral_900"
                                                className=""
                                            >
                                                Costo total de la moto:
                                            </Typography>
                                            <Typography
                                                scale="heading3"
                                                weight="600"
                                                textColor="primary_300"
                                                className=""
                                            >
                                                {priceFormatFinancial(
                                                    formik.values.extraCost +
                                                        formik.values
                                                            .purchaseCost,
                                                    0
                                                )}
                                            </Typography>
                                        </div>
                                    </Grid>
                                    {formik.values.isContado ? (
                                        <Grid item xs={12}>
                                            <Divider />
                                            <div className="display_flex flex_justify_end flex_gap_lg m_y_md flex_align_center">
                                                <Typography
                                                    scale="small"
                                                    weight="600"
                                                    textColor="neutral_900"
                                                    className=""
                                                >
                                                    Precio final de la moto:
                                                </Typography>
                                                <Typography
                                                    scale="heading3"
                                                    weight="600"
                                                    textColor="primary_300"
                                                    className=""
                                                >
                                                    {priceFormatFinancial(
                                                        prices[1]?.price ?? 0,
                                                        0
                                                    )}
                                                </Typography>
                                            </div>
                                        </Grid>
                                    ) : (
                                        <Grid item xs={12}>
                                            <Typography
                                                scale="large"
                                                weight="600"
                                                textColor="neutral_700"
                                            >
                                                Opciones de crédito y Hub de
                                                venta
                                            </Typography>
                                            <Typography
                                                scale="medium"
                                                weight="400"
                                                textColor="neutral_700"
                                                className="m_b_md"
                                            >
                                                Selecciona las semanas que están
                                                habilitadas para esta ozoneta
                                            </Typography>
                                            <div className="display_flex flex_gap_xl w_100_per p_b_md overflow_y_auto">
                                                {Object.keys(creditTimes).map(
                                                    (weeks: string) => (
                                                        <div
                                                            className={`dso_card ${
                                                                creditTimes[
                                                                    weeks
                                                                ].selected
                                                                    ? "bg_neutral_0"
                                                                    : "bg_neutral_300"
                                                            } p_md flex_grow_1 flex_basis_0`}
                                                            key={weeks}
                                                        >
                                                            <div
                                                                className={`br_xxs ${
                                                                    creditTimes[
                                                                        weeks
                                                                    ].selected
                                                                        ? "bg_primary_300"
                                                                        : "bg_neutral_500"
                                                                } display_flex flex_gap_sm p_x_xs m_b_xxs flex_align_center`}
                                                            >
                                                                <Input
                                                                    title=""
                                                                    type="checkbox"
                                                                    options={[
                                                                        " ",
                                                                    ]}
                                                                    name={weeks}
                                                                    checked={
                                                                        creditTimes[
                                                                            weeks
                                                                        ]
                                                                            .selected
                                                                    }
                                                                    onChange={() =>
                                                                        selectCredits(
                                                                            weeks
                                                                        )
                                                                    }
                                                                />
                                                                <Typography
                                                                    scale="xxsmall"
                                                                    weight="600"
                                                                    textColor={
                                                                        creditTimes[
                                                                            weeks
                                                                        ]
                                                                            .selected
                                                                            ? "neutral_0"
                                                                            : "neutral_700"
                                                                    }
                                                                >
                                                                    {`${weeks} semanas`}
                                                                </Typography>
                                                            </div>
                                                            <Typography
                                                                scale="small"
                                                                weight="600"
                                                                textColor={
                                                                    creditTimes[
                                                                        weeks
                                                                    ].selected
                                                                        ? "neutral_900"
                                                                        : "neutral_800"
                                                                }
                                                                className="text_center m_b_xxs"
                                                            >
                                                                Pago semanal
                                                            </Typography>
                                                            <Typography
                                                                scale="heading4"
                                                                weight="600"
                                                                textColor={
                                                                    creditTimes[
                                                                        weeks
                                                                    ].selected
                                                                        ? "green_300"
                                                                        : "neutral_600"
                                                                }
                                                                className="text_center m_b_md"
                                                            >
                                                                {prices[weeks]
                                                                    ?.price
                                                                    ? priceFormatFinancial(
                                                                          prices[
                                                                              weeks
                                                                          ]
                                                                              .price,
                                                                          0
                                                                      )
                                                                    : "$--"}
                                                            </Typography>
                                                            <Typography
                                                                scale="small"
                                                                weight="600"
                                                                textColor={
                                                                    creditTimes[
                                                                        weeks
                                                                    ].selected
                                                                        ? "neutral_900"
                                                                        : "neutral_800"
                                                                }
                                                                className="text_center"
                                                            >
                                                                Tasa de interés
                                                            </Typography>
                                                            <div className="pos_relative">
                                                                <Input
                                                                    type="number"
                                                                    title=""
                                                                    disabled={
                                                                        !creditTimes[
                                                                            weeks
                                                                        ]
                                                                            .selected
                                                                    }
                                                                    name="interest"
                                                                    value={
                                                                        creditTimes[
                                                                            weeks
                                                                        ]
                                                                            .interestRate
                                                                    }
                                                                    onChange={(
                                                                        e: any
                                                                    ) =>
                                                                        changeInterestCredits(
                                                                            weeks,
                                                                            e
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                    className=""
                                                                    inputClassName="text_center input-with-percentage"
                                                                    titleClassName="m_b_none"
                                                                />
                                                                <Typography
                                                                    scale="small"
                                                                    weight="600"
                                                                    className="percentage-symbol"
                                                                >
                                                                    %
                                                                </Typography>
                                                            </div>
                                                            <div
                                                                className={`${
                                                                    creditTimes[
                                                                        weeks
                                                                    ].selected
                                                                        ? "bg_neutral_300"
                                                                        : "bg_neutral_500"
                                                                } br_b_sm p_x_lg p_y_sm`}
                                                            >
                                                                <Typography
                                                                    scale="small"
                                                                    weight="600"
                                                                    textColor={
                                                                        creditTimes[
                                                                            weeks
                                                                        ]
                                                                            .selected
                                                                            ? "neutral_1000"
                                                                            : "neutral_700"
                                                                    }
                                                                    className="text_center"
                                                                >
                                                                    Precio de
                                                                    venta
                                                                </Typography>
                                                                <Typography
                                                                    scale="large"
                                                                    weight="600"
                                                                    textColor={
                                                                        creditTimes[
                                                                            weeks
                                                                        ]
                                                                            .selected
                                                                            ? "green_300"
                                                                            : "neutral_700"
                                                                    }
                                                                    className="text_center"
                                                                >
                                                                    {prices[
                                                                        weeks
                                                                    ]
                                                                        ?.priceCredit
                                                                        ? priceFormatFinancial(
                                                                              prices[
                                                                                  weeks
                                                                              ]
                                                                                  .priceCredit,
                                                                              0
                                                                          )
                                                                        : "$--"}
                                                                </Typography>
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </Grid>
                                    )}

                                    <Grid item xs={12}>
                                        <Input
                                            onBlur={formik.handleBlur}
                                            error={
                                                !!formik.errors.hub &&
                                                (formik.getFieldMeta("hub")
                                                    .touched ||
                                                    showErrors)
                                            }
                                            subtitle={
                                                formik.getFieldMeta("hub")
                                                    .touched || showErrors
                                                    ? (formik.errors
                                                          .hub as string)
                                                    : undefined
                                            }
                                            title="Hub de venta"
                                            name="hub"
                                            placeholder="Selecciona un Hub"
                                            type="select"
                                            options={hubs}
                                            icon={<Store />}
                                            className="m_b_xs w_100_per"
                                            value={formik.values.hub}
                                            onChange={formik.handleChange}
                                        />
                                    </Grid>
                                    <Divider />
                                    <Grid item xs={12}>
                                        <Typography
                                            scale="large"
                                            weight="600"
                                            textColor="neutral_700"
                                            className="m_b_sm"
                                        >
                                            Descripción y Detalles del vehículo
                                        </Typography>
                                        <Typography
                                            scale="medium"
                                            weight="400"
                                            textColor="neutral_800"
                                            className="m_b_sm"
                                        >
                                            Utiliza{" "}
                                            <a
                                                href="https://chat.openai.com"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                ChatGPT
                                            </a>{" "}
                                            con este parámetro: “
                                            {detailTab === 0
                                                ? "Hazme una descripción corta sobre (Nombre de la moto), No más de 25 palabras"
                                                : "Hazme una descripción larga sobre (Nombre de la moto) , con puntos claves"}
                                            ”.
                                            <div
                                                className="m_t_xxs bg_neutral_0 br_xs border_neutral_400 p_y_xxs p_x_xs display_flex flex_gap_sm border_solid border_1 w_fit cursor_pointer"
                                                onClick={() =>
                                                    handleCopy(detailTab)
                                                }
                                                role="button"
                                                onKeyPress={() =>
                                                    handleCopy(detailTab)
                                                }
                                                tabIndex={0}
                                            >
                                                <Copy className="text_primary_300 dim_md border_primary_300" />
                                                <Typography
                                                    scale="xsmall"
                                                    weight="400"
                                                    textColor="neutral_1000"
                                                >
                                                    Copiar parámetro
                                                </Typography>
                                            </div>
                                        </Typography>
                                        <div className="display_flex w_90_per z_index_1 pos_relative">
                                            <div
                                                className={`flex_basis_0 flex_grow_1 cursor_pointer p_x_lg p_y_sm bg_${
                                                    detailTab === 0
                                                        ? "primary_300"
                                                        : "neutral_300"
                                                }`}
                                                onClick={() => setDetailTab(0)}
                                                role="button"
                                                onKeyPress={() =>
                                                    setDetailTab(0)
                                                }
                                                tabIndex={0}
                                            >
                                                <Typography
                                                    scale="medium"
                                                    weight="600"
                                                    textColor={
                                                        detailTab === 0
                                                            ? "neutral_0"
                                                            : "neutral_700"
                                                    }
                                                    className="overflow_ellipsis"
                                                >
                                                    Descripción corta
                                                </Typography>
                                            </div>
                                            <div
                                                className={`flex_basis_0 flex_grow_1 cursor_pointer p_x_lg p_y_sm bg_${
                                                    detailTab === 1
                                                        ? "primary_300"
                                                        : "neutral_300"
                                                }`}
                                                onClick={() => setDetailTab(1)}
                                                role="button"
                                                onKeyPress={() =>
                                                    setDetailTab(1)
                                                }
                                                tabIndex={0}
                                            >
                                                <Typography
                                                    scale="medium"
                                                    weight="600"
                                                    textColor={
                                                        detailTab === 1
                                                            ? "neutral_0"
                                                            : "neutral_700"
                                                    }
                                                >
                                                    Detalles
                                                </Typography>
                                            </div>
                                        </div>
                                        <div className="detalles_edit">
                                            {detailTab === 0 ? (
                                                <Input
                                                    onBlur={formik.handleBlur}
                                                    error={
                                                        !!formik.errors
                                                            .description &&
                                                        (formik.getFieldMeta(
                                                            "description"
                                                        ).touched ||
                                                            showErrors)
                                                    }
                                                    subtitle={
                                                        formik.getFieldMeta(
                                                            "description"
                                                        ).touched || showErrors
                                                            ? (formik.errors
                                                                  .description as string)
                                                            : undefined
                                                    }
                                                    title=""
                                                    name="description"
                                                    placeholder="Ingresa aquí la descripción larga, generada en chatGPT"
                                                    type="textarea"
                                                    className="m_b_xs w_100_per"
                                                    value={
                                                        formik.values
                                                            .description
                                                    }
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    inputClassName="h_100_px"
                                                />
                                            ) : (
                                                <Input
                                                    onBlur={formik.handleBlur}
                                                    error={
                                                        !!formik.errors
                                                            .detail &&
                                                        (formik.getFieldMeta(
                                                            "detail"
                                                        ).touched ||
                                                            showErrors)
                                                    }
                                                    subtitle={
                                                        formik.getFieldMeta(
                                                            "detail"
                                                        ).touched || showErrors
                                                            ? (formik.errors
                                                                  .detail as string)
                                                            : undefined
                                                    }
                                                    title=""
                                                    name="detail"
                                                    placeholder="Ingresa aquí la descripción larga con puntos claves, generada en chatGPT"
                                                    type="textarea"
                                                    className="m_b_xs w_100_per"
                                                    value={formik.values.detail}
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    inputClassName="h_100_px"
                                                />
                                            )}
                                        </div>
                                        <CheckboxInput
                                            className="m_b_lg"
                                            label="Utilizar mensaje predeterminado (No recomendado)"
                                            value
                                            checked={useDefault}
                                            onChange={(e: any) =>
                                                setDesc(
                                                    formik,
                                                    defaultType,
                                                    e.target.checked
                                                )
                                            }
                                        />
                                        {useDefault && (
                                            <>
                                                <Typography
                                                    scale="medium"
                                                    weight="600"
                                                    textColor="neutral_1000"
                                                    className="m_b_lg"
                                                >
                                                    ¿Que tipo de motocicleta, se
                                                    esta subiendo?
                                                </Typography>
                                                <div className="display_flex flex_gap_md">
                                                    {vehicleTypes.map(
                                                        (typeV: any) => (
                                                            <div
                                                                key={
                                                                    typeV.label
                                                                }
                                                                className={`flex_grow_1 flex_basis_0 overflow_hidden cursor_pointer br_xxs border_neutral_400 border_solid border_1 bg_${
                                                                    typeV.label ===
                                                                    defaultType?.label
                                                                        ? "primary_300"
                                                                        : "neutral_0"
                                                                } p_xs`}
                                                                onClick={() =>
                                                                    setDesc(
                                                                        formik,
                                                                        typeV,
                                                                        useDefault
                                                                    )
                                                                }
                                                                role="button"
                                                                onKeyPress={() =>
                                                                    setDesc(
                                                                        formik,
                                                                        typeV,
                                                                        useDefault
                                                                    )
                                                                }
                                                                tabIndex={0}
                                                            >
                                                                <Typography
                                                                    scale="xsmall"
                                                                    weight="400"
                                                                    className="text_center w_100_per"
                                                                >
                                                                    {
                                                                        typeV.label
                                                                    }
                                                                </Typography>
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            </>
                                        )}
                                        {/* <div className="border_solid border_neutral_300 border_1 p_lg m_b_xl">

                </div> */}

                                        {/* <Input
                  onBlur={formik.handleBlur}
                  error={!!formik.errors.warranty && formik.getFieldMeta("warranty").touched || showErrors}
                  subtitle={formik.getFieldMeta("warranty").touched || showErrors ? formik.errors.warranty as string : undefined}
                  title="Garantia"
                  name="warranty"
                  placeholder="escribe los detalles de la garantia"
                  type="textarea"
                  icon={<Note />}
                  className="m_b_xs w_100_per"
                  value={formik.values.warranty}
                  onChange={formik.handleChange}
                  inputClassName="h_150_px"
                /> */}
                                    </Grid>
                                </Grid>
                            </div>
                            <div className="flex_footer">
                                <Button
                                    scale="small"
                                    orientation="right"
                                    icon={<Save />}
                                    className="m_b_xs w_100_per"
                                    type="submit"
                                    onClick={() => {
                                        displayErrors(formik);
                                    }}
                                    disabled={
                                        loading || (!formik.dirty && !modified)
                                    }
                                >
                                    Guardar
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </SideModal>
        )
    );
}

export default EditVehicle;
