/* eslint-disable no-unused-vars */

import { useState, useEffect, useRef } from "react";
import { AxiosResponse } from "axios";
import { get, post, put, deletes } from "helpers/axios/axiosInstance";

interface ApiResponse {
  data: Array<Record<string, any>>;
}

interface UseCrudApiResult {
  data: Record<string, any>[];
  isLoading: boolean;
  error: string | null;
  fetchData: (id?: string) => void;
  postData: (data: Record<string, any>) => void;
  deleteData: (id: string) => void;
  updateData: (id: string, data: Record<string, any>) => void;
}

const useCollectionNotificationsApi = (ozonerId?: string): UseCrudApiResult => {
  const path = "/v1/new";
  const abortController = useRef<AbortController>();

  const [data, setData] = useState<Record<string, any>[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchData = async (id?: string) => {
    setIsLoading(true);
    if (abortController.current) {
      abortController.current.abort();
    }
    const newAbortController = new AbortController();
    abortController.current = newAbortController;
    try {
      const url = `${path}?ozoner=${id}`;
      const response: AxiosResponse<ApiResponse> = await get(url, {
        signal: newAbortController.signal,
      });
      setData(response.data.data);
      // return response.data.data;
    } catch (e: any) {
      setError(e.message);
    }
    setIsLoading(false);
    // return [];
  };

  const postData = async (payload: Record<string, any>) => {
    setIsLoading(true);
    try {
      await post(`${path}`, payload);
      await fetchData(ozonerId);
    } catch (e: any) {
      setError(e.message);
    }
    setIsLoading(false);
  };

  const deleteData = async (id: string) => {
    setIsLoading(true);
    try {
      await deletes(`${path}/${id}`);
      // await fetchData(ozonerId);
    } catch (e: any) {
      setError(e.message);
    }
    setIsLoading(false);
  };

  const updateData = async (id: string, payload: Record<string, any>) => {
    setIsLoading(true);
    try {
      await put(`${path}/${id}`, payload);
      await fetchData(ozonerId);
      // setData(newData);
    } catch (e: any) {
      setError(e.message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData(ozonerId);

    return () => {
      if (abortController.current) {
        abortController.current.abort();
      }
    };
  }, []);

  return {
    data,
    isLoading,
    error,
    fetchData,
    postData,
    deleteData,
    updateData,
  };
};

export default useCollectionNotificationsApi;
