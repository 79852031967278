import React, { FC, useEffect, useState } from "react";
import  Typography  from "../Typography/Typography";
import { ReactComponent as Discount } from "../../static/icons/discount.svg";

interface ModalProps {
  total: number;
  value: number;
  className?: string;
  style?: React.CSSProperties;
}

const ChipPercentage: FC<ModalProps> = ({
  total,
  value,
  className = "",
  style,
}) => {
  const [percentage, setpercentage] = useState<number>();
  useEffect(() => {
    setpercentage(() => 100 - (value * 100) / total);
  }, [value, total]);

  return (
    <div
      style={style}
      className={`${className} bg_green_300 flex_center p_xxs br_cross_r_xs`}
    >
      <Typography weight="600" scale="small" className="text_neutral_0 m_r_xxs">
        {`${percentage?.toFixed()}%`}
      </Typography>
      <Discount className="text_neutral_0 dim_md" />
    </div>
  );
};

export default ChipPercentage;
