import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Paper,
  Grid,
  Typography as MUITypography,
  Modal as MuiModal,
} from "@mui/material";
import {
  ModalConfirmation as Modal,
  Typography,
} from "design_system/src";

import { ReactComponent as Focus } from "design_system/src/static/icons/lightbulb-creative.svg";
import { ReactComponent as Eye } from "design_system/src/static/icons/eye.svg";
import { ReactComponent as Edit } from "design_system/src/static/icons/pencil.svg";
import {
  setupMessageColor,
  transformDate,
  transformTitle,
} from "helpers/collectionNews/utils";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import useCollectionNotificationsApi from "../../../hooks/useCollectionNotificationsApi";
import CollectionModal from "../../../views/ozoner/details/modals/CollectionModal";
import OverlayCards from "../overlayCards/overlayCards";
import {
  getOzonerAgreementById,
  getOzonerAgreements,
} from "../../../helpers/fetchs/ozoners";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "start",
  color: theme.palette.text.secondary,
  display: "flex",
  justifyContent: "space-between",
}));

interface IAgreementDetailProps {
  date: string;
  amount: number;
  comment: string;
}
type ICollectionNewCard = {
  ozonerId?: string;
  collectionNewId: string;
  title: string;
  description: string;
  type: "nice" | "regular" | "bad";

  agreementCollectionNewId?: string;

  updatedAt: "string";

  setCollectionNewsArray?: any;
  setUpdateComponent?: any;
  setOpenAgreement?: any;

  setIsCollectionCardAgreement?: any;

  agreementDetail?: any;
  collectionData:any;
};
const CollectionNewCard: React.FC<ICollectionNewCard> = ({
  ozonerId,
  collectionNewId,
  title,
  updatedAt,
  description,
  type,
  agreementCollectionNewId,
  setCollectionNewsArray,
  setUpdateComponent,
  setOpenAgreement,
  setIsCollectionCardAgreement,
  agreementDetail,
  collectionData
}) => {
  const { _id } = useParams<{ _id: string }>();

  const [isHovering, setIsHovering] = useState(false);
  const [isEditHovering, setIsEditHovering] = useState(false);
  // const [openModal, setOpenModal] = useState(false);
  const [newInfo, setNewInfo] = useState<any>();
  const [openCollectionEditModal, setOpenCollectionEditModal] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [collectionNewPayload, setCollectionNewPayload] =
    useState<any>(undefined);
  const { isLoading, updateData, error, data } =
    useCollectionNotificationsApi(ozonerId);

  const [agreementDetailProps, setAgreementDetailProps] = useState<
    IAgreementDetailProps | undefined
  >(undefined);

  const handleEdit = async (id: string) => {
    // setOpenCollectionNewModal(true);
    // deleteData(id);
    setOpenCollectionEditModal(true);
    setIsCollectionCardAgreement(true);
  };
  const handleCloseConfirm = () => {
    setOpenCollectionEditModal(false);
    setIsCollectionCardAgreement(false);
  };

  const onEditCollectionNew = (values:any) => {
    setUpdateComponent(false);
    const payload = {
      content:values.content,
      comment:values.comment,
      result:values.result,
      status:values.status.value
    };

    updateData(collectionNewId, payload);
    handleCloseConfirm();
    // setCollectionNewsArray(data);

    //
    // setNewInfo(data);
    if (!error) {
      Swal.fire({
        title: "Novedad actualizada!",
        text: "se actualizó correctamente",
        icon: "success",
        confirmButtonText: "Ok",
      }).then((r) => {
        setNewInfo(data);
        setUpdateComponent(true);
      });
    }
  };

  const truncatedText = isExpanded ? description : description.slice(0, 110);

  const toggleText = () => {
    setIsExpanded((prevState) => !prevState);
  };

  const showDetail = () => {
    setOpenDetailModal(true);
  };

  // useEffect(() => {
  //   if (agreementId) {
  //     getOzonerAgreementById(agreementId)
  //       .then((r) => {
  //
  //         setAgreementDetailProps(r);
  //       })
  //       .catch((e) => console.log(e));
  //   }
  // }, []);

  return (
    <>
      <Grid item xs={8}>
        <Item>
          <Grid
            container
            wrap="nowrap"
            style={{
              height: isExpanded ? "auto" : "150px",
            }}
          >
            <Grid item xs={4}>
              <div>
                <div style={{ padding: "0 1px" }}>
                  <Typography
                    scale="medium"
                    weight="600"
                    className="text_center  m_b_xs"
                  >
                    {transformTitle(title)}
                  </Typography>
                  <Typography
                    scale="xsmall"
                    weight="600"
                    className="text_center  m_b_xs"
                  >
                    {collectionData.comment}
                  </Typography>
                  <Typography
                    scale="medium"
                    weight="400"
                    className="text_center m_b_xs "
                  >
                    {transformDate(updatedAt)}
                  </Typography>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    marginBottom: 10,
                  }}
                >
                  <div
                    role="presentation"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      cursor: "pointer",
                      backgroundColor: isHovering
                        ? setupMessageColor(type).backgroundColor
                        : "white",
                      border: isHovering
                        ? `1px solid ${setupMessageColor(type).color}`
                        : "1px solid lightgrey",
                      borderRadius: 6,
                    }}
                    onMouseEnter={() => {
                      setIsHovering(true);
                    }}
                    onMouseLeave={() => {
                      setIsHovering(false);
                    }}
                    onKeyDown={showDetail}
                    onClick={showDetail}
                  >
                    <Eye
                      style={{
                        fill: isHovering
                          ? setupMessageColor(type).color
                          : "grey",
                        padding: 3,
                      }}
                    />
                  </div>
                  <div
                    role="presentation"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      cursor: "pointer",
                      backgroundColor: isEditHovering
                        ? setupMessageColor(type).backgroundColor
                        : "white",
                      border: isEditHovering
                        ? `1px solid ${setupMessageColor(type).color}`
                        : "1px solid lightgrey",
                      borderRadius: 6,
                    }}
                    onMouseEnter={() => {
                      setIsEditHovering(true);
                    }}
                    onMouseLeave={() => {
                      setIsEditHovering(false);
                    }}
                    onKeyDown={() => handleEdit(collectionNewId)}
                    onClick={() => handleEdit(collectionNewId)}
                  >
                    <Edit
                      style={{
                        fill: isEditHovering
                          ? setupMessageColor(type).color
                          : "grey",
                        padding: 3,
                      }}
                    />
                  </div>
                </div>
              </div>
            </Grid>
            <Grid
              item
              xs={8}
              style={{
                padding: 0,
                backgroundColor: setupMessageColor(type).backgroundColor,
              }}
            >
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "space-between",
                }}
              >
                <div
                  style={{
                    color: setupMessageColor(type).color,
                    height: "auto", // agreementId ? "12vh" : "13.75vh",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <Typography scale="medium" weight="400" className="p_sm">
                    {truncatedText} {description.length > 100 ? "..." : ""}
                  </Typography>
                </div>
                {agreementDetail && (
                  <Typography
                    onClick={() => {
                      setOpenDetailModal(true);
                    }}
                    className=""
                    scale="medium"
                    weight="400"
                    style={{
                      color: "white",
                      textAlign: "center",
                      backgroundColor: "darkorange",
                      borderTopRightRadius: 10,
                      borderTopLeftRadius: 10,
                      alignItems: "flex-end",
                      padding: "5px 20px",
                      cursor: "pointer",
                    }}
                  >
                    Ver Acuerdo de Pago
                  </Typography>
                )}
              </div>
            </Grid>
          </Grid>
        </Item>
      </Grid>

      {openCollectionEditModal && (         
          <CollectionModal
            openCollection={openCollectionEditModal}
            setOpenCollection={handleCloseConfirm}
            isLoading={isLoading}
            save={onEditCollectionNew}
            collectionNewId="agreementCollectionNewId"
          editCollection={collectionData}
          />
      )}
      {openDetailModal && (
        <MuiModal
          open={openDetailModal}
          onClose={() => setOpenDetailModal(false)}
          aria-labelledby="detail-new-modal"
          aria-describedby="detail-new-modal-description"
        >
          <OverlayCards
            agreementDetailProps={agreementDetail}
            type={type}
            updatedAt={updatedAt}
            description={description}
            title={title}
            comment={collectionData.comment}
            setOpenDetailModal={setOpenDetailModal}
          />
        </MuiModal>
      )}
    </>
  );
};

export default CollectionNewCard;
