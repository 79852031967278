/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useContext, useCallback } from "react";

// -----------------LIBRARIES IMPORTS------------------- //
// @ts-ignore
import {
    Button,
    Input,
    Knob,
    ModalConfirmation as Modal,
    ModalConfirmation,
    SideModal,
    Typography,
} from "design_system/src";
import { Grid } from "@mui/material";
import * as yup from "yup";

// -----------------DATA IMPORTS------------------- //
import { priceFormatFinancial, priceMXNTotal } from "helpers/prices/prices";
import { TDataozoner } from "models/ozoner.interface";
import { editOzoner } from "helpers/fetchs/ozoners";
import {
    FormStatusValues,
    TDataFinancialForm,
    devolutionReasonEnum,
    devolutionReasonValues,
    formStatusEnum,
    rejectedReasonEnum,
    rejectedReasonValues,
    salesRankEnum,
    salesRankValues,
} from "models/financialForm.interface";
import moment from "moment";

import { handleEventGoogle } from "helpers/analytics/googleAnalytics";

// -----------------STATIC IMPORTS------------------- //
import "../../../ozoner.scss";
import { ReactComponent as Edit } from "design_system/src/static/icons/edit-text.svg";
import { ReactComponent as Reload } from "design_system/src/static/icons/reload.svg";
import { ReactComponent as Close } from "design_system/src/static/icons/close-lg.svg";
import { ReactComponent as Up } from "design_system/src/static/icons/success-plan.svg";
import { ReactComponent as Down } from "design_system/src/static/icons/failed-plan.svg";
import { ReactComponent as Fail } from "design_system/src/static/icons/close-circle.svg";
import { ReactComponent as Success } from "design_system/src/static/icons/tick-circle.svg";
import { ReactComponent as Info } from "design_system/src/static/icons/info.svg";
import { ReactComponent as ArrowUp } from "design_system/src/static/icons/up-md.svg";
import { ReactComponent as ArrowDown } from "design_system/src/static/icons/down-md.svg";

import CurrencyInput from "components/hocs/buttonsInputField/CurrencyInput";

// -----------------COMPONENTS IMPORTS------------------- //
import { statusVehicles } from "models/vehicle.interface";
import Dropzone from "react-dropzone";
import Swal from "sweetalert2";
import Rejected from "views/ozoner/details/modals/Rejected";
import { toasterNotification } from "helpers/globalNotifications/globalNotifications";
import { CreditStatus } from "models/credit.interface";
import { toasterOptions } from "enums/toaster.enum";
import {
    addFileForm,
    deleteFileForm,
    editSolicitud,
} from "helpers/fetchs/solicitudes";
import { UpdateCreditDeposit } from "helpers/fetchs/credits";
import { Roles } from "models/ozonio.interface";
import { RequireRole } from "components/hocs/sidebar/Sidebar";
import { DocumentTypesEnum } from "components/hocs/loadDocuments/loadDocuments";
import { LightTooltip } from "components/hocs/tooltip/Tooltip";
import { commonErrorHandler } from "helpers/errors/errors";
import { ozonerContext } from "../../OzonerContext";
import { EditTypesOzoner, displayQuota } from "../../DetailsOzoner";
import Devolution from "../../modals/Devolution";
import SolicitudOtherInfo from "./SolicitudOtherInfo";
import ReachSection from "./1.ReachSection";
import PendingSection from "./2.PendingSection";
import ValidationSection from "./3.ValidationSection";
import { CancelAction, ScheduleAction } from "./SolicitudActions";
import ApprovedSection from "./4.ApprovedSection";
import ScheduledSection from "./5.ScheduledSection";
import ExpiredSection from "./ExpiredSection";
import RejectedSection from "./RejectedSection";

interface SolicitudProps {
    openEditOzoner: Function;
    handleDetailsVeh: Function;
}

export const informativeMessagge = (
    title: string,
    subtitle: string,
    textColor: any,
    color: string
) => (
    <div className={`p_xs bg_${color} flex_center_col flex_gap_xs m_b_xs`}>
        <div className="flex_center flex_gap_xs ">
            <Info className={`dim_lg text_${textColor}`} />
            <Typography scale="small" weight="600" textColor={textColor}>
                {title}
            </Typography>
        </div>
        <Typography
            scale="xsmall"
            textColor={textColor}
            weight="400"
            className="text_center"
        >
            {subtitle}
        </Typography>
    </div>
);

const noCancelStatuses = [
    formStatusEnum.scheduled,
    formStatusEnum.rejected,
    formStatusEnum.canceled,
    formStatusEnum.expired,
];

function SolicitudOzoner({ openEditOzoner, handleDetailsVeh }: SolicitudProps) {
    const {
        ozoner,
        ozoniosList,
        financialForm,
        setResult,
        setModalOption,
        validCurp,
        canValidateCurp,
        getWebpageLink,
    } = useContext(ozonerContext);

    // -----------------DATA STATES------------------- //
    const [comment, setComment] = useState<string>("");

    const [isActiveCredit, setIsActiveCredit] = useState(false);

    const [loadingResponsible, setLoadingResponsible] = useState(false);

    useEffect(() => {
        setComment(ozoner?.comment ?? "");
    }, [ozoner]);
    useEffect(() => {
        setIsActiveCredit(
            financialForm?.credit?.status &&
                financialForm?.credit?.status !== CreditStatus.pending
        );
    }, [financialForm]);

    const handleChangeResponsible = (e: any) => {
        if (financialForm?.form?._id && e.target.value) {
            setLoadingResponsible(true);
            editSolicitud(financialForm?.form?._id, {
                sellResponsible: e.target.value,
            })
            .then(() => {
                            setResult();
                            setLoadingResponsible(false);
                            toasterNotification({
                                msg: "Solicitud Exitosa",
                                style: "light",
                                toasterType: toasterOptions.success,
                            });
                        })
                        .catch((err: any) => {
                            setLoadingResponsible(false);
                            commonErrorHandler(err);
                        });
        }
    };
    const updateComment = () => {
        if (ozoner)
            editOzoner({ comment }, ozoner._id).then((ele) => {
                setResult(ele);
            });
    };

    const activateContado = () => {
        setModalOption("approve_contado");
        handleEventGoogle(
            "BTN_Pb_request_create_contract_contado",
            "Panda Solicitudes",
            "click in create contract to request button"
        );
    };

    const renderFinancialFormActions = useCallback(() => {
        if (!financialForm?.form) return;
        if (financialForm.form.creditTime === 1) {
            return (
                <div
                    id="actions-soli-ozoner"
                    className="p_md border_b_solid border_1 border_neutral_400 display_flex flex_col flex_gap_xs"
                >
                    <Typography scale="medium" weight="400" className="">
                        Contrato de contado
                    </Typography>

                    <Button
                        scale="small"
                        color="primary"
                        onClick={activateContado}
                    >
                        Activar contrato de contado
                    </Button>
                </div>
            );
        }
        switch (financialForm.form.status) {
            case formStatusEnum.pending:
                if (!financialForm.form.reachedSuccess) {
                    return <ReachSection />;
                }

                return <PendingSection />;

            case formStatusEnum.inValidation:
                return <ValidationSection />;
            case formStatusEnum.approved:
                return <ApprovedSection />;
            case formStatusEnum.scheduled:
                return <ScheduledSection />;
            case formStatusEnum.rejected:
                return <RejectedSection />;
            case formStatusEnum.canceled:
                return informativeMessagge(
                    "Solicitud cancelada por:",
                    financialForm.form.canceledReason ?? "--",
                    "neutral_1000",
                    "neutral_400"
                );
            case formStatusEnum.expired:
                return <ExpiredSection />;

            default:
                return null;
        }
    }, [ozoner, financialForm]);

    return financialForm?.form ? (
        <Grid
            container
            direction="row"
            spacing={3}
            className="h_100_per p_t_md"
        >
            <Grid item xs={3}>
                <div className="bg_neutral_0 border_neutral_400 border_solid border_1 br_sm">
                    {isActiveCredit ? (
                        <SolicitudOtherInfo isCredit />
                    ) : (
                        <>
                            <div
                                id="dates-soli-ozoner"
                                className="p_md border_b_solid border_1 border_neutral_400"
                            >
                                <div className="display_flex flex_align_start flex_justify_between flex_gap_md">
                                    <div className="flex_center_col">
                                        <Typography
                                            scale="small"
                                            weight="400"
                                            textColor="neutral_800"
                                        >
                                            Fecha creación
                                        </Typography>
                                        <Typography
                                            scale="medium"
                                            textColor="neutral_800"
                                            weight="600"
                                        >
                                            {moment(
                                                    financialForm?.form
                                                        ?.createdAt
                                                ).utcOffset(-360)
                                                .format("DD/MM/yyyy")}
                                        </Typography>
                                    </div>

                                    <div className="flex_center_col">
                                        <Typography
                                            scale="small"
                                            weight="400"
                                            textColor="neutral_800"
                                            className="text_no_break"
                                        >
                                            Estado solicitud
                                        </Typography>
                                        <LightTooltip
                                            title={
                                                (FormStatusValues as any)[
                                                    financialForm?.form.status
                                                ]?.tooltip ??
                                                (FormStatusValues as any)[
                                                    financialForm?.form.status
                                                ]
                                            }
                                        >
                                            <div>
                                                <Typography
                                                    scale="medium"
                                                    weight="600"
                                                    className={`br_xs p_x_md p_y_xxxs text_center text_neutral_0 pos_relative
                                        bg_${
                                            (FormStatusValues as any)[
                                                financialForm?.form?.status
                                            ]?.color
                                        }_nocontrast`}
                                                >
                                                    {
                                                        (
                                                            FormStatusValues as any
                                                        )[
                                                            financialForm?.form
                                                                ?.status
                                                        ]?.title
                                                    }
                                                </Typography>
                                            </div>
                                        </LightTooltip>
                                        {financialForm?.form?.statusHistoric
                                            ?.length ? (
                                            <Typography
                                                scale="xxsmall"
                                                textColor="neutral_800"
                                                weight="400"
                                                className="m_t_xs"
                                            >
                                                desde:{" "}
                                                {moment(
                                                    financialForm?.form
                                                        ?.statusHistoric[
                                                        financialForm.form
                                                            .statusHistoric
                                                            .length - 1
                                                    ].updatedAt
                                                )
                                                    .utcOffset(-360)
                                                    .format("DD/MM/yyyy")}{" "}
                                            </Typography>
                                        ) : null}
                                    </div>
                                </div>
                                <Typography
                                    scale="medium"
                                    weight="400"
                                    className="m_t_xs"
                                >
                                    Responsable de Ventas
                                </Typography>
                                <Input
                                    title=""
                                    disabled={loadingResponsible}
                                    name="Responsable"
                                    placeholder="Ingresa el Ozonio"
                                    value={financialForm?.form?.sellResponsible}
                                    onChange={handleChangeResponsible}
                                    className="w_100_per"
                                    type="select"
                                    options={ozoniosList}
                                    variant="default"
                                />
                                {financialForm?.form?.status ===
                                    formStatusEnum.pending && (
                                    <Button
                                        scale="small"
                                        color="primary"
                                        variant="outline"
                                        onClick={getWebpageLink}
                                        className="w_100_per"
                                    >
                                        Copiar Link Carga Docs.
                                    </Button>
                                )}
                            </div>

                            {renderFinancialFormActions()}

                            {(financialForm?.form?.reached ||
                                financialForm?.form?.status !==
                                    formStatusEnum.pending) &&
                            !noCancelStatuses.includes(financialForm?.form?.status) ? (
                                <CancelAction />
                            ) : null}
                        </>
                    )}
                </div>
            </Grid>

            <Grid item xs={9}>
                {canValidateCurp && !validCurp
                    ? informativeMessagge(
                          "El CURP del ozoner esta mal",
                          "El CURP que registra truora es diferente al de panda",
                          "red_300",
                          "red_200"
                      )
                    : null}
                {financialForm && !financialForm.vehicleAvailable
                    ? informativeMessagge(
                          "Vehiculo no disponible",
                          "El vehiculo a sido apartado o entregado a otro ozoner, por favor seleccione un nuevo vehiculo para esta solicitud",
                          "neutral_800",
                          "yellow_100"
                      )
                    : null}

                {financialForm?.form?.devolutionReason
                    ? informativeMessagge(
                          "Solicitud devuelta por:",
                          devolutionReasonValues[
                              financialForm.form
                                  .devolutionReason as devolutionReasonEnum
                          ] ?? "--",
                          "neutral_0",
                          "secondary_400"
                      )
                    : null}

                <Grid container direction="row" spacing={1} className="">
                    <Grid item xs={4}>
                        <div className="br_xxs bg_neutral_0 flex_align_center p_x_md p_y_xs dim_100_per display_flex flex_gap_md border_1 border_neutral_400 border_solid ">
                            <div
                                className={`br_circle dim_xxl bg_${
                                    (salesRankValues as any)[
                                        financialForm?.form?.salesRank ??
                                            salesRankEnum.wood
                                    ].color
                                }`}
                            />
                            <div className="flex_grow_3">
                                <Typography scale="medium" weight="600">
                                    {
                                        (salesRankValues as any)[
                                            financialForm?.form?.salesRank ??
                                                salesRankEnum.wood
                                        ].title
                                    }
                                </Typography>
                                <Typography scale="xsmall" weight="400">
                                    Segmentación
                                </Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div className="br_xxs bg_neutral_0 flex_align_center p_x_md p_y_xs dim_100_per display_flex flex_gap_md border_1 border_neutral_400 border_solid ">
                            <Knob
                                value={Math.round(
                                    (financialForm?.form?.dti ?? 0) * 100
                                )}
                                symbol="%"
                                className="flex_grow_1 w_20_per h_fit border_transparent display_flex"
                                color="green_300"
                            />
                            <div className="flex_grow_3">
                                <Typography scale="medium" weight="600">
                                    DTI
                                </Typography>
                                <Typography scale="xsmall" weight="400">
                                    Relación entre deudas e ingresos
                                </Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div className="br_xxs bg_neutral_0 dim_100_per flex_center p_x_md p_y_xs flex_gap_md border_1 border_neutral_400 border_solid flex_justify_between ">
                            <div>
                                <Typography
                                    scale="medium"
                                    weight="600"
                                    className=""
                                >
                                    Max. Cuota:
                                </Typography>
                                <Typography
                                    scale="xsmall"
                                    weight="400"
                                    className=""
                                >
                                    Pago semanal maximo acordado
                                </Typography>
                            </div>

                            <Typography
                                scale="large"
                                weight="600"
                                textColor="green_300"
                                className=""
                            >
                                {financialForm?.form?.maxWeekQuota
                                    ? priceFormatFinancial(
                                          financialForm.form.maxWeekQuota ?? 0,
                                          0
                                      )
                                    : "--"}
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={6} className="p_md">
                        <div className="br_xxs border_1 border_neutral_400 border_solid ">
                            <div className="bg_primary_25 p_x_md p_y_xs border_b_solid border_1 border_neutral_400">
                                <Typography scale="medium" weight="600">
                                    Ingresos
                                </Typography>
                            </div>
                            <div className="p_x_md p_y_lg border_b_solid border_1 border_neutral_400 ">
                                <div className="display_flex flex_justify_between flex_align_center">
                                    <Typography scale="medium" weight="400">
                                        Ingresos mensuales a comprobar:
                                    </Typography>
                                    <div className="flex_center flex_gap_xxs">
                                        <div className="display_flex p_xs br_circle bg_green_200">
                                            <Up className="dim_md text_green_300 border_transparent" />
                                        </div>
                                        <Typography
                                            scale="large"
                                            weight="600"
                                            textColor="green_300"
                                        >
                                            {financialForm?.form?.monthlyIncome
                                                ? priceFormatFinancial(
                                                      financialForm?.form
                                                          ?.monthlyIncome
                                                  )
                                                : "--"}
                                        </Typography>
                                    </div>
                                </div>
                                {financialForm?.form?.monthlyIncome > 20000 && (
                                    <div className="display_flex flex_justify_between m_t_md">
                                        <Typography
                                            scale="small"
                                            weight="400"
                                            textColor="neutral_700"
                                            className="text_left m_b_sm"
                                        >
                                            10% por ingresos superiores a{" "}
                                            {priceFormatFinancial(20000)}
                                        </Typography>
                                        <Typography
                                            scale="small"
                                            weight="600"
                                            textColor="neutral_700"
                                            className="text_right m_b_sm"
                                        >
                                            +
                                            {priceFormatFinancial(
                                                (financialForm?.form
                                                    ?.monthlyIncome ?? 0) * 0.1
                                            )}
                                        </Typography>
                                    </div>
                                )}
                            </div>
                            <div className="p_x_md p_y_lg border_b_solid border_1 border_neutral_400 display_flex flex_justify_between flex_align_center">
                                <Typography scale="medium" weight="400">
                                    Ingresos brutos Semanal:
                                </Typography>
                                <div className="flex_center flex_gap_xxs">
                                    <div className="display_flex p_xs br_circle bg_green_200">
                                        <Up className="dim_md text_green_300 border_transparent" />
                                    </div>
                                    <Typography
                                        scale="large"
                                        weight="600"
                                        textColor="green_300"
                                    >
                                        {financialForm?.form?.weeklyIncome
                                            ? priceFormatFinancial(
                                                  financialForm?.form
                                                      ?.weeklyIncome
                                              )
                                            : "--"}
                                    </Typography>
                                </div>
                            </div>
                            <div className="p_x_md p_y_lg border_b_solid border_1 border_neutral_400 display_flex flex_justify_between flex_align_center">
                                <Typography scale="medium" weight="400">
                                    Ingresos despues de pago:
                                </Typography>
                                <Typography
                                    scale="large"
                                    weight="600"
                                    textColor="green_300"
                                >
                                    {financialForm?.form?.afterPayIncome
                                        ? priceFormatFinancial(
                                              financialForm?.form
                                                  ?.afterPayIncome
                                          )
                                        : "--"}
                                </Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={6} className="p_md">
                        <div className="br_xxs border_1 border_neutral_400 border_solid pos_relative">
                            <Button
                                onClick={() =>
                                    openEditOzoner(
                                        "Editar Solicitud",
                                        EditTypesOzoner.FINANCIAL_DATA
                                    )
                                }
                                variant="icon"
                                icon={<Edit />}
                                subvariant="edit"
                                scale="small"
                                className="edit-button"
                            />

                            <div className="bg_primary_25 p_x_md p_y_xs border_b_solid border_1 border_neutral_400">
                                <Typography scale="medium" weight="600">
                                    Egresos
                                </Typography>
                            </div>
                            <div className="p_x_md p_y_lg border_b_solid border_1 border_neutral_400 ">
                                <div className="display_flex flex_justify_between flex_align_center">
                                    <Typography scale="medium" weight="400">
                                        Egresos mensuales a comprobar:
                                    </Typography>
                                    <div className="flex_center flex_gap_xxs">
                                        <div className="display_flex p_xs br_circle bg_red_200">
                                            <Down className="dim_md text_red_300 border_transparent" />
                                        </div>
                                        <Typography
                                            scale="large"
                                            weight="600"
                                            textColor="red_300"
                                        >
                                            {financialForm?.form
                                                ?.monthlySpending
                                                ? priceFormatFinancial(
                                                      financialForm?.form
                                                          ?.monthlySpending
                                                  )
                                                : "--"}
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                            <div className="p_x_md p_y_lg border_b_solid border_1 border_neutral_400 display_flex flex_justify_between flex_align_center">
                                <Typography scale="medium" weight="400">
                                    Egreso Ordinario Semanal:
                                </Typography>
                                <div className="flex_center flex_gap_xxs">
                                    <div className="display_flex p_xs br_circle bg_red_200">
                                        <Down className="dim_md text_red_300 border_transparent" />
                                    </div>
                                    <Typography
                                        scale="large"
                                        weight="600"
                                        textColor="red_300"
                                    >
                                        {financialForm?.form?.weeklyOutcome
                                            ? priceFormatFinancial(
                                                  financialForm?.form
                                                      ?.weeklyOutcome
                                              )
                                            : "--"}
                                    </Typography>
                                </div>
                            </div>
                            <div className="p_x_md p_y_lg border_b_solid border_1 border_neutral_400 display_flex flex_justify_between flex_align_center">
                                <Typography scale="medium" weight="400">
                                    Flujo semanal:
                                </Typography>
                                <Typography
                                    scale="large"
                                    weight="600"
                                    textColor="red_300"
                                >
                                    {financialForm?.form?.weekFlow
                                        ? priceFormatFinancial(
                                              financialForm?.form?.weekFlow
                                          )
                                        : "--"}
                                </Typography>
                            </div>
                        </div>
                    </Grid>
                </Grid>

                {!isActiveCredit ? <SolicitudOtherInfo /> : null}
                <Grid container direction="row" spacing={1} className="">
                    <Grid item xs={12} className="">
                        <Typography
                            scale="large"
                            weight="600"
                            textColor="neutral_900"
                            className="m_y_md"
                        >
                            Observaciones del ozoner :
                        </Typography>
                        <textarea
                            className="observaciones w_100_per p_md border_1 border_solid border_neutral_400"
                            placeholder="Agregue todas las observaciones del ozoner que se detecten"
                            value={comment}
                            onChange={(e: any) => setComment(e.target.value)}
                        />
                        {comment !== ozoner?.comment && (
                            <div className="display_flex flex_justify_end">
                                <Button
                                    scale="small"
                                    variant="ghost"
                                    onClick={updateComment}
                                >
                                    Guardar Cambios
                                </Button>
                                <Button
                                    scale="small"
                                    variant="ghost"
                                    onClick={() =>
                                        setComment(ozoner?.comment ?? "")
                                    }
                                >
                                    Cancelar
                                </Button>
                            </div>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    ):null;
}
export default SolicitudOzoner;
