import { TDataozoner } from "./ozoner.interface";
import { TCreditPayment, TCreditPaymentPending } from "./credit-payment.interface";
import { TVehicle } from "./vehicle.interface";

// eslint-disable-next-line no-shadow
export enum CreditStatus {
  pending = "pending",
  current = "current",
  earlyDefault = "earlyDefault",
  DPD_30 = "DPD_30",
  DPD_60 = "DPD_60",
  DPD_90 = "DPD_90",
  DPD_120 = "DPD_120",
  complete = "complete",
  canceled = "canceled",
  devolution = "devolution",
  repossession = "repossession",
  warranty = "warranty",
  writeOff = "writeOff",
  portfolioSale = "portfolioSale"
}
// eslint-disable-next-line no-shadow
export enum CreditStatusFlags {
  isFraud = "isFraud",
  isStolen = "isStolen",
  isCorralon = "isCorralon",
  isJudicialCollection = "isJudicialCollection",
}

export interface TCreditTotals {
  amountPerPay: number;
  totalCredit: number;
  totalPaid: number;
  totalDebt: number;
}
export interface TCredit {
  createdAt: string;
  activatedAt: string;
  financialFormId: string;
  deposit: number;
  depositAddedBy: string
  creditClassification: string;
  amountPerPay: number;
  currency: string;
  daysDefault: number;
  dti: number;
  duration: number;
  endedAt: string;
  ozoner: TDataozoner;
  payments: TCreditPayment[];
  events: any[];
  status: string;
  total: number;
  totalCredit: number;
  totalDebt: number;
  totalPaid: number;
  _id: string;
  code?: number;
  codeOzoner?: number;
  vehicle: TVehicle;
  firstPaymentDate: string
  collectionResponsible: string;

  isFraud: boolean;
  isStolen: boolean;
  isCorralon: boolean;
  isJudicialCollection: boolean;
  writeOffAmount: number;
  writeOffDiscount: number;
}

export const statusPayment = {
  soon: {
    title: "Pronto pago",
    color: "secondary_500",
  },
  payed: {
    title: "Pagado",
    color: "green_300",
  },
  ok: {
    title: "Al dia",
    color: "green_300",
  },
  default: {
    title: "En mora",
    color: "red_300",
  },
};

// TODO: Se agrego una Mora Media, dee ir
export const CreditStatusValues: {
  [key in CreditStatus]: {
    title: string,
    color: string,
    fixed?: boolean,
    tooltip: string,
    trigerred?: boolean,
    writeOff?: boolean,
  } } = {
  [CreditStatus.pending]: {
    title: "En espera",
    color: "primary_300",
    fixed: true,
    tooltip: "Crédito pendientes de aprobación"
  },
  [CreditStatus.current]: {
    title: "Al dia",
    color: "secondary_500",
    tooltip: "Crédito al dia con sus pagos",
  },
  [CreditStatus.earlyDefault]: {
    title: "Mora temprana",
    color: "yellow_100",
    tooltip: "Crédito con una mora menor a 30 dias",
  },
  [CreditStatus.DPD_30]: {
    title: "DPD 30",
    color: "red_300",
    tooltip: "Crédito con una mora de mas de 30 dias",
  },
  [CreditStatus.DPD_60]: {
    title: "DPD 60",
    color: "red_300",
    tooltip: "Crédito con una mora de mas de 60 dias",
  },
  [CreditStatus.DPD_90]: {
    title: "DPD 90",
    color: "red_300",
    tooltip: "Crédito con una mora de mas de 90 dias",
  },
  [CreditStatus.DPD_120]: {
    title: "DPD 120",
    color: "red_300",
    tooltip: "Crédito con una mora de mas de 120 dias",
  },
  [CreditStatus.writeOff]: {
    title: "Write Off",
    color: "neutral_900",
    tooltip: "Crédito en cartera castigada",
    writeOff: true,
  },
  [CreditStatus.portfolioSale]: {
    title: "Venta de cartera",
    color: "neutral_900",
    tooltip: "Cartera vendida a tercero",
    writeOff: true
  },
  [CreditStatus.warranty]: {
    title: "Garantia",
    color: "primary_200",
    tooltip: "Crédito cancelado por garantia",
  },
  [CreditStatus.devolution]: {
    title: "Devolucion",
    color: "yellow_100",
    fixed: true,
    tooltip: "Crédito en el que la moto fue devuelta voluntariamente",
  },
  [CreditStatus.repossession]: {
    title: "Recuperada",
    color: "yellow_100",
    fixed: true,
    trigerred: true,
    tooltip: "Crédito recuperado por mora",
  },
  [CreditStatus.complete]: {
    title: "Liquidado",
    color: "green_300",
    fixed: true,
    trigerred: true,
    tooltip: "Crédito liquidado satisfactoriamente",
  },
  [CreditStatus.canceled]: {
    title: "Cancelado",
    color: "neutral_400",
    fixed: true,
    trigerred: true,
    tooltip: "Crédito cancelado",
  },
};
export const CreditStatusFlagsValues: {
  [key in CreditStatusFlags]: {
    title: string,
    color: string,
    fixed?: boolean,
    tooltip: string,
    trigerred?: boolean,
    writeOff?: boolean,
  } } = {
  [CreditStatusFlags.isCorralon]: {
    title: "Corralon",
    color: "neutral_600",
    tooltip: "Crédito en que la moto entro en corralon",
  },
  [CreditStatusFlags.isStolen]: {
    title: "Robo al ozoner",
    color: "neutral_600",
    tooltip: "Crédito en que al ozoner le robaron la moto",
  },
  [CreditStatusFlags.isFraud]: {
    title: "Fraude",
    color: "neutral_900",
    tooltip: "Crédito en el que no se pagaron las primeras cuotas",
  },
  [CreditStatusFlags.isJudicialCollection]: {
    title: "Cobranza Judicial",
    color: "neutral_900",
    tooltip: "Crédito en proceso de cobranza judicial",
  },
};

export const typePayment = {
  by_month: {
    title: "Mensualidad",
  },
};

export const methodPayment = {
  por_cobrar: {
    title: "por cobrar",
  },
};
