/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import CircularProgress from "@mui/material/CircularProgress";
import { fetchRecover } from "helpers/fetchs/recover";

// logos
import logo from "static/logos/logo_pandaboard.svg";

// css
import "./recover.scss";

// icons
import { ReactComponent as Lock } from "design_system/src/static/icons/lock-square.svg";
import { Input, Button, Typography } from "design_system/src";
import Swal from "sweetalert2";

const validationSchema = yup.object({
  password: yup
    .string()
    .min(8, "Contraseña muy corta - se necesitan mínimo 8 caracteres")
    .required("Campo requerido"),
  repassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Las contraseñas deben ser iguales")
    .required("Campo requerido"),
});

function CardRecover({ token }: { token: string }) {
  const [loading, setloading] = useState<boolean>(false);
  const formik = useFormik({
    initialValues: {
      password: "",
      repassword: "",
      token: "",
    },
    validationSchema,
    onSubmit: (value) => {
      setloading(true);
      const data = { ...value };
      data.token = token;
      fetchRecover(data)
        .then(() => {
          setloading(false);
          Swal.fire({
            title: "Contraseña actualizada!",
            text: "Ir a login",
            icon: "success",
            confirmButtonText: "Ir a Login",
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.href = "/auth";
            }
          });
        })
        .catch(() => {
          setloading(false);
          Swal.fire({
            title: "Error!",
            text: "Token expirado",
            icon: "error",
            confirmButtonText: "Aceptar",
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.href = "/auth";
            }
          });
        });
    },
  });

  return (
    <div
      className="
        dso_card 
        login_card 
        bg_neutral_0 
        p_xxl 
        shadow_hard"
    >
      <img alt="Logo" src={logo} className="logo center_x m_b_xxl" />
      <form onSubmit={formik.handleSubmit}>
        <Typography scale="medium" weight="600" className=" m_b_md text_center">
          Restaurar Contraseña
        </Typography>

        <Input
          title="Nueva Contraseña"
          type="password"
          name="password"
          placeholder="Escriba su contraseña aquí"
          value={formik.values.password}
          onChange={formik.handleChange}
          icon={<Lock />}
          className="m_b_md dso_width_100_per"
        />
        <Input
          title="Repite tu Nueva Contraseña"
          type="password"
          name="repassword"
          placeholder="Repita su contraseña nuevamente"
          value={formik.values.repassword}
          onChange={formik.handleChange}
          icon={<Lock />}
          className="m_b_md dso_width_100_per"
        />
        <Button
          type="submit"
          disabled={loading || !formik.isValid}
          scale="small"
          className="w_100_per m_b_md m_t_xxs"
        >
          {loading ? <CircularProgress className="loading" /> : "Ingresar"}
        </Button>
      </form>
    </div>
  );
}

export default CardRecover;
