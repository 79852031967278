import { axiosInstance } from "helpers/axios/axiosInstance";
import { getRefreshToken } from "helpers/localStorage/authStorage";
import { TUserLogin } from "models/userLogin";
import { store } from "store";
import { login, logout } from "store/actions/user";


export const fetchLogin = async (user: TUserLogin) => {
  const axios = axiosInstance;
  try {
    const res = await axios.post("v1/auth/login", user);
    store.dispatch(login(res.data.access_token, res.data.refresh_token) as any);

    return res.data;
  } catch (err: any) {
    throw err.response.data;
  }
};

export const fetchProfile = async (token:string) => {
  const axios = axiosInstance;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
  
  try {
    const res = await axios.get("v1/profile", config);
    return res.data;
  } catch (err: any) {
    throw err.response.data;
  }
};

export const fetchRefresh = async () => {
  const axios = axiosInstance;
  try {
    const res = await axios.post("v1/auth/refresh", { refresh_token : getRefreshToken()});
    store.dispatch(login(res.data.access_token, res.data.refresh_token) as any);
    return res.data;
  } catch (err: any) {
    store.dispatch(logout(true) as any);
    throw err.response.data;
  }
};


