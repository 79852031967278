/* eslint-disable no-plusplus */
import "./CreditPaymentsTable.scss";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import {
    Typography,
    BaseTableProps,
    ListTable,
} from "design_system/src";
import { TCredit } from "models/credit.interface";
import {
    CreditPaymentStatus,
    CreditPaymentStatusValues,
    TCreditPayment,
} from "models/credit-payment.interface";
import { priceMXNTotal } from "helpers/prices/prices";
import { ReactComponent as Up } from "design_system/src/static/icons/up-md.svg";
import { ReactComponent as Down } from "design_system/src/static/icons/down-md.svg";
import { LightTooltip } from "../tooltip/Tooltip";

interface Props {
    credit: TCredit;
    loading: boolean;
}

const CreditPaymentsTable: React.FC<Props> = ({ credit, loading }) => {
    const [payments, setPayments] = useState<any[]>([]);
    const [openPayment, setOpenPayment] = useState(-1);
    useEffect(() => {
        const payment = credit.payments
            .sort((objA: any, objB: any) =>
                objA.quota - objB.quota
            )
            .map((p: any) => {
                const events = credit.events.filter((event)=>event.quota===p.quota);
                return { ...p,events };
            });
        setPayments(payment);
    }, [credit]);
    const renderChild = useCallback(
        (row: TCreditPayment, index: number, BaseTypography: any) =>
            index === openPayment ? (
                <div className="bg_neutral_0 shadow_soft">
                    <div className="display_flex bg_neutral_400 m_t_xxs p_y_sm">
                        <Typography
                            scale="small"
                            weight="400"
                            className="flex_grow_1 flex_basis_0 text_center"
                        >
                            Fecha
                        </Typography>
                        <Typography
                            scale="small"
                            weight="400"
                            className="flex_grow_1 flex_basis_0 text_center"
                        >
                            Acción
                        </Typography>
                        <Typography
                            scale="small"
                            weight="400"
                            className="flex_grow_1 flex_basis_0 text_center"
                        >
                            Monto pagado
                        </Typography>
                    </div>
                    {row.events?.length?row.events.map((event) => (
                        <div className="display_flex p_y_sm">
                            <Typography
                                scale="small"
                                weight="600"
                                className="flex_grow_1 flex_basis_0 text_center"
                            >
                                {moment
                                    .utc(event.eventDate)
                                    .format("DD/MM/YYYY")}
                            </Typography>
                            <div className="table_divider flex_grow_0 h_lg" />
                            <Typography
                                scale="small"
                                weight="600"
                                className="flex_grow_1 flex_basis_0 text_center"
                            >
                                Pago
                            </Typography>
                            <div className="table_divider flex_grow_0 h_lg" />
                            <Typography
                                scale="small"
                                weight="600"
                                className="flex_grow_1 flex_basis_0 text_center"
                            >
                                {event.eventAmount} MXN
                            </Typography>
                        </div>
                    )): (
                            <Typography
                                scale="small"
                                weight="600"
                                className="flex_grow_1 text_center p_y_xs"
                            >
                                No hay eventos para esta orden de pago
                            </Typography>

                    )}
                </div>
            ) : (
                <div />
            ),
        [openPayment]
    );



    return (
            <ListTable
                data={payments}
                canSelect={false}
                dividers
                headerClassName="bg_transparent text_xsmall_600"
                rowClassName="table_row_custom border_1 border_solid border_neutral_400"
                cols={[
                    {
                        label: "Cuota",
                        render: (
                            row: TCreditPayment,
                            index: number,
                            BaseTypography
                        ) => (
                            <div className="flex_center_col">
                                <Typography scale="small" weight="600">
                                    {row.quota ?? "-"}
                                </Typography>
                            </div>
                        ),
                    },
                    {
                        label: "F. Vencimiento",
                        render: (
                            row: TCreditPayment,
                            index: number,
                            BaseTypography
                        ) => (
                            <div className="flex_center_col">
                                <Typography scale="small" weight="600">
                                    {/* Aqui si va en UTC porque solo importan los dias asignados y estan a las 00:00 */}
                                    {moment
                                        .utc(row.dueDate)
                                        .format("DD/MM/YYYY")}
                                </Typography>
                            </div>
                        ),
                    },
                    {
                        label: "F. pago",
                        render: (
                            row: TCreditPayment,
                            index: number,
                            BaseTypography
                        ) => (
                            <div className="flex_center_col">
                                <Typography
                                    scale="small"
                                    weight="600"
                                    textColor={
                                        moment
                                            .utc(row.paidDate)
                                            .diff(
                                                moment.utc(row.dueDate),
                                                "days"
                                            ) > 0
                                            ? "red_300"
                                            : "neutral_900"
                                    }
                                >
                                    {/* TODO:Este di deberia ir en local porque es la fecha de pago pero los pagos retroactivos llegaron en utc 00:00 y saldrian con las fechas mal */}
                                    {row.paidDate
                                        ? moment(row.paidDate)
                                              .utcOffset(-360)
                                              .format("DD/MM/YYYY")
                                        : "-"}
                                </Typography>
                            </div>
                        ),
                    },
                    {
                        label: "Monto",
                        render: (
                            row: TCreditPayment,
                            index: number,
                            BaseTypography
                        ) => (
                            <div className="flex_center_col">
                                <Typography
                                    scale="small"
                                    weight="600"
                                    textColor="primary_300"
                                >
                                    {priceMXNTotal(row.subtotal,0)}
                                </Typography>
                            </div>
                        ),
                    },
                    {
                        label: "Descuento",
                        render: (row: TCreditPayment) => (
                            <Typography
                                weight="400"
                                scale="small"
                                textColor={row.discount?"green_300":"neutral_700"}
                                className="text_center flex_grow_1 flex_basis_0"
                            >
                                {priceMXNTotal(row.discount ?? 0,0)}
                            </Typography>
                        ),
                    },
                    {
                        label: "Intereses",
                        render: (row: TCreditPayment) => (
                            <Typography
                                weight="400"
                                scale="small"
                                textColor={row.tax?"red_300":"neutral_700"}
                                className="text_center flex_grow_1 flex_basis_0"
                            >
                                {priceMXNTotal(row.tax ?? 0,0)}
                            </Typography>
                        ),
                    },
                    {
                        label: "Total",
                        render: (
                            row: TCreditPayment,
                            index: number,
                            BaseTypography
                        ) => (
                            <div className="flex_center_col">
                                <Typography
                                    scale="small"
                                    weight="600"
                                    textColor="primary_300"
                                >
                                    {priceMXNTotal(row.total,0)}
                                </Typography>
                            </div>
                        ),
                    },
                    {
                        label: "Estado",
                        render: (row: TCreditPayment) => (
                            <Typography
                                weight="400"
                                scale="small"
                                textColor="neutral_700"
                                className="text_center flex_grow_1 flex_basis_0"
                            >
                                <div
                                    className={`dso_chip_small w_fit center_x cardStatus bg_${
                                        CreditPaymentStatusValues[
                                            row.status as keyof typeof CreditPaymentStatusValues
                                        ]?.color
                                    }_nocontrast`}
                                >
                                    {
                                        CreditPaymentStatusValues[
                                            row.status as keyof typeof CreditPaymentStatusValues
                                        ]?.title
                                    }
                                </div>
                            </Typography>
                        ),
                    },
                    {
                        label: "Acciones",
                        render: (row: TCreditPayment, i: number) =>
                            row.status === CreditPaymentStatus.PAID ? (
                                <div className="flex_center flex_gap_md">
                                    <LightTooltip title="Detalle de los pagos">
                                        <div className="">
                                            {openPayment === i ? (
                                                <Up
                                                    className="text_neutral_1000 dim_lg"
                                                    onClick={() =>
                                                        setOpenPayment(-1)
                                                    }
                                                />
                                            ) : (
                                                <Down
                                                    className="text_neutral_1000 dim_lg"
                                                    onClick={() =>
                                                        setOpenPayment(i)
                                                    }
                                                />
                                            )}
                                        </div>
                                    </LightTooltip>
                                </div>
                            ) : (
                                <div />
                            ),
                    },
                ]}
                child={renderChild}
            />
    );
};

export default CreditPaymentsTable;
