/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from "react";
import {
    Input,
    Typography,
    ModalConfirmation as Modal,
    Button,
} from "design_system/src";
import Map, { Marker } from "react-map-gl";
import { ReactComponent as Focus } from "design_system/src/static/icons/lightbulb-creative.svg";

interface BaseLocation {
    latitude: number;
    longitude: number;
}
interface Location extends BaseLocation {
    zoom: number;
}

type LocationModalEditProps = {
    openModal: boolean;
    setOpenModal: any;
    addressData: any;
    saveLocation: Function;
    initialLocation: BaseLocation;
};

const LocationModalEdit: React.FC<LocationModalEditProps> = ({
    openModal,
    setOpenModal,
    addressData,
    saveLocation,
    initialLocation,
}) => {
    const [address, setaddress] = useState("");
    const [addressCoords, setaddressCoords] = useState<BaseLocation>();

    const [viewState, setViewState] = useState<Location>({
        latitude: 18.432608,
        longitude: -99.133209,
        zoom: 15,
    });
    const [marker, setMarker] = useState<BaseLocation>({
        latitude: 18.432608,
        longitude: -99.133209,
    });

    const updatePositionFromAddress = async () => {
        const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
            address
        )}.json?access_token=${process.env.REACT_APP_MAPBOX_API_KEY}`;

        try {
            const response = await fetch(url);
            const data = await response.json();
            const coordinates = data.features[0]?.center;

            if (coordinates) {
                setaddressCoords({
                    latitude: coordinates[1],
                    longitude: coordinates[0],
                });
                console.log("found point", coordinates);
            }
        } catch (error) {
            console.error("Error al obtener dirección:", error);
        }
    };

    useEffect(() => {
        setaddress(
            `${addressData.street} ${addressData.extNumber} ${
                addressData.intNumber ?? ""
            }, ${addressData.neighborhood}, ${addressData.cp}, ${
                addressData.delegation
            }, ${addressData.state}, México`
        );
    }, [addressData]);
    useEffect(() => {
        updatePositionFromAddress();
    }, [address]);

    useEffect(() => {
        if (initialLocation) {
            setViewState({
                ...initialLocation,
                zoom: viewState.zoom,
            });
            setMarker({
                ...initialLocation,
            });
        } else if (addressCoords) {
            setViewState({
                ...addressCoords,
                zoom: viewState.zoom,
            });
            setMarker({
                ...addressCoords,
            });
        }
    }, [addressCoords, initialLocation]);

    const onDragEnd = (event: { lngLat: { lng: number; lat: number } }) => {
        const { lng, lat } = event.lngLat;
        setMarker({
            latitude: lat,
            longitude: lng,
        });
    };

    const centerOnAddressCoords = () => {
        if (addressCoords) {
            setViewState({
                ...addressCoords,
                zoom: viewState.zoom,
            });
            setMarker({
                ...addressCoords,
            });
        }
    };

    return (
        <Modal
            widthDesktop={40}
            icon={<Focus className="text_primary_300" />}
            handleClose={() => setOpenModal(false)}
            openConfirmation={openModal}
            actions={[
                {
                    label: "Cancelar",
                    color: "grey",
                    action: () => setOpenModal(false),
                },
                {
                    label: "Seleccionar ubicacion",
                    color: "primary",
                    isSubmit: true,
                    action: () => {
                        saveLocation(marker);
                        setOpenModal(false);
                    },
                },
            ]}
        >
            <>
                <Typography scale="large" weight="600" className="m_b_md">
                    Registro de ubicacion
                </Typography>
                <Typography scale="small" weight="400" className="m_b_lg">
                    Selecciona en el mapa el punto exacto al cual hace
                    referencia la direccion
                </Typography>
                <div className="bg_neutral_400 p_x_md p_y_xs flex_center flex_gap_md">
                    <Typography
                        scale="small"
                        weight="600"
                        textColor="neutral_700"
                        className=""
                    >
                        {address}
                    </Typography>
                    {addressCoords ? (
                        addressCoords.latitude === marker.latitude &&
                        addressCoords.longitude === marker.longitude ? null : (
                            <Button scale="small" onClick={centerOnAddressCoords}>
                                Centrar en la direccion
                            </Button>
                        )
                    ) : (
                        <Typography
                            scale="small"
                            weight="600"
                            textColor="red_300"
                            className=""
                        >
                            No se pudo determinar las coordenadas de esta
                            direccion
                        </Typography>
                    )}
                </div>
                <Map
                    {...viewState}
                    onMove={(evt) =>
                        setViewState({ ...viewState, ...evt.viewState })
                    }
                    style={{ width: "100%", height: "400px" }}
                    mapStyle="mapbox://styles/mapbox/streets-v11"
                    mapboxAccessToken={process.env.REACT_APP_MAPBOX_API_KEY}
                >
                    <Marker
                        latitude={marker.latitude}
                        longitude={marker.longitude}
                        draggable
                        onDragEnd={onDragEnd}
                        color="orange"
                    />
                </Map>
            </>
        </Modal>
    );
};

export default LocationModalEdit;
