/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useContext } from "react";

// -----------------LIBRARIES IMPORTS------------------- //
// @ts-ignore
import { Button, ModalConfirmation, Typography } from "design_system/src";

// -----------------DATA IMPORTS------------------- //
import { priceFormatFinancial } from "helpers/prices/prices";
import { formStatusEnum } from "models/financialForm.interface";
import moment from "moment";

import { handleEventGoogle } from "helpers/analytics/googleAnalytics";

// -----------------STATIC IMPORTS------------------- //
import "../../../ozoner.scss";
import { ReactComponent as Check } from "design_system/src/static/icons/checkmark.svg";
import { ReactComponent as Fail } from "design_system/src/static/icons/close-circle.svg";
import { ReactComponent as Success } from "design_system/src/static/icons/tick-circle.svg";

// -----------------COMPONENTS IMPORTS------------------- //
import { cancelScheduleFinancialForm } from "helpers/fetchs/solicitudes";
import { fetchCurrentDevice } from "helpers/fetchs/devices";
import { ActivateCredit, DeleteCredit } from "helpers/fetchs/credits";
import { commonErrorHandler } from "helpers/errors/errors";
import { Roles } from "models/ozonio.interface";
import { RequireRole } from "components/hocs/sidebar/Sidebar";
import { DocumentTypesEnum } from "components/hocs/loadDocuments/loadDocuments";
import { ozonerContext } from "../../OzonerContext";
import { CollectionResponsible } from "./Segmentations";
import { ScheduleAction, VerifyAction } from "./SolicitudActions";

const validationItem = (
    valid: boolean,
    validLabel: string,
    invalidLabel: string
) => (
    <>
        {valid ? (
            <Success className="dim_lg text_green_300" />
        ) : (
            <Fail className="dim_lg text_red_300" />
        )}
        <Typography
            scale="xsmall"
            weight="400"
            textColor={valid ? "green_300" : "red_300"}
            className="text_no_break"
        >
            {valid ? validLabel : invalidLabel}
        </Typography>
    </>
);

function ScheduledSection() {
    const {
        ozoner,
        financialForm,
        updateStatusSolicitud,
        ozoniosList,
        vehicle,
        handleDetailsVeh,
        setResult,
    } = useContext(ozonerContext);

    const [hasContract, setHasContract] = useState(false);
    const [hasPromissory, setHasPromissory] = useState(false);
    const [hasDelivery, setHasDelivery] = useState(false);
    const [hasDeposit, setHasDeposit] = useState(false);
    const [hasDevice, setHasDevice] = useState(false);
    const [deliverOzonio, setDeliverOzonio] = useState("");
    const [openActivate, setOpenActivate] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setHasContract(false);
        setHasPromissory(false);
        setHasDelivery(false);
        setHasDeposit(false);
        financialForm?.form?.documents?.forEach((doc: any) => {
            if (doc.typeEvidence === DocumentTypesEnum.contract) {
                setHasContract(true);
            }
            if (doc.typeEvidence === DocumentTypesEnum.promissory_note) {
                setHasPromissory(true);
            }
            if (doc.typeEvidence === DocumentTypesEnum.delivery_photo) {
                setHasDelivery(true);
            }
            if (doc.typeEvidence === DocumentTypesEnum.deposit) {
                setHasDeposit(true);
            }
        });

        setDeliverOzonio(
            ozoniosList.filter(
                (ozonio: any) =>
                    financialForm?.form?.appointment?.deliverOzonio ===
                    ozonio.value
            )?.[0]?.label ?? ""
        );
    }, [financialForm]);
    useEffect(() => {
        if (vehicle?.internalId){
            fetchCurrentDevice(vehicle.internalId).then((dev: any) => {
                if (dev?.hasDevice) {
                    setHasDevice(true);
                }
            });
        }
    }, [vehicle]);

    const returnSolicitud = async () => {
        try {
            const p = [];
            if (financialForm?.credit) {
                p.push(DeleteCredit(financialForm.credit._id));
            }
            if (financialForm?.form?.appointment) {
                p.push(
                    cancelScheduleFinancialForm(
                        ozoner._id,
                        financialForm.form._id
                    )
                );
            }
            await Promise.all(p);
            await updateStatusSolicitud(formStatusEnum.approved, null);
        } catch (error: any) {
            commonErrorHandler(error?.message ?? error);
        }
        handleEventGoogle(
            "BTN_Pb_request_return_approve",
            "Panda Solicitudes",
            "click in approve request button"
        );
    };

    const activateContract = () => {
        if (financialForm?.credit?._id) {
            setLoading(true);
            ActivateCredit(financialForm.credit._id)
                .then((_) => {
                    setLoading(false);
                    setResult();
                    setOpenActivate(false);
                })
                .catch((e) => {
                    setLoading(false);
                    commonErrorHandler(e);
                });
        }
    };

    return (
        <>
            <div
                id="validate-soli-ozoner"
                className="p_md border_b_solid border_1 border_neutral_400 display_flex flex_col"
            >
                <Typography scale="medium" weight="400" className="m_b_xs">
                    Resumen de credito
                </Typography>
                <div className="display_flex">
                    <div className="flex_grow_1 flex_basis_0 border_solid border_1 border_neutral_400 p_y_xxs">
                        <Typography
                            scale="xxsmall"
                            weight="400"
                            textColor="neutral_600"
                            className="text_no_break text_center m_b_xxs"
                        >
                            Deposito
                        </Typography>
                        <Typography
                            scale="small"
                            weight="600"
                            textColor="green_300"
                            className="text_no_break text_center"
                        >
                            {priceFormatFinancial(
                                financialForm?.credit?.deposit ?? 0,
                                0
                            )}
                        </Typography>
                    </div>
                    <div className="flex_grow_1 flex_basis_0 border_solid border_1 border_neutral_400 p_y_xxs">
                        <Typography
                            scale="xxsmall"
                            weight="400"
                            textColor="neutral_600"
                            className="text_no_break text_center m_b_xxs"
                        >
                            Pago por adelantado
                        </Typography>
                        <Typography
                            scale="small"
                            weight="600"
                            textColor="secondary_500"
                            className="text_no_break text_center"
                        >
                            {priceFormatFinancial(
                                financialForm?.form?.advancedMoney ?? 0,
                                0
                            )}
                        </Typography>
                    </div>
                </div>
                <div className="display_flex">
                    <div className="flex_grow_1 flex_basis_0 border_solid border_1 border_neutral_400 p_y_xxs">
                        <Typography
                            scale="xxsmall"
                            weight="400"
                            textColor="neutral_600"
                            className="text_no_break text_center m_b_xxs"
                        >
                            Tiempo
                        </Typography>
                        <Typography
                            scale="small"
                            weight="600"
                            textColor="neutral_1000"
                            className="text_no_break text_center"
                        >
                            {financialForm?.credit?.duration} Semanas
                        </Typography>
                    </div>
                    <div className="flex_grow_1 flex_basis_0 border_solid border_1 border_neutral_400 p_y_xxs">
                        <Typography
                            scale="xxsmall"
                            weight="400"
                            textColor="neutral_600"
                            className="text_no_break text_center m_b_xxs"
                        >
                            Cuota
                        </Typography>
                        <Typography
                            scale="small"
                            weight="600"
                            textColor="primary_300"
                            className="text_no_break text_center"
                        >
                            {priceFormatFinancial(
                                financialForm?.credit?.total ?? 0,
                                0
                            )}
                        </Typography>
                    </div>
                </div>
                <div className="display_flex">
                    <div className="flex_grow_1 flex_basis_0 border_solid border_1 border_neutral_400 p_y_xxs">
                        <Typography
                            scale="xxsmall"
                            weight="400"
                            textColor="neutral_600"
                            className="text_no_break text_center m_b_xxs"
                        >
                            Total credito sin deposito
                        </Typography>
                        <Typography
                            scale="small"
                            weight="600"
                            textColor="green_300"
                            className="text_no_break text_center"
                        >
                            {priceFormatFinancial(
                                (financialForm?.credit?.total ?? 0) *
                                    (financialForm?.credit?.duration ?? 0),
                                0
                            )}
                        </Typography>
                    </div>
                </div>
                <div className="display_flex ">
                    <div className="flex_grow_1 flex_basis_0 border_solid border_1 border_neutral_400 p_y_xxs">
                        <Typography
                            scale="xxsmall"
                            weight="400"
                            textColor="neutral_600"
                            className="text_no_break text_center m_b_xxs"
                        >
                            Alianza
                        </Typography>
                        <Typography
                            scale="small"
                            weight="600"
                            textColor="neutral_900"
                            className="text_no_break text_center"
                        >
                            {financialForm?.form?.partnership ?? "--"}
                        </Typography>
                    </div>
                    <div className="flex_grow_1 flex_basis_0 border_solid border_1 border_neutral_400 p_y_xxs">
                        <Typography
                            scale="xxsmall"
                            weight="400"
                            textColor="neutral_600"
                            className="text_no_break text_center m_b_xxs"
                        >
                            Segmentacion Crédito
                        </Typography>
                        <Typography
                            scale="small"
                            weight="600"
                            textColor="neutral_900"
                            className="text_no_break text_center"
                        >
                            {financialForm?.form?.creditSegmentation}
                        </Typography>
                    </div>
                </div>
                <div className="display_flex flex_gap_md flex_align_center border_solid border_1 border_neutral_400 p_xs">
                    <div className="br_xs overflow_hidden foto_moto">
                        <img
                            src={
                                vehicle.images[0]
                                    ? vehicle.images[0].url
                                    : `${process.env.REACT_APP_STORAGE_BASE_URL}public/uploads/generic-moto.png`
                            }
                            alt="moto detail"
                        />
                    </div>
                    <div className="flex_grow_1 flex_center_col">
                        <Typography
                            scale="small"
                            weight="600"
                            className="inventory_link cursor_pointer"
                            onClick={(e) =>
                                handleDetailsVeh(e, vehicle.internalId)
                            }
                        >
                            {vehicle.internalId}
                        </Typography>

                        <Typography
                            scale="xxsmall"
                            weight="400"
                            className="m_b_xxs"
                        >
                            {vehicle.brand && vehicle.model
                                ? `${vehicle.brand.name} ${
                                      vehicle.model.name
                                  } ${vehicle.suffix ?? ""}`
                                : "no definido"}
                        </Typography>
                    </div>
                </div>
                <div className="display_flex">
                    <div className="flex_grow_1 flex_basis_0 border_solid border_1 border_neutral_400 p_y_xxs">
                        <Typography
                            scale="xxsmall"
                            weight="400"
                            textColor="neutral_600"
                            className="text_no_break text_center m_b_xxs"
                        >
                            Cita de entrega
                        </Typography>
                        <Typography
                            scale="small"
                            weight="600"
                            textColor="neutral_900"
                            className="text_no_break text_center"
                        >
                            {moment(
                                financialForm?.form?.appointment?.scheduledAt
                            )
                                .utcOffset(-360)
                                .format("YYYY-MM-DD hh:mm A")}
                        </Typography>
                    </div>
                </div>
                <div className="display_flex m_b_md">
                    <div className="flex_grow_1 flex_basis_0 border_solid border_1 border_neutral_400 p_y_xxs">
                        <Typography
                            scale="xxsmall"
                            weight="400"
                            textColor="neutral_600"
                            className="text_no_break text_center m_b_xxs"
                        >
                            Encargado de entrega
                        </Typography>
                        <Typography
                            scale="small"
                            weight="600"
                            textColor="neutral_900"
                            className="text_no_break text_center"
                        >
                            {deliverOzonio}
                        </Typography>
                    </div>
                </div>

                <ScheduleAction disable={false} />
            </div>

            <RequireRole authRoles={[Roles.ADMIN, Roles.COLLECTOR_AGENT]}>
                <div
                    id="verify-soli-ozoner"
                    className="p_md border_b_solid border_1 border_neutral_400 display_flex flex_col flex_gap_md"
                >
                    <CollectionResponsible />
                    {financialForm?.credit?.verified ? (
                        <div className="flex_center flex_gap_xs">
                            <Typography
                                scale="small"
                                weight="600"
                                textColor="green_300"
                                className="text_no_break text_center"
                            >
                                Credito Verificado
                            </Typography>
                            <Check className="text_green_300 dim_md" />
                        </div>
                    ) : (
                        <>
                            <div className="display_flex flex_gap_xs flex_align_center">
                                {validationItem(
                                    financialForm?.credit
                                        ?.collectionResponsible,
                                    "Responsable de cobranza asignado",
                                    "Responsable de cobranza sin asignar"
                                )}
                            </div>
                            <VerifyAction
                                disabled={
                                    !financialForm?.credit
                                        ?.collectionResponsible
                                }
                            />
                        </>
                    )}
                </div>
            </RequireRole>

            <div
                id="validate-soli-ozoner"
                className="p_md border_b_solid border_1 border_neutral_400 display_flex flex_col flex_gap_md"
            >
                <div className="display_flex flex_gap_xs flex_align_center">
                    {validationItem(
                        financialForm?.credit?.verified,
                        "Verificacion de cobranza hecha",
                        "Verificacion de cobranza pendiente"
                    )}
                </div>
                <div className="display_flex flex_gap_xs flex_align_center">
                    {validationItem(
                        hasDeposit,
                        "Primera Cuota y deposito adjunto",
                        "Primera Cuota y deposito sin subir"
                    )}
                </div>
                <div className="display_flex flex_gap_xs flex_align_center">
                    {validationItem(
                        hasContract,
                        "Contrato adjunto",
                        "Contrato sin subir"
                    )}
                </div>
                <div className="display_flex flex_gap_xs flex_align_center">
                    {validationItem(
                        hasPromissory,
                        "Pagare adjunto",
                        "Pagare sin subir"
                    )}
                </div>
                <div className="display_flex flex_gap_xs flex_align_center">
                    {validationItem(
                        hasDelivery,
                        "Foto entrega adjunto",
                        "Foto entrega sin subir"
                    )}
                </div>
                <div className="display_flex flex_gap_xs flex_align_center">
                    {validationItem(
                        hasDevice,
                        "Vehiculo con GPS",
                        "Vehiculo sin GPS asignado"
                    )}
                </div>
                <Button
                    scale="small"
                    color="primary"
                    disabled={
                        !hasDelivery ||
                        !hasPromissory ||
                        !hasDeposit ||
                        !hasContract ||
                        !hasDevice ||
                        !financialForm?.credit?.verified
                    }
                    onClick={() => setOpenActivate(true)}
                >
                    Entregar Moto
                </Button>
                <Button
                    scale="small"
                    color="light_grey"
                    onClick={returnSolicitud}
                >
                    Volver a aprobacion
                </Button>
            </div>

            {openActivate && (
                <ModalConfirmation
                    openConfirmation={openActivate}
                    handleClose={() => setOpenActivate(false)}
                    icon={<Check className="text_green_300" />}
                    widthDesktop={40}
                    isConfirmationLoading={false}
                    actions={[
                        {
                            label: "Volver",
                            color: "grey",
                            action: () => setOpenActivate(false),
                        },
                        {
                            label: "Activar credito",
                            color: "primary",
                            action: activateContract,
                            disabled: loading,
                        },
                    ]}
                    title={
                        <Typography scale="large" weight="400">
                            ¿Estás seguro de que{" "}
                            <Typography
                                scale="large"
                                weight="600"
                                component="span"
                            >
                                deseas activar este credito?
                            </Typography>
                        </Typography>
                    }
                    subtitle={
                        <Typography
                            scale="medium"
                            weight="400"
                            textColor="neutral_700"
                        >
                            Esta accion se debe realizar una vez se haya
                            entregado la moto y se hayan firmado todos los
                            documentos pertinentes
                        </Typography>
                    }
                />
            )}
        </>
    );
}
export default ScheduledSection;
