import { get } from "../axios/axiosInstance";

const formatDate = (d: Date) =>
  `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;

export const fetchActiveUsers = async () => {

  try {
    const url = "v1/dashboard/activeUsers";
    // if (dates) {
    //   url = `${url}?min=${formatDate(dates.initial)}&max=${formatDate(
    //     dates.last
    //   )}&city=${dates.city}`;
    // }
    const res = await get(url);
    return res.data;
  } catch (err: any) {
    throw err.response.data;
  }
};
export const fetchFirstPaymentDefault = async () => {

  try {
    const url = "v1/dashboard/firstPaymentDefault";
    // if (dates) {
    //   url = `${url}?min=${formatDate(dates.initial)}&max=${formatDate(
    //     dates.last
    //   )}&city=${dates.city}`;
    // }
    const res = await get(url);
    return res.data;
  } catch (err: any) {
    throw err.response.data;
  }
};
export const fetchDashboardVehicles = async (status: string, dates: any) => {

  try {
    if(dates){
      let url = "v1/dashboard/getVehiclesByStatus";
      url = `${url}?status=${status}&startDate=${formatDate(dates.initial)}&endDate=${formatDate(
        dates.last
      )}`;
      const res = await get(url);
      return res.data;
    }
    return null;
  } catch (err: any) {
    throw err.response?.data ?? err;
  }
};
export const fetchDashboardInventory = async () => {

  try {
    const url = "v1/dashboard/getInventory";
    // if (dates) {
    //   url = `${url}?min=${formatDate(dates.initial)}&max=${formatDate(
    //     dates.last
    //   )}&city=${dates.city}`;
    // }
    const res = await get(url);
    return res.data;
  } catch (err: any) {
    throw err.response.data;
  }
};
export const fetchDashboardCredits = async () => {

  try {
    const url = "v1/dashboard/getCredits";
    // if (dates) {
    //   url = `${url}?min=${formatDate(dates.initial)}&max=${formatDate(
    //     dates.last
    //   )}&city=${dates.city}`;
    // }
    const res = await get(url);
    return res.data;
  } catch (err: any) {
    throw err.response.data;
  }
};
export const fetchDashboardCreditsTime = async (status: string, dates: any) => {
  try {
    if (dates) {
      let url = "v1/dashboard/getCreditsByStatus";
      url = `${url}?status=${status}&startDate=${formatDate(dates.initial)}&endDate=${formatDate(
        dates.last
      )}`;
      const res = await get(url);
      return res.data;
    }
    return null;
  } catch (err: any) {
    throw err?.response?.data ?? err;
  }
};
export const fetchInventoryMaintenance = async () => {

  try {
    const url = "v1/dashboard/getInventoryMaintenance";
    // if (dates) {
    //   url = `${url}?min=${formatDate(dates.initial)}&max=${formatDate(
    //     dates.last
    //   )}&city=${dates.city}`;
    // }
    const res = await get(url);
    return res.data;
  } catch (err: any) {
    throw err.response.data;
  }
};
export const fetchPaymentAnalysis = async (dates: any) => {

  try {
    if (dates) {
      let url = "v1/dashboard/getPaymentsAnalysis";
      url = `${url}?startDate=${formatDate(dates.initial)}&endDate=${formatDate(
        dates.last
      )}`;
      const res = await get(url);
      return res.data;
    }
    return null;
  } catch (err: any) {
    throw err?.response?.data ?? err;
  }
};
