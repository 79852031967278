import moment from "moment";

export const setupMessageColor = (messageType: string) => {
  switch (messageType) {
    case "nice":
      return {
        backgroundColor: "rgb(207,243,220)",
        color: "rgb(1,85,32)",
      };
    case "regular":
      return {
        backgroundColor: "rgb(255,248,224)",
        color: "rgb(252,134,24)",
      };

    case "bad":
      return {
        backgroundColor: "rgb(254,215,223)",
        color: "rgb(216,26,64)",
      };

    default:
      return {
        backgroundColor: "white",
        color: "black",
      };
  }
};

export const toCamelCase = (str: string) => {
  const words = str.split(/[-_\s]/);
  let result = words[0].toLowerCase();

  // eslint-disable-next-line no-plusplus
  for (let i = 1; i < words.length; i++) {
    result +=
      words[i].charAt(0).toUpperCase() + words[i].slice(1).toLowerCase();
  }
  return result;
};

type TitleMapping = {
  [key: string]: string;
};

const TITLE_MAPPING: TitleMapping = {
  firstCall: "Primera Llamada",
  secondCall: "Segunda Llamada",
  ref1Call: "Llamada Ref1",
  ref2Call: "Llamada Ref2",
};

export const camelCaseToNormalText = (str: string) => {
  return (
    str
      // Insert a space before all caps
      .replace(/([A-Z])/g, " $1")
      // Upper case first character
      // eslint-disable-next-line no-shadow
      .replace(/^./, (str) => {
        return str.toUpperCase();
      })
  );
};

export const transformTitle = (title: string) => {
  return TITLE_MAPPING[title] || camelCaseToNormalText(title);
};

export const transformDate = (dateString: string, format = "shortFormat") => {
  switch (format) {
    case "shortFormat":
      return moment(dateString).format("M/D/YY");

    case "full":
      return moment(dateString).locale("es").format("dddd, D MMMM");

    default:
      return moment(dateString).format("M/D/YY");
  }
};
