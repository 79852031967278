import { put } from "helpers/axios/axiosInstance";
import { TPasswordRecover } from "models/passwordRecover";

export const fetchRecover = async (recover: TPasswordRecover) => {
  
  try {
    const res = await put("v1/user/password", recover);
    return res.data;
  } catch (err: any) {
    throw err.response.data;
  }
};
