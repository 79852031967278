import { TBrand } from "models/brand.interface";


const clearBrands = () => {
  localStorage.removeItem("brands");
};
const getBrandsModels = () => {
  const brands = localStorage.getItem("brands");
  if (brands) {
    const parsed = JSON.parse(brands);
    if (parsed.timeout > Date.now())
      return parsed.data;
    clearBrands();
  }
  return null;
};

const setBrands = (brands:TBrand[])=>{
  const timeout = new Date();
  timeout.setDate(timeout.getDate()+1);
  localStorage.setItem("brands", JSON.stringify({timeout : timeout.getTime(),data:brands}));
};

const clearRoles = () => {
  localStorage.removeItem("roles");
};
const getRoles = () => {
  const roles = localStorage.getItem("roles");
  if (roles) {
    const parsed = JSON.parse(roles);
    if (parsed.timeout > Date.now())
      return parsed.data;
    clearRoles();
  }
  return null;
};

const setRoles = (roles: TBrand[]) => {
  const timeout = new Date();
  timeout.setDate(timeout.getDate() + 1);
  localStorage.setItem("roles", JSON.stringify({ timeout: timeout.getTime(), data: roles }));
};



export {
  getBrandsModels,
  setBrands,
  clearBrands,
  getRoles,
  setRoles,
  clearRoles,
};
