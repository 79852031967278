export const sanitizeName = (input: string) => {
  // Eliminar caracteres especiales, números y espacios adicionales
  let sanitized = input
    .replace(/[^a-zA-ZáéíóúÁÉÍÓÚüÜñÑ ]/g, "") // Agregar los caracteres con acento a la expresión regular
    .replace(/\s+/g, " ")
    .trim();
  // Capitalizar primera letra de cada palabra
  sanitized = sanitized
    .split(" ")
    .map((word) => word.charAt(0).toLocaleUpperCase() + word.slice(1))
    .join(" ");
  return sanitized;
};
