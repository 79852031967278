/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-props-no-spreading */
import React, { FC } from "react";
import { Grid, GridSize } from "@mui/material";
import { ReactComponent as Close } from "../../static/icons/close-lg.svg";


import "./SideModal.scss";

interface SideProps {
  open: boolean;
  setOpen: Function;
  children: React.ReactNode;
  fullOpen?: boolean;
  setFullOpen?: Function;
  className?: string;
  onClose?: Function;
  size?: "large" | "medium" | "normal" | "small";
}
const sizes={
  large:[10,2],
  medium:[8,4],
  normal:[6,6],
  small:[6,6],
};
const SideModal: FC<SideProps> = ({
  open,
  setOpen,
  children,
  fullOpen = false,
  setFullOpen = () => {},
  onClose = () => {},
  size = "normal",
  className = "",
}) => {

  const contents = React.Children.toArray(children) as React.ReactElement[];

  return (
    <div
      className={`SideModal ${open ? "open" : ""} ${
        fullOpen ? "full-open" : ""
      } ${className} ${size}`}
    >
      <div
        className="backdrop"
        onClick={() => {
          
          setOpen(false);
          setFullOpen(false);
          onClose();
        }}
        onKeyPress={() => {
          setOpen(false);
          setFullOpen(false);
          onClose();
        }}
        role="button"
        tabIndex={0}
      />
      <div className="br_l_xl p_y_md main-cont m_l_none w_100_per">
      <Grid
        container
        direction="row"
        spacing={3}
        className="h_100_per m_l_none"
      >
        <Grid
          item
          xs={(sizes[size][0]) as GridSize}
          className="overflow_y_auto p_x_xxxl h_100_per"
        >
          {contents[0]}
        </Grid>
        <Grid
          item
          xs={(sizes[size][1]) as GridSize}
          className="overflow_y_auto p_x_xxxl h_100_per"
        >
          {contents[1]}
        </Grid>
      </Grid>
      <Close className="close_modal cursor_pointer dim_lg" 
          onClick={() => {
            if(fullOpen){
              setFullOpen(false);
            }
            else{
              setOpen(false);
            }
            onClose();
          }}
          onKeyPress={() => {
            setOpen(false);
            onClose();
          }}
          role="button"
          tabIndex={0}
          />
      </div>
    </div>
  );
};

export default SideModal;
