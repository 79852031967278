// eslint-disable-next-line no-shadow
export enum CreditPaymentStatus {
  // eslint-disable-next-line no-unused-vars
  PAID = "paid",
  PENDING = "pending",
  // eslint-disable-next-line no-unused-vars
  CURRENT = "current",
  // eslint-disable-next-line no-unused-vars
  EXPIRED = "expired",
  DELETED = "deleted",
}

export interface TCreditPaymentAttachment {
  id: number;
  isVoucher: boolean;
  name: string;
  path: string;
  uuid: string;
  _id: string;
}

export interface TCreditPayment {
  _id: string;
  discount: number;
  dueDate: string;
  issueDate: string;
  paidDate: string;
  status: CreditPaymentStatus;
  subtotal: number;
  tax: number;
  total: number;
  attachment: TCreditPaymentAttachment[];
  period?:number;
  events?:any[];
  quota:number;
}
export interface TCreditPaymentPending {
  _id: string;
  dueDate: string;
  attachment: TCreditPaymentAttachment[];
  total: number;
}

export const CreditPaymentStatusValues = {
  [CreditPaymentStatus.PAID]: {
    title: "Pagado",
    color: "green_300",
  },
  [CreditPaymentStatus.PENDING]: {
    title: "Pendiente",
    color: "secondary_500",
  },
  [CreditPaymentStatus.CURRENT]: {
    title: "Pronto Pago",
    color: "secondary_500",
  },
  [CreditPaymentStatus.EXPIRED]: {
    title: "Vencido",
    color: "red_300",
  },
  [CreditPaymentStatus.DELETED]: {
    title: "Anulado",
    color: "neutral_400",
  },
};
