/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-props-no-spreading */
import React, { FC } from "react";

import { ReactComponent as Left } from "../../static/icons/left-sm.svg";
import { ReactComponent as Right } from "../../static/icons/right-sm.svg";

import "./Pager.scss";
import { Typography } from "../Typography";

interface PagerProps {
  totalPages: number;
  page:number
  count:number;
  setPage?: Function;
  className?: string;
  pageSize?:number;
}
const Pager: FC<PagerProps> = ({
  totalPages,
  page,
  count,
  className = "",
  setPage = () => {},
  pageSize = 30,
}) => {

  const next = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const back = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };
  return (
    <div
      className={`pager-cont display_flex flex_align_baseline flex_gap_md ${className}`}
    >
      <Typography scale="small" weight="400">
        {(page - 1) * pageSize + 1} -{" "}
        {page * pageSize > count ? count : page * pageSize} de {count}
      </Typography>
      <div
        className="br_xxxs bg_neutral_0 p_xs pager_arrows"
        onClick={back}
        onKeyPress={back}
        role="button"
        tabIndex={0}
      >
        <Left className="dim_sm" />
      </div>
      <div className="display_flex flex_gap_md pages-cont p_b_xs pretty_slider">
        {Array(totalPages)
          .fill(0)
          .map((_ele, i) => (
            <div
              className={`br_xs p_x_xs p_y_xxs pager_numbers text_small_400 ${
                page === i + 1 ? "active" : ""
              }`}
              onClick={() => setPage(i + 1)}
              onKeyPress={() => setPage(i + 1)}
              role="button"
              tabIndex={0}
              key={i}
            >
              {i + 1}
            </div>
          ))}
      </div>
      <div
        className="br_xxxs bg_neutral_0 p_xs pager_arrows"
        onClick={next}
        onKeyPress={next}
        role="button"
        tabIndex={0}
      >
        <Right className="dim_sm" />
      </div>
    </div>
  );
};

export default Pager;