import moment from "moment";
import { Repossession } from "models/repossession.interface";
import { get, put, post, deletes } from "../axios/axiosInstance";
import { TCredit, TCreditTotals } from "../../models/credit.interface";
// import CreditsMock from "../mocks/getCreditsTotalsResponse.json";
import { TCreditPayment } from "../../models/credit-payment.interface";

export interface FetchCreditsResponse {
  count: number;
  pages: number;
  page: number;
  totals: any;
  data: TCredit[];
}

export const FetchCredits = async (
  // eslint-disable-next-line no-unused-vars
  page?: number,
  // eslint-disable-next-line no-unused-vars
  status?: string,
  query?: string,
  aditionalQuery?:string,
  fieldsRequired?:string,
): Promise<FetchCreditsResponse> => {
  // eslint-disable-next-line no-unused-vars
  try {
    const res = await get<FetchCreditsResponse>(
      `/v1/credit?&page=${page || 1}${status ? `&status=${status}` : ""}${
        query ? `&query=${query}` : ""}${fieldsRequired ?`&fieldsRequired=${fieldsRequired}`:""}${
      page ? "" : "&limit=-1"}&${aditionalQuery}`
    );
    return res.data;
    // return CreditsMock as unknown as FetchCreditsResponse;
  } catch (err: any) {
    throw err.response.data;
  }
};

export const editCredit = async (id: string, data: any): Promise<any> => {
  try {
    const res = await put<any>(`/v1/credit/${id}`, data);
    return res.data;
  } catch (err: any) {
    throw err.response.data;
  }
};
export const FetchCreditsResume = async (dates?: {
  start: Date;
  end: Date;
}): Promise<FetchCreditsResponse> => {
  // eslint-disable-next-line no-unused-vars

  try {
    const res = await get<any>(
      `/v1/credit/resume${
        dates
          ? `?min=${moment.utc(dates.start).format("YYYY-MM-DD")}&max=${moment
              .utc(dates.end)
              .format("YYYY-MM-DD")}`
          : ""
      }`
    );
    return res.data;
    // return CreditsMock as unknown as FetchCreditsResponse;
  } catch (err: any) {
    throw err.response.data.message;
  }
};

export const FetchCredit = async (id: string): Promise<TCredit> => {
  try {
    const res = await get<TCredit>(`/v1/credit/${id}`);
    return res.data;
  } catch (err: any) {
    throw err.response.data.message;
  }
};
export const FetchEstimateRestructure = async (id: string,values:any): Promise<any> => {
  try {
    const res = await put<any>(`/v1/credit/${id}/estimateRestructure`,values);
    return res.data;
  } catch (err: any) {
    throw err.response.data.message;
  }
};
export const RestructureCredit = async (id: string,values:any): Promise<any> => {
  try {
    const res = await put<any>(`/v1/credit/${id}/restructure`,values);
    return res.data;
  } catch (err: any) {
    throw err.response.data.message;
  }
};
export const DeleteCredit = async (id: string): Promise<TCredit> => {
  try {
    const res = await deletes<TCredit>(`/v1/credit/${id}`);
    return res.data;
  } catch (err: any) {
    throw err.response.data;
  }
};

export const ActivateCredit = async (id: string): Promise<TCredit> => {
  try {
    const res = await post<TCredit>(`/v1/credit/${id}/activate`);
    return res.data;
  } catch (err: any) {
    throw err.response.data.message;
  }
};
export const settleDebt = async (id: string,data:any): Promise<TCredit> => {
  try {
    const res = await post<TCredit>(`/v1/credit/settle-debt/${id}`,data);
    return res.data;
  } catch (err: any) {
    throw err.response.data.message;
  }
};

export const addFileCredit = async (
  credit: string,
  file: File,
  type: string = "other",
  alternativeName:string=""
): Promise<any> => {
  try {
    const data = new FormData();
    data.append("name", alternativeName !== "" ? alternativeName :file.name);
    data.append("file", file);

    const res = await put(`v1/credit/${credit}/${type}`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return res.data;
  } catch (err: any) {
    throw err.response.data.message;
  }
};
export const FetchCreditTotals = async (id: string): Promise<TCreditTotals> => {
  try {
    // return CreditsMock;
    // // eslint-disable-next-line no-unreachable
    const res = await get<TCreditTotals>(`/v1/credit/${id}/totals`);
    return res.data;
  } catch (err: any) {
    throw err.response.data;
  }
};
export const FetchCreditRepossession = async (id: string): Promise<Repossession[]> => {
  try {
    // return CreditsMock;
    // // eslint-disable-next-line no-unreachable
    const res = await get<Repossession[]>(`/v1/repossession/credit/${id}`);
    return res.data;
  } catch (err: any) {
    throw err.response.data;
  }
};

export const FetchCreditByOzonerId = async (id: string): Promise<any> => {
  try {
    // return CreditsMock;
    // // eslint-disable-next-line no-unreachable
    const res = await get<any>(`/v1/credit/ozoner/${id}`);
    return res.data;
  } catch (err: any) {
    throw err.response.data;
  }
};

export const UpdateCreditPaymentStatus = async (
  credit: TCredit,
  payment: TCreditPayment
): Promise<TCreditPayment> => {
  try {
    const res = await put<TCreditPayment>(
      // eslint-disable-next-line no-underscore-dangle
      `/v1/credit/${credit._id}/payments/${payment._id}`
    );
    return res.data;
  } catch (err: any) {
    throw err.response.data;
  }
};

export const UpdateCreditDeposit = async (
  creditId: string,
  deposit: number
): Promise<TCredit> => {
  try {
    const res = await put<TCredit>(
      `/v1/credit/${creditId}/add-deposit`,
      {deposit}
    );
    return res.data;
  } catch (err: any) {
    throw err.response.data;
  }
};

export const exportCredit = async (pages?: string, params?: string,fields?:string,limit?:number) => {
  try {
    let path = "v1/credit/export";
    let first = true;
    if (pages) {
      path = `${path}${first?"?":"&"}pages=${pages}`;
      first=false;
    }
    if (limit) {
      path = `${path}${first ? "?" : "&"}limit=${limit}`;
      first = false;
    }
    if (fields) {
      path = `${path}${first ? "?" : "&"}fieldsRequired=${fields}`;
      first = false;
    }
    if (params) {
      path = `${path}${first ? "?" : "&"}${params}`;
      first = false;
    }


    const res = await get<any>(path);
    return res.data;
  } catch (err: any) {
    throw err.response.data;
  }
};

export const completeCredit = async (
  id: string,
  amount: number
): Promise<void> => {
  try {
    await put<TCreditPayment>(`/v1/credit/${id}/complete`, {
      debtCompletedFor: amount,
    });
  } catch (err: any) {
    throw err.response.data;
  }
};
export const returnCredit = async (
  id: string,
): Promise<void> => {
  try {
    await put<TCreditPayment>(`/v1/credit/${id}/return-pending`);
  } catch (err: any) {
    throw err.response.data;
  }
};
export const syncPC = async (
  id: string,
): Promise<void> => {
  try {
    await put<TCreditPayment>(`/v1/credit/${id}/porcobrarSync`);
  } catch (err: any) {
    throw err.response.data;
  }
};

// eslint-disable-next-line no-shadow
export enum AmountType{
  FIXED=1,
  PERCENTAGE=0
};
export const UpdatePaymentsFees = async (
  creditId: string,
  paymentsId: string[],
  type: AmountType,
  amount:number
): Promise<TCredit> => {
  try {
    const res = await put<TCredit>(
      `/v1/credit/${creditId}/putFees`,
      { 
        paymentsId,
        [type ? "feeFixed":"feePercentage"]:amount
      }
    );
    return res.data;
  } catch (err: any) {
    throw err.response.data;
  }
};
export const UpdatePaymentsDiscount = async (
  creditId: string,
  paymentsId: string[],
  type: AmountType,
  amount:number
): Promise<TCredit> => {
  try {
    const res = await put<TCredit>(
      `/v1/credit/${creditId}/putDiscount`,
      { 
        paymentsId,
        [type ? "discountFixed" :"discountPercentage"]:amount
      }
    );
    return res.data;
  } catch (err: any) {
    throw err.response.data;
  }
};

