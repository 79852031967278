/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useState } from "react";

// -----------------LIBRARIES IMPORTS------------------- //
// @ts-ignore
import {
  Button,
  Typography,
  CheckboxInput,
  Select,
  ModalConfirmation as Modal,
  DataTable,
  SideModal,
} from "design_system/src";
import { Divider, Grid } from "@mui/material";
import moment from "moment";
import "moment/locale/es";
import Dropzone from "react-dropzone";
import { toasterNotification } from "helpers/globalNotifications/globalNotifications";
import { toasterOptions } from "enums/toaster.enum";
import Swal from "sweetalert2";

// -----------------DATA IMPORTS------------------- //
import { priceMXNTotal } from "helpers/prices/prices";
import {
  TDataozoner,
  statusOzoners,
} from "models/ozoner.interface";
import { statusVehicles } from "models/vehicle.interface";
import { addFile, deleteFile, editOzoner } from "helpers/fetchs/ozoners";
import { editSolicitud } from "helpers/fetchs/solicitudes";
import { economicActivityEnum } from "models/financialForm.interface";

// -----------------STATIC IMPORTS------------------- //
import "../../ozoner.scss";
import { ReactComponent as Mail } from "design_system/src/static/icons/mail.svg";
import { ReactComponent as Whatsapp } from "design_system/src/static/icons/whatsapp.svg";
import { ReactComponent as Edit } from "design_system/src/static/icons/edit-text.svg";
import { ReactComponent as Refresh } from "design_system/src/static/icons/refresh.svg";
import { ReactComponent as Check } from "design_system/src/static/icons/checkmark.svg";
import { ReactComponent as Eye } from "design_system/src/static/icons/eye.svg";
import { ReactComponent as Reload } from "design_system/src/static/icons/reload.svg";
import { ReactComponent as Close } from "design_system/src/static/icons/close-lg.svg";
import { ReactComponent as Info } from "design_system/src/static/icons/info.svg";

// -----------------COMPONENTS IMPORTS------------------- //
import EditOzoner from "../../edits/EditOzoner";
import { EditTypesOzoner, displayQuota } from "../DetailsOzoner";
import Rejected from "../modals/Rejected";
import { ozonerContext } from "../OzonerContext";

const detailsData = [
  {
    title: "Nivel de Estudios",
    name: "educationalLevel",
  },

  {
    title: "Estado Civil",
    name: "civilStatus",
  },
  {
    title: "Dependientes Económicos",
    name: "dependantsCount",
  },
];

const rejectedReasons = {
  maxWeekly: {
    msg: "exceder el cupo máximo semanal",
    actionName: "Cambiar Vehículo",
  },
  creditRisk: {
    msg: "riesgo créditicio",
  },
  reported: {
    msg: "reportado",
  },
  capacity: {
    msg: "capacidad de pago",
  },
};


interface DataProps {
  handleDetailsVeh: Function;
  openEditOzoner: Function;
}
function DataOzoner({ handleDetailsVeh, openEditOzoner }: DataProps) {
  const {
      ozoner,
      vehicle,
      financialForm,
      setResult,
      fetchData,
      ozonioLastChange,
      validCurp,
      canValidateCurp,
      hasCredit,
  } = useContext(ozonerContext);

  // -----------------DATA STATES------------------- //
  const [age, setAge] = useState<any>("no definido");
  const [gender, setGender] = useState<any>("no definido");
  const [address, setAddress] = useState<any>("no definido");
  const [comment, setComment] = useState<string>("");

  // -----------------OPEN STATES------------------- //

  // -----------------SUBSTATUS STATES------------------- //
  const [substatusChange, setSubstatusChange] = useState<any>("");
  const [openSubstatusChange, setOpenSubstatusChange] =
  useState<boolean>(false);
  
  // ---------- handle modal--------------

  

  useEffect(() => {
    if (ozoner?.curp) {
      moment.parseTwoDigitYear = (yearString) => {
        const year = parseInt(yearString, 10);
        return year + (year > moment().year() % 100 ? 1900 : 2000);
      };
      const actualYear = moment(ozoner.curp.substring(4, 10), "YYMMDD");
      setAge(moment().diff(actualYear, "years", false));
      setGender(
        ozoner.curp.toUpperCase().substring(10, 11) === "H"
          ? "Masculino"
          : "Femenino"
      );
    }
    setComment(ozoner?.comment ?? "");
  }, [ozoner]);

  useEffect(() => {
    if (ozoner?.fullAddress) {
      setAddress(
        `${ozoner?.fullAddress.street} ${ozoner?.fullAddress.extNumber} ${ozoner?.fullAddress.intNumber ?? ""
        }, ${ozoner?.fullAddress.neighborhood}, ${ozoner?.fullAddress.cp}, ${ozoner?.fullAddress.delegation
        }, ${ozoner?.fullAddress.state}`
      );
    } else if (ozoner?.address) {
      setAddress(`${ozoner?.address} (Direccion antigua v1 pandaboard)`);
    } else {
      setAddress("no definido");
    }
  }, [ozoner, financialForm]);

  const updateComment = () => {
    if (ozoner)
      editOzoner({ comment }, ozoner._id).then((ele) => {
        setResult(ele);
      });
  };

  const goWA = (number: string) =>
    window.open(`https://api.whatsapp.com/send?phone=${number}`, "_blank");

  const getDetails = () => {

    let data: any=[];
    data.push({
        label: "Curp",
        value: `${ozoner.curp}`,
        valueColor:canValidateCurp?(validCurp?"green_300":"red_300"):"neutral_900",
    });
     data=[...data, ...detailsData.map((field: any) => ({
      label: field.title,
      value: `${field.map
        ? field.map[
        (ozoner as any)[field.name] ?? (financialForm as any)?.form?.[field.name]
        ]
        : (ozoner as any)[field.name] ?? (financialForm as any)?.form?.[field.name]
        }`,
    }))];
    data.push({
      label: "Direccion",
      value: `${address}`,
    });
    data.push({
      label: "Vive con",
      value: `${financialForm?.form?.livesWith?.join(", ").slice(0, -1)}`,
    });
    data.push({
      label: "Activos",
      value: `${financialForm?.form?.assets?.joinedAssets}`,
    });
    data.push({
      label: "Ocupación",
      value: `${financialForm?.form?.economicActivity?.value === economicActivityEnum.otro?financialForm?.form?.economicActivity?.description :financialForm?.form?.economicActivity?.value}`,
    });
    data.push({ label: "Edad", value: `${age}` });
    data.push({ label: "Genero", value: `${gender}` });
    if (ozoner.vehicle) {
      data.push({
        label: "Pago semanal",
        value: `${vehicle?.paymentWeek?priceMXNTotal(vehicle.paymentWeek, 0):"--"}`,
      });
    }
    return data;
  };

  return (
      <Grid
        container
        direction="row"
        spacing={3}
        className="h_100_per p_t_md"
      >
        <Grid item xs={3}>
          <div className="bg_neutral_0 border_neutral_400 border_solid border_1 br_sm">

            <div id="vehicle-ozoner" className="p_md border_b_solid border_1 border_neutral_400 display_flex flex_gap_md flex_align_center">
              {vehicle ? (<>
                <div className="br_xs overflow_hidden foto_moto">
                  <img
                    src={
                      vehicle.images[0]
                        ? vehicle.images[0].url
                        : `${process.env.REACT_APP_STORAGE_BASE_URL}public/uploads/generic-moto.png`
                    }
                    alt="moto detail"
                  />
                </div>
                <div className="flex_grow_1 flex_center_col">
                  <Typography
                    scale="small"
                    weight="600"
                    className="inventory_link cursor_pointer"
                    onClick={(e) => handleDetailsVeh(e, vehicle.internalId)}
                  >
                    {vehicle.internalId}
                  </Typography>

                  <Typography
                    scale="xxsmall"
                    weight="400"
                    className="m_b_xxs"
                  >
                    {vehicle.brand && vehicle.model
                      ? `${vehicle.brand.name} ${vehicle.model.name} ${vehicle.suffix ?? ""
                      }`
                      : "no definido"}
                  </Typography>
                  <div
                    className={` br_xs p_x_md p_y_xxs  bg_${financialForm?.vehicleAvailable?statusVehicles[
                      vehicle.status as keyof typeof statusVehicles
                    ]?.color:"neutral_600"
                      }_nocontrast`}
                  >
                    <Typography
                      scale="small"
                      weight="600"
                      textColor="neutral_0"
                      className="text_center"
                    >
                      {
                        financialForm?.vehicleAvailable?statusVehicles[
                          vehicle.status as keyof typeof statusVehicles
                        ]?.title??vehicle.status:"No disponible"
                      }
                    </Typography>
                  </div>
                </div>
              </>) : (<>
                <div className="br_xs overflow_hidden foto_moto">
                  <img
                    src={`${process.env.REACT_APP_STORAGE_BASE_URL}public/uploads/generic-moto.png`}
                    alt="moto detail"
                  />
                </div>
                <div className="flex_grow_1 flex_center_col">
                  <Typography
                    scale="small"
                    weight="600"
                    className="inventory_link cursor_pointer"
                  >
                    Sin Moto
                  </Typography>

                  <div
                    className="br_xs p_x_md p_y_xxs  bg_neutral_600_nocontrast"
                  >
                    <Typography
                      scale="small"
                      weight="600"
                      textColor="neutral_0"
                      className="text_center"
                    >
                      Por Asignar
                    </Typography>
                  </div>
                </div>
              </>)}
            </div>
            <div id="contacto-ozoner" className="p_md border_b_solid border_1 border_neutral_400">
              <Typography
                scale="medium"
                weight="400"
              >
                Contacto
              </Typography>
              <div className="display_flex flex_gap_md p_y_md">
                <Whatsapp className="dim_lg text_primary_300 flex_shrink_0" />
                <Typography
                  scale="medium"
                  weight="600"
                  className="text_link"
                  onClick={() => goWA(ozoner.phone)}
                >
                  {ozoner.phone}
                </Typography>
              </div>
              <div className="display_flex flex_gap_md p_y_md">
                <Mail className="dim_lg text_primary_300 flex_shrink_0" />
                <Typography
                  scale="medium"
                  weight="600"
                  className="overflow_ellipsis text_link"
                  onClick={() => {
                    window.location.href = `mailto:${ozoner.email}`;
                  }}
                >
                  {ozoner.email}
                </Typography>
              </div>
              {/* {type === 0 && (
                <div className="br_xs bg_neutral_300 p_x_lg p_y_md display_flex flex_gap_sm">
                  <CheckboxInput
                    className="m_b_none"
                    checked={ozoner?.reached ?? false}
                    value=""
                    label=""
                    onChange={() =>
                      setModalOption(ozoner?.reached ? "unreach" : "reach")
                    }
                  />
                  {ozoner?.reached ? (
                    <Typography scale="xxsmall" weight="400">
                      Contactado Por:{" "}
                      <Typography scale="xxsmall" weight="600" component="span">
                        {ozonioReached}
                      </Typography>
                    </Typography>
                  ) : (
                    <Typography scale="xxsmall" weight="400">
                      ¿Contactaste al ozoner?
                    </Typography>
                  )}
                </div>
              )} */}
            </div>
            <div id="estado-ozoner" className="p_md border_b_solid border_1 border_neutral_400">
              <div className="display_flex flex_justify_between flex_align_center m_b_xs">
                <Typography
                  scale="medium"
                  weight="400"
                  textColor="neutral_800"
                  className="text_no_break"
                >
                  Estado Ozoner:
                </Typography>
                <Typography
                  scale="medium"
                  weight="600"
                  className={`br_xs p_x_md p_y_xxxs text_center text_neutral_0 pos_relative
                                        bg_${(statusOzoners as any)[ozoner.status]
                      ?.color
                    }_nocontrast`}
                >
                  {(statusOzoners as any)[ozoner.status]?.title}
                </Typography>
              </div>
              {ozonioLastChange && (
                <div className="br_xs bg_neutral_300 p_x_lg p_y_md flex_gap_sm flex_center">
                  <Typography
                    scale="xxsmall"
                    weight="400"
                    className="text_neutral_800 text_no_break"
                  >
                    Ultima modificación:
                  </Typography>
                  <Typography
                    scale="xxsmall"
                    weight="600"
                    className="text_neutral_900 text_no_break"
                  >
                    {ozonioLastChange}
                  </Typography>
                </div>
              )}
            </div>

          </div>
        </Grid>

        <Grid item xs={9}>
          <Grid container direction="row" spacing={3} className="">
            <Grid item xs={7} className="">
              <div className="br_xxs bg_neutral_0 p_xs border_1 border_solid border_neutral_400 h_100_per pos_relative">
                <Button
                  onClick={() => openEditOzoner("Editar Ozoner", EditTypesOzoner.OZONER_DATA)}
                  variant="icon"
                  icon={<Edit />}
                  subvariant="edit"
                  scale="small"
                  className="edit-button"
                />
                {canValidateCurp && !validCurp && (
                    <div
                        id="info-soli-ozoner"
                        className="p_xs flex_center_col bg_red_200 flex_gap_xs"
                    >
                        <div className="flex_center flex_gap_xs">
                            <Info className="dim_lg text_red_300" />
                            <Typography
                                scale="small"
                                weight="600"
                                textColor="red_300"
                            >
                                El CURP del ozoner esta mal
                            </Typography>
                        </div>
                        <Typography
                            scale="xsmall"
                            textColor="red_300"
                            weight="400"
                            className="text_center"
                        >
                            El CURP que registra truora es diferente al de panda
                        </Typography>
                    </div>
                )}
                <DataTable
                  data={getDetails()}
                  weightLabels={3}
                  weightValues={2}
                />
              </div>
            </Grid>
            <Grid item xs={5} className="display_flex flex_col flex_gap_lg pos_relative">
              {[1, 2].map(num => (
                <div>
                  <Typography
                    scale="medium"
                    weight="600"
                    className="cursor_pointer text_center p_x_lg p_y_md reference_tab w_fit"
                    key={num}
                  >
                    Referencia {num}
                  </Typography>
                  <div className="br_xxs bg_neutral_0 p_xs border_1 border_solid border_neutral_400 m_b_sm pos_relative">
                    {num === 1 ? (<Button
                      onClick={() => openEditOzoner("Editar Referencias", EditTypesOzoner.PERSONAL_REFERENCES)}
                      variant="icon"
                      icon={<Edit />}
                      subvariant="edit"
                      scale="small"
                      className="edit-button"
                    />) : null}
                    <DataTable
                      weightLabels={3}
                      weightValues={2}
                      data={[
                        {
                          label: "Nombre",
                          value: financialForm?.form?.personal_references?.[`personal_reference_${num}`].name ?? "",
                        },
                        {
                          label: "Teléfono",
                          value: financialForm?.form?.personal_references?.[`personal_reference_${num}`].phone ?? "",
                          onClick: () =>
                            goWA(financialForm?.form?.personal_references?.[`personal_reference_${num}`].phone),
                        },
                        {
                          label: "Parentesco",
                          value:
                            financialForm?.form?.personal_references?.[`personal_reference_${num}`].other ||
                            financialForm?.form?.personal_references?.[`personal_reference_${num}`].relation || "",
                        },
                      ]}
                    />
                  </div>
                </div>
              ))}

            </Grid>
            <Grid item xs={12} className="">
              <Typography
                scale="large"
                weight="600"
                textColor="neutral_900"
                className="m_y_md"
              >
                Observaciones del ozoner :
              </Typography>
              <textarea
                className="observaciones w_100_per p_md border_1 border_solid border_neutral_400"
                placeholder="Agregue todas las observaciones del ozoner que se detecten"
                value={comment}
                onChange={(e: any) => setComment(e.target.value)}
              />
              {comment !== ozoner?.comment && (
                <div className="display_flex flex_justify_end">
                  <Button
                    scale="small"
                    variant="ghost"
                    onClick={updateComment}
                  >
                    Guardar Cambios
                  </Button>
                  <Button
                    scale="small"
                    variant="ghost"
                    onClick={() => setComment(ozoner?.comment ?? "")}
                  >
                    Cancelar
                  </Button>
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
  );
}
export default DataOzoner;
