/* eslint-disable no-nested-ternary */
import React, { useState, useContext, useEffect } from "react";

// -----------------LIBRARIES IMPORTS------------------- //
// @ts-ignore
import {
    Button,
    Input,
    ModalConfirmation,
    Typography,
} from "design_system/src";
import { ReactComponent as Reject } from "design_system/src/static/icons/delete-folder.svg";
import { ReactComponent as Check } from "design_system/src/static/icons/data-quality.svg";
import { ReactComponent as Money } from "design_system/src/static/icons/money.svg";
import { ReactComponent as Percentage } from "design_system/src/static/icons/percentage.svg";

// -----------------DATA IMPORTS------------------- //

// -----------------STATIC IMPORTS------------------- //
import "../../../ozoner.scss";

// -----------------COMPONENTS IMPORTS------------------- //
import { toasterNotification } from "helpers/globalNotifications/globalNotifications";
import { cancelScheduleFinancialForm, createCredit, editSolicitud, scheduleFinancialForm } from "helpers/fetchs/solicitudes";
import { toasterOptions } from "enums/toaster.enum";
import { handleEventGoogle } from "helpers/analytics/googleAnalytics";
import { formStatusEnum } from "models/financialForm.interface";
import { commonErrorHandler } from "helpers/errors/errors";
import { editCredit } from "helpers/fetchs/credits";
import { priceFormatFinancial } from "helpers/prices/prices";
import moment from "moment";
import {
    creditSegmentationOptions,
    ozonerContext,
    partnershipSegmentationOptions,
} from "../../OzonerContext";
import Rejected from "../../modals/Rejected";
import Devolution from "../../modals/Devolution";

export function CancelAction() {
    const { financialForm, setResult } = useContext(ozonerContext);

    const [loading, setLoading] = useState(false);
    const [openCancelReason, setOpenCancelReason] = useState(false);
    const [canceledReason, setCanceledReason] = useState("");

    const closeCancelReason = () => {
        setOpenCancelReason(false);
        setCanceledReason("");
    };

    const cancelSolicitud = () => {
        if (canceledReason && financialForm?.form?._id) {
            setLoading(true);
            editSolicitud(financialForm.form._id, {
                canceledReason,
                status: formStatusEnum.canceled,
            })
                .then((_) => {
                    setLoading(false);
                    setResult();
                })
                .catch((e) => {
                    setLoading(false);
                    toasterNotification({
                        msg: e ?? "Ocurrio un error",
                        toasterType: toasterOptions.error,
                        style: "light",
                    });
                });
        }
    };

    return (
        <>
            <div
                id="actions-soli-ozoner"
                className="p_md border_b_solid border_1 border_neutral_400 display_flex flex_col flex_gap_xs"
            >
                <Button
                    scale="small"
                    color="neutral"
                    onClick={() => setOpenCancelReason(true)}
                >
                    Cancelar solicitud
                </Button>
            </div>
            {openCancelReason && (
                <ModalConfirmation
                    openConfirmation={openCancelReason}
                    handleClose={closeCancelReason}
                    icon={<Reject className="text_primary_300" />}
                    widthDesktop={40}
                    isConfirmationLoading={false}
                    actions={[
                        {
                            label: "Volver",
                            color: "grey",
                            action: closeCancelReason,
                        },
                        {
                            label: "Cancelar solicitud",
                            color: "primary",
                            action: () => cancelSolicitud(),
                            disabled:
                                !canceledReason ||
                                canceledReason === "" ||
                                loading,
                        },
                    ]}
                >
                    <>
                        <Typography
                            scale="large"
                            weight="600"
                            className="m_b_xs"
                        >
                            Deseas cancelar la solicitud
                        </Typography>
                        <Typography
                            scale="medium"
                            weight="400"
                            className="m_b_md"
                            textColor="neutral_700"
                        >
                            Use esta accion si explicitamente el ozoner solicito
                            no continuar con la solicitud por algun motivo
                        </Typography>

                        <Typography
                            scale="large"
                            weight="600"
                            textColor="neutral_900"
                            className="m_y_md"
                        >
                            Motivo de cancelación :
                        </Typography>
                        <textarea
                            className="observaciones w_100_per p_md border_1 border_solid border_neutral_400"
                            placeholder="Describa el motivo de cancelación de esta solicitud"
                            value={canceledReason}
                            onChange={(e: any) =>
                                setCanceledReason(e.target.value)
                            }
                        />
                    </>
                </ModalConfirmation>
            )}
        </>
    );
}

export function RejectAction() {
    const { financialForm, setResult } = useContext(ozonerContext);
    const [rejectedReason, setRejectedReason] = useState<string>("");
    const [openRejectReason, setOpenRejectReason] = useState<boolean>(false);

    const rejectSolicitud = () => {
        setOpenRejectReason(true);
        handleEventGoogle(
            "BTN_Pb_request_reject",
            "Panda Solicitudes",
            "click in reject request button"
        );
    };

    const updateRejectedSolicitud = () => {
        const dataRejected: any = {
            status: formStatusEnum.rejected,
            rejectedReason,
        };
        if (financialForm?.form) {
            editSolicitud(financialForm.form._id, dataRejected).then(() => {
                setResult();
                setOpenRejectReason(false);
            });
        }
    };

    return (
        <>
            <Button scale="small" color="red" onClick={rejectSolicitud}>
                Rechazar
            </Button>

            {openRejectReason && (
                <Rejected
                    changeStatusRejected={updateRejectedSolicitud}
                    selected={rejectedReason}
                    setSelected={setRejectedReason}
                    open={openRejectReason}
                    setOpen={setOpenRejectReason}
                />
            )}
        </>
    );
}

const formatDate = (date: Date | null) =>
    date
        ? `${date.getFullYear()}-${date.getMonth() + 1 < 10 ? 0 : ""}${
              date.getMonth() + 1
          }-${date.getDate() + 1 < 10 ? 0 : ""}${date.getDate()}`
        : "";

const dayHours = [
    "09:00",
    "09:30",
    "10:00",
    "10:30",
    "11:00",
    "11:30",
    "12:00",
    "12:30",
    "13:00",
    "13:30",
    "14:00",
    "14:30",
    "15:00",
    "15:30",
    "16:00",
    "16:30",
    "17:00",
];

export function ScheduleAction({ disable }: { disable: boolean }) {
    const { financialForm, setResult, ozoniosList, ozoner, vehicle} =
        useContext(ozonerContext);
    const [scheduledAt, setScheduledAt] = useState(formatDate(new Date()));
    const [scheduledTime, setScheduledTime] = useState("09:00");
    const [deliverOzonio, setDeliverOzonio] = useState("");
    const [openSchedule, setOpenSchedule] = useState(false);
    const [loading, setLoading] = useState(false);
    const [validDate, setValidDate] = useState(false);

    const agendarSolicitud = async () => {
        const dataSchedule: any = {
                        scheduledAt: `${scheduledAt.substring(
                0,
                10
            )}T${scheduledTime}:00-06:00`,
            deliverOzonio,
        };
        if (financialForm?.form) {
            if(financialForm?.form?.appointment){
                await cancelScheduleFinancialForm(ozoner._id,financialForm.form._id);
            }
            setLoading(true);
            scheduleFinancialForm(ozoner._id,financialForm.form._id,dataSchedule).then(()=>{
                return editSolicitud(financialForm.form._id, {status: formStatusEnum.scheduled});
            })
            .then(() => {
                    if (financialForm?.credit?._id) {
                        return Promise.resolve();
                    }
                    return createCredit(
                        ozoner._id,
                        financialForm.form._id,
                        financialForm.form.vehicle?._id
                    );
                })
                .then(() => {
                    setResult();
                    setOpenSchedule(false);
                    setLoading(false);
                    toasterNotification({
                        msg: "Solicitud Exitosa",
                        style: "light",
                        toasterType: toasterOptions.success,
                    });
                })
                .catch((err) => {
                    commonErrorHandler(err);
                    setResult();
                    setOpenSchedule(false);
                    setLoading(false);
                });
        }
    };

    useEffect(() => {
        const diference = moment().add("days",vehicle?.maintenanceDays?(vehicle.maintenanceDays+1):0).diff(moment(scheduledAt),"days");
            setValidDate(diference<=0);
    }, [vehicle,scheduledAt]);
    useEffect(() => {
        if (financialForm?.form?.appointment?.scheduledAt) {
            setScheduledAt(financialForm.form.appointment.scheduledAt);
        }
        setDeliverOzonio(
            financialForm?.form?.appointment?.deliverOzonio ??
                financialForm?.form?.sellResponsible
        );
    }, [financialForm]);
    return (
        <>
            <Button
                scale="small"
                color={financialForm?.credit?._id ? "secondary" : "primary"}
                onClick={() => setOpenSchedule(true)}
                disabled={disable}
            >
                {financialForm?.credit?._id ? "Reagendar" : "Agendar"} Entrega
            </Button>
            {openSchedule && (
                <ModalConfirmation
                    openConfirmation={openSchedule}
                    handleClose={() => setOpenSchedule(false)}
                    icon={<Reject className="text_primary_300" />}
                    widthDesktop={40}
                    isConfirmationLoading={false}
                    heightDesktop={80}
                    actions={[
                        {
                            label: "Volver",
                            color: "grey",
                            action: () => setOpenSchedule(false),
                        },
                        {
                            label: "Agendar entrega",
                            color: "primary",
                            action: () => agendarSolicitud(),
                            disabled:
                                !scheduledAt ||
                                !deliverOzonio ||
                                !validDate ||
                                deliverOzonio === "" ||
                                loading,
                        },
                    ]}
                >
                    <>
                        <Typography
                            scale="large"
                            weight="600"
                            className="m_b_xs"
                        >
                            Agendar entrega
                        </Typography>
                        <Typography
                            scale="medium"
                            weight="400"
                            className="m_b_md"
                            textColor="neutral_700"
                        >
                            Selecciona la fecha de entrega de la moto y el
                            ozonio que se encargara de la misma
                        </Typography>
                        <Input
                            title="Fecha de cita"
                            name="purchaseDate"
                            type="date"
                            className="m_b_xs w_100_per"
                            value={scheduledAt}
                            onChange={(e: any) => {
                                setScheduledAt(e.target.value);
                            }}
                            error={!validDate}
                            subtitle={validDate ? "" : `El vehiculo necesita al menos ${vehicle?.maintenanceDays} dias de mantenimiento, porfavor agende la cita para ${moment().add("days",vehicle?.maintenanceDays?(vehicle.maintenanceDays+1):0).format("YYYY-MM-DD")} o despues`}
                        />
                        <Input
                            title="Hora de la cita"
                            name="hour"
                            placeholder="Ingresa la hora"
                            value={scheduledTime}
                            onChange={(e: any) =>
                                setScheduledTime(e.target.value)
                            }
                            className="w_100_per"
                            type="select"
                            options={dayHours}
                            variant="default"
                        />
                        <Input
                            title="Responsable de la entrega"
                            name="Responsable de la entrega"
                            placeholder="Ingresa el Ozonio"
                            value={deliverOzonio}
                            onChange={(e: any) =>
                                setDeliverOzonio(e.target.value)
                            }
                            className="w_100_per"
                            type="select"
                            options={ozoniosList}
                            variant="default"
                        />
                        {financialForm?.credit?._id ? null : (
                            <Typography
                                scale="medium"
                                weight="400"
                                className="m_b_md"
                                textColor="red_300"
                            >
                                *Nota: Esta accion creara el contrato en espera
                                y no se podra modificar la solicitud. Para
                                volver a editar porfavor cancele el agendamiento
                                y vuelva al estado aprobado
                            </Typography>
                        )}
                    </>
                </ModalConfirmation>
            )}
        </>
    );
}
export function DevolutionAction() {
    const { financialForm, setResult } = useContext(ozonerContext);
    const [devolutionReason, setDevolutionReason] = useState<string>("");
    const [openDevolutionReason, setOpenDevolutionReason] =
        useState<boolean>(false);

    const returnSolicitud = () => {
        setOpenDevolutionReason(true);
        handleEventGoogle(
            "BTN_Pb_request_return",
            "Panda Solicitudes",
            "click in return request button"
        );
    };

    const updateDevolutionSolicitud = () => {
        const dataDevolution: any = {
            status: formStatusEnum.pending,
            devolutionReason,
        };
        if (financialForm?.form) {
            editSolicitud(financialForm.form._id, dataDevolution).then(() => {
                setResult();
                setOpenDevolutionReason(false);
            });
        }
    };
    return (
        <>
            <Button scale="small" color="light_grey" onClick={returnSolicitud}>
                Devolver
            </Button>
            {openDevolutionReason && (
                <Devolution
                    changeStatusDevolution={updateDevolutionSolicitud}
                    selected={devolutionReason}
                    setSelected={setDevolutionReason}
                    open={openDevolutionReason}
                    setOpen={setOpenDevolutionReason}
                />
            )}
        </>
    );
}

export function VerifyAction({ disabled }: { disabled: boolean }) {
    const { financialForm, setResult, credit } = useContext(ozonerContext);

    const [loading, setLoading] = useState(false);
    const [openVerify, setOpenVerify] = useState(false);

    const closeCancelReason = () => {
        setOpenVerify(false);
    };

    const verifyCredit = () => {
        if (financialForm?.credit?._id) {
            setLoading(true);
            editCredit(financialForm.credit._id, {
                verified: true,
            })
                .then((_) => {
                    setLoading(false);
                    setResult();
                })
                .catch((e) => {
                    setLoading(false);
                    toasterNotification({
                        msg: e ?? "Ocurrio un error",
                        toasterType: toasterOptions.error,
                        style: "light",
                    });
                });
        }
    };

    return (
        <>
            <Button
                scale="small"
                color="primary"
                onClick={() => setOpenVerify(true)}
                disabled={disabled}
            >
                Verificar credito
            </Button>
            {openVerify && (
                <ModalConfirmation
                    openConfirmation={openVerify}
                    handleClose={closeCancelReason}
                    icon={<Check className="text_green_300" />}
                    widthDesktop={40}
                    isConfirmationLoading={false}
                    actions={[
                        {
                            label: "Volver",
                            color: "grey",
                            action: closeCancelReason,
                        },
                        {
                            label: "Verificar credito",
                            color: "primary",
                            action: () => verifyCredit(),
                            disabled: loading,
                        },
                    ]}
                    title={
                        <Typography scale="large" weight="400">
                            ¿Estás seguro de que{" "}
                            <Typography
                                scale="large"
                                weight="600"
                                component="span"
                            >
                                deseas verificar este credito?
                            </Typography>
                        </Typography>
                    }
                    subtitle={
                        <Typography
                            scale="medium"
                            weight="400"
                            textColor="neutral_700"
                        >
                            Una vez quede verificado, se podra activar el
                            credito una vez se completen todos los documentos
                        </Typography>
                    }
                />
            )}
        </>
    );
}

export function UpdateQuota() {
    const { financialForm, setResult } = useContext(ozonerContext);
    const [openQuota, setOpenQuota] = useState(false);
    const [monthlyIncome, setMonthlyIncome] = useState(0);
    const [loading, setLoading] = useState(false);

    const [CTI, setCTI] = useState(25);

    useEffect(() => {
        setMonthlyIncome(financialForm?.form?.monthlyIncome ?? 0);
        if (financialForm?.form?.percentageMaxQuota) {
            setCTI(financialForm.form.percentageMaxQuota * 100);
        }
    }, [financialForm]);

    const closeQuota = () => {
        setOpenQuota(false);
        setMonthlyIncome(financialForm?.form?.monthlyIncome ?? 0);
        setCTI((financialForm?.form?.percentageMaxQuota ?? 0.25)*100);
    };

    const updateQuota = () => {
        const data: any = {
            monthlyIncome,
            percentageMaxQuota:CTI/100,
        };
        if (financialForm?.form) {
            setLoading(true);
            editSolicitud(financialForm.form._id, data).then(() => {
                setResult();
                setLoading(false);
                closeQuota();
            }).catch((err) => {
                commonErrorHandler(err);
                setLoading(false);
            });
        }
    };

    return (
        <>
            <Button
                scale="small"
                color="primary"
                variant="outline"
                onClick={() => setOpenQuota(true)}
            >
                Asignar cuota maxima
            </Button>
            {openQuota && (
                <ModalConfirmation
                    openConfirmation={openQuota}
                    handleClose={closeQuota}
                    icon={<Money className="text_primary_300" />}
                    isConfirmationLoading={false}
                    widthDesktop={40}
                    actions={[
                        {
                            label: "Cancelar",
                            color: "grey",
                            action: closeQuota,
                        },
                        {
                            label: "Asignar cuota",
                            color: "primary",
                            disabled: loading,
                            action: updateQuota,
                        },
                    ]}
                >
                    <div className="">
                        <Typography
                            scale="large"
                            weight="600"
                            className="m_b_md"
                        >
                            Asignar cuota maxima
                        </Typography>
                        <Input
                            type="number"
                            name="newPayment"
                            title="Ingresos Mensuales"
                            placeholder="0 OMX"
                            value={monthlyIncome}
                            icon={<Money />}
                            onChange={(e: any) => {
                                setMonthlyIncome(parseInt(e.target.value, 10));
                            }}
                            className="w_100_per"
                        />
                        <Input
                            className="w_100_per"
                            type="number"
                            icon={<Percentage className="status_arrows red" />}
                            name="newDurarion"
                            title="CTI"
                            placeholder="25%"
                            value={CTI}
                            onChange={(e: any) => {
                                setCTI(parseInt(e.target.value, 10));
                            }}
                        />
                        <div className="display_flex flex_align_center flex_justify_between flex_gap_md">
                            <div className="bg_neutral_0 shadow_soft flex_basis_0 flex_grow_1 p_xs">
                                <Typography
                                    scale="medium"
                                    weight="400"
                                    className="m_b_xs"
                                >
                                    Ingreso semanal
                                </Typography>
                                <Typography
                                    scale="large"
                                    weight="600"
                                    className=""
                                    textColor="green_300"
                                >
                                    {priceFormatFinancial(
                                        (monthlyIncome / 30) * 7
                                    )}
                                </Typography>
                            </div>
                            <div className="bg_neutral_0 shadow_soft flex_basis_0 flex_grow_1 p_xs">
                                <Typography
                                    scale="medium"
                                    weight="400"
                                    className="m_b_xs"
                                >
                                    Cuota maxima
                                </Typography>
                                <Typography
                                    scale="large"
                                    weight="600"
                                    className=""
                                    textColor="primary_300"
                                >
                                    {priceFormatFinancial(
                                        Math.ceil(
                                            (monthlyIncome * 7 * CTI) / 3000
                                        )
                                    )}
                                </Typography>
                            </div>
                        </div>
                    </div>
                </ModalConfirmation>
            )}
        </>
    );
}
