import { TCredit } from "./credit.interface";
import { TVehicle } from "./vehicle.interface";

// eslint-disable-next-line no-shadow
export enum RepossessionPolicy {
  event1 = "event1",
  event2 = "event2",
  event3 = "event3",
  devolution = "devolution",
  preWriteOff = "preWriteOff",
  writeOff = "writeOff",
  priority = "priority"
}

interface StatusHistoric {
  status: string;
  updatedAt: Date;
  by: any;
}

// eslint-disable-next-line no-shadow
export enum RepossessionRisk {
  high = "high",
  medium = "medium",
  low = "low",
}

// eslint-disable-next-line no-shadow
export enum RepossessionStatus {
  pending = "pending",
  running = "running",
  completed = "completed",
  failed = "failed",
  canceled = "canceled",
}
export const RepossessionPolicyValues = {
  priority: {
    title: "Prioritario",
    color: "primary_300"
  },
  event1: {
    title: "Mora 30",
    color: "green_300"
  },
  event2: {
    title: "Mora 60",
    color: "secondary_600"
  },
  event3: {
    title: "Fraude",
    color: "primary_500"
  },
  devolution:{
    title: "Devolución",
    color: "neutral_600"
  },
  preWriteOff: {
    title: "Pre-WriteOff",
    color: "red_300"
  },
  writeOff: {
    title: "WriteOff",
    color: "neutral_900"
  },
};

export interface RepossessionTotals {
  event1: number;
  event2: number;
  event3: number;
  preWriteOff: number;
  writeOff: number;
  count: number
}
// eslint-disable-next-line no-shadow
export enum InterestPointType {
  hotspot = "hotspot",
  signalVehicle = "signalVehicle",
  noSignalVehicle = "noSignalVehicle",
  address = "address",
}
// eslint-disable-next-line no-shadow
export enum LocationType {
  signalVehicle = "signalVehicle",
  noSignalVehicle = "noSignalVehicle",
  gpsTraker = "gpsTraker",
}

// eslint-disable-next-line no-shadow
export enum RecoveryStatus {
  pending = "pending",
  onWay = "onWay",
  inSite = "inSite",
  recovered = "recovered",
  failed = "failed",
  canceled = "canceled",
}

// eslint-disable-next-line no-shadow
export enum InterestPointStatus {
  active = "active",
  expired = "expired",
}

export const InterestPointTypeValues = {
  [InterestPointType.hotspot]: {
    title: "Hotspot",
    score:0
  },
  [InterestPointType.signalVehicle]: {
    title: "Ubicacion actual",
    score:3
  },
  [InterestPointType.noSignalVehicle]: {
    title: "Ultima ubicacíon",
    score:2
  },
  [InterestPointType.address]: {
    title: "Hogar",
    score:1
  },
};

// eslint-disable-next-line no-shadow
export enum RouteStatus {
  planning = "planning",
  inProgress = "inProgress",
  completed = "completed",
  canceled = "canceled",
}

export const RouteStatusValues: { [key in RouteStatus]: { title: string, color: string, textColor: string } } = {
  [RouteStatus.planning]: {
    title: "Planeada",
    color: "neutral_300",
    textColor: "neutral_800"
  },
  [RouteStatus.inProgress]: {
    title: "En Progreso",
    color: "neutral_300",
    textColor: "secondary_500"
  },
  [RouteStatus.completed]:{
    title:"Finalizada",
    color:"green_100",
    textColor:"green_300"
  },
  [RouteStatus.canceled]: {
    title: "Cancelada",
    color: "red_100",
    textColor: "red_300"
  },
};

export const RecoveryStatusValues: { [key in RecoveryStatus]: { title: string, color: string } } = {
  [RecoveryStatus.pending]: {
    title: "Por recuperar",
    color: "yellow_100"
  },
  [RecoveryStatus.onWay]: {
    title: "En proceso",
    color: "primary_300"
  },
  [RecoveryStatus.inSite]: {
    title: "En sitio",
    color: "primary_200"
  },
  [RecoveryStatus.failed]: {
    title: "No recuperada",
    color: "red_300"
  },
  [RecoveryStatus.recovered]: {
    title: "Recuperada",
    color: "green_300"
  },
  [RecoveryStatus.canceled]: {
    title: "Cancelada",
    color: "neutral_1000"
  },
};

interface Point {
  type: string;
  coordinates: [number, number];
}

export interface InterestPoint {
  isTrackGPS?: boolean;
  createdAt: string;
  _id: string;
  location: Point;
  type: InterestPointType;
  status: InterestPointStatus;
  description: string;
  visits: number;
}
export interface InterestPointCreate {
  coordinates: [number, number];
  type: InterestPointType;
  status: InterestPointStatus;
  description?: string;
}
export interface Repossession {
  vehicle: TVehicle;
  vehicleId: string;
  policy: RepossessionPolicy;
  tries: number;
  location: string;
  status: RepossessionStatus;
  totalDebt: number;
  totalCredit: number;
  currentDebt: number;
  latePayments: number;
  lastDatePaid: string;
  ozonerName: string;
  ozoner: string;
  risk: RepossessionRisk;
  riskPercentage: number;
  paidPayments: number;
  statusHistoric: StatusHistoric[];
  credit: TCredit;
  interestPoint: InterestPoint[];
  createdAt: string;
  updatedAt: string;
  gps?: any;
  _id: string;
}


export interface FetchRepossessionsResponse {
  count: number;
  data: Repossession[];
  page: number;
  pages: number;
  totals: RepossessionTotals;
}


export interface RecoveryRoute {
  idRepossession: string;
  repossession: Repossession;
  idInterestPoint: string;
  interestPoint: InterestPoint
  index: number;
}
export interface RecoveryRouteCreate {
  idRepossession: string;
  idInterestPoint: string;
  index: number;
}

export interface Recovery {

  _id: string,
  createdAt: string,
  index: number,
  interestPoint: InterestPoint,
  repossession: string,
  route: string,
  status: RecoveryStatus,
  statusHistoric: any[],
  teamLeader: string,
  updatedAt: string,
  visits: number
}
export interface RecoveryPopulated {
  _id: string,
  createdAt: string,
  index: number,
  interestPoint: InterestPoint,
  repossession: Repossession,
  route: string,
  status: RecoveryStatus,
  statusHistoric: any[],
  teamLeader: string,
  updatedAt: string,
  visits: number,
  canChangeStatus?:boolean
}
export interface Route {
  dateEstimate: string;
  recoveries: Recovery[];
  repossessions: Repossession[];
  status: RouteStatus;
  statusHistoric: any[];
  teamLeader: any;
  week: string;
  totals?: any;
  _id: string;
  __v: number;
}
export interface RouteEdit {
  teamLeader?: string;
  recoveries?: RecoveryRouteCreate[];
  dateEstimate?: string;
  week?: string;
  status?: string;
}

export interface FetchRoutesResponse {
  count: number;
  data: Route[];
  page: number;
  pages: number;
}
export interface FetchRouteResponse {
  count: number;
  data: Route[];
  page: number;
  pages: number;
}
export interface FetchRecoveryResponse {
  count: number;
  data: RecoveryPopulated[];
  page: number;
  pages: number;
}



