/* eslint-disable no-nested-ternary */
import React, { useContext } from "react";

// -----------------LIBRARIES IMPORTS------------------- //
// @ts-ignore
import { Button } from "design_system/src";

// -----------------DATA IMPORTS------------------- //
import { formStatusEnum, rejectedReasonEnum, rejectedReasonValues } from "models/financialForm.interface";
import moment from "moment";

import { handleEventGoogle } from "helpers/analytics/googleAnalytics";

// -----------------STATIC IMPORTS------------------- //
import "../../../ozoner.scss";
import { LightTooltip } from "components/hocs/tooltip/Tooltip";
import { ozonerContext } from "../../OzonerContext";
import { informativeMessagge } from "./SolicitudOzoner";

function RejectedSection() {
    const { updateStatusSolicitud, financialForm } = useContext(ozonerContext);

    const reviveSolicitud = () => {
        updateStatusSolicitud(formStatusEnum.pending, null);
        handleEventGoogle(
            "BTN_Pb_request_revive",
            "Panda Solicitudes",
            "click in revive request button"
        );
    };

    return (
        <>
            {informativeMessagge(
                    "Solicitud rechazada por:",
                    rejectedReasonValues[
                        financialForm.form.rejectedReason as rejectedReasonEnum
                    ] ?? "--",
                    "red_300",
                    "red_200"
                )}
            <div
                id="validate-soli-ozoner"
                className="p_md border_b_solid border_1 border_neutral_400 display_flex flex_col"
            >
                <LightTooltip title={financialForm?.form?.updatedAt && !moment(financialForm.form.updatedAt).isBefore(moment().subtract(3, "month"))?"":"La solicitud tiene mas de 3 meses, no es posible restaurarla"}>
                    <div className="w_100_per">

                    <Button
                        scale="small"
                        color="primary"
                        onClick={reviveSolicitud}
                        className="w_100_per"
                        disabled={financialForm?.form?.updatedAt?moment(financialForm.form.updatedAt).isBefore(moment().subtract(3, "month")):true}
                    >
                        Restablecer solicitud
                    </Button>
                    </div>
                </LightTooltip>
            </div>
        </>
    );
}
export default RejectedSection;
