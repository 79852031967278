/* eslint-disable dot-notation */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import {
    Form,
    Formik,
    FormikHelpers,
    FormikProps,
    FormikProvider,
    FormikValues,
    useFormik,
} from "formik";
import * as yup from "yup";
import Swal from "sweetalert2";

import {
    addFile,
    addOzoner,
    deleteOzoner,
    getOzonerByCurp,
} from "helpers/fetchs/ozoners";

// icons
import { ReactComponent as Profile } from "design_system/src/static/icons/stars-profile.svg";

import { ReactComponent as Close } from "design_system/src/static/icons/close-circle.svg";
import { ReactComponent as User } from "design_system/src/static/icons/add-profile.svg";
import { ReactComponent as Phone } from "design_system/src/static/icons/phone.svg";
import { ReactComponent as Mail } from "design_system/src/static/icons/mail.svg";
import { ReactComponent as Email } from "design_system/src/static/icons/email.svg";
import { ReactComponent as Map } from "design_system/src/static/icons/map.svg";
import { ReactComponent as ID } from "design_system/src/static/icons/user-card-2.svg";
import { ReactComponent as Calendar } from "design_system/src/static/icons/date.svg";
import { ReactComponent as Check } from "design_system/src/static/icons/checkmark.svg";
import { ReactComponent as Moto } from "design_system/src/static/icons/motorcycle.svg";
import { ReactComponent as Baby } from "design_system/src/static/icons/baby.svg";
import { ReactComponent as Working } from "design_system/src/static/icons/working-bag-3.svg";
// import { ReactComponent as Save } from "design_system/src/static/icons/save.svg";
import { ReactComponent as Groups } from "design_system/src/static/icons/groups.svg";
import { ReactComponent as Ruler } from "design_system/src/static/icons/ruler-pencil.svg";
import { ReactComponent as Love } from "design_system/src/static/icons/couple-love.svg";
import { ReactComponent as Files } from "design_system/src/static/icons/documents.svg";
import { ReactComponent as GPS } from "design_system/src/static/icons/gps.svg";
import { ReactComponent as Up } from "design_system/src/static/icons/success-plan.svg";
import { ReactComponent as Down } from "design_system/src/static/icons/failed-plan.svg";

import Divider from "@mui/material/Divider";
import {
    Button,
    Input,
    SideModal,
    Typography,
} from "design_system/src";

// css
import "../ozoner.scss";
import Inventory from "views/inventory/Inventory";
import { getCities } from "helpers/fetchs/inventory";
import { TDataozoner, TEditDataozoner } from "models/ozoner.interface";
import { useNavigate } from "react-router-dom";
import { createSolicitud, editSolicitud } from "helpers/fetchs/solicitudes";
import { fetchByPostalCode } from "helpers/fetchs/copomex";
import { TVehicle } from "models/vehicle.interface";
import LoadDocuments, {
    DocumentTypesEnum,
} from "components/hocs/loadDocuments/loadDocuments";
import { TDataFinancialForm, economicActivityEnum } from "models/financialForm.interface";
import { sanitizeName } from "../../../helpers/form/sanitazeName";
import { toasterNotification } from "../../../helpers/globalNotifications/globalNotifications";
import { toasterOptions } from "../../../enums/toaster.enum";
import LocationModalEdit from "./LocationModalEdit";

const curpRegex =
    /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;
const ozonerVS = yup.object({
    name: yup.string().required("Campo requerido"),
    phone: yup.number().required("Campo requerido"),
    email: yup.string().email("Debe ser email").required("Campo requerido"),
    curp: yup
        .string()
        .required("Campo requerido")
        .matches(curpRegex, "CURP inválido"),
    city: yup.string().required("Campo requerido"),
});
const addressVS = yup.object({
    cp: yup
        .string()
        .required("Campo requerido")
        .matches(/^\d{5}$/, "Debe ser un número de 5 dígitos.")
        .test("cp", "El codigo postal no existe", function test(value) {
            const { state } = this.parent;
            if (value && value.length === 5 && !state) {
                return this.createError({
                    message: "El codigo postal no existe",
                });
            }
            return true;
        })
        .required("El código es requerido"),
    state: yup.string().required("Campo requerido"),
    delegation: yup.string().required("Campo requerido"),
    neighborhood: yup.string().required("Campo requerido"),
    street: yup.string().required("Campo requerido"),
    extNumber: yup.string().required("Campo requerido"),
    intNumber: yup.string(),
});
const referencesVS = yup.object({
    ref1name: yup.string().required("Campo requerido"),
    ref1phone: yup.number().required("Campo requerido"),
    ref1relation: yup.string().required("Campo requerido"),
    ref1other: yup.string().when("ref1relation", {
        is: (value: string) => value === "Otro",
        then: yup.string().required("Campo requerido"),
        otherwise: yup.string().optional(),
    }),
    ref2name: yup.string().required("Campo requerido"),
    ref2phone: yup.number().required("Campo requerido"),
    ref2relation: yup.string().required("Campo requerido"),
    ref2other: yup.string().when("ref2relation", {
        is: (value: string) => value === "Otro",
        then: yup.string().required("Campo requerido"),
        otherwise: yup.string().optional(),
    }),
});
const financialVS = yup.object({
    monthlyIncome: yup.number().required("Campo requerido"),
    monthlySpending: yup.number().required("Campo requerido"),
    childrenCount: yup.number().required("Campo requerido"),
    dependantsCount: yup.number().required("Campo requerido"),
    companyName: yup.string().nullable(),
    economicActivity: yup.string().required("Campo requerido"),
    economicActivityOther: yup.string().when("economicActivity", {
        is: (value: string) => value === economicActivityEnum.otro,
        then: yup.string().required("Campo requerido"),
        otherwise: yup.string().optional(),
    }),
    civilStatus: yup.string().required("Campo requerido"),
    creditTime: yup.number().nullable(),
    educationalLevel: yup.string().required("Campo requerido"),
    livesWith: yup.array().min(1, "Campo Requerido"),
});
const formatDate = (date: Date | null) =>
    date
        ? `${date.getFullYear()}-${date.getMonth() + 1 < 10 ? 0 : ""}${
              date.getMonth() + 1
          }-${date.getDate() + 1 < 10 ? 0 : ""}${date.getDate()}`
        : "";

const ozonerD = {
    city: "",
    name: "",
    phone: "",
    email: "",
    curp: "",
};

const addressD = {
    cp: "",
    state: "",
    delegation: "",
    neighborhood: "",
    street: "",
    extNumber: "",
    intNumber: "",
};

const referencesD = {
    ref1name: "",
    ref1phone: "",
    ref1relation: "",
    ref1other: "",
    ref2name: "",
    ref2phone: "",
    ref2relation: "",
    ref2other: "",
};

const financialD = {
    monthlyIncome: "",
    monthlySpending: "",
    childrenCount: 0,
    dependantsCount: 0,
    companyName: "",
    economicActivity: "",
    economicActivityOther: "",
    civilStatus: "",
    creditTime: "",
    educationalLevel: "",
    livesWith: [],
    workInDigitalPlatforms: false,
    assets: [],
};

interface EditProps {
    open: boolean;
    setOpen: Function;
    setResult: Function;
    ozoner?: TDataozoner | null;
    form?: TDataFinancialForm | null;
    label?: string;
    sections?: boolean[];
    hasCredit?: boolean;
}

const education = [
    "Ninguna",
    "Primaria",
    "Secundaria",
    "Preparatoria",
    "Tecnicatura",
    "Licenciatura",
    "Maestria",
];

const children = [
    { label: "0", value: 0 },
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
    { label: "5 o más", value: 5 },
];
const dependant = [
    { label: "0", value: 0 },
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
    { label: "5 o más", value: 5 },
];
const relationOptions = [
    { label: "Familiar", value: "Familiar" },
    { label: "Pareja", value: "Pareja" },
    { label: "Amigo/a", value: "Amigo/a" },
    { label: "Compañero de trabajo", value: "Compañero de trabajo" },
    { label: "Otro", value: "Otro" },
];

const livesWithOptions = [
    { label: "Solo (a)", value: "Solo(a)" },
    { label: "Esposo (a)", value: "Esposo(a)" },
    { label: "Amigos", value: "Amigos" },
    { label: "Padres", value: "Padres" },
    { label: "Pareja estable", value: "Pareja estable" },
    { label: "Hermanos", value: "Hermanos" },
    { label: "Hijos", value: "Hijos" },
];

const civil = ["Solter@", "Union Libre", "Casad@", "Viud@"];
const activos = [
    { label: "Carro", value: "carro" },
    { label: "Casa", value: "casa" },
    { label: "Moto", value: "moto" },
    { label: "Otros", value: "otro" },
];

const formatNumber = (number: string) => {
    if (!number.startsWith("521")) {
        return `521${number}`;
    }
    return number;
};
function EditOzoner({
    open,
    setOpen,
    ozoner,
    form,
    setResult,
    label = "Crear nueva Solicitud",
    sections = [true, true, true, true],
    hasCredit = false,
}: EditProps) {
    const navigate = useNavigate();

    const [openLocation, setOpenLocation] = useState(false);
    const [ozonerI, setOzonerI] = useState<any>(ozonerD);
    const [addressI, setAddressI] = useState<any>(addressD);
    const [referencesI, setReferencesI] = useState<any>(referencesD);
    const [financialI, setFinancialI] = useState<any>(financialD);

    const [vehicle, setVehicle] = useState<TVehicle>();
    const [fullOpen, setFullOpen] = useState(false);
    const [curpValidation, setCurpValidation] = useState(false);

    const [loading, setLoading] = useState<boolean>(false);
    const [loadingValidation, setLoadingValidation] = useState<boolean>(false);
    const [showErrors, setShowErrors] = useState<boolean>(false);
    const [showRef1OtherField, setShowRef1OtherField] = useState(false);
    const [showRef2OtherField, setShowRef2OtherField] = useState(false);
    const [neighborhoodOptions, setNeighborhoodOptions] = useState([]);
    const [loadingCP, setloadingCP] = useState(false);

    const [location, setLocation] = useState<any>();

    const [cities, setCities] = useState<any[]>([]);

    const ozonerF = useFormik({
        initialValues: ozonerI,
        validateOnMount: true,
        enableReinitialize: true,
        validationSchema: ozonerVS,
        onSubmit: () => {},
    });
    const addressF = useFormik({
        initialValues: addressI,
        validateOnMount: true,
        enableReinitialize: true,
        validationSchema: addressVS,
        onSubmit: () => {},
    });
    const referencesF = useFormik({
        initialValues: referencesI,
        validateOnMount: true,
        enableReinitialize: true,
        validationSchema: referencesVS,
        onSubmit: () => {},
    });
    const financialF = useFormik({
        initialValues: financialI,
        validateOnMount: true,
        enableReinitialize: true,
        validationSchema: financialVS,
        onSubmit: () => {},
    });

    useEffect(() => {
        if (ozoner) {
            const { name, phone, email, curp, city } = ozoner;
            setOzonerI({ name, phone, email, curp, city });
            if (ozoner.fullAddress) {
                const {
                    cp,
                    state,
                    delegation,
                    neighborhood,
                    street,
                    intNumber,
                    extNumber,
                    lat,
                    long
                } = ozoner.fullAddress;
                setAddressI({
                    cp,
                    state,
                    delegation,
                    neighborhood,
                    street,
                    intNumber,
                    extNumber,
                });
                if(lat && long){
                    setLocation({
                        latitude: lat,
                        longitude: long,
                    });
                }
            }
        }
        if (form) {
            setReferencesI({
                ref1name: form.personal_references?.personal_reference_1?.name,
                ref1phone:
                    form.personal_references?.personal_reference_1?.phone,
                ref1relation:
                    form.personal_references?.personal_reference_1?.relation,
                ref1other:
                    form.personal_references?.personal_reference_1?.other,
                ref2name: form.personal_references?.personal_reference_2?.name,
                ref2phone:
                    form.personal_references?.personal_reference_2?.phone,
                ref2relation:
                    form.personal_references?.personal_reference_2?.relation,
                ref2other:
                    form.personal_references?.personal_reference_2?.other,
            });
            const {
                monthlyIncome,
                monthlySpending,
                childrenCount,
                dependantsCount,
                companyName,
                economicActivity,
                civilStatus,
                educationalLevel,
                livesWith,
                workInDigitalPlatforms,
            } = form;
            let assets = form.assets?.assets || [];
            if (!Array.isArray(assets)) assets = [assets];
            if (assets?.other) {
                assets = [...assets, "otro"];
            }
            setFinancialI({
                monthlyIncome,
                monthlySpending,
                childrenCount,
                dependantsCount,
                companyName,
                economicActivity:economicActivity?.value,
                economicActivityOther:economicActivity?.description,
                civilStatus,
                educationalLevel,
                livesWith,
                workInDigitalPlatforms,
                assets,
                otherAssets: assets?.other || "",
            });
            if (
                form.personal_references?.personal_reference_1?.relation ===
                "Otro"
            ) {
                setShowRef1OtherField(true);
            }
            if (
                form.personal_references?.personal_reference_2?.relation ===
                "Otro"
            ) {
                setShowRef2OtherField(true);
            }
        }

        if (!ozoner && !form) {
            setCurpValidation(true);
        }
    }, [ozoner, form]);

    useEffect(() => {
        financialF.setFieldValue("creditTime", "");
    }, [vehicle]);

    useEffect(() => {
        getCities().then((citiesR) => {
            if (citiesR) {
                setCities(
                    citiesR.map((c) => ({ label: c.name, value: c._id }))
                );
            }
        });
    }, []);

    const clearForm = () => {
        setVehicle(undefined);
        setOpen(false);
        setOzonerI(ozonerD);
        setAddressI(addressD);
        setReferencesI(referencesD);
        setFinancialI(financialD);
        ozonerF.resetForm();
        addressF.resetForm();
        referencesF.resetForm();
        financialF.resetForm();
        setLoading(false);
    };

    const onSubmit = () => {
        setLoading(true);
        if (vehicle && !form?._id && !financialF.values.creditTime) {
            setLoading(false);
            financialF.setFieldError("creditTime", "Campo requerido");
            return;
        }
        if (
            (ozonerF.isValid || !sections[0]) &&
            ((addressF.isValid && !!location) || !sections[1]) &&
            (referencesF.isValid || !sections[2]) &&
            (financialF.isValid || !sections[3])
        ) {
            let ozonerData = {};
            let formData: any = {};
            if (sections[0]) {
                ozonerData = { ...ozonerData, ...ozonerF.values };
            }
            if (sections[1]) {
                ozonerData = { ...ozonerData, ...addressF.values, ...location };
            }
            if (sections[2]) {
                formData = {
                    ...formData,
                    personal_references: {
                        personal_reference_1: {
                            name: sanitizeName(referencesF.values.ref1name),
                            phone: formatNumber(
                                `${referencesF.values.ref1phone}`
                            ),
                            relation: referencesF.values.ref1relation,
                            other:
                                referencesF.values.ref1relation === "Otro"
                                    ? referencesF.values.ref1other
                                    : "",
                        },
                        personal_reference_2: {
                            name: sanitizeName(referencesF.values.ref2name),
                            phone: formatNumber(
                                `${referencesF.values.ref2phone}`
                            ),
                            relation: referencesF.values.ref2relation,
                            other:
                                referencesF.values.ref2relation === "Otro"
                                    ? referencesF.values.ref2other
                                    : "",
                        },
                    },
                };
            }
            if (sections[3]) {
                const asset = financialF.values.assets ?? [];
                const assets: any = {
                    assets: asset.filter((ele: string) => ele !== "otro"),
                    other: financialF.values.otherAssets,
                    joinedAssets: `${asset
                        .filter((ele: string) => ele !== "otro")
                        .reduce(
                            (acc: string, val: string) =>
                                `${acc ? `${acc},` : ""}${val}`,
                            ""
                        )}${
                        financialF.values.otherAssets
                            ? `, ${financialF.values.otherAssets}`
                            : ""
                    }`,
                };
                formData = {
                    ...formData,
                    ...financialF.values,
                    assets,
                };
                if (!form?._id) {
                    formData.vehicleId = vehicle?.internalId;
                }
                if(ozoner?._id){
                    formData.economicActivity = {
                        value : financialF.values.economicActivity,
                        other : financialF.values.economicActivity===economicActivityEnum.otro?financialF.values.economicActivityOther:""
                    };
                }
                delete formData.otherAssets;
                if (!formData.creditTime || formData.creditTime === "") {
                    delete formData.creditTime;
                }
            }

            const p = [];
            if (!ozoner?._id) {
                p.push(
                    addOzoner(
                        {
                            ...ozonerData,
                        } as TEditDataozoner,
                        formData
                    )
                );
            } else if (!form?._id) {
                p.push(createSolicitud(ozoner._id, formData));
                p.push(
                    addOzoner(ozonerData as TEditDataozoner, null, ozoner._id)
                );
            } else {
                if (Object.keys(ozonerData).length) {
                    p.push(
                        addOzoner(
                            ozonerData as TEditDataozoner,
                            null,
                            ozoner._id
                        )
                    );
                }
                if (Object.keys(formData).length) {
                    p.push(editSolicitud(form._id, formData));
                }
            }
            Promise.all(p)
                .then(() => {
                    setResult();
                    clearForm();
                    toasterNotification({
                        msg: "Se actualizó el Ozoner Correctamente",
                        toasterType: toasterOptions.success,
                        style: "light",
                    });
                })
                .catch((err) => {
                    toasterNotification({
                        // eslint-disable-next-line no-nested-ternary
                        msg: err
                            ? Array.isArray(err)
                                ? err[0]
                                : err
                            : "Ocurrio un error",
                        toasterType: toasterOptions.error,
                        style: "light",
                    });
                    setLoading(false);
                });
        } else {
            setLoading(false);
            setShowErrors(true);
            toasterNotification({
                msg: "El formulario esta incompleto",
                style: "light",
                toasterType: toasterOptions.error,
              });
        }
    };

    const handleRelationChange = (e: any) => {
        if (e.target.value === "Otro") setShowRef1OtherField(true);
        else setShowRef1OtherField(false);
        referencesF.handleChange(e);
    };

    const handleRelation2Change = (e: any) => {
        if (e.target.value === "Otro") setShowRef2OtherField(true);
        else setShowRef2OtherField(false);
        referencesF.handleChange(e);
    };

    const clearPostalCode = () => {
        addressF.setFieldValue("state", "");
        addressF.setFieldValue("delegation", "");
        addressF.setFieldValue("neighborhood", "");
        setNeighborhoodOptions([]);
        addressF.setFieldTouched("cp", true, true);
    };

    const updatePostalCode = async (value: string) => {
        if (value.length === 5) {
            setloadingCP(true);
            fetchByPostalCode(value)
                .then((response: any) => {
                    setloadingCP(false);
                    const { data } = response;
                    if (!data.error) {
                        addressF.setFieldValue("state", data.response.estado);
                        addressF.setFieldValue(
                            "delegation",
                            data.response.municipio
                        );
                        addressF.setFieldValue("neighborhood", "");
                        setNeighborhoodOptions(data.response.asentamiento);
                        addressF.setFieldTouched("cp", true, true);
                    }
                })
                .catch((e) => {
                    setloadingCP(false);
                    clearPostalCode();
                });
        } else {
            clearPostalCode();
        }
    };

    const validateCURP = () => {
        if (ozonerF.values.curp) {
            setLoadingValidation(true);
            getOzonerByCurp(ozonerF.values.curp)
                .then((ozonerCurp) => {
                    setLoadingValidation(false);
                    if (ozonerCurp) {
                        navigate(`/ozoner/${ozonerCurp._id}?openEdit=true`);
                    } else {
                        setCurpValidation(false);
                    }
                })
                .catch((e) => {
                    setLoadingValidation(false);
                    toasterNotification({
                        msg: e ?? "Ocurrio un error",
                        toasterType: toasterOptions.error,
                        style: "light",
                    });
                });
        }
    };

    return (
        <>
            <SideModal
                open={open}
                setOpen={setOpen}
                fullOpen={fullOpen}
                setFullOpen={setFullOpen}
                size="normal"
            >
                <div className="flex_content_col">
                    <div className="flex_header">
                        <Typography
                            scale="heading4"
                            weight="600"
                            textColor="neutral_900"
                            className="m_b_lg m_t_md"
                        >
                            {label}
                        </Typography>
                        <Divider />
                    </div>
                    {curpValidation ? (
                        <>
                            <form>
                                <Input
                                    onBlur={ozonerF.handleBlur}
                                    error={
                                        !!ozonerF.errors["curp"] &&
                                        (ozonerF.getFieldMeta("curp").touched ||
                                            showErrors)
                                    }
                                    subtitle={
                                        // eslint-disable-next-line no-nested-ternary
                                        hasCredit
                                            ? "No se puede editar el CURP de un ozoner con crédito"
                                            : ozonerF.getFieldMeta("curp")
                                                  .touched || showErrors
                                            ? (ozonerF.errors["curp"] as string)
                                            : undefined
                                    }
                                    title="CURP*"
                                    placeholder="Escribe el CURP aquí"
                                    name="curp"
                                    type="text"
                                    icon={<ID />}
                                    className="m_y_xs w_100_per"
                                    value={ozonerF.values.curp}
                                    onChange={ozonerF.handleChange}
                                    disabled={hasCredit}
                                />
                            </form>
                            <Button
                                scale="small"
                                orientation="right"
                                className="w_100_per"
                                onClick={() => {
                                    validateCURP();
                                }}
                                disabled={
                                    loadingValidation ||
                                    ozonerF.values["curp"] === "" ||
                                    !!ozonerF.errors["curp"]
                                }
                            >
                                Verificar Curp
                            </Button>
                        </>
                    ) : (
                        <>
                            <div className="flex_body p_y_lg p_x_sm overflow_x_hidden">
                                <form>
                                    {sections[0] ? (
                                        <>
                                            <Typography
                                                scale="heading4"
                                                weight="600"
                                                textColor="neutral_700"
                                                className="m_b_xl"
                                            >
                                                Información personal
                                            </Typography>
                                            <Grid
                                                container
                                                direction="row"
                                                rowSpacing={0}
                                                columnSpacing={6}
                                                justifyContent="center"
                                                className=""
                                            >
                                                <Grid item xs={6}>
                                                    <Input
                                                        title="Ciudad*"
                                                        placeholder="Selecciona ciudad"
                                                        name="city"
                                                        type="select"
                                                        icon={<Profile />}
                                                        className="m_b_xs w_100_per"
                                                        value={
                                                            ozonerF.values.city
                                                        }
                                                        onChange={
                                                            ozonerF.handleChange
                                                        }
                                                        onBlur={
                                                            ozonerF.handleBlur
                                                        }
                                                        options={cities}
                                                        error={
                                                            !!ozonerF.errors[
                                                                "city"
                                                            ] &&
                                                            (ozonerF.getFieldMeta(
                                                                "city"
                                                            ).touched ||
                                                                showErrors)
                                                        }
                                                        subtitle={
                                                            ozonerF.getFieldMeta(
                                                                "city"
                                                            ).touched ||
                                                            showErrors
                                                                ? (ozonerF
                                                                      .errors[
                                                                      "city"
                                                                  ] as string)
                                                                : undefined
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Input
                                                        title="Nombre*"
                                                        placeholder="Escribe el nombre aquí"
                                                        name="name"
                                                        type="text"
                                                        icon={<Profile />}
                                                        className="m_b_xs w_100_per"
                                                        value={
                                                            ozonerF.values.name
                                                        }
                                                        onChange={
                                                            ozonerF.handleChange
                                                        }
                                                        onBlur={
                                                            ozonerF.handleBlur
                                                        }
                                                        error={
                                                            !!ozonerF.errors[
                                                                "name"
                                                            ] &&
                                                            (ozonerF.getFieldMeta(
                                                                "name"
                                                            ).touched ||
                                                                showErrors)
                                                        }
                                                        subtitle={
                                                            ozonerF.getFieldMeta(
                                                                "name"
                                                            ).touched ||
                                                            showErrors
                                                                ? (ozonerF
                                                                      .errors[
                                                                      "name"
                                                                  ] as string)
                                                                : undefined
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Input
                                                        onBlur={
                                                            ozonerF.handleBlur
                                                        }
                                                        error={
                                                            !!ozonerF.errors[
                                                                "email"
                                                            ] &&
                                                            (ozonerF.getFieldMeta(
                                                                "email"
                                                            ).touched ||
                                                                showErrors)
                                                        }
                                                        subtitle={
                                                            ozonerF.getFieldMeta(
                                                                "email"
                                                            ).touched ||
                                                            showErrors
                                                                ? (ozonerF
                                                                      .errors[
                                                                      "email"
                                                                  ] as string)
                                                                : undefined
                                                        }
                                                        title="Correo*"
                                                        placeholder="Escribe el correo aquí"
                                                        name="email"
                                                        type="email"
                                                        icon={<Email />}
                                                        className="m_b_xs w_100_per"
                                                        value={
                                                            ozonerF.values.email
                                                        }
                                                        onChange={
                                                            ozonerF.handleChange
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Input
                                                        onBlur={
                                                            ozonerF.handleBlur
                                                        }
                                                        error={
                                                            !!ozonerF.errors[
                                                                "phone"
                                                            ] &&
                                                            (ozonerF.getFieldMeta(
                                                                "phone"
                                                            ).touched ||
                                                                showErrors)
                                                        }
                                                        subtitle={
                                                            ozonerF.getFieldMeta(
                                                                "phone"
                                                            ).touched ||
                                                            showErrors
                                                                ? (ozonerF
                                                                      .errors[
                                                                      "phone"
                                                                  ] as string)
                                                                : undefined
                                                        }
                                                        title="Teléfono*"
                                                        placeholder="Escribe el teléfono aquí"
                                                        name="phone"
                                                        type="number"
                                                        icon={<Phone />}
                                                        className="m_b_xs w_100_per"
                                                        value={
                                                            ozonerF.values.phone
                                                        }
                                                        onChange={
                                                            ozonerF.handleChange
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Input
                                                        onBlur={
                                                            ozonerF.handleBlur
                                                        }
                                                        error={
                                                            !!ozonerF.errors[
                                                                "curp"
                                                            ] &&
                                                            (ozonerF.getFieldMeta(
                                                                "curp"
                                                            ).touched ||
                                                                showErrors)
                                                        }
                                                        subtitle={
                                                            // eslint-disable-next-line no-nested-ternary
                                                            hasCredit
                                                                ? "No se puede editar el CURP de un ozoner con crédito"
                                                                : ozonerF.getFieldMeta(
                                                                      "curp"
                                                                  ).touched ||
                                                                  showErrors
                                                                ? (ozonerF
                                                                      .errors[
                                                                      "curp"
                                                                  ] as string)
                                                                : undefined
                                                        }
                                                        title="CURP*"
                                                        placeholder="Escribe el CURP aquí"
                                                        name="curp"
                                                        type="text"
                                                        icon={<ID />}
                                                        className="m_b_xs w_100_per"
                                                        value={
                                                            ozonerF.values.curp
                                                        }
                                                        onChange={
                                                            ozonerF.handleChange
                                                        }
                                                        disabled={hasCredit}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </>
                                    ) : null}
                                    {sections[1] ? (
                                        <>
                                            {sections[0] ? (
                                                <Divider variant="middle" />
                                            ) : null}
                                            <Typography
                                                scale="heading4"
                                                weight="600"
                                                textColor="neutral_700"
                                                className="m_y_xl"
                                            >
                                                Dirección
                                            </Typography>
                                            <Grid
                                                container
                                                direction="row"
                                                rowSpacing={0}
                                                columnSpacing={6}
                                                justifyContent="center"
                                                className=""
                                            >
                                                {ozoner?.address ? (
                                                    <Grid item xs={12}>
                                                        <div className="bg_neutral_200 w_100_per m_b_xs border_1 border_neutral_400 border_solid p_x_md p_y_xs">
                                                            <Typography
                                                                scale="medium"
                                                                weight="400"
                                                                textColor="neutral_800"
                                                            >
                                                                {ozoner.address}
                                                            </Typography>
                                                        </div>
                                                        <Typography
                                                            scale="xsmall"
                                                            weight="400"
                                                            textColor="neutral_600"
                                                            className="m_b_xs"
                                                        >
                                                            *Esta es la
                                                            direccion
                                                            previamente
                                                            registrada
                                                        </Typography>
                                                    </Grid>
                                                ) : null}
                                                <Grid item xs={12}>
                                                    <Input
                                                        onBlur={(e) => {
                                                            addressF.handleBlur(
                                                                e
                                                            );
                                                        }}
                                                        error={
                                                            !!addressF.errors
                                                                .cp &&
                                                            (addressF.getFieldMeta(
                                                                "cp"
                                                            ).touched ||
                                                                showErrors)
                                                        }
                                                        subtitle={
                                                            addressF.getFieldMeta(
                                                                "cp"
                                                            ).touched ||
                                                            showErrors
                                                                ? (addressF
                                                                      .errors
                                                                      .cp as string)
                                                                : undefined
                                                        }
                                                        title="Código postal*"
                                                        name="cp"
                                                        type="text"
                                                        className="m_b_xs w_100_per"
                                                        icon={<Mail />}
                                                        placeholder="Escribe el código postal"
                                                        value={
                                                            addressF.values.cp
                                                        }
                                                        onChange={(e: any) => {
                                                            addressF.handleChange(
                                                                e
                                                            );
                                                            updatePostalCode(
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                    {loadingCP ? (
                                                        <Typography
                                                            scale="large"
                                                            weight="600"
                                                            textColor="neutral_700"
                                                            className="m_y_sm text_center"
                                                        >
                                                            Cargando...
                                                        </Typography>
                                                    ) : null}
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Input
                                                        onBlur={
                                                            addressF.handleBlur
                                                        }
                                                        error={
                                                            !!addressF.errors
                                                                .state &&
                                                            (addressF.getFieldMeta(
                                                                "state"
                                                            ).touched ||
                                                                showErrors)
                                                        }
                                                        subtitle={
                                                            addressF.getFieldMeta(
                                                                "state"
                                                            ).touched ||
                                                            showErrors
                                                                ? (addressF
                                                                      .errors
                                                                      .state as string)
                                                                : undefined
                                                        }
                                                        title="Estado*"
                                                        name="state"
                                                        icon={<GPS />}
                                                        disabled
                                                        placeholder="Selecciona el estado"
                                                        type="text"
                                                        className="m_b_xs w_100_per"
                                                        value={
                                                            addressF.values
                                                                .state
                                                        }
                                                        onChange={(e: any) => {
                                                            addressF.handleChange(
                                                                e
                                                            );
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Input
                                                        onBlur={
                                                            addressF.handleBlur
                                                        }
                                                        error={
                                                            !!addressF.errors
                                                                .delegation &&
                                                            (addressF.getFieldMeta(
                                                                "delegation"
                                                            ).touched ||
                                                                showErrors)
                                                        }
                                                        subtitle={
                                                            addressF.getFieldMeta(
                                                                "delegation"
                                                            ).touched ||
                                                            showErrors
                                                                ? (addressF
                                                                      .errors
                                                                      .delegation as string)
                                                                : undefined
                                                        }
                                                        title="Delegación*"
                                                        name="delegation"
                                                        icon={<GPS />}
                                                        placeholder="Selecciona la delegac"
                                                        type="text"
                                                        disabled
                                                        className="m_b_xs w_100_per"
                                                        value={
                                                            addressF.values
                                                                .delegation
                                                        }
                                                        onChange={(e: any) => {
                                                            addressF.handleChange(
                                                                e
                                                            );
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Input
                                                        onBlur={
                                                            addressF.handleBlur
                                                        }
                                                        error={
                                                            !!addressF.errors
                                                                .neighborhood &&
                                                            (addressF.getFieldMeta(
                                                                "neighborhood"
                                                            ).touched ||
                                                                showErrors)
                                                        }
                                                        subtitle={
                                                            addressF.getFieldMeta(
                                                                "neighborhood"
                                                            ).touched ||
                                                            showErrors
                                                                ? (addressF
                                                                      .errors
                                                                      .neighborhood as string)
                                                                : undefined
                                                        }
                                                        title="Colonia*"
                                                        name="neighborhood"
                                                        icon={<GPS />}
                                                        placeholder="Selecciona la colonia"
                                                        type="select"
                                                        options={
                                                            neighborhoodOptions
                                                        }
                                                        className="m_b_xs w_100_per"
                                                        value={
                                                            addressF.values
                                                                .neighborhood
                                                        }
                                                        onChange={(e: any) => {
                                                            addressF.handleChange(
                                                                e
                                                            );
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Input
                                                        onBlur={
                                                            addressF.handleBlur
                                                        }
                                                        error={
                                                            !!addressF.errors
                                                                .street &&
                                                            (addressF.getFieldMeta(
                                                                "street"
                                                            ).touched ||
                                                                showErrors)
                                                        }
                                                        subtitle={
                                                            addressF.getFieldMeta(
                                                                "street"
                                                            ).touched ||
                                                            showErrors
                                                                ? (addressF
                                                                      .errors
                                                                      .street as string)
                                                                : undefined
                                                        }
                                                        title="Calle*"
                                                        name="street"
                                                        icon={<GPS />}
                                                        placeholder="Escribe nombre de la calle "
                                                        type="text"
                                                        className="m_b_xs w_100_per"
                                                        value={
                                                            addressF.values
                                                                .street
                                                        }
                                                        onChange={(e: any) => {
                                                            addressF.handleChange(
                                                                e
                                                            );
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Input
                                                        onBlur={
                                                            addressF.handleBlur
                                                        }
                                                        error={
                                                            !!addressF.errors
                                                                .extNumber &&
                                                            (addressF.getFieldMeta(
                                                                "extNumber"
                                                            ).touched ||
                                                                showErrors)
                                                        }
                                                        subtitle={
                                                            addressF.getFieldMeta(
                                                                "extNumber"
                                                            ).touched ||
                                                            showErrors
                                                                ? (addressF
                                                                      .errors
                                                                      .extNumber as string)
                                                                : undefined
                                                        }
                                                        title="Numero Exterior* "
                                                        name="extNumber"
                                                        icon={<GPS />}
                                                        placeholder="Escribe el numero exterior"
                                                        type="text"
                                                        className="m_b_xs w_100_per"
                                                        value={
                                                            addressF.values
                                                                .extNumber
                                                        }
                                                        onChange={(e: any) => {
                                                            addressF.handleChange(
                                                                e
                                                            );
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Input
                                                        onBlur={
                                                            addressF.handleBlur
                                                        }
                                                        error={
                                                            !!addressF.errors
                                                                .intNumber &&
                                                            (addressF.getFieldMeta(
                                                                "intNumber"
                                                            ).touched ||
                                                                showErrors)
                                                        }
                                                        subtitle={
                                                            addressF.getFieldMeta(
                                                                "intNumber"
                                                            ).touched ||
                                                            showErrors
                                                                ? (addressF
                                                                      .errors
                                                                      .intNumber as string)
                                                                : undefined
                                                        }
                                                        title="Numero Interior"
                                                        name="intNumber"
                                                        icon={<GPS />}
                                                        placeholder="Escribe el numero interior"
                                                        type="text"
                                                        className="m_b_xs w_100_per"
                                                        value={
                                                            addressF.values
                                                                .intNumber
                                                        }
                                                        onChange={(e: any) => {
                                                            addressF.handleChange(
                                                                e
                                                            );
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Button
                                                variant="outline"
                                                scale="small"
                                                onClick={() =>
                                                    setOpenLocation(true)
                                                }
                                                className="w_100_per"
                                            >
                                                Asignar ubicación exacta
                                            </Button>
                                            {location ? (
                                                <div className="bg_neutral_400 p_x_md p_y_xs flex_center m_t_md">
                                                    <Typography
                                                        scale="small"
                                                        weight="600"
                                                        textColor="neutral_700"
                                                        className=""
                                                    >
                                                        {location.latitude},
                                                        {location.longitude}
                                                    </Typography>
                                                </div>
                                            ) : (
                                                <Typography
                                                    scale="medium"
                                                    weight="600"
                                                    textColor="red_300"
                                                    className=" text_center m_t_md"
                                                >
                                                    No se ha asignado ubicacion
                                                    exacta
                                                </Typography>
                                            )}
                                        </>
                                    ) : null}

                                    {sections[2] ? (
                                        <>
                                            {sections[0] || sections[1] ? (
                                                <Divider variant="middle" />
                                            ) : null}

                                            <Typography
                                                scale="heading4"
                                                weight="600"
                                                textColor="neutral_700"
                                                className="m_y_xl"
                                            >
                                                Referencias personales
                                            </Typography>
                                            <Grid
                                                container
                                                direction="row"
                                                rowSpacing={0}
                                                columnSpacing={6}
                                                justifyContent="center"
                                                className=""
                                            >
                                                <Grid item xs={6}>
                                                    <Input
                                                        onBlur={
                                                            referencesF.handleBlur
                                                        }
                                                        error={
                                                            !!referencesF
                                                                .errors[
                                                                "ref1name"
                                                            ] &&
                                                            (referencesF.getFieldMeta(
                                                                "ref1name"
                                                            ).touched ||
                                                                showErrors)
                                                        }
                                                        subtitle={
                                                            referencesF.getFieldMeta(
                                                                "ref1name"
                                                            ).touched ||
                                                            showErrors
                                                                ? (referencesF
                                                                      .errors[
                                                                      "ref1name"
                                                                  ] as string)
                                                                : undefined
                                                        }
                                                        name="ref1name"
                                                        placeholder="Escribe su nombre completo"
                                                        icon={<Profile />}
                                                        title="Referencia 1"
                                                        type="text"
                                                        className="m_b_xs w_100_per"
                                                        value={
                                                            referencesF.values
                                                                .ref1name
                                                        }
                                                        onChange={
                                                            referencesF.handleChange
                                                        }
                                                    />
                                                    <Input
                                                        onBlur={
                                                            referencesF.handleBlur
                                                        }
                                                        error={
                                                            !!referencesF
                                                                .errors[
                                                                "ref1phone"
                                                            ] &&
                                                            (referencesF.getFieldMeta(
                                                                "ref1phone"
                                                            ).touched ||
                                                                showErrors)
                                                        }
                                                        subtitle={
                                                            referencesF.getFieldMeta(
                                                                "ref1phone"
                                                            ).touched ||
                                                            showErrors
                                                                ? (referencesF
                                                                      .errors[
                                                                      "ref1phone"
                                                                  ] as string)
                                                                : undefined
                                                        }
                                                        name="ref1phone"
                                                        placeholder="Escribe su numero telefónico"
                                                        title=""
                                                        type="number"
                                                        className="m_b_xs w_100_per"
                                                        value={
                                                            referencesF.values
                                                                .ref1phone
                                                        }
                                                        onChange={
                                                            referencesF.handleChange
                                                        }
                                                    />
                                                    <Input
                                                        onBlur={
                                                            referencesF.handleBlur
                                                        }
                                                        error={
                                                            !!referencesF
                                                                .errors[
                                                                "ref1relation"
                                                            ] &&
                                                            (referencesF.getFieldMeta(
                                                                "ref1relation"
                                                            ).touched ||
                                                                showErrors)
                                                        }
                                                        subtitle={
                                                            referencesF.getFieldMeta(
                                                                "ref1relation"
                                                            ).touched ||
                                                            showErrors
                                                                ? (referencesF
                                                                      .errors[
                                                                      "ref1relation"
                                                                  ] as string)
                                                                : undefined
                                                        }
                                                        title=""
                                                        placeholder="Selecciona el parentesco"
                                                        name="ref1relation"
                                                        type="select"
                                                        options={
                                                            relationOptions
                                                        }
                                                        className="m_b_xs w_100_per"
                                                        value={
                                                            referencesF.values
                                                                .ref1relation
                                                        }
                                                        onChange={
                                                            handleRelationChange
                                                        }
                                                        // onChange={referencesF.handleChange}
                                                    />
                                                    {showRef1OtherField && (
                                                        <Input
                                                            onBlur={
                                                                referencesF.handleBlur
                                                            }
                                                            error={
                                                                !!referencesF
                                                                    .errors[
                                                                    "ref1other"
                                                                ] &&
                                                                (referencesF.getFieldMeta(
                                                                    "ref1other"
                                                                ).touched ||
                                                                    showErrors)
                                                            }
                                                            subtitle={
                                                                referencesF.getFieldMeta(
                                                                    "ref1other"
                                                                ).touched ||
                                                                showErrors
                                                                    ? (referencesF
                                                                          .errors[
                                                                          "ref1other"
                                                                      ] as string)
                                                                    : undefined
                                                            }
                                                            name="ref1other"
                                                            placeholder="Escribe el parentesco"
                                                            title=""
                                                            type="text"
                                                            className="m_b_xs w_100_per"
                                                            value={
                                                                referencesF
                                                                    .values
                                                                    .ref1other
                                                            }
                                                            onChange={
                                                                referencesF.handleChange
                                                            }
                                                        />
                                                    )}
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Input
                                                        onBlur={
                                                            referencesF.handleBlur
                                                        }
                                                        error={
                                                            !!referencesF
                                                                .errors[
                                                                "ref2name"
                                                            ] &&
                                                            (referencesF.getFieldMeta(
                                                                "ref2name"
                                                            ).touched ||
                                                                showErrors)
                                                        }
                                                        subtitle={
                                                            referencesF.getFieldMeta(
                                                                "ref2name"
                                                            ).touched ||
                                                            showErrors
                                                                ? (referencesF
                                                                      .errors[
                                                                      "ref2name"
                                                                  ] as string)
                                                                : undefined
                                                        }
                                                        name="ref2name"
                                                        placeholder="Escribe su nombre completo"
                                                        icon={<Profile />}
                                                        title="Referencia 2"
                                                        type="text"
                                                        className="m_b_xs w_100_per"
                                                        value={
                                                            referencesF.values
                                                                .ref2name
                                                        }
                                                        onChange={
                                                            referencesF.handleChange
                                                        }
                                                    />
                                                    <Input
                                                        onBlur={
                                                            referencesF.handleBlur
                                                        }
                                                        error={
                                                            !!referencesF
                                                                .errors[
                                                                "ref2phone"
                                                            ] &&
                                                            (referencesF.getFieldMeta(
                                                                "ref2phone"
                                                            ).touched ||
                                                                showErrors)
                                                        }
                                                        subtitle={
                                                            referencesF.getFieldMeta(
                                                                "ref2phone"
                                                            ).touched ||
                                                            showErrors
                                                                ? (referencesF
                                                                      .errors[
                                                                      "ref2phone"
                                                                  ] as string)
                                                                : undefined
                                                        }
                                                        name="ref2phone"
                                                        placeholder="Escribe su numero telefónico"
                                                        title=""
                                                        type="number"
                                                        className="m_b_xs w_100_per"
                                                        value={
                                                            referencesF.values
                                                                .ref2phone
                                                        }
                                                        onChange={
                                                            referencesF.handleChange
                                                        }
                                                    />
                                                    <Input
                                                        onBlur={
                                                            referencesF.handleBlur
                                                        }
                                                        error={
                                                            !!referencesF
                                                                .errors[
                                                                "ref2relation"
                                                            ] &&
                                                            (referencesF.getFieldMeta(
                                                                "ref2relation"
                                                            ).touched ||
                                                                showErrors)
                                                        }
                                                        subtitle={
                                                            referencesF.getFieldMeta(
                                                                "ref2relation"
                                                            ).touched ||
                                                            showErrors
                                                                ? (referencesF
                                                                      .errors[
                                                                      "ref2relation"
                                                                  ] as string)
                                                                : undefined
                                                        }
                                                        title=""
                                                        placeholder="Selecciona el parentesco"
                                                        name="ref2relation"
                                                        type="select"
                                                        options={
                                                            relationOptions
                                                        }
                                                        className="m_b_xs w_100_per"
                                                        value={
                                                            referencesF.values
                                                                .ref2relation
                                                        }
                                                        onChange={
                                                            handleRelation2Change
                                                        }
                                                    />
                                                    {showRef2OtherField && (
                                                        <Input
                                                            onBlur={
                                                                referencesF.handleBlur
                                                            }
                                                            error={
                                                                !!referencesF
                                                                    .errors[
                                                                    "ref2other"
                                                                ] &&
                                                                (referencesF.getFieldMeta(
                                                                    "ref2other"
                                                                ).touched ||
                                                                    showErrors)
                                                            }
                                                            subtitle={
                                                                referencesF.getFieldMeta(
                                                                    "ref2other"
                                                                ).touched ||
                                                                showErrors
                                                                    ? (referencesF
                                                                          .errors[
                                                                          "ref2other"
                                                                      ] as string)
                                                                    : undefined
                                                            }
                                                            name="ref2other"
                                                            placeholder="Escribe el parentesco"
                                                            title=""
                                                            type="text"
                                                            className="m_b_xs w_100_per"
                                                            value={
                                                                referencesF
                                                                    .values
                                                                    .ref2other
                                                            }
                                                            onChange={
                                                                referencesF.handleChange
                                                            }
                                                        />
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </>
                                    ) : null}
                                    {sections[3] ? (
                                        <>
                                            {sections[0] ||
                                            sections[1] ||
                                            sections[2] ? (
                                                <Divider variant="middle" />
                                            ) : null}
                                            {!ozoner?._id || !form?._id ? (
                                                <div className="flex_center m_t_md">
                                                    <div className="dso_card bg_neutral_0 flex_center_col p_md">
                                                        <Typography
                                                            scale="xsmall"
                                                            weight="600"
                                                            textColor="neutral_900"
                                                            className="text_center m_b_md"
                                                        >
                                                            {!vehicle
                                                                ? "ASIGNAR VEHÍCULO"
                                                                : vehicle?.internalId}
                                                        </Typography>
                                                        <div
                                                            className="dso_card_img m_b_md bg_neutral_200 w_fit display_flex overflow_hidden border_primary_100"
                                                            onClick={() => {
                                                                setFullOpen(
                                                                    true
                                                                );
                                                            }}
                                                            onKeyPress={() => {
                                                                setFullOpen(
                                                                    true
                                                                );
                                                            }}
                                                            role="button"
                                                            tabIndex={0}
                                                        >
                                                            {vehicle ? (
                                                                <img
                                                                    src={
                                                                        vehicle
                                                                            .images[0]
                                                                            ? vehicle
                                                                                  .images[0]
                                                                                  .url
                                                                            : `${process.env.REACT_APP_STORAGE_BASE_URL}public/uploads/generic-moto.png`
                                                                    }
                                                                    alt=""
                                                                    className="cards_photo"
                                                                />
                                                            ) : (
                                                                <Moto className="dim_xl m_lg border_transparent" />
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null}
                                            <Typography
                                                scale="heading4"
                                                weight="600"
                                                textColor="neutral_700"
                                                className="m_y_xxl"
                                            >
                                                Financiero
                                            </Typography>

                                            <Grid
                                                container
                                                direction="row"
                                                rowSpacing={0}
                                                columnSpacing={6}
                                                justifyContent="center"
                                                className=""
                                            >
                                                <Grid item xs={12}>
                                                    <Input
                                                        onBlur={
                                                            financialF.handleBlur
                                                        }
                                                        error={
                                                            !!financialF.errors[
                                                                "monthlyIncome"
                                                            ] &&
                                                            (financialF.getFieldMeta(
                                                                "monthlyIncome"
                                                            ).touched ||
                                                                showErrors)
                                                        }
                                                        subtitle={
                                                            financialF.getFieldMeta(
                                                                "monthlyIncome"
                                                            ).touched ||
                                                            showErrors
                                                                ? (financialF
                                                                      .errors[
                                                                      "monthlyIncome"
                                                                  ] as string)
                                                                : undefined
                                                        }
                                                        title="Ingreso Bruto Mensual*"
                                                        placeholder="Escribe el valor de ingresos del ozoner"
                                                        name="monthlyIncome"
                                                        type="number"
                                                        icon={
                                                            <Up className="m_l_xs status_arrows green" />
                                                        }
                                                        className="m_b_xs w_100_per"
                                                        value={
                                                            financialF.values
                                                                .monthlyIncome
                                                        }
                                                        onChange={
                                                            financialF.handleChange
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Input
                                                        onBlur={
                                                            financialF.handleBlur
                                                        }
                                                        error={
                                                            !!financialF.errors[
                                                                "monthlySpending"
                                                            ] &&
                                                            (financialF.getFieldMeta(
                                                                "monthlySpending"
                                                            ).touched ||
                                                                showErrors)
                                                        }
                                                        subtitle={
                                                            financialF.getFieldMeta(
                                                                "monthlySpending"
                                                            ).touched ||
                                                            showErrors
                                                                ? (financialF
                                                                      .errors[
                                                                      "monthlySpending"
                                                                  ] as string)
                                                                : undefined
                                                        }
                                                        title="Egreso Ordinario Mensual*"
                                                        placeholder="Escribe el valor de gastos del ozoner"
                                                        name="monthlySpending"
                                                        type="number"
                                                        icon={
                                                            <Down className="m_l_xs status_arrows red" />
                                                        }
                                                        className="m_b_xs w_100_per"
                                                        value={
                                                            financialF.values
                                                                .monthlySpending
                                                        }
                                                        onChange={
                                                            financialF.handleChange
                                                        }
                                                    />
                                                </Grid>

                                                {/* <Grid item xs={6}>

                  </Grid> */}
                                            </Grid>
                                            <div className="display_flex flex_align_center flex_gap_xxl m_y_xl">
                                                <div>
                                                    <div className="display_flex flex_gap_xs flex_align_center m_b_xs">
                                                        <Ruler className="dim_lg text_primary_300" />
                                                        <Typography
                                                            scale="medium"
                                                            weight="600"
                                                            textColor="neutral_900"
                                                        >
                                                            ¿El ozoner trabaja
                                                            en plataformas
                                                            digitales?
                                                        </Typography>
                                                    </div>
                                                    <Typography
                                                        scale="xsmall"
                                                        weight="400"
                                                        textColor="neutral_900"
                                                    >
                                                        Se enviara un correo al
                                                        ozoner para la
                                                        validacion de su cuenta
                                                    </Typography>
                                                </div>
                                                <div className="flex_center p_x_sm bg_neutral_300 br_xs">
                                                    <Input
                                                        title=""
                                                        name="workInDigitalPlatforms"
                                                        type="slider"
                                                        value={
                                                            financialF.values
                                                                .workInDigitalPlatforms
                                                        }
                                                        onChange={
                                                            financialF.handleChange
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <Grid
                                                container
                                                direction="row"
                                                rowSpacing={0}
                                                columnSpacing={6}
                                                justifyContent="center"
                                                className=""
                                            >
                                                <Grid item sm={6}>
                                                    <Input
                                                        onBlur={
                                                            financialF.handleBlur
                                                        }
                                                        error={
                                                            !!financialF.errors[
                                                                "educationalLevel"
                                                            ] &&
                                                            (financialF.getFieldMeta(
                                                                "educationalLevel"
                                                            ).touched ||
                                                                showErrors)
                                                        }
                                                        subtitle={
                                                            financialF.getFieldMeta(
                                                                "educationalLevel"
                                                            ).touched ||
                                                            showErrors
                                                                ? (financialF
                                                                      .errors[
                                                                      "educationalLevel"
                                                                  ] as string)
                                                                : undefined
                                                        }
                                                        title="Nivel de estudios*"
                                                        placeholder="Su último grado de estudios"
                                                        name="educationalLevel"
                                                        type="select"
                                                        options={education}
                                                        icon={<Ruler />}
                                                        className="m_b_xs w_100_per"
                                                        value={
                                                            financialF.values
                                                                .educationalLevel
                                                        }
                                                        onChange={
                                                            financialF.handleChange
                                                        }
                                                    />
                                                    <Input
                                                    onBlur={
                                                        financialF.handleBlur
                                                    }
                                                    error={
                                                        !!financialF.errors[
                                                            "economicActivity"
                                                        ] &&
                                                        (financialF.getFieldMeta(
                                                            "economicActivity"
                                                        ).touched ||
                                                            showErrors)
                                                    }
                                                    subtitle={
                                                        financialF.getFieldMeta(
                                                            "economicActivity"
                                                        ).touched || showErrors
                                                            ? (financialF
                                                                  .errors[
                                                                  "economicActivity"
                                                              ] as string)
                                                            : undefined
                                                    }
                                                    title="Ocupación*"
                                                    placeholder="Escribe la ocupación aquí"
                                                    name="economicActivity"
                                                    type="select"
                                                    options={Object.values(economicActivityEnum)}
                                                    icon={<Profile />}
                                                    className="m_b_xs w_100_per"
                                                    value={
                                                        financialF.values
                                                            .economicActivity
                                                    }
                                                    onChange={
                                                        financialF.handleChange
                                                    }
                                                />
                                                {financialF.values
                                                            .economicActivity === economicActivityEnum.otro?(
                                                <Input
                                                    onBlur={
                                                        financialF.handleBlur
                                                    }
                                                    error={
                                                        !!financialF.errors[
                                                            "economicActivityOther"
                                                        ] &&
                                                        (financialF.getFieldMeta(
                                                            "economicActivityOther"
                                                        ).touched ||
                                                            showErrors)
                                                    }
                                                    subtitle={
                                                        financialF.getFieldMeta(
                                                            "economicActivityOther"
                                                        ).touched || showErrors
                                                            ? (financialF
                                                                  .errors[
                                                                  "economicActivityOther"
                                                              ] as string)
                                                            : undefined
                                                    }
                                                    title="Cual?"
                                                    placeholder="Escribe la ocupación aquí"
                                                    name="economicActivityOther"
                                                    type="text"
                                                    icon={<Profile />}
                                                    className="m_b_xs w_100_per"
                                                    value={
                                                        financialF.values
                                                            .economicActivityOther
                                                    }
                                                    onChange={
                                                        financialF.handleChange
                                                    }
                                                />
                                                            ):null}
                                                    <Input
                                                        onBlur={
                                                            financialF.handleBlur
                                                        }
                                                        error={
                                                            !!financialF.errors[
                                                                "companyName"
                                                            ] &&
                                                            (financialF.getFieldMeta(
                                                                "companyName"
                                                            ).touched ||
                                                                showErrors)
                                                        }
                                                        subtitle={
                                                            financialF.getFieldMeta(
                                                                "companyName"
                                                            ).touched ||
                                                            showErrors
                                                                ? (financialF
                                                                      .errors[
                                                                      "companyName"
                                                                  ] as string)
                                                                : undefined
                                                        }
                                                        title="Empresa"
                                                        placeholder="Escribe el nombre de tu empresa"
                                                        name="companyName"
                                                        type="text"
                                                        icon={<Working />}
                                                        className="m_b_xs w_100_per"
                                                        value={
                                                            financialF.values
                                                                .companyName
                                                        }
                                                        onChange={
                                                            financialF.handleChange
                                                        }
                                                    />
                                                    <Input
                                                        onBlur={
                                                            financialF.handleBlur
                                                        }
                                                        error={
                                                            !!financialF.errors[
                                                                "social_network"
                                                            ] &&
                                                            (financialF.getFieldMeta(
                                                                "social_network"
                                                            ).touched ||
                                                                showErrors)
                                                        }
                                                        subtitle={
                                                            financialF.getFieldMeta(
                                                                "social_network"
                                                            ).touched ||
                                                            showErrors
                                                                ? (financialF
                                                                      .errors[
                                                                      "social_network"
                                                                  ] as string)
                                                                : undefined
                                                        }
                                                        title="Red social"
                                                        placeholder="Escribe la red social del ozoner"
                                                        name="social_network"
                                                        type="text"
                                                        icon={<Working />}
                                                        className="m_b_xs w_100_per"
                                                        value={
                                                            financialF.values
                                                                .social_network
                                                        }
                                                        onChange={
                                                            financialF.handleChange
                                                        }
                                                    />

                                                    {vehicle && (
                                                        <Input
                                                            onBlur={
                                                                financialF.handleBlur
                                                            }
                                                            error={
                                                                !!financialF
                                                                    .errors[
                                                                    "creditTime"
                                                                ] &&
                                                                (financialF.getFieldMeta(
                                                                    "creditTime"
                                                                ).touched ||
                                                                    showErrors)
                                                            }
                                                            subtitle={
                                                                financialF.getFieldMeta(
                                                                    "creditTime"
                                                                ).touched ||
                                                                showErrors
                                                                    ? (financialF
                                                                          .errors[
                                                                          "creditTime"
                                                                      ] as string)
                                                                    : undefined
                                                            }
                                                            title="Tiempo de crédito*"
                                                            name="creditTime"
                                                            type="select"
                                                            icon={<ID />}
                                                            className="m_b_xs w_100_per"
                                                            placeholder="Selecciona un tiempo de crédito"
                                                            options={[
                                                                ...(vehicle?.salePrices
                                                                    ? vehicle.salePrices.map(
                                                                          (
                                                                              t: any
                                                                          ) => ({
                                                                              label: `${t.weeks} semanas`,
                                                                              value: t.weeks,
                                                                          })
                                                                      )
                                                                    : []),
                                                            ]}
                                                            value={
                                                                financialF
                                                                    .values
                                                                    .creditTime
                                                            }
                                                            onChange={
                                                                financialF.handleChange
                                                            }
                                                        />
                                                    )}
                                                    <Input
                                                        onBlur={
                                                            financialF.handleBlur
                                                        }
                                                        error={
                                                            !!financialF.errors[
                                                                "livesWith"
                                                            ] &&
                                                            (financialF.getFieldMeta(
                                                                "livesWith"
                                                            ).touched ||
                                                                showErrors)
                                                        }
                                                        subtitle={
                                                            financialF.getFieldMeta(
                                                                "livesWith"
                                                            ).touched ||
                                                            showErrors
                                                                ? (financialF
                                                                      .errors[
                                                                      "livesWith"
                                                                  ] as string)
                                                                : undefined
                                                        }
                                                        icon={<Groups />}
                                                        title="Vive Con"
                                                        name="livesWith"
                                                        placeholder="¿Con quíen vive?"
                                                        disabled={
                                                            !livesWithOptions
                                                        }
                                                        value={
                                                            financialF.values
                                                                .livesWith
                                                        }
                                                        onChange={(e: any) => {
                                                            financialF.handleChange(
                                                                e
                                                            );
                                                        }}
                                                        className="m_b_xs w_100_per"
                                                        type="select"
                                                        options={
                                                            livesWithOptions
                                                        }
                                                        variant="checkbox"
                                                        multiple
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Input
                                                        onBlur={
                                                            financialF.handleBlur
                                                        }
                                                        error={
                                                            !!financialF.errors[
                                                                "civilStatus"
                                                            ] &&
                                                            (financialF.getFieldMeta(
                                                                "civilStatus"
                                                            ).touched ||
                                                                showErrors)
                                                        }
                                                        subtitle={
                                                            financialF.getFieldMeta(
                                                                "civilStatus"
                                                            ).touched ||
                                                            showErrors
                                                                ? (financialF
                                                                      .errors[
                                                                      "civilStatus"
                                                                  ] as string)
                                                                : undefined
                                                        }
                                                        title="Estado Civil*"
                                                        placeholder="Seleccione su estado civil"
                                                        name="civilStatus"
                                                        type="select"
                                                        options={civil}
                                                        icon={<Love />}
                                                        className="m_b_xs w_100_per"
                                                        value={
                                                            financialF.values
                                                                .civilStatus
                                                        }
                                                        onChange={
                                                            financialF.handleChange
                                                        }
                                                    />
                                                    <Input
                                                        onBlur={
                                                            financialF.handleBlur
                                                        }
                                                        error={
                                                            !!financialF.errors[
                                                                "dependantsCount"
                                                            ] &&
                                                            (financialF.getFieldMeta(
                                                                "dependantsCount"
                                                            ).touched ||
                                                                showErrors)
                                                        }
                                                        subtitle={
                                                            financialF.getFieldMeta(
                                                                "dependantsCount"
                                                            ).touched ||
                                                            showErrors
                                                                ? (financialF
                                                                      .errors[
                                                                      "dependantsCount"
                                                                  ] as string)
                                                                : undefined
                                                        }
                                                        title="Dependientes económicos*"
                                                        placeholder="Personas que viven de su ingreso"
                                                        name="dependantsCount"
                                                        type="select"
                                                        options={dependant}
                                                        icon={<Calendar />}
                                                        className="m_b_xs w_100_per"
                                                        value={
                                                            financialF.values
                                                                .dependantsCount
                                                        }
                                                        onChange={
                                                            financialF.handleChange
                                                        }
                                                    />
                                                    <Input
                                                        onBlur={
                                                            financialF.handleBlur
                                                        }
                                                        error={
                                                            !!financialF.errors[
                                                                "childrenCount"
                                                            ] &&
                                                            (financialF.getFieldMeta(
                                                                "childrenCount"
                                                            ).touched ||
                                                                showErrors)
                                                        }
                                                        subtitle={
                                                            financialF.getFieldMeta(
                                                                "childrenCount"
                                                            ).touched ||
                                                            showErrors
                                                                ? (financialF
                                                                      .errors[
                                                                      "childrenCount"
                                                                  ] as string)
                                                                : undefined
                                                        }
                                                        title="Hijos*"
                                                        placeholder="Cuantos hijos tiene"
                                                        name="childrenCount"
                                                        type="select"
                                                        options={children}
                                                        icon={<Baby />}
                                                        className="m_b_xs w_100_per"
                                                        value={
                                                            financialF.values
                                                                .childrenCount
                                                        }
                                                        onChange={
                                                            financialF.handleChange
                                                        }
                                                    />
                                                    <Input
                                                        onBlur={
                                                            financialF.handleBlur
                                                        }
                                                        error={
                                                            !!financialF.errors[
                                                                "assets"
                                                            ] &&
                                                            (financialF.getFieldMeta(
                                                                "assets"
                                                            ).touched ||
                                                                showErrors)
                                                        }
                                                        subtitle={
                                                            financialF.getFieldMeta(
                                                                "assets"
                                                            ).touched ||
                                                            showErrors
                                                                ? (financialF
                                                                      .errors[
                                                                      "assets"
                                                                  ] as string)
                                                                : undefined
                                                        }
                                                        title="Activos"
                                                        name="assets"
                                                        type="checkbox"
                                                        options={activos}
                                                        icon={<Check />}
                                                        className="w_100_per"
                                                        value={
                                                            financialF.values
                                                                .assets
                                                        }
                                                        onChange={
                                                            financialF.handleChange
                                                        }
                                                    />
                                                    {financialF.values?.assets?.includes(
                                                        "otro"
                                                    ) && (
                                                        <Input
                                                            title=""
                                                            placeholder="Otros activos"
                                                            name="otherAssets"
                                                            type="text"
                                                            className="m_b_xs w_100_per other_input"
                                                            value={
                                                                financialF
                                                                    .values
                                                                    .otherAssets
                                                            }
                                                            onChange={
                                                                financialF.handleChange
                                                            }
                                                        />
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </>
                                    ) : null}
                                </form>
                            </div>

                            <div className="flex_footer">
                                <Button
                                    scale="large"
                                    orientation="right"
                                    className="w_100_per"
                                    type="submit"
                                    onClick={() => {
                                        setShowErrors(true);
                                        onSubmit();
                                    }}
                                    disabled={
                                        loading ||
                                        (!ozonerF.dirty &&
                                            !addressF.dirty &&
                                            !referencesF.dirty &&
                                            !financialF.dirty &&
                                            (!ozoner?.fullAddress || !location || ozoner.fullAddress.lat === location?.latitude )&&
                                            (!ozoner?.fullAddress || !location || ozoner.fullAddress.long === location?.longitude )
                                        )
                                    }
                                >
                                    Guardar
                                </Button>
                            </div>
                        </>
                    )}
                </div>
                {fullOpen && (
                    <Inventory
                        onClick={(v: any) => {
                            setVehicle(v);
                            setFullOpen(false);
                        }}
                        initialFilter={["available"]}
                        availableFilters={{
                            available: true,
                            delivered: false,
                            aside: false,
                            lost: false,
                            inRecovery: false,
                            recovered: false,
                            left: false,
                            underMaintenance: false,
                            draft: false,
                        }}
                    />
                )}
            </SideModal>
            {openLocation && (
                <LocationModalEdit
                    openModal={openLocation}
                    setOpenModal={setOpenLocation}
                    addressData={addressF.values}
                    saveLocation={setLocation}
                    initialLocation = {location}
                />
            )}
        </>
    );
}

export default EditOzoner;
