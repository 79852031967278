/* eslint-disable no-nested-ternary */
import React, { useState, useContext, useEffect } from "react";

// -----------------LIBRARIES IMPORTS------------------- //
// @ts-ignore
import { Button, Input, Typography } from "design_system/src";

// -----------------DATA IMPORTS------------------- //

// -----------------STATIC IMPORTS------------------- //
import "../../../ozoner.scss";

// -----------------COMPONENTS IMPORTS------------------- //
import { toasterNotification } from "helpers/globalNotifications/globalNotifications";
import { editSolicitud } from "helpers/fetchs/solicitudes";
import { toasterOptions } from "enums/toaster.enum";
import { editCredit } from "helpers/fetchs/credits";
import {
    collectionSegmentationOptions,
    creditSegmentationOptions,
    ozonerContext,
    partnershipSegmentationOptions,
} from "../../OzonerContext";

export function PartnershipSegmentation() {
    const { financialForm, setResult } = useContext(ozonerContext);

    const [loading, setLoading] = useState(false);

    const changeSegmentation = (newSegmentation: string) => {
        if (newSegmentation && financialForm?.form?._id) {
            setLoading(true);
            editSolicitud(financialForm.form._id, {
                partnership: newSegmentation,
            })
                .then((_) => {
                    setLoading(false);
                    setResult();
                })
                .catch((e) => {
                    setLoading(false);
                    toasterNotification({
                        msg: e ?? "Ocurrio un error",
                        toasterType: toasterOptions.error,
                        style: "light",
                    });
                });
        }
    };

    return (
        <div
            id="segmentacion-alianza"
            className="p_x_md p_t_md border_b_solid border_1 border_neutral_400"
        >
            <Typography scale="medium" weight="400" className="">
                Alianza lead
            </Typography>
            <Input
                title=""
                disabled={loading}
                name="segmentacion"
                placeholder="Selecciona alianza"
                value={financialForm.form.partnership}
                onChange={(e: any) => changeSegmentation(e.target.value)}
                className="w_100_per"
                type="select"
                options={partnershipSegmentationOptions}
                variant="default"
            />
        </div>
    );
}
export function CreditSegmentation() {
    const { financialForm, setResult } = useContext(ozonerContext);

    const [loading, setLoading] = useState(false);

    const changeSegmentation = (newSegmentation: string) => {
        if (newSegmentation && financialForm?.form?._id) {
            setLoading(true);
            editSolicitud(financialForm.form._id, {
                creditSegmentation: newSegmentation,
            })
                .then((_) => {
                    setLoading(false);
                    setResult();
                })
                .catch((e) => {
                    setLoading(false);
                    toasterNotification({
                        msg: e ?? "Ocurrio un error",
                        toasterType: toasterOptions.error,
                        style: "light",
                    });
                });
        }
    };

    return (
        <div
            id="segmentacion-credito"
            className="p_x_md p_t_md border_b_solid border_1 border_neutral_400"
        >
            <Typography scale="medium" weight="400" className="">
                Segmentacion de credito
            </Typography>
            <Input
                title=""
                disabled={loading}
                name="segmentacion"
                placeholder="Selecciona Segmentación"
                value={financialForm.form.creditSegmentation}
                onChange={(e: any) => changeSegmentation(e.target.value)}
                className="w_100_per"
                type="select"
                options={creditSegmentationOptions}
                variant="default"
            />
        </div>
    );
}
export function CollectionSegmentation() {
    const { financialForm, setResult } = useContext(ozonerContext);

    const [loading, setLoading] = useState(false);

    const changeSegmentation = (newSegmentation: string) => {
        if (newSegmentation && financialForm?.form?._id) {
            setLoading(true);
            editSolicitud(financialForm.form._id, {
                collectionSegmentation: newSegmentation,
            })
                .then((_) => {
                    setLoading(false);
                    setResult();
                })
                .catch((e) => {
                    setLoading(false);
                    toasterNotification({
                        msg: e ?? "Ocurrio un error",
                        toasterType: toasterOptions.error,
                        style: "light",
                    });
                });
        }
    };

    return (
        <div>
            <Typography scale="medium" weight="400" className="">
                Segmentacion de cobranza
            </Typography>
            <Input
                title=""
                disabled={loading}
                name="segmentacion"
                placeholder="Selecciona Segmentación"
                value={financialForm.form.collectionSegmentation}
                onChange={(e: any) => changeSegmentation(e.target.value)}
                className="w_100_per"
                type="select"
                options={collectionSegmentationOptions}
                variant="default"
            />
        </div>
    );
}

export function CollectionResponsible() {
    const { financialForm, setResult,ozoniosList } = useContext(ozonerContext);

    const [loading, setLoading] = useState(false);
    const [collectionOptions, setCollectionOptions] = useState([]);


    const changeResponsible = (newResponsible: string) => {

        if (newResponsible && financialForm?.credit?._id) {
            setLoading(true);
            editCredit(financialForm.credit._id, {
                collectionResponsible: newResponsible,
            })
                .then((_) => {
                    setLoading(false);
                    setResult();
                })
                .catch((e) => {
                    setLoading(false);
                    toasterNotification({
                        msg: e ?? "Ocurrio un error",
                        toasterType: toasterOptions.error,
                        style: "light",
                    });
                });
        }
    };
    useEffect(() => {
        const ozoniosDesc = ozoniosList
                    .filter((ozonio: any) =>
                        [
                            "62e43be83fce23d2fb978518",
                            "64f62a459df5f1f39e704cea",
                        ].includes(ozonio.value)
                    )
                    .sort(({ label: a }: any, { label: b }: any) =>
                        a.localeCompare(b)
                    );
                setCollectionOptions(ozoniosDesc);

    },[ozoniosList]);

    return (
        <div>
            <Typography scale="medium" weight="400" className="">
                Responsable de Cobranza
            </Typography>
            <Input
                title=""
                disabled={loading}
                name="estados"
                placeholder="Ingresa el nombre del Ozonio"
                value={financialForm?.credit?.collectionResponsible}
                onChange={(e: any) => changeResponsible(e.target.value)}
                className="w_100_per"
                type="select"
                options={collectionOptions}
                variant="default"
            />
        </div>
    );
}
