/* eslint-disable dot-notation */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from "react";

import { getExport, getOzoners } from "helpers/fetchs/ozoners";

// icons
import { ReactComponent as File } from "design_system/src/static/icons/download-file.svg";

import {
  Input,
  Typography,
  ModalConfirmation,
  Select,
  DateSelect,
} from "design_system/src";

// css
import "./Solicitudes.scss";
import moment from "moment";
import {
  getOzonerFilter,
  getSolicitudesFilter,
} from "helpers/localStorage/filterStorage";
import { statusOzonerEnum, statusOzoners } from "models/ozoner.interface";
import { toasterNotification } from "helpers/globalNotifications/globalNotifications";
import { toasterOptions } from "enums/toaster.enum";
import {
  FetchSolicitudes,
  exportarSolicitudes,
} from "helpers/fetchs/solicitudes";
import { FormStatusValues, formStatusEnum } from "models/financialForm.interface";

const createdOnOptions = [
  {
    label: "Pandaboard",
    color: "primary_200",
    value: "pandaboard",
  },
  {
    label: "Webpage",
    color: "yellow_100",
    value: "webpage",
  },
];

interface ExportProps {
  open: boolean;
  setOpen: Function;
}

let petition: number;

function ExportSolicitudes({ open, setOpen }: ExportProps) {
  const [createdOnFilter, setCreatedOnFilter] = useState([]);
  const [statusFilter, setStatusFilter] = useState<string[]>([]);

  const [datesFilter, setDatesFilter] = useState({
    initial: new Date(),
    last: new Date(),
    option: -1,
  });
  const [hasDatesFilter, setHasDatesFilter] = useState(false);

  const [minPage, setMinPage] = useState<number>(1);
  const [maxPage, setMaxPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [count, setCount] = useState<any>();
  const [paging, setPaging] = useState<any>();
  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState(false);

  const requestSolicitudes = () => {
    const num = petition + 1;
    petition += 1;

    const filters = [];

    if (
      createdOnFilter.length &&
      createdOnFilter.length !== createdOnOptions.length
    )
      filters.push(`createdOn=${createdOnFilter}`);
    if (
      statusFilter.length &&
      statusFilter.length !== Object.keys(statusOzoners).length
    )
      filters.push(`formStatus=${statusFilter}`);

    if (datesFilter && hasDatesFilter) {
      filters.push(
        `min=${moment
          .utc(datesFilter.initial)
          .format("YYYY-MM-DD")}&max=${moment
          .utc(datesFilter.last)
          .format("YYYY-MM-DD")}`
      );
    }
    FetchSolicitudes(
      minPage,
      undefined,
      undefined,
      undefined,
      filters.join("&")
    ).then((res) => {
      if (res && num === petition) {
        setTotalPages(res.pages);
        setCount(res.count);
      }
    });
  };

  useEffect(() => {
    if (minPage < 1) {
      setMinPage(1);
    }
    if (minPage > maxPage) {
      setMinPage(maxPage);
    }
    if (maxPage < minPage) {
      setMaxPage(minPage);
    }
    if (totalPages > 0 && maxPage > totalPages) {
      setMaxPage(totalPages);
    }

    if (minPage === 1 && maxPage === totalPages) {
      setPaging(0);
    } else if (minPage === 1 && maxPage === 1) {
      setPaging(1);
    } else if (minPage === 1 && maxPage === 5) {
      setPaging(2);
    } else {
      setPaging(-1);
    }
  }, [minPage, maxPage]);

  useEffect(() => {
    const information = getSolicitudesFilter();
    if (information.createdOnFilter) {
      setCreatedOnFilter(information.createdOnFilter);
    }
    if (information?.datesFilter) {
      setDatesFilter({
        ...information.datesFilter,
        initial: new Date(information.datesFilter.initial),
        last: new Date(information.datesFilter.last),
      });

      setHasDatesFilter(true);
    }
    if (information.statusFilter) {
      setStatusFilter(information.statusFilter);
    }
    petition = 0;
    requestSolicitudes();
  }, []);
  useEffect(() => {
    requestSolicitudes();
  }, [statusFilter, createdOnFilter, datesFilter, hasDatesFilter]);

  const exportSolicitudes = () => {
    let pages;
    let limit;
    if (minPage === 1 && maxPage === totalPages) {
      pages = undefined;
      limit = -1;
    } else {
      pages = `${minPage}-${maxPage}`;
      limit = undefined;
    }
    const filters = [];

    if (
      createdOnFilter.length &&
      createdOnFilter.length !== createdOnOptions.length
    )
      filters.push(`createdOn=${createdOnFilter}`);
    if (
      statusFilter.length &&
      statusFilter.length !== Object.keys(statusOzoners).length
    )
      filters.push(`formStatus=${statusFilter}`);

    if (datesFilter && hasDatesFilter) {
      filters.push(
        `min=${moment
          .utc(datesFilter.initial)
          .format("YYYY-MM-DD")}&max=${moment
          .utc(datesFilter.last)
          .format("YYYY-MM-DD")}`
      );
    }
    setLoading(true);
    exportarSolicitudes(pages, filters.join("&"), limit)
      .then((data) => {
        const blob = new Blob([data], { type: "text/csv;charset=utf-8;" });
        const link = document.createElement("a");

        if (link.download !== undefined) {
          // Browsers that support HTML5 download attribute
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", "solicitudes.csv");
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
        setLoading(false);
        setOpen(false);
      })
      .catch((e) => {
        setLoading(false);
        toasterNotification({
          msg: `Algo fallo: ${e.message}`,
          toasterType: toasterOptions.error,
          style: "light",
        });
      });
  };

  return (
      <ModalConfirmation
          openConfirmation={open}
          handleClose={() => {
              setOpen(false);
          }}
          icon={<File />}
          isConfirmationLoading={false}
          widthDesktop={tab === 0 ? 70 : 30}
          heightDesktop={tab === 0 ? 100 : undefined}
          actions={
              tab === 0
                  ? [
                        {
                            label: "Cancelar",
                            color: "grey",
                            action: () => setOpen(false),
                        },
                        {
                            label: "Siguiente",
                            color: "primary",
                            disabled: loading || !count,
                            action: () => setTab(1),
                        },
                    ]
                  : [
                        {
                            label: "Volver",
                            color: "grey",
                            action: () => setTab(0),
                        },
                        {
                            label: "Exportar",
                            color: "primary",
                            action: () => exportSolicitudes(),
                            loading,
                        },
                    ]
          }
      >
          {tab === 0 ? (
              <>
                  <Typography
                      scale="large"
                      weight="600"
                      textColor="neutral_1000"
                      className="m_b_sm"
                  >
                      ¿Quieres exportar Solicitudes ?
                  </Typography>
                  <Typography
                      scale="medium"
                      weight="400"
                      textColor="neutral_700"
                      className="m_b_sm"
                  >
                      Selecciona los filtros para el reporte.
                  </Typography>
                  <div className="display_flex flex_col flex_gap_sm m_b_sm">
                      <div className="display_flex flex_gap_sm">
                          <div className="flex_basis_0 flex_grow_1">
                              <DateSelect
                                  value={datesFilter}
                                  hasValue={hasDatesFilter}
                                  setHasValue={setHasDatesFilter}
                                  placeholder="Fecha de creación"
                                  setValue={(e: any) => {
                                      setDatesFilter(e);
                                      setHasDatesFilter(true);
                                  }}
                              />
                          </div>
                          <div className="flex_basis_0 flex_grow_1">
                              <Select
                                  fixedPlaceholder
                                  variant="checkbox"
                                  placeholder="Origen"
                                  options={createdOnOptions}
                                  value={createdOnFilter}
                                  onChange={(e: any) => {
                                      setCreatedOnFilter(e.target.value);
                                  }}
                              />
                          </div>
                      </div>
                      <Select
                          fixedPlaceholder
                          variant="checkbox"
                          placeholder="Estado"
                          options={Object.keys(FormStatusValues).map(
                              (key: any) => ({
                                  label: FormStatusValues[
                                      key as formStatusEnum
                                  ].title,
                                  value: key,
                              })
                          )}
                          value={statusFilter}
                          onChange={(e: any) => {
                              setStatusFilter(e.target.value);
                          }}
                      />
                  </div>

                  <div className="display_flex flex_align_end m_t_md">
                      <Typography
                          scale="large"
                          weight="600"
                          className="m_r_xxs"
                      >
                          {count ?? 0}
                      </Typography>
                      <Typography scale="medium" weight="400">
                          Solicitudes
                      </Typography>
                  </div>
              </>
          ) : (
              <>
                  <Typography weight="600" scale="heading4" className="m_b_xs">
                      Paginación
                  </Typography>
                  <div className="display_flex br_xs shadow_medium overflow_hidden">
                      <div className="flex_grow_1 flex_basis_0  bg_neutral_0 overflow_hidden">
                          <div className="p_x_lg p_y_sm">
                              <Input
                                  type="counter"
                                  title="Desde la:"
                                  name="desde"
                                  titleClassName="text_center w_100_per"
                                  min={1}
                                  max={totalPages}
                                  onChange={(e) =>
                                      setMinPage(parseInt(e.target.value, 10))
                                  }
                                  value={minPage}
                              />
                              <Input
                                  type="counter"
                                  title="Hasta la:"
                                  name="hasta"
                                  titleClassName="text_center w_100_per"
                                  min={1}
                                  max={totalPages}
                                  onChange={(e) =>
                                      setMaxPage(parseInt(e.target.value, 10))
                                  }
                                  value={maxPage}
                              />
                          </div>
                      </div>
                      <div className="flex_grow_1 flex_basis_0  bg_neutral_300 overflow_hidden">
                          <div className="p_t_sm p_r_sm">
                              {totalPages > 1 && (
                                  <Typography
                                      weight="600"
                                      scale="small"
                                      className={`p_y_sm p_x_md m_b_sm text_no_break  br_r_md ${
                                          paging === 0 ? "bg_neutral_0" : ""
                                      }`}
                                      onClick={() => {
                                          setMinPage(1);
                                          setMaxPage(totalPages);
                                      }}
                                  >
                                      Todas
                                  </Typography>
                              )}

                              <Typography
                                  weight="600"
                                  scale="small"
                                  className={`p_y_sm p_x_md m_b_sm text_no_break br_r_md ${
                                      paging === 1 || totalPages === 1
                                          ? "bg_neutral_0"
                                          : ""
                                  }`}
                                  onClick={() => {
                                      setMinPage(1);
                                      setMaxPage(1);
                                  }}
                              >
                                  La primera página
                              </Typography>
                              {totalPages > 1 && (
                                  <Typography
                                      weight="600"
                                      scale="small"
                                      className={`p_y_sm p_x_md m_b_sm text_no_break br_r_md ${
                                          paging === 2 ? "bg_neutral_0" : ""
                                      }`}
                                      onClick={() => {
                                          setMinPage(1);
                                          setMaxPage(5);
                                      }}
                                  >
                                      Las primeras 5
                                  </Typography>
                              )}
                          </div>
                      </div>
                  </div>
                  <div className="display_flex flex_align_end m_t_md">
                      <Typography
                          scale="large"
                          weight="600"
                          className="m_r_xxs"
                      >
                          {Math.min(count, (maxPage + 1 - minPage) * 30) ?? 0}
                      </Typography>
                      <Typography scale="medium" weight="400">
                          Ozoners
                      </Typography>
                  </div>
              </>
          )}
      </ModalConfirmation>
  );
}

export default ExportSolicitudes;
