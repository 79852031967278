/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import "./Solicitudes.scss";
import {
    Button,
    Pager,
    Typography,
    ModalConfirmation as Modal,
    Select,
    DateSelect,
    Popup,
    Knob,
    CheckboxInput,
    ModalConfirmation,
    Input,
    ListTable,
} from "design_system/src";
import { Link, useNavigate } from "react-router-dom";
import {
    batchSellerAssign,
    editSolicitud,
    exportarSolicitudes,
    FetchSolicitudes,
} from "helpers/fetchs/solicitudes";
import {
    getSolicitudesFilter,
    setSolicitudesFilter,
} from "helpers/localStorage/filterStorage";
import {
    contactStatusEnum,
    contactStatusValues,
    formStatusEnum,
    FormStatusValues,
    reachedMethodsEnum,
    reachedMethodsValues,
    salesRankEnum,
    salesRankValues,
    TDataFinancialForm,
} from "models/financialForm.interface";
import { ReactComponent as Pin } from "design_system/src/static/icons/pin.svg";
import { ReactComponent as Action } from "design_system/src/static/icons/more-v.svg";
import { ReactComponent as File } from "design_system/src/static/icons/download-file.svg";
import { ReactComponent as Check } from "design_system/src/static/icons/checkmark.svg";
import { ReactComponent as AddCircle } from "design_system/src/static/icons/add-circle.svg";
import { ReactComponent as Monitor } from "design_system/src/static/icons/monitor.svg";
import { ReactComponent as Panda } from "design_system/src/static/icons/panda.svg";
import { ReactComponent as List } from "design_system/src/static/icons/bulleted-list-2.svg";
import { ReactComponent as Boxes } from "design_system/src/static/icons/box_2.svg";
import { ReactComponent as Excel } from "design_system/src/static/icons/excel.svg";
import { ReactComponent as Fail } from "design_system/src/static/icons/close-circle.svg";
import { ReactComponent as Trash } from "design_system/src/static/icons/trash.svg";
import { ReactComponent as Success } from "design_system/src/static/icons/tick-circle.svg";
import { ReactComponent as Clip } from "design_system/src/static/icons/document.svg";
import { ReactComponent as Person } from "design_system/src/static/icons/user-2.svg";
import { ReactComponent as Profile } from "design_system/src/static/icons/add-profile.svg";
import { ReactComponent as Chat } from "design_system/src/static/icons/chat-circle.svg";
import { ReactComponent as Helmet } from "design_system/src/static/icons/helmet.svg";

import motoLoading from "static/icons/loading_moto.gif";

import { LightTooltip } from "components/hocs/tooltip/Tooltip";
import { Grid } from "@mui/material";

import { getUser } from "helpers/localStorage/authStorage";
import EditOzoner from "views/ozoner/edits/EditOzoner";
import {
    addUserFilter,
    deleteUserFilter,
    getOzonioById,
    getOzonios,
} from "helpers/fetchs/ozonios";
import { FilterSections, UserFilter } from "models/ozonio.interface";
import moment from "moment";
import profileDefaultOzonio from "static/images/ozonio_default.png";
import {
    documentsTypes,
    DocumentTypesEnum,
} from "components/hocs/loadDocuments/loadDocuments";
import { commonErrorHandler } from "helpers/errors/errors";
import { CreditStatus, CreditStatusFlags } from "models/credit.interface";
import SaveFilter from "components/hocs/saveFilter/SaveFilter";
import { toasterNotification } from "helpers/globalNotifications/globalNotifications";
import { toasterOptions } from "enums/toaster.enum";
import { handleEventGoogle } from "helpers/analytics/googleAnalytics";
import SearchBar from "../../components/hocs/searchBar/searchBar";

import ExportSolicitudes from "./ExportSolicitudes";

const createdOnOptions = [
    {
        label: "Pandaboard",
        color: "primary_200",
        value: "pandaboard",
    },
    {
        label: "Webpage",
        color: "yellow_100",
        value: "webpage",
    },
];
const digitalPlatformsOptions = [
    {
        label: "Repartidor",
        value: true,
    },
    {
        label: "No repartidor",
        value: false,
    },
];

const filterOptions = [
    {
        _id: "validation_solicitudes",
        name: "Solicitudes por validar",
        values: {
            statusFilter: [formStatusEnum.inValidation],
        },
        default: true,
    },
    {
        _id: "approved_solicitudes",
        name: "Solicitudes aprobadas",
        values: {
            statusFilter: [formStatusEnum.approved],
        },
        default: true,
    },
];

const requiredDocuments = [
    DocumentTypesEnum.ine_front,
    DocumentTypesEnum.ine_back,
    DocumentTypesEnum.selfie,
    DocumentTypesEnum.bank_certificate_1,
    DocumentTypesEnum.bank_certificate_2,
    DocumentTypesEnum.bank_certificate_3,
    DocumentTypesEnum.proof_of_address,
    DocumentTypesEnum.tax_situation,
];

let numFetch = 0;

const sortOptions = [
    {
        value: "name",
        label: "A-Z",
    },
    {
        value: "-name",
        label: "Z-A",
    },
    {
        value: "-updatedAt",
        label: "Actualizado recientemente",
    },
    {
        value: "createdAt",
        label: "Más antiguos",
    },
    {
        value: "-createdAt",
        label: "Más recientes",
    },
    {
        value: "salesRank",
        label: "Mejor segmentacion",
    },
    {
        value: "-salesRank",
        label: "Peor segmentacion",
    },
];

interface TotalSoli {
    approved: number;
    approvedWithDocuments: number;
    approvedWithoutDocuments: number;
    new: number;
    newWithDocuments: number;
    newWithoutDocuments: number;
    rejected: number;
    rejectedByMax: number;
    rejectedByMaxWithDocuments: number;
    rejectedWithDocuments: number;
    rejectedWithoutDocuments: number;
}

const Solicitudes = () => {
    const [viewType, setViewType] = useState(true);

    const [sortValue, setSortValue] = useState("-createdAt");

    const [result, setResult] = useState();
    const [data, setData] = useState<any[]>([]);
    const [pages, setPages] = useState<number>();
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [loading, setLoading] = useState(false);
    const [query, setQuery] = useState<string>("");
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [openExport, setOpenExport] = useState(false);
    const [count, setCount] = useState(0);

    const navigate = useNavigate();

    // -----------------FILTERS STATES------------------- //
    const [openDetails, setOpenDetails] = useState<boolean>(false);

    const [openConfirmFilter, setOpenConfirmFilter] = useState(false);
    const [callbackConfirm, setCallbackConfirm] = useState<any>();
    const [openOptions, setOpenOptions] = useState("");
    const [personalFilters, setPersonalFilters] = useState<UserFilter[]>([]);
    const [selectedPersonalFilter, setSelectedPersonalFilter] =
        useState<string>("");
    const [openSaveFilter, setOpenSaveFilter] = useState<boolean>(false);
    const [statusFilter, setStatusFilter] = useState<string[]>([]);
    const [createdOnFilter, setCreatedOnFilter] = useState([]);
    const [digitalPlatformsFilter, setDigitalPlatformsFilter] = useState([]);
    const [salesRankFilter, setSalesRankFilter] = useState([]);
    const [sellResponsibleFilter, setSellResponsibleFilter] = useState("");
    const [contactFilter, setContactFilter] = useState("");
    const [datesFilter, setDatesFilter] = useState({
        initial: new Date(),
        last: new Date(),
        option: -1,
    });
    const [hasDatesFilter, setHasDatesFilter] = useState(false);

    const [ozonios, setOzonios] = useState<any[]>([]);
    const [ozoniosPhoto, setOzoniosPhoto] = useState<any>({});

    const [selecteds, setSelecteds] = useState<any[]>([]);
    const [selectedAll, setSelectedAll] = useState(false);
    const [selectedsIds, setSelectedsIds] = useState<string[]>([]);

    const [openSeller, setOpenSeller] = useState(false);
    const [selectedSeller, setSelectedSeller] = useState("");
    const [loadingSeller, setLoadingSeller] = useState(false);

    const [openContact, setOpenContact] = useState(false);
    const [selectedContact, setSelectedContact] = useState("");
    const [loadingContact, setLoadingContact] = useState(false);

    const [openReachSuccess, setOpenReachSuccess] = useState<any>();

    const requestFilters = () => {
        const userL = getUser();
        getOzonioById(userL.userId).then((ozonio) => {
            setPersonalFilters([
                ...filterOptions,
                {
                    _id: "my_solicitudes",
                    name: "Mis Solicitudes",
                    values: {
                        sellResponsibleFilter: userL.userId,
                        statusFilter: [
                            formStatusEnum.pending,
                            formStatusEnum.inValidation,
                            formStatusEnum.rejected,
                            formStatusEnum.approved,
                            formStatusEnum.scheduled,
                        ],
                    },
                    default: true,
                },
                ...(ozonio?.personalFilters?.filter(
                    (fil: UserFilter) =>
                        fil.section === FilterSections.formsList
                ) ?? []),
            ]);
        });
    };
    const changePersonalFilter = (uFilter: UserFilter) => {
        if (uFilter._id !== selectedPersonalFilter) {
            setSelectedPersonalFilter(uFilter._id);
            setStatusFilter(uFilter.values.statusFilter ?? []);
            setCreatedOnFilter(uFilter.values.createdOnFilter ?? []);
            setDigitalPlatformsFilter(
                uFilter.values.digitalPlatformsFilter ?? []
            );
            setSalesRankFilter(uFilter.values.salesRankFilter ?? []);
            setSellResponsibleFilter(
                uFilter.values.sellResponsibleFilter ?? ""
            );
            setContactFilter(uFilter.values.contactFilter ?? "");
            if (uFilter.values.datesFilter) {
                setDatesFilter({
                    ...uFilter.values.datesFilter,
                    initial: new Date(uFilter.values.datesFilter.initial),
                    last: new Date(uFilter.values.datesFilter.last),
                });
                setHasDatesFilter(true);
            } else {
                setHasDatesFilter(false);
            }
        }
    };
    const cleanFilter = () => {
        setCreatedOnFilter([]);
        setDigitalPlatformsFilter([]);
        setSalesRankFilter([]);
        setSellResponsibleFilter("");
        setContactFilter("");
        setHasDatesFilter(false);
        setStatusFilter([]);
        setSelectedPersonalFilter("");
    };
    const deletePersonalFilter = (uFilter: UserFilter) => {
        deleteUserFilter(getUser().userId, uFilter._id).then(() => {
            setSelectedPersonalFilter("");
            requestFilters();
        });
    };

    const confirmChangeFilter = () => {
        const { type, value } = callbackConfirm;
        changePersonalFilter(value);
    };

    const changingFilter = (type: boolean, value: any) => {
        if (
            selectedPersonalFilter ||
            (!createdOnFilter.length &&
                digitalPlatformsFilter.length &&
                sellResponsibleFilter === "" &&
                contactFilter === "" &&
                !statusFilter.length &&
                !salesRankFilter.length &&
                !hasDatesFilter)
        ) {
            changePersonalFilter(value);
        } else {
            setCallbackConfirm({ type, value });
            setOpenConfirmFilter(true);
        }
    };

    const saveFilter = (name: string) => {
        return addUserFilter(getUser().userId, name, FilterSections.formsList, {
            createdOnFilter,
            digitalPlatformsFilter,
            sellResponsibleFilter,
            contactFilter,
            salesRankFilter,
            datesFilter: hasDatesFilter ? datesFilter : undefined,
            statusFilter,
        }).then((e: any) => {
            requestFilters();
            setSelectedPersonalFilter(
                e.personalFilters[e.personalFilters.length - 1]._id
            );
            return e;
        });
    };

    const FetchSolicitud = async (page: number) => {
        try {
            setLoading(true);
            const a = numFetch + 1;
            numFetch += 1;
            const filters = [];

            if (
                createdOnFilter.length &&
                createdOnFilter.length !== createdOnOptions.length
            )
                filters.push(`createdOn=${createdOnFilter}`);
            if (
                digitalPlatformsFilter.length &&
                digitalPlatformsFilter.length !== digitalPlatformsOptions.length
            )
                filters.push(
                    `workInDigitalPlatforms=${digitalPlatformsFilter}`
                );
            if (
                salesRankFilter.length &&
                salesRankFilter.length !== Object.keys(salesRankValues).length
            )
                filters.push(`salesRank=${salesRankFilter}`);
            if (contactFilter !== "")
                filters.push(`contactStatus=${contactFilter}`);
            if (sellResponsibleFilter !== "")
                if (sellResponsibleFilter === "noResponsible") {
                    filters.push("withSeller=false");
                } else if (sellResponsibleFilter === "assignedResponsible") {
                    filters.push("withSeller=true");
                } else {
                    filters.push(`sellResponsible=${sellResponsibleFilter}`);
                }
            if (
                statusFilter.length &&
                statusFilter.length !== Object.keys(FormStatusValues).length
            )
                filters.push(`formStatus=${statusFilter}`);

            if (datesFilter && hasDatesFilter) {
                filters.push(
                    `min=${moment
                        .utc(datesFilter.initial)
                        .format("YYYY-MM-DD")}&max=${moment
                        .utc(datesFilter.last)
                        .format("YYYY-MM-DD")}`
                );
            }

            if (sortValue && sortValue !== "") {
                filters.push(`sort=${sortValue}`);
            }

            const rows = await FetchSolicitudes(
                page,
                searchQuery,
                undefined,
                undefined,
                filters.join("&")
            );
            if (a === numFetch) {
                const datos = rows.data.map((row) => {
                    const itemWeight = 1 / (requiredDocuments.length + 1);
                    const progress: any = {
                        total: 0,
                    };
                    row.documents.forEach((doc: any) => {
                        if (requiredDocuments.includes(doc.typeEvidence)) {
                            progress.total += itemWeight;
                            progress[doc.typeEvidence] = true;
                        }
                    });
                    if (row.personal_references) {
                        progress.total += itemWeight;
                        progress.references = true;
                    }

                    return { ...row, progress };
                });
                setData(datos);
                setPages(rows.pages);
                setCount(rows.count);

                if (currentPage > rows.pages) {
                    setCurrentPage(1);
                }
                setLoading(false);
            }
        } catch (e) {
            setLoading(false);
            //  TODO show error message
        }
    };

    useEffect(() => {
        handleEventGoogle(
            "VIEW_Panda_requests",
            "Panda",
            "page view to the loan requests list view"
        );
        const information = getSolicitudesFilter();
        if (information.searchQuery) {
            setQuery(information.searchQuery);
            setSearchQuery(information.searchQuery);
        }
        if (information.statusFilter) {
            setStatusFilter(information.statusFilter);
        }
        if (information.selectedPersonalFilter) {
            setSelectedPersonalFilter(information.selectedPersonalFilter);
        }
        if (information.createdOnFilter) {
            setCreatedOnFilter(information.createdOnFilter);
        }
        if (information.digitalPlatformsFilter) {
            setDigitalPlatformsFilter(information.digitalPlatformsFilter);
        }
        if (information.salesRankFilter) {
            setSalesRankFilter(information.salesRankFilter);
        }
        if (information.sellResponsibleFilter) {
            setSellResponsibleFilter(information.sellResponsibleFilter);
        }
        if (information.contactFilter) {
            setContactFilter(information.contactFilter);
        }
        if (information.datesFilter) {
            setDatesFilter({
                ...information.datesFilter,
                initial: new Date(information.datesFilter.initial),
                last: new Date(information.datesFilter.last),
            });
            setHasDatesFilter(true);
        }
        setTimeout(() => {
            if (information.currentPage) {
                setCurrentPage(information.currentPage);
            } else {
                setCurrentPage(1);
            }
        }, 10);

        requestFilters();

        getOzonios("").then((ozons) => {
            if (ozons) {
                setOzonios(
                    ozons.data
                        .map((ozonio: any) => ({
                            value: ozonio._id,
                            label: ozonio.name,
                        }))
                        .sort((a: any, b: any) => {
                            if (a.label < b.label) {
                                return -1;
                            }
                            if (a.label > b.label) {
                                return 1;
                            }
                            return 0;
                        })
                );

                setOzoniosPhoto(
                    ozons.data.reduce((acc: any, ozo: any) => {
                        return { ...acc, [ozo._id]: ozo?.image?.url };
                    }, {})
                );
            }
        });
    }, []);

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };
    useEffect(() => {
        setSolicitudesFilter({
            statusFilter,
            searchQuery,
            currentPage,
            createdOnFilter,
            digitalPlatformsFilter,
            salesRankFilter,
            sellResponsibleFilter,
            contactFilter,
            datesFilter: hasDatesFilter ? datesFilter : null,
            selectedPersonalFilter,
        });
        if (currentPage === 1) {
            FetchSolicitud(currentPage);
        } else if (currentPage) {
            handlePageChange(1);
        }
    }, [
        statusFilter,
        searchQuery,
        createdOnFilter,
        digitalPlatformsFilter,
        salesRankFilter,
        sellResponsibleFilter,
        contactFilter,
        datesFilter,
        hasDatesFilter,
        selectedPersonalFilter,
        sortValue,
        result,
    ]);
    useEffect(() => {
        setSolicitudesFilter({
            statusFilter,
            searchQuery,
            currentPage,
            createdOnFilter,
            digitalPlatformsFilter,
            salesRankFilter,
            sellResponsibleFilter,
            contactFilter,
            datesFilter: hasDatesFilter ? datesFilter : null,
            selectedPersonalFilter,
        });
        if (currentPage) {
            FetchSolicitud(currentPage);
        }
    }, [currentPage]);

    const handleRowClick = (row: any) => {
        // eslint-disable-next-line no-underscore-dangle
        return `/ozoner/${row.ozonerId}?formId=${row.idForm}&tab=2`;
    };

    const handleSearch = (event: any) => {
        event.preventDefault();
        setSearchQuery(query);
    };

    const getFormCreditChip = (solicitud: any) => {
        const finishedStatus = [
            formStatusEnum.completed,
            formStatusEnum.expired,
            formStatusEnum.canceled,
            formStatusEnum.rejected,
        ];
        const statuses = solicitud?.creditStatus.filter((status:string) => status !== formStatusEnum.pending) ?? [];	
        if (
            statuses.length &&
            !finishedStatus.includes(solicitud?.statusForm)
        ) {
            let msg;

            if (
                statuses.includes(CreditStatus.writeOff) ||
                statuses.includes(CreditStatus.portfolioSale) ||
                statuses.includes(
                    CreditStatusFlags.isJudicialCollection
                )
            ) {
                msg = "Crédito en writeOff";
            } else if (
                statuses.includes(CreditStatus.repossession)
            ) {
                msg = "Moto recuperada";
            } else if (
                statuses.includes(CreditStatusFlags.isFraud)
            ) {
                msg = "Crédito con fraude";
            } else if (
                statuses.includes(CreditStatus.DPD_30) ||
                statuses.includes(CreditStatus.DPD_60) ||
                statuses.includes(CreditStatus.DPD_90) ||
                statuses.includes(CreditStatus.DPD_120)
            ) {
                msg = "Crédito en mora";
            } else if (
                statuses.includes(CreditStatus.devolution) ||
                statuses.includes(CreditStatus.warranty)
            ) {
                msg = "Moto devuelta";
            } else {
                return (
                    <Typography
                        scale="xxsmall"
                        weight="600"
                        className="br_xs p_x_md p_y_xxxs text_center text_neutral_0 pos_relative bg_green_300_nocontrast"
                    >
                        Segunda Moto
                    </Typography>
                );
            }
            return (
                <Typography
                    scale="xxsmall"
                    weight="600"
                    className="br_xs p_x_md p_y_xxxs text_center text_neutral_0 pos_relative bg_red_300_nocontrast"
                >
                    {msg}
                </Typography>
            );
        }
        return null;
    };

    const goWA = (number: string) =>
        window.open(`https://api.whatsapp.com/send?phone=${number}`, "_blank");

    const select = (row: any) => {
        if (selectedsIds.includes(row.idForm)) {
            setSelecteds(
                selecteds?.filter((sel) => sel.idForm !== row.idForm) ?? []
            );
        } else {
            setSelecteds([...(selecteds ?? []), row]);
        }
    };
    const selectAll = () => {
        if (selectedAll) {
            const deselect = data.map((ele) => ele.idForm);

            setSelecteds(
                selecteds?.filter((ele) => !deselect.includes(ele.idForm)) ?? []
            );
        } else {
            setSelecteds([...(selecteds ?? []), ...data]);
        }
    };

    useEffect(() => {
        const ids = selecteds?.map((ele) => ele.idForm) ?? [];
        setSelectedsIds(ids);
        setSelectedAll(
            data.reduce(
                (acc, row) => acc && ids.includes(row.idForm),
                true
            ) as any
        );
    }, [selecteds, data]);

    const copyNumbers = () => {
        const contentToCopy = selecteds
            .map((solicitud) => `+${solicitud.phone}`)
            .join(",");
        navigator.clipboard
            .writeText(contentToCopy)
            .then(() => {
                toasterNotification({
                    toasterType: toasterOptions.success,
                    msg: "Copiado al portapapeles",
                    style: "light",
                });
            })
            .catch((error) => {
                toasterNotification({
                    toasterType: toasterOptions.error,
                    msg: `Error al copiar el contenido: ${error}`,
                    style: "light",
                });
            });
    };

    const closeSeller = () => {
        setOpenSeller(false);
        setSelectedSeller("");
        setSelecteds([]);
    };
    const assignSeller = () => {
        if (selectedSeller && selectedSeller !== "") {
            setLoadingSeller(true);
            batchSellerAssign(selecteds, selectedSeller)
                .then(() => {
                    closeSeller();
                    setLoadingSeller(false);
                    FetchSolicitud(currentPage);
                    toasterNotification({
                        msg: "Asignacion Exitosa",
                        style: "light",
                        toasterType: toasterOptions.success,
                    });
                })
                .catch((err) => {
                    commonErrorHandler(err);
                    setLoadingSeller(false);
                });
        }
    };

    const closeReachSuccess = () => {
        setOpenReachSuccess(undefined);
    };
    const reachSuccess = () => {
        if (openReachSuccess?.idForm) {
            setLoading(true);
            handleEventGoogle(
                "BTN_Pb_request_reached_success",
                "Panda Solicitudes",
                "click in reach request button"
            );
            editSolicitud(openReachSuccess?.idForm, { reachedSuccess: true })
                .then(() => {
                    closeReachSuccess();
                    FetchSolicitud(currentPage);
                })
                .catch((err) => {
                    commonErrorHandler(err);
                });
        }
    };

    const contact = () => {
        setLoadingContact(true);
        const p = selecteds.map((s) => {
            return editSolicitud(s.idForm, {
                reached: true,
                reachedMethod: selectedContact,
            });
        });

        Promise.all(p)
            .then(() => {
                setSelectedContact("");
                setOpenContact(false);
                FetchSolicitud(currentPage);
                setLoadingContact(false);
            })
            .catch((err) => {
                commonErrorHandler(err);
                setLoadingContact(false);
            });
    };

    return (
        <div className="dim_100_per flex_content_col shadow_hard inner_container bg_neutral_400">
            <div className="flex_header">
                <div className="bg_neutral_0 shadow_hard p_t_xs p_b_md p_x_lg">
                    <div className="display_flex flex_gap_sm flex_align_center">
                        <Typography
                            scale="heading3"
                            weight="600"
                            textColor="neutral_900"
                        >
                            Solicitudes
                        </Typography>
                        {statusFilter.length === 1 ? (
                            <Typography
                                scale="heading3"
                                weight="600"
                                textColor={
                                    (FormStatusValues as any)[statusFilter[0]]
                                        ?.color ?? "primary_300"
                                }
                            >
                                {
                                    (FormStatusValues as any)[statusFilter[0]]
                                        ?.title
                                }
                            </Typography>
                        ) : null}
                        <SearchBar
                            className="flex_grow_1"
                            handleSearch={handleSearch}
                            query={query}
                            setQuery={setQuery}
                            sortOptions={sortOptions}
                            sortValue={sortValue}
                            setsortValue={setSortValue}
                        />
                        <div className="display_flex flex_gap_xs flex_grow_0 flex_align_center">
                            <LightTooltip title="Vista de tabla">
                                <div
                                    className={`selectable_icon ${
                                        viewType ? "active" : ""
                                    }`}
                                    role="button"
                                    tabIndex={0}
                                    onClick={() => setViewType(true)}
                                    onKeyDown={() => setViewType(true)}
                                >
                                    <List className="dim_lg" />
                                </div>
                            </LightTooltip>
                            <LightTooltip title="Vista en cards">
                                <div
                                    className={`selectable_icon ${
                                        !viewType ? "active" : ""
                                    }`}
                                    role="button"
                                    tabIndex={0}
                                    onClick={() => setViewType(false)}
                                    onKeyDown={() => setViewType(false)}
                                >
                                    <Boxes className="dim_lg" />
                                </div>
                            </LightTooltip>
                            <LightTooltip title="Exportar solicitudes">
                                <div
                                    className="selectable_icon"
                                    role="button"
                                    tabIndex={0}
                                    onClick={() => setOpenExport(true)}
                                    onKeyDown={() => setOpenExport(true)}
                                >
                                    <Excel className="dim_lg" />
                                </div>
                            </LightTooltip>
                        </div>
                    </div>
                    <div className="display_flex flex_gap_xl m_b_xs">
                        <div
                            className={`display_flex flex_gap_md flex_grow_1 flex_align_start ${
                                statusFilter.length ? "flex_col" : ""
                            }`}
                        >
                            <div className="display_flex flex_gap_xl flex_align_start">
                                <Select
                                    fixedPlaceholder
                                    variant="checkbox"
                                    placeholder="Estado"
                                    options={Object.keys(FormStatusValues).map(
                                        (key: any) => ({
                                            label: FormStatusValues[
                                                key as formStatusEnum
                                            ].title,
                                            value: key,
                                        })
                                    )}
                                    value={statusFilter}
                                    onChange={(e: any) => {
                                        setStatusFilter(e.target.value);
                                        setSelectedPersonalFilter("");
                                    }}
                                    className="flex_grow_1"
                                />
                                <Select
                                    fixedPlaceholder
                                    variant="checkbox"
                                    placeholder="Origen"
                                    options={createdOnOptions}
                                    value={createdOnFilter}
                                    onChange={(e: any) => {
                                        setCreatedOnFilter(e.target.value);
                                        setSelectedPersonalFilter("");
                                    }}
                                />
                            </div>
                            <div className="display_flex flex_gap_xl flex_align_start">
                                <DateSelect
                                    value={datesFilter}
                                    hasValue={hasDatesFilter}
                                    setHasValue={setHasDatesFilter}
                                    placeholder="Fecha de creación"
                                    setValue={(e: any) => {
                                        setDatesFilter(e);
                                        setHasDatesFilter(true);
                                        setSelectedPersonalFilter("");
                                    }}
                                />
                                <Select
                                    fixedPlaceholder
                                    variant="checkbox"
                                    placeholder="Segmentacíón"
                                    options={Object.keys(salesRankValues).map(
                                        (k) => ({
                                            label: (salesRankValues as any)[k]
                                                .title,
                                            value: k,
                                        })
                                    )}
                                    value={salesRankFilter}
                                    onChange={(e: any) => {
                                        setSalesRankFilter(e.target.value);
                                        setSelectedPersonalFilter("");
                                    }}
                                />
                                <Select
                                    fixedPlaceholder
                                    variant="default"
                                    placeholder="Responsable"
                                    options={[
                                        {
                                            value: "noResponsible",
                                            label: "Sin Responsable",
                                        },
                                        {
                                            value: "assignedResponsible",
                                            label: "Responsable Asignado",
                                        },
                                        ...ozonios,
                                        { value: "", label: "Responsable" },
                                    ]}
                                    value={sellResponsibleFilter}
                                    onChange={(e: any) => {
                                        setSellResponsibleFilter(
                                            e.target.value
                                        );
                                        setSelectedPersonalFilter("");
                                    }}
                                />
                                <Select
                                    fixedPlaceholder
                                    variant="default"
                                    placeholder="Estado contacto"
                                    options={Object.keys(
                                        contactStatusValues
                                    ).map((k) => ({
                                        value: k,
                                        label: contactStatusValues[
                                            k as contactStatusEnum
                                        ],
                                    }))}
                                    value={contactFilter}
                                    onChange={(e: any) => {
                                        setContactFilter(e.target.value);
                                        setSelectedPersonalFilter("");
                                    }}
                                />
                                <Select
                                    variant="checkbox"
                                    placeholder="Repartidor"
                                    options={digitalPlatformsOptions}
                                    value={digitalPlatformsFilter}
                                    onChange={(e: any) => {
                                        setDigitalPlatformsFilter(
                                            e.target.value
                                        );
                                        setSelectedPersonalFilter("");
                                    }}
                                />
                            </div>
                        </div>
                        <div
                            className={`display_flex flex_gap_xs flex_grow_0 flex_justify_between ${
                                statusFilter.length
                                    ? "flex_col flex_align_end"
                                    : "flex_align_center"
                            }`}
                        >
                            <Button
                                variant="ghost"
                                scale="small"
                                icon={<Pin />}
                                onClick={() => setOpenSaveFilter(true)}
                            >
                                Fijar filtro
                            </Button>
                            <Button
                                variant="ghost"
                                scale="small"
                                icon={<Trash />}
                                onClick={() => cleanFilter()}
                                color="grey"
                            >
                                Limpiar Filtro
                            </Button>
                        </div>
                    </div>
                    <div className="display_flex flex_gap_xl overflow_x_auto p_b_md">
                        {personalFilters.map((uFilter) => (
                            <div
                                className={`display_flex flex_gap_sm p_x_md p_y_xs status_tab pos_relative ${
                                    selectedPersonalFilter === uFilter._id
                                        ? "active"
                                        : ""
                                }
              `}
                                key={uFilter._id}
                            >
                                <Typography
                                    scale="small"
                                    weight={
                                        selectedPersonalFilter === uFilter._id
                                            ? "600"
                                            : "400"
                                    }
                                    onClick={() =>
                                        changingFilter(false, uFilter)
                                    }
                                    key={`status-filter-${uFilter._id}`}
                                    className="cursor_pointer "
                                >
                                    {uFilter.name}
                                </Typography>
                                {!uFilter.default && (
                                    <>
                                        <Action
                                            className="dim_md cursor_pointer text_neutral_600"
                                            onClick={() =>
                                                setOpenOptions(uFilter._id)
                                            }
                                        />
                                        <Popup
                                            open={openOptions === uFilter._id}
                                            setOpen={() => setOpenOptions("")}
                                            placementX="right-inset"
                                            className="bg_neutral_100 br_xxs m_r_md"
                                        >
                                            <Typography
                                                scale="xsmall"
                                                weight="400"
                                                className="p_xs border_b_solid border_b_1 border_neutral_400 cursor_pointer menu_selectable"
                                                onClick={() =>
                                                    deletePersonalFilter(
                                                        uFilter
                                                    )
                                                }
                                                onKeyPress={() =>
                                                    deletePersonalFilter(
                                                        uFilter
                                                    )
                                                }
                                                tabIndex={0}
                                                role="button"
                                            >
                                                Eliminar filtro
                                            </Typography>
                                        </Popup>
                                    </>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div
                className={`flex_body ${
                    viewType ? "p_x_lg" : "body_listing"
                } overflow_x_hidden`}
            >
                {loading ? (
                    <div className="flex_center_col dim_100_per">
                        <Typography
                            weight="600"
                            scale="heading4"
                            textColor="neutral_800"
                            className="m_b_xs"
                        >
                            Cargando las solicitudes...
                        </Typography>
                        <img src={motoLoading} alt="" className="h_xxxl" />
                    </div>
                ) : data?.length ? (
                    viewType ? (
                        <ListTable
                            data={data}
                            canSelect
                            rowClassName="br_none text_decoration_none"
                            hideHeader
                            className="m_t_md"
                            to={handleRowClick}
                            ContComponent={Link}
                            selecteds={selecteds}
                            setSelecteds={setSelecteds}
                            selectId="idForm"
                            cols={[
                                {
                                    label: "Nombre",
                                    weight: 4,
                                    render: (row: any) => (
                                        // eslint-disable-next-line no-nested-ternary
                                        <div className="display_flex flex_align_center flex_gap_md w_100_per">
                                            {row?.createdOn === "pandaboard" ? (
                                                <Panda className="dim_md text_primary_300" />
                                            ) : (
                                                <Monitor className="dim_md text_primary_300" />
                                            )}

                                            <div className="flex_grow_1">
                                                <Typography
                                                    scale="medium"
                                                    weight="400"
                                                    textColor="neutral_700"
                                                    className=""
                                                >
                                                    {row?.name || "-"}
                                                </Typography>
                                                <div className="display_flex flex_align_center flex_gap_xs">
                                                    <Typography
                                                        scale="xsmall"
                                                        weight="400"
                                                        textColor="neutral_700"
                                                        className=""
                                                    >
                                                        {row?.curp || "-"}
                                                    </Typography>
                                                    {getFormCreditChip(row)}
                                                </div>
                                            </div>
                                        </div>
                                    ),
                                },
                                {
                                    label: "Progreso",
                                    render: (row: any) => (
                                        <LightTooltip
                                            title={
                                                <div className="display_flex flex_col flex_gap_xs">
                                                    <div className="display_flex flex_gap_sm">
                                                        {row.progress
                                                            .references ? (
                                                            <Success className="dim_sm text_green_300" />
                                                        ) : (
                                                            <Fail className="dim_sm text_red_300" />
                                                        )}
                                                        <Typography
                                                            scale="xsmall"
                                                            weight="400"
                                                            textColor="neutral_700"
                                                            className="text_center flex_1 "
                                                        >
                                                            Referencias
                                                            personales
                                                        </Typography>
                                                    </div>
                                                    {requiredDocuments.map(
                                                        (type) => (
                                                            <div
                                                                className="display_flex flex_gap_sm"
                                                                key={type}
                                                            >
                                                                {row.progress[
                                                                    type
                                                                ] ? (
                                                                    <Success className="dim_sm text_green_300" />
                                                                ) : (
                                                                    <Fail className="dim_sm text_red_300" />
                                                                )}
                                                                <Typography
                                                                    scale="xsmall"
                                                                    weight="400"
                                                                    textColor="neutral_700"
                                                                    className=""
                                                                >
                                                                    {
                                                                        documentsTypes[
                                                                            type
                                                                        ].title
                                                                    }{" "}
                                                                    {
                                                                        documentsTypes[
                                                                            type
                                                                        ]
                                                                            .subtitle
                                                                    }
                                                                </Typography>
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            }
                                        >
                                            <div className="flex_center_col">
                                                <Knob
                                                    value={Math.round(
                                                        (row.progress?.total ??
                                                            0) * 100
                                                    )}
                                                    symbol="%"
                                                    className="dim_xxl border_transparent display_flex"
                                                    color="green_300"
                                                />
                                                <Typography
                                                    scale="xsmall"
                                                    weight="400"
                                                    textColor="neutral_700"
                                                    className="text_center flex_1"
                                                >
                                                    Validaciones
                                                </Typography>
                                            </div>
                                        </LightTooltip>
                                    ),
                                },
                                {
                                    label: "Segmentacion",
                                    weight:2,
                                    render: (row: any) => (
                                        <div className="flex_center flex_gap_xs w_100_per">
                                                <LightTooltip
                                                    title={
                                                        (salesRankValues as any)[
                                                            row.salesRank ??
                                                                salesRankEnum.wood
                                                        ]?.tooltip ?? ""
                                                    }
                                                >
                                            <div className="">
                                                <Typography
                                                    scale="xsmall"
                                                    weight="400"
                                                    className="text_center flex_1"
                                                >
                                                    Segmentación
                                                </Typography>
                                                    <Typography
                                                        scale="medium"
                                                        weight="600"
                                                        className={`text_center flex_1 text_${
                                                            (
                                                                salesRankValues as any
                                                            )[
                                                                row.salesRank ??
                                                                    salesRankEnum.wood
                                                            ].color
                                                        }`}
                                                    >
                                                        {
                                                            (
                                                                salesRankValues as any
                                                            )[
                                                                row.salesRank ??
                                                                    salesRankEnum.wood
                                                            ].title
                                                        }
                                                    </Typography>
                                                </div>
                                                </LightTooltip>
                                                {row.workInDigitalPlatforms?(
                                                    <LightTooltip title="Trabajador de plataformas digitales">
                                                        <Helmet className="dim_lg text_primary_300" />
                                                    </LightTooltip>
                                                ):null}
                                            </div>
                                    ),
                                },
                                {
                                    label: "Contacto",
                                    weight: 2,
                                    render: (row: any) => (
                                        <div>
                                            <Typography
                                                scale="medium"
                                                weight="400"
                                                className="text_center flex_1 text_link"
                                                onClick={(e) => {
                                                    goWA(row.phone);
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                }}
                                            >
                                                {row?.phone || "-"}
                                            </Typography>
                                            <Typography
                                                scale="xsmall"
                                                weight="400"
                                                textColor="neutral_700"
                                                className="text_center flex_1"
                                            >
                                                {row?.reachedSuccess ? (
                                                    <div className="flex_center flex_gap_xs">
                                                        <Typography
                                                            scale="xsmall"
                                                            weight="400"
                                                            textColor="green_300"
                                                            className="text_no_break text_center"
                                                        >
                                                            Contacto exitoso
                                                        </Typography>
                                                        <Check className="text_green_300 dim_md" />
                                                    </div>
                                                ) : row?.reached ? (
                                                    <Button
                                                        scale="small"
                                                        variant="outline"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            setOpenReachSuccess(
                                                                row
                                                            );
                                                        }}
                                                    >
                                                        Ozoner respondió
                                                    </Button>
                                                ) : (
                                                    <Typography
                                                        scale="xsmall"
                                                        weight="400"
                                                        textColor="red_300"
                                                        className="text_no_break text_center"
                                                    >
                                                        Lead sin contactar
                                                    </Typography>
                                                )}
                                            </Typography>
                                        </div>
                                    ),
                                },
                                {
                                    label: "Moto",
                                    render: (row: any) => (
                                        <div className="flex_center">
                                            {row?.vehicle ? (
                                                <div className="flex_center_col">
                                                    <Typography
                                                        scale="medium"
                                                        weight="600"
                                                        textColor="neutral_700"
                                                        className="text_center flex_1"
                                                    >
                                                        {row?.vehicle
                                                            ?.internalId || "-"}
                                                    </Typography>
                                                    <Typography
                                                        scale="xsmall"
                                                        weight="400"
                                                        textColor="neutral_700"
                                                        className="text_center flex_1"
                                                    >
                                                        {row?.vehicle?.brand
                                                            ?.name || "-"}{" "}
                                                        {row?.vehicle?.model
                                                            ?.name || "-"}{" "}
                                                        {row?.vehicle
                                                            ?.cylindersCapacity || "-"}
                                                    </Typography>
                                                </div>
                                            ) : (
                                                <Typography
                                                    scale="medium"
                                                    weight="400"
                                                    textColor="neutral_700"
                                                    className="text_center flex_1"
                                                >
                                                    Sin vehiculo
                                                </Typography>
                                            )}
                                        </div>
                                    ),
                                },
                                {
                                    label: "Estado",
                                    weight: 2,
                                    render: (row: any) => (
                                        <div className="flex_center flex_gap_xs">
                                            <LightTooltip
                                                title={
                                                    row?.sellResponsible
                                                        ?.name ?? "Sin asignado"
                                                }
                                            >
                                                <div
                                                    className="dim_xxl border_solid border_1 border_neutral_500 br_circle overflow_hidden flex_center"
                                                    tabIndex={0}
                                                    role="button"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        e.preventDefault();
                                                        setSelecteds([row]);
                                                        setOpenSeller(true);
                                                        setSelectedSeller(
                                                            row?.sellResponsible
                                                                ?._id ?? ""
                                                        );
                                                    }}
                                                    onKeyDown={(e) => {
                                                        e.stopPropagation();
                                                        e.preventDefault();
                                                        setSelecteds([row]);
                                                        setOpenSeller(true);
                                                        setSelectedSeller(
                                                            row?.sellResponsible
                                                                ?._id ?? ""
                                                        );
                                                    }}
                                                >
                                                    {row?.sellResponsible ? (
                                                        <img
                                                            src={
                                                                ozoniosPhoto[
                                                                    row
                                                                        ?.sellResponsible
                                                                        ?._id
                                                                ] ??
                                                                profileDefaultOzonio
                                                            }
                                                            className="dim_100_per"
                                                            alt=""
                                                        />
                                                    ) : (
                                                        <Profile className="dim_lg text_neutral_700 border_none" />
                                                    )}
                                                </div>
                                            </LightTooltip>
                                            <div className="flex_center_col pos_relative flex_gap_xxs">
                                                <LightTooltip
                                                    title={
                                                        (
                                                            FormStatusValues as any
                                                        )[row?.statusForm]
                                                            ?.tooltip ??
                                                        "Sin informacion"
                                                    }
                                                >
                                                    <div>
                                                        <Typography
                                                            scale="medium"
                                                            weight="600"
                                                            className={`br_xs p_x_md p_y_xxxs text_center text_neutral_0 pos_relative
                                        bg_${
                                            (FormStatusValues as any)[
                                                row?.statusForm
                                            ]?.color
                                        }_nocontrast`}
                                                        >
                                                            {
                                                                (
                                                                    FormStatusValues as any
                                                                )[
                                                                    row
                                                                        ?.statusForm
                                                                ]?.title
                                                            }
                                                        </Typography>
                                                    </div>
                                                </LightTooltip>
                                                {row.statusHistoric?.length ? (
                                                    <Typography
                                                        scale="xsmall"
                                                        weight="400"
                                                        textColor="neutral_700"
                                                        className="text_center flex_1"
                                                    >
                                                        Fecha:{" "}
                                                        {moment(
                                                            row.statusHistoric[
                                                                row
                                                                    .statusHistoric
                                                                    .length - 1
                                                            ].updatedAt
                                                        )
                                                            .utcOffset(-360)
                                                            .format(
                                                                "DD/MM/YY"
                                                            )}{" "}
                                                    </Typography>
                                                ) : null}
                                            </div>
                                        </div>
                                    ),
                                },
                            ]}
                        />
                    ) : (
                        <Grid
                            container
                            direction="row"
                            spacing={3}
                            className="h_100_per p_t_md spaced"
                        >
                            <Grid item xs={12}>
                                <CheckboxInput
                                    className="m_b_none flex_grow_0 m_l_lg"
                                    checked={selectedAll}
                                    value=""
                                    label="Seleccionar todo"
                                    onChange={() => selectAll()}
                                />
                            </Grid>
                            {data.map((solicitud, index) => (
                                <Grid item xs={6} sm={3} md={2} key={index}>
                                    <div
                                        className="dso_card flex_center_col flex_justify_between  bg_neutral_0 dim_100_per p_x_xs p_y_sm cursor_pointer ozoner_card"
                                        onClick={() =>
                                            handleRowClick(solicitud)
                                        }
                                        onKeyPress={() =>
                                            handleRowClick(solicitud)
                                        }
                                        role="button"
                                        tabIndex={0}
                                    >
                                        <div className="flex_center_col w_100_per flex_gap_xxs">
                                            <div
                                                tabIndex={0}
                                                role="button"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                }}
                                                onKeyUp={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                }}
                                            >
                                                <CheckboxInput
                                                    className="m_b_md"
                                                    checked={selectedsIds.includes(
                                                        solicitud.idForm
                                                    )}
                                                    value=""
                                                    label=" Seleccionar"
                                                    onChange={() =>
                                                        select(solicitud)
                                                    }
                                                />
                                            </div>
                                            <LightTooltip
                                                title={`Creado en ${solicitud.createdOn}`}
                                            >
                                                <div className="bg_primary_50_nocontrast text_primary_300 display_flex p_xs br_xs shadow_hard">
                                                    {solicitud.createdOn ===
                                                    "pandaboard" ? (
                                                        <Panda className="dim_md" />
                                                    ) : (
                                                        <Monitor className="dim_md" />
                                                    )}
                                                </div>
                                            </LightTooltip>
                                            <Typography
                                                scale="small"
                                                weight="400"
                                                textColor="neutral_700"
                                                className="text_center flex_1 text_no_break overflow_ellipsis w_100_per"
                                            >
                                                {solicitud?.name || "-"}
                                            </Typography>
                                            <Typography
                                                scale="xsmall"
                                                weight="400"
                                                textColor="neutral_700"
                                                className="text_center flex_1"
                                            >
                                                {solicitud?.curp || "-"}
                                            </Typography>
                                            {getFormCreditChip(solicitud)}
                                            <div className=" flex_1 w_100_per">
                                                <img
                                                    className="w_100_per center_y br_sm moto_img_soli"
                                                    src={
                                                        solicitud.vehicle
                                                            ?.images[0]
                                                            ? solicitud.vehicle
                                                                  ?.images[0]
                                                                  .url
                                                            : `${process.env.REACT_APP_STORAGE_BASE_URL}public/uploads/generic-moto.png`
                                                    }
                                                    alt="vehicle"
                                                />
                                            </div>
                                            {[
                                                formStatusEnum.inValidation,
                                                formStatusEnum.pending,
                                            ].includes(solicitud.statusForm) ? (
                                                <LightTooltip
                                                    title={
                                                        <div className="display_flex flex_col flex_gap_xs">
                                                            <div className="display_flex flex_gap_sm">
                                                                {solicitud
                                                                    .progress
                                                                    .personal_references ? (
                                                                    <Success className="dim_sm text_green_300" />
                                                                ) : (
                                                                    <Fail className="dim_sm text_red_300" />
                                                                )}
                                                                <Typography
                                                                    scale="xsmall"
                                                                    weight="400"
                                                                    textColor="neutral_700"
                                                                    className="text_center flex_1 "
                                                                >
                                                                    Referencias
                                                                    personales
                                                                </Typography>
                                                            </div>
                                                            {requiredDocuments.map(
                                                                (type) => (
                                                                    <div
                                                                        className="display_flex flex_gap_sm"
                                                                        key={
                                                                            type
                                                                        }
                                                                    >
                                                                        {solicitud
                                                                            .progress[
                                                                            type
                                                                        ] ? (
                                                                            <Success className="dim_sm text_green_300" />
                                                                        ) : (
                                                                            <Fail className="dim_sm text_red_300" />
                                                                        )}
                                                                        <Typography
                                                                            scale="xsmall"
                                                                            weight="400"
                                                                            textColor="neutral_700"
                                                                            className=""
                                                                        >
                                                                            {
                                                                                documentsTypes[
                                                                                    type
                                                                                ]
                                                                                    .title
                                                                            }{" "}
                                                                            {
                                                                                documentsTypes[
                                                                                    type
                                                                                ]
                                                                                    .subtitle
                                                                            }
                                                                        </Typography>
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>
                                                    }
                                                >
                                                    <div className="flex_center flex_gap_md">
                                                        <Typography
                                                            scale="xsmall"
                                                            weight="400"
                                                            textColor="neutral_700"
                                                            className="text_center flex_1"
                                                        >
                                                            Validaciones:
                                                        </Typography>
                                                        <Knob
                                                            value={Math.round(
                                                                (solicitud
                                                                    .progress
                                                                    ?.total ??
                                                                    0) * 100
                                                            )}
                                                            symbol="%"
                                                            className="dim_xxxl border_transparent display_flex"
                                                            color="green_300"
                                                        />
                                                    </div>
                                                </LightTooltip>
                                            ) : (
                                                <div />
                                            )}
                                            <Typography
                                                scale="small"
                                                weight="400"
                                                className="text_center flex_1 text_link"
                                                onClick={(e) => {
                                                    goWA(solicitud.phone);
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                }}
                                            >
                                                {solicitud?.phone || "-"}
                                            </Typography>
                                            <Typography
                                                scale="xsmall"
                                                weight="400"
                                                textColor="neutral_700"
                                                className="text_center flex_1"
                                            >
                                                Creación:{" "}
                                                {moment(solicitud.createdAt)
                                                    .utcOffset(-360)
                                                    .format("DD/MM/YY")}{" "}
                                            </Typography>
                                            <Typography
                                                scale="medium"
                                                weight="600"
                                                textColor="neutral_700"
                                                className="text_center flex_1"
                                            >
                                                {solicitud?.vehicle
                                                    ?.internalId || "-"}
                                            </Typography>
                                            <Typography
                                                scale="small"
                                                weight="400"
                                                textColor="neutral_700"
                                                className="text_center flex_1"
                                            >
                                                {solicitud?.vehicle?.brand
                                                    ?.name || "-"}{" "}
                                                {solicitud?.vehicle?.model
                                                    ?.name || "-"}{" "}
                                                {solicitud?.vehicle
                                                    ?.cylindersCapacity || "-"}
                                            </Typography>
                                            <Typography
                                                scale="medium"
                                                weight="600"
                                                textColor="neutral_900"
                                                className="text_center flex_1"
                                            >
                                                $
                                                {solicitud?.vehicle
                                                    ?.salePrices &&
                                                solicitud?.creditTime
                                                    ? Math.round(
                                                          solicitud.vehicle
                                                              .salePrices[
                                                              solicitud
                                                                  ?.creditTime
                                                          ]?.paymentWeek
                                                      )
                                                    : "--"}
                                            </Typography>
                                            <LightTooltip
                                                title={
                                                    (FormStatusValues as any)[
                                                        solicitud?.statusForm
                                                    ]?.tooltip ??
                                                    "Sin informacion"
                                                }
                                            >
                                                <div>
                                                    <Typography
                                                        scale="medium"
                                                        weight="600"
                                                        className={`br_xs p_x_md p_y_xxxs text_center text_neutral_0 pos_relative
                                        bg_${
                                            (FormStatusValues as any)[
                                                solicitud?.statusForm
                                            ]?.color
                                        }_nocontrast`}
                                                    >
                                                        {
                                                            (
                                                                FormStatusValues as any
                                                            )[
                                                                solicitud
                                                                    ?.statusForm
                                                            ]?.title
                                                        }
                                                    </Typography>
                                                </div>
                                            </LightTooltip>
                                            {solicitud.statusHistoric
                                                ?.length ? (
                                                <Typography
                                                    scale="xsmall"
                                                    weight="400"
                                                    textColor="neutral_700"
                                                    className="text_center flex_1"
                                                >
                                                    Fecha:{" "}
                                                    {moment(
                                                        solicitud
                                                            .statusHistoric[
                                                            solicitud
                                                                .statusHistoric
                                                                .length - 1
                                                        ].updatedAt
                                                    )
                                                        .utcOffset(-360)
                                                        .format(
                                                            "DD/MM/YY"
                                                        )}{" "}
                                                </Typography>
                                            ) : null}
                                        </div>
                                    </div>
                                </Grid>
                            ))}
                        </Grid>
                    )
                ) : (
                    <div className="flex_center dim_100_per">
                        <Typography
                            scale="heading4"
                            weight="600"
                            className="text_neutral_900 m_xl text_center"
                        >
                            Tus filtros no generaron resultados
                        </Typography>
                    </div>
                )}
            </div>

            <div className="display_flex flex_align_center flex_justify_between flex_footer bg_neutral_0 p_x_xxxl p_y_md">
                {selecteds.length > 0 ? (
                    <div className="display_flex flex_gap_md">
                        <Button
                            scale="small"
                            icon={<Clip />}
                            onClick={copyNumbers}
                        >
                            Copiar numeros de telefono
                        </Button>
                        <Button
                            scale="small"
                            icon={<Person />}
                            onClick={() => setOpenSeller(true)}
                        >
                            Asignar vendedor
                        </Button>
                        <Button
                            scale="small"
                            icon={<Chat />}
                            onClick={() => setOpenContact(true)}
                            disabled={
                                !selecteds.reduce(
                                    (acc, form) => acc && !form.reachedSuccess,
                                    true
                                )
                            }
                        >
                            Registrar contacto
                        </Button>
                    </div>
                ) : (
                    <Button
                        scale="small"
                        icon={<AddCircle />}
                        className="w_30_per"
                        onClick={() => setOpenDetails(true)}
                    >
                        Crear nueva Solicitud
                    </Button>
                )}
                <Pager
                    page={currentPage}
                    totalPages={pages || 1}
                    setPage={handlePageChange}
                    count={count}
                    className="pager_solicitudes "
                />
            </div>
            {(openSaveFilter || openConfirmFilter) && (
                <SaveFilter
                    open={openSaveFilter}
                    setOpen={setOpenSaveFilter}
                    callback={saveFilter}
                    openConfirm={openConfirmFilter}
                    setOpenConfirm={setOpenConfirmFilter}
                    callbackConfirm={confirmChangeFilter}
                />
            )}
            {openExport && (
                <ExportSolicitudes open={openExport} setOpen={setOpenExport} />
            )}
            <EditOzoner
                open={openDetails}
                setOpen={setOpenDetails}
                setResult={setResult}
            />

            {openSeller && (
                <ModalConfirmation
                    openConfirmation={openSeller}
                    handleClose={closeSeller}
                    icon={<Person className="text_primary_300" />}
                    widthDesktop={40}
                    isConfirmationLoading={false}
                    heightDesktop={80}
                    actions={[
                        {
                            label: "Volver",
                            color: "grey",
                            action: closeSeller,
                        },
                        {
                            label: "Asignar vendedor",
                            color: "primary",
                            action: () => assignSeller(),
                            disabled:
                                !selectedSeller ||
                                selectedSeller === "" ||
                                loadingSeller,
                        },
                    ]}
                >
                    <>
                        <Typography
                            scale="large"
                            weight="600"
                            className="m_b_xs"
                        >
                            Asignar vendedor
                        </Typography>
                        <Typography
                            scale="medium"
                            weight="400"
                            className="m_b_md"
                            textColor="neutral_700"
                        >
                            Selecciona el vendedor al que se le{" "}
                            {selecteds.length === 1
                                ? "va a asignar la solicitud"
                                : `van a asignar ${selecteds.length} solicitudes`}
                        </Typography>
                        <Input
                            title=""
                            name="Responsable de la entrega"
                            placeholder="Ingresa el Ozonio"
                            value={selectedSeller}
                            onChange={(e: any) =>
                                setSelectedSeller(e.target.value)
                            }
                            className="w_100_per"
                            type="select"
                            options={ozonios}
                            variant="default"
                        />
                    </>
                </ModalConfirmation>
            )}
            {openSeller && (
                <ModalConfirmation
                    openConfirmation={openSeller}
                    handleClose={closeSeller}
                    icon={<Person className="text_primary_300" />}
                    widthDesktop={40}
                    isConfirmationLoading={false}
                    heightDesktop={80}
                    actions={[
                        {
                            label: "Volver",
                            color: "grey",
                            action: closeSeller,
                        },
                        {
                            label: "Asignar vendedor",
                            color: "primary",
                            action: () => assignSeller(),
                            disabled:
                                !selectedSeller ||
                                selectedSeller === "" ||
                                loadingSeller,
                        },
                    ]}
                >
                    <>
                        <Typography
                            scale="large"
                            weight="600"
                            className="m_b_xs"
                        >
                            Asignar vendedor
                        </Typography>
                        <Typography
                            scale="medium"
                            weight="400"
                            className="m_b_md"
                            textColor="neutral_700"
                        >
                            Selecciona el vendedor al que se le{" "}
                            {selecteds.length === 1
                                ? "va a asignar la solicitud"
                                : `van a asignar ${selecteds.length} solicitudes`}
                        </Typography>
                        <Input
                            title=""
                            name="Responsable de la entrega"
                            placeholder="Ingresa el Ozonio"
                            value={selectedSeller}
                            onChange={(e: any) =>
                                setSelectedSeller(e.target.value)
                            }
                            className="w_100_per"
                            type="select"
                            options={ozonios}
                            variant="default"
                        />
                    </>
                </ModalConfirmation>
            )}
            {openContact && (
                <ModalConfirmation
                    openConfirmation={openContact}
                    handleClose={() => {
                        setSelectedContact("");
                        setOpenContact(false);
                    }}
                    icon={<Chat className="text_primary_300" />}
                    widthDesktop={40}
                    isConfirmationLoading={false}
                    heightDesktop={80}
                    actions={[
                        {
                            label: "Volver",
                            color: "grey",
                            action: () => {
                                setSelectedContact("");
                                setOpenContact(false);
                            },
                        },
                        {
                            label: "Reportar contacto",
                            color: "primary",
                            action: () => contact(),
                            disabled:
                                !selectedContact ||
                                selectedContact === "" ||
                                loadingContact,
                        },
                    ]}
                >
                    <>
                        <Typography
                            scale="large"
                            weight="600"
                            className="m_b_xs"
                        >
                            Reportar contacto
                        </Typography>
                        <Typography
                            scale="medium"
                            weight="400"
                            className="m_b_md"
                            textColor="neutral_700"
                        >
                            Selecciona el metodo de contacto que se hizo
                        </Typography>

                        <Input
                            title=""
                            name="reachedMethod"
                            placeholder="Metodo de contacto"
                            value={selectedContact}
                            onChange={(e: any) =>
                                setSelectedContact(e.target.value)
                            }
                            className="w_100_per"
                            type="select"
                            options={Object.keys(reachedMethodsValues).map(
                                (method) => ({
                                    label: reachedMethodsValues[
                                        method as reachedMethodsEnum
                                    ],
                                    value: method,
                                })
                            )}
                            variant="default"
                        />
                    </>
                </ModalConfirmation>
            )}
            {!!openReachSuccess && (
                <ModalConfirmation
                    openConfirmation={!!openReachSuccess}
                    handleClose={closeReachSuccess}
                    icon={<Chat className="text_primary_300" />}
                    widthDesktop={40}
                    isConfirmationLoading={false}
                    actions={[
                        {
                            label: "Volver",
                            color: "grey",
                            action: closeReachSuccess,
                        },
                        {
                            label: "Marcar respuesta",
                            color: "primary",
                            action: () => reachSuccess(),
                        },
                    ]}
                    title={
                        <Typography
                            scale="medium"
                            weight="600"
                            className="m_b_md"
                            textColor="neutral_700"
                        >
                            ¿Esta seguro que desea marcar que se contesto esta
                            solicitud?
                        </Typography>
                    }
                    subtitle={
                        <Typography
                            scale="medium"
                            weight="400"
                            className="m_b_md"
                            textColor="neutral_700"
                        >
                            Una vez se marque como contacto exitoso se puede
                            continuar con el proceso para llegar a validacion
                        </Typography>
                    }
                />
            )}
        </div>
    );
};

export default Solicitudes;
