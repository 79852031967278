import { TDataFinancialForm } from "./financialForm.interface";
import { TVehicle } from "./vehicle.interface";

export interface TFullAddress {
  cp: number,
  delegation: string,
  extNumber: string,
  intNumber: string,
  neighborhood: string,
  state: string,
  street: string,
  lat: number,
  long:number,
}

export interface TDataozoner {
  internalId: string;
  fullAddress?: TFullAddress;
  city: any;
  createdOn: string;
  truoraId?: string;
  palencaId?: string;
  truoraData?: any[];
  palencaData?: any;
  createdAt: string;
  documents?: any;
  _id: string;
  name: string;
  phone: string;
  email: string;
  address: string;
  curp: string;
  workInDigitalPlatforms: boolean;
  paymentDay: number;
  social_network: string;
  paymentMethod: string;
  status: string;
  comment?: string;
  image?: any;
  financialForm: TDataFinancialForm[];
  statusHistoric: any[];
  porcobrarId?: string;

}


export interface TEditDataozoner {
  longitude: number;
  latitude: number;
  createdAt: string;
  name: string;
  phone: string;
  email: string;
  curp: string;
  cp: string;
  state: string;
  delegation: string;
  neighborhood: string;
  street: string;
  extNumber: string;
  intNumber?: string;
  city?: string;
}

export interface TRequestozoner {
  totals: any;
  count: number;
  pages: number;
  page: number;
  data: TDataozoner[];
}

export interface IOzonerTotals {
  totals: { [key: string]: number };
}

// eslint-disable-next-line no-shadow
export enum statusAgreements {
  accomplished = "accomplished",
  failed = "failed",
  onCourse = "onCourse",
}

export const statusAgreementsValues = {
  [statusAgreements.accomplished]: {
    title: "Pagado",
    color: "primary_300"
  },
  [statusAgreements.failed]: {
    title: "Fallido",
    color: "red_300"
  },
  [statusAgreements.onCourse]: {
    title: "En curso",
    color: "transparent"
  }
};


// eslint-disable-next-line no-shadow
export enum statusOzonerEnum {
  inactive = "inactive",
  active = "active",
  lead = "lead",
}
export const statusOzoners = {
  lead: {
    title: "Lead",
    color: "yellow_100",
  },
  active: {
    title: "Activo",
    color: "green_300",
  },
  inactive: {
    title: "Inactivo",
    color: "neutral_700",
  },
};

export interface ozonerSearchGroups {
  _id: string;
  status: string;
  email: string;
  name: string;
  curp: string;
  subStatus: string;
}

export const ozonerFields = {
  name: "Nombre",
  phone: "Teléfono",
  email: "Correo electrónico",
  address: "Dirección",
  password: "Contraseña",
  roles: "Roles",
  financialForm: "Formulario financiero",
  geolocations: "Geolocalizaciones",
  city: "Ciudad",
  currentHashedRefreshToken: "Token de actualización",
  curp: "CURP",
  palencaVerification: "Verificación de Palenca",
  truoraVerification: "Verificación de Truora",
  paymentDay: "Día de pago",
  paymentMethod: "Método de pago",
  status: "Estado",
  subStatus: "Subestado",
  vehicle: "Vehículo",
  image: "Imagen",
  porcobrarId: "ID por cobrar",
  createdAt: "Fecha de creación",
  statusHistoric: "Histórico de estado",
  documents: "Documentos",
  comment: "Comentario",
  createdOn: "Creado en",
  truoraId: "ID de Truora",
  palencaId: "ID de Palenca",
  truoraData: "Datos de Truora",
  palencaData: "Datos de Palenca",
  internalId: "ID interno",
  social_network: "Red social",
  lastChangeBy: "Último cambio realizado por",
};

