import { deletes, get, post, put } from "../axios/axiosInstance";
import { toasterNotification } from "../globalNotifications/globalNotifications";
import { toasterOptions } from "../../enums/toaster.enum";

export interface FetchSolicitudesResponse {
  count: number;
  pages: number;
  page: number;
  totals: any;
  data: any[];
}
// TODO:Soli interface

export const exportarSolicitudes = async (
  pages?: string,
  params?: string,
  limit?: number,
  city?: string,
): Promise<any> => {
  try {
    let path = "v1/financial-form/export?";
    if (pages) {
      path = `${path}&pages=${pages}`;
    }
    if (limit) {
      path = `${path}&limit=${limit}`;
    }
    if (city) {
      path = `${path}&city=${city}`;
    }

    if (params) {
      path = `${path}&${params}`;
    }


    const res = await get(path);
    return res.data;
  } catch (err: any) {
    throw err.response.data;
  }
};
export const FetchSolicitudes = async (
  page: number,
  query?: string,
  status?: string,
  order?: string,
  aditionalQuery?: string,
  city?: string,
): Promise<FetchSolicitudesResponse> => {
  try {
    
    const res = await get<FetchSolicitudesResponse>(
      /* eslint no-underscore-dangle: 0 */
      `/v1/financial-form?page=${page}${
        status ? `&formStatus=${status}` : ""
      }${query ? `&query=${query}` : ""}${order ? `&sort=${order}` : ""}${city ? `&city=${city}` : ""}&${
        aditionalQuery ?? ""
      }`
    );
    return res.data;
  } catch (err: any) {
    toasterNotification({
      msg: "Ocurrio un error al cargar los datos",
      style: "light",
      toasterType: toasterOptions.error,
    });
    throw err.response.data;
  }
};

export const createCredit = async (idOzoner: string, idForm: string, idVehicle: string): Promise<any> => {
  try {
    const res = await post("v1/credit/createPending", { idVehicle, idForm, idOzoner });
    return res;
  } catch (err: any) {
    throw err.response.data.message;
  }
};


export const FetchSolicitud = async (id: string): Promise<any> => {
  try {
    const res = await get<any>(`/v1/financial-form/${id}`);
    return res.data;
  } catch (err: any) {
    throw err.response.data;
  }
};
export const editSolicitud = async (id: string, data: any): Promise<any> => {
  try {
    const res = await put<any>(`/v1/financial-form/${id}`, data);
    return res.data;
  } catch (err: any) {
    throw err.response.data;
  }
};
export const createSolicitud = async (idOzoner: string, data: any): Promise<any> => {
  try {
    const res = await post<any>(`/v1/financial-form/PB/${idOzoner}`, data);
    return res.data;
  } catch (err: any) {
    throw err.response.data;
  }
};


export const scheduleFinancialForm = async (idOzoner: string, idForm:string, data: any): Promise<any> => {
  try {
    const res = await post<any>(`/v1/financial-form/${idOzoner}/schedule-appointment/${idForm}`, data);
    return res.data;
  } catch (err: any) {
    throw err.response.data;
  }
};

export const cancelScheduleFinancialForm = async (idOzoner: string, idForm:string): Promise<any> => {
  try {
    const res = await post<any>(`/v1/financial-form/${idOzoner}/cancel-appointment/${idForm}`);
    return res.data;
  } catch (err: any) {
    throw err.response.data;
  }
};


export const deleteFileForm = async (
  idSolicitud: string,
  urlFile: string
): Promise<any> => {
  try {
    const res = await deletes(
      `v1/financial-form/${idSolicitud}/document/${urlFile}`
    );
    return res.data;
  } catch (err: any) {
    throw err.response.data.message;
  }
};

export const addFileForm = async (
  form: string,
  file: File,
  type: string = "other"
): Promise<any> => {
  try {
    const data = new FormData();
    data.append("name", file.name);
    data.append("file", file);

const res = await put(`v1/financial-form/${form}/document/${type}`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return res.data;
  } catch (err: any) {
    throw err.response.data;
  }
};

export const getFiles = async (id: string): Promise<any> => {
  try {
    const res = await get(`v1/financial-form/${id}/download-documents`, {
      responseType: "arraybuffer",
      responseEncoding: "binary",
    });
    return res.data;
  } catch (err: any) {
    throw err.response.data.message;
  }
};

export const updateAdvancedMoney = async (
  financialFormId: string,
  advancedMoneyAmount: number
): Promise<any> => {
  try {
    const res = await put(
      `v1/financial-form/${financialFormId}/advancedmoney`,
      {
        advancedMoney: advancedMoneyAmount,
      }
    );
    return res.data;
  } catch (err: any) {
    throw err.response.data.message;
  }
};
export const batchSellerAssign = async (
  solicitudes: any[],
  seller: string
): Promise<any> => {
  try {

    const ozonersId=solicitudes.reduce((acc,solicitud)=>{
      if(!(solicitud._id in acc) )
        acc[solicitud._id]=[];
      acc[solicitud._id].push(solicitud.idForm);
      return acc;
    },{});

    const arrayIds = Object.keys(ozonersId).map(ozonerId=>({id:ozonerId,financialFormIds:ozonersId[ozonerId]}));

    const res = await post(
      "v1/sales/batch/assign-seller",
      {
        sellerId: seller,
        ozoners:arrayIds
      }
    );
    return res.data;
  } catch (err: any) {
    throw err.response.data.message;
  }
};
