/* eslint-disable dot-notation */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from "react";
import palenca from "static/images/truora/palenca.png";
import { ModalConfirmation as Modal, Typography, DataTable } from "design_system/src";
import moment from "moment";

import "./palenca.scss";

const platforms: any = {
  ifood: {
    name: "iFood",
    logo: "https://s3-us-west-2.amazonaws.com/palenca/platforms/logo_0cd47f3d-ecfa-4f91-9b78-c14561347ad8-1647033600.jpg",
    type: "gig"
  },
  indriver: {
    name: "inDriver",
    logo: "https://s3-us-west-2.amazonaws.com/palenca/platforms/logo_db608db0-66ff-491b-b1bf-ec4df492a9cf-1647013508.jpg",
    type: "gig"
  },
  picap: {
    name: "Picap",
    logo: "https://s3-us-west-2.amazonaws.com/palenca/platforms/logo_dbc5b538-1e44-4069-bbf4-8749ba086ae0-1647033950.jpg",
    type: "gig"
  },
  noventa_nove: {
    name: "99 Motorista",
    logo: "https://s3-us-west-2.amazonaws.com/palenca/platforms/logo_57ff8f66-1641-4fa1-b65f-005b3d477174-1647357176.jpg",
    type: "gig"
  },
  clip: {
    name: "Clip",
    logo: "https://s3-us-west-2.amazonaws.com/palenca/platforms/logo_e4ecc73d-0184-4dff-b03f-b794d4ed86cd-1657059346.png",
    type: "employment"
  },
  runa: {
    name: "Runa",
    logo: "https://s3-us-west-2.amazonaws.com/palenca/platforms/logo_828e094a-403b-44ed-8f39-fe88b233e1d2-1648155806.jpg",
    type: "employment"
  },
  cabify: {
    name: "Cabify",
    logo: "https://s3-us-west-2.amazonaws.com/palenca/platforms/logo_2e9c05ce-dc8e-4425-b995-032012a26765-1647032334.jpg",
    type: "gig"
  },
  pemex: {
    name: "Pemex",
    logo: "https://s3-us-west-2.amazonaws.com/palenca/platforms/logo_ebd71029-a38d-4761-a8f6-407a740958dd-1650923326.jpg",
    type: "employment"
  },
  mensajeros_urbanos: {
    name: "Mensajeros Urbanos",
    logo: "https://s3-us-west-2.amazonaws.com/palenca/platforms/logo_f9f405cf-895c-41d8-bda5-fcd353be21b8-1647032641.jpg",
    type: "gig"
  },
  imss: {
    name: "IMSS",
    logo: "https://s3-us-west-2.amazonaws.com/palenca/platforms/logo_5120f8f2-1e3c-49c5-bd6f-b06b9efb92b9-1647023928.jpg",
    type: "employment"
  },
  uber: {
    name: "Uber",
    logo: "https://s3-us-west-2.amazonaws.com/palenca/platforms/logo_c203c5ef-043a-4cf2-a2ca-12ab97c0b24a-1647034068.jpg",
    type: "gig"
  },
  rappi: {
    name: "Rappi",
    logo: "https://s3-us-west-2.amazonaws.com/palenca/platforms/logo_6b5c3e38-35e7-4dfb-98d1-f09294c992c8-1647033701.jpg",
    type: "gig"
  },
  issste: {
    name: "ISSSTE",
    logo: "https://s3-us-west-2.amazonaws.com/palenca/platforms/logo_c72b9628-96b5-4e4e-bfcf-e7a3be9d117f-1648155995.jpg",
    type: "employment"
  },
  didi_food: {
    name: "Didi Repartidor",
    logo: "https://s3-us-west-2.amazonaws.com/palenca/platforms/logo_e425ebc5-0809-47fc-9044-7c717edb45b7jpg",
    type: "gig"
  },
  cornershop: {
    name: "Cornershop",
    logo: "https://s3-us-west-2.amazonaws.com/palenca/platforms/logo_dff23c57-3ab7-4ec9-9216-be3e09d8a067-1647032407.jpg",
    type: "gig"
  },
  uber_eats: {
    name: "Uber Eats",
    logo: "https://s3-us-west-2.amazonaws.com/palenca/platforms/logo_64024ae2-1c36-4931-8951-c56e8965a0b4-1647034126.jpg",
    type: "gig"
  },
  pedidos_ya: {
    name: "PedidosYa",
    logo: "https://s3-us-west-2.amazonaws.com/palenca/platforms/logo_1cad6f07-3447-46a9-a32a-9ed4ede35bb4jpg",
    type: "gig"
  },
  grupo_tress: {
    name: "Grupo Tress",
    logo: "https://palenca.s3.us-west-2.amazonaws.com/platforms/a707ebac-96de-4d87-9852-df8876cc1ebf.jpg",
    type: "employment"
  },
  beat: {
    name: "Beat",
    logo: "https://s3-us-west-2.amazonaws.com/palenca/platforms/logo_fe325844-27a5-4f3a-b272-396ecf62e4a9-1647033206.jpg",
    type: "gig"
  },
  didi: {
    name: "DiDi Conductor",
    logo: "https://s3-us-west-2.amazonaws.com/palenca/platforms/logo_a9189331-5104-45d7-9997-2292a76418fa-1647033838.jpg",
    type: "gig"
  },
  osmos: {
    name: "Osmos",
    logo: "https://s3-us-west-2.amazonaws.com/palenca/platforms/logo_02a099dc-31a5-427d-a762-fe93586c1692-1656613407.jpg",
    type: "employment"
  }
};

interface PalencaProps {
  open: boolean;
  setOpen: Function;
  palencaData: any;
  getPalencaLink:any;
}

function PalencaDetails({
  open,
  setOpen,
  palencaData,
  getPalencaLink
}: PalencaProps) {
  const [stepPalenca, setStepPalenca] = useState<number>(0);
  const [availablePlatforms, setAvailablePlatforms] = useState<any>([]);
  useEffect(() => {
    if (palencaData) {
      const data: any = {};
      Object.keys(palencaData).forEach(key => { data[palencaData[key].account?.platform] = palencaData[key]; });
      setAvailablePlatforms(Object.values(palencaData));
      setStepPalenca(stepPalenca ?? 0);
    }
  }, [palencaData]);

  const renderEmployment = (data: any) => {
    const overview = [
      { label: "Plataforma", value: platforms[data.account?.platform]?.name ?? data.account?.platform },
      { label: "Ultima Actualizacion", value: moment(data.account?.last_successful_connection).format("D MMM YYYY hh:mm") ?? "--" },
      { label: "Nombres", value: data.profile?.profile.first_name ?? "--" },
      { label: "Apellidos", value: data.profile?.profile.last_name ?? "--" },
      { label: "Fecha de nacimiento", value: moment(data.profile?.profile.birthday).format("D MMM YYYY") ?? "--" },
      { label: "Estado de Validacion", value: data.account?.status === "success" ? "Exitosa" : "Fallida", valueColor: data.account?.status === "success" ? "green_300" : "red_300" },
    ];
    const ids = data.profile?.ids_info?.reduce((acc: any[], val: any) => [...acc, ...[{
      label: "Tipo de ID", value: val.name ?? "--"
    }, {
      label: "numero de ID", value: val.value ?? "--"
    }]], []) ?? [];

    const employments = data.employment?.employment_history?.reduce((acc: any[], employment: any) => [...acc, {
      title: employment.employer,
      data: [
        { label: "Salario Mensual", value: employment.monthly_salary ?? "--" },
        { label: "Salario Base", value: employment.base_salary ?? "--" },
        { label: "Fecha de inicio", value: moment(employment.start_date).format("D MMM YYYY") ?? "--" },
      ]
    }], []) ?? [];


    return (
      <>
        <DataTable
          title="Resumen"
          data={overview}
          className="m_b_lg"
        />
        <DataTable
          title="Informacion de Identificaciones"
          data={ids}
        />
        <Typography scale="large" weight="600" className="m_t_lg">Historial de empleo</Typography>
        {employments.map((employment: any) => (
          <DataTable
            title={employment.title}
            data={employment.data}
            className="m_t_lg"
          />
        ))}
      </>
    );
  };
  const renderCourier = (data: any) => {
    const overview = [
      { label: "Plataforma", value: platforms[data.account?.platform].name ?? data.account?.platform },
      // { label: "Añadido", value: moment(data.account?.platform).format("D MMM YYYY hh:mm") ?? "--" },
      { label: "Ultima Actualizacion", value: moment(data.account?.last_successful_connection).format("D MMM YYYY hh:mm") ?? "--" },
      { label: "Nombres", value: data.profile?.profile?.first_name ?? "--" },
      { label: "Apellidos", value: data.profile?.profile?.last_name ?? "--" },
      { label: "Telefono", value: data.profile?.profile?.phone ?? "--" },
      { label: "Email", value: data.profile?.profile?.email ?? "--" },
      { label: "Estado de Validacion", value: data.account?.status === "success" ? "Exitosa" : "Fallida", valueColor: data.account?.status === "success" ? "green_300" : "red_300" },
    ];
    const bank = [
      { label: "Numero de cuenta", value: data.profile?.bank_info?.account_number ?? "--" },
    ];

    const vehicle = [
      { label: "Tipo", value: data.profile?.vehicle_info?.type ?? "--" },
      { label: "Marca", value: data.profile?.vehicle_info?.brand ?? "--" },
      { label: "Modelo", value: data.profile?.vehicle_info?.model ?? "--" },
      { label: "Año", value: data.profile?.vehicle_info?.year ?? "--" },
      { label: "Placa", value: data.profile?.vehicle_info?.plate ?? "--" },
      { label: "VIN", value: data.profile?.vehicle_info?.vin ?? "--" },
    ];
    const metrics = [
      { label: "Tasa de aceptacion", value: data.profile?.metrics_info?.acceptance_rate ?? "--" },
      { label: "Calificacion", value: data.profile?.metrics_info?.rating ?? "--" },
      { label: "Nivel", value: data.profile?.metrics_info?.level_name ?? "--" },
    ];

    const earnings = data.earnings?.earnings.filter((earn:any)=>earn.amount>0).map((earn: any) => ({ label: moment(earn.earning_date).format("D MMM YYYY"), value: earn.amount ? `${earn.amount} ${earn.currency}` : "--" })) ?? [];

    return (
      <>
        <DataTable
          title="Resumen"
          data={overview}
          className="m_b_lg"
        />
        <DataTable
          title="Informacion Bancaria"
          data={bank}
          className="m_b_lg"
        />
        <DataTable
          title="Informacion del vehiculo"
          data={vehicle}
          className="m_b_lg"
        />
        <DataTable
          title="Metricas"
          data={metrics}
        />
        {earnings.length? (<DataTable
          title="Ganancias"
          data={earnings}
          className="m_t_lg"
        />):(<div className="display_none"/>)}
      </>
    );
  };

  return open && palencaData && availablePlatforms.length ? (
    <Modal
      openConfirmation={open}
      handleClose={() => { setOpen(false); }}
      icon={<img src={palenca} alt="truora icon" />}
      isConfirmationLoading={false}
      widthDesktop={60}
      actions={[
        { label: "Cerrar Palenca", color: "grey", action: () => { setOpen(false); } },
        { label: "Copiear Link Palenca", color: "green", action: () => { getPalencaLink(); } }
      ]}
    >
      <>
        <div className="palenca_options flex_gap_md">
          {availablePlatforms.map((data:any,i:number) => (
            <div className={`br_sm p_y_sm p_x_md bg_neutral_0 border_1 border_solid ${stepPalenca === i ? "border_secondary_600" : ""} cursor_pointer display_flex flex_gap_xs flex_align_center`}
              role="button"
              tabIndex={0}
              onClick={() => setStepPalenca(i)}
              onKeyPress={() => setStepPalenca(i)}
            >
              <div className="dim_xl br_circle overflow_hidden">
                <img src={platforms[data.account?.platform]?.logo} className="h_xl" alt="" />
              </div>
              <Typography scale="medium" weight={stepPalenca === i ?"600":"400"}>
                {platforms[data.account?.platform]?.name ?? data.account?.platform}
              </Typography>
            </div>
          ))}
        </div>
        {platforms[availablePlatforms[stepPalenca].account?.platform]?.type === "gig" && (
          renderCourier(availablePlatforms[stepPalenca])
        )}
        {platforms[availablePlatforms[stepPalenca].account?.platform]?.type === "employment" && (
          renderEmployment(availablePlatforms[stepPalenca])
        )}
      </>
    </Modal>
  ) : (<div/>);
}

export default PalencaDetails;
