/* eslint-disable no-unused-vars */
import { createTheme, PaletteColorOptions } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface CustomPalette {
    anger: PaletteColorOptions;
    customBlack: PaletteColorOptions;
    customYellow: PaletteColorOptions;
  }
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}
}

declare module "@mui/material/Button" {
  // eslint-disable-next-line no-shadow
  interface ButtonPropsColorOverrides {
    anger: true;
    customBlack: true;
    customYellow: true;
  }
}

export const index = createTheme({
  palette: {
    primary: {
      main: "#F28E18",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#FFFFFF",
      dark: "#E5E5E5",
      light: "#FF8B00",
    },
    anger: {
      main: "#DF2040",
      dark: "#DF2040",
      light: "#DF2040",
    },
    customBlack: {
      main: "#232323",
      dark: "#232323",
      light: "#232323",
    },
    customYellow: {
      main: "#ECC026",
      dark: "#ECC026",
      light: "#ECC026",
    },
  },
  typography: {
    fontFamily: "Poppins",
    fontSize: 16,
    button: {
      textTransform: "none",
      whiteSpace: "nowrap",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 360,
      md: 600,
      lg: 1450,
      xl: 1800,
    },
  },
});
