/* eslint-disable no-nested-ternary */
import React, { useState, useContext } from "react";

// -----------------LIBRARIES IMPORTS------------------- //
// @ts-ignore
import { Button, Typography } from "design_system/src";

// -----------------DATA IMPORTS------------------- //

import { handleEventGoogle } from "helpers/analytics/googleAnalytics";

// -----------------STATIC IMPORTS------------------- //
import "../../../ozoner.scss";

// -----------------COMPONENTS IMPORTS------------------- //
import { Grid } from "@mui/material";
import { economicActivityEnum } from "models/financialForm.interface";
import { toasterNotification } from "helpers/globalNotifications/globalNotifications";
import { toasterOptions } from "enums/toaster.enum";
import { editSolicitud } from "helpers/fetchs/solicitudes";
import { ozonerContext } from "../../OzonerContext";

function SolicitudOtherInfo({isCredit=false}:({isCredit?:boolean})) {
    const { financialForm, setResult } = useContext(ozonerContext);



    return (
                <Grid container direction="row" spacing={isCredit?0:1} className="">

            <Grid item xs={isCredit?12:4} className={isCredit?"":"p_md"}>
                <div className="br_xxs border_1 border_neutral_400 border_solid ">
                    <div className="bg_primary_25 p_x_md p_y_xs border_b_solid border_1 border_neutral_400">
                        <Typography scale="medium" weight="600">
                            Trabajo
                        </Typography>
                    </div>
                    <div className="p_x_md p_y_lg border_b_solid border_1 border_neutral_400 ">
                        <Typography
                            scale="medium"
                            weight="400"
                            className="m_b_xs"
                        >
                            Ocupación
                        </Typography>
                        <Typography scale="medium" weight="600">
                            {financialForm?.form?.economicActivity?.value === economicActivityEnum.otro?financialForm?.form?.economicActivity?.description :financialForm?.form?.economicActivity?.value}
                        </Typography>
                    </div>
                    <div className="p_x_md p_y_lg border_b_solid border_1 border_neutral_400 ">
                        <Typography
                            scale="medium"
                            weight="400"
                            className="m_b_xs"
                        >
                            Empresa
                        </Typography>
                        <Typography scale="medium" weight="600">
                            {financialForm?.form?.companyName}
                        </Typography>
                    </div>
                </div>
            </Grid>
            <Grid item xs={isCredit?12:4} className={isCredit?"":"p_md"}>
                <div className="br_xxs border_1 border_neutral_400 border_solid ">
                    <div className="bg_primary_25 p_x_md p_y_xs border_b_solid border_1 border_neutral_400">
                        <Typography scale="medium" weight="600">
                            Finanzas Personales
                        </Typography>
                    </div>
                    <div className="p_x_md p_y_lg border_b_solid border_1 border_neutral_400 ">
                        <Typography
                            scale="medium"
                            weight="400"
                            className="m_b_xs"
                        >
                            Numero de hijos
                        </Typography>
                        <Typography scale="medium" weight="600">
                            {financialForm?.form?.childrenCount} hijos
                        </Typography>
                    </div>
                    <div className="p_x_md p_y_lg border_b_solid border_1 border_neutral_400 ">
                        <Typography
                            scale="medium"
                            weight="400"
                            className="m_b_xs"
                        >
                            Dependientes Económicos
                        </Typography>
                        <Typography scale="medium" weight="600">
                            {financialForm?.form?.dependantsCount} Dependientes
                        </Typography>
                    </div>
                </div>
            </Grid>
            <Grid item xs={isCredit?12:4} className={isCredit?"":"p_md"}>
                <div className="br_xxs border_1 border_neutral_400 border_solid h_100_per">
                    <div className="bg_primary_25 p_x_md p_y_xs border_b_solid border_1 border_neutral_400">
                        <Typography scale="medium" weight="600">
                            Activos
                        </Typography>
                    </div>
                    <div className="p_x_md p_y_xs">
                        <Typography scale="medium" weight="600">
                            {financialForm?.form?.assets?.joinedAssets ??
                                "Ninguno"}
                        </Typography>
                    </div>
                </div>
            </Grid>
            </Grid>

    );
}
export default SolicitudOtherInfo;
