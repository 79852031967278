import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Stack } from "@mui/material";
import { Typography } from "design_system/src";

type LoaderIndicatorProps = {
  msg?: boolean;
};

const LoaderIndicator: React.FC<LoaderIndicatorProps> = ({ msg = true }) => {
  return (
    <Stack alignItems="center">
      <CircularProgress />
      {msg && (
        <Typography scale="small" weight="600">
          Cargando...
        </Typography>
      )}
    </Stack>
  );
};

export default LoaderIndicator;
