/* eslint-disable dot-notation */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from "react";


// icons
import { ReactComponent as Filter } from "design_system/src/static/icons/filter-descending.svg";
import { ReactComponent as Pin } from "design_system/src/static/icons/pin.svg";


import { Input, Typography, ModalConfirmation } from "design_system/src";
import { toasterNotification } from "helpers/globalNotifications/globalNotifications";
import { toasterOptions } from "enums/toaster.enum";



interface SaveFilterProps {
  open: boolean;
  setOpen: Function;
  callback: Function;
  openConfirm?: boolean;
  setOpenConfirm?: Function;
  callbackConfirm?: Function;
}

let petition: number;

function SaveFilter({
  open,
  setOpen,
  callback,
  openConfirm,
  setOpenConfirm,
  callbackConfirm,
}: SaveFilterProps) {
  const [filter, setFilter] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const saveFilter = () => {
    setLoading(true);
    callback(filter)
      .then(() => {
        setLoading(false);
        toasterNotification({
          msg: "Se guardo el filtro personalizado",
          toasterType: toasterOptions.success,
          style: "light",
        });
        setOpen(false);
      })
      .catch(() => {
        toasterNotification({
          style: "light",
          msg: "Ocurrio un error",
          toasterType: toasterOptions.error,
        });
        setLoading(false);
      });
  };

  return (
    <>
      <ModalConfirmation
        openConfirmation={openConfirm??false}
        handleClose={() => {
          setOpenConfirm?.(false);
        }}
        icon={<Filter />}
        isConfirmationLoading={false}
        widthDesktop={40}
        actions={[
          {
            label: "Cambiar de filtro",
            color: "grey",
            action: () => {
              setOpenConfirm?.(false);
              callbackConfirm?.();
            },
          },
          {
            label: "Guardar filtro actual",
            color: "primary",
            action: () => {
              setOpenConfirm?.(false);
              setOpen(true);
            },
            loading,
          },
        ]}
        title={
          <Typography scale="large" weight="400">
            ¿Estás seguro de que{" "}
            <Typography scale="large" weight="600" component="span">
              deseas cambiar a otro filtro?
            </Typography>
          </Typography>
        }
        subtitle={
          <Typography scale="medium" weight="400" textColor="neutral_700">
            Perderas los filtros actuales que tienes ahora
          </Typography>
        }
      />

      <ModalConfirmation
        openConfirmation={open}
        handleClose={() => {
          setOpen(false);
        }}
        icon={<Filter />}
        isConfirmationLoading={false}
        widthDesktop={40}
        actions={[
          { label: "Cancelar", color: "grey", action: () => setOpen(false) },
          {
            label: "Fijar filtro",
            color: "primary",
            action: saveFilter,
            loading,
          },
        ]}
      >
        <>
          <Typography
            scale="large"
            weight="600"
            textColor="neutral_1000"
            className="m_b_sm"
          >
            Fijar filtro
          </Typography>
          <Typography
            scale="medium"
            weight="400"
            textColor="neutral_700"
            className="m_b_sm"
          >
            Al fijar un filtro podrás acceder a el a manera de Tab
          </Typography>
          <Input
            type="text"
            name="name"
            title="Nombre del nuevo filtro"
            icon={<Pin />}
            placeholder="Que nombre quieres darle a tu filtrado"
            className="w_100_per bg_white"
            value={filter}
            onChange={(e: any) => setFilter(e.target.value)}
          />
        </>
      </ModalConfirmation>
    </>
  );
}

export default SaveFilter;
