import React, { useEffect, useState } from "react";
import {
  Input, Typography,
  ModalConfirmation as Modal,
  Button,
} from "design_system/src";
import { ReactComponent as Sad } from "design_system/src/static/icons/call-center-sad.svg";
import { ReactComponent as Happy } from "design_system/src/static/icons/call-center-happy.svg";
import { ReactComponent as Focus } from "design_system/src/static/icons/lightbulb-creative.svg";

import * as yup from "yup";
import { Formik, Form } from "formik";

import CreatableSelect from "react-select/creatable";
import {
  setupMessageColor,
  toCamelCase,
} from "../../../../helpers/collectionNews/utils";



// "nice" | "regular" | "bad";
const buttonOptions = [
  {
    id: 1,
    type: "bad",
    icon: <Sad className="text_red_300 border_transparent" />,
    color: "red_300"
  },
  {
    id: 2,
    type: "regular",
    icon: <Happy className="text_yellow_100 border_transparent" />,
    color: "yellow_100"
  },
  {
    id: 3,
    type: "nice",
    icon: <Happy className="text_green_300 border_transparent" />,
    color: "green_300"
  },
];

const validationSchema = yup.object().shape({

  content: yup.string(),
  comment: yup.string().required("Campo requerido"),
  status: yup.object().required(),
  result: yup.string().required(),
});
const defaultsCollection = {
  id: null,
  content: "",
  comment: "",
  status: "",
  result: "regular",
};

interface Option {
  readonly label: string;
  readonly value: string;
}

const createOption = (label: string, value: string) => ({
  label,
  value,
});

const defaultOptions = [
  createOption("Primera Llamada", "firstCall"),
  createOption("Segunda Llamada", "secondCall"),
  createOption("Llamada_ref1", "ref1Call"),
  createOption("Llamada_ref2", "ref2Call"),
];

const commentOptions = [
  "Contesta",
  "No Contesta",
  "Buzón",
  "Contesta otra persona",
  "Fuera de servicio",
  "Cuelga llamada",
  "Equivocado",
  "Restricción llamadas",
  "No enlazada",
  "No existe",
  "Contesta WhatsApp",
  "Ocupado",
  "Número Suspendido",
  "Correo",
];


type CollectionModalProps = {
  collectionNewId?: string;
  setOpenAgreement?: any;
  editCollection?: any;

  openCollection: boolean;
  setOpenCollection: any;
  isLoading: boolean;
  save: any
};

const CollectionModal: React.FC<
  CollectionModalProps
> = ({
  collectionNewId,
  setOpenAgreement,
  editCollection,

  openCollection,
  setOpenCollection,
  isLoading,
  save
}) => {



    const [isInputLoading, setIsInputLoading] = useState(false);
    const [options, setOptions] = useState(defaultOptions);
    const [collection, setCollection] = useState(defaultsCollection);

    const [value, setValue] = useState<Option | null>();
    const [textAreaValue, setTextAreaValue] = useState("");
    const [selectedButton, setSelectedButton] = useState<string | undefined>(
      undefined
    );

    const handleCreate = (inputValue: string) => {
      setIsInputLoading(true);
      setTimeout(() => {
        const newOption = createOption(inputValue, toCamelCase(inputValue));
        setIsInputLoading(false);
        setOptions((prev) => [...prev, newOption]);
        setValue(newOption);
      }, 1000);
    };

    const handleChange = (e: any) => {
      setTextAreaValue(e.target.value);
    };



    useEffect(() => {
      if (collectionNewId) {
        setCollection({ ...editCollection, status: createOption(editCollection.status, editCollection.status)});
      }
    }, [collectionNewId]);

    return (
      <Formik
        validateOnMount
        enableReinitialize
        initialValues={collection}
        onSubmit={() => { }}
        validationSchema={validationSchema}
      >
        {(formik: any) => (
          <Form>
            <Modal
              widthDesktop={40}
              icon={<Focus className="text_primary_300" />}
              handleClose={() => setOpenCollection(false)}
              openConfirmation={openCollection}
              isConfirmationLoading={isLoading}
              actions={[
                {
                  label: "Cancelar",
                  color: "grey",
                  action: () => setOpenCollection(false),
                },
                {
                  label: collectionNewId
                    ? "Modificar novedad"
                    : "Crear novedad",
                  color: "primary",
                  isSubmit: true,
                  action: () => save(formik.values),
                  disabled: !formik.isValid,
                },
              ]}
            >
              <>

                <Typography scale="large" weight="600" className="m_b_md">
                  {collectionNewId ? "Editar" : "Añadir"} novedad
                </Typography>
                <Typography scale="small" weight="400" className="m_b_lg">
                  Registra toda llamada o conversación con el ozoner de manera simple y
                  dinámica
                </Typography>
                <Typography scale="small" weight="600" textColor="neutral_700" className="m_b_sm ">
                  Estado de comunicación:
                </Typography>

                <CreatableSelect
                  placeholder="Selecciona un estado o crea uno"
                  formatCreateLabel={(inputText) => `Crear: "${inputText}"`}
                  isClearable
                  isDisabled={isInputLoading}
                  isLoading={isInputLoading}
                  onChange={(e: any) => {
                    formik.setFieldValue("status", e);
                  }}
                  onCreateOption={handleCreate}
                  options={options}
                  value={formik.values.status}
                  className="m_b_md"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#FFD8AB",
                      primary: "#FE8A02",
                    },
                  })}
                />
                <Input
                  title="Observación"
                  name="comment"
                  placeholder="Que estado tuvo la comunicacion"
                  type="select"
                  className="m_b_xs w_100_per"
                  value={formik.values.comment}
                  onChange={formik.handleChange}
                  options={commentOptions}
                />
                <Typography scale="small" weight="600" textColor="neutral_700" className="m_t_lg">
                  Comentarios:
                </Typography>

                <Input
                  title=""
                  name="content"
                  placeholder="Escribe un pequeño reporte de como te fue en la comunicación con el ozoner"
                  type="textarea"
                  className="m_b_xs w_100_per"
                  value={formik.values.content}
                  onChange={formik.handleChange}
                  inputClassName="h_100_px"
                />
                {!collectionNewId && setOpenAgreement ? (
                  <>
                    <Typography
                      scale="small"
                      weight="600"
                      className="text_center m_t_lg m_b_lg"
                      textColor="neutral_700"
                    >
                      ¿Hubo algún acuerdo de pago?
                    </Typography>
                    <Button
                      onClick={()=>setOpenAgreement(true) }
                      className="w_100_per m_b_md"
                      scale="small"
                      variant="tint"
                    >
                      Crear acuerdo de pago
                    </Button>
                  </>
                ) : null}

                <Typography scale="small" weight="600" textColor="neutral_700" className=" m_y_lg text_center">
                  ¿Como le fué a Ozon en esta ocasión?
                </Typography>
                <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                  {buttonOptions.map((option, index) => (
                    <div
                      className={`br_circle p_xs cursor_pointer display_flex ${formik.values.result === option.type ? `bg_primary_25 border_1 border_solid border_${option.color}` : "bg_neutral_0"}`}
                      role="button"
                      tabIndex={0}
                      onClick={() => formik.setFieldValue("result", option.type)}
                      onKeyDown={() => formik.setFieldValue("result", option.type)}
                    >
                      {option.icon}
                    </div>
                  ))}
                </div>
              </>

            </Modal>
          </Form>
        )}
      </Formik>

    );
  };

export default CollectionModal;
