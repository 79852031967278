/* eslint-disable no-nested-ternary */
/* eslint-disable dot-notation */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from "react";
import { Formik, Form, FormikValues, FormikHelpers } from "formik";
import * as yup from "yup";
import Swal from "sweetalert2";

// icons
import { ReactComponent as Close } from "design_system/src/static/icons/close-circle.svg";
import { ReactComponent as User } from "design_system/src/static/icons/add-profile.svg";
import { ReactComponent as Stars } from "design_system/src/static/icons/stars-profile.svg";
import { ReactComponent as Phone } from "design_system/src/static/icons/numeric-password.svg";
import { ReactComponent as GPS } from "design_system/src/static/icons/gps.svg";
import { ReactComponent as Settings } from "design_system/src/static/icons/user-setting.svg";

import {
    Button,
    Input,
    Typography,
    SideModal,
    ModalConfirmation,
} from "design_system/src";

import { addDevice, deleteDevice } from "helpers/fetchs/devices";
import { DeviceBrands } from "models/devices.interface";
// css
import "./devices.scss";
import { useNavigate } from "react-router-dom";
import {
    addOzonio,
    deleteOzonio,
    getOzonioRoles,
} from "helpers/fetchs/ozonios";
import { Role } from "models/ozonio.interface";
import { toasterNotification } from "helpers/globalNotifications/globalNotifications";
import { toasterOptions } from "enums/toaster.enum";

const validationSchema = yup.object({
    imei: yup.string().required("Campo requerido"),
    brand: yup.string().required("Campo requerido"),
    iccid: yup.string().required("Campo requerido"),
    phone: yup.string().required("Campo requerido"),
    sn: yup.string().required("Campo requerido"),
    simBrand: yup.string().required("Campo requerido"),
});

const defaults = {
    _id: "",
    brand: "",
    iccid: "",
    phone: "",
    sn: "",
    simBrand: "",
};
const brandOptions = ["TELCEL PREPAGO", "EMNIFY"];

interface EditProps {
    open: boolean;
    setOpen: Function;
    setResult: Function;
    values?: any | null;
    disabled?: boolean;
}

function EditDevice({
    open,
    setOpen,
    values = defaults,
    setResult,
    disabled = false,
}: EditProps) {
    const [initial, setInitial] = useState<any>(defaults);
    const [loading, setLoading] = useState<boolean>(false);
    const [openDelete, setOpenDelete] = useState(false);

    useEffect(() => {
        setInitial(values ?? defaults);
    }, [values]);

    const deleteO = () => {
        if (values["_id"]) {
            deleteDevice(values["_id"]).then(() => setResult());
            setOpen(false);
            setOpenDelete(false);
        }
    };

    const onSubmit = (vals: FormikValues, formik: FormikHelpers<any>) => {
        setLoading(true);

        addDevice(vals)
            .then(async (ele) => {
                setOpen(false);
                setResult(ele);
                setInitial(defaults);
                formik.resetForm();
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
                toasterNotification({
                    msg: e ?? "Ocurrio un error",
                    toasterType: toasterOptions.error,
                    style: "light",
                });
            });
    };

    return (
        <>
            <SideModal open={open} setOpen={setOpen} size="small">
                <Formik
                    validateOnMount
                    enableReinitialize
                    initialValues={initial}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                >
                    {(formik) => (
                        <Form className="flex_content_col">
                            <Typography
                                scale="heading4"
                                weight="600"
                                textColor="neutral_900"
                                className="text_center flex_header m_b_xl  "
                            >
                                {disabled
                                    ? "Informacion del Dispositivo"
                                    : values?.["_id"]
                                    ? "Editar Dispositivo"
                                    : "Nuevo Dispositivo"}
                            </Typography>

                            <div className="flex_body pretty_slider overflow_x_visible overflow_y_auto">
                                <Input
                                    disabled={disabled}
                                    title="Teléfono"
                                    placeholder="Escribe el teléfono aquí"
                                    name="phone"
                                    type="text"
                                    icon={<Phone />}
                                    className="m_b_xs w_100_per"
                                    value={formik.values.phone}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        !!formik.errors["phone"] &&
                                        formik.getFieldMeta("phone").touched
                                    }
                                    subtitle={
                                        formik.getFieldMeta("phone").touched
                                            ? (formik.errors["phone"] as string)
                                            : undefined
                                    }
                                />
                                <Input
                                    disabled={disabled}
                                    title="ICCID"
                                    placeholder="Escribe el ICCID aquí"
                                    name="iccid"
                                    type="text"
                                    icon={<GPS />}
                                    className="m_b_xs w_100_per"
                                    value={formik.values.iccid}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        !!formik.errors["iccid"] &&
                                        formik.getFieldMeta("iccid").touched
                                    }
                                    subtitle={
                                        formik.getFieldMeta("iccid").touched
                                            ? (formik.errors["iccid"] as string)
                                            : undefined
                                    }
                                />
                                <Input
                                    disabled={disabled || values?.vehicle}
                                    title="IMEI"
                                    placeholder="Escribe el IMEI aquí"
                                    name="imei"
                                    type="text"
                                    icon={<GPS />}
                                    className="m_b_xs w_100_per"
                                    value={formik.values.imei}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        !!formik.errors["imei"] &&
                                        formik.getFieldMeta("imei").touched
                                    }
                                    subtitle={
                                        formik.getFieldMeta("imei").touched
                                            ? (formik.errors["imei"] as string)
                                            : undefined
                                    }
                                />
                                <Input
                                    disabled={disabled}
                                    title="SN"
                                    placeholder="Escribe el SN aquí"
                                    name="sn"
                                    type="text"
                                    icon={<GPS />}
                                    className="m_b_xs w_100_per"
                                    value={formik.values.sn}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        !!formik.errors["sn"] &&
                                        formik.getFieldMeta("sn").touched
                                    }
                                    subtitle={
                                        formik.getFieldMeta("sn").touched
                                            ? (formik.errors["sn"] as string)
                                            : undefined
                                    }
                                />
                                <Input
                                    disabled={disabled}
                                    title="Operador Telefónico"
                                    placeholder="Escribe el operador telefónico aquí"
                                    name="simBrand"
                                    type="select"
                                    icon={<GPS />}
                                    className="m_b_xs w_100_per"
                                    value={formik.values.simBrand}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    options={brandOptions}
                                    error={
                                        !!formik.errors["simBrand"] &&
                                        formik.getFieldMeta("simBrand").touched
                                    }
                                    subtitle={
                                        formik.getFieldMeta("simBrand").touched
                                            ? (formik.errors[
                                                  "simBrand"
                                              ] as string)
                                            : undefined
                                    }
                                />
                                <Input
                                    disabled={disabled}
                                    title="Marca GPS"
                                    placeholder="Selecciona la marca"
                                    name="brand"
                                    type="select"
                                    options={[
                                        DeviceBrands.Coban4G,
                                        DeviceBrands.Seeworld,
                                    ]}
                                    icon={<Stars />}
                                    className="m_b_xs w_100_per"
                                    value={formik.values.brand}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        !!formik.errors["brand"] &&
                                        formik.getFieldMeta("brand").touched
                                    }
                                    subtitle={
                                        formik.getFieldMeta("brand").touched
                                            ? (formik.errors["brand"] as string)
                                            : undefined
                                    }
                                />
                            </div>
                            {!disabled && (
                                <div className="flex_footer">
                                    <Button
                                        scale="large"
                                        orientation="right"
                                        className="w_100_per"
                                        type="submit"
                                        disabled={
                                            loading ||
                                            !formik.isValid ||
                                            !formik.dirty
                                        }
                                    >
                                        Guardar
                                    </Button>
                                    <Button
                                        scale="small"
                                        orientation="right"
                                        variant="ghost"
                                        icon={<Close />}
                                        className={`m_b_xs w_100_per text_red_300 ${
                                            formik.values["_id"]
                                                ? ""
                                                : "display_none"
                                        }`}
                                        onClick={() => setOpenDelete(true)}
                                    >
                                        Eliminar Dispositivo
                                    </Button>
                                </div>
                            )}
                        </Form>
                    )}
                </Formik>
            </SideModal>
            <ModalConfirmation
                openConfirmation={openDelete}
                handleClose={() => {
                    setOpenDelete(false);
                }}
                icon={<Close />}
                isConfirmationLoading={false}
                widthDesktop={40}
                actions={[
                    {
                        label: "Cancelar",
                        color: "grey",
                        action: () => {
                            setOpenDelete(false);
                        },
                    },
                    {
                        label: "Eliminar dispositivo",
                        color: "primary",
                        action: () => {
                            deleteO();
                        },
                        loading,
                    },
                ]}
                title={
                    <Typography scale="large" weight="400">
                        ¿Estás seguro de que{" "}
                        <Typography scale="large" weight="600" component="span">
                            deseas eliminar el dispositivo?
                        </Typography>
                    </Typography>
                }
                subtitle={
                    <Typography
                        scale="medium"
                        weight="400"
                        textColor="neutral_700"
                    >
                        Se perderan todos los datos del mismo
                    </Typography>
                }
            />
        </>
    );
}

export default EditDevice;
