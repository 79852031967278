/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-plusplus */
import React, {
    useEffect,
    useState,
    createContext,
    useMemo,
    useContext,
} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { handleEventGoogle } from "helpers/analytics/googleAnalytics";

// -----------------LIBRARIES IMPORTS------------------- //
import Swal from "sweetalert2";
import {
    Button,
    Input,
    Knob,
    ModalConfirmation as Modal,
    ModalConfirmation as Confirm,
    SideModal,
    Typography,
} from "design_system/src";
import { Divider, Grid, Switch } from "@mui/material";
import { Form, Formik } from "formik";
import moment from "moment";
import "moment/locale/es";
import * as yup from "yup";
import { isArray } from "lodash";
import {
    getGroupNotificationsById,
    toasterNotification,
} from "helpers/globalNotifications/globalNotifications";
import { toasterOptions } from "enums/toaster.enum";
import { LightTooltip } from "components/hocs/tooltip/Tooltip";

// -----------------DATA IMPORTS------------------- //
import { priceFormatFinancial, priceMXNTotal } from "helpers/prices/prices";
import { getOzonioById, getOzonios } from "helpers/fetchs/ozonios";
import {
    createCredit,
    editSolicitud,
    FetchSolicitud,
} from "helpers/fetchs/solicitudes";
import { TCredit } from "models/credit.interface";
import {
    statusAgreements,
    statusOzonerEnum,
    statusOzoners,
    TDataozoner,
} from "models/ozoner.interface";
import {
    addFile,
    addOzonerAgreements,
    cloneOzoner,
    deleteOzoner,
    editOzoner,
    getOzonerAgreements,
    getOzonerById,
    getOzonerByVehicle,
    getOzonerCredits,
    getOzonerNotifications,
    getOzonerNotificationsCount,
    getOzonerPalenca,
    getOzonerTruora,
    setOzonerAgreements,
    validateTruora,
} from "helpers/fetchs/ozoners";

// -----------------STATIC IMPORTS------------------- //
import "../ozoner.scss";
import { ReactComponent as ApproveIcon } from "design_system/src/static/icons/verified-user.svg";
import { ReactComponent as ContactIcon } from "design_system/src/static/icons/phone.svg";
import profileDefaultOzoner from "static/images/default_profile_ozoner.png";
import { advanceMoneyStatusEnum } from "enums/advanceMoneyStatus.enum";
import {
    ActivateCredit,
    FetchCreditByOzonerId,
    FetchCreditRepossession,
    FetchCreditTotals,
} from "helpers/fetchs/credits";
import CustomFile from "models/customFile";
import { RepossessionStatus } from "models/repossession.interface";
import { formStatusEnum } from "models/financialForm.interface";
import { getCurrentDate } from "helpers/dates";
import { displayQuota } from "./DetailsOzoner";

export const ozonerContext = createContext<any>({});

const truoraFiles = ["ine_front", "ine_back", "selfie"];

export const partnershipSegmentationOptions = [
    "Sin Alianza",
    "Didi",
    "Uber",
];
export const creditSegmentationOptions = [
    "Clientes generales",
    "Go Nuts",
    "Walmart",
];
export const collectionSegmentationOptions = [
    "Clientes generales",
    "Nuevas Compras",
    "WO 2023",
    "Reestructuracion",
    "Reestructurado",
    "Clientes 15 intereses",
    "Clientes 30 Intereses",
];

export const OzonerContextProvider = ({ children }: { children: any }) => {
    const location = useLocation();
    const navigate = useNavigate();

    // -----------------LOGIC STATES------------------- //
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingModal, setLoadingModal] = useState<boolean>(false);
    const [id, setId] = useState<string>();
    // -----------------INITIAL STATES------------------- //
    const [ozoner, setOzoner] = useState<TDataozoner | null>();
    const [indexForm, setIndexForm] = useState<number>(0);
    const [formId, setFormId] = useState<string>();
    const [financialForms, setFinancialForms] = useState<any[]>([]);

    // -----------------OPEN STATES------------------- //
    const [openVehicle, setOpenVehicle] = useState<boolean>(false);

    // -----------------MODAL STATES------------------- //
    const [modalOption, setModalOption] = useState("");
    const [openModal, setOpenModal] = useState<boolean>(false);

    // -----------------ACTIONS STATES------------------- //
    const [ozonioReceipt, setOzonioReceipt] = useState("");
    const [ozonioReached, setOzonioReached] = useState("");
    const [ozonioActive, setOzonioActive] = useState("");
    const [ozonioLastChange, setOzonioLastChange] = useState("");
    const [ozonioContract, setOzonioContract] = useState("");
    const [ozonioComplete, setOzonioComplete] = useState<any>("");
    const [ozonioStatusSolicitud, setOzonioStatusSolicitud] = useState("");

    // -----------------PLATFORMS STATES------------------- //
    const [syncPlatforms, setSyncPlatforms] = useState(false);
    const [validTruora, setValidTruora] = useState(false);

    // -----------------CREDIT STATES------------------- //
    const [selectedCreditTime, setSelectedCreditTime] = useState<any>("");
    const [creditOptions, setCreditOptions] = useState<any>();
    const [openCreditChange, setOpenCreditChange] = useState<boolean>(false);

    // -----------------DOCUMENTS STATES------------------- //
    const [selfie, setSelfie] = useState<any>(profileDefaultOzoner);
    const [validCurp, setValidCurp] = useState(false);
    const [canValidateCurp, setCanValidateCurp] = useState(false);

    // -----------------VEHICLE STATES------------------- //
    const [maxSalePrice, setMaxSalePrice] = useState<any>(undefined);

    // -----------------SOLICITUD STATES------------------- //
    const [hasCredit, setHasCredit] = useState(false);

    // -----------------Norificaciones STATES------------------- //
    const [notificationsCount, setNotificationsCount] = useState<any>({
        textmsg: 0,
        email: 0,
        whatsapp: 0,
    });
    const [notifications, setNotifications] = useState<any[] | undefined>();
    // -----------------AGREEMENTS STATES------------------- //
    const [agreements, setAgreements] = useState<any>(null);
    const [agreementsHist, setAgreementsHist] = useState<any[]>([]);

    const [ozonios, setOzonios] = useState({});
    const [ozoniosList, setOzoniosList] = useState<any[]>([]);


    /*
     * AdvancedMoney states
     * */
    const [initialAdvanceMoney, setInitialAdvanceMoney] = useState<
        number | undefined
    >(undefined);
    const [advanceMoneyStatus, setAdvanceMoneyStatus] =
        useState<advanceMoneyStatusEnum>(advanceMoneyStatusEnum.default);
    const [unformattedValue, setUnformattedValue] = useState<string>("");
    // -----------------INITIAL------------------- //

    const getOzoner = (_id: string) => {
        let ozonerData: TDataozoner | null;
        let creditsData: any[] = [];
        const promises = [];

        promises.push(
            getOzonerById(_id).then((response) => {
                ozonerData = response;
            })
        );
        promises.push(
            FetchCreditByOzonerId(_id).then((response) => {
                creditsData = response;
                if (response?.length) {
                    setHasCredit(true);
                } else {
                    setHasCredit(false);
                }
            })
        );

        Promise.all(promises)
            .then(async () => {
                setLoading(false);
                setOzoner(ozonerData);
                const availableForms: any[] = [];
                if (ozonerData && ozonerData.financialForm) {
                    let forms = [...ozonerData.financialForm];
                    const usedIds: string[] = [];
                    await Promise.all(
                        creditsData.map(async (credit) => {
                            const index = forms.findIndex(
                                (form) => credit.financialFormId === form._id
                            );
                            const creditVehicle = credit.vehicle;
                            const totals = await FetchCreditTotals(credit._id);
                            const repossessions = (
                                await FetchCreditRepossession(credit._id)
                            ).filter(
                                (repossession) =>
                                    repossession.status ===
                                        RepossessionStatus.pending ||
                                    repossession.status ===
                                        RepossessionStatus.running
                            );
                            const currPayments =
                                credit?.payments.sort((a: any, b: any) => {
                                    return a.quota-b.quota;
                                }) ?? [];
                            if (creditVehicle) {
                                if (
                                    creditVehicle.salePrices &&
                                    !isArray(creditVehicle.salePrices)
                                ) {
                                    creditVehicle.salePrices = Object.keys(
                                        creditVehicle.salePrices
                                    ).map(
                                        (ele: any) =>
                                            creditVehicle?.salePrices[ele]
                                    );
                                }
                                let price = creditVehicle.salePrices.filter(
                                    (ele: any) =>
                                        ele.weeks === credit.duration + 1
                                )[0]?.paymentWeek;
                                const discount =
                                    creditVehicle.discounts?.filter(
                                        (e: any) => e.status === "active"
                                    )[0];
                                if (discount) {
                                    switch (discount.type) {
                                        case "percentage":
                                            price *=
                                                1 - discount.percentageValue;
                                            break;
                                        case "net":
                                            price -= discount.netValue;
                                            break;
                                        default:
                                            break;
                                    }
                                }
                                creditVehicle.paymentWeek = price;
                            }
                            if (index !== -1) {
                                usedIds.push(credit.financialFormId);
                                availableForms.push({
                                    credit,
                                    form: forms[index],
                                    vehicle: creditVehicle,
                                    creditTotals: totals,
                                    payment: currPayments[0],
                                    vehicleAvailable: true,
                                    repossession: !!repossessions?.length
                                        ? repossessions[0]
                                        : undefined,
                                });
                            }
                        })
                    );

                    forms = forms.filter((form) => !usedIds.includes(form._id));

                    forms.sort((a, b) => {
                        return moment
                            .utc(b.createdAt)
                            .diff(moment.utc(a.createdAt), "days");
                    });
                    let findRejected = false;
                    for (let i = 0; i < forms.length; i++) {
                        const element = forms[i];
                        if (
                            (element.status !== formStatusEnum.rejected &&
                                element.status !== formStatusEnum.expired) ||
                            !findRejected
                        ) {
                            findRejected =
                                element.status === formStatusEnum.rejected ||
                                element.status === formStatusEnum.expired;
                            const creditVehicle: any = element.vehicle;
                            let vehicleAvailable = true;
                            if (creditVehicle) {
                                // eslint-disable-next-line no-await-in-loop
                                await getOzonerByVehicle(
                                    creditVehicle?._id
                                ).then((res) => {
                                    if (res && res._id !== _id)
                                        vehicleAvailable = false;
                                });
                                if (
                                    creditVehicle.salePrices &&
                                    !isArray(creditVehicle.salePrices)
                                ) {
                                    creditVehicle.salePrices = Object.keys(
                                        creditVehicle.salePrices
                                    ).map(
                                        (ele: any) =>
                                            creditVehicle?.salePrices[ele]
                                    );
                                }

                                let price = creditVehicle.salePrices.filter(
                                    (ele: any) =>
                                        ele.weeks === element.creditTime
                                )[0]?.paymentWeek;
                                const discount =
                                    creditVehicle.discounts?.filter(
                                        (e: any) => e.status === "active"
                                    )[0];
                                if (discount) {
                                    switch (discount.type) {
                                        case "percentage":
                                            price *=
                                                1 - discount.percentageValue;
                                            break;
                                        case "net":
                                            price -= discount.netValue;
                                            break;
                                        default:
                                            break;
                                    }
                                }
                                creditVehicle.paymentWeek = price;
                            }
                            availableForms.push({
                                creditTotals: null,
                                credit: null,
                                form: element,
                                vehicle: creditVehicle,
                                vehicleAvailable,
                            });
                        }
                    }
                }
                setFinancialForms(availableForms);
            })
            .catch((e: any) => {
                setLoading(false);
                Swal.fire({
                    title: "Error!",
                    text: e ? e.message : "ozoner no valido",
                    icon: "error",
                    confirmButtonText: "Aceptar",
                }).then(() => {
                    navigate("/solicitudes");
                });
                throw e;
            });
    };

    useEffect(() => {
        if (formId) {
            const index = financialForms.findIndex(
                (form) => form.form._id === formId
            );
            if (index >= 0) {
                setIndexForm(index);
            }
        }
    }, [formId, financialForms]);

    const getNotifications = (_id: string) => {
        getOzonerNotificationsCount(id).then((data) => {
            const res: any = {};
            data.forEach((ele: any) => {
                res[ele._id] = ele.count;
            });
            setNotificationsCount(res);
        });
    };

    const getAgreements = () => {
        if (financialForms?.[indexForm]?.credit?._id) {
            getOzonerAgreements(financialForms[indexForm].credit._id).then(
                (agree: any) => {
                    setAgreementsHist(agree.data);
                    const agreementFiltered = agree.data.filter(
                        (ele: any) => ele.status === statusAgreements.onCourse
                    )[0];
                    setAgreements(agreementFiltered);
                }
            );
        }
    };

    const fetchData = () => {
        if (id) {
            setLoading(true);
            getOzoner(id);
            getNotifications(id);
        }
    };
    useEffect(() => {
        fetchData();
    }, [id]);

    

    // -----------------Platforms------------------- //

    const refreshPlatforms = (data: any, force?: boolean) => {
        const p = [];
        if (data) {
            if (
                data.truoraId &&
                (force ||
                    !data.truoraData ||
                    !data.truoraData.reduce(
                        (acc: boolean, ele: any) =>
                            acc &&
                            (ele.validation_status === "success" ||
                                ele.status === "completed" ||
                                ele.status === "error"),
                        true
                    ))
            ) {
                p.push(getOzonerTruora(data.truoraId).then(() => fetchData()));
            }
            if (
                data.palencaId &&
                (!data.palencaData ||
                    !Object.keys(data.palencaData).length ||
                    force)
            ) {
                p.push(getOzonerPalenca(data._id).then(() => fetchData()));
            }
        }
        return Promise.all(p);
    };

    const triggerTruora = async (data?: Blob, typeDoc?: string) => {
        if (ozoner) {
            const docs: any = {};
            let findAll = true;
            await Promise.all(
                truoraFiles.map((fileType) => {
                    if (fileType === typeDoc) {
                        docs[fileType] = data;
                        return new Promise((resolve) => {
                            resolve(true);
                        });
                    }
                    const file = ozoner.documents?.filter(
                        (doc: any) => doc.typeEvidence === fileType
                    );
                    if (!file?.length) {
                        findAll = false;
                        return new Promise((resolve) => {
                            resolve(true);
                        });
                    }
                    return fetch(file[0].url, {
                        method: "GET",
                        cache: "no-cache",
                    })
                        .then((r) => r.blob())
                        .then((blob: Blob) => {
                            docs[fileType] = blob;
                        })
                        .catch((e) => {
                            findAll = false;
                            toasterNotification({
                                msg: "Ocurrio un error cargando los documentos",
                                style: "light",
                                toasterType: toasterOptions.error,
                            });
                        });
                })
            );
            if (findAll) {
                const slackNotifyPayload = {
                    ozonerName: ozoner.name,
                    email: ozoner.email,
                    phone: ozoner.phone,
                    requestDate: getCurrentDate(),
                    // vehicle: vehicle?.internalId || undefined,
                    // quota: "658",
                    requestId: financialForms?.[indexForm]?._id || " ",
                };

                return validateTruora(docs, ozoner._id, slackNotifyPayload)
                    .then(() => getOzonerById(ozoner._id))
                    .then((ozone: any) => {
                        setOzoner(ozone);
                        return refreshPlatforms(ozone, true);
                    })
                    .then(() => {
                        toasterNotification({
                            msg: "Se actualizo la informacion de truora satisfactoriamente",
                            style: "light",
                            toasterType: toasterOptions.success,
                        });
                    })
                    .catch((e) => {
                        console.log(e);
                        toasterNotification({
                            msg: "Fallo la actualizacion de truora",
                            style: "light",
                            toasterType: toasterOptions.error,
                        });
                    });
            }
            toasterNotification({
                msg: "No tiene los documentos necesarios cargados",
                style: "light",
                toasterType: toasterOptions.error,
            });
        }
        return new Promise((resolve) => {
            resolve(false);
        });
    };

    const evaluatePlatforms = (ozonerData: TDataozoner) => {
        if (ozonerData.truoraData) {
            setValidTruora(
                ozonerData.truoraData.reduce(
                    (acc: boolean, val: any) =>
                        acc &&
                        (val.validation_status === "success" ||
                            val.status === "completed" ||
                            val.status === "delayed"),
                    true
                ) && ozonerData.truoraData.length >= 2
            );
        }
        if (!syncPlatforms) {
            refreshPlatforms(ozoner);
            setSyncPlatforms(true);
        }
    };

    const getOzonerDocuments = (documents: any[]) => {
        if (documents?.length) {
            const url = documents.filter(
                (obj: any) => obj.typeEvidence === "selfie"
            );
            if (url.length > 0) {
                setSelfie(url[0].url);
            } else {
                setSelfie(profileDefaultOzoner);
            }
        }
    };
    useEffect(() => {
        if (ozoner) {
            evaluatePlatforms(ozoner);

            // TODO: search for the documents on all objects
            getOzonerDocuments(ozoner.documents);

            const correctCurp = ozoner?.truoraData?.filter(
                (ele: any) => ele.type === "document-validation"
            )[0]?.details?.document_details?.document_number;
            if (correctCurp) {
                setCanValidateCurp(true);
                setValidCurp(correctCurp === ozoner?.curp);
            }
        }
    }, [ozoner]);

    const getOzoniosForm = async (historic: any[], formHistoric: any[]) => {
        const newOzonios: any = { ...ozonios };
        const ozoniosIds = [...historic, ...formHistoric]
            .filter(
                (history: any) =>
                    history.by && !Object.keys(newOzonios).includes(history.by)
            )
            .map((history) => history.by);
        await Promise.all(
            [...new Set(ozoniosIds)].map((ozonioId) => {
                return getOzonioById(ozonioId)
                    .then((ozonio: any) => {
                        newOzonios[ozonioId] = ozonio;
                    })
                    .catch((e) => console.log("user not found"));
            })
        ).then(() => setOzonios(newOzonios));

        let status: any = historic.filter((ele: any) => ele.by);
        if (status.length) {
            status = status[status.length - 1];
            setOzonioLastChange(newOzonios[status.by]?.name);
        }

        status = historic.filter((ele: any) => ele.status === "active");
        if (status.length) {
            status = status[status.length - 1];
            setOzonioActive(newOzonios[status.by]?.name);
        }

        status = formHistoric.filter(
            (ele: any) => ele.status === "uploadReceipt"
        );
        if (status.length) {
            status = status[status.length - 1];
            setOzonioReceipt(newOzonios[status.by]?.name);
        }
        status = formHistoric.filter(
            (ele: any) => ele.status === "creditCreation"
        );
        if (status.length) {
            status = status[status.length - 1];
            setOzonioContract(newOzonios[status.by]?.name);
        }
        status = historic.filter((ele: any) => ele.status === "complete");
        if (status.length) {
            status = status[status.length - 1];
            setOzonioComplete(newOzonios[status.by]?.name);
        }
        status = historic.filter((ele: any) => ele.status === "complete");
        if (status.length) {
            status = status[status.length - 1];
            setOzonioComplete(newOzonios[status.by]?.name);
        }

        status = formHistoric.filter((ele: any) => ele.status === "reached");
        if (status.length) {
            status = status[status.length - 1];
            setOzonioReached(newOzonios[status.by]?.name);
        }
        if (financialForms?.[indexForm]?.status !== "new") {
            status = formHistoric.filter(
                (ele: any) => ele.status === financialForms?.[indexForm].status
            );
            if (status.length) {
                status = status[status.length - 1];
                setOzonioStatusSolicitud(newOzonios[status.by]?.name);
            }
        }
    };

    useEffect(() => {
        getOzoniosForm(
            ozoner?.statusHistoric ?? [],
            financialForms?.[indexForm]?.form?.statusHistoric ?? []
        );

        if (financialForms?.[indexForm]?.credit?._id) {
            getAgreements();
        }

    setInitialAdvanceMoney(financialForms?.[indexForm]?.form?.advancedMoney??0);

        if (financialForms?.[indexForm]?.form?.vehicle) {
            setCreditOptions(
                financialForms[indexForm].form.vehicle.salePrices.map(
                    (ele: any) =>
                        ele.weeks === 1
                            ? {
                                  value: ele.weeks,
                                  label: "Venta de contado",
                              }
                            : {
                                  value: ele.weeks,
                                  label: `${ele.weeks} semanas (${Math.round(
                                      ele.weeks * 0.23
                                  )} meses)`,
                              }
                )
            );
        } else {
            setCreditOptions([]);
        }
    }, [indexForm, ozoner, financialForms]);

    useEffect(() => {
        if (!openVehicle) {
            fetchData();
        }
    }, [openVehicle]);

    // -----------------UPDATES VALUES------------------- //

    const updateReached = () => {
        if (financialForms[indexForm])
            editSolicitud(financialForms[indexForm].form._id, {
                reached: !financialForms[indexForm].form.reached,
            })
                .then((ele) => {
                    setOpenModal(false);
                    toasterNotification({
                        msg: "Solicitud Exitosa",
                        style: "light",
                        toasterType: toasterOptions.success,
                    });
                    fetchData();
                })
                .catch(() =>
                    toasterNotification({
                        msg: "Ocurrio un error",
                        style: "light",
                        toasterType: toasterOptions.error,
                        onOpenCallback: () => setOpenModal(false),
                    })
                );
    };

    const handleDetailsVeh = (e: any, internalId: string) => {
        e.stopPropagation();
        navigate(`/inventory/${internalId}`);
    };

    const updateStatusSolicitud = (status?: string, vehicleId?: string) => {
        const up: any = {
            rejectedReason: null,
            devolutionReason: null,
        };
        if (status) {
            up.status = status;
        }
        if (vehicleId) {
            up.vehicleId = vehicleId;
            up.creditTime = null;
        }
        if (financialForms?.[indexForm]?.form?._id) {
            return editSolicitud(financialForms?.[indexForm]?.form?._id, up)
                .then(() => {
                    toasterNotification({
                        msg: "Solicitud Exitosa",
                        style: "light",
                        toasterType: toasterOptions.success,
                        onOpenCallback: () => setOpenModal(false),
                    });
                    fetchData();
                })
                .catch((err: any) => {
                    if (
                        err.statusCode === 400 &&
                        err.message === "Vehicle not available"
                    ) {
                        setOpenVehicle(true);
                        toasterNotification({
                            msg: "El vehiculo ya no esta disponible",
                            style: "light",
                            toasterType: toasterOptions.error,
                            onOpenCallback: () => setOpenModal(false),
                        });
                    }
                });
        }
        return null;
    };

    const updateReview = async () => {
        try {
            const payload = {
                status: "approved",
            };

            await editSolicitud(
                financialForms?.[indexForm]?.form?._id,
                payload
            );
            toasterNotification({
                msg: "Solicitud Exitosa",
                style: "light",
                toasterType: toasterOptions.success,
                onOpenCallback: () => setOpenModal(false),
            });
            fetchData();
        } catch (e: any) {
            toasterNotification({
                msg: `Ocurrio un error: ${e.message}.`,
                style: "light",
                toasterType: toasterOptions.error,
                onOpenCallback: () => setOpenModal(false),
                onCloseCallback: () => {
                    setOpenVehicle(true);
                    setMaxSalePrice(
                        displayQuota(
                            financialForms?.[indexForm]?.form?.maxWeekQuota
                        )
                    );
                },
            });
        }
    };

    // -----------------CREDIT ACTIONS------------------- //
    const changeCreditLabel = () => {
        const vehicle = financialForms?.[indexForm]?.vehicle;
        if (vehicle && ozoner) {
            const old: any = "";
            const newC = vehicle.salePrices.filter(
                (ele: any) => ele.weeks === selectedCreditTime
            )[0];
            return old ? (
                <Typography scale="medium" weight="400" textColor="neutral_700">
                    Pasará de pagar
                    <Typography scale="medium" weight="600" component="span">
                        {` $${old.paymentWeek} por ${old.weeks} semanas `}
                    </Typography>
                    a pagar
                    <Typography scale="medium" weight="600" component="span">
                        {` $${newC.paymentWeek} por ${newC.weeks} semanas `}
                    </Typography>
                </Typography>
            ) : (
                <Typography scale="medium" weight="400" textColor="neutral_700">
                    Empezara a pagar
                    <Typography scale="medium" weight="600" component="span">
                        {` $${newC.paymentWeek} por ${newC.weeks} semanas `}
                    </Typography>
                </Typography>
            );
        }
        return <div />;
    };
    const updateCredit = (e: any) => {
        setSelectedCreditTime(e.target.value);
        setOpenCreditChange(true);
    };
    const updateCreditTime = () => {
        if (financialForms?.[indexForm]?.form) {
            const upload:any = {
                creditTime: selectedCreditTime,
                
            };
            if(selectedCreditTime===1){
                upload.deposit= 0;
                upload.advancedMoney = 0;
            }
            editSolicitud(financialForms?.[indexForm]?.form?._id, upload)
                .then(() => {
                    toasterNotification({
                        msg: "Cambio de semanas exitoso",
                        style: "light",
                        toasterType: toasterOptions.success,
                        onOpenCallback: () => setOpenModal(false),
                    });
                    fetchData();
                    setOpenCreditChange(false);
                })
                .catch((err: any) => {
                    toasterNotification({
                        msg: "Algo fallo",
                        style: "light",
                        toasterType: toasterOptions.error,
                        onOpenCallback: () => setOpenModal(false),
                    });
                });
        }
    };

    // -----------------OTHER ACTIONS------------------- //

    const getPalencaLink = () => {
        navigator.clipboard
            .writeText(
                `https://connect.palenca.com/?widget_id=7c3011ec-7872-4e8a-bdf2-8c0f93e266d7&external_id=${ozoner?._id}`
            )
            .then(() => {
                toasterNotification({
                    toasterType: toasterOptions.success,
                    msg: "Copiado al portapapeles",
                    style: "light",
                });
            })
            .catch((error) => {
                toasterNotification({
                    toasterType: toasterOptions.error,
                    msg: `Error al copiar el contenido: ${error}`,
                    style: "light",
                });
            });;
    };
    const getWebpageLink = () => {
        let prefix ="";
        if (process.env.REACT_APP_API_BASE_URL?.includes("dev")){
            prefix = "dev.";
        }
        if (process.env.REACT_APP_API_BASE_URL?.includes("qa")){
            prefix = "qa.";
        }
            navigator.clipboard
                .writeText(
                    `https://${prefix}ozon.mobi/documentos-en-proceso?email=${encodeURIComponent(ozoner?.email??"")}`
                )
                .then(() => {
                    toasterNotification({
                        toasterType: toasterOptions.success,
                        msg: "Copiado al portapapeles",
                        style: "light",
                    });
                })
                .catch((error) => {
                    toasterNotification({
                        toasterType: toasterOptions.error,
                        msg: `Error al copiar el contenido: ${error}`,
                        style: "light",
                    });
                });;
    };

    const fetchNotifications = (typeN: "textmsg" | "email" | "whatsapp") => {
        setNotifications(undefined);
        return getOzonerNotifications(ozoner?._id, typeN)
            .then((data) => {
                const p = data.map((not: any) =>
                    getGroupNotificationsById(not.groupEmail).then((res) => ({
                        ...not,
                        msg: res.data.body,
                    }))
                );
                return Promise.all(p);
            })
            .then((data: any) => {
                setNotifications(data);
            });
    };

    const approveContract = () => {
        // TODO: add loading status to the buttons dso
        if (ozoner?._id && financialForms?.[indexForm]?.form?._id)
            createCredit(
                ozoner._id,
                financialForms[indexForm].form._id,
                financialForms[indexForm].form.vehicle?._id
            )
                .then(() => {
                    setOpenModal(false);
                    fetchData();
                    toasterNotification({
                        msg: "Solicitud Exitosa",
                        style: "light",
                        toasterType: toasterOptions.success,
                        onCloseCallback: () => fetchData,
                    });
                })
                .catch((error: any) => {
                    toasterNotification({
                        msg: `Ocurrio un error: ${error}`,
                        style: "light",
                        toasterType: toasterOptions.error,
                        onOpenCallback: () => setOpenModal(false),
                    });
                });
    };
    const approveContado = () => {
        if (ozoner?._id && financialForms?.[indexForm]?.form?._id) {
            setLoadingModal(true);
            createCredit(
                ozoner._id,
                financialForms[indexForm].form._id,
                financialForms[indexForm].form.vehicle?._id
            )
                .then((data) => {
                    const creditId = data.data._id;
                    return ActivateCredit(creditId);
                }).catch((error: any) => {
                    setLoadingModal(false);
                    toasterNotification({
                        msg: `Ocurrio un error: ${error}`,
                        style: "light",
                        toasterType: toasterOptions.error,
                        onOpenCallback: () => setOpenModal(false),
                    });
                })
                .then((data) => {
                    setOpenModal(false);
                    setLoadingModal(false);
                    fetchData();
                    toasterNotification({
                        msg: "Solicitud Exitosa",
                        style: "light",
                        toasterType: toasterOptions.success,
                        onCloseCallback: () => fetchData,
                    });
                })
                .catch((error: any) => {
                    setLoadingModal(false);
                    toasterNotification({
                        msg: `Ocurrio un error: ${error}`,
                        style: "light",
                        toasterType: toasterOptions.error,
                        onOpenCallback: () => setOpenModal(false),
                    });
                });
        }
    };

    useEffect(() => {
        if (modalOption !== "") {
            setOpenModal(true);
        }
    }, [modalOption]);

    const modalData: any = {
        reach: {
            title: (
                <Typography scale="medium" weight="400" textColor="neutral_900">
                    ¿Has{" "}
                    <Typography scale="medium" weight="600" component="span">
                        contactado al ozoner?
                    </Typography>
                </Typography>
            ),
            subtitle: (
                <Typography scale="xsmall" weight="400" textColor="neutral_900">
                    Le marcaste, mandaste un correo o un mensaje al ozoner con
                    los datos registrados?{" "}
                </Typography>
            ),
            action: updateReached,
            icon: <ApproveIcon />,
        },
        unreach: {
            title: (
                <Typography scale="medium" weight="400" textColor="neutral_900">
                    ¿Deseas{" "}
                    <Typography scale="medium" weight="600" component="span">
                        cambiar el estado del ozoner?
                    </Typography>
                </Typography>
            ),
            subtitle: (
                <Typography scale="xsmall" weight="400" textColor="neutral_900">
                    Puedes volver a cambiar el estado en otro momento
                </Typography>
            ),
            action: updateReached,
            icon: <ApproveIcon />,
        },
        reject: {
            title: (
                <Typography scale="medium" weight="600" textColor="neutral_900">
                    ¿Estás seguro de que deseas rechazar el ozoner?
                </Typography>
            ),
            subtitle: (
                <Typography scale="xsmall" weight="400" textColor="neutral_900">
                    Se cambiará el estado del ozoner ha rechazado
                </Typography>
            ),
            // action: () => updateStatus("rejected"),
            icon: <ContactIcon />,
        },
        approve: {
            title: (
                <Typography scale="medium" weight="600" textColor="neutral_900">
                    ¿Estás seguro de que quieres crear el contrato en espera?
                </Typography>
            ),
            subtitle: (
                <Typography scale="xsmall" weight="400" textColor="neutral_900">
                    Se creará un contrato de espera para el ozoner, en el módulo
                    de crédito
                </Typography>
            ),
            action: approveContract,
            icon: <ContactIcon />,
        },
        approve_contado: {
            title: (
                <Typography scale="medium" weight="600" textColor="neutral_900">
                    ¿Estás seguro de que quieres procesar el contrato de
                    contado?
                </Typography>
            ),
            subtitle: (
                <Typography scale="xsmall" weight="400" textColor="neutral_900">
                    Se creará un contrato y se liquidara inmediatamente, esta
                    acción debe realizarse solo cuando ya se recibio el pago por
                    la moto.
                </Typography>
            ),
            action: approveContado,
            icon: <ContactIcon />,
        },
        layawayReceipt: {
            title: (
                <Typography scale="medium" weight="600" textColor="neutral_900">
                    Comprobante de pago apartado
                </Typography>
            ),
            subtitle: (
                <Typography scale="xsmall" weight="400" textColor="neutral_900">
                    Comprobante de pago apartado
                </Typography>
            ),
            action: () => {
                setOpenModal(false);
                handleEventGoogle(
                    "BTN_Pb_ozoner_aside_certificate",
                    "Panda Ozoners",
                    "click in aside certificate ozoner button"
                );
            },
            icon: <ApproveIcon />,
        },
        approve_soli: {
            title: (
                <Typography scale="medium" weight="400" textColor="neutral_900">
                    ¿Has revisado{" "}
                    <Typography scale="medium" weight="600" component="span">
                        esta solicitud?
                    </Typography>
                </Typography>
            ),
            subtitle: (
                <Typography scale="xsmall" weight="400" textColor="neutral_900">
                    Revisaste en su totalidad, la{" "}
                    <Typography scale="medium" weight="600" component="span">
                        {" "}
                        información personal,
                    </Typography>{" "}
                    los datos
                    <Typography scale="medium" weight="600" component="span">
                        {" "}
                        financieros,
                    </Typography>{" "}
                    y los
                    <Typography scale="medium" weight="600" component="span">
                        {" "}
                        documentos
                    </Typography>
                </Typography>
            ),
            action: () => updateReview(),
        },
    };

    useEffect(() => {
        getOzonios("").then((ozons) => {
            setOzoniosList(
                ozons.data
                    .sort((a:any, b:any) => {
                        if (a.name < b.name) {
                            return -1;
                        }
                        if (a.name > b.name) {
                            return 1;
                        }
                        return 0;
                    })
                    .map((ozonio: any) => ({
                        value: ozonio._id,
                        label: ozonio.name,
                    }))
            );
        });
    }, []);

    const contextValue = useMemo(
        () => ({
            ozoner,
            ozoniosList,
            financialForm: financialForms?.[indexForm],
            vehicle: financialForms?.[indexForm]?.vehicle,
            setResult: fetchData,
            setModalOption,
            updateCredit,
            ozonioReached,
            fetchData,
            ozonioLastChange,
            ozonioComplete,
            ozonioContract,
            ozonioReceipt,
            ozonioStatusSolicitud,
            updateStatusSolicitud,
            setOpenVehicle,
            setMaxSalePrice,
            hasCredit,
            loading,
            openModal,
            setOpenModal,
            modalOption,
            modalData,
            selfie,
            getPalencaLink,
            openCreditChange,
            setOpenCreditChange,
            updateCreditTime,
            changeCreditLabel,
            notificationsCount,
            fetchNotifications,
            openVehicle,
            maxSalePrice,
            validTruora,
            id,
            setId,
            setFormId,
            financialForms,
            ozonios,
            notifications,
            creditOptions,
            triggerTruora,
            refreshPlatforms,
            initialAdvanceMoney,
            advanceMoneyStatus,
            setUnformattedValue,
            getAgreements,
            agreementsHist,
            agreements,
            canValidateCurp,
            validCurp,
            loadingModal,
            getWebpageLink,
            handleDetailsVeh
        }),
        [
            ozoner,
            ozoniosList,
            loading,
            indexForm,
            ozonioReached,
            ozonioComplete,
            fetchData,
            ozonioLastChange,
            ozonioContract,
            ozonioReceipt,
            ozonioStatusSolicitud,
            updateStatusSolicitud,
            hasCredit,
            id,
            financialForms,
            ozonios,
            notifications,
            creditOptions,
            initialAdvanceMoney,
            advanceMoneyStatus,
            validCurp,
            canValidateCurp,
            loadingModal,
        ]
    );
    return (
        <ozonerContext.Provider value={contextValue}>
            {children}
        </ozonerContext.Provider>
    );
};
