import { getUser } from "helpers/localStorage/authStorage";
import { get, post, put } from "../axios/axiosInstance";



export const fetchNotifications = async (ozonio?:string, page?:number, limit?:number, read?:null|string|boolean, vehicleId?:string) => {
  try {
    const filters=[];
    if(read!==null && read !==undefined){
      filters.push(`read=${read}`);
    }
    if(limit){
      filters.push(`limit=${limit}`);
    }
    if(page){
      filters.push(`page=${page}`);
    }
    if(ozonio){
      filters.push(`user=${ozonio}`);
    }
    if (vehicleId){
      filters.push(`vehicleInternalId=${vehicleId}`);
    }
    const url = `v1/notify?${filters.join("&")}`;
    const res = await get(url);
    return res.data;
  } catch (err: any) {
    throw err.response.data;
  }
}; 

export const createNotification = async (data:any) => {
  
  try {
    const url = "v1/notify";
    const res = await post(url,data);
    return res.data;
  } catch (err: any) {
    throw err.response.data;
  }
};
export const markRead = async (notification:any) => {
  
  try {
    const url = `v1/notify/${notification}`;
    const userL = getUser();

    const res = await put(url, { read: true, user: userL.userId });
    return res.data;
  } catch (err: any) {
    throw err.response.data;
  }
};
