/* eslint-disable no-shadow */
/* eslint-disable no-underscore-dangle */
/* eslint-disable dot-notation */
import { get, put, post, deletes } from "helpers/axios/axiosInstance";
import { TChange, TDisplayHistoric, THistoric } from "models/history.interface";
import { ReactComponent as Refresh } from "design_system/src/static/icons/refresh.svg";

import moment from "moment";
import "moment/locale/es";
import { ozonerFields, statusAgreementsValues, statusOzoners } from "models/ozoner.interface";
import { statusVehicles, vehicleFields } from "models/vehicle.interface";
import { documentsTypes } from "components/hocs/loadDocuments/loadDocuments";
import { financialFormFields } from "models/financialForm.interface";

export const getHistory = async (
  id: string
): Promise<THistoric[]> => {
  try {
    const res = await get(`v1/historic?objectId=${id}&sort=-createdAt`);
    return res.data;
  } catch (err: any) {
    throw err.response.data;
  }
};

const getTime = (time: string) => {
  const date = moment(time);

  const now = moment();
  const diffInHours = now.diff(date, "hours");
  const diffInDays = now.diff(date, "days");

  let result;

  if (diffInHours < 24) {
    result = `${diffInHours} Horas`;
  } else if (diffInDays < 7) {
    result = `${diffInDays} Dias ${date.format("YYYY-MM-DD")}`;
  } else {
    result = date.format("YYYY-MM-DD");
  }
  return result;
};

const financialFormStatuses: any = {
  approved: "Aprobada",
  rejected: "Rechazada",
  rejectedByMax: "Rechazada por maximo",
  new: "Pendiente de aprobación",
};
const rejectedReason:any = {
  creditRisk: "Riesgo de crédito",
  reported: "Reportado",
  maxWeekly: "Límite semanal máximo",
  capacity: "Capacidad"
};

const typePayments:any={
  creditPayment:"cuota",
  note:"intereses"
};
const paymentStatus:any={
  paid:"pagado"
};
enum modelComplementEnum{
  payment = "payment",
  agreement = "agreement",
}
const getOzonerMsg = (base: TDisplayHistoric, changes: TChange[], context:any) => {
  const answer: TDisplayHistoric[] = [];
  if (changes.length > 5) {
    answer.push({ ...base, msg: `Se modificaron ${changes.length} campos en el ozoner` });
  }
  else if (context.modelComplement === modelComplementEnum.payment){
    let type:any;
    let amount;
    let status;
    changes.forEach(change=>{
      switch(change.field){
        case "typePayment":
          type=change.updated;
          break;
        case "payment.total":
          amount=change.updated;
          break;
        case "payment.status":
          status=change.updated;
          break;
        default:
          break;
      }
    });
    const icon = "document";
    const msg = `Pago de ${(typePayments as any)[type]} recibido por valor de: ${amount}`;
    answer.push({ ...base, msg, icon });

  }
  else {
    let iterableChanges = changes.filter(change => change.field !== "internalId");
    if (iterableChanges.filter(change => change.field === "status" || change.field === "subStatus").length === 2) {
      iterableChanges = iterableChanges.filter(change => change.field !== "status");
    };
    iterableChanges.forEach(change => {
      let msg = "";
      let icon = "refresh";
      switch (change.field) {
        case "documents.layawayReceipt":
          msg = "Se añadio el comprobante de apartado";
          icon="document";
          break;
        case "truoraId":
          msg = "Se asigno un id de truora al ozoner";
          icon = "eye";
          break;
        case "palencaId":
          msg = "Se asigno un id de palenca al ozoner";
          icon = "eye";
          break;
        case "truoraData":
          msg = "Se actualizo la informacion de truora del ozoner";
          icon = "eye";
          break;
        case "palencaData":
          msg = "Se actualizo la informacion de palenca del ozoner";
          icon = "eye";
          break;
        case "financialForm.vehicleId":
          msg = `Se asigno el vehiculo ${change.updated} al ozoner`;
          break;
        case "financialForm.status":
          msg = `El estado de la solicitud cambio a ${financialFormStatuses[change.updated]}`;
          break;
        case "financialForm.rejectedReason":
          msg = `La solicitud se rechazo por ${rejectedReason[change.updated]}`;
          break;
        case "reached":
          msg = `El ozoner se marco como ${change.updated ? "" : "no "}contactado`;
          icon = "eye";
          break;
        case "creditTime":
          msg = `El tiempo de financiamiento se cambio de ${change.old} a ${change.updated}`;
          icon = "document";
          break;
        case "status":
          msg = `El estado del ozoner cambio de ${(statusOzoners as any)[change.old]?.title } a ${(statusOzoners as any)[change.updated]?.title }`;
          break;
        // case "subStatus":
        //   msg = `El subestado del ozoner cambio de ${(subStatusOzoners as any)[change.old]?.title } a ${(subStatusOzoners as any)[change.updated]?.title }`;
        //   break;
        case "created":
          if (context.modelComplement === modelComplementEnum.agreement){
            icon = "document";
            msg = "Acuerdo de pago creado";
          }
          else{
            icon = "document";
            msg = "Registro de novedad en cobranza.";
          }
          break;
        case "agreementPart.status":
          icon = "money";
          msg = `Acuerdo de pago se marco como ${(statusAgreementsValues as any)[change.updated]?.title??""}`;
          break;
        case "agreementPart.amount":
          icon = "money";
          msg = `Acuerdo de pago por valor de: ${change.updated}`;
          break;
        default:
          if(change.field.includes("documents.")){
            icon = change.updated === ""?"document_red":"document";
            msg = `Se ${change.updated === "" ? "elimino" : "modifico"} el documento ${(documentsTypes as any)[change.field.split(".").reverse()[0]]?.title ??"otro"} del ozoner`;
          }
          else if (change.field.startsWith("financialForm.")){
            msg = `Se modifico el campo ${(financialFormFields as any)[change.field.split(".").reverse()[0]]} del ozoner`;
          }
          else{
            msg = `Se modifico el campo ${(ozonerFields as any)[change.field]} del ozoner`;
          }
          break;
      }
      answer.push({ ...base, msg, icon });
    });
  }
  return answer;
};
const getVehicleMsg = (base: TDisplayHistoric, changes: TChange[]) => {
  const answer: TDisplayHistoric[] = [];
  if (changes.length > 20) {
    answer.push({ ...base, msg: `Se modificaron ${changes.length} campos en el ozoner` });
  }
  else {
    const iterableChanges = changes;
    iterableChanges.forEach(change => {
      let msg = "";
      let icon = "refresh";
      switch (change.field) {
        case "created":
          msg = "Se creo el vehiculo";
          icon="document";
          break;
        case "status":
          msg = `El estado del vehículo cambio a ${(statusVehicles as any)[change.updated]?.title }`;
          icon = "eye";
          break;
        
        default:
          if(change.field.startsWith("documents.")){
            icon = "document";
            msg = `Se modifico el documento ${(documentsTypes as any)[change.field.replace("documents.","")]?.title??"otro"} del vehiculo`;
          }
          else{
            msg = `Se modifico el campo ${(vehicleFields as any)[change.field]} del vehiculo`;
          }
          break;
      }
      answer.push({ ...base, msg, icon });
    });
  }
  return answer;
};

export const translateHistory = (historyData: THistoric[]) => {
  return historyData.reduce((acc: TDisplayHistoric[], history: THistoric) => {
    const resume: TDisplayHistoric = {
      by: history.by,
      createdAt: getTime(history.createdAt),
      msg: "",
      icon: "refresh"
    };
    switch (history.model) {
      case "ozoner":
        return [...acc, ...getOzonerMsg(resume, history.changes,history)];
      case "vehicle":
        return [...acc, ...getVehicleMsg(resume, history.changes)];
      default:
        return [...acc, resume];
    }
  }, []);
};