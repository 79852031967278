/* eslint-disable no-restricted-syntax */
/* eslint-disable react/jsx-no-constructed-context-values */

import React, { useContext, useEffect, useReducer } from "react";
import { AnySourceData, LngLatBounds, Map, Marker, Popup } from "mapbox-gl";
import { Feature } from "views/ozoner/location/interfaces/places";
import { MapContext } from "./MapContext";
import { mapReducer } from "./mapReducer";
import { PlacesContext } from "../places/PlacesContext";

interface MarkerWithId extends Marker {
  id?: string;
}
export interface MapState {
  isMapReady: boolean;
  map?: Map;
  markers: MarkerWithId[];
}

const INITIAL_STATE: MapState = {
  isMapReady: false,
  map: undefined,
  markers: [],
};

type Props = {
  children: JSX.Element;
};

const MapProvider = ({ children }: Props) => {
  const { places } = useContext(PlacesContext);
  const [state, dispatch] = useReducer(mapReducer, INITIAL_STATE);

  // lat: 19.436390695431946, longitud -99.15464053200171
  // places.push([-99.15464053200171, 19.436390695431946]);

  useEffect(() => {
    console.log(places);
  }, [places]); // places

  const setMap = (map: Map) => {
    console.log(map);
  };

  const getRouteBetweenPoints = async () => {
    console.log("getRouteBetweenPoints");
  };

  return (
    <MapContext.Provider
      value={{
        ...state,
        setMap,
        getRouteBetweenPoints,
      }}
    >
      {children}
    </MapContext.Provider>
  );
};

export default MapProvider;
