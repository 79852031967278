/* eslint-disable no-unused-vars */
/* eslint-disable dot-notation */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { Formik, Form, FormikValues, FormikHelpers } from "formik";
import * as yup from "yup";
import Swal from "sweetalert2";

import { ReactComponent as User } from "design_system/src/static/icons/favorite-profile.svg";

import { ReactComponent as Mas } from "design_system/src/static/icons/mas.svg";
import { ReactComponent as Menos } from "design_system/src/static/icons/menos.svg";

import { Button, Input, Typography, SideModal } from "design_system/src";
import { getExport, getOzonios } from "helpers/fetchs/ozonios";

// css
import CardOzoner from "components/hocs/cardozoner/CardOzoner";
import "./ozonio.scss";
import Inventory from "views/inventory/Inventory";
import { TDataozoner } from "models/ozoner.interface";
import { useNavigate } from "react-router-dom";
import { editSolicitud } from "helpers/fetchs/solicitudes";
import Ozoner from "./Ozonio";

function SortArray(x: any, y: any) {
  if (x.name < y.name) {
    return -1;
  }
  if (x.name > y.name) {
    return 1;
  }
  return 0;
}

interface ExportProps {
  open: boolean;
  setOpen: Function;
  currentQuery: string;
}

let petition: number;

function ExportOzonios({ open, setOpen, currentQuery }: ExportProps) {
  const history = useNavigate();

  const [query, setQuery] = useState<string>(currentQuery);
  const [ozonios, setOzonios] = useState<any | null>();
  const [count, setCount] = useState<any>();
  const [letters, setLetters] = useState<string[]>([
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
  ]);
  const [minLetter, setMinLetter] = useState<string>("a");
  const [maxLetter, setMaxLetter] = useState<string>("z");
  const [paging, setPaging] = useState<number>(0);
  const [loading, setLoading] = useState(false);


  const requestOzonios = () => {
    const num = petition + 1;
    petition += 1;
    const letterQuery = `${minLetter}-${maxLetter}`;

    getOzonios(query, letterQuery).then((res) => {
      if (res && num === petition) {
        const order = res.data.sort(SortArray);
        const ozoniosA: any = {};
        order.forEach((ozonio: any) => {
          const letter = ozonio.name.substring(0, 1).toUpperCase();
          if (!ozoniosA[letter]) ozoniosA[letter] = [];
          ozoniosA[letter].push(ozonio);
        });
        setOzonios(ozoniosA);
        setCount(res.count);
      }
    });
  };

  useEffect(() => {
    petition = 0;
    requestOzonios();
  }, []);

  const exportOzonios = () => {
    setLoading(true);

    const letterQuery = `${minLetter}-${maxLetter}`;
    getExport(query, letterQuery).then((data) => {
      const blob = new Blob([data], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      if (link.download !== undefined) {
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "ozonios.csv");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        
      }
      setLoading(false);
      setOpen(false); 
    });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    requestOzonios();
  };

  return (
    <SideModal
      open={open}
      setOpen={setOpen}
      fullOpen={open}
      setFullOpen={() => {}}
    >
      <div className="flex_content_col">
        <Typography
          scale="heading1"
          weight="600"
          className="text_neutral_900 flex_header"
        >
          Ozonios
        </Typography>
        <div className="flex_body pretty_slider overflow_x_hidden p_r_md">
          {ozonios &&
            Object.keys(ozonios).map((i) => {
              const data = ozonios[i];
              return (
                <>
                  <Typography
                    scale="heading4"
                    weight="600"
                    textColor="primary_300"
                    className="p_x_xxxl m_b_xxs"
                  >
                    {i}
                  </Typography>
                  <div className="w_100_per overflow_hidden br_md shadow_medium m_b_md">
                    {data.map((ozonio: any, j: number) => (
                      <div
                        className="display_flex flex_align_center bg_neutral_0 p_x_xxxl p_y_xs border_1 border_solid border_neutral_300"
                        key={`ozonio_${i}_${j}`}
                      >
                        <div className="profile_image m_r_sm display_flex">
                          {ozonio.image && ozonio.image.url ? (
                            <img src={ozonio.image.url} alt="ozonio" />
                          ) : (
                            <User className="dim_lg m_sm" />
                          )}
                        </div>
                        <div>
                          <Typography
                            scale="medium"
                            weight="600"
                            textColor="neutral_900"
                          >
                            {ozonio.name}
                          </Typography>
                          <Typography
                            scale="small"
                            weight="400"
                            textColor="primary_200"
                          >
                            {/* {ozonio.role} */}
                            Administrador
                          </Typography>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              );
            })}
        </div>
        <div className="display_flex m_t_md flex_align_end flex_footer">
          <Typography scale="large" weight="600" className="m_r_xxs">
            {count}
          </Typography>
          <Typography scale="medium" weight="400">
            Ozonios
          </Typography>
        </div>
      </div>
      <div className="display_flex flex_col flex_justify_between h_100_per flex_content_col">
        <div className="flex_body pretty_slider overflow_x_visible overflow_y_auto">
          <Typography weight="600" scale="heading4" className="m_b_xs">
            Busqueda
          </Typography>
          <form onSubmit={handleSubmit}>
            <Input
              title=""
              placeholder="Busqueda"
              name="search"
              type="text"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
          </form>

          <Typography weight="600" scale="heading4" className="m_b_xs">
            Abecedario
          </Typography>
          <div className="display_flex br_xs shadow_medium overflow_hidden">
            <div className="flex_1 p_x_xxl p_y_sm bg_neutral_0">
              <div className="input_indicators">
                <Input
                  type="text"
                  title="Desde la:"
                  name="desde"
                  titleClassName="text_center w_100_per"
                  value={minLetter}
                  inputClassName="text_center p_x_xxxl"
                />
                <Menos
                  className={`dim_lg dso_indicator_left ${
                    letters.indexOf(minLetter) > 1
                      ? "text_primary_300"
                      : "text_neutral_500"
                  }`}
                  onClick={() =>
                    setMinLetter(letters[letters.indexOf(minLetter) - 1])
                  }
                />
                <Mas
                  className={`dim_lg dso_indicator_right ${
                    letters.indexOf(minLetter) < letters.indexOf(maxLetter)
                      ? "text_primary_300"
                      : "text_neutral_500"
                  }`}
                  onClick={() =>
                    setMinLetter(letters[letters.indexOf(minLetter) + 1])
                  }
                />
              </div>
              <div className="input_indicators">
                <Input
                  type="text"
                  title="Hasta la:"
                  name="hasta"
                  titleClassName="text_center w_100_per"
                  value={maxLetter}
                  inputClassName="text_center p_x_xxxl"
                />
                <Menos
                  className={`dim_lg dso_indicator_left ${
                    letters.indexOf(maxLetter) > letters.indexOf(minLetter)
                      ? "text_primary_300"
                      : "text_neutral_500"
                  }`}
                  onClick={() =>
                    setMaxLetter(letters[letters.indexOf(maxLetter) - 1])
                  }
                />
                <Mas
                  className={`dim_lg dso_indicator_right ${
                    letters.indexOf(maxLetter) < letters.length
                      ? "text_primary_300"
                      : "text_neutral_500"
                  }`}
                  onClick={() =>
                    setMaxLetter(letters[letters.indexOf(maxLetter) + 1])
                  }
                />
              </div>
            </div>
            <div className="flex_1 p_t_sm p_r_sm bg_neutral_300">

              <Typography
                weight="600"
                scale="medium"
                className={`p_y_sm p_x_md m_b_sm br_r_md ${
                  paging === 0 ? "bg_neutral_0" : ""
                }`}
                onClick={() => {
                  setPaging(0);
                  setMinLetter("a");
                  setMaxLetter("z");
                }}
              >
                Todas
              </Typography>
              <Typography
                weight="600"
                scale="medium"
                className={`p_y_sm p_x_md m_b_sm br_r_md ${
                  paging === 1  ? "bg_neutral_0" : ""
                }`}
                onClick={() => {
                  setPaging(1);
                  setMinLetter("a");
                  setMaxLetter("m");
                }}
              >
                A-M
              </Typography>
              <Typography
                weight="600"
                scale="medium"
                className={`p_y_sm p_x_md m_b_sm br_r_md ${
                  paging === 2 ? "bg_neutral_0" : ""
                }`}
                onClick={() => {
                  setPaging(2);
                  setMinLetter("n");
                  setMaxLetter("z");
                }}
              >
                N-Z
              </Typography>
            </div>
          </div>
        </div>
        <div className=" w_100_per flex_footer">
          <Button
            scale="small"
            variant="outline"
            className="w_100_per m_t_xxxl m_b_md"
            onClick={() => requestOzonios()}
          >
            Previsualizar
          </Button>
          <div className="display_flex flex_gap_md">
            <Button
              className="flex_1"
              scale="small"
              variant="outline"
              onClick={() => setOpen(false)}
            >
              Cancelar
            </Button>
            <Button
              className="flex_1"
              scale="small"
              onClick={() => exportOzonios()}
              loading={loading}
            >
              Exportar
            </Button>
          </div>
        </div>
      </div>
    </SideModal>
  );
}

export default ExportOzonios;
