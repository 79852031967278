const getInventoryFilter = () => {
  const filter = sessionStorage.getItem("inventoryFilter");
  if (filter) {
    return JSON.parse(filter);
  }
  return {};
};

const setInventoryFilter = (filter: any) => {

  sessionStorage.setItem("inventoryFilter", JSON.stringify(filter));
};

const clearInventoryFilter = () => {
  sessionStorage.removeItem("inventoryFilter");
};

const getOzonerFilter = () => {
  const filter = sessionStorage.getItem("ozonerFilter");
  if (filter) {
    return JSON.parse(filter);
  }
  return {};
};

const setOzonerFilter = (filter: any) => {
  sessionStorage.setItem("ozonerFilter", JSON.stringify(filter));
};

const clearOzonerFilter = () => {
  sessionStorage.removeItem("ozonerFilter");
};
const getOzocioFilter = () => {
  const filter = sessionStorage.getItem("ozocioFilter");
  if (filter) {
    return JSON.parse(filter);
  }
  return {};
};

const setOzocioFilter = (filter: any) => {
  sessionStorage.setItem("ozocioFilter", JSON.stringify(filter));
};

const clearOzocioFilter = () => {
  sessionStorage.removeItem("ozocioFilter");
};
const getNotificationsFilter = () => {
  const filter = sessionStorage.getItem("notificationsFilter");
  if (filter) {
    return JSON.parse(filter);
  }
  return {};
};

const setNotificationsFilter = (filter: any) => {
  sessionStorage.setItem("notificationsFilter", JSON.stringify(filter));
};

const clearNotificationsFilter = () => {
  sessionStorage.removeItem("notificationsFilter");
};
const getDevicesFilter = () => {
  const filter = sessionStorage.getItem("devicesFilter");
  if (filter) {
    return JSON.parse(filter);
  }
  return {};
};

const setDevicesFilter = (filter: any) => {
  sessionStorage.setItem("devicesFilter", JSON.stringify(filter));
};

const clearDevicesFilter = () => {
  sessionStorage.removeItem("devicesFilter");
};
const getRepossessionsFilter = () => {
  const filter = sessionStorage.getItem("repossessionsFilter");
  if (filter) {
    return JSON.parse(filter);
  }
  return {};
};

const setRepossessionsFilter = (filter: any) => {
  sessionStorage.setItem("repossessionsFilter", JSON.stringify(filter));
};

const clearRepossessionsFilter = () => {
  sessionStorage.removeItem("repossessionsFilter");
};

const getOzonioFilter = () => {
  const filter = sessionStorage.getItem("ozonioFilter");
  if (filter) {
    return JSON.parse(filter);
  }
  return {};
};
const setOzonioFilter = (filter: any) => {
  sessionStorage.setItem("ozonioFilter", JSON.stringify(filter));
};

const clearOzonioFilter = () => {
  sessionStorage.removeItem("ozonioFilter");
};

const getSolicitudesFilter = () => {
  const filter = sessionStorage.getItem("solicitudesFilter");
  if (filter) {
    return JSON.parse(filter);
  }
  return {};
};

const setSolicitudesFilter = (filter: any) => {
  sessionStorage.setItem("solicitudesFilter", JSON.stringify(filter));
};

const clearSolicitudesFilter = () => {
  sessionStorage.removeItem("solicitudesFilter");
};

const getCreditsFilter = () => {
  const filter = sessionStorage.getItem("creditsFilter");
  if (filter) {
    return JSON.parse(filter);
  }
  return {};
};

const setCreditsFilter = (filter: any) => {
  sessionStorage.setItem("creditsFilter", JSON.stringify(filter));
};

const clearCreditsFilter = () => {
  sessionStorage.removeItem("creditsFilter");
};
const getWriteOffFilter = () => {
  const filter = sessionStorage.getItem("writeOffFilter");
  if (filter) {
    return JSON.parse(filter);
  }
  return {};
};

const setWriteOffFilter = (filter: any) => {
  sessionStorage.setItem("writeOffFilter", JSON.stringify(filter));
};

const clearWriteOffFilter = () => {
  sessionStorage.removeItem("writeOffFilter");
};
export {
  getInventoryFilter,
  setInventoryFilter,
  clearInventoryFilter,
  getOzonerFilter,
  setOzonerFilter,
  clearOzonerFilter,
  getOzocioFilter,
  setOzocioFilter,
  clearOzocioFilter,
  getOzonioFilter,
  setOzonioFilter,
  clearOzonioFilter,
  getSolicitudesFilter,
  setSolicitudesFilter,
  clearSolicitudesFilter,
  getCreditsFilter,
  setCreditsFilter,
  clearCreditsFilter,
  getRepossessionsFilter,
  setRepossessionsFilter,
  clearRepossessionsFilter,
  getNotificationsFilter,
  setNotificationsFilter,
  clearNotificationsFilter,
  getDevicesFilter,
  setDevicesFilter,
  clearDevicesFilter,
  getWriteOffFilter,
setWriteOffFilter,
clearWriteOffFilter,
};
