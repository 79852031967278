/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */
import React, { Dispatch, SetStateAction } from "react";

import { FormControl, Grid } from "@mui/material";

import { Input, Button, Typography } from "design_system/src";
import { ReactComponent as Search } from "design_system/src/static/icons/search.svg";
import { ReactComponent as Excel } from "design_system/src/static/icons/excel.svg";

import "./searchBar.scss";

type SearchBarArgs = {
  sortOptions?: Array<{ value: string; label: string }>;
  sortValue?: string;
  setsortValue?: Dispatch<SetStateAction<string>>;
  handleSearch?: (event: any) => void;
  query: string;
  setQuery: Dispatch<SetStateAction<string>>;
  filter?: string | string[];
  setFilter?:
    | Dispatch<SetStateAction<string>>
    | Dispatch<SetStateAction<string[]>>;
  filterOptions?: any[];
  exportText?: string;
  handleExport?: (event: any) => void;
  miniSearchButton?: boolean;
  className?: string;
  filterMultiple?: boolean;
  subview?: boolean;
};

const SearchBar: React.FC<SearchBarArgs> = ({
  sortOptions,
  sortValue = "",
  setsortValue,
  className = "",
  query,
  setQuery,
  handleSearch,
  filter,
  setFilter,
  filterOptions,
  exportText,
  handleExport,
  miniSearchButton = false,
  filterMultiple = false,
  subview = false,
}) => {
  const getInput = () => {
    return (
      <div className="pos_relative flex_grow_1">
        <Input
          title=""
          placeholder="Búsqueda"
          name="search"
          type="text"
          value={query}
          onChange={(e: any) => setQuery(e.target.value)}
        />
        {filterOptions && setFilter && subview === false && (
          <Input
            title=""
            type="select"
            name="filter"
            value={filter}
            multiple={filterMultiple}
            onChange={(e: any) => setFilter(e.target.value)}
            options={filterOptions}
            className={`filter_select ${subview && "w_40_per"}`}
          />
        )}
      </div>
    );
  };

  return (
    <form onSubmit={handleSearch} className={className}>
      <div className="display_flex flex_align_center flex_gap_md">
        
          {getInput()}
          {miniSearchButton ? (
              <Button
                type="submit"
                scale="small"
                icon={<Search />}
                className=""
                onClick={handleSearch}
                variant="icon"
                subvariant="fill"
              />
          ) : (
            <Button
              type="submit"
              scale="small"
              icon={<Search />}
              className=""
              onClick={handleSearch}
            >
              Buscar
            </Button>
          )}
        {exportText && handleExport && (
            <Button
              scale="small"
              icon={<Excel />}
              variant="tint"
              className=""
              onClick={handleExport}
            >
              {exportText}
            </Button>
        )}

        {sortOptions && setsortValue && (
              <Input
                title=""
                placeholder="Ordenar por:"
                name="sortVehicles"
                type="select"
                options={sortOptions}
                className=""
                value={sortValue}
                onChange={(e: any) => setsortValue(e.target.value)}
              />
        )}
      </div>
    </form>
  );
};

export default SearchBar;
