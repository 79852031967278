/* eslint-disable no-nested-ternary */
import React, { useState, useContext } from "react";

// -----------------LIBRARIES IMPORTS------------------- //
// @ts-ignore
import { Button, Input, Typography } from "design_system/src";

// -----------------DATA IMPORTS------------------- //

import { handleEventGoogle } from "helpers/analytics/googleAnalytics";

// -----------------STATIC IMPORTS------------------- //
import "../../../ozoner.scss";

// -----------------COMPONENTS IMPORTS------------------- //
import { toasterNotification } from "helpers/globalNotifications/globalNotifications";
import { toasterOptions } from "enums/toaster.enum";
import { editSolicitud } from "helpers/fetchs/solicitudes";
import { commonErrorHandler } from "helpers/errors/errors";
import {
    reachedMethodsEnum,
    reachedMethodsValues,
} from "models/financialForm.interface";
import { ozonerContext } from "../../OzonerContext";

export default function ReachSection() {
    const { financialForm, setResult } = useContext(ozonerContext);

    const [loading, setLoading] = useState(false);

    const markReachedMethod = (reachedMethod: reachedMethodsEnum) => {
        if (financialForm?.form) {
            setLoading(true);
            handleEventGoogle(
                "BTN_Pb_request_reached_method",
                "Panda Solicitudes",
                "Assign a reach method"
            );
            editSolicitud(financialForm.form._id, { reached: true, reachedMethod })
                .then(() => {
                    setResult();
                    setLoading(false);
                })
                .catch((err) => {
                    commonErrorHandler(err);
                    setLoading(false);
                });
        }
    };

    const markReached = () => {
        if (financialForm?.form) {
            setLoading(true);
            handleEventGoogle(
                "BTN_Pb_request_reached_success",
                "Panda Solicitudes",
                "click in reach request button"
            );
            editSolicitud(financialForm.form._id, { reachedSuccess: true })
                .then(() => {
                    setResult();
                    setLoading(false);
                })
                .catch((err) => {
                    commonErrorHandler(err);
                    setLoading(false);
                });
        }
    };

    return (
        <div
            id="actions-soli-ozoner"
            className="p_md border_b_solid border_1 border_neutral_400 display_flex flex_col flex_gap_xs"
        >
            <Typography scale="medium" weight="400" className="">
                Metodo de contacto
            </Typography>
            <Input
                title=""
                disabled={loading}
                name="reachedMethod"
                placeholder="Metodo de contacto"
                value={financialForm.form.reachedMethod}
                onChange={(e: any) => markReachedMethod(e.target.value)}
                className="w_100_per"
                type="select"
                options={Object.keys(reachedMethodsValues).map((method) => ({
                    label: reachedMethodsValues[method as reachedMethodsEnum],
                    value: method,
                }))}
                variant="default"
            />
            {financialForm.form.reachedMethod ?(
            <Button
                scale="small"
                color="primary"
                disabled={loading}
                onClick={markReached}
            >
                Ozoner respondio
            </Button>
            ):null}
        </div>
    );
}
