/* eslint-disable dot-notation */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from "react";

// icons
import { ReactComponent as File } from "design_system/src/static/icons/download-file.svg";

import {
  Input,
  Typography,
  ModalConfirmation,
  Select,
  DateSelect,
} from "design_system/src";

// css
import "./Credits.scss";
import { FetchCredits, exportCredit } from "helpers/fetchs/credits";
import { CreditStatus, CreditStatusValues } from "models/credit.interface";
import { isArray } from "lodash";
import moment from "moment";
import {
  CreditExportFieldsNames,
  InformeCreditoFields,
} from "models/creditExport.interface";
import { getCreditsFilter } from "helpers/localStorage/filterStorage";
import { toasterNotification } from "helpers/globalNotifications/globalNotifications";
import { toasterOptions } from "enums/toaster.enum";

interface ExportProps {
  open: boolean;
  setOpen: Function;
  currentPage: number;
  currentStatus: string[];
}

const classOptions = ["AAA", "AA", "A", "B", "C"];

let petition: number;

const exportTypes = [
  {
    title: "Informe de Crédito",
    fields: InformeCreditoFields,
  },
];

const formatDate = (date: Date | null) =>
  date ? `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}` : "";
function ExportCredits({
  open,
  setOpen,
  currentPage,
  currentStatus,
}: ExportProps) {
  const [tab, setTab] = useState(0);
  const [isloading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const [type, setType] = useState<number>(0);
  const [selectedFields, setSelectedFields] = useState<string[]>(
    exportTypes[0].fields
  );
  const [scoreFilter, setScoreFilter] = useState<string[]>([]);
  const [statusFilter, setStatusFilter] = useState<string[]>([
    CreditStatus.pending,
  ]);
  const [datesFilter, setDatesFilter] = useState({
    initial: new Date(),
    last: new Date(),
    option: -1,
  });
  const [hasDatesFilter, setHasDatesFilter] = useState(false);

  const [minPage, setMinPage] = useState<number>(currentPage);
  const [maxPage, setMaxPage] = useState<number>(currentPage);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [count, setCount] = useState<any>();
  const [paging, setPaging] = useState<any>();

  const requestCredits = () => {
    const num = petition + 1;
    petition += 1;
    setLoading(true);
    const filters = [];

    if (scoreFilter.length && scoreFilter.length !== classOptions.length)
      filters.push(`classification=${scoreFilter}`);
    if (
      statusFilter.length &&
      statusFilter.length !== Object.keys(CreditStatusValues).length
    )
      filters.push(`status=${statusFilter}`);

    if (datesFilter && hasDatesFilter) {
      filters.push(
        `min=${moment
          .utc(datesFilter.initial)
          .format("YYYY-MM-DD")}&max=${moment
          .utc(datesFilter.last)
          .format("YYYY-MM-DD")}`
      );
    }
    FetchCredits(minPage, undefined, undefined, filters.join("&")).then(
      (res) => {
        if (res && num === petition) {
          setLoading(false);
          setCount(res.count);
          setTotalPages(res.pages);
          if (res.pages > 0 && maxPage > res.pages) {
            setMaxPage(res.pages);
          }
        }
      }
    );
  };


  useEffect(() => {
    petition = 0;
    const information = getCreditsFilter();
    if (information.statusFilter) {
      setStatusFilter(information.statusFilter);
    }
    if (information.scoreFilter) {
      setScoreFilter(information.scoreFilter);
    }
    if (information?.datesFilter) {
      setDatesFilter({
        ...information.datesFilter,
        initial: new Date(information.datesFilter.initial),
        last: new Date(information.datesFilter.last),
      });

      setHasDatesFilter(true);
    }
    requestCredits();
  }, []);
  useEffect(() => {
    requestCredits();
  }, [statusFilter, scoreFilter, datesFilter, hasDatesFilter]);

  const exportCredits = () => {
    setIsLoading(true);

    let pages;
    let limit;
    if (minPage === 1 && maxPage === totalPages) {
      pages = undefined;
      limit = -1;
    } else {
      pages = `${minPage}-${maxPage}`;
      limit = undefined;
    }

    const filters = [];

    if (scoreFilter.length && scoreFilter.length !== classOptions.length)
      filters.push(`classification=${scoreFilter}`);
    if (
      statusFilter.length &&
      statusFilter.length !== Object.keys(CreditStatusValues).length
    )
      filters.push(`status=${statusFilter}`);

    if (datesFilter && hasDatesFilter) {
      filters.push(
        `min=${moment
          .utc(datesFilter.initial)
          .format("YYYY-MM-DD")}&max=${moment
          .utc(datesFilter.last)
          .format("YYYY-MM-DD")}`
      );
    }

    exportCredit(
      pages,
      filters.join("&"),
      selectedFields.join(","),
      limit
    ).then((data) => {
      const blob = new Blob([data], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      if (link.download !== undefined) {
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "credits.csv");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      setOpen(false);
      setIsLoading(false);
    }).catch((e)=>{
      setIsLoading(false);
      toasterNotification({
        msg: `Algo fallo: ${e.message}`,
        toasterType: toasterOptions.error,
        style: "light",
      });
    });
  };
  useEffect(() => {
    if (minPage < 1) {
      setMinPage(1);
    }
    if (minPage > maxPage) {
      setMinPage(maxPage);
    }
    if (maxPage < minPage) {
      setMaxPage(minPage);
    }
    if (totalPages > 0 && maxPage > totalPages) {
      setMaxPage(totalPages);
    }

    if (minPage === 1 && maxPage === totalPages) {
      setPaging(0);
    } else if (minPage === 1 && maxPage === 1) {
      setPaging(1);
    } else if (minPage === 1 && maxPage === 5) {
      setPaging(2);
    } else {
      setPaging(-1);
    }
  }, [minPage, maxPage]);

  return (
    <ModalConfirmation
      openConfirmation={open}
      handleClose={() => {
        setOpen(false);
      }}
      icon={<File />}
      isConfirmationLoading={false}
      widthDesktop={tab === 0 ? 70 : 30}
      heightDesktop={tab === 0 ? 100 : undefined}
      actions={
        tab === 0
          ? [
              {
                label: "Cancelar",
                color: "grey",
                action: () => setOpen(false),
              },
              {
                label: "Siguiente",
                color: "primary",
                disabled: !count || loading,
                action: () => setTab(1),
              },
            ]
          : [
              { label: "Volver", color: "grey", action: () => setTab(0) },
              {
                label: "Exportar",
                color: "primary",
                action: () => exportCredits(),
                loading: isloading,
              },
            ]
      }
    >
      {tab === 0 ? (
        <>
          <Typography
            scale="large"
            weight="600"
            textColor="neutral_1000"
            className="m_b_sm"
          >
            ¿Quieres exportar Créditos ?
          </Typography>
          <Typography
            scale="medium"
            weight="400"
            textColor="neutral_700"
            className="m_b_sm"
          >
            Selecciona los filtros y campos.
          </Typography>
          <div className="display_flex flex_col flex_gap_sm m_b_sm">
            <div className="display_flex flex_gap_sm">
              <div className="flex_basis_0 flex_grow_1">
                <DateSelect
                  value={datesFilter}
                  hasValue={hasDatesFilter}
                  setHasValue={setHasDatesFilter}
                  placeholder="Fecha de creación"
                  setValue={(e: any) => {
                    setDatesFilter(e);
                    setHasDatesFilter(true);
                  }}
                />
              </div>
              <div className="flex_basis_0 flex_grow_1">
                <Select
                  fixedPlaceholder
                  variant="checkbox"
                  placeholder="Calificación"
                  options={classOptions}
                  value={scoreFilter}
                  onChange={(e: any) => {
                    setScoreFilter(e.target.value);
                  }}
                />
              </div>
            </div>
            <Select
              fixedPlaceholder
              variant="checkbox"
              placeholder="Estado"
              options={Object.keys(CreditStatusValues).map((key: any) => ({
                label: CreditStatusValues[key as CreditStatus].title,
                value: key,
              }))}
              value={statusFilter}
              onChange={(e: any) => {
                setStatusFilter(e.target.value);
              }}
            />
          </div>
          {/* <Typography
            scale="large"
            weight="600"
            textColor="neutral_900"
            className="m_b_sm"
          >
            Tipo de Informe
          </Typography>
          <Select
            fixedPlaceholder
            placeholder="Selecciona el tipo"
            className="w_100_per bg_white m_b_md"
            options={exportTypes.map((tipo, i) => ({
              label: tipo.title,
              value: i,
            }))}
            value={type}
            onChange={(e: any) => {
              setSelectedFields(e.target.value);
              setType(e.target.value);
            }}
          /> */}
          <Select
            fixedPlaceholder
            placeholder="Selecciona los campos"
            className="w_100_per bg_white m_b_md"
            multiple
            options={exportTypes[type].fields.map((key) => ({
              label: CreditExportFieldsNames[key],
              value: key,
            }))}
            value={selectedFields}
            onChange={(e: any) => setSelectedFields(e.target.value)}
          />

          <div className="display_flex flex_align_end m_t_md">
            {loading ? (
              <Typography scale="large" weight="600" className="m_r_xxs">
                Cargando...
              </Typography>
            ) : (
              <>
                <Typography scale="large" weight="600" className="m_r_xxs">
                  {count ?? 0}
                </Typography>
                <Typography scale="medium" weight="400">
                  créditos
                </Typography>
              </>
            )}
          </div>
        </>
      ) : (
        <>
          <Typography weight="600" scale="heading4" className="m_b_xs">
            Paginación
          </Typography>
          <div className="display_flex br_xs shadow_medium overflow_hidden">
            <div className="flex_grow_1 flex_basis_0  bg_neutral_0 overflow_hidden">
              <div className="p_x_lg p_y_sm">
                <Input
                  type="counter"
                  title="Desde la:"
                  name="desde"
                  titleClassName="text_center w_100_per"
                  min={1}
                  max={totalPages}
                  onChange={(e: any) =>
                    setMinPage(parseInt(e.target.value, 10))
                  }
                  value={minPage}
                />
                <Input
                  type="counter"
                  title="Hasta la:"
                  name="hasta"
                  titleClassName="text_center w_100_per"
                  min={1}
                  max={totalPages}
                  onChange={(e: any) =>
                    setMaxPage(parseInt(e.target.value, 10))
                  }
                  value={maxPage}
                />
              </div>
            </div>
            <div className="flex_grow_1 flex_basis_0  bg_neutral_300 overflow_hidden">
              <div className="p_t_sm p_r_sm">
                {totalPages > 1 && (

                <Typography
                  weight="600"
                  scale="small"
                  className={`p_y_sm p_x_md m_b_sm text_no_break  br_r_md ${
                    paging === 0 ? "bg_neutral_0" : ""
                  }`}
                  onClick={() => {
                    setMinPage(1);
                    setMaxPage(totalPages);
                  }}
                >
                  Todas
                </Typography>
                )}
                <Typography
                  weight="600"
                  scale="small"
                  className={`p_y_sm p_x_md m_b_sm text_no_break br_r_md ${
                    paging === 1 || totalPages===1 ? "bg_neutral_0" : ""
                  }`}
                  onClick={() => {
                    setMinPage(1);
                    setMaxPage(1);
                  }}
                >
                  La primera página
                </Typography>
                {totalPages > 1 && (

                <Typography
                  weight="600"
                  scale="small"
                  className={`p_y_sm p_x_md m_b_sm text_no_break br_r_md ${
                    paging === 2 ? "bg_neutral_0" : ""
                  }`}
                  onClick={() => {
                    setMinPage(1);
                    setMaxPage(5);
                  }}
                >
                  Las primeras 5
                </Typography>
                )}

              </div>
            </div>
          </div>
          <div className="display_flex flex_align_end m_t_md">
            <Typography scale="large" weight="600" className="m_r_xxs">
              {Math.min(count, (maxPage + 1 - minPage) * 30) ?? 0}
            </Typography>
            <Typography scale="medium" weight="400">
              Ozoners
            </Typography>
          </div>
        </>
      )}
    </ModalConfirmation>
  );
}

export default ExportCredits;
