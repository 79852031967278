/* eslint-disable no-nested-ternary */
/* eslint-disable no-case-declarations */
/* eslint-disable no-unused-vars */
import { Grid } from "@mui/material";
import ReactGA from "react-ga4";
import React, { useEffect, useState } from "react";
import CardInventory, {
    colorsMaintenance,
} from "components/hocs/cardInventory/CardInventory";

// css
import "./inventory.scss";

import { ReactComponent as Checkmark } from "design_system/src/static/icons/checkmark.svg";
import { ReactComponent as Eyedropper } from "design_system/src/static/icons/eyedropper-tool.svg";
import { ReactComponent as Lockcircle } from "design_system/src/static/icons/lock-circle.svg";
import { ReactComponent as Hand } from "design_system/src/static/icons/hand.svg";
import { ReactComponent as Deleteprice } from "design_system/src/static/icons/delete-price.svg";
import { ReactComponent as AddCircle } from "design_system/src/static/icons/add-circle.svg";
import { ReactComponent as Excel } from "design_system/src/static/icons/excel.svg";
import { ReactComponent as Pin } from "design_system/src/static/icons/pin.svg";
import { ReactComponent as Eye } from "design_system/src/static/icons/eye.svg";
import { ReactComponent as EyeOff } from "design_system/src/static/icons/eye-off.svg";
import { ReactComponent as Edit } from "design_system/src/static/icons/edit-ui-2.svg";
import { ReactComponent as Moto } from "design_system/src/static/icons/Motocycle.svg";
import { ReactComponent as Signal } from "design_system/src/static/icons/radio-signal.svg";
import { ReactComponent as Action } from "design_system/src/static/icons/more-v.svg";
import { ReactComponent as List } from "design_system/src/static/icons/bulleted-list-2.svg";
import { ReactComponent as Boxes } from "design_system/src/static/icons/box_2.svg";
import { LightTooltip } from "components/hocs/tooltip/Tooltip";

import { getBrandsWithModels, getVehicles } from "helpers/fetchs/inventory";
import {
    MaintenanceColorValues,
    TVehicle,
    VehicleSortOptions,
    statusVehicles,
    statusVehiclesEnum,
} from "models/vehicle.interface";
import {
    Typography,
    Button,
    Pager,
    DateSelect,
    Popup,
    Select,
    ListTable,
} from "design_system/src";
import { useLocation, useNavigate } from "react-router-dom";
import {
    getInventoryFilter,
    setInventoryFilter,
} from "helpers/localStorage/filterStorage";
import { getUser } from "helpers/localStorage/authStorage";
import {
    addUserFilter,
    deleteUserFilter,
    getOzonioById,
} from "helpers/fetchs/ozonios";
import { FilterSections, UserFilter } from "models/ozonio.interface";
import moment from "moment";
import SaveFilter from "components/hocs/saveFilter/SaveFilter";
import motoLoading from "static/icons/loading_moto.gif";
import { priceFormatFinancial } from "helpers/prices/prices";
import EditVehicle from "./EditVehicle";
import ExportInventory from "./ExportInventory";
import SearchBar from "../../components/hocs/searchBar/searchBar";

interface DiscountSort {
    type: string;
    percentageValue: number;
    createdAt: string;
    id: string;
    status: string;
}

interface AvailableFilters {
    available: boolean;
    delivered: boolean;
    aside: boolean;
    lost: boolean;
    inRecovery: boolean;
    recovered: boolean;
    left: boolean;
    underMaintenance: boolean;
    draft: boolean;
}
const defaultFilters = {
    available: true,
    delivered: true,
    aside: true,
    lost: true,
    inRecovery: true,
    recovered: true,
    left: true,
    underMaintenance: true,
    draft: true,
};
interface InventoryProps {
    onClick?: Function | null;
    initialFilter?: string[];
    availableFilters?: AvailableFilters;
    maxQuota?: number;
}

const filterOptions = [
    {
        _id: "available_hide_vehicles",
        name: "Vehiculos disponibles",
        values: {
            statusFilter: [statusVehiclesEnum.available],
            weeksFilter: [],
        },
        default: true,
    },
    {
        _id: "no_available_vehicles",
        name: "Vehiculos no disponibles ",
        values: {
            statusFilter: [
                statusVehiclesEnum.left,
                statusVehiclesEnum.underMaintenance,
                statusVehiclesEnum.draft,
            ],
            weeksFilter: [],
        },
        default: true,
    },
];

const getStatusFilterName = (status: string) =>
    `statusPersonalFilter_${status}`;

let petition: number;
// eslint-disable-next-line quotes
function Inventory({
    onClick = null,
    initialFilter = [],
    availableFilters = defaultFilters,
    maxQuota,
}: InventoryProps) {
    const navigate = useNavigate();
    const location = useLocation();

    const [viewType, setViewType] = useState(true);

    const [openDetails, setOpenDetails] = useState<boolean>(false);
    const [openExport, setOpenExport] = useState<boolean>(false);
    const [totalPages, settotalPages] = useState<number>(0);
    const [totals, setTotals] = useState<any>({});
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [vehicles, setvehicles] = useState<TVehicle[] | null>();
    const [vehiclesSorted, setVehiclesSorted] = useState<TVehicle[] | null>();
    const [subview, setSubview] = useState<boolean>(false);
    const [inputQuery, setInputQuery] = useState<string>("");
    const [query, setQuery] = useState<string>("");
    const [result, setResult] = useState();
    const [loading, setLoading] = useState(false);

    // SORT
    const sortOptions = [
        { value: VehicleSortOptions.HIGHER_PRICE, label: "Precio más alto" },
        { value: VehicleSortOptions.LOWER_PRICE, label: "Precio más bajo" },
        { value: VehicleSortOptions.HIGHER_CC, label: "Mayor CC" },
        { value: VehicleSortOptions.LOWER_CC, label: "Menor CC" },
        { value: VehicleSortOptions.PROMOTION, label: "En promoción" },
        { value: VehicleSortOptions.LOWER_KM, label: "Menos KM" },
        { value: VehicleSortOptions.HIGHER_KM, label: "Más KM" },
    ];

    const weeksOptions = [
        { value: "1", label: "De contado" },
        { value: "52", label: "52 Cuotas" },
        { value: "78", label: "78 Cuotas" },
        { value: "104", label: "104 Cuotas" },
        { value: "156", label: "156 Cuotas" },
    ];

    const [sortValue, setsortValue] = useState<string>(VehicleSortOptions.HIGHER_PRICE);

    // -----------------FILTERS STATES------------------- //
    const [openConfirmFilter, setOpenConfirmFilter] = useState(false);
    const [callbackConfirm, setCallbackConfirm] = useState<any>();
    const [openOptions, setOpenOptions] = useState("");
    const [personalFilters, setPersonalFilters] = useState<UserFilter[]>([]);
    const [selectedPersonalFilter, setSelectedPersonalFilter] =
        useState<string>("");
    const [openSaveFilter, setOpenSaveFilter] = useState<boolean>(false);
    const [statusFilter, setStatusFilter] = useState<string[]>([]);
    const [weeksFilter, setWeeksFilter] = useState([]);
    const [brandFilter, setBrandFilter] = useState("");
    const [datesFilter, setDatesFilter] = useState({
        initial: new Date(),
        last: new Date(),
        option: -1,
    });
    const [hasDatesFilter, setHasDatesFilter] = useState(false);

    const [brands, setBrands] = useState<any[]>([]);

    const [count, setCount] = useState(0);

    const requestFilters = () => {
        const userL = getUser();
        getOzonioById(userL.userId).then((data) => {
            setPersonalFilters([
                ...filterOptions,
                ...(data?.personalFilters?.filter(
                    (fil: UserFilter) =>
                        fil.section === FilterSections.vehiclesList
                ) ?? []),
            ]);
        });
    };
    const changePersonalFilter = (uFilter: UserFilter) => {
        if (uFilter._id !== selectedPersonalFilter) {
            setSelectedPersonalFilter(uFilter._id);
            setStatusFilter(uFilter.values.statusFilter ?? []);
            setWeeksFilter(uFilter.values.weekFilter ?? []);
            setBrandFilter(uFilter.values.brandFilter ?? []);
            if (uFilter.values.datesFilter) {
                setDatesFilter({
                    ...uFilter.values.datesFilter,
                    initial: new Date(uFilter.values.datesFilter.initial),
                    last: new Date(uFilter.values.datesFilter.last),
                });
                setHasDatesFilter(true);
            } else {
                setHasDatesFilter(false);
            }
        }
    };

    const setStatusPersonalFilter = (value: string) => {
        const name = getStatusFilterName(value);
        setSelectedPersonalFilter(name);
        setWeeksFilter([]);
        setBrandFilter("");
        setHasDatesFilter(false);
        setStatusFilter([value]);
    };

    const confirmChangeFilter = () => {
        const { type, value } = callbackConfirm;
        if (type) {
            setStatusPersonalFilter(value);
        } else {
            changePersonalFilter(value);
        }
    };

    const changingFilter = (type: boolean, value: any) => {
        if (
            selectedPersonalFilter ||
            (!weeksFilter.length &&
                !brandFilter.length &&
                !statusFilter.length &&
                !hasDatesFilter)
        ) {
            if (type) {
                setStatusPersonalFilter(value);
            } else {
                changePersonalFilter(value);
            }
        } else {
            setCallbackConfirm({ type, value });
            setOpenConfirmFilter(true);
        }
    };

    const deletePersonalFilter = (uFilter: UserFilter) => {
        deleteUserFilter(getUser().userId, uFilter._id).then(() => {
            setSelectedPersonalFilter("");
            requestFilters();
        });
    };

    const saveFilter = (name: string) => {
        return addUserFilter(
            getUser().userId,
            name,
            FilterSections.vehiclesList,
            {
                weeksFilter,
                brandFilter,
                datesFilter: hasDatesFilter ? datesFilter : undefined,
                statusFilter,
            }
        ).then((e: any) => {
            requestFilters();
            setSelectedPersonalFilter(
                e.personalFilters[e.personalFilters.length - 1]._id
            );
            return e;
        });
    };

    const handleDetailsVeh = (id: string) => {
        navigate(`/inventory/${id}`);
    };
    // END SORT

    const requestVehicles = () => {
        const num = petition + 1;
        petition += 1;

        const filters = [];
        const sort = sortValue === "" ? undefined : sortValue;

        if (weeksFilter.length && weeksFilter.length !== weeksOptions.length) {
            filters.push(`weeks=${weeksFilter}`);
        }
        if (
            (weeksFilter.length &&
                weeksFilter.length !== weeksOptions.length) ||
            maxQuota
        ) {
            filters.push(`salePrice=0,${maxQuota ?? 100000}`);
        }

        if (brandFilter && brandFilter !== "")
            filters.push(`brand=${brandFilter}`);
        if (
            statusFilter.length &&
            statusFilter.length !== Object.keys(statusVehicles).length
        )
            filters.push(`status=${statusFilter}`);

        if (datesFilter && hasDatesFilter) {
            filters.push(
                `minCreatedAt=${moment
                    .utc(datesFilter.initial)
                    .format("YYYY-MM-DD")}&maxCreatedAt=${moment
                    .utc(datesFilter.last)
                    .format("YYYY-MM-DD")}`
            );
        }

        setLoading(true);

        setCount(0);
        getVehicles(currentPage, undefined, query, filters.join("&"), undefined, sort)
            .then((res) => {
                if (res && num === petition) {
                    setvehicles(res.data);
                    setVehiclesSorted(res.data);
                    settotalPages(res.pages);
                    setCount(res.count);
                    setTotals(res.totals);
                    setLoading(false);
                }
            })
            .catch(() => {
                setLoading(false);
                setvehicles([]);
                setVehiclesSorted([]);
            });
    };

    useEffect(() => {
        ReactGA.event("VIEW_Panda_inventory", {
            category: "Panda",
            label: "page view to the vehicles list view",
        });
        petition = 0;
        const queryParams = new URLSearchParams(location.search);
        const queryStatusFilter = queryParams.get("statusFilter");
        const information = getInventoryFilter();
        if (information.query && !onClick) {
            setQuery(information.query);
            setInputQuery(information.query);
        }
        if (information?.datesFilter) {
            setDatesFilter({
                ...information.datesFilter,
                initial: new Date(information.datesFilter.initial),
                last: new Date(information.datesFilter.last),
            });

            setHasDatesFilter(true);
        }
        if (information.selectedPersonalFilter) {
            setSelectedPersonalFilter(information.selectedPersonalFilter);
        }
        if (information.weeksFilter) {
            setWeeksFilter(information.weeksFilter);
        }
        if (information.brandFilter) {
            setBrandFilter(information.brandFilter);
        }
        if (information.currentPage) {
            setCurrentPage(information.currentPage);
        }
        if (initialFilter.length) {
            setStatusFilter(initialFilter);
        } else if (information.statusFilter?.length) {
            setStatusFilter(information.statusFilter);
        } else if (queryStatusFilter) {
            setStatusFilter([queryStatusFilter]);
        } else {
            setStatusFilter([]);
        }
        if (onClick) {
            setSubview(true);
        } else {
            setSubview(false);
        }
        requestFilters();

        getBrandsWithModels().then((res) => {
            if (res) {
                const maped = res
                    .map((ele) => ele.name)
                    .sort((a: any, b: any) => {
                        if (a < b) {
                            return -1;
                        }
                        if (a > b) {
                            return 1;
                        }
                        return 0;
                    });
                setBrands(maped);
            }
        });
    }, []);

    useEffect(() => {
        if (currentPage === 1) {
            requestVehicles();
        } else {
            setCurrentPage(1);
        }
    }, [
        statusFilter,
        result,
        query,
        weeksFilter,
        brandFilter,
        datesFilter,
        hasDatesFilter,
        sortValue
    ]);
    useEffect(() => {
        requestVehicles();
    }, [currentPage]);
    useEffect(() => {
        if (!onClick)
            setInventoryFilter({
                statusFilter,
                query,
                currentPage,
                weeksFilter,
                brandFilter,
                datesFilter: hasDatesFilter ? datesFilter : null,
                selectedPersonalFilter,
            });
    }, [
        statusFilter,
        query,
        currentPage,
        weeksFilter,
        brandFilter,
        datesFilter,
        hasDatesFilter,
        selectedPersonalFilter,
    ]);

    const handleSearch = (event: any) => {
        event.preventDefault();
        setQuery(inputQuery);
    };

    const renderContent = () => {
        return (
            <div
                className={`dim_100_per flex_content_col ${
                    subview ? "" : "shadow_hard inner_container bg_neutral_400"
                }`}
            >
                <div className="flex_header">
                    <div
                        className={
                            subview
                                ? "p_t_xs"
                                : "bg_neutral_0 shadow_hard p_t_xs p_b_md p_x_lg"
                        }
                    >
                        <div className="display_flex flex_gap_sm flex_align_center">
                            {subview ? null : (
                                <Typography
                                    scale="heading3"
                                    weight="600"
                                    textColor="neutral_900"
                                >
                                    Inventario
                                </Typography>
                            )}
                            <SearchBar
                                sortOptions={sortOptions}
                                sortValue={sortValue}
                                setsortValue={setsortValue}
                                handleSearch={handleSearch}
                                query={inputQuery}
                                setQuery={setInputQuery}
                                miniSearchButton={subview}
                                subview={subview}
                                className="flex_grow_1"
                            />
                        </div>
                        <div className="display_flex flex_gap_xl m_b_xs">
                            <div
                                className={`display_flex flex_grow_1 ${
                                    subview
                                        ? "flex_col flex_gap_md"
                                        : "flex_gap_xs"
                                }`}
                            >
                                <Select
                                    fixedPlaceholder
                                    variant="checkbox"
                                    placeholder="Estado"
                                    options={Object.keys(statusVehicles)
                                        .filter(
                                            (key) =>
                                                (availableFilters as any)[key]
                                        )
                                        .map((key: any) => ({
                                            label: (statusVehicles as any)[key]
                                                .title,
                                            value: key,
                                        }))}
                                    value={statusFilter}
                                    onChange={(e: any) => {
                                        setStatusFilter(e.target.value);
                                        setSelectedPersonalFilter("");
                                    }}
                                />
                                <Select
                                    fixedPlaceholder
                                    variant="checkbox"
                                    placeholder="Semanas"
                                    options={weeksOptions}
                                    value={weeksFilter}
                                    onChange={(e: any) => {
                                        setWeeksFilter(e.target.value);
                                        setSelectedPersonalFilter("");
                                    }}
                                />
                                <Select
                                    fixedPlaceholder
                                    variant="default"
                                    placeholder="Marca"
                                    options={[
                                        ...brands,
                                        { value: "", label: "Marca" },
                                    ]}
                                    value={brandFilter}
                                    onChange={(e: any) => {
                                        setBrandFilter(e.target.value);
                                        setSelectedPersonalFilter("");
                                    }}
                                />
                                {!subview && (
                                    <DateSelect
                                        value={datesFilter}
                                        hasValue={hasDatesFilter}
                                        placeholder="Fecha de creación"
                                        setHasValue={setHasDatesFilter}
                                        setValue={(e: any) => {
                                            setDatesFilter(e);
                                            setHasDatesFilter(true);
                                            setSelectedPersonalFilter("");
                                        }}
                                    />
                                )}
                            </div>
                            {!subview && (
                                <div className="display_flex flex_gap_xs flex_grow_0 flex_align_center">
                                    <Button
                                        variant="ghost"
                                        scale="small"
                                        icon={<Pin />}
                                        onClick={() => setOpenSaveFilter(true)}
                                    >
                                        Fijar filtro
                                    </Button>
                                    <LightTooltip title="Vista de tabla">
                                        <div
                                            className={`selectable_icon ${
                                                viewType ? "active" : ""
                                            }`}
                                            role="button"
                                            tabIndex={0}
                                            onClick={() => setViewType(true)}
                                            onKeyDown={() => setViewType(true)}
                                        >
                                            <List className="dim_lg" />
                                        </div>
                                    </LightTooltip>
                                    <LightTooltip title="Vista en cards">
                                        <div
                                            className={`selectable_icon ${
                                                !viewType ? "active" : ""
                                            }`}
                                            role="button"
                                            tabIndex={0}
                                            onClick={() => setViewType(false)}
                                            onKeyDown={() => setViewType(false)}
                                        >
                                            <Boxes className="dim_lg" />
                                        </div>
                                    </LightTooltip>
                                    <LightTooltip title="Exportar inventario">
                                        <div
                                            className="selectable_icon"
                                            role="button"
                                            tabIndex={0}
                                            onClick={() => setOpenExport(true)}
                                            onKeyDown={() =>
                                                setOpenExport(true)
                                            }
                                        >
                                            <Excel className="dim_lg" />
                                        </div>
                                    </LightTooltip>
                                </div>
                            )}
                        </div>
                        <div className="display_flex flex_gap_xl overflow_x_auto p_b_md">
                            {!subview &&
                                personalFilters.map((uFilter) => (
                                    <div
                                        key={uFilter._id}
                                        className={`display_flex flex_gap_sm p_x_md p_y_xs status_tab pos_relative ${
                                            selectedPersonalFilter ===
                                            uFilter._id
                                                ? "active"
                                                : ""
                                        }
              `}
                                    >
                                        <Typography
                                            scale="small"
                                            weight={
                                                selectedPersonalFilter ===
                                                uFilter._id
                                                    ? "600"
                                                    : "400"
                                            }
                                            onClick={() =>
                                                changingFilter(false, uFilter)
                                            }
                                            key={`status-filter-${uFilter._id}`}
                                            className="cursor_pointer "
                                        >
                                            {uFilter.name}
                                        </Typography>
                                        {!uFilter.default && (
                                            <>
                                                <Action
                                                    className="dim_md cursor_pointer text_neutral_600"
                                                    onClick={() =>
                                                        setOpenOptions(
                                                            uFilter._id
                                                        )
                                                    }
                                                />
                                                <Popup
                                                    open={
                                                        openOptions ===
                                                        uFilter._id
                                                    }
                                                    setOpen={() =>
                                                        setOpenOptions("")
                                                    }
                                                    placementX="right-inset"
                                                    className="bg_neutral_100 br_xxs m_r_md"
                                                >
                                                    <Typography
                                                        scale="xsmall"
                                                        weight="400"
                                                        className="p_xs border_b_solid border_b_1 border_neutral_400 cursor_pointer menu_selectable"
                                                        onClick={() =>
                                                            deletePersonalFilter(
                                                                uFilter
                                                            )
                                                        }
                                                        onKeyPress={() =>
                                                            deletePersonalFilter(
                                                                uFilter
                                                            )
                                                        }
                                                        tabIndex={0}
                                                        role="button"
                                                    >
                                                        Eliminar filtro
                                                    </Typography>
                                                </Popup>
                                            </>
                                        )}
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>

                <div
                    className={`flex_body overflow_x_hidden ${
                        subview ? "" : viewType ? "p_x_lg" : "body_listing"
                    }`}
                >
                    {loading ? (
                        <div className="flex_center_col dim_100_per">
                            <Typography
                                weight="600"
                                scale="heading4"
                                textColor="neutral_800"
                                className="m_b_xs"
                            >
                                Cargando los vehiculos...
                            </Typography>
                            <img src={motoLoading} alt="" className="h_xxxl" />
                        </div>
                    ) : vehiclesSorted?.length ? (
                        <>
                            {viewType && !subview ? (
                                <ListTable
                                    data={vehiclesSorted}
                                    onRowClick={(item) =>
                                        handleDetailsVeh(item.internalId)
                                    }
                                    canSelect={false}
                                    rowClassName="br_none"
                                    hideHeader
                                    className="m_t_md"
                                    cols={[
                                        {
                                            label: "img",
                                            weight: 0,
                                            render: (
                                                row: TVehicle,
                                                index: number
                                            ) => (
                                                <div className="vehicle_list_img">
                                                    <img
                                                        className="img dim_100_per"
                                                        src={
                                                            row.images[0]
                                                                ? row.images[0]
                                                                      .url
                                                                : `${process.env.REACT_APP_STORAGE_BASE_URL}public/uploads/generic-moto.png`
                                                        }
                                                        alt="vehicle"
                                                    />
                                                </div>
                                            ),
                                        },
                                        {
                                            label: "name",
                                            weight: 4,
                                            render: (
                                                row: TVehicle,
                                                index: number
                                            ) => (
                                                <Typography
                                                    scale="medium"
                                                    weight="600"
                                                >
                                                    {`${row.brand?.name} ${
                                                        row.model?.name
                                                    } ${
                                                        row.cylindersCapacity
                                                    } ${
                                                        row.suffix
                                                            ? row.suffix
                                                            : ""
                                                    }`}
                                                </Typography>
                                            ),
                                        },
                                        {
                                            label: "options",
                                            weight: 3,
                                            render: (
                                                row: TVehicle,
                                                index: number
                                            ) => (
                                                <div className="display_flex flex_align_center flex_gap_xs">
                                                    <LightTooltip
                                                        title={
                                                            row.maintenanceDays
                                                                ? `Mantenimiento de ${row.maintenanceDays} dias`
                                                                : "No dispone de informacion"
                                                        }
                                                    >
                                                        <Moto
                                                            className={`dim_lg text_${
                                                                MaintenanceColorValues[
                                                                    row
                                                                        .maintenanceColor
                                                                ]?.color ??
                                                                "primary_300"
                                                            }`}
                                                        />
                                                    </LightTooltip>
                                                    <LightTooltip
                                                        title={
                                                            row.gpsStatus ===
                                                            "withSignal"
                                                                ? "Reporta señal GPS"
                                                                : "No reporta señal GPS"
                                                        }
                                                    >
                                                        <Signal
                                                            className={`dim_lg text_${
                                                                row.gpsStatus ===
                                                                "withSignal"
                                                                    ? "green_300"
                                                                    : "neutral_300"
                                                            }`}
                                                        />
                                                    </LightTooltip>
                                                    <div className="flex_center_col">
                                                        <Typography
                                                            scale="medium"
                                                            weight="400"
                                                            textColor="neutral_900"
                                                            className="text_center"
                                                        >
                                                            {row.internalId}
                                                        </Typography>
                                                        <Typography
                                                            scale="xsmall"
                                                            weight="400"
                                                            textColor="neutral_600"
                                                            className="text_center"
                                                        >
                                                            Creación:
                                                        </Typography>
                                                        <Typography
                                                            scale="xsmall"
                                                            weight="400"
                                                            textColor="neutral_600"
                                                            className="text_center"
                                                        >
                                                            {row.createdAt
                                                                ? moment(
                                                                      row.createdAt
                                                                  ).format(
                                                                      "DD/MM/YYYY"
                                                                  )
                                                                : "--"}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            ),
                                        },
                                        {
                                            label: "prices",
                                            weight: 7,
                                            render: (
                                                row: TVehicle,
                                                index: number
                                            ) =>
                                                row.salePrices ? (
                                                    <div className="display_flex flex_align_center flex_gap_xl">
                                                        {Object.keys(
                                                            row.salePrices
                                                        ).map((key) => {
                                                            const price =
                                                                row.salePrices[
                                                                    key
                                                                ];
                                                            const newPrice = {
                                                                ...price,
                                                            };
                                                            const discount =
                                                                row?.discounts?.filter(
                                                                    (ele) =>
                                                                        ele.status ===
                                                                        "active"
                                                                )[0];

                                                            if (discount) {
                                                                newPrice.oldPaymentWeek =
                                                                    newPrice.paymentWeek;
                                                                switch (
                                                                    discount.type
                                                                ) {
                                                                    case "percentage":
                                                                        newPrice.paymentWeek =
                                                                            price.paymentWeek *
                                                                            (1 -
                                                                                discount.percentageValue);
                                                                        break;
                                                                    case "net":
                                                                        newPrice.paymentWeek =
                                                                            price.paymentWeek -
                                                                            discount.netValue;
                                                                        break;
                                                                    default:
                                                                        break;
                                                                }
                                                            }
                                                            return (
                                                                <div className="flex_center_col">
                                                                    {newPrice?.oldPaymentWeek && (
                                                                        <Typography
                                                                            scale="xsmall"
                                                                            weight="600"
                                                                            className="text_center text_red_300 text_decoration_line-through"
                                                                        >
                                                                            {priceFormatFinancial(
                                                                                newPrice?.oldPaymentWeek,
                                                                                0
                                                                            )}
                                                                        </Typography>
                                                                    )}
                                                                    <Typography
                                                                        scale="large"
                                                                        weight="600"
                                                                        className="text_center text_neutral_900"
                                                                    >
                                                                        {priceFormatFinancial(
                                                                            newPrice?.paymentWeek ??
                                                                                0,
                                                                            2
                                                                        )}
                                                                    </Typography>
                                                                    <Typography
                                                                        scale="xsmall"
                                                                        weight="400"
                                                                        className="text_center text_neutral_900"
                                                                    >
                                                                        {
                                                                            newPrice.weeks
                                                                        }{" "}
                                                                        semanas
                                                                    </Typography>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                ) : (
                                                    <div />
                                                ),
                                        },
                                        {
                                            label: "estado",
                                            weight: 2,
                                            render: (
                                                row: TVehicle,
                                                index: number
                                            ) => (
                                                <div
                                                    className={`
                          dso_chip_small flex_grow_1 
                          bg_${
                              statusVehicles[
                                  row.status as keyof typeof statusVehicles
                              ]?.color
                          }_nocontrast text_neutral_0 flex_basis_0 overflow_hidden overflow_ellipsis text_center
                          `}
                                                >
                                                    {statusVehicles[
                                                        row.status as keyof typeof statusVehicles
                                                    ]?.title ?? row.status}
                                                </div>
                                            ),
                                        },
                                    ]}
                                />
                            ) : (
                                <Grid
                                    container
                                    direction="row"
                                    spacing={3}
                                    className="h_100_per p_t_md spaced"
                                >
                                    {vehiclesSorted.map((i, index) => (
                                        // eslint-disable-next-line react/no-array-index-key
                                        <Grid
                                            item
                                            md={subview ? 6 : 2}
                                            key={index}
                                        >
                                            <CardInventory
                                                vehicle={i}
                                                onClick={onClick}
                                                setResult={setResult}
                                                filterSalePrice={
                                                    weeksFilter as any
                                                }
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            )}
                            {null}
                        </>
                    ) : (
                        <div className="flex_center dim_100_per">
                            <Typography
                                scale="heading4"
                                weight="600"
                                className="text_neutral_900 m_xl text_center"
                            >
                                Tus filtros no generaron resultados
                            </Typography>
                        </div>
                    )}
                </div>

                <div
                    className={`display_flex flex_align_center flex_justify_between flex_footer ${
                        subview ? "" : "bg_neutral_0 p_x_xxxl p_y_md"
                    }`}
                >
                    {!subview && (
                        <Button
                            scale="small"
                            icon={<AddCircle />}
                            className="w_30_per"
                            onClick={() => setOpenDetails(true)}
                        >
                            Añadir vehículo
                        </Button>
                    )}
                    <Pager
                        totalPages={totalPages}
                        page={currentPage}
                        setPage={setCurrentPage}
                        count={count}
                        className="w_fit"
                    />
                </div>
                <EditVehicle
                    open={openDetails}
                    setOpen={setOpenDetails}
                    setResult={setResult}
                />
                {!subview && openExport && (
                    <ExportInventory
                        open={openExport}
                        setOpen={setOpenExport}
                    />
                )}
                {(openSaveFilter || openConfirmFilter) && (
                    <SaveFilter
                        open={openSaveFilter}
                        setOpen={setOpenSaveFilter}
                        callback={saveFilter}
                        openConfirm={openConfirmFilter}
                        setOpenConfirm={setOpenConfirmFilter}
                        callbackConfirm={confirmChangeFilter}
                    />
                )}
            </div>
        );
    };

    return renderContent();
}

export default Inventory;
