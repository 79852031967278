/* eslint-disable no-nested-ternary */
import React, { useState, useContext, useEffect } from "react";

// -----------------LIBRARIES IMPORTS------------------- //
// @ts-ignore
import { Button, Input, Typography } from "design_system/src";

// -----------------DATA IMPORTS------------------- //

import { handleEventGoogle } from "helpers/analytics/googleAnalytics";

// -----------------STATIC IMPORTS------------------- //
import "../../../ozoner.scss";
import { ReactComponent as Reload } from "design_system/src/static/icons/reload.svg";
import { ReactComponent as Money } from "design_system/src/static/icons/money.svg";

// -----------------COMPONENTS IMPORTS------------------- //
import { toasterNotification } from "helpers/globalNotifications/globalNotifications";
import { toasterOptions } from "enums/toaster.enum";
import { editSolicitud } from "helpers/fetchs/solicitudes";
import { commonErrorHandler } from "helpers/errors/errors";
import { priceFormatFinancial } from "helpers/prices/prices";
import { useNavigate } from "react-router-dom";
import { TVehicle } from "models/vehicle.interface";
import {
    TDataFinancialForm,
    formStatusEnum,
    reachedMethodsEnum,
    reachedMethodsValues,
} from "models/financialForm.interface";
import { ozonerContext } from "../../OzonerContext";
import { displayQuota } from "../../DetailsOzoner";

export const calculateQuota = (vehicle: TVehicle, financialForm: any)=>{
    const creditTime = financialForm?.form?.creditTime;
    const payment =
        vehicle?.salePrices?.filter(
            (salePrice: any) => salePrice.weeks === creditTime
        )?.[0]?{...vehicle?.salePrices?.filter(
            (salePrice: any) => salePrice.weeks === creditTime
        )?.[0]}:undefined;
    if (creditTime && payment) {
        const discount = vehicle.discounts.find(
            (d:any) => d.status === "active"
        );
        if (discount) {
            const { type, percentageValue, netValue } = discount;
            payment.paymentWeek = 
                type === "percentage"
                ? payment.paymentWeek - payment.paymentWeek * percentageValue
                    : payment.paymentWeek - netValue;
            payment.interest = 
                type === "percentage"
                ? payment.interest - payment.interest * percentageValue
                    : payment.interest - netValue;
            payment.capital = 
                type === "percentage"
                    ? payment.capital - payment.capital * percentageValue
                    : payment.capital - netValue;
        }
        if (financialForm?.form?.advancedMoney  && financialForm.form.advancedMoney > 0) {
            const total = payment.paymentWeek * creditTime;
            const { purchaseCost } = vehicle;
            payment.paymentWeek = 
                (total - financialForm.form.advancedMoney) / creditTime;
            payment.capital =    
                payment.paymentWeek / (total - financialForm.form.advancedMoney) / purchaseCost;
            payment.interest = 
                payment.paymentWeek - payment.capital;
        }
        payment.paymentWeek = Math.round(payment.paymentWeek);
        payment.capital = Math.round(payment.capital);
        payment.interest = Math.round(payment.interest);
        return payment;
    }
        return {paymentWeek:0};
};
export function VehicleSection() {
    const navigate = useNavigate();
    const {
        financialForm,
        vehicle,
        setMaxSalePrice,
        setOpenVehicle,
        creditOptions,
        updateCredit,
    } = useContext(ozonerContext);

    const [quota, setQuota] = useState(0);
    
    

    useEffect(() => {
        const payment = calculateQuota(vehicle,financialForm);
        setQuota(payment.paymentWeek);
    }, [financialForm, vehicle]);

    const handleDetailsVeh = (e: any, id: string) => {
        e.stopPropagation();
        navigate(`/inventory/${id}`);
    };

    return (
        <>
            <div
                id="vehicle-soli-ozoner"
                className=" border_b_solid border_1 border_neutral_400 p_x_sm p_b_md p_t_xl w_100_per"
            >
                {vehicle ? (
                    <>
                        <div className="display_flex flex_gap_md flex_align_center">
                            <div className="br_xs overflow_hidden foto_moto">
                                <img
                                    src={
                                        vehicle.images[0]
                                            ? vehicle.images[0].url
                                            : `${process.env.REACT_APP_STORAGE_BASE_URL}public/uploads/generic-moto.png`
                                    }
                                    alt="moto detail"
                                />
                            </div>
                            <div className="flex_grow_1 flex_center_col">
                                <Typography
                                    scale="small"
                                    weight="600"
                                    className="inventory_link cursor_pointer"
                                    onClick={(e) =>
                                        handleDetailsVeh(e, vehicle.internalId)
                                    }
                                >
                                    {vehicle.internalId}
                                </Typography>

                                <Typography
                                    scale="xxsmall"
                                    weight="400"
                                    className="m_b_xxs"
                                >
                                    {vehicle.brand && vehicle.model
                                        ? `${vehicle.brand.name} ${
                                              vehicle.model.name
                                          } ${vehicle.suffix ?? ""}`
                                        : "no definido"}
                                </Typography>
                                {!financialForm?.credit &&
                                    financialForm?.form.status !==
                                        formStatusEnum.expired && (
                                        <div className="display_flex flex_gap_sm">
                                            <div className="flex_grow_1 flex_center_col">
                                                <Button
                                                    scale="small"
                                                    icon={<Reload />}
                                                    variant="principal"
                                                    onClick={() => {
                                                        setOpenVehicle(true);
                                                        setMaxSalePrice(
                                                            displayQuota(
                                                                financialForm?.maxWeekQuota
                                                            )
                                                        );
                                                    }}
                                                >
                                                    Cambiar Moto
                                                </Button>
                                            </div>
                                        </div>
                                    )}
                            </div>
                        </div>
                        <div className="m_t_md">
                            {creditOptions && (
                                <Input
                                    title="Plan escogido"
                                    placeholder="Selecciona un plan"
                                    icon={<Money />}
                                    onChange={updateCredit}
                                    value={financialForm?.form?.creditTime}
                                    type="select"
                                    name="creditTime"
                                    options={creditOptions}
                                />
                            )}
                        </div>
                    </>
                ) : !financialForm?.credit &&
                  financialForm?.form.status !== formStatusEnum.expired ? (
                    <Button
                        scale="small"
                        icon={<Reload />}
                        variant="principal"
                        onClick={() => {
                            setOpenVehicle(true);
                            setMaxSalePrice(
                                displayQuota(financialForm?.form?.maxWeekQuota)
                            );
                            handleEventGoogle(
                                "BTN_Pb_request_assign_vehicle",
                                "Panda Solicitudes",
                                "click in assign vehicle to request button"
                            );
                        }}
                        className="w_100_per p_b_md"
                    >
                        Asignar Moto
                    </Button>
                ) : null}
            </div>
            {vehicle && financialForm?.form?.creditTime && (
                <div
                    id="vehicle-advance-ozoner"
                    className=" border_b_solid border_1 border_neutral_400 p_x_sm p_y_sm w_100_per display_flex flex_gap_sm flex_align_center"
                >
                    <div className="bg_green_200 p_xs br_xxs display_flex ">
                        <Money className="dim_md text_green_300" />
                    </div>
                    <Typography
                        scale="small"
                        weight="600"
                        textColor="neutral_800"
                        className="text_no_break"
                    >
                        Cuota semanal:
                    </Typography>
                    <Typography
                        scale="medium"
                        weight="600"
                        textColor="neutral_800"
                        className="text_no_break"
                    >
                        {priceFormatFinancial(quota, 0)}
                    </Typography>
                </div>
            )}
        </>
    );
}
