export const types = {
    login: "[LOGIN] user",
    refres_token: "[REFRESH] token",
    logout: "[LOGOUT] user",
};

// eslint-disable-next-line no-shadow
export enum RepossessionsActions{
    load_repossession="[LOAD] repossessions",
    add_historic="[ADD] historic"
};
// eslint-disable-next-line no-shadow
export enum LocationsActions{
    load_locations="[LOAD] locations",
    add_historic="[ADD] historic"
};
