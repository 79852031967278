import { TVehicle } from "./vehicle.interface";

// eslint-disable-next-line no-shadow
export enum formStatusEnum {
  pending = "pending",
  inValidation = "inValidation",
  approved = "approved",
  rejected = "rejected",
  scheduled = "scheduled",
  completed = "completed",
  expired = "expired",
  canceled = "canceled",

}

// eslint-disable-next-line no-shadow
export enum rejectedReasonEnum {
  LessThanThreeMonthsEmployed = "LessThanThreeMonthsEmployed",
  DeliveryPersonUsingOthersAccount = "DeliveryPersonUsingOthersAccount",
  InformationInconsistencies = "InformationInconsistencies",
  RejectionScore = "RejectionScore",
  PaymentCapacity = "PaymentCapacity",
  Truora = "Truora",
  Fraud = "Fraud",
  Zoning = "Zoning",
  Seon = "Seon",
  PreviousRecovery = "PreviousRecovery",
  Unemployment = "Unemployment",
  Address = "Address",
  DocumentsFromOtherPeople = "DocumentsFromOtherPeople",
  Age = "Age",
  Occupation = "Occupation",
  OzonersTypeD = "OzonersTypeD",
  TemporaryResidentForeigners = "TemporaryResidentForeigners",
}

export const rejectedReasonValues = {
  [rejectedReasonEnum.LessThanThreeMonthsEmployed]: "Tiene menos de 3 meses en el empleo",
  [rejectedReasonEnum.DeliveryPersonUsingOthersAccount]: "Trabaja desde la cuenta de otra persona",
  [rejectedReasonEnum.InformationInconsistencies]: "Inconsistencias en la información",
  [rejectedReasonEnum.RejectionScore]: "Score Ozon",
  [rejectedReasonEnum.PaymentCapacity]: "Capacidad de pago",
  [rejectedReasonEnum.Truora]: "Truora",
  [rejectedReasonEnum.Fraud]: "Fraude",
  [rejectedReasonEnum.Zoning]: "Zonificación",
  [rejectedReasonEnum.Seon]: "Seon",
  [rejectedReasonEnum.PreviousRecovery]: "Recuperación previa",
  [rejectedReasonEnum.Unemployment]: "Desempleo",
  [rejectedReasonEnum.Address]: "Dirección erronea",
  [rejectedReasonEnum.DocumentsFromOtherPeople]: "Documentos de otra persona",
  [rejectedReasonEnum.Age]: "Edad",
  [rejectedReasonEnum.Occupation]: "Ocupación",
  [rejectedReasonEnum.OzonersTypeD]: "Ozoner tipo D",
  [rejectedReasonEnum.TemporaryResidentForeigners]: "Extranjeros con residencia temporal",
};

// eslint-disable-next-line no-shadow
export enum reachedMethodsEnum {
  email = "email",
  whatsApp = "whatsApp",
  call = "call",
  inSite = "inSite",
}

export const reachedMethodsValues = {
  [reachedMethodsEnum.email]: "Email",
  [reachedMethodsEnum.whatsApp]: "Whatsapp",
  [reachedMethodsEnum.call]: "Llamada",
  [reachedMethodsEnum.inSite]: "Presencial",
};

// eslint-disable-next-line no-shadow
export enum devolutionReasonEnum {
  incompleteData = "incompleteData",
  incompleteDocuments = "incompleteDocuments",
  expiredDocuments = "expiredDocuments",
}
// eslint-disable-next-line no-shadow
export const devolutionReasonValues = {
  [devolutionReasonEnum.incompleteData]: "Informacion incompleta",
  [devolutionReasonEnum.incompleteDocuments]: "Documentos incompletos",
  [devolutionReasonEnum.expiredDocuments]: "Documentos fuera de los 3 meses",
};

// eslint-disable-next-line no-shadow
export enum contactStatusEnum {
  contacted = "contacted",
  notContacted = "notContacted",
  withoutAnswer = "withoutAnswer",
}

export const contactStatusValues={
  [contactStatusEnum.contacted]: "Contactado",
  [contactStatusEnum.withoutAnswer]: "Sin Respuesta",
  [contactStatusEnum.notContacted]: "No contactado",
};

export const FormStatusValues: { [key in formStatusEnum]: { title: string, color: string, tooltip: string } } = {
  [formStatusEnum.pending]: {
    title: "En espera",
    color: "primary_200",
    tooltip: "Solicitud en proceso con el vendedor",
  },
  [formStatusEnum.inValidation]: {
    title: "Validación",
    color: "yellow_100",
    tooltip: "Solicitud pendiente de validación de crédito",
  },
  [formStatusEnum.approved]: {
    title: "Aprobada",
    color: "green_300",
    tooltip: "Solicitud aprobada para crédito",
  },
  [formStatusEnum.rejected]: {
    title: "Rechazada",
    color: "red_300",
    tooltip: "Solicitud rechazada para crédito",
  },
  [formStatusEnum.scheduled]: {
    title: "Agendada",
    color: "primary_400",
    tooltip: "Solicitud agendada para entrega",
  },
  [formStatusEnum.completed]: {
    title: "Completada",
    color: "secondary_500",
    tooltip: "Solicitud completada con un crédito",
  },
  [formStatusEnum.expired]: {
    title: "Caducadas",
    color: "neutral_600",
    tooltip: "Solicitud expirada por tiempo de inactividad",
  },
  [formStatusEnum.canceled]: {
    title: "Cancelada",
    color: "neutral_900",
    tooltip: "Solicitud cancelada por peticion del ozoner",
  },
};

// eslint-disable-next-line no-shadow
export enum salesRankEnum {
  diamond = "diamond",
  platinum = "platinum",
  gold = "gold",
  silver = "silver",
  bronze = "bronze",
  wood = "wood",
}

export const salesRankValues = {
  [salesRankEnum.diamond]: {
    title: "Diamante",
    color: "diamond_color",
    tooltip:"En esta segmentacion estan los ozoners que cargaron por su cuenta el ine, selfie, comprobante de domicilio y al menos un comprobante de ingresos"
  },
  [salesRankEnum.platinum]: {
    title: "Platino",
    color: "platinum_color",
    tooltip:"En esta segmentacion estan los ozoners que cargaron por su cuenta el ine, selfie, comprobante de domicilio y que son repartidores"
  },
  [salesRankEnum.gold]: {
    title: "Oro",
    color: "gold_color",
    tooltip:"En esta segmentacion estan los ozoners que cargaron por su cuenta el ine, selfie, comprobante de domicilio y al menos un comprobante de ingresos"
  },
  [salesRankEnum.silver]: {
    title: "Plata",
    color: "silver_color",
    tooltip:"En esta segmentacion estan los ozoners que cargaron por su cuenta el ine, selfie y comprobante de domicilio"
  },
  [salesRankEnum.bronze]: {
    title: "Bronce",
    color: "bronze_color",
    tooltip:"En esta segmentacion estan los ozoners que cargaron por su cuenta el ine y selfie"
  },
  [salesRankEnum.wood]: {
    title: "Madera",
    color: "wood_color",
    tooltip:"En esta segmentacion estan los ozoners que no cargaron por su cuenta ni el ine ni la selfie"
  },
};

// eslint-disable-next-line no-shadow
export enum economicActivityEnum {
  empleado = "empleado",
  repartidor = "repartidor",
  comerciante = "comerciante",
  conductor = "conductor",
  conductorRepartidor = "conductorRepartidor",
  ayudanteGeneral = "ayudanteGeneral",
  seguridad = "seguridad",
  independiente = "independiente",
  estudiante = "estudiante",
  policia = "policia",
  servidorPublico = "servidorPublico",
  enfermero = "enfermero",
  mecanico = "mecanico",
  otro = "otro",
}

export interface TAppointment {
  scheduledAt:string;
  deliverOzonio:string;
}

export interface TDataFinancialForm {
  duration: any;
  vehicle: TVehicle;
  createdAt: string;
  documents: any[];
  _id: string;
  weeklyIncome?: number;
  monthlyIncome?: number;
  weekFlow?: number;
  afterPayIncome?: number;
  maxWeekQuota?: number;
  monthlySpending?: number;
  educationalLevel?: string;
  civilStatus?: string;
  companyName?: string;
  dependantsCount?: number;
  childrenCount?: number;
  assets?: any;
  personal_references?: any;
  economicActivity: {value:economicActivityEnum,description?:string};
  advancedMoney?: number;
  creditTime?: number
  status: formStatusEnum;
  workInDigitalPlatforms: boolean;
  livesWith: string[];
  percentageMaxQuota?: number;
  reached?: boolean;
  reachedMethod?: reachedMethodsEnum;
  reachedSuccess?: boolean;
  rejectedReason?: rejectedReasonEnum;
  devolutionReason?: devolutionReasonEnum;

  monthlyIncomeVerified?: number;
  monthlySpendingVerified?: number;
  authDataTreatment: boolean;
  group: string;
  platforms: number[];
  vehicleId?: string;
  approved?: boolean;
  score: number;
  dti: number;
  city: string;
  statusHistoric: any;
  updatedAt: string;
  weeklyOutcome?: number;
  partnerShip?: string;
  reviewed?: boolean;
  vehicleAvailable: boolean;
  sellResponsible?: string;
  discountId?: string;
  salesRank: salesRankEnum;
  canceledReason?: string;
  creditSegmentation?: string;
  collectionSegmentation?: string;
  appointment?:TAppointment;
}

export interface TPersonalReference {
  name: string;
  phone: string;
  relation: string;
  other?: string
}
export interface TPersonalReferences {
  personal_reference_1: TPersonalReference;
  personal_reference_2: TPersonalReference;
}

export interface TAssets {
  assets: string[];
  other: string;
  joinedAssets: string;
}

export interface TEditFinancialForm {
  economicActivityOther?: string;
  vehicleId: string;
  createdAt: string
  monthlyIncome: number
  monthlySpending: number
  percentageMaxQuota?: number
  childrenCount: number
  dependantsCount: number
  companyName?: string
  economicActivity: economicActivityEnum
  civilStatus: string
  educationalLevel: string
  livesWith: string[]
  workInDigitalPlatforms: boolean
  personal_references: TPersonalReferences
  assets: TAssets
  creditTime?: number
  vehicle?: string
}


export const financialFormFields = {
  userInfoStep: "Paso de información de usuario",
  monthlyIncome: "Ingreso mensual",
  monthlySpending: "Gasto mensual",
  childrenCount: "Cantidad de hijos",
  dependantsCount: "Cantidad de dependientes",
  companyName: "Nombre de la empresa",
  economicActivity: "Actividad económica",
  group: "Grupo",
  civilStatus: "Estado civil",
  livesWith: "Vive con",
  authDataTreatment: "Tratamiento de datos de autorización",
  platforms: "Plataformas",
  workInDigitalPlatforms: "Trabaja en plataformas digitales",
  _id: "ID",
  vehicleId: "ID del vehículo",
  approved: "Aprobado",
  score: "Puntuación",
  dti: "DTI",
  createdAt: "Fecha de creación",
  updatedAt: "Fecha de actualización",
  status: "Estado del formulario",
  city: "Ciudad",
  statusHistoric: "Histórico de estado",
  weekFlow: "Flujo semanal",
  weeklyIncome: "Ingreso semanal",
  weeklyOutcome: "Gasto semanal",
  afterPayIncome: "Ingreso después del pago",
  maxWeekQuota: "Cuota máxima semanal",
  creditTime: "Tiempo de crédito",
  advancedMoney: "Dinero avanzado",
  educationalLevel: "Nivel educativo",
  assets: "Activos",
  rejectedReason: "Motivo de rechazo",
  percentageMaxQuota: "CTI",
  personal_references: "Referencias personales",
  reached: "Contactado",
  reachedMethod: "Metodo de contacto",
  reachedSuccess: "Contacto exitoso",
  
  appointment:"Cita de entrega",
};
