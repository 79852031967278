/* eslint-disable arrow-body-style */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-props-no-spreading */
import { Popup,  Typography } from "design_system/src";
import React, { FC } from "react";

import "./statusPopup.scss";

interface StatusPopupProps {
    open: boolean,
    setOpen:any,
    labels:any,
    variant?:string,
    className?: string,
    onChange?: Function;
    placementY?: "top" | "bottom" | "top-inset" | "bottom-inset";
    placementX?: "right" | "left" | "right-inset" | "left-inset"
}
const clases=(color:string,variant:String)=>{
    if(variant==="principal")
        return `text_neutral_0 bg_${color}_nocontrast`;
    if(variant==="outline")
        return `text_neutral_900 border_${color} border_2 border_solid`;
    return "";
};

export const StatusPopup: FC<StatusPopupProps> = ({ open, setOpen, labels, variant="principal", placementY = "bottom", placementX = "left-inset", onChange = () => { }, className = "" }) => {

    return (
        <Popup open={open} setOpen={setOpen} placementY={placementY} placementX={placementX} className={`p_md status_popup ${className}`} onClick={(e: any) => e?.stopPropagation()}>
            <Typography scale="small" weight="600" className="text" textColor="neutral_900">
                Seleccione estado
            </Typography>
            {
                Object.keys(labels).map((ele: string) => (
                    <div className={`dso_chip_small m_t_sm cursor_pointer ${clases(labels[ele].color,variant)}`} key={`status-${ele}`} onClick={() => onChange(ele)} onKeyPress={() => onChange(ele) } role="button" tabIndex={0}>{labels[ele].title}</div>
                ))
            }
        </Popup>
    );
};