/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useContext } from "react";

// -----------------LIBRARIES IMPORTS------------------- //
// @ts-ignore
import {
    Button,
    Input,
    Knob,
    ModalConfirmation as Modal,
    ModalConfirmation,
    SideModal,
    Typography,
} from "design_system/src";
import { Grid } from "@mui/material";
import * as yup from "yup";

// -----------------DATA IMPORTS------------------- //
import { priceFormatFinancial, priceMXNTotal } from "helpers/prices/prices";
import { TDataozoner } from "models/ozoner.interface";
import { editOzoner } from "helpers/fetchs/ozoners";
import {
    FormStatusValues,
    TDataFinancialForm,
    devolutionReasonEnum,
    devolutionReasonValues,
    formStatusEnum,
    rejectedReasonEnum,
    rejectedReasonValues,
} from "models/financialForm.interface";
import moment from "moment";

import { handleEventGoogle } from "helpers/analytics/googleAnalytics";

// -----------------STATIC IMPORTS------------------- //
import "../../../ozoner.scss";
import { ReactComponent as Edit } from "design_system/src/static/icons/edit-text.svg";
import { ReactComponent as Check } from "design_system/src/static/icons/checkmark.svg";
import { ReactComponent as Eye } from "design_system/src/static/icons/eye.svg";
import { ReactComponent as Reload } from "design_system/src/static/icons/reload.svg";
import { ReactComponent as Close } from "design_system/src/static/icons/close-lg.svg";
import { ReactComponent as Up } from "design_system/src/static/icons/success-plan.svg";
import { ReactComponent as AddFiles } from "design_system/src/static/icons/upload-bracket.svg";
import { ReactComponent as Down } from "design_system/src/static/icons/failed-plan.svg";
import { ReactComponent as Fail } from "design_system/src/static/icons/close-circle.svg";
import { ReactComponent as Success } from "design_system/src/static/icons/tick-circle.svg";
import { ReactComponent as Info } from "design_system/src/static/icons/info.svg";
import { ReactComponent as ArrowUp } from "design_system/src/static/icons/up-md.svg";
import { ReactComponent as ArrowDown } from "design_system/src/static/icons/down-md.svg";

import CurrencyInput from "components/hocs/buttonsInputField/CurrencyInput";

// -----------------COMPONENTS IMPORTS------------------- //
import { statusVehicles } from "models/vehicle.interface";
import Dropzone from "react-dropzone";
import Swal from "sweetalert2";
import Rejected from "views/ozoner/details/modals/Rejected";
import { toasterNotification } from "helpers/globalNotifications/globalNotifications";
import { CreditStatus } from "models/credit.interface";
import { toasterOptions } from "enums/toaster.enum";
import {
    addFileForm,
    deleteFileForm,
    editSolicitud,
} from "helpers/fetchs/solicitudes";
import { isInteger } from "lodash";
import { commonErrorHandler } from "helpers/errors/errors";
import { UpdateCreditDeposit } from "helpers/fetchs/credits";
import { Roles } from "models/ozonio.interface";
import { RequireRole } from "components/hocs/sidebar/Sidebar";
import { DocumentTypesEnum } from "components/hocs/loadDocuments/loadDocuments";
import { LightTooltip } from "components/hocs/tooltip/Tooltip";
import { ozonerContext } from "../../OzonerContext";
import { EditTypesOzoner, displayQuota } from "../../DetailsOzoner";
import Devolution from "../../modals/Devolution";
import SolicitudOtherInfo from "./SolicitudOtherInfo";
import { CreditSegmentation, PartnershipSegmentation } from "./Segmentations";
import { DevolutionAction, RejectAction, UpdateQuota } from "./SolicitudActions";

const validationItem = (
    valid: boolean,
    validLabel: string,
    invalidLabel: string
) => (
    <>
        {valid ? (
            <Success className="dim_lg text_green_300" />
        ) : (
            <Fail className="dim_lg text_red_300" />
        )}
        <Typography
            scale="xsmall"
            weight="400"
            textColor={valid ? "green_300" : "red_300"}
            className="text_no_break"
        >
            {valid ? validLabel : invalidLabel}
        </Typography>
    </>
);

function ValidationSection() {
    const {
        financialForm,
        ozoner,
        updateStatusSolicitud,
        setResult,
        triggerTruora,
        refreshPlatforms,
    } = useContext(ozonerContext);

    const [loadingTruora, setLoadingTruora] = useState(false);
    const [openRevalidateTruora, setOpenRevalidateTruora] = useState(false);


    

    const validateTruora = () => {
        if (ozoner.truoraId) {
            setOpenRevalidateTruora(true);
        } else {
            handleEventGoogle(
                "BTN_Pb_validate_truora",
                "Panda Solicitudes",
                "click in validate truora button"
            );
            setLoadingTruora(true);
            triggerTruora().then(() => {
                setLoadingTruora(false);
            });
        }
    };

    const approveSolicitud = () => {
        updateStatusSolicitud(formStatusEnum.approved, null);
        handleEventGoogle(
            "BTN_Pb_request_approve",
            "Panda Solicitudes",
            "click in approve request button"
        );
    };


    

    return (
        <>
            <RequireRole authRoles={[Roles.ADMIN, Roles.CREDIT_ANALYST]}>
                <>
                    <CreditSegmentation />

                    <div
                        id="validate-soli-ozoner"
                        className="p_md border_b_solid border_1 border_neutral_400 display_flex flex_col flex_gap_md"
                    >
                        <div className="display_flex flex_col flex_gap_xs">
                            <div className="display_flex flex_gap_xs flex_align_center">
                                {validationItem(
                                    financialForm?.form?.creditSegmentation,
                                    "Segmentación asignada",
                                    "Segmentación sin asignar"
                                )}
                            </div>
                            <div className="display_flex flex_gap_xs flex_align_center">
                                {validationItem(
                                    financialForm?.form?.maxWeekQuota,
                                    "Cuota maxima asignada",
                                    "Cuota maxima sin asignar"
                                )}
                            </div>
                        </div>

                        <Button
                            scale="small"
                            color="secondary"
                            onClick={validateTruora}
                            disabled={loadingTruora}
                        >
                            Validar Truora
                        </Button>
                        <UpdateQuota/>
                        <Button
                            scale="small"
                            color="primary"
                            onClick={approveSolicitud}
                            disabled={
                                !financialForm?.form?.maxWeekQuota ||
                                !financialForm?.form?.creditSegmentation
                            }
                        >
                            Aprobar
                        </Button>
                        <DevolutionAction />
                        <RejectAction />
                    </div>
                </>
            </RequireRole>
            <PartnershipSegmentation />

            {openRevalidateTruora && (
                <Modal
                    openConfirmation={openRevalidateTruora}
                    handleClose={() => setOpenRevalidateTruora(false)}
                    widthDesktop={50}
                    actions={[
                        {
                            color: "secondary",
                            label: "Validar Truora",
                            action: () => {
                                handleEventGoogle(
                                    "BTN_Pb_validate_truora_again",
                                    "Panda Solicitudes",
                                    "click in validate truora button again"
                                );
                                setLoadingTruora(true);
                                triggerTruora().then(() => {
                                    setLoadingTruora(false);
                                });
                                setOpenRevalidateTruora(false);
                            },
                        },
                        {
                            color: "primary",
                            label: "Actualizar Datos",
                            action: () => {
                                handleEventGoogle(
                                    "BTN_Pb_update_platforms",
                                    "Panda Solicitudes",
                                    "click in update platforms button"
                                );
                                refreshPlatforms(ozoner, true);
                                setOpenRevalidateTruora(false);
                            },
                        },
                    ]}
                    title={
                        <Typography scale="large" weight="600">
                            Validar Truora
                        </Typography>
                    }
                    subtitle={
                        <>
                            <Typography
                                scale="medium"
                                weight="600"
                                className="m_b_md"
                            >
                                Esta acción va a generar costos adicionales al
                                proceso ¿Estas seguro que quieres continuar?
                            </Typography>
                            <Typography scale="medium" weight="400">
                                En algunos casos Truora demora en mostrar los
                                datos te recomendamos actualizar datos antes de
                                Validar nuevamente
                            </Typography>
                        </>
                    }
                    icon={<Reload />}
                />
            )}
            
        </>
    );
}
export default ValidationSection;
