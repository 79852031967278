
// eslint-disable-next-line no-shadow
export enum DeviceBrands {
  Coban4G = "Coban4G",
  Seeworld = "Seeworld",
}

// eslint-disable-next-line no-shadow
export enum DeviceStatus {
  Active = "Active",
  Inactive = "Inactive",
  Broken = "Broken",
  Repairing = "Repairing",
}

export interface VehicleList {
  internalId: string;
  startedAt: Date;
  endedAt: Date;
  vehicle: "string";
}

export interface TDevice {
  createdAt: string;
  updatedAt: string;
  imei: string;
  brand: DeviceBrands;
  iccid: string;
  phone: string;
  sn: string;
  simBrand: string;
  status: DeviceStatus;
  vehiclesList: VehicleList[];
  vehicle?: VehicleList
  _id:string
}
