import React, { useContext } from "react";
import { Fab } from "@mui/material";
import { ReactComponent as Location } from "design_system/src/static/icons/user-location.svg";
import { MapContext, PlacesContext } from "../../../context";

const MyLocationButton = () => {
  const { map, isMapReady } = useContext<any>(MapContext);
  const { userLocation } = useContext<any>(PlacesContext);

  const onClick = () => {
    if (!isMapReady) throw new Error("Map is not Ready");
    if (!userLocation) throw new Error("No hay ubicacion de usuario");

    map?.flyTo({
      zoom: 14,
      center: userLocation,
    });
  };

  return (
    <div
      className="btn btn-primary"
      style={{
        position: "fixed",
        bottom: 30,
        right: 30,
        zIndex: 100,
      }}
    >
      <Fab color="primary" variant="extended" onClick={onClick}>
        <Location style={{ fill: "white" }} className="m_r_xs" />
        Mi Ubicación
      </Fab>
    </div>
  );
};

export default MyLocationButton;
