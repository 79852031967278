import React from "react";
import mapboxgl from "mapbox-gl";

import "./styles.scss";
import OzonerLocation from "./OzonerLocation";
import { PlacesProvider } from "../../../context";
import MapProvider from "../../../context/map/MapProvider"; // or "const mapboxgl = require('mapbox-gl');"

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API_KEY || "";

export const MapView = () => {
  return (
    <PlacesProvider>
      <MapProvider>
        <OzonerLocation />
      </MapProvider>
    </PlacesProvider>
  );
};
