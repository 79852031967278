/* eslint-disable no-restricted-globals */
import { setToken, setRefreshToken, clearLogout, setLastUrl } from "helpers/localStorage/authStorage";
import { Dispatch } from "redux";
import { types } from "store/types/types";

export const login = (accessToken: any, refreshToken:any) => (dispatch: Dispatch) => {
  setToken(accessToken);
  setRefreshToken(refreshToken);
  
  dispatch({
    type: types.login,
    payload: {
      accessToken,
      refreshToken
    },
  });
};

export const logout = (save=false) => (dispatch: Dispatch) => {
  if (save) setLastUrl(location.href);
  clearLogout();
  location.reload();
  dispatch({
    type: types.logout,
  });
};
