/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

// icons
import { ReactComponent as Excel } from "design_system/src/static/icons/excel.svg";
import { ReactComponent as Search } from "design_system/src/static/icons/search.svg";
import { ReactComponent as AddCircle } from "design_system/src/static/icons/add-circle.svg";

import { Button, Typography } from "design_system/src";

import {
  getOzonioFilter,
  setOzonioFilter,
} from "helpers/localStorage/filterStorage";
import { getOzonioRoles, getOzonios } from "helpers/fetchs/ozonios";
import { ReactComponent as User } from "design_system/src/static/icons/favorite-profile.svg";

// css
import "./ozonio.scss";
import profileDefaultOzonio from "static/images/ozonio_default.png";
import { handleEventGoogle } from "helpers/analytics/googleAnalytics";
import { Role, Roles } from "models/ozonio.interface";
import { RequireRole } from "components/hocs/sidebar/Sidebar";
import EditOzonio from "./EditOzonio";
import ExportOzonios from "./ExportOzonios";
import SearchBar from "../../components/hocs/searchBar/searchBar";

interface OzonioProps {
  subview?: boolean;
}
function SortArray(x: any, y: any) {
  if (x.name < y.name) {
    return -1;
  }
  if (x.name > y.name) {
    return 1;
  }
  return 0;
}
let petition: number;
// onClick = null, initialFilter = "pending", availableFilters = defaultFilters, requireFilter = true,
function Ozonio({ subview = false }: OzonioProps) {
  const [openDetails, setOpenDetails] = useState<boolean>(false);
  const [openExport, setOpenExport] = useState<boolean>(false);
  const [totals, setTotals] = useState<number>(0);
  const [ozonios, setOzonios] = useState<any | null>(null);
  const [query, setQuery] = useState<string>("");
  const [finQuery, setFinQuery] = useState<string>("");
  const [result, setResult] = useState();

  const [roles, setRoles] = useState<any>({});

  const navigate = useNavigate();

  const handleDetailsOzo = (id: string) => {
    navigate(`/ozonio/${id}`);
  };

  useEffect(() => {
    handleEventGoogle(
      "VIEW_Panda_welcome",
      "Panda",
      "page view to the dashboard home view"
    );
    petition = 0;
    const information = getOzonioFilter();
    if (information.query) {
      setFinQuery(information.query);
      setQuery(information.query);
    }
    getOzonioRoles().then((roleList) => {
      const rolesObj: any = {};
      roleList.forEach((role: Role) => {
        rolesObj[role._id] = role;
      });
      setRoles(rolesObj);
    });
  }, []);
  const requestOzonios = () => {
    const num = petition + 1;
    petition += 1;

    getOzonios(query).then((res) => {
      if (res && num === petition) {
        const order = res.data.sort(SortArray);
        const ozoniosA: any = {};
        order.forEach((ozonio: any) => {
          const letter = ozonio.name.substring(0, 1).toUpperCase();
          if (!ozoniosA[letter]) ozoniosA[letter] = [];
          ozoniosA[letter].push(ozonio);
        });
        setOzonios(ozoniosA);
        setTotals(res.count);
      }
    });
  };
  useEffect(() => {
    requestOzonios();
  }, [finQuery, result]);
  useEffect(() => {
    if (!subview) setOzonioFilter({ query: finQuery });
  }, [finQuery]);

  const handleSearch = (event: any) => {
    event.preventDefault();
    setFinQuery(query);
  };

  return (
    <div className="flex_content_col dim_100_per">
      <div className="flex_header">
        <Typography scale="heading1" weight="600" className="text_neutral_900">
          Ozonios
        </Typography>
        <Typography scale="large" weight="600" textColor="neutral_600">
          Permisos de acceso a Pandaboard
        </Typography>
        <SearchBar
          handleSearch={handleSearch}
          query={query}
          setQuery={setQuery}
          exportText={!subview ? "Exportar ozonios" : undefined}
          miniSearchButton={subview}
          handleExport={() => setOpenExport(true)}
        />
      </div>
      <div className="flex_body pretty_slider overflow_x_hidden p_r_md">
        {ozonios &&
          Object.keys(ozonios).map((i) => {
            const data = ozonios[i];
            return (
              <>
                <Typography
                  scale="heading4"
                  weight="600"
                  textColor="primary_300"
                  className="p_x_xxxl m_b_xxs"
                >
                  {i}
                </Typography>
                <div className="w_100_per overflow_hidden br_md shadow_medium m_b_md">
                  {data.map((ozonio: any, j: number) => (
                    <div
                      className="display_flex flex_align_center bg_neutral_0 p_x_xxxl p_y_xs border_1 border_solid border_neutral_300 cursor_pointer"
                      key={`ozonio_${i}_${j}`}
                      onClick={() => handleDetailsOzo(ozonio._id)}
                      role="button"
                      tabIndex={0}
                      onKeyDown={() => handleDetailsOzo(ozonio._id)}
                    >
                      <div className="profile_image m_r_sm display_flex">
                        {ozonio.image && ozonio.image.url ? (
                          <img src={ozonio.image.url} alt="ozonio" />
                        ) : (
                          <img src={profileDefaultOzonio} alt="ozonio" />
                        )}
                      </div>
                      <div>
                        <div className="flex_center flex_gap_sm">

                        <Typography
                          scale="medium"
                          weight="600"
                          textColor="neutral_900"
                        >
                          {ozonio.name}
                        </Typography>
                        {!ozonio.isActive?(
                        <Typography
                          scale="xsmall"
                          weight="600"
                          textColor="neutral_0"
                          className="bg_red_300 br_xs p_x_xs p_y_xxs"
                        >
                          Inactivo
                        </Typography>

                        ):null}
                        </div>
                        <Typography
                          scale="small"
                          weight="400"
                          textColor="primary_200"
                        >
                          {roles[ozonio.role]?.description??"--"}
                        </Typography>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            );
          })}
      </div>
      <div className="flex_footer display_flex flex_align_center flex_justify_between">
          <Button
            scale="small"
            icon={<AddCircle />}
            className="w_30_per"
            onClick={() => setOpenDetails(true)}
          >
            Añadir Ozonio
          </Button>
        <div className="display_flex">
          <Typography
            scale="small"
            weight="600"
            textColor="neutral_900"
            className="m_r_xs"
          >
            {totals}
          </Typography>
          <Typography scale="small" weight="400" textColor="neutral_900">
            Ozonios Registrados
          </Typography>
        </div>
      </div>
      <EditOzonio
        open={openDetails}
        setOpen={setOpenDetails}
        setResult={setResult}
      />
      {!subview && openExport && (
        <ExportOzonios
          open={openExport}
          setOpen={setOpenExport}
          currentQuery={query}
        />
      )}
    </div>
  );
}

export default Ozonio;
