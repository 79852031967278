/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-param-reassign */
/* eslint-disable dot-notation */
/* eslint-disable no-underscore-dangle */
import { Grid } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import {
    Typography,
    Knob,
    DateRange,
    DateSelect,
} from "design_system/src";


import { ReactComponent as Moto } from "design_system/src/static/icons/motorcycle.svg";
import { ReactComponent as Document } from "design_system/src/static/icons/document-tick.svg";
import { ReactComponent as Idea } from "design_system/src/static/icons/idea.svg";
import { ReactComponent as Delete } from "design_system/src/static/icons/delete-profile.svg";
import { ReactComponent as GPS } from "design_system/src/static/icons/gps.svg";
import { ReactComponent as Info } from "design_system/src/static/icons/info.svg";
import { ReactComponent as Motos } from "design_system/src/static/icons/Motocycle.svg";
import { ReactComponent as Percentage } from "design_system/src/static/icons/percentage.svg";
import { ReactComponent as Money } from "design_system/src/static/icons/money.svg";
import { ReactComponent as Ghost } from "design_system/src/static/icons/ghost.svg";

import React, { useEffect, useState } from "react";

import "./dashboard.scss";
import { TDashboard } from "models/dashboard.interface";
import { statusVehicles, statusVehiclesEnum } from "models/vehicle.interface";
import { CreditStatus } from "models/credit.interface";
import { LightTooltip } from "components/hocs/tooltip/Tooltip";
import {
    fetchActiveUsers,
    fetchDashboardCredits,
    fetchDashboardCreditsTime,
    fetchDashboardInventory,
    fetchDashboardVehicles,
    fetchFirstPaymentDefault,
    fetchPaymentAnalysis,
} from "helpers/fetchs/dashboard";
import { useNavigate } from "react-router-dom";
import { getUser } from "helpers/localStorage/authStorage";
import { handleEventGoogle } from "helpers/analytics/googleAnalytics";
import { priceMXNTotal } from "helpers/prices/prices";
import { setupColor } from "../../helpers/common/utils";

const months = [
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun",
    "Jul",
    "Ago",
    "Sept",
    "Oct",
    "Nov",
    "Dic",
];

interface DashboardCardProps {
    title: string;
    subtitle?: string;
    tooltip?: string;
}

interface GenericDashboardCardProp extends DashboardCardProps {
    icon: React.ReactNode;
    value: string;
}
interface CustomDashboardCardProp extends DashboardCardProps {
    children: React.ReactNode;
}

const isGenericDashboardCardProp = (
    props: GenericDashboardCardProp | CustomDashboardCardProp
): props is GenericDashboardCardProp => {
    return (props as GenericDashboardCardProp).icon !== undefined;
};

const DashboardCard = (
    props: GenericDashboardCardProp | CustomDashboardCardProp
) => {
    const { title, subtitle, tooltip } = props;
    return (
        <div className="br_xs shadow_soft bg_neutral_0 p_md border_1 border_solid border_neutral_400">
            <div className="display_flex flex_justify_between m_b_xs">
                <Typography
                    className=""
                    scale="large"
                    weight="600"
                    textColor="neutral_900"
                >
                    {title}
                </Typography>
                {tooltip && (
                    <LightTooltip title={tooltip}>
                        <Info className="dim_lg text_neutral_600" />
                    </LightTooltip>
                )}
            </div>
            {subtitle && (
                <Typography
                    className="m_b_xs"
                    scale="small"
                    weight="400"
                    textColor="neutral_900"
                >
                    {subtitle}
                </Typography>
            )}
            {isGenericDashboardCardProp(props) ? (
                <div className="display_flex flex_align_center">
                    <div className="dso_card_icon bg_primary_300 dim_fit display_flex m_r_lg border_transparent">
                        {props.icon}
                    </div>
                    <Typography
                        scale="large"
                        weight="600"
                        textColor="neutral_900"
                        className=""
                    >
                        {props.value}
                    </Typography>
                </div>
            ) : (
                props.children
            )}
        </div>
    );
};

const activeContracts = [
    CreditStatus.current,
    CreditStatus.earlyDefault,
    CreditStatus.DPD_30,
    CreditStatus.DPD_60,
    CreditStatus.DPD_90,
    CreditStatus.DPD_120,
];
const writeOffContracts = [CreditStatus.writeOff, CreditStatus.portfolioSale];

const formatDate = (d: Date) => `${d.getDate()} ${months[d.getMonth()]}`;
function Dashboard() {

    const navigate = useNavigate();

    const [date, setDate] = useState<string>("");
    const [fecha, setFecha] = useState<any>(null);
    const [user, setUser] = useState<any>(null);

    const [activeUsers, setActiveUsers] = useState();
    const [inventory, setInventory] = useState<any>();
    const [fpd, setFpd] = useState();
    const [credits, setCredits] = useState<any>();
    const [creditsRepossession, setCreditsRepossession] = useState<any>();
    const [creditsReturn, setCreditsReturn] = useState<any>();
    const [creditsComplete, setCreditsComplete] = useState<any>();
    const [creditsDelivered, setCreditsDelivered] = useState<any>();
    const [payments, setPayments] = useState<any>();

    const changeDate = (fech: any) => {
            setFecha(fech);
            setDate(`${formatDate(fech.initial)} - ${formatDate(fech.last)}`);
    };

    const isBigScreen = useMediaQuery({ query: "(min-width: 1441px)" });

    useEffect(() => {
        handleEventGoogle(
            "VIEW_Panda_welcome",
            "Panda",
            "page view to the dashboard home view"
        );
        setUser(getUser());

        fetchActiveUsers().then((info: any) => {
            setActiveUsers(info);
        });
        fetchFirstPaymentDefault().then((info: any) => {
            setFpd(info);
        });
        fetchDashboardInventory().then((info: any) => {
            setInventory(info);
        });
        fetchDashboardCredits().then((info: any) => {
            setCredits(info);
        });
    }, []);

    useEffect(() => {
        fetchDashboardCreditsTime(CreditStatus.repossession, fecha).then(
            (info: any) => {
                setCreditsRepossession(info);
            }
        );
        fetchDashboardCreditsTime(CreditStatus.complete, fecha).then(
            (info: any) => {
                setCreditsComplete(info);
            }
        );
        fetchDashboardCreditsTime(CreditStatus.devolution, fecha).then(
            (info: any) => {
                setCreditsReturn(info);
            }
        );
        fetchDashboardVehicles(statusVehiclesEnum.delivered, fecha).then(
            (info: any) => {
                setCreditsDelivered(info);
            }
        );
        fetchPaymentAnalysis(fecha).then((info: any) => {
            setPayments(info);
        });
    }, [fecha]);

    return (
        <div className="w_100_per p_b_xl">
            <div className="display_flex m_b_xl flex_align_end">
                <Typography
                    scale="heading3"
                    weight="600"
                    textColor="neutral_900"
                >
                    Bienvenido(a) {user?.name}
                </Typography>
            </div>
            <Typography
                scale="large"
                weight="600"
                textColor="neutral_900"
                className="m_b_md"
            >
                Estadísticas globales
            </Typography>
            <Grid container spacing={1}>
                <Grid item xs={3}>
                    <DashboardCard
                        title="Usuarios activos"
                        tooltip="Todos los Ozoners que han realizado un pago en los últimos 30 días."
                        icon={<Idea />}
                        value={activeUsers === undefined ? "--" : activeUsers}
                    />
                </Grid>
                <Grid item xs={3}>
                    <DashboardCard
                        title="Write-Off"
                        tooltip="Todos los créditos que pasaron a cartera castigada (Write-off) esto incluye los estados corralón, robadas, fraude, cobranza judicial."
                        icon={<Delete />}
                        value={
                            credits === undefined
                                ? "--"
                                : `${Object.keys(credits)
                                      .filter((status) =>
                                          writeOffContracts.includes(
                                              status as CreditStatus
                                          )
                                      )
                                      .reduce(
                                          (acc, status) =>
                                              acc + credits[status] ?? 0,
                                          0
                                      )}`
                        }
                    />
                </Grid>
                <Grid item xs={3}>
                    <DashboardCard
                        title="Créditos vigentes"
                        tooltip="Todos los contractos vigentes al día de hoy incluyendo DPD  30,  DPD 60,  DPD 90  y DPD 120 excluyendo los créditos en Write-off"
                        icon={<Document />}
                        value={
                            credits === undefined
                                ? "--"
                                : `${Object.keys(credits)
                                      .filter((status) =>
                                          activeContracts.includes(
                                              status as CreditStatus
                                          )
                                      )
                                      .reduce(
                                          (acc, status) =>
                                              acc + credits[status] ?? 0,
                                          0
                                      )}`
                        }
                    />
                </Grid>
                <Grid item xs={3}>
                    <DashboardCard
                        title="First payment default"
                        tooltip="Todos los usuarios que a la fecha no han hecho el pago de su primera cuota"
                        icon={<Ghost />}
                        value={fpd === undefined ? "--" : fpd}
                    />
                </Grid>
                <Grid item xs={12}>
                    <DashboardCard
                        title="Inventario disponible"
                        tooltip="Motos registradas en el inventario por estados"
                    >
                        <div className="display_flex flex_justify_between flex_gap_xl border_transparent m_b_xs">
                            {inventory &&
                                Object.keys(statusVehicles)
                                    .filter((status) => inventory[status])
                                    .map((status) => (
                                        <div
                                            className="display_flex flex_col cursor_pointer"
                                            role="button"
                                            tabIndex={0}
                                            key={status}
                                            onClick={() => {
                                                navigate(
                                                    `/inventory?statusFilter=${status}`
                                                );
                                            }}
                                            onKeyDown={() => {
                                                navigate(
                                                    `/inventory?statusFilter=${status}`
                                                );
                                            }}
                                        >
                                            <Typography
                                                scale="small"
                                                weight="400"
                                                textColor="neutral_900"
                                                className="m_b_xs"
                                            >
                                                {
                                                    (statusVehicles as any)[
                                                        status
                                                    ]?.title
                                                }
                                            </Typography>
                                            <div className="display_flex flex_align_center flex_gap_md">
                                                <LightTooltip
                                                    title={
                                                        (statusVehicles as any)[
                                                            status
                                                        ]?.title
                                                    }
                                                >
                                                    <div
                                                        className={`dso_card_icon bg_${
                                                            (
                                                                statusVehicles as any
                                                            )[status]?.color
                                                        }_nocontrast dim_fit display_flex text_neutral_0`}
                                                    >
                                                        <Moto />
                                                    </div>
                                                </LightTooltip>

                                                <Typography
                                                    scale="large"
                                                    weight="600"
                                                    textColor="neutral_900"
                                                >
                                                    {inventory[status]}
                                                </Typography>
                                            </div>
                                        </div>
                                    ))}
                        </div>
                    </DashboardCard>
                </Grid>
            </Grid>
            <div className="m_y_md display_flex flex_gap_md flex_align_center">
                <Typography
                    scale="large"
                    weight="600"
                    textColor="neutral_900"
                    className=""
                >
                    Estadísticas temporales
                </Typography>
                <DateSelect
                    value={fecha}
                    hasValue
                    placeholder="Selecciona fechas"
                    setHasValue={() => {}}
                    setValue={changeDate}
                />
            </div>

            <Grid container spacing={1} className="">
                <Grid item xs={4}>
                    <DashboardCard
                        title="Motos recuperadas"
                        subtitle={`Promedio ${date}`}
                        tooltip="Motos que en el periodo de tiempo seleccionado cambiaron a estado recuperada"
                        icon={<GPS />}
                        value={
                            creditsRepossession === undefined
                                ? "--"
                                : creditsRepossession
                        }
                    />
                </Grid>
                <Grid item xs={4}>
                    <DashboardCard
                        title="Motos devueltas"
                        subtitle={`Promedio ${date}`}
                        tooltip="Motos que en el periodo de tiempo seleccionado fueron devueltas por el usuario"
                        icon={<Motos />}
                        value={
                            creditsReturn === undefined ? "--" : creditsReturn
                        }
                    />
                </Grid>
                <Grid item xs={4}>
                    <DashboardCard
                        title="Créditos liquidados"
                        subtitle={`Promedio ${date}`}
                        tooltip="Créditos que se liquidaron en el periodo de tiempo seleccionado"
                        icon={<Moto />}
                        value={
                            creditsComplete === undefined
                                ? "--"
                                : creditsComplete
                        }
                    />
                </Grid>
                <Grid item xs={4}>
                    <DashboardCard
                        title="Motos entregadas"
                        subtitle={`Promedio ${date}`}
                        tooltip="Motos que fueron entregadas a nuevos créditos en el periodo de tiempo seleccionado"
                        icon={<Moto />}
                        value={
                            creditsDelivered === undefined
                                ? "--"
                                : creditsDelivered
                        }
                    />
                </Grid>
                <Grid item xs={4}>
                    <DashboardCard
                        title="Porcentaje de recaudo"
                        subtitle={`Promedio ${date}`}
                        tooltip="Porcentaje del dinero que se debia recaudar, en el periodo de tiempo, que efectivamente se recaudo"
                        icon={<Percentage />}
                        value={
                            payments?.paidPercentage === undefined ||
                            payments?.paidPercentage === null
                                ? "--"
                                : `${
                                      Math.round(payments.paidPercentage * 10) /
                                      10
                                  }%`
                        }
                    />
                </Grid>
                <Grid item xs={4}>
                    <DashboardCard
                        title="Dinero recaudado"
                        subtitle={`Promedio ${date}`}
                        tooltip="Dinero neto recaudado de los usuarios que entro en el Periodo de tiempo seleccionado"
                        icon={<Money />}
                        value={
                            payments?.totalPaid === undefined ||
                            payments?.totalPaid === null
                                ? "--"
                                : priceMXNTotal(payments.totalPaid, 0)
                        }
                    />
                </Grid>
            </Grid>
        </div>
    );
}

export default Dashboard;
