/* eslint-disable no-param-reassign */
/* eslint-disable no-undef-init */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useRef, useState } from "react";

// @ts-ignore
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import mapboxgl, { Map, MarkerOptions } from "mapbox-gl";

import { priceFormatFinancial } from "helpers/prices/prices";
import {
    addRoute,
    editRoute,
    createInterestPoint,
    editRecovery,
    FetchGeofences,
} from "helpers/fetchs/repossession";

import { Button, Input, Knob, Typography } from "design_system/src";
import { LightTooltip } from "components/hocs/tooltip/Tooltip";
import {
    RepossessionPolicy,
    Repossession,
    RepossessionPolicyValues,
    InterestPointType,
    InterestPoint,
    RouteEdit,
    InterestPointStatus,
} from "models/repossession.interface";
import { getHotspotsByInternalId } from "helpers/fetchs/inventory";

import { ReactComponent as Light } from "design_system/src/static/icons/lightbulb-creative.svg";
import { ReactComponent as Moto } from "design_system/src/static/icons/moto-sport.svg";
import { ReactComponent as Back } from "design_system/src/static/icons/back.svg";
import { ReactComponent as Left } from "design_system/src/static/icons/left-lg.svg";
import { ReactComponent as Search } from "design_system/src/static/icons/search.svg";
import { ReactComponent as Street } from "design_system/src/static/icons/street.svg";
import { ReactComponent as Menu } from "design_system/src/static/icons/hamburger-menu.svg";
import { ReactComponent as Delete } from "design_system/src/static/icons/trash.svg";
import { ReactComponent as AddCircle } from "design_system/src/static/icons/add-circle.svg";
import { ReactComponent as Right } from "design_system/src/static/icons/chevron-right.svg";
import { ReactComponent as Down } from "design_system/src/static/icons/chevron-down.svg";
import { ReactComponent as Signal } from "design_system/src/static/icons/radio-signal.svg";
import { ReactComponent as NoSignal } from "design_system/src/static/icons/no-radio-signal.svg";
import { ReactComponent as Home } from "design_system/src/static/icons/temple.svg";
import { ReactComponent as Hotspot } from "design_system/src/static/icons/fire-camp.svg";
import { toasterNotification } from "helpers/globalNotifications/globalNotifications";
import { toasterOptions } from "enums/toaster.enum";

import RecoveryDetail from "./RecoveryDetail";
import CreditGPSDetail from "./CreditGPSDetail";
import { createCluster, createMarker } from "./markers";

import motoLoading from "../../../static/icons/loading_moto.gif";
import {
    allVehiclesSourceBase,
    generateMockPoints,
    hotspotHeatLayerDefinition,
    hotspotLayerDefinition,
    vehiclesSourceBase,
} from "../Repossessions.constants";
import {
    TDetailsLocation,
    TDetailsMap,
    TDetailsRepossession,
} from "../Repossessions";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API_KEY || "";

const typesIcons = {
    [InterestPointType.address]: (
        <div className="flex_center">
            <Home className="dim_xl text_neutral_800" />
        </div>
    ),
    [InterestPointType.hotspot]: (
        <div className="flex_center">
            <Hotspot className="dim_xl text_primary_300" />
        </div>
    ),
    [InterestPointType.signalVehicle]: (
        <div className="display_flex flex_gap_xs flex_align_center m_b_xs">
            <Moto className="dim_lg text_green_300" />
            <Signal className="dim_lg text_green_300" />
        </div>
    ),
    [InterestPointType.noSignalVehicle]: (
        <div className="display_flex flex_gap_xs flex_align_center m_b_xs">
            <Moto className="dim_lg text_red_300" />
            <NoSignal className="dim_lg text_red_300" />
        </div>
    ),
};

interface RecoveryMapProps {
    loadingData?: boolean;
    allLocations?: any[];
    // searchError: string;
    // setSearch: Function;
    ozonios: any[];
    selecteds?: {
        _id?: string;
        repossession: Repossession;
        interestPoint: InterestPoint;
    }[];
    setSelecteds: Function;
    query?: string;
    setQuery: Function;
    map: React.MutableRefObject<any>;
    routeDate: string;
    setRouteDate: Function;
    routeOzonio: string;
    setRouteOzonio: Function;
    openRuta: boolean;
    setOpenRuta: Function;
    idRuta?: string;
    viewTab: number;
    setIdRuta: Function;
    fetchRoutes: Function;
    setViewTab: Function;
    updateSource: Function;
    isFullMap: boolean;
    details: TDetailsMap | undefined;
    setDetails: Function;
}

export const compareRouteRecoveries = (recovery1: any, recovery2: any) => {
    if (!recovery1 || !recovery2) return false;
    if (recovery1.interestPoint?._id && recovery2.interestPoint?._id)
        return recovery1.interestPoint._id === recovery2.interestPoint._id;
    if (!recovery1.interestPoint?._id && !recovery2.interestPoint?._id)
        return recovery1.repossession._id === recovery2.repossession._id;

    return false;
};

const initialDisplay: any = {
    center: {
        lat: 19.42847,
        lng: -99.12766,
    },
    zoom: 10,
};

let markers: { [id: string]: mapboxgl.Marker } = {};
let visibleMarkers: { [id: string]: mapboxgl.Marker } = {};
let firstUpdate = true;
let viewTabExt = 0;

const RecoveryMap: React.FC<RecoveryMapProps> = ({
    loadingData,
    allLocations,
    ozonios,
    selecteds,
    setSelecteds,
    map,
    routeDate,
    setRouteDate,
    routeOzonio,
    setRouteOzonio,
    openRuta,
    setOpenRuta,
    idRuta,
    viewTab,
    setIdRuta,
    fetchRoutes,
    setViewTab,
    updateSource,
    query,
    setQuery,
    isFullMap,
    details,
    setDetails,
}) => {
    const [openSearch, setOpenSearch] = useState(false);

    const [openDetailsIndex, setOpenDetailsIndex] = useState(-1);
    const [loadingHotspots, setLoadingHotspots] = useState(false);

    const [currentHotspotsId, setCurrentHotspotsId] = useState("");

    const [lng, setLng] = useState(initialDisplay.center.lng);
    const [lat, setLat] = useState(initialDisplay.center.lat);
    const [zoom, setZoom] = useState(initialDisplay.zoom);

    const mapContainer = useRef<any>(null);

    const openRoute = () => {
        setOpenRuta(true);
        setDetails(undefined);
    };
    const closeRoute = () => {
        if (openRuta) {
            setOpenRuta(false);
            const lineString: any = {
                type: "Feature",
                geometry: {
                    type: "LineString",
                    coordinates: [],
                },
            };
            map.current.getSource("line-route").setData(lineString);
        }
    };

    const goToVehicle = (recovery: {
        repossession: Repossession;
        interestPoint: InterestPoint;
    }) => {
        closeRoute();
        setDetails(recovery);
        map.current.flyTo({
            center: {
                lat: recovery.interestPoint.location.coordinates[1],
                lng: recovery.interestPoint.location.coordinates[0] - 0.0045,
            },
            zoom: 15,
        });
    };
    const goToVehicleGPS = (position: TDetailsLocation) => {
        closeRoute();
        setDetails(position);
        map.current.flyTo({
            center: {
                lat: position.coordinates[1],
                lng: position.coordinates[0] - 0.0045,
            },
            zoom: 15,
        });
    };

    const updateMarkers = (adjustView: boolean = true) => {
        const features = map.current.querySourceFeatures("vehicles");
        const featuresL = map.current.querySourceFeatures("allvehicles");
        if (viewTabExt > 1) {
            Object.values(visibleMarkers).forEach((marker: any) => {
                marker.remove();
            });
        }
        const isAllVehicles = viewTabExt === 0;
        const evalFeatures = isAllVehicles ? featuresL : features;
        if (evalFeatures) {
            const newMarkers: any = {};
            Object.values(visibleMarkers).forEach((marker: any) => {
                marker.remove();
            });
            evalFeatures.forEach((feature: any) => {
                const coords = feature.geometry.coordinates;
                const props = feature.properties;
                const id = props.cluster
                    ? `${props.cluster_id}${isAllVehicles ? "AV" : "IP"}`
                    : props.id;

                let marker = markers[id];

                if (!marker) {
                    const el: any = props.cluster
                        ? createCluster(props, false, () => {
                              map.current.flyTo({
                                  center: {
                                      lat: feature.geometry.coordinates[1],
                                      lng: feature.geometry.coordinates[0],
                                  },
                                  zoom: feature.tile.z + 3,
                              });
                          })
                        : createMarker(
                              feature,
                              isAllVehicles
                                  ? () => {
                                        goToVehicleGPS(
                                            JSON.parse(props.position)
                                        );
                                    }
                                  : () => {
                                        goToVehicle({
                                            repossession: JSON.parse(
                                                props.repossession
                                            ),
                                            interestPoint: JSON.parse(
                                                props.interestPoint
                                            ),
                                        });
                                    }
                          );
                    if (el) {
                        marker = new mapboxgl.Marker({
                            element: el,
                            anchor: props.cluster ? "center" : "bottom",
                        }).setLngLat(coords);
                        markers[id] = marker;
                    }
                }
                newMarkers[id] = marker;
                marker.addTo(map.current);
            });

            visibleMarkers = newMarkers;
        }
    };

    useEffect(() => {
        if (map.current) return;
        // eslint-disable-next-line no-param-reassign
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: "mapbox://styles/mapbox/streets-v12",
            center: [lng, lat],
            zoom,
        });
        map.current.addControl(new mapboxgl.NavigationControl(), "bottom-left");
        map.current.on("load", () => {
            map.current.on("sourcedata", (e: any) => {
                if (e.sourceId === "vehicles" && firstUpdate) {
                    updateSource();
                    firstUpdate = false;
                }
            });
            map.current.addSource("line-route", {
                type: "geojson",
                data: {
                    type: "FeatureCollection",
                    features: [],
                },
            });
            map.current.addSource("vehicle-hotspots", {
                type: "geojson",
                data: {
                    type: "FeatureCollection",
                    features: [],
                },
            });
            map.current.addSource("allvehicles", allVehiclesSourceBase);
            map.current.addSource("vehicles", vehiclesSourceBase);
            map.current.addLayer({
                id: "all_points",
                type: "circle",
                source: "allvehicles",
                paint: {
                    "circle-color": "transparent",
                    "circle-opacity": 0.6,
                    "circle-radius": 12,
                },
            });
            map.current.addLayer({
                id: "interest_points",
                type: "circle",
                source: "vehicles",
                paint: {
                    "circle-color": "transparent",
                    "circle-opacity": 0.6,
                    "circle-radius": 12,
                },
            });
            FetchGeofences().then((geofencesData) => {
                geofencesData?.forEach((geofence: any) => {
                    const sourceName = `${geofence.code}-geofence-data`;
                    const geofenceName = `${geofence.code}-geofence`;
                    const outlineName = `${geofence.code}-outline`;
                    map.current.addSource(sourceName, {
                        type: "geojson",
                        data: {
                            type: "Feature",
                            geometry: geofence.geometry,
                        },
                    });
                    // Add a new layer to visualize the polygon.
                    map.current.addLayer({
                        id: geofenceName,
                        type: "fill",
                        source: sourceName, // reference the data source
                        layout: {},
                        maxzoom: 11,
                        paint: {
                            "fill-color": "#0080ff", // blue color fill
                            "fill-opacity": 0.3,
                        },
                    });
                    // Add a black outline around the polygon.
                    map.current.addLayer({
                        id: outlineName,
                        type: "line",
                        source: sourceName,
                        layout: {},
                        maxzoom: 11,
                        paint: {
                            "line-color": "#000",
                            "line-width": 3,
                        },
                    });
                });
            });
            map.current.on("render", () => {
                updateMarkers();
            });
        });
        map.current.on("move", () => {
            setLng(map.current.getCenter().lng.toFixed(4));
            setLat(map.current.getCenter().lat.toFixed(4));
            setZoom(map.current.getZoom().toFixed(2));
        });

        map.current.on("click", "interest_points_circles", (event: any) => {
            const props = event.features[0].properties;
            if (viewTabExt) {
                goToVehicle({
                    repossession: JSON.parse(props.repossession),
                    interestPoint: JSON.parse(props.interestPoint),
                });
            }
        });
        map.current.on("mouseenter", "interest_points_circles", () => {
            if (viewTabExt) map.current.getCanvas().style.cursor = "pointer";
        });
        map.current.on("mouseleave", "interest_points_circles", () => {
            if (viewTabExt) map.current.getCanvas().style.cursor = "";
        });
    });

    const toogleDetail = (i: number) => {
        setOpenDetailsIndex(openDetailsIndex === i ? -1 : i);
    };

    useEffect(() => {
        map.current.flyTo(initialDisplay);

        return () => {
            visibleMarkers = {};
            markers = {};
            firstUpdate = true;
        };
    }, []);

    const clearHotspots = () => {
        const emptyPoints: any = {
            type: "Feature",
            geometry: {
                type: "LineString",
                coordinates: [],
            },
        };
        map.current.getSource("vehicle-hotspots")?.setData(emptyPoints);
        if (map.current.getLayer("interest_points_circles")) {
            map.current.removeLayer("interest_points_circles");
        }
        if (map.current.getLayer("interest_points_heat")) {
            map.current.removeLayer("interest_points_heat");
        }
        setCurrentHotspotsId("");
    };

    const getHotspots = async (coordinates: [], internalId: string) => {
        let points = [];
        if (internalId === currentHotspotsId) {
            points = map.current.getSource("vehicle-hotspots")._data.features;
        } else {
            setCurrentHotspotsId(internalId);
            setLoadingHotspots(true);
            let hotspots: any = [];
            try {
                hotspots = (await getHotspotsByInternalId(internalId)).locations.map((location:{latitude:number,longitude:number})=>({
                    type: "Feature",
                    geometry: {
                        type: "Point",
                        coordinates: [location.longitude,location.latitude]
                    },
                    properties: {}
                }));
            } catch(e:any){
                toasterNotification({
                    toasterType: toasterOptions.error,
                    msg: `Fallo cargando los hotspots: ${e?.message??e}`,
                    style: "light",
                });
            } finally {
                setLoadingHotspots(false);
            }
            points = hotspots.map((point: any) => {
                return {
                    ...point,
                    properties:
                        viewTabExt === 0
                            ? {}
                            : {
                                  repossession: JSON.stringify(
                                      (details as TDetailsRepossession)
                                          .repossession
                                  ),
                                  interestPoint: JSON.stringify({
                                      isTrackGPS: false,
                                      createdAt: "",
                                      _id: `tmp-${internalId}-${point.geometry.coordinates[0]}-${point.geometry.coordinates[1]}`,
                                      location: point.geometry,
                                      type: InterestPointType.hotspot,
                                      status: InterestPointStatus.expired,
                                      description: "",
                                      visits: 0,
                                  }),
                              },
                };
            });
            if (!details) return;
            map.current.getSource("vehicle-hotspots").setData({
                type: "FeatureCollection",
                features: points,
            });
            if (map.current.getLayer("interest_points_circles")) {
                map.current.removeLayer("interest_points_circles");
            }
            if (map.current.getLayer("interest_points_heat")) {
                map.current.removeLayer("interest_points_heat");
            }
            map.current.addLayer(hotspotHeatLayerDefinition, "waterway-label");
            map.current.addLayer(hotspotLayerDefinition, "waterway-label");
        }

        const bounds = new mapboxgl.LngLatBounds();
        [
            ...points,
            ...(viewTabExt
                ? map.current.getSource("vehicles")._data.features
                : []),
        ].forEach((point) => {
            bounds.extend(point.geometry.coordinates as any);
        });

        bounds.getEast();
        const size = (bounds.getEast() - bounds.getWest()) * 2.2;
        bounds.extend({
            lng: bounds.getEast() - size,
            lat: bounds.getNorth(),
        });

        map.current.fitBounds(bounds, {
            padding: 40,
        });
    };

    useEffect(() => {
        updateSource();
        if (!details) {
            clearHotspots();
            return;
        }
        let coordinates: any;
        let internalId: any;
        if (viewTabExt === 0) {
            coordinates = (details as TDetailsLocation).coordinates;
            internalId = (details as TDetailsLocation).internalId;
        } else if (viewTabExt === 1) {
            coordinates = (details as TDetailsRepossession)?.interestPoint
            ?.location?.coordinates;
            internalId = (details as TDetailsRepossession).repossession
                .vehicleId;
        }
        if (!coordinates) {
            clearHotspots();
            return;
        }
        getHotspots(coordinates, internalId);
    }, [details]);

    useEffect(() => {
        if (openRuta && (selecteds?.length ?? 0) > 1) {
            const lineString: any = {
                type: "Feature",
                geometry: {
                    type: "LineString",
                    coordinates: [],
                },
            };
            const bounds = new mapboxgl.LngLatBounds();
            selecteds?.forEach((recoveryRoute) => {
                bounds.extend([
                    ...recoveryRoute.interestPoint.location.coordinates,
                ] as any);
                lineString.geometry.coordinates.push([
                    ...recoveryRoute.interestPoint.location.coordinates,
                ]);
            });
            bounds.getEast();
            const size = (bounds.getEast() - bounds.getWest()) * 1.5;
            bounds.extend({
                lng: bounds.getEast() + size,
                lat: bounds.getNorth(),
            });
            map.current.getSource("line-route").setData(lineString);
            if (map.current.getLayer("line")) {
                map.current.removeLayer("line");
            }
            map.current.addLayer({
                id: "line",
                type: "line",
                source: "line-route",
                layout: {
                    "line-join": "miter",
                    "line-miter-limit": 2,
                },
                paint: {
                    "line-color": "#32E0C1",
                    "line-width": 4,
                },
            });
            map.current.fitBounds(bounds, {
                padding: 40,
            });
        }
    }, [selecteds, openRuta]);

    const closeDetails = () => {
        if (details) {
            map.current.flyTo({ zoom: 12 });
            setDetails(undefined);
        }
    };

    const searchVehicle = () => {
        const features = map.current.querySourceFeatures(
            viewTab ? "vehicles" : "allvehicles"
        );
        if (features?.length === 1) {
            const props = features[0].properties;
            if (viewTab) {
                goToVehicle({
                    repossession: JSON.parse(props.repossession),
                    interestPoint: JSON.parse(props.interestPoint),
                });
            } else {
                goToVehicleGPS(JSON.parse(props.position));
            }
        } else if (features?.length) {
            const bounds = new mapboxgl.LngLatBounds();
            features?.forEach((feature: any) => {
                bounds.extend(feature.geometry.coordinates);
            });
            map.current.fitBounds(bounds, {
                padding: 100,
            });
        }
    };
    useEffect(() => {
        viewTabExt = viewTab;
        setQuery("");
        setOpenSearch(false);
        updateMarkers();
        closeDetails();
        closeRoute();
    }, [viewTab]);

    const addToRoute = (recovery: {
        repossession: Repossession;
        interestPoint: InterestPoint;
    }) => {
        const find = (selecteds ?? []).findIndex((item) =>
            compareRouteRecoveries(item, recovery)
        );
        if (find !== -1) {
            setSelecteds([
                ...(selecteds ?? []).filter(
                    (item) => !compareRouteRecoveries(item, recovery)
                ),
            ]);
        } else {
            setSelecteds([...(selecteds ?? []), recovery]);
        }
        closeDetails();
        openRoute();
    };

    const onDragEnd = (result: any) => {
        // dropped outside the list
        if (!result.destination || !selecteds) {
            return;
        }

        const reorder = Array.from(selecteds);
        const [removed] = reorder.splice(result.source.index, 1);
        reorder.splice(result.destination.index, 0, removed);

        setSelecteds(reorder);
        if (openDetailsIndex !== -1) {
            if (result.source.index === openDetailsIndex) {
                setOpenDetailsIndex(result.destination.index);
            } else if (result.destination.index >= openDetailsIndex) {
                setOpenDetailsIndex(openDetailsIndex + 1);
            }
        }
    };
    const cleanRoute = () => {
        fetchRoutes();
        setSelecteds([]);
        setViewTab(1);
        setRouteDate(undefined);
        setRouteOzonio("");
        closeRoute();
        setIdRuta(undefined);
    };

    const saveRoute = async () => {
        if (selecteds) {
            const uploadInterestPoints = [...selecteds];
            const p = [];
            for (let i = 0; i < uploadInterestPoints.length; i++) {
                const { repossession, interestPoint } = uploadInterestPoints[i];
                if (!interestPoint._id || interestPoint._id.startsWith("tmp")) {
                    p.push(
                        createInterestPoint(repossession.vehicle._id, {
                            type: interestPoint.type,
                            status: InterestPointStatus.expired,
                            coordinates: [
                                ...interestPoint.location.coordinates,
                            ] as [number, number],
                        }).then((res: any) => {
                            uploadInterestPoints[i].interestPoint._id =
                                res.interestPoint[
                                    res.interestPoint.length - 1
                                ]._id;
                        })
                    );
                }
            }
            await Promise.all(p);

            const route: RouteEdit = {
                teamLeader: routeOzonio,
                dateEstimate: routeDate,
            };
            if (idRuta) {
                const promises = [];
                promises.push(editRoute(idRuta, route));
                selecteds.forEach((ele, i) => {
                    if (ele._id) {
                        promises.push(
                            editRecovery(ele._id, {
                                index: i,
                            })
                        );
                    }
                });
                Promise.all(promises).then(() => cleanRoute());
            } else {
                route.week = "";
                route.recoveries = selecteds.map(
                    ({ repossession, interestPoint }, index) => ({
                        idRepossession: repossession._id,
                        idInterestPoint: interestPoint._id,
                        index,
                    })
                );
                addRoute(route).then(() => {
                    cleanRoute();
                });
            }
        }
    };

    return (
        <>
            {/* Loader */}
            {loadingData ? (
                <div className="loader_map flex_center">
                    <Typography
                        weight="600"
                        scale="medium"
                        textColor="neutral_0"
                        className="m_b_xs"
                    >
                        Cargando la información...
                    </Typography>
                    <img src={motoLoading} alt="" className="h_xxxl" />
                </div>
            ) : null}
            {/* Conventions */}
            {viewTab === 0 && (
                <div
                    className={`map_conventions_bar bg_neutral_0 display_flex flex_col p_md flex_gap_xs br_xs shadow_hard ${
                        details ? "display_none" : ""
                    }`}
                >
                    <div className="display_flex flex_align_center flex_gap_xxs">
                        <div className="dim_lg br_xxs bg_green_300" />
                        <Typography
                            weight="600"
                            scale="medium"
                            textColor="neutral_900"
                        >
                            Motos con señal
                        </Typography>
                    </div>
                    <div className="display_flex flex_align_center flex_gap_xxs">
                        <div className="dim_lg br_xxs bg_red_300" />
                        <Typography
                            weight="600"
                            scale="medium"
                            textColor="neutral_900"
                        >
                            Motos sin señal
                        </Typography>
                    </div>
                    <div className="display_flex flex_align_center flex_gap_xxs">
                        <div className="dim_lg br_xxs bg_neutral_600" />
                        <Typography
                            weight="600"
                            scale="medium"
                            textColor="neutral_900"
                        >
                            Motos gpsTracker
                        </Typography>
                    </div>
                </div>
            )}
            {viewTab === 1 && (
                <div
                    className={`map_conventions_bar bg_neutral_0 display_flex flex_col p_md flex_gap_xs br_xs shadow_hard ${
                        details ? "display_none" : ""
                    }`}
                >
                    <div className="display_flex flex_align_center flex_gap_xxs">
                        <div className="dim_lg br_xxs bg_neutral_600" />
                        <Typography
                            weight="600"
                            scale="medium"
                            textColor="neutral_900"
                        >
                            Devolución
                        </Typography>
                    </div>
                    <div className="display_flex flex_align_center flex_gap_xxs">
                        <div className="dim_lg br_xxs bg_primary_300" />
                        <Typography
                            weight="600"
                            scale="medium"
                            textColor="neutral_900"
                        >
                            Prioritario
                        </Typography>
                    </div>
                    <div className="display_flex flex_align_center flex_gap_xxs">
                        <div className="dim_lg br_xxs bg_green_300" />
                        <Typography
                            weight="600"
                            scale="medium"
                            textColor="neutral_900"
                        >
                            Mora 30
                        </Typography>
                    </div>
                    <div className="display_flex flex_align_center flex_gap_xxs">
                        <div className="dim_lg br_xxs bg_secondary_600" />
                        <Typography
                            weight="600"
                            scale="medium"
                            textColor="neutral_900"
                        >
                            Mora 60
                        </Typography>
                    </div>
                    <div className="display_flex flex_align_center flex_gap_xxs">
                        <div className="dim_lg br_xxs bg_primary_500" />
                        <Typography
                            weight="600"
                            scale="medium"
                            textColor="neutral_900"
                        >
                            Fraude
                        </Typography>
                    </div>
                    <div className="display_flex flex_align_center flex_gap_xxs">
                        <div className="dim_lg br_xxs bg_red_300" />
                        <Typography
                            weight="600"
                            scale="medium"
                            textColor="neutral_900"
                        >
                            Pre-Write Off
                        </Typography>
                    </div>
                    <div className="display_flex flex_align_center flex_gap_xxs">
                        <div className="dim_lg br_xxs bg_neutral_1000" />
                        <Typography
                            weight="600"
                            scale="medium"
                            textColor="neutral_900"
                        >
                            Write Off
                        </Typography>
                    </div>
                </div>
            )}
            {/* SearchBar */}
            <div
                className={`map_search_bar p_x_md p_y_xs flex_gap_md br_b_xs shadow_hard ${
                    openSearch ? "open" : ""
                }`}
            >
                <div className="flex_center">
                    <div
                        className="br_circle flex_center bg_primary_300 p_xs cursor_pointer"
                        onClick={() => setOpenSearch(!openSearch)}
                        onKeyDown={() => setOpenSearch(!openSearch)}
                        role="button"
                        tabIndex={0}
                    >
                        <Left className="open_icon dim_md" />
                    </div>
                    <Input
                        type="text"
                        title=""
                        name="search"
                        className="seach_input"
                        placeholder="Buscar vehículo"
                        onChange={(e: any) => setQuery(e.target.value)}
                        value={query}
                    />
                </div>
                {openSearch ? (
                    <Button
                        onClick={searchVehicle}
                        scale="small"
                        icon={<Search />}
                    >
                        Buscar
                    </Button>
                ) : (
                    <Search className="dim_lg text_primary_300 " />
                )}
            </div>
            {/* Ruta */}
            {viewTab === 1 && (
                <div
                    className={`map_route overflow_hidden br_xs shadow_hard ${
                        openRuta ? "open" : ""
                    }`}
                >
                    <div
                        className="route_bar p_x_md p_y_xs flex_gap_md cursor_pointer"
                        onClick={openRuta ? closeRoute : openRoute}
                        onKeyDown={openRuta ? closeRoute : openRoute}
                        role="button"
                        tabIndex={0}
                    >
                        <div className="shadow_hard br_xs p_xs flex_center">
                            <Street className="text_primary_300 dim_lg" />
                        </div>
                        <Typography
                            scale="large"
                            weight="600"
                            className="flex_grow_1"
                        >
                            {idRuta ? "Editando Ruta" : "Tu ruta"} (
                            {selecteds?.length ?? 0})
                        </Typography>
                        <div className="br_circle flex_center bg_primary_300 p_xs ">
                            <Left className="open_icon dim_md" />
                        </div>
                    </div>
                    <div className="flex_content_col route_cont bg_neutral_300">
                        <div className="flex_body p_md display_flex flex_col">
                            <div className="display_flex flex_gap_md">
                                <Input
                                    type="short-date"
                                    name="fecha"
                                    title="Fecha de ruta"
                                    className="flex_basis_0 flex_grow_1"
                                    value={routeDate}
                                    onChange={(e: any) =>
                                        setRouteDate(e.target.value)
                                    }
                                />
                                <Input
                                    type="select"
                                    name="ozonio"
                                    title="Encargado"
                                    placeholder="Selecciona un encargado"
                                    className="flex_basis_0 flex_grow_1"
                                    options={ozonios}
                                    value={routeOzonio}
                                    onChange={(e: any) =>
                                        setRouteOzonio(e.target.value)
                                    }
                                />
                            </div>
                            {!selecteds?.length && (
                                <Typography
                                    scale="small"
                                    weight="400"
                                    textColor="neutral_700"
                                    className="m_b_md"
                                >
                                    Para crear una nueva ruta, elige la fecha,
                                    asigna a un equipo y selecciona a las
                                    ozonetas a recuperar.
                                </Typography>
                            )}
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="droppable">
                                    {(provided: any, snapshot: any) => (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            className="flex_grow_1"
                                        >
                                            {selecteds?.map((item, index) => (
                                                <Draggable
                                                    key={`${item.repossession._id} ${item.interestPoint._id}`}
                                                    draggableId={`${item.repossession._id} ${item.interestPoint._id}`}
                                                    index={index}
                                                >
                                                    {(
                                                        provided2: any,
                                                        snapshot2: any
                                                    ) => (
                                                        <div
                                                            className={`route_component br_sm m_b_sm ${
                                                                index ===
                                                                openDetailsIndex
                                                                    ? "open"
                                                                    : ""
                                                            } ${
                                                                snapshot2.isDragging
                                                                    ? "bg_primary_50"
                                                                    : "bg_neutral_0"
                                                            }`}
                                                            ref={
                                                                provided2.innerRef
                                                            }
                                                            {...provided2.draggableProps}
                                                            {...provided2.dragHandleProps}
                                                            style={
                                                                provided2
                                                                    .draggableProps
                                                                    .style
                                                            }
                                                        >
                                                            <div className="display_flex flex_align_center flex_gap_md flex_justify_between p_x_lg p_y_xs">
                                                                <div className="display_flex flex_align_center flex_gap_md">
                                                                    <Menu className="dim_lg flex_shrink_0" />
                                                                    {!idRuta && (
                                                                        <Delete
                                                                            className="dim_lg flex_shrink_0 cursor_pointer"
                                                                            role="button"
                                                                            tabIndex={
                                                                                0
                                                                            }
                                                                            onKeyDown={() => {
                                                                                addToRoute(
                                                                                    item
                                                                                );
                                                                            }}
                                                                            onClick={() => {
                                                                                addToRoute(
                                                                                    item
                                                                                );
                                                                            }}
                                                                        />
                                                                    )}
                                                                    {
                                                                        typesIcons[
                                                                            item
                                                                                .interestPoint
                                                                                .type
                                                                        ]
                                                                    }
                                                                </div>
                                                                <div
                                                                    className="display_flex flex_align_center flex_gap_md cursor_pointer"
                                                                    role="button"
                                                                    tabIndex={0}
                                                                    onKeyDown={() => {
                                                                        toogleDetail(
                                                                            index
                                                                        );
                                                                    }}
                                                                    onClick={() => {
                                                                        toogleDetail(
                                                                            index
                                                                        );
                                                                    }}
                                                                >
                                                                    <div className="flex_center flex_gap_xs overflow_hidden">
                                                                        <div className="h_xxl w_xxxl br_xs overflow_hidden flex_shrink_0">
                                                                            <img
                                                                                className="img_contain"
                                                                                src={
                                                                                    item
                                                                                        .repossession
                                                                                        ?.vehicle
                                                                                        ?.images?.[0]
                                                                                        ? item
                                                                                              .repossession
                                                                                              .vehicle
                                                                                              .images?.[0]
                                                                                              .url
                                                                                        : `${process.env.REACT_APP_STORAGE_BASE_URL}public/uploads/generic-moto.png`
                                                                                }
                                                                                alt="vehicle"
                                                                            />
                                                                        </div>
                                                                        <div className="overflow_hidden">
                                                                            <Typography
                                                                                scale="medium"
                                                                                weight="600"
                                                                                className="overflow_ellipsis"
                                                                            >
                                                                                {
                                                                                    item
                                                                                        .repossession
                                                                                        .ozonerName
                                                                                }
                                                                            </Typography>
                                                                            <div className="flex_center flex_gap_xs w_fit overflow_hidden">
                                                                                {item
                                                                                    .repossession
                                                                                    ?.vehicle ? (
                                                                                    <>
                                                                                        <Typography
                                                                                            scale="small"
                                                                                            weight="600"
                                                                                            textColor="neutral_700"
                                                                                        >
                                                                                            {
                                                                                                item
                                                                                                    .repossession
                                                                                                    ?.vehicle
                                                                                                    ?.internalId
                                                                                            }
                                                                                        </Typography>
                                                                                        <Typography
                                                                                            scale="small"
                                                                                            weight="400"
                                                                                            textColor="neutral_600"
                                                                                        >{`${
                                                                                            item
                                                                                                .repossession
                                                                                                ?.vehicle
                                                                                                ?.brand
                                                                                                ?.name
                                                                                        } ${
                                                                                            item
                                                                                                .repossession
                                                                                                ?.vehicle
                                                                                                ?.model
                                                                                                ?.name
                                                                                        } ${
                                                                                            item
                                                                                                .repossession
                                                                                                ?.vehicle
                                                                                                ?.cylindersCapacity
                                                                                        } ${
                                                                                            item
                                                                                                .repossession
                                                                                                ?.vehicle
                                                                                                ?.suffix
                                                                                                ? item
                                                                                                      .repossession
                                                                                                      ?.vehicle
                                                                                                      ?.suffix
                                                                                                : ""
                                                                                        }`}</Typography>
                                                                                    </>
                                                                                ) : (
                                                                                    <Typography
                                                                                        scale="small"
                                                                                        weight="400"
                                                                                        textColor="neutral_600"
                                                                                    >
                                                                                        sin
                                                                                        datos
                                                                                        de
                                                                                        vehículo
                                                                                    </Typography>
                                                                                )}
                                                                                <div className="bg_secondary_700 p_y_xxxs p_x_xs br_lg">
                                                                                    <Typography
                                                                                        scale="xsmall"
                                                                                        weight="600"
                                                                                        className="text_no_break"
                                                                                    >
                                                                                        {
                                                                                            (
                                                                                                RepossessionPolicyValues as any
                                                                                            )[
                                                                                                item
                                                                                                    .repossession
                                                                                                    .policy
                                                                                            ]
                                                                                                .title
                                                                                        }
                                                                                    </Typography>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <Down className="dim_lg flex_shrink_0 open_details_icon" />
                                                                </div>
                                                            </div>
                                                            <div className="route_component_details">
                                                                <div className="p_xxxs ">
                                                                    <div className="bg_neutral_200 display_flex w_100_per br_b_xs">
                                                                        <div className="border_solid border_1 border_neutral_400 flex_basis_0 flex_grow_1 p_y_xs br_bl_xs">
                                                                            <Typography
                                                                                scale="medium"
                                                                                weight="600"
                                                                                textColor="red_300"
                                                                                className="text_no_break text_center"
                                                                            >
                                                                                {
                                                                                    item
                                                                                        .repossession
                                                                                        .latePayments
                                                                                }{" "}
                                                                                cuotas
                                                                            </Typography>
                                                                            <Typography
                                                                                scale="xsmall"
                                                                                weight="400"
                                                                                textColor="neutral_900"
                                                                                className="text_no_break text_center"
                                                                            >
                                                                                en
                                                                                mora
                                                                            </Typography>
                                                                        </div>
                                                                        <div className="display_flex flex_gap_xs flex_justify_center flex_align_center border_solid border_1 border_neutral_400 flex_basis_0 flex_grow_1 p_y_xs">
                                                                            <Knob
                                                                                value={Math.round(
                                                                                    (item
                                                                                        .repossession
                                                                                        .totalDebt /
                                                                                        item
                                                                                            .repossession
                                                                                            .totalCredit) *
                                                                                        100
                                                                                )}
                                                                                variant="fixed"
                                                                                symbol="%"
                                                                                className="dim_xl border_transparent"
                                                                            />
                                                                            <div>
                                                                                <Typography
                                                                                    scale="xsmall"
                                                                                    weight="600"
                                                                                    textColor="neutral_900"
                                                                                >
                                                                                    Cuotas
                                                                                </Typography>
                                                                                <Typography
                                                                                    scale="xsmall"
                                                                                    weight="600"
                                                                                    textColor="neutral_900"
                                                                                >
                                                                                    pagadas
                                                                                </Typography>
                                                                            </div>
                                                                        </div>
                                                                        <div className="border_solid border_1 border_neutral_400 flex_basis_0 flex_grow_1 p_y_xs">
                                                                            <Typography
                                                                                scale="medium"
                                                                                weight="600"
                                                                                textColor="neutral_900"
                                                                                className="text_center"
                                                                            >
                                                                                {priceFormatFinancial(
                                                                                    item
                                                                                        .repossession
                                                                                        .currentDebt,
                                                                                    0
                                                                                )}
                                                                            </Typography>
                                                                            <Typography
                                                                                scale="xsmall"
                                                                                weight="400"
                                                                                textColor="neutral_900"
                                                                                className="text_no_break text_center"
                                                                            >
                                                                                Para
                                                                                regularizar
                                                                            </Typography>
                                                                        </div>
                                                                        <div className="border_solid border_1 border_neutral_400 flex_center flex_basis_0 flex_grow_1 p_y_xs  br_br_xs">
                                                                            <Button
                                                                                scale="small"
                                                                                variant="ghost"
                                                                                icon={
                                                                                    <Right />
                                                                                }
                                                                                orientation="right"
                                                                                onClick={() =>
                                                                                    goToVehicle(
                                                                                        item
                                                                                    )
                                                                                }
                                                                            >
                                                                                Mas
                                                                                Detalles
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                        <div className="flex_footer p_x_lg p_y_md bg_neutral_0 display_flex flex_gap_md">
                            {idRuta && (
                                <Button
                                    onClick={() => cleanRoute()}
                                    scale="small"
                                    className="flex_grow_1"
                                    color="grey"
                                >
                                    Dejar de editar
                                </Button>
                            )}
                            <Button
                                onClick={saveRoute}
                                scale="small"
                                className="flex_grow_1"
                                disabled={
                                    !selecteds?.length ||
                                    routeDate === "" ||
                                    routeOzonio === ""
                                }
                            >
                                {idRuta ? "Editar" : "Crear"} ruta
                            </Button>
                        </div>
                    </div>
                </div>
            )}

            {/* Details */}
            <div
                className={`pos_absolute z_index_2 details_container ${
                    details ? "open" : ""
                }`}
            >
                <Button
                    variant="icon"
                    icon={<Back />}
                    subvariant="edit"
                    scale="small"
                    onClick={closeDetails}
                />
                <div className="flex_grow_1 h_100_per">
                    {viewTab === 1 ? (
                        (details as TDetailsRepossession)?.repossession ? (
                            <RecoveryDetail
                                detailRepossession={
                                    details as TDetailsRepossession
                                }
                                setDetailRepossession={setDetails}
                                addRoute={addToRoute}
                                selecteds={selecteds ?? []}
                                rutaId={idRuta}
                                map={map}
                                loadingHotspots={loadingHotspots}
                            />
                        ) : null
                    ) : (details as any)?.credit ? (
                        <CreditGPSDetail
                            detailVehicle={details}
                            map={map}
                            loadingHotspots={loadingHotspots}
                        />
                    ) : null}
                </div>
            </div>
            {/* Map */}
            <div ref={mapContainer} className="map_container" />
        </>
    );
};

export default RecoveryMap;
