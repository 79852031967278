import React, { useEffect, useRef, useState } from "react";

import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import "mapbox-gl/dist/mapbox-gl.css";
// @ts-ignore
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import {
    FetchRecoveryResponse,
    InterestPointType,
    Recovery,
    RecoveryPopulated,
    RecoveryStatus,
    RecoveryStatusValues,
    Repossession,
    Route,
} from "models/repossession.interface";

import { ReactComponent as Back } from "design_system/src/static/icons/back.svg";
import { ReactComponent as User } from "design_system/src/static/icons/user-circle.svg";
import { ReactComponent as Moto } from "design_system/src/static/icons/motorcycle.svg";
import { ReactComponent as AddCircle } from "design_system/src/static/icons/add-circle.svg";
import { ReactComponent as Signal } from "design_system/src/static/icons/radio-signal.svg";
import { ReactComponent as NoSignal } from "design_system/src/static/icons/no-radio-signal.svg";
import { ReactComponent as Home } from "design_system/src/static/icons/temple.svg";
import { ReactComponent as Hotspot } from "design_system/src/static/icons/fire-camp.svg";
import {
    Button,
    DateRange,
    Input,
    ModalConfirmation,
    Typography,
} from "design_system/src";

import "../recovery.scss";
import { Grid } from "@mui/material";
import { priceFormatFinancial } from "helpers/prices/prices";
import {
    FetchRecoveries,
    FetchRoute,
    editRecovery,
    sendRouteSlack,
} from "helpers/fetchs/repossession";
import { toasterNotification } from "helpers/globalNotifications/globalNotifications";
import { toasterOptions } from "enums/toaster.enum";

export const RouteDetail = () => {
    const { _id } = useParams();

    const navigate = useNavigate();

    const [route, setRoute] = useState<Route>();
    const [recoveries, setRecoveries] = useState<RecoveryPopulated[]>();
    const [totals, setTotals] = useState<{ [id in RecoveryStatus]: number }>();

    const [openConfirm, setOpenConfirm] = useState(false);
    const [confirmData, setConfirmData] = useState<any>();

    const typeRecovery = {
        [InterestPointType.address]: (
            <div className="display_flex flex_gap_xs flex_align_center m_b_xs">
                <Home className="dim_lg text_yellow_100" />
                <Typography scale="small" weight="600">
                    Hogar Ozoner
                </Typography>
            </div>
        ),
        [InterestPointType.hotspot]: (
            <div className="display_flex flex_gap_xs flex_align_center m_b_xs">
                <Hotspot className="dim_lg text_primary_300" />
                <Typography scale="small" weight="600">
                    Hotspot
                </Typography>
            </div>
        ),
        [InterestPointType.signalVehicle]: (
            <div className="display_flex flex_gap_xs flex_align_center m_b_xs">
                <Moto className="dim_lg text_green_300" />
                <Signal className="dim_lg text_green_300" />
                <Typography scale="small" weight="600">
                    Ultima ubicacion
                </Typography>
            </div>
        ),
        [InterestPointType.noSignalVehicle]: (
            <div className="display_flex flex_gap_xs flex_align_center m_b_xs">
                <Moto className="dim_lg text_red_300" />
                <NoSignal className="dim_lg text_red_300" />
                <Typography scale="small" weight="600">
                    Ultima ubicacion
                </Typography>
            </div>
        ),
    };

    const fetchData = () => {
        if (_id) {
            FetchRoute(_id).then((resp: Route) => {
                setRoute(resp);
            });

            FetchRecoveries(_id).then(
                (recoveriesData: FetchRecoveryResponse) => {
                    setRecoveries(recoveriesData.data);
                }
            );
        }
    };

    useEffect(() => {
        fetchData();
    }, [_id]);

    useEffect(() => {
        if (recoveries) {
            const calculateTotals: { [id in RecoveryStatus]: number } = {
                [RecoveryStatus.pending]: 0,
                [RecoveryStatus.onWay]: 0,
                [RecoveryStatus.inSite]: 0,
                [RecoveryStatus.recovered]: 0,
                [RecoveryStatus.failed]: 0,
                [RecoveryStatus.canceled]: 0,
            };

            recoveries.forEach((recovery) => {
                calculateTotals[recovery.status as any as RecoveryStatus] += 1;
            });
            setTotals(calculateTotals);
        }
    }, [recoveries]);

    const changeStatus = (result: any) => {
        if (recoveries) {
            const changedRecoveries = [...recoveries];
            const targetId = changedRecoveries.findIndex(
                (item) => item._id === result.draggableId
            );
            changedRecoveries[targetId].status = result.destination.droppableId;
            setRecoveries(changedRecoveries);
            editRecovery(result.draggableId, {
                status: result.destination.droppableId,
            })
                .then(() => {
                    fetchData();
                    toasterNotification({
                        msg: "Se cambio de estado la recuperacion",
                        toasterType: toasterOptions.success,
                        style: "light",
                    });
                })
                .catch(() => {
                    fetchData();
                    toasterNotification({
                        msg: "Ocurrio un error",
                        toasterType: toasterOptions.error,
                        style: "light",
                    });
                });
        }
    };

    const onDragEnd = (result: any) => {
        if (recoveries) {
            // dropped outside the list
            if (
                !result.destination ||
                result.destination.droppableId === result.source.droppableId
            ) {
                return;
            }
            if (result.destination.droppableId === RecoveryStatus.recovered) {
                setOpenConfirm(true);
                setConfirmData(result);
            } else {
                changeStatus(result);
            }
        }
    };

    const sendRuta = (ruta: Route) => {
        sendRouteSlack(ruta)
            .then(() => {
                toasterNotification({
                    msg: "Se enviaron los datos de la ruta",
                    toasterType: toasterOptions.success,
                    style: "light",
                });
            })
            .catch(() => {
                toasterNotification({
                    msg: "Ocurrio un error",
                    toasterType: toasterOptions.error,
                    style: "light",
                });
            });
    };

    return route && recoveries ? (
        <>
            <div className="dim_100_per flex_content_col shadow_hard inner_container bg_neutral_400">
                <div className="flex_header">
                    <div className="bg_neutral_0 shadow_hard p_y_lg p_x_xxxl">
                        <div className="display_flex flex_gap_md flex_align_center">
                            <Button
                                variant="icon"
                                icon={<Back />}
                                subvariant="edit"
                                scale="small"
                                onClick={() => navigate(-1)}
                            />
                            <Typography
                                scale="heading3"
                                weight="600"
                                textColor="primary_300"
                                className="text_capitalize"
                            >
                                {moment
                                    .utc(route.dateEstimate)
                                    .locale("es")
                                    .format("dddd, D MMMM")}
                            </Typography>
                            <Typography
                                scale="small"
                                weight="600"
                                textColor="neutral_900"
                                className="bg_neutral_300 br_md p_y_xxs p_x_xs"
                            >
                                {route.teamLeader.name}
                            </Typography>
                        </div>
                    </div>
                    <div className="p_x_xxxl">
                        <Grid container className="m_t_none" spacing={3}>
                            {Object.keys(RecoveryStatusValues)
                                .filter(
                                    (key) =>
                                        key !== RecoveryStatus.inSite &&
                                        key !== RecoveryStatus.canceled
                                )
                                .map((key, i) => (
                                    <Grid item xs={3} key={key}>
                                        <div className="flex_center flex_gap_sm">
                                            <div
                                                className={`br_circle dim_xs bg_${
                                                    RecoveryStatusValues[
                                                        key as RecoveryStatus
                                                    ].color
                                                }`}
                                            />
                                            <Typography
                                                scale="small"
                                                weight="400"
                                                textColor="neutral_600"
                                            >
                                                {
                                                    RecoveryStatusValues[
                                                        key as RecoveryStatus
                                                    ].title
                                                }{" "}
                                                (
                                                {totals?.[
                                                    key as RecoveryStatus
                                                ] ?? 0}
                                                )
                                            </Typography>
                                        </div>
                                    </Grid>
                                ))}
                        </Grid>
                    </div>
                </div>
                <div className="flex_body p_x_xxxl overflow_x_hidden p_t_md">
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Grid container className="h_100_per" spacing={5}>
                            {Object.keys(RecoveryStatusValues)
                                .filter(
                                    (key) =>
                                        key !== RecoveryStatus.inSite &&
                                        key !== RecoveryStatus.canceled
                                )
                                .map((key, i, arr) => (
                                    <Grid
                                        item
                                        xs={3}
                                        key={key}
                                        className={
                                            i < arr.length - 1
                                                ? "canal_drag"
                                                : ""
                                        }
                                    >
                                        <Droppable droppableId={key}>
                                            {(provided: any, snapshot: any) => (
                                                <div
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                    className="h_100_per"
                                                >
                                                    {recoveries
                                                        .filter(
                                                            (item) =>
                                                                (item.status as any) ===
                                                                key
                                                        )
                                                        .map((item, index) => (
                                                            <Draggable
                                                                key={item._id}
                                                                draggableId={
                                                                    item._id
                                                                }
                                                                index={index}
                                                            >
                                                                {(
                                                                    provided2: any,
                                                                    snapshot2: any
                                                                ) => (
                                                                    <div
                                                                        className={`shadow_hard br_sm m_b_sm ${
                                                                            snapshot2.isDragging
                                                                                ? "bg_primary_25"
                                                                                : "bg_neutral_0"
                                                                        }`}
                                                                        ref={
                                                                            provided2.innerRef
                                                                        }
                                                                        {...provided2.draggableProps}
                                                                        {...provided2.dragHandleProps}
                                                                        style={
                                                                            provided2
                                                                                .draggableProps
                                                                                .style
                                                                        }
                                                                    >
                                                                        <div className="p_md border_b_solid border_neutral_300 border_1">
                                                                            <div className="display_flex flex_gap_xs flex_align_center m_b_sm h_xxl">
                                                                                <User className="dim_lg" />
                                                                                <Typography
                                                                                    scale="small"
                                                                                    weight="600"
                                                                                    className="flex_1 "
                                                                                    textColor="neutral_900"
                                                                                >
                                                                                    {
                                                                                        item
                                                                                            .repossession
                                                                                            .ozonerName
                                                                                    }
                                                                                </Typography>
                                                                            </div>
                                                                            <div className="flex_center flex_gap_xs">
                                                                                <Typography
                                                                                    scale="small"
                                                                                    weight="600"
                                                                                    className=""
                                                                                    textColor="neutral_700"
                                                                                >
                                                                                    {
                                                                                        item
                                                                                            .repossession
                                                                                            ?.vehicle
                                                                                            ?.internalId
                                                                                    }
                                                                                </Typography>
                                                                                <Typography
                                                                                    scale="small"
                                                                                    weight="400"
                                                                                    className=""
                                                                                    textColor="neutral_700"
                                                                                >
                                                                                    {item
                                                                                        .repossession
                                                                                        ?.vehicle
                                                                                        ?.brand
                                                                                        ?.name ||
                                                                                        "-"}{" "}
                                                                                    {item
                                                                                        .repossession
                                                                                        ?.vehicle
                                                                                        ?.model
                                                                                        ?.name ||
                                                                                        "-"}{" "}
                                                                                    {item
                                                                                        .repossession
                                                                                        ?.vehicle
                                                                                        ?.cylindersCapacity||
                                                                                        "-"}
                                                                                </Typography>
                                                                            </div>
                                                                        </div>
                                                                        <div className="p_md">
                                                                            {
                                                                                typeRecovery[
                                                                                    item
                                                                                        .interestPoint
                                                                                        .type
                                                                                ]
                                                                            }
                                                                            <div
                                                                                className={`br_sm ${
                                                                                    snapshot2.isDragging
                                                                                        ? "bg_neutral_0"
                                                                                        : "bg_neutral_200"
                                                                                }`}
                                                                            >
                                                                                <div className="border_b_solid border_1 border_neutral_300 p_y_xs">
                                                                                    <Typography
                                                                                        scale="small"
                                                                                        weight="600"
                                                                                        textColor="red_300"
                                                                                        className="text_no_break text_center"
                                                                                    >
                                                                                        {
                                                                                            item
                                                                                                .repossession
                                                                                                .latePayments
                                                                                        }{" "}
                                                                                        cuotas
                                                                                        <Typography
                                                                                            scale="small"
                                                                                            weight="400"
                                                                                            textColor="neutral_900"
                                                                                            className="text_no_break text_center"
                                                                                            component="span"
                                                                                        >
                                                                                            {" "}
                                                                                            en
                                                                                            mora
                                                                                        </Typography>
                                                                                    </Typography>
                                                                                </div>
                                                                                <div className="border_b_solid border_1 border_neutral_300 p_y_xs">
                                                                                    <Typography
                                                                                        scale="small"
                                                                                        weight="600"
                                                                                        textColor="green_300"
                                                                                        className="text_no_break text_center"
                                                                                    >
                                                                                        {Math.round(
                                                                                            (item
                                                                                                .repossession
                                                                                                .totalDebt /
                                                                                                item
                                                                                                    .repossession
                                                                                                    .totalCredit) *
                                                                                                100
                                                                                        )}

                                                                                        %
                                                                                        <Typography
                                                                                            scale="small"
                                                                                            weight="400"
                                                                                            textColor="neutral_900"
                                                                                            className="text_no_break text_center"
                                                                                            component="span"
                                                                                        >
                                                                                            {" "}
                                                                                            Cuotas
                                                                                            pagadas
                                                                                        </Typography>
                                                                                    </Typography>
                                                                                </div>
                                                                                <div className="border_b_solid border_1 border_neutral_300 p_y_xs">
                                                                                    <Typography
                                                                                        scale="small"
                                                                                        weight="600"
                                                                                        className="text_no_break text_center"
                                                                                    >
                                                                                        {priceFormatFinancial(
                                                                                            item
                                                                                                .repossession
                                                                                                .currentDebt,
                                                                                            0
                                                                                        )}
                                                                                        <Typography
                                                                                            scale="small"
                                                                                            weight="400"
                                                                                            textColor="neutral_900"
                                                                                            className="text_no_break text_center"
                                                                                            component="span"
                                                                                        >
                                                                                            {" "}
                                                                                            para
                                                                                            regularizar
                                                                                        </Typography>
                                                                                    </Typography>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        ))}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </Grid>
                                ))}
                        </Grid>
                    </DragDropContext>
                </div>

                <div className="display_flex flex_align_center flex_justify_end flex_footer bg_neutral_0 p_x_xxxl p_y_md">
                    <Button
                        scale="small"
                        className="w_30_per"
                        onClick={() => sendRuta(route)}
                    >
                        Enviar por Slack
                    </Button>
                </div>
            </div>
            <ModalConfirmation
                handleClose={() => setOpenConfirm(false)}
                openConfirmation={openConfirm}
                onConfirmationCallBack={() => {changeStatus(confirmData);setOpenConfirm(false);}}
                title={
                    <Typography
                        scale="large"
                        weight="600"
                        textColor="neutral_900"
                    >
                        ¿Moto Recuperada?
                    </Typography>
                }
                subtitle={
                    <Typography
                        scale="medium"
                        weight="400"
                        textColor="neutral_900"
                    >
                        Al cambiar a este estado todos los pagos pendientes del
                        crédito quedaran anulados esta seguro que quiere hacer
                        el cambio
                    </Typography>
                }
                copyReject="Cancelar"
                copyConfirm="Cambiar"
            />
        </>
    ) : null;
};
