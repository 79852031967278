import axios, { AxiosRequestConfig, AxiosResponse } from "axios";


export const fetchByPostalCode = async (cp: string) => {

  const config = axios.create({
    baseURL: process.env.REACT_APP_COPOMEX_BASE_URL,
    headers: {
      "Content-Type": "application/json",
    },
  });

  return config.get(`info_cp/${cp}?type=simplified&token=${process.env.REACT_APP_COPOMEX_API_KEY}`);
};