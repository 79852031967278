/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useContext } from "react";

// -----------------LIBRARIES IMPORTS------------------- //
// @ts-ignore
import {
    Button,
    Input,
    Knob,
    ModalConfirmation as Modal,
    ModalConfirmation,
    SideModal,
    Typography,
} from "design_system/src";
import { Grid } from "@mui/material";
import * as yup from "yup";

// -----------------DATA IMPORTS------------------- //
import { priceFormatFinancial, priceMXNTotal } from "helpers/prices/prices";
import { TDataozoner } from "models/ozoner.interface";
import { editOzoner } from "helpers/fetchs/ozoners";
import {
    FormStatusValues,
    TDataFinancialForm,
    devolutionReasonEnum,
    devolutionReasonValues,
    formStatusEnum,
    rejectedReasonEnum,
    rejectedReasonValues,
} from "models/financialForm.interface";
import moment from "moment";

import { handleEventGoogle } from "helpers/analytics/googleAnalytics";

// -----------------STATIC IMPORTS------------------- //
import "../../../ozoner.scss";
import { ReactComponent as Edit } from "design_system/src/static/icons/edit-text.svg";
import { ReactComponent as Check } from "design_system/src/static/icons/checkmark.svg";
import { ReactComponent as Eye } from "design_system/src/static/icons/eye.svg";
import { ReactComponent as Reload } from "design_system/src/static/icons/reload.svg";
import { ReactComponent as Close } from "design_system/src/static/icons/close-lg.svg";
import { ReactComponent as Money } from "design_system/src/static/icons/money.svg";
import { ReactComponent as Up } from "design_system/src/static/icons/success-plan.svg";
import { ReactComponent as AddFiles } from "design_system/src/static/icons/upload-bracket.svg";
import { ReactComponent as Down } from "design_system/src/static/icons/failed-plan.svg";
import { ReactComponent as Fail } from "design_system/src/static/icons/close-circle.svg";
import { ReactComponent as Success } from "design_system/src/static/icons/tick-circle.svg";
import { ReactComponent as Info } from "design_system/src/static/icons/info.svg";
import { ReactComponent as ArrowUp } from "design_system/src/static/icons/up-md.svg";
import { ReactComponent as ArrowDown } from "design_system/src/static/icons/down-md.svg";

import CurrencyInput from "components/hocs/buttonsInputField/CurrencyInput";

// -----------------COMPONENTS IMPORTS------------------- //
import { statusVehicles } from "models/vehicle.interface";
import Dropzone from "react-dropzone";
import Swal from "sweetalert2";
import Rejected from "views/ozoner/details/modals/Rejected";
import { toasterNotification } from "helpers/globalNotifications/globalNotifications";
import { CreditStatus } from "models/credit.interface";
import { toasterOptions } from "enums/toaster.enum";
import {
    addFileForm,
    deleteFileForm,
    editSolicitud,
} from "helpers/fetchs/solicitudes";
import { UpdateCreditDeposit } from "helpers/fetchs/credits";
import { Roles } from "models/ozonio.interface";
import { RequireRole } from "components/hocs/sidebar/Sidebar";
import { DocumentTypesEnum } from "components/hocs/loadDocuments/loadDocuments";
import { LightTooltip } from "components/hocs/tooltip/Tooltip";
import { ozonerContext } from "../../OzonerContext";
import { EditTypesOzoner, displayQuota } from "../../DetailsOzoner";
import Devolution from "../../modals/Devolution";
import { VehicleSection, calculateQuota } from "./VehicleSection";
import {
    SolicitudAdvanced,
    SolicitudApartado,
    SolicitudDeposit,
} from "./SolicitudNumberInputs";
import { DevolutionAction, ScheduleAction, UpdateQuota } from "./SolicitudActions";

const validationItem = (
    valid: boolean,
    validLabel: string,
    invalidLabel: string
) => (
    <>
        {valid ? (
            <Success className="dim_lg text_green_300" />
        ) : (
            <Fail className="dim_lg text_red_300" />
        )}
        <Typography
            scale="xsmall"
            weight="400"
            textColor={valid ? "green_300" : "red_300"}
            className="text_no_break"
        >
            {valid ? validLabel : invalidLabel}
        </Typography>
    </>
);

function ApprovedSection() {
    const { financialForm, vehicle } = useContext(ozonerContext);

    const [hasCreditTime, setHasCreditTime] = useState(false);
    const [hasAdvancedPayment, setHasAdvancedPayment] = useState(false);
    const [hasRecipt, setHasRecipt] = useState(false);
    const [correctQuota, setCorrectQuota] = useState(false);

    const [canSchedule, setCanSchedule] = useState(false);

    useEffect(() => {
        const quota = calculateQuota(vehicle, financialForm);

        setCorrectQuota(
            quota.paymentWeek <= (financialForm?.form?.maxWeekQuota ?? 0)
        );

        setHasAdvancedPayment(false);
        setHasRecipt(false);
        setHasCreditTime(!!financialForm?.form?.creditTime);

        financialForm?.form?.documents?.forEach((doc: any) => {
            if (doc.typeEvidence === DocumentTypesEnum.layawayReceipt) {
                setHasRecipt(true);
            }
            if (doc.typeEvidence === DocumentTypesEnum.advancedmoney) {
                setHasAdvancedPayment(true);
            }
        });
    }, [financialForm, vehicle]);

    useEffect(() => {
        setCanSchedule(
            vehicle &&
                hasCreditTime &&
                correctQuota &&
                financialForm?.form?.deposit !== undefined &&
                hasRecipt &&
                (!financialForm?.form?.advancedMoney || hasAdvancedPayment)
        );
    }, [
        hasCreditTime,
        hasAdvancedPayment,
        hasRecipt,
        correctQuota,
        financialForm,
        vehicle,
    ]);

    return (
        <>
            <RequireRole authRoles={[Roles.ADMIN, Roles.CREDIT_ANALYST]}>
                <div
                    id="validate-soli-ozoner"
                    className="p_md border_b_solid border_1 border_neutral_400 display_flex flex_col flex_gap_md"
                >
                    <UpdateQuota />
                </div>
            </RequireRole>
            <VehicleSection />
            <SolicitudDeposit />
            <SolicitudAdvanced />
            {vehicle && <SolicitudApartado />}
            <div
                id="validate-soli-ozoner"
                className="p_md border_b_solid border_1 border_neutral_400 display_flex flex_col flex_gap_md"
            >
                <div className="display_flex flex_gap_xs flex_align_center">
                    {validationItem(
                        vehicle,
                        "Moto asignada",
                        "Moto sin asignar"
                    )}
                </div>
                <div className="display_flex flex_gap_xs flex_align_center">
                    {validationItem(
                        hasCreditTime,
                        "Plan de pagos seleccionado",
                        "Plan de pagos sin asignar"
                    )}
                </div>
                <div className="display_flex flex_gap_xs flex_align_center">
                    {validationItem(
                        correctQuota,
                        "Valor de cuota apropiada",
                        "Cuota demasiado alta"
                    )}
                </div>
                <div className="display_flex flex_gap_xs flex_align_center">
                    {validationItem(
                        financialForm?.form?.deposit !== undefined,
                        "Valor de depósito asignado",
                        "Valor de depósito sin asignar"
                    )}
                </div>
                {financialForm?.form?.advancedMoney !== undefined &&
                    financialForm.form.advancedMoney > 0 && (
                        <div className="display_flex flex_gap_xs flex_align_center">
                            {validationItem(
                                hasAdvancedPayment,
                                "Comprobante pago adelantado adjunto",
                                "Comprobante pago adelantado sin subir"
                            )}
                        </div>
                    )}
                <div className="display_flex flex_gap_xs flex_align_center">
                    {validationItem(
                        hasRecipt,
                        "Comprobante de Apartado adjunto",
                        "Comprobante de Apartado sin subir"
                    )}
                </div>
                <ScheduleAction disable={!canSchedule} />
                <DevolutionAction />
            </div>
        </>
    );
}
export default ApprovedSection;
