/* eslint-disable no-plusplus */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from "react";
import { Typography } from "design_system/src";
import { InterestPointType, RepossessionPolicy, RepossessionPolicyValues } from "models/repossession.interface";
import ReactDOM from "react-dom";
import { ReactComponent as Signal } from "design_system/src/static/icons/radio-signal.svg";
import { ReactComponent as NoSignal } from "design_system/src/static/icons/no-radio-signal.svg";
import { ReactComponent as Moto } from "design_system/src/static/icons/moto-sport.svg";
import { ReactComponent as Home } from "design_system/src/static/icons/temple.svg";
import { ReactComponent as Hotspot } from "design_system/src/static/icons/fire-camp.svg";
// eslint-disable-next-line import/no-duplicates
import { ReactComponent as MotoTracker } from "static/icons/map/moto-tracker.svg";
import home from "static/icons/map/home.svg";
import hotspot from "static/icons/map/hotspot.svg";
import motoSignal from "static/icons/map/moto-signal.svg";
import motoNoSignal from "static/icons/map/moto-no-signal.svg";
// eslint-disable-next-line import/no-duplicates
import motoTracker from "static/icons/map/moto-tracker.svg";

const markerIcons = {
  [InterestPointType.signalVehicle]: (
    <div className="display_flex flex_align_center flex_gap_xxs">
      <Moto className="dim_lg text_green_300" />
      <Signal className="dim_sm text_green_300" />
    </div>
  ),
  [InterestPointType.noSignalVehicle]: (
    <div className="display_flex flex_align_center flex_gap_xxs">
      <Moto className="dim_lg text_red_300" />
      <NoSignal className="dim_sm text_red_300" />
    </div>
  ),
  [InterestPointType.address]: (
    <div className="flex_center">
      <Home className="dim_md text_neutral_800" />
    </div>
  ),
  [InterestPointType.hotspot]: (
    <div className="flex_center">
      <Hotspot className="dim_md text_primary_300" />
    </div>
  ),
};

const Marker = ({ onClick, feature }: { onClick: any, feature: any }) => {
  const _onClick = () => {
    onClick();
  };

  // return <div className="dim_xl bg_primary_300">hola</div>;
  // eslint-disable-next-line no-unreachable
  return (
      <div
          className="flex_center_col p_xs br_xxs shadow_soft bg_neutral_100 main_marker cursor_pointer"
          onClick={_onClick}
          tabIndex={0}
          role="button"
          onKeyDown={_onClick}
      >
          <Typography scale="xsmall" weight="600">
              {feature.properties.title}
          </Typography>
          {feature.properties.policy && (
              <Typography
                  scale="xxsmall"
                  weight="600"
                  className="m_b_xs"
                  textColor={
                      (RepossessionPolicyValues as any)[
                          feature.properties.policy
                      ]?.color
                  }
              >
                  {(RepossessionPolicyValues as any)[feature.properties.policy]
                      ?.title ?? feature.properties.policy}
              </Typography>
          )}
          {feature.properties.isTrackGPS ? (
              <MotoTracker />
          ) : (
              (markerIcons as any)[feature.properties.type]
          )}
          <span className="arrow" />
      </div>
  );
};
export const createMarker = (feature: any, onClick: any) => {
  const el = document.createElement("div");
  ReactDOM.render(
    <Marker
      onClick={onClick}
      feature={feature}
    />,
    el
  );
  return el;
};
const donutSegment = (start: any, end: any, r: any, r0: any, color: any) => {
  // eslint-disable-next-line no-param-reassign
  if (end - start === 1) end -= 0.00001;
  const a0 = 2 * Math.PI * (start - 0.25);
  const a1 = 2 * Math.PI * (end - 0.25);
  const x0 = Math.cos(a0);
  const y0 = Math.sin(a0);
  const x1 = Math.cos(a1);
  const y1 = Math.sin(a1);
  const largeArc = end - start > 0.5 ? 1 : 0;

  // draw an SVG path
  return `<path d="M ${r + r0 * x0} ${r + r0 * y0} L ${r + r * x0} ${r + r * y0
    } A ${r} ${r} 0 ${largeArc} 1 ${r + r * x1} ${r + r * y1} L ${r + r0 * x1
    } ${r + r0 * y1} A ${r0} ${r0} 0 ${largeArc} 0 ${r + r0 * x0} ${r + r0 * y0
    }" fill="${color}" />`;
};
const drawCounter = (x: number, y: number, icon: any, text: number, size: number) => {
  return `<image x="${x - (size / 2)}" y="${y - (20 * (20 / size))}" width="${size}" xlink:href="${icon}" />
              <text dominant-baseline="central" transform="translate(${x}, ${y + 10})">
                <tspan font-weight="bold">
                  ${text}
                </tspan>
              </text>`;
};

const colors = [
  "#fe8a02",
  "#0ac257",
  "#1f56b5",
  "#805628",
  "#df2040",
  "#232323"
];
const locationcolors = ["#0ac257", "#df2040", "#67737E"];
export const createCluster = (props: any, isLocation: any, onClick: any) => {
  const offsets: any[] = [];
  let countsEvents = [];
  if(isLocation){

    countsEvents = [props.active, props.inactive, props.gpsTracker];
  }else{
    countsEvents = [
      props[RepossessionPolicy.priority],
      props[RepossessionPolicy.event1],
      props[RepossessionPolicy.event2],
      props[RepossessionPolicy.event3],
      props[RepossessionPolicy.preWriteOff],
      props[RepossessionPolicy.writeOff],
    ];
  }
  let totalEvents = 0;
  countsEvents.forEach(count => {
    offsets.push(totalEvents);
    totalEvents += count;
  });
  const countsMarkers = [
      { count: props.active, icon: motoSignal, size: 30 },
      { count: props.inactive, icon: motoNoSignal, size: 30 },
      { count: props.hotspot, icon: hotspot, size: 20 },
      { count: props.address, icon: home, size: 20 },
      { count: props.gpsTracker, icon: motoTracker, size: 30 },
  ];
  const typesAvailable = countsMarkers.filter(count => !!count.count);
  const maxType = countsMarkers.reduce((acc, count) => Math.max(acc, count.count), 0);
  const fontSize = maxType >= 1000 ? 10 : maxType >= 100 ? 12 : maxType >= 10 ? 14 : 16;
  const r = 61 / (typesAvailable.length > 1 ? 1 : 2);
  const r0 = Math.round(r * 0.7);
  const w = r * 2;
  const side = r0 * 0.6 / (Math.sqrt(2));
  let html = "<div class=\"flex_center\">";

  html += `<svg width="${w}" height="${w}" viewbox="0 0 ${w} ${w}" text-anchor="middle" style="font: ${fontSize}px sans-serif; display: block">`;
  for (let i = 0; i < countsEvents.length; i++) {
    html += donutSegment(
      offsets[i] / totalEvents,
      (offsets[i] + countsEvents[i]) / totalEvents,
      r,
      r0,
      (isLocation?locationcolors:colors)[i]
    );
  }
  html += `<circle cx="${r}" cy="${r}" r="${r0}" fill="white" />`;
  if (typesAvailable.length === 1) {
    const type = typesAvailable[0];
    html += drawCounter(r, r, type.icon, type.count, type.size);
  }
  else {
    typesAvailable.forEach((type, i) => {
      const x = r + ((i % 2 ? 1 : -1) * side);
      const y = typesAvailable.length > 2 ? (i > 1 ? r + side : r - side) : (r);

      html += drawCounter(x, y, type.icon, type.count, type.size);
    });
  }
  html += `<defs>
<clipPath id="clip0_151_11413">
<rect width="6.75" height="6.75" fill="white" transform="translate(37.0869 20.6382)"/>
</clipPath>
</defs>
            </svg>`;
  html += "</div>";

  const el = document.createElement("div");
  el.innerHTML = html;
  const cont = document.createElement("div");
  ReactDOM.render(
    <div
    onClick={onClick}
      tabIndex={0} role="button"
      onKeyDown={onClick}
      aria-label="group"
      className="cursor_pointer"
      />,
    cont,
    ()=>{
      cont.firstChild?.appendChild(el);
    }
    );
  return cont;
};
