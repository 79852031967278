// eslint-disable-next-line no-shadow
export enum CreditExportFields {
  activatedAt = "activatedAt",
  endedAtEstimated = "endedAtEstimated",
  firstInvoiceDate = "firstInvoiceDate",
  endedAt = "endedAt",
  porcobrarIdCustomer = "porcobrarIdCustomer",
  porcobrarIdContract = "porcobrarIdContract",
  total = "total",
  currency = "currency",
  totalPaid = "totalPaid",
  debtCompletedFor = "debtCompletedFor",
  completedAt = "completedAt",
  canceledAt = "canceledAt",
  currencyRate = "currencyRate",
  status = "status",
  code = "code",
  codeOzoner = "codeOzoner",
  duration = "duration",
  compareWithPB = "compareWithPB",
  payments = "payments",
  discountId = "discountId",
  creditClassification = "creditClassification",
  historicClassification = "historicClassification",
  delayedTimes = "delayedTimes",
  deposit = "deposit",
  depositAddedBy = "depositAddedBy",
  paidCreditAmount = "paidCreditAmount",
  paidCreditWithInterest = "paidCreditWithInterest",
  contractTotal = "contractTotal",
  financialFormId = "financialFormId",
  documents = "documents",
  statusHistoric = "statusHistoric",
  collectionResponsible = "collectionResponsible",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  latePayments = "latePayments",
  ozoner = "ozoner",
  "ozoner.name" = "ozoner.name",
  "ozoner.phone" = "ozoner.phone",
  "ozoner.email" = "ozoner.email",
  "ozoner.address" = "ozoner.address",
  "ozoner.fullAddress" = "ozoner.fullAddress",
  "ozoner.geolocations" = "ozoner.geolocations",
  "ozoner.city" = "ozoner.city",
  "ozoner.curp" = "ozoner.curp",
  "ozoner.palencaVerification" = "ozoner.palencaVerification",
  "ozoner.truoraVerification" = "ozoner.truoraVerification",
  "ozoner.status" = "ozoner.status",
  "ozoner.image" = "ozoner.image",
  "ozoner.createdAt" = "ozoner.createdAt",
  "ozoner.statusHistoric" = "ozoner.statusHistoric",
  "ozoner.documents" = "ozoner.documents",
  "ozoner.comment" = "ozoner.comment",
  "ozoner.createdOn" = "ozoner.createdOn",
  "ozoner.truoraId" = "ozoner.truoraId",
  "ozoner.palencaId" = "ozoner.palencaId",
  "ozoner.truoraData" = "ozoner.truoraData",
  "ozoner.palencaData" = "ozoner.palencaData",
  "ozoner.internalId" = "ozoner.internalId",
  "ozoner.score" = "ozoner.score",
  "ozoner.social_network" = "ozoner.social_network",
  "ozoner.lastChangeBy" = "ozoner.lastChangeBy",
  "ozoner.updatedAt" = "ozoner.updatedAt",
  vehicle = "vehicle",
  "vehicle.brand" = "vehicle.brand",
  "vehicle.model" = "vehicle.model",
  "vehicle.cylindersCapacity" = "vehicle.cylindersCapacity",
  "vehicle.suffix" = "vehicle.suffix",
  "vehicle.purchaseDate" = "vehicle.purchaseDate",
  "vehicle.color" = "vehicle.color",
  "vehicle.secondaryColor" = "vehicle.secondaryColor",
  "vehicle.condition" = "vehicle.condition",
  "vehicle.purchaseCost" = "vehicle.purchaseCost",
  "vehicle.rentPrice" = "vehicle.rentPrice",
  "vehicle.tankSize" = "vehicle.tankSize",
  "vehicle.plate" = "vehicle.plate",
  "vehicle.brakeType" = "vehicle.brakeType",
  "vehicle.brakesCondition" = "vehicle.brakesCondition",
  "vehicle.engineSN" = "vehicle.engineSN",
  "vehicle.registrationCard" = "vehicle.registrationCard",
  "vehicle.vehicleSN" = "vehicle.vehicleSN",
  "vehicle.maintenanceCost" = "vehicle.maintenanceCost",
  vehicleImages = "vehicle.images",
  "vehicle.internalId" = "vehicle.internalId",
  "vehicle.externalId" = "vehicle.externalId",
  "vehicle.oldPrice" = "vehicle.oldPrice",
  "vehicle.discounts" = "vehicle.discounts",
  "vehicle.insurancePrice" = "vehicle.insurancePrice",
  "vehicle.score" = "vehicle.score",
  "vehicle.details" = "vehicle.details",
  "vehicle.type" = "vehicle.type",
  "vehicle.status" = "vehicle.status",
  "vehicle.maintenanceColor" = "vehicle.maintenanceColor",
  "vehicle.maintenanceDays" = "vehicle.maintenanceDays",
  "vehicle.gpsStatus" = "vehicle.gpsStatus",
  "vehicle.statusHistoric" = "vehicle.statusHistoric",
  "vehicle.insurance" = "vehicle.insurance",
  "vehicle.deposit" = "vehicle.deposit",
  "vehicle.depositPrice" = "vehicle.depositPrice",
  "vehicle.salePrices" = "vehicle.salePrices",
  "vehicle.extraCost" = "vehicle.extraCost",
  "vehicle.totalCost" = "vehicle.totalCost",
  "vehicle.hub" = "vehicle.hub",
  "vehicle.city" = "vehicle.city",
  "vehicle.country" = "vehicle.country",
  "vehicle.documents" = "vehicle.documents",
  "vehicle.warranty" = "vehicle.warranty",
  "vehicle.description" = "vehicle.description",
  "vehicle.detail" = "vehicle.detail",
  "vehicle.factorKM" = "vehicle.factorKM",
  "vehicle.confirmationKM" = "vehicle.confirmationKM",
  "vehicle.blueBookPrice" = "vehicle.blueBookPrice",
  "vehicle.avgKM" = "vehicle.avgKM",
  "vehicle.trackerGPSId" = "vehicle.trackerGPSId",
  "vehicle.lastChangeBy" = "vehicle.lastChangeBy",
  "vehicle.createdAt" = "vehicle.createdAt",
  "vehicle.updatedAt" = "vehicle.updatedAt",

  "ozoner.financialForm" = "ozoner.financialForm",
  "ozoner.financialForm.userInfoStep" = "ozoner.financialForm.userInfoStep",
  "ozoner.financialForm.monthlyIncome" = "ozoner.financialForm.monthlyIncome",
  "ozoner.financialForm.monthlyIncomeVerified" = "ozoner.financialForm.monthlyIncomeVerified",
  "ozoner.financialForm.monthlySpending" = "ozoner.financialForm.monthlySpending",
  "ozoner.financialForm.monthlySpendingVerified" = "ozoner.financialForm.monthlySpendingVerified",
  "ozoner.financialForm.childrenCount" = "ozoner.financialForm.childrenCount",
  "ozoner.financialForm.dependantsCount" = "ozoner.financialForm.dependantsCount",
  "ozoner.financialForm.companyName" = "ozoner.financialForm.companyName",
  "ozoner.financialForm.economicActivity" = "ozoner.financialForm.economicActivity",
  "ozoner.financialForm.group" = "ozoner.financialForm.group",
  "ozoner.financialForm.civilStatus" = "ozoner.financialForm.civilStatus",
  "ozoner.financialForm.livesWith" = "ozoner.financialForm.livesWith",
  "ozoner.financialForm.authDataTreatment" = "ozoner.financialForm.authDataTreatment",
  "ozoner.financialForm.platforms" = "ozoner.financialForm.platforms",
  "ozoner.financialForm.workInDigitalPlatforms" = "ozoner.financialForm.workInDigitalPlatforms",
  "ozoner.financialForm._id" = "ozoner.financialForm._id",
  "ozoner.financialForm.vehicleId" = "ozoner.financialForm.vehicleId",
  "ozoner.financialForm.approved" = "ozoner.financialForm.approved",
  "ozoner.financialForm.score" = "ozoner.financialForm.score",
  "ozoner.financialForm.dti" = "ozoner.financialForm.dti",
  "ozoner.financialForm.createdAt" = "ozoner.financialForm.createdAt",
  "ozoner.financialForm.updatedAt" = "ozoner.financialForm.updatedAt",
  "ozoner.financialForm.status" = "ozoner.financialForm.status",
  "ozoner.financialForm.statusHistoric" = "ozoner.financialForm.statusHistoric",
  "ozoner.financialForm.weekFlow" = "ozoner.financialForm.weekFlow",
  "ozoner.financialForm.weeklyIncome" = "ozoner.financialForm.weeklyIncome",
  "ozoner.financialForm.weeklyOutcome" = "ozoner.financialForm.weeklyOutcome",
  "ozoner.financialForm.afterPayIncome" = "ozoner.financialForm.afterPayIncome",
  "ozoner.financialForm.maxWeekQuota" = "ozoner.financialForm.maxWeekQuota",
  "ozoner.financialForm.creditTime" = "ozoner.financialForm.creditTime",
  "ozoner.financialForm.advancedMoney" = "ozoner.financialForm.advancedMoney",
  "ozoner.financialForm.educationalLevel" = "ozoner.financialForm.educationalLevel",
  "ozoner.financialForm.assets" = "ozoner.financialForm.assets",
  "ozoner.financialForm.rejectedReason" = "ozoner.financialForm.rejectedReason",
  "ozoner.financialForm.returnPendingReason" = "ozoner.financialForm.returnPendingReason",
  "ozoner.financialForm.percentageMaxQuota" = "ozoner.financialForm.percentageMaxQuota",
  "ozoner.financialForm.partnerShip" = "ozoner.financialForm.partnerShip",
  "ozoner.financialForm.personal_references" = "ozoner.financialForm.personal_references",
  "ozoner.financialForm.vehicleAvailable" = "ozoner.financialForm.vehicleAvailable",
  "ozoner.financialForm.reached" = "ozoner.financialForm.reached",
  "ozoner.financialForm.documents" = "ozoner.financialForm.documents",
  "ozoner.financialForm.sellResponsible" = "ozoner.financialForm.sellResponsible",
  "ozoner.financialForm.discountId" = "ozoner.financialForm.discountId",
}


export const CreditExportFieldsNames: { [key in CreditExportFields]: string } = {
  [CreditExportFields.activatedAt]: "Fecha activacion",
  [CreditExportFields.endedAtEstimated]: "Fecha estimada de fin",
  [CreditExportFields.firstInvoiceDate]: "Fecha de primera factura",
  [CreditExportFields.endedAt]: "Fecha dr finalizacion",
  [CreditExportFields.porcobrarIdCustomer]: "Id Comprador",
  [CreditExportFields.porcobrarIdContract]: "Id contrato",
  [CreditExportFields.total]: "Cuota",
  [CreditExportFields.currency]: "Moneda",
  [CreditExportFields.totalPaid]: "Pago hasta hoy",
  [CreditExportFields.debtCompletedFor]: "",
  [CreditExportFields.completedAt]: "Fecha completado",
  [CreditExportFields.canceledAt]: "Fecha de cancelacion",
  [CreditExportFields.currencyRate]: "Tasa",
  [CreditExportFields.status]: "Estado",
  [CreditExportFields.code]: "Codigo",
  [CreditExportFields.codeOzoner]: "Codigo Ozoner",
  [CreditExportFields.duration]: "Duracion",
  [CreditExportFields.compareWithPB]: "",
  [CreditExportFields.payments]: "Pagos",
  [CreditExportFields.discountId]: "Id descuento",
  [CreditExportFields.creditClassification]: "Clasificacion de crédito",
  [CreditExportFields.historicClassification]: "Historico de clasificaciones",
  [CreditExportFields.delayedTimes]: "# de veces atrasado",
  [CreditExportFields.deposit]: "Deposito",
  [CreditExportFields.depositAddedBy]: "Ozonio que añadio el deposito",
  [CreditExportFields.paidCreditAmount]: "Capital pagado",
  [CreditExportFields.paidCreditWithInterest]: "Intereses Pagados",
  [CreditExportFields.contractTotal]: "Valor total del contrato",
  [CreditExportFields.financialFormId]: "Id Informacion financiera",
  [CreditExportFields.documents]: "Documentos",
  [CreditExportFields.statusHistoric]: "Historico de estados",
  [CreditExportFields.collectionResponsible]: "Responsable de cobranza",
  [CreditExportFields.createdAt]: "Fecha de creacion",
  [CreditExportFields.updatedAt]: "Fecha de actualizacion",
  [CreditExportFields.latePayments]: "Cuotas vencidas",
  [CreditExportFields.ozoner]: "Ozoner",
  [CreditExportFields["ozoner.name"]]: "Nombre ozoner",
  [CreditExportFields["ozoner.phone"]]: "Telefono ozoner",
  [CreditExportFields["ozoner.email"]]: "Email ozoner",
  [CreditExportFields["ozoner.address"]]: "Direccion antigua ozoner",
  [CreditExportFields["ozoner.fullAddress"]]: "Direccion completa ozoner",
  [CreditExportFields["ozoner.financialForm"]]: "Solicitudes del ozoner",
  [CreditExportFields["ozoner.geolocations"]]: "Ubicaciones logeo ozoner",
  [CreditExportFields["ozoner.city"]]: "Ciudad ozoner",
  [CreditExportFields["ozoner.curp"]]: "Curp ozoner",
  [CreditExportFields["ozoner.palencaVerification"]]: "Verificacion palenca ozoner",
  [CreditExportFields["ozoner.truoraVerification"]]: "Verificacion truora ozoner",
  [CreditExportFields["ozoner.status"]]: "Estado ozoner",
  [CreditExportFields["ozoner.image"]]: "Foto ozoner",
  [CreditExportFields["ozoner.createdAt"]]: "Fecha creacion ozoner",
  [CreditExportFields["ozoner.statusHistoric"]]: "Historico de estados ozoner",
  [CreditExportFields["ozoner.documents"]]: "Documentos ozoner",
  [CreditExportFields["ozoner.comment"]]: "Comentarios ozoner",
  [CreditExportFields["ozoner.createdOn"]]: "Plataforma de creacion ozoner",
  [CreditExportFields["ozoner.truoraId"]]: "Id truora ozoner",
  [CreditExportFields["ozoner.palencaId"]]: "Id palenca ozoner",
  [CreditExportFields["ozoner.truoraData"]]: "Datos truora ozoner",
  [CreditExportFields["ozoner.palencaData"]]: "Datos palenca ozoner",
  [CreditExportFields["ozoner.internalId"]]: "Id ozoner",
  [CreditExportFields["ozoner.score"]]: "Calificacion ozoner",
  [CreditExportFields["ozoner.social_network"]]: "Red social ozoner",
  [CreditExportFields["ozoner.lastChangeBy"]]: "Ozonio ultima modificacion ozoner",
  [CreditExportFields["ozoner.updatedAt"]]: "Fecha actualizacion ozoner",
  [CreditExportFields.vehicle]: "Vehiculo",
  [CreditExportFields["vehicle.brand"]]: "Marca vehiculo",
  [CreditExportFields["vehicle.model"]]: "Modelo  vehiculo",
  [CreditExportFields["vehicle.cylindersCapacity"]]: "Cilindraje vehiculo",
  [CreditExportFields["vehicle.suffix"]]: "Sufijo vehiculo",
  [CreditExportFields["vehicle.purchaseDate"]]: "Fecha compra vehiculo",
  [CreditExportFields["vehicle.color"]]: "Color vehiculo",
  [CreditExportFields["vehicle.secondaryColor"]]: "Color secundario vehiculo",
  [CreditExportFields["vehicle.condition"]]: "Condicion vehiculo",
  [CreditExportFields["vehicle.purchaseCost"]]: "Precio compra vehiculo",
  [CreditExportFields["vehicle.rentPrice"]]: "Precio renta vehiculo",
  [CreditExportFields["vehicle.tankSize"]]: "Tamaño del tanque vehiculo",
  [CreditExportFields["vehicle.plate"]]: "Placa vehiculo",
  [CreditExportFields["vehicle.brakeType"]]: "Tipo de frenos vehiculo",
  [CreditExportFields["vehicle.brakesCondition"]]: "Condiciones frenos vehiculo",
  [CreditExportFields["vehicle.engineSN"]]: "Serial del motor vehiculo",
  [CreditExportFields["vehicle.registrationCard"]]: "Tarjeta de registro vehiculo",
  [CreditExportFields["vehicle.vehicleSN"]]: "Serial vehiculo",
  [CreditExportFields["vehicle.maintenanceCost"]]: "Costo mantenimiento vehiculo",
  [CreditExportFields.vehicleImages]: "Imagenes vehiculo",
  [CreditExportFields["vehicle.internalId"]]: "Id vehiculo",
  [CreditExportFields["vehicle.externalId"]]: "Id externo vehiculo",
  [CreditExportFields["vehicle.oldPrice"]]: "Precio original vehiculo",
  [CreditExportFields["vehicle.discounts"]]: "Discuentos vehiculo",
  [CreditExportFields["vehicle.insurancePrice"]]: "Precio aseguradora vehiculo",
  [CreditExportFields["vehicle.score"]]: "Puntaje vehiculo",
  [CreditExportFields["vehicle.details"]]: "Detalles vehiculo",
  [CreditExportFields["vehicle.type"]]: "Tipo vehiculo",
  [CreditExportFields["vehicle.status"]]: "Estado vehiculo",
  [CreditExportFields["vehicle.maintenanceColor"]]: "Color mantenimiento vehiculo",
  [CreditExportFields["vehicle.maintenanceDays"]]: "Dias mantenimiento vehiculo",
  [CreditExportFields["vehicle.gpsStatus"]]: "Estado GPS vehiculo",
  [CreditExportFields["vehicle.statusHistoric"]]: "Historico de estados vehiculo",
  [CreditExportFields["vehicle.insurance"]]: "Aseguradora vehiculo",
  [CreditExportFields["vehicle.deposit"]]: "Deposito vehiculo",
  [CreditExportFields["vehicle.depositPrice"]]: "Precio deposito vehiculo",
  [CreditExportFields["vehicle.salePrices"]]: "Precios vehiculo",
  [CreditExportFields["vehicle.extraCost"]]: "Costo adicional vehiculo",
  [CreditExportFields["vehicle.totalCost"]]: "Costo total vehiculo",
  [CreditExportFields["vehicle.hub"]]: "Hub vehiculo",
  [CreditExportFields["vehicle.city"]]: "Ciudad vehiculo",
  [CreditExportFields["vehicle.country"]]: "Pais vehiculo",
  [CreditExportFields["vehicle.documents"]]: "Documentos vehiculo",
  [CreditExportFields["vehicle.warranty"]]: "Garantia vehiculo",
  [CreditExportFields["vehicle.description"]]: "Descripcion vehiculo",
  [CreditExportFields["vehicle.detail"]]: "Detalles vehiculo",
  [CreditExportFields["vehicle.factorKM"]]: "Factor KM vehiculo",
  [CreditExportFields["vehicle.confirmationKM"]]: "KM por confirmar",
  [CreditExportFields["vehicle.blueBookPrice"]]: "Precio del mercado del vehiculo",
  [CreditExportFields["vehicle.avgKM"]]: "KM aproximados vehiculo",
  [CreditExportFields["vehicle.trackerGPSId"]]: "Id GPS Tracker vehiculo",
  [CreditExportFields["vehicle.lastChangeBy"]]: "Ozonio ultimo cambio vehiculo",
  [CreditExportFields["vehicle.createdAt"]]: "Fecha creacion vehiculo",
  [CreditExportFields["vehicle.updatedAt"]]: "Fecha actualizacion vehiculo",

  [CreditExportFields["ozoner.financialForm.userInfoStep"]]: "Informacion usuario",
  [CreditExportFields["ozoner.financialForm.monthlyIncome"]]: "Ingreso mensual",
  [CreditExportFields["ozoner.financialForm.monthlyIncomeVerified"]]: "Ingreso mensual verificado",
  [CreditExportFields["ozoner.financialForm.monthlySpending"]]: "Gasto mensual",
  [CreditExportFields["ozoner.financialForm.monthlySpendingVerified"]]: "Gasto mensual verificado",
  [CreditExportFields["ozoner.financialForm.childrenCount"]]: "Cantidad de hijos",
  [CreditExportFields["ozoner.financialForm.dependantsCount"]]: "Cantidad de dependientes economicos",
  [CreditExportFields["ozoner.financialForm.companyName"]]: "Nombre empresa",
  [CreditExportFields["ozoner.financialForm.economicActivity"]]: "Ocupación",
  [CreditExportFields["ozoner.financialForm.group"]]: "Grupo",
  [CreditExportFields["ozoner.financialForm.civilStatus"]]: "Estado ivil",
  [CreditExportFields["ozoner.financialForm.livesWith"]]: "Vive con",
  [CreditExportFields["ozoner.financialForm.authDataTreatment"]]: "Autoriza tratamiento de datos",
  [CreditExportFields["ozoner.financialForm.platforms"]]: "Plataformas",
  [CreditExportFields["ozoner.financialForm.workInDigitalPlatforms"]]: "Trabaja en plataformas digitales",
  [CreditExportFields["ozoner.financialForm._id"]]: "Id solicitud de crédito",
  [CreditExportFields["ozoner.financialForm.vehicleId"]]: "Id del vehiculo de la solicitud",
  [CreditExportFields["ozoner.financialForm.approved"]]: "Aprobado",
  [CreditExportFields["ozoner.financialForm.score"]]: "Puntaje",
  [CreditExportFields["ozoner.financialForm.dti"]]: "DTI",
  [CreditExportFields["ozoner.financialForm.createdAt"]]: "Fecha creacion solicitud",
  [CreditExportFields["ozoner.financialForm.updatedAt"]]: "Fecha actualizacion solicitud",
  [CreditExportFields["ozoner.financialForm.status"]]: "Estado solicitud",
  [CreditExportFields["ozoner.financialForm.statusHistoric"]]: "Historial estados solicitud",
  [CreditExportFields["ozoner.financialForm.weekFlow"]]: "Flujo semanal",
  [CreditExportFields["ozoner.financialForm.weeklyIncome"]]: "Ingreso semanal",
  [CreditExportFields["ozoner.financialForm.weeklyOutcome"]]: "Gasto semanal",
  [CreditExportFields["ozoner.financialForm.afterPayIncome"]]: "Ingresos despues de gastos",
  [CreditExportFields["ozoner.financialForm.maxWeekQuota"]]: "Cuota maxima",
  [CreditExportFields["ozoner.financialForm.creditTime"]]: "Tiempo de crédito",
  [CreditExportFields["ozoner.financialForm.advancedMoney"]]: "Monto apartado",
  [CreditExportFields["ozoner.financialForm.educationalLevel"]]: "Nivel educativo",
  [CreditExportFields["ozoner.financialForm.assets"]]: "Activos ozoner",
  [CreditExportFields["ozoner.financialForm.rejectedReason"]]: "Razon de rechazo",
  [CreditExportFields["ozoner.financialForm.returnPendingReason"]]: "Razon de devolución",
  [CreditExportFields["ozoner.financialForm.percentageMaxQuota"]]: "CTI",
  [CreditExportFields["ozoner.financialForm.partnerShip"]]: "Alianza",
  [CreditExportFields["ozoner.financialForm.personal_references"]]: "Referencias personales",
  [CreditExportFields["ozoner.financialForm.vehicleAvailable"]]: "Vehiculo disponible",
  [CreditExportFields["ozoner.financialForm.reached"]]: "Contactado",
  [CreditExportFields["ozoner.financialForm.documents"]]: "Documentos solicitud",
  [CreditExportFields["ozoner.financialForm.sellResponsible"]]: "Responsable de ventas",
  [CreditExportFields["ozoner.financialForm.discountId"]]: "Id descuento solicitud",
};

export const InformeCreditoFields = [
  CreditExportFields["porcobrarIdCustomer"],
  CreditExportFields["ozoner.name"],
  CreditExportFields["ozoner.phone"],
  CreditExportFields["ozoner.email"],
  CreditExportFields["ozoner.address"],
  CreditExportFields["ozoner.fullAddress"],
  CreditExportFields["ozoner.curp"],
  CreditExportFields["ozoner.financialForm.economicActivity"],
  CreditExportFields["ozoner.financialForm.companyName"],
  CreditExportFields["ozoner.financialForm.partnerShip"],
  CreditExportFields["vehicle.internalId"],
  CreditExportFields["vehicle.totalCost"],
  CreditExportFields["currency"],
  CreditExportFields["ozoner.financialForm.advancedMoney"],
  CreditExportFields["endedAtEstimated"],
  CreditExportFields["duration"],
  CreditExportFields["deposit"],
  CreditExportFields["createdAt"],
  CreditExportFields["status"],
  CreditExportFields["endedAt"],
  CreditExportFields["ozoner.city"],
  CreditExportFields["discountId"],
  CreditExportFields.latePayments,
  CreditExportFields["ozoner.comment"]
];