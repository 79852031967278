import React, { useContext, useLayoutEffect, useRef } from "react";
import { Map } from "mapbox-gl";
import { MapContext, PlacesContext } from "../../../context";
import LoadingMap from "./LoadingMap";

const OzonMap = () => {
  const { isLoading, userLocation } = useContext(PlacesContext);
  const { setMap } = useContext(MapContext);

  const mapDiv = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!isLoading) {
      const map = new Map({
        container: mapDiv.current!,
        style: "mapbox://styles/mapbox/streets-v9", //
        center: userLocation,
        zoom: 14,
      });

      setMap(map);
    }
  }, [isLoading]);

  if (isLoading) {
    return <LoadingMap />;
  }

  return (
    <>
      <div
        ref={mapDiv}
        style={{
          backgroundColor: "whitesmoke",
          height: "100dvh",
          width: "100dvw",
          position: "fixed",
          top: 0,
          left: 0,
        }}
      >
        {userLocation?.join(",")}
      </div>
      {/* <MapInfoContainer /> */}
    </>
  );
};

export default OzonMap;
