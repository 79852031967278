// eslint-disable-next-line no-shadow
export enum StatusOfferVehicle {
  offer = "offer",
  reached = "reached",
  bought = "bought",
  rejected = "rejected",
}
export const StatusOfferVehicleValues={
  offer:{
    title: "Ofertado",
    color:"secondary_600",
    tooltip:"Oferta sin atender",
  },
  reached:{
    title: "Contatado",
    color:"primary_300",
    tooltip:"Oferta en progreso",
  },
  bought:{
    title: "Comprada",
    color:"green_300",
    tooltip:"Oferta aceptada",
  },
  rejected:{
    title: "Rechazada",
    color:"red_300",
    tooltip:"Oferta rechazada",
  },
};
// eslint-disable-next-line no-shadow
export enum CreatedOnType {
  webpage = "webpage",
  pandaboard = "pandaboard",
}
export interface OfferVehicle {
  _id: string;
  year: number;
  km: number;
  brand: string;
  model: string;
  cylinderCapacity: string;
  amountOffer: number;
  status: StatusOfferVehicle;
  statusHistoric: any;
  createdAt: string;
  updatedAt: string;
  comment:string;
}
export interface TOzocio {
  name: string;
  phone: string;
  email: string;
  address: string;
  OfferVehicles: OfferVehicle[];
  createdOn: CreatedOnType;
  reachedBy: string;
  createdAt:string;
  updatedAt:string;
  _id:string
}
export interface TOzocioOneOffer {
  _id: string;
  name: string;
  phone: string;
  email: string;
  address: string;
  OfferVehicles: OfferVehicle;
  createdOn: CreatedOnType;
  reachedBy: string;
  createdAt:string;
  updatedAt:string;
}
