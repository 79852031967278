/* eslint-disable no-nested-ternary */
/* eslint-disable dot-notation */
/* eslint-disable no-unused-vars */

import React, { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import profileDefaultOzoner from "static/images/default_profile_ozoner.png";
// -----------------LIBRARIES IMPORTS------------------- //
import {
    Button,
    DataTable,
    Input,
    ModalConfirmation as Modal,
    SideModal,
    Typography,
} from "design_system/src";
import { toasterNotification } from "helpers/globalNotifications/globalNotifications";
import { toasterOptions } from "enums/toaster.enum";
import { LightTooltip } from "components/hocs/tooltip/Tooltip";

// -----------------DATA IMPORTS------------------- //
import { CreditStatus, CreditStatusValues } from "models/credit.interface";
import { statusOzoners } from "models/ozoner.interface";
import { statusVehicles, TVehicle } from "models/vehicle.interface";
import { FormStatusValues } from "models/financialForm.interface";

// -----------------STATIC IMPORTS------------------- //
import "./ozocio.scss";
import { ReactComponent as Back } from "design_system/src/static/icons/back.svg";
import { ReactComponent as Eye } from "design_system/src/static/icons/eye.svg";
import { ReactComponent as Refresh } from "design_system/src/static/icons/refresh.svg";
import { ReactComponent as Copy } from "design_system/src/static/icons/copy.svg";
import { ReactComponent as Monitor } from "design_system/src/static/icons/monitor.svg";
import { ReactComponent as Panda } from "design_system/src/static/icons/panda.svg";
import { ReactComponent as EyeOff } from "design_system/src/static/icons/eye-off.svg";
import { ReactComponent as Reload } from "design_system/src/static/icons/reload.svg";
import { ReactComponent as Whatsapp } from "design_system/src/static/icons/whatsapp.svg";
import { ReactComponent as Mail } from "design_system/src/static/icons/mail.svg";
import { ReactComponent as SMS } from "design_system/src/static/icons/chat-square-plain.svg";
import { ReactComponent as Add } from "design_system/src/static/icons/add-circle.svg";
import { ReactComponent as Down } from "design_system/src/static/icons/chevron-down.svg";
import { ReactComponent as Edit } from "design_system/src/static/icons/edit-text.svg";

// -----------------COMPONENTS IMPORTS------------------- //
import { editCredit } from "helpers/fetchs/credits";
import History from "components/hocs/history/History";
import { Grid } from "@mui/material";
import Inventory from "views/inventory/Inventory";
import { handleEventGoogle } from "helpers/analytics/googleAnalytics";
import Swal from "sweetalert2";
import { editOffer, editOzocio, getBrandsOzocios, getOzocioById } from "helpers/fetchs/ozocios";
import {
    OfferVehicle,
    StatusOfferVehicle,
    StatusOfferVehicleValues,
    TOzocio,
} from "models/ozocio.interface";
import moment from "moment";
import { getOzonios } from "helpers/fetchs/ozonios";
import EditOzocio from "./EditOzocio";

// eslint-disable-next-line no-shadow
export enum EditTypesOzoner {
    REGULAR = 0,
    OZONER_DATA = 1,
    PERSONAL_REFERENCES = 2,
    FINANCIAL_DATA = 3,
    NEW_SOLICITUD = 4,
}

const detailsData = [
    {
        title: "Marca",
        name: "brand",
    },

    {
        title: "Modelo",
        name: "model",
    },
    {
        title: "Cilindraje",
        name: "cylinderCapacity",
    },
    {
        title: "Kilometraje",
        name: "km",
    },
    {
        title: "Año",
        name: "year",
    },
    {
        title: "Oferta Pandaboard",
        name: "amountOffer",
    },
];

const OfferOption = (offer: OfferVehicle, onClick: any = () => {}) => {
    if (offer) {
        const { _id, brand, model, year, status, createdAt, cylinderCapacity } =
            offer;
        return (
            <div
                className="p_md display_flex flex_gap_md border_solid border_1 border_neutral_400 flex_align_center cursor_pointer"
                role="button"
                tabIndex={0}
                onClick={onClick}
                onKeyDown={onClick}
                key={_id}
            >
                <div className="flex_grow_1 flex_center_col">
                    <Typography
                        scale="small"
                        weight="600"
                        className="inventory_link cursor_pointer"
                    >
                        {brand ?? ""} {model ?? ""} {cylinderCapacity ?? ""}{" "}
                        {year ?? ""}{" "}
                    </Typography>

                    <LightTooltip
                        title={
                            (StatusOfferVehicleValues as any)[status]
                                ?.tooltip ?? "Sin informacion"
                        }
                    >
                        <div>
                            <Typography
                                weight="400"
                                scale="medium"
                                textColor="neutral_700"
                                className="text_center flex_grow_1 flex_basis_0"
                            >
                                <div
                                    className={`
                                                  dso_chip flex_grow_1
                                                  bg_${
                                                      (
                                                          StatusOfferVehicleValues as any
                                                      )[status]?.color ??
                                                      "neutral_600"
                                                  }_nocontrast
                                                  text_neutral_0 flex_basis_0 overflow_hidden overflow_ellipsis text_center
                                                `}
                                >
                                    {(StatusOfferVehicleValues as any)[status]
                                        ?.title ?? "No registrado"}
                                </div>
                            </Typography>
                        </div>
                    </LightTooltip>
                </div>
                <div className="h_xxxl bg_neutral_400 w_xxxs" />
                <div className="flex_grow_1">
                    <Typography
                        scale="medium"
                        weight="600"
                        textColor="neutral_900"
                        className="m_b_xs text_no_break"
                    >
                        Oferta {_id.slice(-4)}
                    </Typography>
                    <Typography
                        scale="small"
                        weight="600"
                        textColor="neutral_600"
                        className="text_no_break"
                    >
                        {createdAt
                            ? moment(createdAt)
                                  .utcOffset(-360)
                                  .format("DD/MM/YY")
                            : "No registra"}
                    </Typography>
                </div>
                <div className="dim_lg" />
            </div>
        );
    }
    return null;
};

const DetailsOzocio = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { _id } = useParams();

    // -----------------LOGIC STATES------------------- //
    const [loading, setLoading] = useState<boolean>(false);
    const [openStatusHistory, setOpenStatusHistory] = useState<boolean>(false);
    const [openOffers, setOpenOffers] = useState<boolean>(false);

    const [openEdit, setOpenEdit] = useState<boolean>(false);
    const [isNewEdit, setIsNewEdit] = useState<boolean>(false);
    const [comment, setComment] = useState("");

    const [offerId, setOfferId] = useState<string>();

    const [ozocio, setOzocio] = useState<TOzocio | null>();

    const [offerSelected, setOfferSelected] = useState<OfferVehicle>();
    const [loadingResponsible, setLoadingResponsible] = useState(false);
    const [ozoniosList, setOzoniosList] = useState<any[]>([]);

    const [openModal, setOpenModal] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState("");

    const [ozonioLastChange, setOzonioLastChange] = useState("");


    const getOzocio = (id: string) => {
        getOzocioById(id)
            .then((response) => {
                setOzocio(response);
                setLoading(false);
            })
            .catch((e: any) => {
                setLoading(false);
                Swal.fire({
                    title: "Error!",
                    text: e ? e.message : "ozocio no valido",
                    icon: "error",
                    confirmButtonText: "Aceptar",
                }).then(() => {
                    navigate("/ozocio");
                });
            });
    };

    const fetchData = () => {
        if (_id) {
            setLoading(true);
            getOzocio(_id);
        }
    };
    const goWA = (number: string) =>
        window.open(`https://api.whatsapp.com/send?phone=${number}`, "_blank");

    useEffect(() => {
        fetchData();
    }, [_id]);
    useEffect(() => {
        setOfferSelected(undefined);
        const ofSelected = ozocio?.OfferVehicles?.filter(
            (offer) => offer._id === offerId
        );
        if (ofSelected?.length) {
            setOfferSelected(ofSelected[0]);
            setComment(ofSelected[0].comment ?? "");
            setOzonioLastChange(
                ofSelected[0].statusHistoric?.[
                    (ofSelected[0].statusHistoric?.length??1) - 1
                ]?.by??undefined
            );
        }
    }, [ozocio, offerId]);

    const changeForm = (id: string) => {
        navigate(`/ozocio/${_id}?offerId=${id}`);
        setOfferId(id);
        setOpenOffers(false);
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const offerIds = queryParams.get("offerId");
        if (offerIds) setOfferId(offerIds);
    }, [_id]);
    useEffect(() => {
        getOzonios("").then((ozons:{data:any[]}) => {
            setOzoniosList(
                ozons.data.sort((a,b)=>{
                    if (a.name < b.name) {
                        return -1;
                    }
                    if (a.name > b.name) {
                        return 1;
                    }
                    return 0;
                }).map((ozonio: any) => ({
                    value: ozonio._id,
                    label: ozonio.name,
                }))
            );
        });

    }, []);

    const updateComment = () => {
        if (ozocio?._id && offerSelected?._id)
          editOffer(ozocio._id,offerSelected._id,{ comment }).then(() => {
            fetchData();
          });
    };
    const handleChangeResponsible = (e: any) => {
        if (ozocio?._id && e.target.value) {
            setLoadingResponsible(true);
            editOzocio(ozocio._id, {
                reachedBy: e.target.value,
            })
                .then(() => {
                    fetchData();
                    setLoadingResponsible(false);
                    toasterNotification({
                        msg: "Solicitud Exitosa",
                        style: "light",
                        toasterType: toasterOptions.success,
                    });
                })
                .catch((err: any) => {
                    if (
                        err.statusCode === 400 &&
                        err.message === "Vehicle not available"
                    ) {
                        setLoadingResponsible(false);
                        toasterNotification({
                            msg: "El vehiculo ya no esta disponible",
                            style: "light",
                            toasterType: toasterOptions.error,
                        });
                    }
                });
        }
    };

    const getDetails = () => {
        let data: any = [];
        data.push({
            label: "Fecha de Solicitud:",
            value: offerSelected?.createdAt
                ? moment(offerSelected?.createdAt)
                      .utcOffset(-360)
                      .format("D MMMM YYYY")
                : "No registra",
        });
        data = [
            ...data,
            ...detailsData.map((field: any) => ({
                label: field.title,
                value: `${(offerSelected as any)?.[field.name]}`,
            })),
        ];
        return data;
    };
    const updateStatus = (status: string) => {
        if (ozocio?._id && offerSelected?._id) {
            editOffer(ozocio._id, offerSelected._id, { status })
                .then(() => {
                    toasterNotification({
                        msg: "Solicitud Exitosa",
                        style: "light",
                        toasterType: toasterOptions.success,
                    });
                    fetchData();
                    setOpenModal(false);
                })
                .catch((e: any) => {
                    toasterNotification({
                        msg: e ?? "Ocurrio un error",
                        style: "light",
                        toasterType: toasterOptions.error,
                    });
                });
        }
    };
    const statusActions: any = {
        [StatusOfferVehicle.offer]: [
            {
                title: "Marcar como contactado",
                action: () => {
                    setSelectedStatus(StatusOfferVehicle.reached);
                    setOpenModal(true);
                    handleEventGoogle(
                        "BTN_Pb_request_reached",
                        "Panda Ozocios",
                        "click reached request button"
                    );
                },
                color: "primary",
            },
        ],
        [StatusOfferVehicle.rejected]: [
            {
                title: "Devolver",
                action: () => {
                    setSelectedStatus(StatusOfferVehicle.offer);
                    setOpenModal(true);
                    handleEventGoogle(
                        "BTN_Pb_request_offer",
                        "Panda Ozocios",
                        "click offer request button"
                    );
                },
                color: "light_grey",
            },
        ],
        [StatusOfferVehicle.bought]: [
            {
                title: "Devolver",
                action: () => {
                    setSelectedStatus(StatusOfferVehicle.offer);
                    setOpenModal(true);
                    handleEventGoogle(
                        "BTN_Pb_request_offer",
                        "Panda Ozocios",
                        "click offer request button"
                    );
                },
                color: "light_grey",
            },
        ],
        [StatusOfferVehicle.reached]: [
            {
                title: "Vehículo comprado",
                action: () => {
                    setSelectedStatus(StatusOfferVehicle.bought);
                    setOpenModal(true);
                    handleEventGoogle(
                        "BTN_Pb_request_bought",
                        "Panda Ozocios",
                        "click bought request button"
                    );
                },
                color: "primary",
            },
            {
                title: "Vehículo Rechazado",
                action: () => {
                    setSelectedStatus(StatusOfferVehicle.rejected);
                    setOpenModal(true);
                    handleEventGoogle(
                        "BTN_Pb_request_rejected",
                        "Panda Ozocios",
                        "click rejected request button"
                    );
                },
                color: "red",
            },
            {
                title: " Marcar como sin contactar",
                action: () => {
                    setSelectedStatus(StatusOfferVehicle.offer);
                    setOpenModal(true);
                    handleEventGoogle(
                        "BTN_Pb_request_.offer",
                        "Panda Ozocios",
                        "click .offer request button"
                    );
                },
                variant:"link",
                color: "primary",
            },
        ],
    };
    return !loading && ozocio && offerSelected ? (
        <>
            <div className="dim_100_per">
                <div className="shadow_hard br_xs p_x_md p_y_xs display_flex flex_gap_md flex_align_center m_b_lg">
                    <Button
                        variant="icon"
                        icon={<Back />}
                        subvariant="edit"
                        scale="small"
                        onClick={() => navigate(-1)}
                        className="flex_align_self_start"
                    />
                    <div
                        id="preview-ozoner"
                        className="display_flex flex_gap_md flex_align_center flex_grow_1"
                    >
                        <div className="br_sm border_xxs border_solid photo_ozoner  display_flex overflow_hidden img_ozoner border_neutral_500">
                            <img
                                src={profileDefaultOzoner}
                                alt=""
                                className="dim_100_per img_cover"
                            />
                        </div>
                        <div className="display_flex flex_justify_between flex_col flex_gap_xs ">
                            <div className="display_flex flex_gap_md flex_align_center">
                                <div className="p_y_xxxs p_x_xxs br_xxxs bg_neutral_300 flex_center flex_gap_xs">
                                    {ozocio.createdOn === "pandaboard" ? (
                                        <Panda className=" dim_sm text_primary_200" />
                                    ) : (
                                        <Monitor className=" dim_sm text_primary_200" />
                                    )}
                                    <Typography
                                        scale="small"
                                        textColor="neutral_800"
                                        weight="400"
                                        className="text_capitalize"
                                    >
                                        {ozocio.createdOn}
                                    </Typography>
                                </div>
                            </div>
                            <Typography
                                scale="medium"
                                textColor="neutral_900"
                                weight="600"
                                className="text_capitalize"
                            >
                                {ozocio.name}
                            </Typography>
                        </div>
                    </div>
                    <div id="credit-selection" className="pos_relative">
                        <div
                            className="pos_relative cursor_pointer"
                            onClick={() => setOpenOffers(!openOffers)}
                            role="button"
                            tabIndex={0}
                            onKeyDown={() => setOpenOffers(!openOffers)}
                        >
                            {OfferOption(offerSelected)}
                            <Down
                                className={`dim_lg open_credits ${
                                    openOffers ? "open" : ""
                                }`}
                            />
                        </div>
                        <div
                            className={`credits_section shadow_hard bg_neutral_0 ${
                                openOffers ? "open" : ""
                            }`}
                        >
                            {ozocio.OfferVehicles?.filter(
                                (offer: any) => offerSelected._id !== offer._id
                            ).map((offer: any) =>
                                OfferOption(offer, () => changeForm(offer._id))
                            )}
                            <div
                                className="p_xl flex_gap_xs border_solid border_1 border_neutral_400 flex_center cursor_pointer"
                                role="button"
                                tabIndex={0}
                                onClick={() => {
                                    setOpenEdit(true);
                                    setIsNewEdit(true);
                                    setOpenOffers(false);
                                }}
                                onKeyDown={() => {
                                    setIsNewEdit(true);
                                    setOpenEdit(true);
                                    setOpenOffers(false);
                                }}
                            >
                                <Typography
                                    weight="600"
                                    scale="medium"
                                    textColor="neutral_900"
                                    className=""
                                >
                                    Nueva Oferta
                                </Typography>
                                <Add className="dim_lg" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="display_flex">
                    <Typography
                        scale="medium"
                        weight="600"
                        className="cursor_pointer text_center p_x_md p_y_xs ozoner_tab active"
                    >
                        Datos
                    </Typography>
                </div>
                <div className="p_sm border_1 border_solid border_neutral_400 bg_neutral_100">
                    <Grid
                        container
                        direction="row"
                        spacing={3}
                        className="h_100_per"
                    >
                        <Grid item xs={3}>
                            <div className="bg_neutral_0 border_neutral_400 border_solid border_1 br_sm">
                                <div
                                    id="contacto-ozoner"
                                    className="p_md border_b_solid border_1 border_neutral_400"
                                >
                                    <Typography scale="medium" weight="400">
                                        Contacto
                                    </Typography>
                                    <div className="display_flex flex_gap_md p_y_md">
                                        <Whatsapp className="dim_lg text_primary_300 flex_shrink_0" />
                                        <Typography
                                            scale="medium"
                                            weight="600"
                                            className="text_link"
                                            onClick={() => goWA(ozocio.phone)}
                                        >
                                            {ozocio.phone}
                                        </Typography>
                                    </div>
                                    <div className="display_flex flex_gap_md p_y_md">
                                        <Mail className="dim_lg text_primary_300 flex_shrink_0" />
                                        <Typography
                                            scale="medium"
                                            weight="600"
                                            className="overflow_ellipsis text_link"
                                            onClick={() => {
                                                window.location.href = `mailto:${ozocio.email}`;
                                            }}
                                        >
                                            {ozocio.email}
                                        </Typography>
                                    </div>
                                </div>
                                <div
                                    id="estado-ozocio"
                                    className="p_md border_b_solid border_1 border_neutral_400"
                                >
                                    <Typography
                                        scale="medium"
                                        weight="400"
                                        className="m_t_md"
                                    >
                                        Responsable de Contacto
                                    </Typography>
                                    <Input
                                        title=""
                                        disabled={loadingResponsible}
                                        name="Responsable"
                                        placeholder="Ingresa el Ozonio"
                                        value={ozocio.reachedBy}
                                        onChange={handleChangeResponsible}
                                        className="w_100_per"
                                        type="select"
                                        options={ozoniosList}
                                        variant="default"
                                    />
                                    <div className="display_flex flex_justify_between flex_align_center m_b_xs">
                                        <Typography
                                            scale="medium"
                                            weight="400"
                                            textColor="neutral_800"
                                            className="text_no_break"
                                        >
                                            Estado :
                                        </Typography>
                                        <LightTooltip
                                            title={
                                                (
                                                    StatusOfferVehicleValues as any
                                                )[offerSelected.status]
                                                    ?.tooltip ??
                                                "Sin informacion"
                                            }
                                        >
                                            <div>
                                                <Typography
                                                    weight="400"
                                                    scale="medium"
                                                    textColor="neutral_700"
                                                    className="text_center flex_grow_1 flex_basis_0"
                                                >
                                                    <div
                                                        className={`
                                                  dso_chip flex_grow_1
                                                  bg_${
                                                      (
                                                          StatusOfferVehicleValues as any
                                                      )[offerSelected.status]
                                                          ?.color ??
                                                      "neutral_600"
                                                  }_nocontrast
                                                  text_neutral_0 flex_basis_0 overflow_hidden overflow_ellipsis text_center
                                                `}
                                                    >
                                                        {(
                                                            StatusOfferVehicleValues as any
                                                        )[offerSelected.status]
                                                            ?.title ??
                                                            "No registrado"}
                                                    </div>
                                                </Typography>
                                            </div>
                                        </LightTooltip>
                                    </div>
                                    <div
                                        id="actions-soli-ozoner"
                                        className=" display_flex flex_col flex_gap_xs"
                                    >
                                        {statusActions[
                                            offerSelected.status
                                        ]?.map((action: any) => (
                                            <Button
                                                scale="small"
                                                color={action.color}
                                                disabled={action.disabled}
                                                onClick={action.action}
                                                key={action.title}
                                                variant={action.variant??"principal"}
                                            >
                                                {action.title}
                                            </Button>
                                        ))}
                                    </div>
                                    {ozonioLastChange && (
                                        <div className="br_xs bg_neutral_300 p_x_lg p_y_md m_t_md flex_gap_sm flex_center">
                                            <Typography
                                                scale="xxsmall"
                                                weight="400"
                                                className="text_neutral_800 text_no_break"
                                            >
                                                Ultima modificación:
                                            </Typography>
                                            <Typography
                                                scale="xxsmall"
                                                weight="600"
                                                className="text_neutral_900 text_no_break"
                                            >
                                                {ozoniosList?.filter(ozonio=>ozonio.value===ozonioLastChange)[0]?.label??ozonioLastChange}
                                            </Typography>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Grid>

                        <Grid item xs={9}>
                            <div className="br_xxs bg_neutral_0 p_xs border_1 border_solid border_neutral_400 pos_relative">
                                <Button
                                    onClick={() => {
                                        setOpenEdit(true);
                                        setIsNewEdit(false);
                                    }}
                                    variant="icon"
                                    icon={<Edit />}
                                    subvariant="edit"
                                    scale="small"
                                    className="edit-button"
                                />
                                <DataTable
                                    data={getDetails()}
                                    weightLabels={3}
                                    weightValues={2}
                                />
                            </div>
                            <Typography
                                scale="large"
                                weight="600"
                                textColor="neutral_900"
                                className="m_y_md"
                            >
                                Observaciones del ozocio :
                            </Typography>
                            <textarea
                                className="observaciones w_100_per p_md border_1 border_solid border_neutral_400"
                                placeholder="Agregue todas las observaciones del ozoner que se detecten"
                                value={comment}
                                onChange={(e: any) =>
                                    setComment(e.target.value)
                                }
                            />
                            {comment !== offerSelected.comment && (
                                <div className="display_flex flex_justify_end">
                                    <Button
                                        scale="small"
                                        variant="ghost"
                                        onClick={updateComment}
                                    >
                                        Guardar Cambios
                                    </Button>
                                    <Button
                                        scale="small"
                                        variant="ghost"
                                        onClick={() =>
                                            setComment(
                                                offerSelected?.comment ?? ""
                                            )
                                        }
                                    >
                                        Cancelar
                                    </Button>
                                </div>
                            )}
                        </Grid>
                    </Grid>
                </div>
            </div>
            {openEdit && (
                <EditOzocio
                    open={openEdit}
                    setOpen={setOpenEdit}
                    ozocio={ozocio}
                    offer={isNewEdit ? undefined : offerSelected}
                    setResult={fetchData}
                    label={isNewEdit?"Crear nueva oferta":"Editar oferta"}
                />
            )}
            {openModal && (
                <Modal
                    openConfirmation={openModal}
                    handleClose={() => setOpenModal(false)}
                    onConfirmationCallBack={() => updateStatus(selectedStatus)}
                    subtitle={
                        <Typography scale="large" weight="400">
                            El estado de la oferta cambiara de{" "}
                            <Typography
                                scale="large"
                                weight="600"
                                component="span"
                            >
                                {
                                    StatusOfferVehicleValues[
                                        offerSelected.status
                                    ].title
                                }
                            </Typography>{" "}
                            a{" "}
                            <Typography
                                scale="large"
                                weight="600"
                                component="span"
                            >
                                {
                                    StatusOfferVehicleValues[
                                        selectedStatus as StatusOfferVehicle
                                    ].title
                                }
                            </Typography>{" "}
                        </Typography>
                    }
                    title={
                        <Typography scale="large" weight="400">
                            ¿Seguro que deseas cambiar el estado?
                        </Typography>
                    }
                    copyConfirm="Aceptar cambio"
                    copyReject="Cancelar"
                    icon={<Reload />}
                    widthDesktop={40}
                />
            )}
        </>
    ) : null;
};

export default DetailsOzocio;
