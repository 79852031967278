/* eslint-disable no-unused-vars */
/* eslint-disable no-prototype-builtins */
/* eslint-disable guard-for-in,no-restricted-syntax */
import { Theme, toast } from "react-toastify";
import moment from "moment";

import { get, post, put, deletes } from "../axios/axiosInstance";
import { toasterOptions } from "../../enums/toaster.enum";

/**
 * Function to send the selected notifications
 * @param {Object} notificationPayload - Notifications detail.
 */
export const sendNotifications = async (
  notificationPayload: any
): Promise<any | null> => {
  try {
    const endpoint = "v1/ozoner/notifications";
    const { data } = await post(endpoint, notificationPayload);

    return {
      ok: true,
      message: `Se Enviaron ${data.estimated} notificaciones`,
      totalNotifications: data.estimated,
    };
  } catch (e) {
    return {
      ok: false,
      message: e,
    };
  }
};

export const sendNotificationsByIds = async (
  groupEmailId: string,
  notificationIDsArr: string[]
): Promise<any | null> => {
  try {
    const endpoint = `v1/group-notification/resend/${groupEmailId}`;
    const { data } = await post(endpoint, {
      emails: notificationIDsArr,
    });
    return {
      ok: true,
      message: "Reenvio exitoso",
      totalNotifications: data.estimated,
    };
  } catch (e) {
    return {
      ok: false,
      message: e,
    };
  }
};

/**
 * Function to remove duplicate values inside a given array.
 * @param {Array} arr - Array of filters.
 * @param {Object} newValue - Totals Items return from endpoint.
 */
export const removeArrDuplicateValues = (arr: any, newValue: string = "") => {
  if (arr.includes(newValue)) {
    return arr.filter((name: string) => name !== newValue);
  }
  const newArr = [...arr, newValue];
  return newArr;
};

/**
 * Function to Compare the totalNotifications Obj with the actual filters.
 * @param {Array} firstArr - Array of filters.
 * @param {Object} totalsObj - Totals Items return from endpoint.
 */
export const compareArrays = (firstArr: any, totalsObj: any) => {
  const totalsArr: any[] = [];
  for (const item in firstArr) {
    if (totalsObj.subStatus.hasOwnProperty(firstArr[item])) {
      const preprocesedObj = totalsObj.subStatus[firstArr[item]] || 0;
      totalsArr.push(preprocesedObj);
    }
  }
  return totalsArr;
};

/**
 * Funtion to sum the actual selected filter values.
 * @param {Object} totals - Total items received.
 * @param {Array} selectedOptions - Current filtered options.
 */
export const getTotals = (totals: any, selectedOptions: any): number => {
  const result = compareArrays(selectedOptions, totals).reduce(
    (previousValue, currentValue) => previousValue + currentValue,
    0
  );

  return result as number;
};

export const removeCommas = (str: string) => {
  if (str.startsWith(",") && str.endsWith(",")) {
    return str.slice(1, -1);
  }

  if (str.startsWith(",")) {
    return str.slice(1);
  }

  if (str.endsWith(",")) {
    return str.slice(0, -1);
  }

  return str;
};
/**
 * Funtion to create a template.
 * @param {String} templateName - Template name.
 * @param {String} templateContent - Template Content information.
 * @param templateMeta - Template METADATA information necessary for DRAFT-JS.
 * @param typeNotification - typeNotification
 */
export const createTemplate = async (
  templateName: string,
  templateContent: string,
  templateMeta: string,
  typeNotification: string
) => {
  console.log(templateName);
  console.log(templateContent);
  console.log(templateMeta);
  console.log(typeNotification);
  const endpoint = "v1/template";

  const createTemplatePayload = {
    name: templateName,
    body: templateContent,
    meta: templateMeta,
    typeNotification,
  };
  try {
    const { data } = await post(endpoint, createTemplatePayload);
    return {
      ok: true,
      data,
    };
  } catch (e) {
    return {
      ok: false,
      error: e,
    };
  }
};
/**
 * Funtion to get a template By Id.
 * @param {String} id - Template name.
 */
export const getTemplateById = async (id: string) => {
  const endpoint = "v1/template";

  try {
    const { data } = await get(`${endpoint}/${id}`);
    return {
      ok: true,
      data,
    };
  } catch (e) {
    return {
      ok: false,
      data: e,
    };
  }
};
/**
 * Funtion to get all templates.
 * @param notificationType
 */
export const getAllTemplates = async (notificationType: string) => {
  const endpoint = `v1/template?typeNotification=${notificationType}`;
  const { data } = await get(endpoint);
  return {
    ok: true,
    data,
  };
};

/**
 * Funtion to update  templates.
 * @param {String} id - Template ID.
 * @param {any} templateContent - template content
 * @param templateMeta - string to render tags in Draft-js
 */
export const updateTemplateById = async (
  id: string,
  templateContent: any,
  templateMeta: string
) => {
  const endpoint = "v1/template";

  const getTemplateInfo = await getTemplateById(id);

  const updatePayload = {
    name: getTemplateInfo.data.name,
    body: templateContent,
    meta: templateMeta,
  };

  try {
    const { data } = await put(`${endpoint}/${id}`, updatePayload);
    return {
      ok: true,
      data,
    };
  } catch (e) {
    return {
      ok: false,
      error: e,
    };
  }
};

export const deleteTemplateById = async (id: string) => {
  const endpoint = "v1/template";
  try {
    const { data } = await deletes(`${endpoint}/${id}`);
    return {
      ok: true,
      data,
    };
  } catch (e) {
    return {
      ok: false,
      error: e,
    };
  }
};

/**
 * Funtion to get Notification Groups .
 * @param {String} id - Template ID.
 * @param {String} limit - Limit results.
 */

export const getGroupNotificationsById = async (id: string,limit:number=30) => {
  const endpoint = `/v1/group-notification/${id}?limit=${limit}&page=1&pages=1%2C2`;

  try {
    const { data } = await get(`${endpoint}`);
    return {
      ok: true,
      data,
    };
  } catch (e) {
    return {
      ok: false,
      error: e,
    };
  }
};

interface IToasterOptionsParams {
  msg: string;
  toasterType: toasterOptions;
  style?: Theme | undefined;
  onOpenCallback?: any;
  onCloseCallback?: any;
}

export const toasterNotification = (options: IToasterOptionsParams) => {
  const {
    msg,
    toasterType,
    style = "colored",
    onOpenCallback = null,
    onCloseCallback = null,
  } = options;
  switch (toasterType) {
    case toasterType:
      return toast[toasterType](msg, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: style,
        onOpen: onOpenCallback,
        onClose: onCloseCallback, // ({ uid }) => window.alert(uid)
      });
    default:
      return toast(msg, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: style,
        onOpen: onOpenCallback,
        onClose: onCloseCallback,
      });
  }
};

export const convertDate = (date: string = " ") =>
  moment(date).format("DD/MM/YYYY");

export const extractTimeFromDate = (dateString: string = " ") =>
  moment(dateString).format("hh:mm A");

interface Ozoner {
  invoice: any[];
  documents: any[];
  roles: string[];
  _id: string;
  vehicle: string;
  status: string;
  subStatus: string;
  paymentMethod: string;
  paymentDay: number;
  social_network: string;
  truoraVerification: boolean;
  palencaVerification: boolean;
  curp: string;
  address: string;
  email: string;
  phone: string;
  name: string;
  __v: number;
  createdAt: string;
  statusHistoric: any[];
  financialForm: any[];
  id_ozon: string;
}
export interface INotificationResponse {
  codeCredit: number;
  _id: string;
  statusOzon: string;
  status: string;
  idSG: string;
  groupEmail: string;
  ozoner: Ozoner;
  createdAt: Date;
  updatedAt: Date;
  __v: number;
}

export const tranformNotificationResponseIntoTableData = (
  notificationArr: INotificationResponse[]
) => {
  return notificationArr.map((item) => {
    return {
      id: item?._id,
      nombre: item?.ozoner?.name,
      contrato: item?.codeCredit || "sin número",
      telefono: item?.ozoner?.phone,
      correo: item?.ozoner?.email,
      subStatus: item?.ozoner?.subStatus,
      status: item?.ozoner?.status,
    };
  });
};

export const addStringIntoArr = (id: string, arr: string[]) => {
  return arr.concat([...arr, id]);
};

export const removeStringFromArr = (id: string, arr: string[]) => {
  const newArr = arr.filter((e) => e !== id);
  return newArr;
};

export const setTemplateName = (notificationInfo: any) => {
  const templateName = notificationInfo.templateMail
    ? notificationInfo.templateMail.name
    : "Sin Nombre";
  return templateName;
};

export const mergeArrays = (arrays: any[]) => {
  let mergedArray: any[] = [];

  arrays.forEach((array: any) => {
    mergedArray = mergedArray.concat(array);
  });

  return mergedArray;
};

export const maskPhoneNumber = (phoneNumber: string) => {
  if (phoneNumber.length < 13) {
    return phoneNumber;
  }
  const x = phoneNumber
    .replace(/\D/g, "")
    .match(/(\d{3})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/);
  const masked = `${x![2]}${x![3]}${x![4]}${x![5]}${x![6]}`;
  return masked;
};

type Option = {
  value: string;
  label: string;
};

type Group = {
  label: string;
  options: Option[];
};

type GroupName = "En Espera" | "Activo" | "No Activos" | "Default";

type Groups = Record<GroupName, Group>;

type Obj = Record<string, number>;

const groups: Groups = {
  "En Espera": {
    label: "En Espera",
    options: [
      {
        value: "pandaLead",
        label: "Lead PB",
      },
      {
        value: "approved",
        label: "Aprobado",
      },
      {
        value: "webLead",
        label: "Lead WP",
      },
    ],
  },
  Activo: {
    label: "Activo",
    options: [
      {
        value: "alDia",
        label: "Al Día",
      },
      {
        value: "earlyDefault",
        label: "Mora Temprana",
      },
      {
        value: "lateDefault",
        label: "Mora Tardía",
      },
      {
        value: "moraAlta",
        label: "Mora Alta",
      },
      {
        value: "risk",
        label: "Mora En Riesgo",
      },
      {
        value: "default",
        label: "Default",
      },
    ],
  },
  "No Activos": {
    label: "No Activos",
    options: [
      {
        value: "devolution",
        label: "Devolucion",
      },
      {
        value: "repossession",
        label: "Recuperada",
      },
    ],
  },
  Default: {
    label: "Write Off",
    options: [
      {
        value: "corralon",
        label: "Corralón",
      },
      {
        value: "stolen",
        label: "Robada a Ozoner",
      },
      {
        value: "fraud",
        label: "Fraude",
      },
      {
        value: "writeOffB",
        label: "Write Off B",
      },
      {
        value: "judicial_collection",
        label: "Cobranza Judicial",
      },
    ],
  },
};


export const getTotalValues = (firstArray: any[], secondArray: any[]) => {
  const result = {};
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < secondArray.length; i++) {
    if (Array.isArray(secondArray[i].options)) {
      const nestedResult = getTotalValues(firstArray, secondArray[i].options);
      for (const key in nestedResult) {
        if (result.hasOwnProperty(key)) {
          // @ts-ignore
          result[key] += nestedResult[key];
        } else {
          // @ts-ignore
          result[key] = nestedResult[key];
        }
      }
    } else {
      const value = secondArray[i];
      if (firstArray.includes(value.value)) {
        // @ts-ignore
        result[value.value] = value.total;
      }
    }
  }
  return result;
};
