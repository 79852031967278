/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import React, { useCallback, useEffect, useState } from "react";
import "./Credits.scss";

import {
    Typography,
    SideModal,
    Button,
    DateRange,
    TableMode,
    ModalConfirmation as Modal,
    Knob,
    Pager,
    Input,
    ListTable,
    Popup,
    Select,
    DateSelect,
    CheckboxInput,
} from "design_system/src";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Ozoner from "views/ozoner/Ozoner";
import {
    getCreditsFilter,
    getWriteOffFilter,
    setCreditsFilter,
    setWriteOffFilter,
} from "helpers/localStorage/filterStorage";
import { editOzoner } from "helpers/fetchs/ozoners";
import profileDefaultOzoner from "static/images/default_profile_ozoner.png";

import { ReactComponent as AddCircle } from "design_system/src/static/icons/add-circle.svg";

import { ReactComponent as Right } from "design_system/src/static/icons/chevron-right.svg";
import { ReactComponent as Document } from "design_system/src/static/icons/document.svg";
import { ReactComponent as Calendar } from "design_system/src/static/icons/date.svg";
import { ReactComponent as Clock } from "design_system/src/static/icons/clock.svg";
import { ReactComponent as Close } from "design_system/src/static/icons/close-circle.svg";
import { ReactComponent as List } from "design_system/src/static/icons/bulleted-list-2.svg";
import { ReactComponent as Boxes } from "design_system/src/static/icons/box_2.svg";
import { ReactComponent as Excel } from "design_system/src/static/icons/excel.svg";
import motoLoading from "static/icons/loading_moto.gif";

import { LightTooltip } from "components/hocs/tooltip/Tooltip";
import { ReactComponent as Pin } from "design_system/src/static/icons/pin.svg";
import { ReactComponent as Action } from "design_system/src/static/icons/more-v.svg";
import { getUser } from "helpers/localStorage/authStorage";
import {
    addUserFilter,
    deleteUserFilter,
    getOzonioById,
} from "helpers/fetchs/ozonios";
import { FilterSections, UserFilter } from "models/ozonio.interface";
import SaveFilter from "components/hocs/saveFilter/SaveFilter";
import { statusOzoners } from "models/ozoner.interface";
import { priceFormatFinancial } from "helpers/prices/prices";
import { isArray } from "lodash";
import { Divider, Grid } from "@mui/material";
import { DocumentTypesEnum } from "components/hocs/loadDocuments/loadDocuments";
import { handleEventGoogle } from "helpers/analytics/googleAnalytics";
import {
    ActivateCredit,
    DeleteCredit,
    FetchCredits,
} from "../../helpers/fetchs/credits";
import {
    CreditStatus,
    CreditStatusFlags,
    CreditStatusFlagsValues,
    CreditStatusValues,
    TCredit,
} from "../../models/credit.interface";
import SearchBar from "../../components/hocs/searchBar/searchBar";
import ExportCredits from "./ExportCredit";

let numFetch = 0;
const classOptions = ["AAA", "AA", "A", "B", "C"];
const classLevel: any = {
    AAA: { value: 100, color: "green_300" },
    AA: { value: 80, color: "green_300" },
    A: { value: 60, color: "green_300" },
    B: { value: 40, color: "yellow_100" },
    C: { value: 20, color: "red_300" },
};

const filterOptions = [
    {
        _id: "pending_credits",
        name: "Créditos por verificar",
        values: {
            statusFilter: [CreditStatus.pending],
            verifiedFilter: ["false"],
        },
        default: true,
        writeOff: false,
    },
    {
        _id: "active_credits",
        name: "Créditos activos",
        values: {
            statusFilter: [
                CreditStatus.current,
                CreditStatus.earlyDefault,
                CreditStatus.DPD_30,
                CreditStatus.DPD_60,
                CreditStatus.DPD_90,
                CreditStatus.DPD_120,
            ],
        },
        default: true,
        writeOff: false,
    },
    {
        _id: "inactive_credits",
        name: "Créditos inactivos",
        values: {
            statusFilter: [
                CreditStatus.warranty,
                CreditStatus.devolution,
                CreditStatus.repossession,
                CreditStatus.complete,
                CreditStatus.canceled,
            ],
        },
        default: true,
        writeOff: false,
    },
];

const sortOptions = [
    {
        value: "createdAt",
        label: "Más antiguos",
    },
    {
        value: "-createdAt",
        label: "Más recientes",
    },
];
const verifiedOptions = [
    {
        value: "true",
        label: "Verificado",
    },
    {
        value: "false",
        label: "Sin verificar",
    },
];

const getStatusFilterName = (status: string) =>
    `statusPersonalFilter_${status}`;
const Credits = ({ writeOff = false }: { writeOff?: boolean }) => {
    const [data, setData] = useState<TCredit[]>([]);

    const [viewType, setViewType] = useState(true);

    const [openExport, setOpenExport] = useState<boolean>(false);
    const [openDelete, setOpenDelete] = useState<boolean>(false);
    const [openActivate, setOpenActivate] = useState<boolean>(false);

    const [loadingActivate, setLoadingActivate] = useState(false);

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pages, setPages] = useState<number>();
    const [totals, setTotals] = useState<any>();
    const [loading, setLoading] = useState(false);
    const [query, setQuery] = useState<string>("");
    const navigate = useNavigate();

    // -----------------FILTERS STATES------------------- //
    const [sortValue, setSortValue] = useState("-createdAt");
    const [openConfirmFilter, setOpenConfirmFilter] = useState(false);
    const [callbackConfirm, setCallbackConfirm] = useState<any>();
    const [openOptions, setOpenOptions] = useState("");
    const [personalFilters, setPersonalFilters] = useState<UserFilter[]>([]);
    const [selectedPersonalFilter, setSelectedPersonalFilter] =
        useState<string>("");
    const [openSaveFilter, setOpenSaveFilter] = useState<boolean>(false);
    const [scoreFilter, setScoreFilter] = useState<string[]>([]);
    const [verifiedFilter, setVerifiedFilter] = useState<string[]>([]);
    const [flagFilter, setFlagFilter] = useState<string[]>([]);
    const [statusFilter, setStatusFilter] = useState<string[]>([]);
    const [datesFilter, setDatesFilter] = useState({
        initial: new Date(),
        last: new Date(),
        option: -1,
    });
    const [hasDatesFilter, setHasDatesFilter] = useState(false);

    const [count, setCount] = useState(0);

    

    const requestFilters = () => {
        const userL = getUser();
        getOzonioById(userL.userId).then((ozonio) => {
            setPersonalFilters([
                ...filterOptions.filter(
                    (filter) => !!filter.writeOff == writeOff
                ),
                ...(ozonio?.personalFilters?.filter(
                    (fil: UserFilter) =>
                        fil.section ===
                        (writeOff
                            ? FilterSections.creditsWriteOffList
                            : FilterSections.creditsList)
                ) ?? []),
            ]);
        });
    };
    const changePersonalFilter = (uFilter: UserFilter) => {
        if (uFilter._id !== selectedPersonalFilter) {
            setSelectedPersonalFilter(uFilter._id);
            setScoreFilter(uFilter.values.scoreFilter ?? []);
            setVerifiedFilter(uFilter.values.verifiedFilter ?? []);
            setFlagFilter(uFilter.values.flagFilter ?? []);
            setStatusFilter(uFilter.values.statusFilter ?? []);
            if (uFilter.values.datesFilter) {
                setDatesFilter({
                    ...uFilter.values.datesFilter,
                    initial: new Date(uFilter.values.datesFilter.initial),
                    last: new Date(uFilter.values.datesFilter.last),
                });
                setHasDatesFilter(true);
            } else {
                setHasDatesFilter(false);
            }
        }
    };

    const setStatusPersonalFilter = (value: string) => {
        const name = getStatusFilterName(value);
        setSelectedPersonalFilter(name);
        setScoreFilter([]);
        setVerifiedFilter([]);
        setFlagFilter([]);
        setHasDatesFilter(false);
        setStatusFilter([value]);
    };

    const deletePersonalFilter = (uFilter: UserFilter) => {
        deleteUserFilter(getUser().userId, uFilter._id).then(() => {
            setSelectedPersonalFilter("");
            requestFilters();
        });
    };

    const confirmChangeFilter = () => {
        const { type, value } = callbackConfirm;
        if (type) {
            setStatusPersonalFilter(value);
        } else {
            changePersonalFilter(value);
        }
    };

    const changingFilter = (type: boolean, value: any) => {
        if (
            selectedPersonalFilter ||
            (!scoreFilter.length &&
                !verifiedFilter.length &&
                !flagFilter.length &&
                !statusFilter.length &&
                !hasDatesFilter)
        ) {
            if (type) {
                setStatusPersonalFilter(value);
            } else {
                changePersonalFilter(value);
            }
        } else {
            setCallbackConfirm({ type, value });
            setOpenConfirmFilter(true);
        }
    };

    const saveFilter = (name: string) => {
        return addUserFilter(
            getUser().userId,
            name,
            writeOff
                ? FilterSections.creditsWriteOffList
                : FilterSections.creditsList,
            {
                statusFilter,
                scoreFilter,
                verifiedFilter,
                flagFilter,
                datesFilter: hasDatesFilter ? datesFilter : undefined,
            }
        ).then((e: any) => {
            requestFilters();
            setSelectedPersonalFilter(
                e.personalFilters[e.personalFilters.length - 1]._id
            );
            return e;
        });
    };

    const asyncFetchCredits = async (page: number) => {
        try {
            setLoading(true);
            const a = numFetch + 1;
            numFetch += 1;

            const filters = [];

            if (
                scoreFilter.length &&
                scoreFilter.length !== classOptions.length
            )
                filters.push(`classification=${scoreFilter}`);
            if (
                verifiedFilter.length &&
                verifiedFilter.length !== verifiedOptions.length
            )
                filters.push(`verified=${verifiedFilter}`);
            if (flagFilter.length) {
                flagFilter.forEach((flag) => {
                    filters.push(`${flag}=true`);
                });
            }
            if (
                statusFilter.length &&
                statusFilter.length !==
                    Object.keys(CreditStatusValues).filter(
                        (status) =>
                            !!CreditStatusValues[status as CreditStatus]
                                .writeOff === writeOff
                    ).length
            )
                filters.push(`status=${statusFilter}`);
            else {
                filters.push(
                    `status=${Object.keys(CreditStatusValues)
                        .filter(
                            (status) =>
                                !!CreditStatusValues[status as CreditStatus]
                                    .writeOff === writeOff
                        )
                        .join(",")}`
                );
            }

            if (datesFilter && hasDatesFilter) {
                filters.push(
                    `min=${moment
                        .utc(datesFilter.initial)
                        .format("YYYY-MM-DD")}&max=${moment
                        .utc(datesFilter.last)
                        .format("YYYY-MM-DD")}`
                );
            }

            if (sortValue && sortValue !== "") {
                filters.push(`sort=${sortValue}`);
            }
            const rows = await FetchCredits(
                page,
                undefined,
                query,
                filters.join("&"),
                "ozoner,vehicle,code,status,createdAt,activatedAt,payments,financialFormId,creditClassification,isCorralon,isStolen,isFraud,isJudicialCollection"
            );

            if (a === numFetch) {
                setData(rows.data);
                setPages(rows.pages);
                setTotals(rows.totals);
                setCount(rows.count);
                setLoading(false);
            }
        } catch (e) {
            setLoading(false);
            setData([]);
            //  TODO show error message
        }
    };
    
    const handlePageChange = (page: number) => {
            setCurrentPage(page);
        };
    useEffect(() => {
        if (currentPage === 1){
            asyncFetchCredits(currentPage);
        }
        else{
            handlePageChange(1);
        }
    }, [
        statusFilter,
        scoreFilter,
        verifiedFilter,
        flagFilter,
        datesFilter,
        hasDatesFilter,
        sortValue,
        writeOff,
    ]);
    useEffect(() => {
        asyncFetchCredits(currentPage);
    }, [
        currentPage,
    ]);


    useEffect(() => {
        handleEventGoogle(
            writeOff ? "VIEW_Panda_credits_write_off" : "VIEW_Panda_credits",
            "Panda",
            "page view to the credits list view"
        );
        const information = writeOff ? getWriteOffFilter() : getCreditsFilter();
        if (information.query) {
            setQuery(information.query);
        }
        if (information.statusFilter) {
            setStatusFilter(information.statusFilter);
        }
        if (information.currentPage) {
            setCurrentPage(information.currentPage);
        }
        if (information.selectedPersonalFilter) {
            setSelectedPersonalFilter(information.selectedPersonalFilter);
        }
        if (information.scoreFilter) {
            setScoreFilter(information.scoreFilter);
        }
        if (information.verifiedFilter) {
            setVerifiedFilter(information.verifiedFilter);
        }
        if (information.flagFilter) {
            setFlagFilter(information.flagFilter);
        }
        if (information?.datesFilter) {
            setDatesFilter({
                ...information.datesFilter,
                initial: new Date(information.datesFilter.initial),
                last: new Date(information.datesFilter.last),
            });

            setHasDatesFilter(true);
        }
        requestFilters();
    }, [writeOff]);

    useEffect(() => {
        (writeOff ? setWriteOffFilter : setCreditsFilter)({
            query,
            statusFilter,
            currentPage,
            scoreFilter,
            verifiedFilter,
            flagFilter,
            datesFilter: hasDatesFilter ? datesFilter : null,
            selectedPersonalFilter,
        });
    }, [
        query,
        currentPage,
        statusFilter,
        scoreFilter,
        verifiedFilter,
        flagFilter,
        datesFilter,
        hasDatesFilter,
        selectedPersonalFilter,
    ]);

    const goToCredit = (credit: TCredit) => {
        navigate(
            `/ozoner/${credit?.ozoner?._id}?tab=${credit.status === CreditStatus.pending?"2":"4"}&formId=${credit.financialFormId}`
        );
    };

    const handleSearch = (event: any) => {
        event.preventDefault();
        // setFinQuery(query);
        asyncFetchCredits(currentPage);
    };

    return (
        <div className="dim_100_per flex_content_col shadow_hard inner_container bg_neutral_400">
            <div className="flex_header">
                <div className="bg_neutral_0 shadow_hard p_t_xs p_b_md p_x_lg">
                    <div className="display_flex flex_gap_sm flex_align_center">
                        <Typography
                            scale="heading3"
                            weight="600"
                            textColor="neutral_900"
                        >
                            {writeOff ? "Cartera castigada" : "Créditos"}
                        </Typography>
                        <SearchBar
                            className="flex_grow_1"
                            handleSearch={handleSearch}
                            query={query}
                            setQuery={setQuery}
                            sortOptions={sortOptions}
                            sortValue={sortValue}
                            setsortValue={setSortValue}
                        />
                    </div>
                    <div className="display_flex flex_gap_xl m_b_xs">
                        <div className="display_flex flex_gap_xl flex_grow_1 flex_align_start">
                            <Select
                                fixedPlaceholder
                                variant="checkbox"
                                placeholder="Estado"
                                options={Object.keys(CreditStatusValues)
                                    .filter(
                                        (status) =>
                                            !!CreditStatusValues[
                                                status as CreditStatus
                                            ].writeOff === writeOff
                                    )
                                    .map((key: any) => ({
                                        label: CreditStatusValues[
                                            key as CreditStatus
                                        ].title,
                                        value: key,
                                    }))}
                                value={statusFilter}
                                onChange={(e: any) => {
                                    setStatusFilter(e.target.value);
                                    setSelectedPersonalFilter("");
                                }}
                            />
                            <Select
                                fixedPlaceholder
                                variant="checkbox"
                                placeholder="Calificación"
                                options={classOptions}
                                value={scoreFilter}
                                onChange={(e: any) => {
                                    setScoreFilter(e.target.value);
                                    setSelectedPersonalFilter("");
                                }}
                            />
                            <Select
                                fixedPlaceholder
                                variant="checkbox"
                                placeholder="Verificacion"
                                options={verifiedOptions}
                                value={verifiedFilter}
                                onChange={(e: any) => {
                                    setVerifiedFilter(e.target.value);
                                    setSelectedPersonalFilter("");
                                }}
                            />
                            <Select
                                fixedPlaceholder
                                variant="checkbox"
                                placeholder="Etiqueta"
                                options={Object.keys(
                                    CreditStatusFlagsValues
                                ).map((key) => ({
                                    value: key,
                                    label:
                                        CreditStatusFlagsValues[
                                            key as CreditStatusFlags
                                        ]?.title ?? key,
                                }))}
                                value={flagFilter}
                                onChange={(e: any) => {
                                    setFlagFilter(e.target.value);
                                    setSelectedPersonalFilter("");
                                }}
                            />
                            <DateSelect
                                value={datesFilter}
                                hasValue={hasDatesFilter}
                                setHasValue={setHasDatesFilter}
                                placeholder="Fecha de creación"
                                setValue={(e: any) => {
                                    setDatesFilter(e);
                                    setHasDatesFilter(true);
                                    setSelectedPersonalFilter("");
                                }}
                            />
                        </div>
                        <div className="display_flex flex_gap_xs flex_grow_0 flex_align_center">
                            <Button
                                variant="ghost"
                                scale="small"
                                icon={<Pin />}
                                onClick={() => setOpenSaveFilter(true)}
                            >
                                Fijar filtro
                            </Button>
                            <LightTooltip title="Vista de tabla">
                                <div
                                    className={`selectable_icon ${
                                        viewType ? "active" : ""
                                    }`}
                                    role="button"
                                    tabIndex={0}
                                    onClick={() => setViewType(true)}
                                    onKeyDown={() => setViewType(true)}
                                >
                                    <List className="dim_lg" />
                                </div>
                            </LightTooltip>
                            <LightTooltip title="Vista en cards">
                                <div
                                    className={`selectable_icon ${
                                        !viewType ? "active" : ""
                                    }`}
                                    role="button"
                                    tabIndex={0}
                                    onClick={() => setViewType(false)}
                                    onKeyDown={() => setViewType(false)}
                                >
                                    <Boxes className="dim_lg" />
                                </div>
                            </LightTooltip>
                            <LightTooltip title="Exportar creditos">
                                <div
                                    className="selectable_icon"
                                    role="button"
                                    tabIndex={0}
                                    onClick={() => setOpenExport(true)}
                                    onKeyDown={() => setOpenExport(true)}
                                >
                                    <Excel className="dim_lg" />
                                </div>
                            </LightTooltip>
                        </div>{" "}
                    </div>
                    <div className="display_flex flex_gap_xl overflow_x_auto p_b_md">
                        {personalFilters.map((uFilter) => (
                            <div
                                className={`display_flex flex_gap_sm p_x_md p_y_xs status_tab pos_relative ${
                                    selectedPersonalFilter === uFilter._id
                                        ? "active"
                                        : ""
                                }`}
                            >
                                <Typography
                                    scale="small"
                                    weight={
                                        selectedPersonalFilter === uFilter._id
                                            ? "600"
                                            : "400"
                                    }
                                    onClick={() =>
                                        changingFilter(false, uFilter)
                                    }
                                    key={`status-filter-${uFilter._id}`}
                                    className="cursor_pointer "
                                >
                                    {uFilter.name}
                                </Typography>
                                {!uFilter.default && (
                                    <>
                                        <Action
                                            className="dim_md cursor_pointer text_neutral_600"
                                            onClick={() =>
                                                setOpenOptions(uFilter._id)
                                            }
                                        />
                                        <Popup
                                            open={openOptions === uFilter._id}
                                            setOpen={() => setOpenOptions("")}
                                            placementX="right-inset"
                                            className="bg_neutral_100 br_xxs m_r_md"
                                        >
                                            <Typography
                                                scale="xsmall"
                                                weight="400"
                                                className="p_xs border_b_solid border_b_1 border_neutral_400 cursor_pointer menu_selectable"
                                                onClick={() =>
                                                    deletePersonalFilter(
                                                        uFilter
                                                    )
                                                }
                                                onKeyPress={() =>
                                                    deletePersonalFilter(
                                                        uFilter
                                                    )
                                                }
                                                tabIndex={0}
                                                role="button"
                                            >
                                                Eliminar filtro
                                            </Typography>
                                        </Popup>
                                    </>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div
                className={`flex_body ${
                    viewType ? "p_x_lg p_t_md" : "body_listing"
                }  overflow_x_hidden`}
            >
                {loading ? (
                    <div className="flex_center_col dim_100_per">
                        <Typography
                            weight="600"
                            scale="heading4"
                            textColor="neutral_800"
                            className="m_b_xs"
                        >
                            Cargando los créditos...
                        </Typography>
                        <img src={motoLoading} alt="" className="h_xxxl" />
                    </div>
                ) : data?.length ? (
                    viewType ? (
                        <ListTable
                            hideHeader
                            onRowClick={goToCredit}
                            data={data}
                            canSelect={false}
                            cols={[
                                {
                                    label: "Foto",
                                    render: (row) => (
                                        <div className="display_flex flex_gap_md flex_align_center">
                                            <div className="flex_center">
                                                <div
                                                    className={` border_solid border_4 w_fit br_sm bg_neutral_200 display_flex overflow_hidden border_${
                                                        (statusOzoners as any)[
                                                            row?.ozoner?.status
                                                        ]?.color
                                                    }`}
                                                >
                                                    <img
                                                        src={
                                                            row.ozoner?.image
                                                                ?.url ||
                                                            // selfie ||
                                                            profileDefaultOzoner
                                                        }
                                                        alt=""
                                                        className="dim_xxl"
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <Typography
                                                    scale="small"
                                                    weight="600"
                                                >
                                                    {row.ozoner?.name ??
                                                        "Sin nombre"}
                                                </Typography>
                                                <div className="flex_center flex_gap_xs w_fit">
                                                    {row.vehicle ? (
                                                        <>
                                                            <Typography
                                                                scale="xsmall"
                                                                weight="600"
                                                                textColor="neutral_900"
                                                            >
                                                                {
                                                                    row.vehicle
                                                                        ?.internalId
                                                                }
                                                            </Typography>
                                                            <Typography
                                                                scale="xsmall"
                                                                weight="400"
                                                                textColor="neutral_600"
                                                            >
                                                                {`${
                                                                    row.vehicle?.brand
                                                                        ?.name ??
                                                                    ""
                                                                } ${
                                                                    row.vehicle
                                                                        ?.model
                                                                        ?.name ??
                                                                    ""
                                                                } ${
                                                                    row.vehicle
                                                                        ?.cylindersCapacity ??
                                                                    ""
                                                                } ${
                                                                    row.vehicle
                                                                        ?.suffix ??
                                                                    ""
                                                                }`}
                                                            </Typography>
                                                        </>
                                                    ) : (
                                                        <Typography
                                                            scale="small"
                                                            weight="400"
                                                            textColor="neutral_600"
                                                        >
                                                            sin datos de
                                                            vehiculo
                                                        </Typography>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="display_flex flex_align_center flex_wrap flex_gap_xs">
                                                {Object.keys(
                                                    CreditStatusFlagsValues
                                                )
                                                    .filter((key) => (row as any)[key])
                                                    .map((key) => (
                                                        <LightTooltip
                                                            title={
                                                                CreditStatusFlagsValues[
                                                                    key as CreditStatusFlags
                                                                ]?.tooltip ??
                                                                key
                                                            }
                                                        >
                                                            <div>
                                                                <Typography
                                                                    scale="xxsmall"
                                                                    weight="600"
                                                                    className="br_xxs p_x_xs p_y_xxxs text_center text_red_300 pos_relative bg_red_200_nocontrast text_no_break"
                                                                >
                                                                    {CreditStatusFlagsValues[
                                                                        key as CreditStatusFlags
                                                                    ]?.title ??
                                                                        key}
                                                                </Typography>
                                                            </div>
                                                        </LightTooltip>
                                                    ))}
                                            </div>
                                            <div className="flex_grow_1 flex_justify_end display_flex">
                                                {row.status ===
                                                CreditStatus.pending ? (
                                                    <div className="br_xxs bg_neutral_200 flex_gap_xl p_x_xl p_y_xs w_fit br_sm">
                                                        <div>
                                                            <Typography
                                                                scale="small"
                                                                weight="600"
                                                                textColor="neutral_900"
                                                                className="text_no_break text_center"
                                                            >
                                                                {row.createdAt
                                                                    ? moment(
                                                                          row.createdAt
                                                                      )
                                                                          .utcOffset(
                                                                              -360
                                                                          )
                                                                          .locale(
                                                                              "es"
                                                                          )
                                                                          .format(
                                                                              "DD/MM/YYYY"
                                                                          )
                                                                    : "--"}
                                                            </Typography>
                                                            <Typography
                                                                scale="xsmall"
                                                                weight="400"
                                                                textColor="neutral_600"
                                                                className="text_no_break text_center"
                                                            >
                                                                Creación
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="br_xxs bg_neutral_200 flex_gap_xl p_x_xl p_y_xs flex_center w_fit br_sm">
                                                        <div>
                                                            <Typography
                                                                scale="small"
                                                                weight="600"
                                                                textColor="neutral_900"
                                                                className="text_no_break text_center"
                                                            >
                                                                {row.createdAt
                                                                    ? moment(
                                                                          row.createdAt
                                                                      )
                                                                          .utcOffset(
                                                                              -360
                                                                          )
                                                                          .locale(
                                                                              "es"
                                                                          )
                                                                          .format(
                                                                              "DD/MM/YYYY"
                                                                          )
                                                                    : "--"}
                                                            </Typography>
                                                            <Typography
                                                                scale="xsmall"
                                                                weight="400"
                                                                textColor="neutral_600"
                                                                className="text_no_break text_center"
                                                            >
                                                                Creación
                                                            </Typography>
                                                        </div>
                                                        <div className="table_divider h_xl" />
                                                        <div>
                                                            <Typography
                                                                scale="small"
                                                                weight="600"
                                                                textColor="neutral_900"
                                                                className="text_no_break text_center"
                                                            >
                                                                {row.activatedAt
                                                                    ? moment(
                                                                          row.activatedAt
                                                                      )
                                                                          .utcOffset(
                                                                              -360
                                                                          )
                                                                          .locale(
                                                                              "es"
                                                                          )
                                                                          .format(
                                                                              "DD/MM/YYYY"
                                                                          )
                                                                    : "--"}
                                                            </Typography>
                                                            <Typography
                                                                scale="xsmall"
                                                                weight="400"
                                                                textColor="neutral_600"
                                                                className="text_no_break text_center"
                                                            >
                                                                Activación
                                                            </Typography>
                                                        </div>
                                                        <div className="table_divider h_xl" />
                                                        <div>
                                                            <Typography
                                                                scale="small"
                                                                weight="600"
                                                                textColor="neutral_900"
                                                                className="text_no_break text_center"
                                                            >
                                                                {row.code ??
                                                                    "N/D"}
                                                            </Typography>
                                                            <Typography
                                                                scale="xsmall"
                                                                weight="400"
                                                                textColor="neutral_600"
                                                                className="text_no_break text_center"
                                                            >
                                                                # contrato
                                                            </Typography>
                                                        </div>
                                                        <div className="table_divider h_xl" />
                                                        <div>
                                                            <Typography
                                                                scale="small"
                                                                weight="600"
                                                                textColor="green_300"
                                                                className="text_no_break text_center"
                                                            >
                                                                {priceFormatFinancial(
                                                                    row.totalPaid,
                                                                    0
                                                                )}
                                                            </Typography>
                                                            <Typography
                                                                scale="xsmall"
                                                                weight="400"
                                                                textColor="neutral_600"
                                                                className="text_no_break text_center"
                                                            >
                                                                Pagado
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                                <div className="flex_center flex_gap_xs flex_shrink_0">
                                                    <div className="flex_center_col">
                                                        <Typography
                                                            scale="xsmall"
                                                            weight="400"
                                                            textColor={
                                                                classLevel[
                                                                    row
                                                                        .creditClassification
                                                                ]?.color ??
                                                                "red_300"
                                                            }
                                                            className="text_no_break text_center"
                                                        >
                                                            Crédito:{" "}
                                                            {
                                                                row.creditClassification
                                                            }
                                                        </Typography>
                                                        <Knob
                                                            value={
                                                                classLevel[
                                                                    row
                                                                        .creditClassification
                                                                ]?.value ?? 0
                                                            }
                                                            variant="gauge"
                                                            symbol="%"
                                                            color={
                                                                classLevel[
                                                                    row
                                                                        .creditClassification
                                                                ]?.color ??
                                                                "red_300"
                                                            }
                                                            className="dim_xxxl"
                                                        />
                                                    </div>
                                                    <LightTooltip
                                                        title={
                                                            CreditStatusValues[
                                                                row?.status as keyof typeof CreditStatus
                                                            ]?.tooltip ??
                                                            row?.status
                                                        }
                                                    >
                                                        <div
                                                            className={`
                                            dso_chip_small 
                                            bg_${
                                                CreditStatusValues[
                                                    row?.status as keyof typeof CreditStatus
                                                ]?.color
                                            }_nocontrast text_neutral_0 overflow_hidden overflow_ellipsis text_center credit_table_status
                                            `}
                                                        >
                                                            {CreditStatusValues[
                                                                row?.status as keyof typeof CreditStatus
                                                            ]?.title ??
                                                                row?.status}
                                                        </div>
                                                    </LightTooltip>
                                                </div>
                                        </div>
                                    ),
                                },
                            ]}
                        />
                    ) : (
                        <Grid
                            container
                            direction="row"
                            spacing={3}
                            className=" p_t_md spaced"
                        >
                            {data.map((credit, index) => (
                                <Grid item xs={6} sm={3} md={2} key={index}>
                                    <div
                                        className="dso_card flex_center_col flex_justify_between bg_neutral_0 dim_100_per p_x_xs p_y_sm cursor_pointer ozoner_card"
                                        onClick={() => goToCredit(credit)}
                                        onKeyPress={() => goToCredit(credit)}
                                        role="button"
                                        tabIndex={0}
                                    >
                                        <div className="flex_center_col w_100_per">
                                            {credit.status !==
                                                CreditStatus.pending && (
                                                <>
                                                    <div className="display_flex flex_justify_between w_100_per flex_align_center ">
                                                        <div className="">
                                                            <Typography
                                                                scale="xsmall"
                                                                weight="600"
                                                                textColor="neutral_900"
                                                                className="text_no_break text_center"
                                                            >
                                                                ID:{" "}
                                                                {credit.code ??
                                                                    "N/D"}
                                                            </Typography>
                                                            <Typography
                                                                scale="xsmall"
                                                                weight="400"
                                                                textColor={
                                                                    classLevel[
                                                                        credit
                                                                            .creditClassification
                                                                    ]?.color ??
                                                                    "red_300"
                                                                }
                                                                className="text_no_break text_center"
                                                            >
                                                                Crédito:{" "}
                                                                {
                                                                    credit.creditClassification
                                                                }
                                                            </Typography>
                                                        </div>
                                                        <div className="display_flex flex_gap_xs flex_align_center">
                                                            <Knob
                                                                value={
                                                                    classLevel[
                                                                        credit
                                                                            .creditClassification
                                                                    ]?.value ??
                                                                    0
                                                                }
                                                                variant="gauge"
                                                                symbol="%"
                                                                color={
                                                                    classLevel[
                                                                        credit
                                                                            .creditClassification
                                                                    ]?.color ??
                                                                    "red_300"
                                                                }
                                                                className="dim_xxxl"
                                                            />
                                                        </div>
                                                    </div>
                                                    <Divider className="w_100_per m_y_xs" />
                                                </>
                                            )}
                                            <div className="dso_card_img m_b_md bg_neutral_200 display_flex overflow_hidden border_green_300">
                                                <img
                                                    src={
                                                        credit.ozoner?.image
                                                            ?.url ||
                                                        credit?.ozoner?.documents?.filter(
                                                            (ele: any) =>
                                                                ele.typeEvidence ===
                                                                DocumentTypesEnum.selfie
                                                        )[0] ||
                                                        profileDefaultOzoner
                                                    }
                                                    alt=""
                                                    className="cards_photo"
                                                />
                                            </div>
                                            <Typography
                                                scale="small"
                                                weight="600"
                                                className="text_center text_uppercase text_neutral_900 m_b_sm"
                                            >
                                                {credit.ozoner?.name}
                                            </Typography>
                                            <Typography
                                                scale="medium"
                                                weight="600"
                                                textColor="neutral_900"
                                                className="text_no_break text_center"
                                            >
                                                {credit.createdAt
                                                    ? moment(credit.createdAt)
                                                          .utcOffset(-360)
                                                          .locale("es")
                                                          .format("DD/MM/YYYY")
                                                    : "--"}
                                            </Typography>
                                            <Typography
                                                scale="small"
                                                weight="400"
                                                textColor="neutral_600"
                                                className="text_no_break text_center"
                                            >
                                                Fecha de creación
                                            </Typography>
                                            {credit.status !==
                                                CreditStatus.pending && (
                                                <>
                                                    <Typography
                                                        scale="medium"
                                                        weight="600"
                                                        textColor="neutral_900"
                                                        className="text_no_break text_center m_t_md"
                                                    >
                                                        {credit.activatedAt
                                                            ? moment(
                                                                  credit.activatedAt
                                                              )
                                                                  .utcOffset(
                                                                      -360
                                                                  )
                                                                  .locale("es")
                                                                  .format(
                                                                      "DD/MM/YYYY"
                                                                  )
                                                            : "--"}
                                                    </Typography>
                                                    <Typography
                                                        scale="small"
                                                        weight="400"
                                                        textColor="neutral_600"
                                                        className="text_no_break text_center"
                                                    >
                                                        Fecha de activación
                                                    </Typography>
                                                    <Typography
                                                        scale="medium"
                                                        weight="600"
                                                        textColor="green_300"
                                                        className="text_no_break text_center"
                                                    >
                                                        {priceFormatFinancial(
                                                            credit.totalPaid,
                                                            0
                                                        )}
                                                    </Typography>
                                                    <Typography
                                                        scale="small"
                                                        weight="400"
                                                        textColor="neutral_600"
                                                        className="text_no_break text_center"
                                                    >
                                                        Pagado
                                                    </Typography>
                                                </>
                                            )}
                                            <LightTooltip
                                                title={
                                                    CreditStatusValues[
                                                        credit?.status as keyof typeof CreditStatus
                                                    ]?.tooltip ?? credit?.status
                                                }
                                            >
                                                <div
                                                    className={`
                                                dso_chip_small 
                                                bg_${
                                                    CreditStatusValues[
                                                        credit?.status as keyof typeof CreditStatus
                                                    ]?.color
                                                }_nocontrast text_neutral_0 overflow_hidden overflow_ellipsis text_center
                                                `}
                                                >
                                                    {CreditStatusValues[
                                                        credit?.status as keyof typeof CreditStatus
                                                    ]?.title ?? credit?.status}
                                                </div>
                                            </LightTooltip>
                                            <div className="flex_center flex_wrap flex_gap_xs m_t_md">
                                                {Object.keys(
                                                    CreditStatusFlagsValues
                                                )
                                                    .filter(
                                                        (key: any) =>
                                                            (credit as any)[key]
                                                    )
                                                    .map((key) => (
                                                        <LightTooltip
                                                            title={
                                                                CreditStatusFlagsValues[
                                                                    key as CreditStatusFlags
                                                                ]?.tooltip ??
                                                                key
                                                            }
                                                        >
                                                            <div>
                                                                <Typography
                                                                    scale="xxsmall"
                                                                    weight="600"
                                                                    className="br_xxs p_x_xs p_y_xxxs text_center text_red_300 pos_relative bg_red_200_nocontrast text_no_break"
                                                                >
                                                                    {CreditStatusFlagsValues[
                                                                        key as CreditStatusFlags
                                                                    ]?.title ??
                                                                        key}
                                                                </Typography>
                                                            </div>
                                                        </LightTooltip>
                                                    ))}
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                            ))}
                        </Grid>
                    )
                ) : (
                    <div className="flex_center dim_100_per">
                        <Typography
                            scale="heading4"
                            weight="600"
                            className="text_neutral_900 m_xl text_center"
                        >
                            Tus filtros no generaron resultados
                        </Typography>
                    </div>
                )}
            </div>

            <div className="display_flex flex_align_center flex_justify_between flex_footer bg_neutral_0 p_x_xxxl p_y_md">
                <div className="display_flex flex_gap_md"/>

                <Pager
                    totalPages={pages ?? 0}
                    setPage={handlePageChange}
                    page={currentPage}
                    className="w_fit"
                    count={count}
                />
            </div>
            {openExport && (
                <ExportCredits
                    open={openExport}
                    setOpen={setOpenExport}
                    currentPage={currentPage}
                    currentStatus={statusFilter}
                />
            )}
            {(openSaveFilter || openConfirmFilter) && (
                <SaveFilter
                    open={openSaveFilter}
                    setOpen={setOpenSaveFilter}
                    callback={saveFilter}
                    openConfirm={openConfirmFilter}
                    setOpenConfirm={setOpenConfirmFilter}
                    callbackConfirm={confirmChangeFilter}
                />
            )}
        </div>
    );
};

export default Credits;
