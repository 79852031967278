/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from "react";
import { Skeleton, Typography } from "design_system/src";
import { useNavigate } from "react-router-dom";

// import ozonerimg from "static/images/ozoner.svg";
import profileDefaultOzoner from "static/images/default_profile_ozoner.png";

import {
  statusOzoners,
  TDataozoner,
} from "models/ozoner.interface";

import "./cardozoner.scss";
import { ReactComponent as ContactS } from "design_system/src/static/icons/call-center-sad.svg";
import { ReactComponent as ContactH } from "design_system/src/static/icons/call-center-happy.svg";
import { ReactComponent as Monitor } from "design_system/src/static/icons/monitor.svg";
import { ReactComponent as Panda } from "design_system/src/static/icons/panda.svg";
import Divider from "@mui/material/Divider";

import { LightTooltip } from "../tooltip/Tooltip";

type Props = {
  ozoner?: TDataozoner;
  onClick?: Function | null;
  loading?: boolean;
};

function CardOzoner({ ozoner, loading = false, onClick = null }: Props) {
  const [selfie, setSelfie] = useState<any>("");

  useEffect(() => {
    if (ozoner) {
      if (ozoner.documents?.length) {
        const url = ozoner.documents.filter(
          (obj: any) => obj.typeEvidence === "selfie"
        );
        if (url.length > 0) {
          setSelfie(url[0].url);
        } else {
          setSelfie(null);
        }
      }
    }
  }, [ozoner]);

  const navigate = useNavigate();
  const handleDetailsOzo = () => {
    if (ozoner) {
      navigate(`/ozoner/${ozoner._id}`);
    }
  };

  return (
    <div
      className="dso_card flex_center_col flex_justify_between  bg_neutral_0 dim_100_per p_x_xs p_y_sm cursor_pointer ozoner_card"
      onClick={() => {
        if (!onClick) {
          handleDetailsOzo();
        } else {
          onClick(ozoner);
        }
      }}
      onKeyPress={handleDetailsOzo}
      role="button"
      tabIndex={0}
    >
      {ozoner && !loading ? (
        <div className="flex_center_col w_100_per">
          <div className="fit display_flex flex_gap_xs flex_align_center pos_relative w_100_per">
            <div
              className={`
          dso_chip_small flex_grow_1 
          bg_${(statusOzoners as any)[ozoner.status]?.color}_nocontrast 
          text_neutral_0 flex_basis_0 overflow_hidden overflow_ellipsis text_center
          `}
            >
              {(statusOzoners as any)[ozoner.status]?.title ?? "No registrado"}
            </div>

            <LightTooltip title={`Creado en ${ozoner?.createdOn}`}>
              <div className="bg_primary_50_nocontrast text_primary_300 display_flex p_xs br_xs shadow_hard">
                {ozoner.createdOn === "pandaboard" ? (
                  <Panda className="dim_md" />
                ) : (
                  <Monitor className="dim_md" />
                )}
              </div>
            </LightTooltip>
          </div>
          <Divider className="w_100_per m_y_xs" />

          <div
            className={`dso_card_img m_b_md bg_neutral_200 display_flex overflow_hidden border_${(statusOzoners as any)[ozoner.status]?.color
              }`}
          >
            <img
              src={ozoner?.image?.url || selfie || profileDefaultOzoner}
              alt=""
              className="cards_photo"
            />
          </div>
          <Typography
            scale="small"
            weight="600"
            className="text_center text_uppercase text_neutral_900 m_b_sm"
          >
            {ozoner.name}
          </Typography>
        </div>
      ) : (
        <>
          <Skeleton className="w_100_per  m_b_md" height={30} />
          <Skeleton className="m_b_lg" height={60} width={60} />
          <Skeleton className="m_b_xl" height={45} width={45} />
          <Skeleton className="w_80_per m_b_md" height={20} />
          <Skeleton className="w_60_per m_b_xxl" height={16} />
          <Skeleton className="w_80_per m_b_md" height={20} />
        </>
      )}
    </div>
  );
}

export default CardOzoner;
