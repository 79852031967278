/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";
// @ts-ignore
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import mapboxgl, { Map, MarkerOptions } from "mapbox-gl";
import { Grid } from "@mui/material";
import moment from "moment";

import { priceFormatFinancial } from "helpers/prices/prices";

import { Button, Input, Knob, Pager, Popup, ProgressBar, Typography } from "design_system/src";
import { LightTooltip } from "components/hocs/tooltip/Tooltip";

import { ReactComponent as Moto } from "design_system/src/static/icons/motorcycle.svg";
import { ReactComponent as AddCircle } from "design_system/src/static/icons/add-circle.svg";
import { ReactComponent as Signal } from "design_system/src/static/icons/radio-signal.svg";
import { ReactComponent as NoSignal } from "design_system/src/static/icons/no-radio-signal.svg";
import { ReactComponent as Home } from "design_system/src/static/icons/temple.svg";
import { ReactComponent as Hotspot } from "design_system/src/static/icons/fire-camp.svg";
import { ReactComponent as Action } from "design_system/src/static/icons/more-v.svg";

import { FetchRecoveryResponse, Route, RouteStatus, RouteStatusValues } from "models/repossession.interface";
import { toasterNotification } from "helpers/globalNotifications/globalNotifications";
import { toasterOptions } from "enums/toaster.enum";
import { FetchRecoveries, editRoute, sendRouteSlack } from "helpers/fetchs/repossession";

import RecoveryDetail from "../map/RecoveryDetail";
import { createCluster, createMarker } from "../map/markers";
import motoLoading from "../../../static/icons/loading_moto.gif";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API_KEY || "";

interface RoutesProps {
  routes?: Route[]
  fetchRoutes: Function;
  totalPages: number;
  count: number;
  setViewTab: Function;

  setSelecteds: Function;
  setRouteDate: Function;
  setRouteOzonio: Function;
  setOpenRuta: Function;
  setIdRuta: Function;
}

const Routes: React.FC<RoutesProps> = ({
  routes,
  fetchRoutes,
  totalPages,
  count,
  setViewTab,
  setSelecteds,
  setRouteDate,
  setRouteOzonio,
  setOpenRuta,
  setIdRuta,
}) => {
  const navigate = useNavigate();
  const [actualPage, setActualPage] = useState<number>(1);
  const [openAction, setOpenAction] = useState<Route>();

  const editRuta = async (ruta:Route)=>{
    const recoveries: FetchRecoveryResponse = await FetchRecoveries(ruta._id);

    setOpenAction(undefined);
    setViewTab(1);
    setSelecteds(recoveries.data.sort((a, b) => a.index - b.index));
    setRouteDate(moment.utc(ruta.dateEstimate).format("YYYY-MM-DD"));
    setRouteOzonio(ruta.teamLeader._id);
    setOpenRuta(true);
    setIdRuta(ruta._id);
  };

  const selectRuta = (ruta: Route) => {
    navigate(`/route/${ruta._id}`);
  };
  const cancelRoute = () => {
    setOpenAction(undefined);
    if(openAction){
      editRoute(openAction._id, {status:RouteStatus.canceled}).then(() => {
        fetchRoutes();
        toasterNotification({
          msg: "Se cancelo la ruta",
          toasterType: toasterOptions.success,
          style: "light",
        });
      }).catch(() => {
        toasterNotification({
          msg: "Ocurrio un error",
          toasterType: toasterOptions.error,
          style: "light",
        });
      });
    }
  };
  return (
      <>
          {/* Loader */}
          {!routes ? (
              <div className="loader_map flex_center">
                  <Typography
                      weight="600"
                      scale="medium"
                      textColor="neutral_0"
                      className="m_b_xs"
                  >
                      Cargando la información...
                  </Typography>
                  <img src={motoLoading} alt="" className="h_xxxl" />
              </div>
          ) : null}
          <div className="flex_body p_x_xxxl overflow_x_hidden bg_neutral_300">
              <Grid
                  container
                  direction="row"
                  spacing={3}
                  className="h_100_per p_t_md spaced"
              >
                  {routes?.map((route, i) => (
                      <Grid item xs={6} sm={3} md={3} key={i}>
                          <div className="br_sm bg_neutral_0">
                              <div className="display_flex flex_justify_between p_md p_b_xs border_b_solid border_neutral_300 border_1">
                                  <div>
                                      <Typography
                                          weight="600"
                                          scale="medium"
                                          textColor="neutral_1000"
                                          className=""
                                      >
                                          {moment
                                              .utc(route.dateEstimate)
                                              .locale("es")
                                              .format("dddd, D MMMM")}
                                      </Typography>
                                      <Typography
                                          weight="400"
                                          scale="small"
                                          textColor="neutral_600"
                                          className=""
                                      >
                                          {route.teamLeader.name}
                                      </Typography>
                                  </div>
                                  <div className="pos_relative">
                                      <Action
                                          className="dim_md cursor_pointer border_transparent"
                                          tabIndex={0}
                                          role="button"
                                          onKeyDown={() =>
                                              setOpenAction(
                                                  openAction ? undefined : route
                                              )
                                          }
                                          onClick={() =>
                                              setOpenAction(
                                                  openAction ? undefined : route
                                              )
                                          }
                                      />
                                      <Popup
                                          open={openAction?._id === route._id}
                                          setOpen={(action: boolean) =>
                                              setOpenAction(
                                                  action ? route : undefined
                                              )
                                          }
                                          placementX="left-inset"
                                          className="bg_neutral_100 br_xxs"
                                      >
                                          <div
                                              className="p_x_md p_y_xs action_option cursor_pointer"
                                              tabIndex={0}
                                              role="button"
                                              onKeyDown={() => editRuta(route)}
                                              onClick={() => editRuta(route)}
                                          >
                                              <Typography
                                                  scale="medium"
                                                  weight="400"
                                                  className="text_no_break"
                                              >
                                                  Editar ruta
                                              </Typography>
                                          </div>
                                          {/* <div className="p_x_md p_y_xs action_option cursor_pointer" tabIndex={0} role="button" onKeyDown={()=>{}} onClick={()=>{}}>
                            <Typography scale="medium" weight="400" className="text_no_break">Duplicar ruta</Typography>
                            </div> */}
                                          <div
                                              className="p_x_md p_y_xs action_option cursor_pointer"
                                              tabIndex={0}
                                              role="button"
                                              onKeyDown={() => cancelRoute()}
                                              onClick={() => cancelRoute()}
                                          >
                                              <Typography
                                                  scale="medium"
                                                  weight="400"
                                                  className="text_no_break"
                                              >
                                                  Cancelar ruta
                                              </Typography>
                                          </div>
                                      </Popup>
                                  </div>
                              </div>
                              <div className="p_md p_y_xs border_b_solid border_neutral_300 border_1">
                                  <div className="display_flex flex_gap_xs flex_align_center">
                                      <Moto className="dim_md" />
                                      <Typography
                                          weight="400"
                                          scale="medium"
                                          textColor="neutral_1000"
                                          className=""
                                      >
                                          {route.recoveries.length} motos a
                                          recuperar
                                      </Typography>
                                  </div>
                                  <div className="flex_center">
                                      <Typography
                                          weight="600"
                                          scale="small"
                                          textColor={
                                              RouteStatusValues[route.status]
                                                  .textColor as any
                                          }
                                          className={`bg_${
                                              RouteStatusValues[route.status]
                                                  .color
                                          }_nocontrast br_xl p_y_xxs p_x_sm m_y_xs`}
                                      >
                                          {
                                              RouteStatusValues[route.status]
                                                  .title
                                          }
                                      </Typography>
                                  </div>
                                  <div className="display_flex flex_justify_between">
                                      <div className="display_flex flex_gap_xs flex_align_center text_green_300">
                                          <Signal className="dim_md" />
                                          <Typography
                                              weight="600"
                                              scale="medium"
                                              className=""
                                          >
                                              {route.totals?.signal ?? 0} con
                                              señal
                                          </Typography>
                                      </div>
                                      <div className="display_flex flex_gap_xs flex_align_center text_red_300">
                                          <NoSignal className="dim_md" />
                                          <Typography
                                              weight="600"
                                              scale="medium"
                                              className=""
                                          >
                                              {route.totals?.noSignal ?? 0} sin
                                              señal
                                          </Typography>
                                      </div>
                                  </div>
                                  <div className="display_flex flex_justify_between m_t_xs">
                                      <div className="display_flex flex_gap_xs flex_align_center text_yellow_100">
                                          <Home className="dim_md" />
                                          <Typography
                                              weight="600"
                                              scale="medium"
                                              className=""
                                          >
                                              {route.totals?.address ?? 0}{" "}
                                              Hogares
                                          </Typography>
                                      </div>
                                      <div className="display_flex flex_gap_xs flex_align_center text_primary_300">
                                          <Hotspot className="dim_md" />
                                          <Typography
                                              weight="600"
                                              scale="medium"
                                              className=""
                                          >
                                              {route.totals?.hotspots ?? 0}{" "}
                                              Hotspots
                                          </Typography>
                                      </div>
                                  </div>
                              </div>
                              <div className="p_md">
                                  <Typography
                                      weight="400"
                                      scale="small"
                                      textColor="neutral_600"
                                      className=""
                                  >
                                      Recuperaciones completadas
                                  </Typography>
                                  <div className=" p_y_xs display_flex flex_align_center flex_gap_md m_b_xs">
                                      <Typography
                                          scale="small"
                                          weight="600"
                                          textColor="primary_300"
                                          className=""
                                      >
                                          {route.totals?.completed}/
                                          {route.recoveries?.length}{" "}
                                      </Typography>
                                      <ProgressBar
                                          containerClassName="bg_neutral_400 h_sm"
                                          fillClassName="bg_primary_300 h_sm"
                                          percentage={
                                              ((route.totals?.completed ?? 0) *
                                                  100) /
                                              route.recoveries.length
                                          }
                                      />
                                  </div>
                                  <Button
                                      scale="small"
                                      className="w_100_per"
                                      onClick={() => selectRuta(route)}
                                  >
                                      Ver ruta
                                  </Button>
                              </div>
                          </div>
                      </Grid>
                  ))}
              </Grid>
          </div>
          <div className="display_flex flex_align_center flex_justify_between flex_footer bg_neutral_0 p_x_xxxl p_y_md">
              <Button
                  scale="small"
                  icon={<AddCircle />}
                  className="w_30_per"
                  onClick={() => setViewTab(0)}
              >
                  Crear nueva ruta
              </Button>
              <Pager
                  totalPages={totalPages}
                  setPage={setActualPage}
                  page={actualPage}
                  count={count}
                  className="w_fit"
              />
          </div>
      </>
  );
};

export default Routes;
