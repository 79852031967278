import { RepossessionTotals, Repossession, InterestPointTypeValues, InterestPoint } from "models/repossession.interface";
import { RepossessionsActions, LocationsActions, types } from "store/types/types";
import { featureProperties } from "views/repossessions/Repossessions";

interface ReducerType{
  lastUpdate:number;
  data: {[key:string]:{repossession:Repossession,interestPoint:InterestPoint,id?:string,primary:boolean,isOnRoute?:boolean,isSelected?:boolean}} | undefined;
  recoveryTotals: RepossessionTotals | null;
  historicRecoveries: { [key: string]: Repossession };
}

const ini= {
  lastUpdate: 0,
  data: undefined,
  recoveryTotals: null,
  historicRecoveries:{}
};

// eslint-disable-next-line default-param-last
export const repossessionReducer = (state: ReducerType = ini, action: any):ReducerType => {
  switch (action.type) {
    case RepossessionsActions.load_repossession:
      return {
        ...state,
        lastUpdate: Date.now(),
        data: action.payload.data?.reduce(
          (
              acc:{[id:string]:featureProperties} ,
              repossession:Repossession
          ) => {
              if (!repossession.interestPoint?.length) return acc;
              let currentScore = 0;
              let idPrimary = "";

              const interestPoints:{[id:string]:featureProperties} = {};
              repossession.interestPoint.forEach(
                  (interestPoint) => {
                      const id = `${
                          interestPoint._id ?? `tmp${interestPoint.type}`
                      }${repossession._id}`;
                      const ipScore = InterestPointTypeValues[interestPoint.type].score;
                      if (currentScore < ipScore ) {
                          currentScore = ipScore;
                          idPrimary = id;
                      }
                      interestPoints[id]= {
                          repossession,
                          interestPoint,
                          id,
                          primary: false,
                      };
                  }
              );
              interestPoints[idPrimary].primary = true;
              return {...acc,...interestPoints};
          },
          {}
      ),
        recoveryTotals: action.payload.totals,
      };
    case RepossessionsActions.add_historic:
      return {
        ...state,
        historicRecoveries: { ...state.historicRecoveries, [action.payload._id]: action.payload },
      };

    default:
      return state;
  }
};
interface ReducerLocationsType{
  lastUpdateLocations:number;
  allLocations: any[] | undefined;
  historicLocations: { [key: string]: any };
}

const iniLoc= {
  lastUpdateLocations: 0,
  allLocations: undefined,
  historicLocations:{}
};

// eslint-disable-next-line default-param-last
export const locationsReducer = (state: ReducerLocationsType = iniLoc, action: any):ReducerLocationsType => {
  switch (action.type) {
    case LocationsActions.load_locations:
      return {
        ...state,
        lastUpdateLocations: Date.now(),
        allLocations: action.payload.data,
      };
    case RepossessionsActions.add_historic:
      return {
        ...state,
        historicLocations: { ...state.historicLocations, [action.payload?.credit?._id]: action.payload },
      };

    default:
      return state;
  }
};
